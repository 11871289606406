import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * Footer component for AppLayout
 *
 * @param props          Props passed to the component
 * @param props.children Footer contents
 * @returns              Component itself
 */
const Footer: FC<Props> = ({ children }) => {
  return <Styled.Footer>{children}</Styled.Footer>;
};

export default Footer;
