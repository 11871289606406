/**
 * @file A template was updated, so we update the cache with it
 */
import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TemplateUpdateMutation,
  TemplateUpdateMutationVariables,
} from '../../../../../generated/graphql';
import templateUpdate from '../utils/updaters/templates/templatesUpdate';

/**
 * Resolve cache after a template was updated
 *
 * @param result The result of the mutation (unused)
 * @param _args  Arguments of a graphQL operation (unused)
 * @param cache  URQL graphQL cache
 */
const templateUpdateMutation: UpdateResolver<
  TemplateUpdateMutation,
  TemplateUpdateMutationVariables
> = (result, _args, cache) => {
  templateUpdate(cache, result.templateUpdate);
};

export default templateUpdateMutation;
