/**
 * @file Helper files for message labels
 */

import {
  LabelBasicFragment,
  MessageLabelsQuery,
} from '../../../generated/graphql';

/**
 * Extract available labels from retrieved data
 *
 * @param data The data returned from the server
 * @returns    All available labels
 */
export const getLabelsAll = (
  data?: MessageLabelsQuery,
): LabelBasicFragment[] => {
  return data?.labels ?? [];
};

/**
 * Extract selected labels for this message
 *
 * @param data The data returned from the server
 * @returns    All selected labels
 */
export const getLabelsSelected = (
  data?: MessageLabelsQuery,
): LabelBasicFragment[] => {
  return data?.message?.labels ?? [];
};
