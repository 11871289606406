/**
 * @file Handle topic delete mutation by updating the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicDeleteMutation,
  TopicDeleteMutationVariables,
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables,
  TopicsDocument,
  TopicSettingsDocument,
} from '../../../../../generated/graphql';
import queryDeletion from '../utils/updaters/queryDeletion';

/**
 * A topic was deleted, so we remove it from all queries
 *
 * @param _result The result of the mutation (unused)
 * @param args    Arguments of a graphQL operation
 * @param cache   URQL graphQL cache
 */
const topicDelete: UpdateResolver<
  TopicDeleteMutation | TopicInfoSubscription,
  TopicDeleteMutationVariables | TopicInfoSubscriptionVariables
> = (_result, args, cache): void => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: TopicsDocument,
    queryName: 'topics',
  });

  queryDeletion({
    cache,
    entityId: args.id,
    query: TopicSettingsDocument,
    queryName: 'topicsAll',
  });

  // const { parentKey: entityKey, fieldName } = info;

  // This alone is enough to remove this topic from all queries,
  // but it's slower than direct cache manipulation
  // so queryDeletion is used to instantly remove from UI,
  // and then this is added to clean up all the links
  // and from any queries we might have forgotten
  cache.invalidate({
    __typename: 'Topic',
    id: args.id,
  });
};

export default topicDelete;
