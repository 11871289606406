/**
 * @file contains a set of helpers that convert RGBA color codes to HSLA
 */

import { ColorRGBA } from './colorHexToRgba';

type ColorHSLA = {
  hue: number;
  saturation: number;
  lightness: number;
  alpha: number;
};

type hueArgs = {
  red: number;
  green: number;
  blue: number;
  channelDelta: number;
  channelMax: number;
};

/**
 * Used for calculating hue
 *
 * @param hueArgs              Object containing arguments for this function
 * @param hueArgs.red          RGB red
 * @param hueArgs.green        RGB green
 * @param hueArgs.blue         RGB blue
 * @param hueArgs.channelDelta Difference between highest and lowest channel value
 * @param hueArgs.channelMax   Highest channel value (r, g or b)
 * @returns                    HSL hue
 */
const calculateHue = ({
  red,
  green,
  blue,
  channelDelta,
  channelMax,
}: hueArgs): number => {
  let hue;

  if (channelDelta === 0) {
    return 0;
  }

  if (channelMax === red) {
    hue = ((green - blue) / channelDelta) % 6;
  } else if (channelMax === green) {
    hue = (blue - red) / channelDelta + 2;
  } else {
    hue = (red - green) / channelDelta + 4;
  }

  return hue < 0 ? Math.round(hue * 60) + 360 : Math.round(hue * 60);
};

/**
 * Used for calculating saturation
 *
 * @param channelDelta Difference between highest and lowest channel value
 * @param lightness    HSL lightness
 * @returns            HSL saturation
 */
const calculateSaturation = (
  channelDelta: number,
  lightness: number,
): number => {
  const saturation =
    channelDelta === 0 ? 0 : channelDelta / (1 - Math.abs(2 * lightness - 1));

  return Math.round(saturation) * 100;
};

/**
 * Get a color's HSLA values from its RGBA representation
 *
 * @param color RGBA color (use colorHexToRgba() to transform HEX to RGBA)
 * @returns     HSLA color
 */
const colorRgbaToHsla = (color: ColorRGBA): ColorHSLA => {
  const red = color.red / 255;
  const green = color.green / 255;
  const blue = color.blue / 255;
  const alpha = color.alpha;

  const channelMin = Math.min(red, green, blue);
  const channelMax = Math.max(red, green, blue);
  const channelDelta = channelMax - channelMin;

  // Object keys sorted in rgb manner, we don't need the linter to correct this
  // eslint-disable-next-line sort-keys
  const hue = calculateHue({ red, green, blue, channelDelta, channelMax });
  const lightness = Math.round(((channelMin + channelMax) / 2) * 100);
  const saturation = calculateSaturation(channelDelta, lightness) * 100;

  // Object keys sorted in hsla manner, we don't need the linter to correct this
  // eslint-disable-next-line sort-keys
  return { hue, saturation, lightness, alpha };
};

export default colorRgbaToHsla;
