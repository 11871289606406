import React, { FC } from 'react';

import { PREFERENCE_BADGE_LIMIT } from '../../../constants/preferences';

import { reportError } from '../../../services/reporting';

import * as Styled from './styled';

export type Props = {
  number: number;
};

/**
 * Badge number indicator for eg. unread messages
 *
 * @param props        Props passed to the component
 * @param props.number The number to show
 * @returns            The component itself
 */
const BadgeNumber: FC<Props> = ({ number }) => {
  if (number < 0) {
    reportError(
      'BadgeNumber: Number should not be less then 0, please check the code.',
    );
    return null;
  }

  if (number < 1) {
    return null;
  }

  const badgeText =
    number <= PREFERENCE_BADGE_LIMIT
      ? number.toString()
      : `${PREFERENCE_BADGE_LIMIT}+`;

  return <Styled.Badge>{badgeText}</Styled.Badge>;
};

export default BadgeNumber;
