import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import SplashScreen from '../../../components/SplashScreen';
import { TopicsListDocument } from '../../../generated/graphql';
import useUserSelectedHotel from '../../../hooks/hotel/useUserSelectedHotel';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../hooks/useIsMobile';
import { reportError } from '../../../services/reporting';
import { getTopicFromHomeRedirectUrl } from '../../urls/topic';

import ErrorTopicsNotFetched from './ErrorTopicsNotFetched';
import HomeMobile from './HomeMobile';
import * as Styled from './styled';

/**
 * Home page
 *
 * @returns Home page component.
 */
const PageHome: FC = () => {
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  useUserSelectedHotel(hotelId);

  const [{ data, error, fetching }] = useQuery({ query: TopicsListDocument });

  if (fetching) {
    return (
      <Styled.Wrapper>
        <SplashScreen />
      </Styled.Wrapper>
    );
  }

  if (error) {
    reportError(error);

    return <ErrorTopicsNotFetched />;
  }

  // For some reason there is no data on the topics
  if (data === undefined) {
    reportError('Error. Topics were not fetched.');

    return <ErrorTopicsNotFetched />;
  }

  if (isMobile) {
    return <HomeMobile isLoading={fetching} topics={data.topics} />;
  }

  const firstTopic = data.topics[0];
  // Redirect to /feed on desktop
  const feedUrl = getTopicFromHomeRedirectUrl(hotelId, firstTopic?.id ?? null);

  return <Navigate to={feedUrl} />;
};

export default PageHome;
