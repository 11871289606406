/**
 * @file contains function for validation of Mongo id
 */

import { reportError } from '../../services/reporting';

// RegEx to validate Mongo IDs against
const mongoIdRegex = /^[a-fA-F0-9]{24}$/;

/**
 * Validates a string to see if it matches the mongoId format
 * note: This is not 100% check if something is an ID, just if something looks like a mongoId
 *
 * @param idToCheck Id to check
 * @returns         Whether the argument passed in is a valid mongo id or not
 */
const validateMongoId = (idToCheck: string | null | undefined): boolean => {
  if (idToCheck === null || idToCheck === undefined) {
    reportError('MongoID not passed');
    return false;
  }

  const isValidId = mongoIdRegex.test(idToCheck);

  if (isValidId === false) {
    reportError('Invalid MongoID: ' + idToCheck);
  }

  return isValidId;
};

export default validateMongoId;
