import { IconButton } from '@mui/material';
import React, { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import MuiIcon from '../../../components/MUI/MuiIcon';
import { TopicWithMembersFragment } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../hooks/useIsTablet';
import translate from '../../../i18n/translate';
import { getTopicDetailsUrl } from '../../../routes/urls/topic';
import { getCanUpdateTopic } from '../../../utils/permissions/topic';

type Props = {
  topic: TopicWithMembersFragment | undefined;
};

/**
 * Component that renders the edit icon in the header if the user has permissions
 *
 * @param props       Props passed to the component
 * @param props.topic The topic we want to edit
 * @returns           THe component itself
 */
const ContentRightTopicEdit: FC<Props> = ({ topic }) => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();

  if (topic === undefined || getCanUpdateTopic(topic) === false) {
    return null;
  }

  const topicEditUrl = getTopicDetailsUrl(
    hotelId,
    isTablet,
    location,
    topic.id,
  );

  return (
    <IconButton
      aria-label={translate('TOPIC__EDIT')}
      color="primary"
      component={Link}
      to={topicEditUrl}
    >
      <MuiIcon icon="edit" />
    </IconButton>
  );
};

export default ContentRightTopicEdit;
