// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --box-shadow-strong: 0 5px 9px 0 rgba(0, 0, 0, 0.5);
  --box-shadow-popup: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
  --box-shadow-popup-triangle-upward: -4px 4px 4px 0 rgba(0, 0, 0, 0.4);
  --box-shadow-popup-triangle-downward: -2px 2px 0px 0 var(--color-grey-lighter);
  --focus-shadow: 0 0 0 1px var(--color-outline);

  --border-alternative: 1px solid var(--color-border-alternative);
  --border-dark: 1px solid var(--color-border-dark);
  --border-danger: 1px solid var(--color-border-danger);
  --border-darker: 1px solid var(--color-border-darker);
  --border-group: 1px solid var(--color-background-tools);
  --border-pale: 1px solid var(--color-border-light);
  --border-outline: 1px solid var(--color-outline);
  --border-scrollbar: 3px solid var(--color-border-dark);
  --mui-button-outlined-border: 1px solid rgba(55, 79, 103, 0.5);

  --z-1: 1;
  --z-subheader: 2;
  --z-bottom-nav: 3;
  --z-compose-main: 4;
  --z-compose-popup: 5;
  --z-sidebar: 6;
  --z-select-dropdown: 7;
  --z-modals: 8;
  --z-toast: 9;

  --cubic-bezier-1: cubic-bezier(0.82, 0.085, 0.395, 0.895);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_miscellaneous.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,kDAAkD;EAClD,qEAAqE;EACrE,8EAA8E;EAC9E,8CAA8C;;EAE9C,+DAA+D;EAC/D,iDAAiD;EACjD,qDAAqD;EACrD,qDAAqD;EACrD,uDAAuD;EACvD,kDAAkD;EAClD,gDAAgD;EAChD,sDAAsD;EACtD,8DAA8D;;EAE9D,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,YAAY;;EAEZ,yDAAyD;AAC3D","sourcesContent":[":root {\n  --box-shadow-strong: 0 5px 9px 0 rgba(0, 0, 0, 0.5);\n  --box-shadow-popup: 0 4px 4px 0 rgba(0, 0, 0, 0.4);\n  --box-shadow-popup-triangle-upward: -4px 4px 4px 0 rgba(0, 0, 0, 0.4);\n  --box-shadow-popup-triangle-downward: -2px 2px 0px 0 var(--color-grey-lighter);\n  --focus-shadow: 0 0 0 1px var(--color-outline);\n\n  --border-alternative: 1px solid var(--color-border-alternative);\n  --border-dark: 1px solid var(--color-border-dark);\n  --border-danger: 1px solid var(--color-border-danger);\n  --border-darker: 1px solid var(--color-border-darker);\n  --border-group: 1px solid var(--color-background-tools);\n  --border-pale: 1px solid var(--color-border-light);\n  --border-outline: 1px solid var(--color-outline);\n  --border-scrollbar: 3px solid var(--color-border-dark);\n  --mui-button-outlined-border: 1px solid rgba(55, 79, 103, 0.5);\n\n  --z-1: 1;\n  --z-subheader: 2;\n  --z-bottom-nav: 3;\n  --z-compose-main: 4;\n  --z-compose-popup: 5;\n  --z-sidebar: 6;\n  --z-select-dropdown: 7;\n  --z-modals: 8;\n  --z-toast: 9;\n\n  --cubic-bezier-1: cubic-bezier(0.82, 0.085, 0.395, 0.895);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
