/**
 *
 * @file contains basic translations function
 * (provide key and get translation in current language)
 */

import { PREFERENCE_PLACEHOLDER_SYMBOL } from '../constants/preferences';
import { reportError, reportWarning } from '../services/reporting';
import { getLanguageCode } from '../utils/user/language';

import { getTranslations } from './translations';

/**
 * Count the number of occurrences of value in string
 *
 * @param text  Text on which to check the count
 * @param regEx RegExp to match the placeholders
 * @returns     Number of occurrences of regEx in text
 */
const getCount = (text: string, regEx: RegExp): number => {
  return (text.match(regEx) ?? []).length;
};

/**
 * Replace placeholders with passed values.
 *
 * When replaceValues is string match every occurrence of placeholders and replace them with same value.
 *
 * When replaceValues is string[] match placeholder by index and replace with same index value in array.
 *
 * @param regEx         RegExp to match the placeholders
 * @param replaceValues Value to replace placeholders with
 * @param text          Text with placeholders
 * @returns             Text with replaced placeholders
 */
const insertPlaceholderValues = (
  regEx: RegExp,
  replaceValues: string | string[],
  text: string,
): string => {
  if (typeof replaceValues === 'string') {
    text = text.replace(regEx, replaceValues);
    return text;
  }

  const count = getCount(text, regEx);
  const matchedPlaceholders = text.match(regEx);

  if (matchedPlaceholders === null) {
    reportWarning(
      'Translation: there are no placeholders in requested string but you still passed replaceValues. Please add placeholders or remove replaceValues.',
    );
    return text;
  }

  if (count !== replaceValues.length) {
    reportError(
      `Translation: replaceValues length doesn't match number of placeholders. Expected: ${count} Received ${replaceValues.length}`,
    );
    return text;
  }

  replaceValues.forEach(
    (_word, index) =>
      (text = text.replace(matchedPlaceholders[index], replaceValues[index])),
  );

  return text;
};

/**
 * Translate a key, using the currently set locale
 *
 * @param key           The key that needs to be translated
 * @param replaceValues Values that are used to replace placeholders in translation
 * @returns             The translated string
 */
const translate = (key: string, replaceValues?: string | string[]): string => {
  const translations = getTranslations();

  if (!translations) {
    reportError('Translations not loaded');
    return key;
  }
  const value = translations[key];

  if (value === undefined) {
    reportError(
      `Translation: ${key} not found for language: ${getLanguageCode()}`,
    );
    return key;
  }

  if (replaceValues === undefined) {
    return value;
  }

  // Use $n for placeholders, where n is number. (eg. "This is $1 example.")
  const regEx = new RegExp(`\\${PREFERENCE_PLACEHOLDER_SYMBOL}\\d`, 'g');

  return insertPlaceholderValues(regEx, replaceValues, value);
};

export default translate;
