/**
 * @file Hook to keep track of the conditions to show the notifications modals
 */

import { useCallback, useEffect, useState } from 'react';

import { setModalDismissed } from '../../../../../utils/storage/notifications';

import { NotificationModal } from '../helpers';

import useShouldShowOnFirstMessage from './useShouldShowOnFirstMessage';
import useShouldShowOnPageLoad from './useShouldShowOnPageLoad';

/**
 * Hook to keep track of the conditions to show the notifications modals
 *
 * @returns Which modal to show (or null if none)
 */
const useModalToShow = (): [NotificationModal | null, () => void] => {
  const toShowPageLoad = useShouldShowOnPageLoad();
  const toShowFirstMessage = useShouldShowOnFirstMessage();
  const [toShow, setToShow] = useState(toShowPageLoad ?? toShowFirstMessage);

  /**
   * Request to hide the current modal
   */
  const requestClose = useCallback(() => {
    setModalDismissed(toShow);
    setToShow(null);
  }, [toShow]);

  useEffect(() => {
    setToShow(toShowPageLoad ?? toShowFirstMessage);
  }, [toShowFirstMessage, toShowPageLoad]);

  return [toShow, requestClose];
};

export default useModalToShow;
