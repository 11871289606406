import React, { FC } from 'react';

import useTranslatedMessages from '../../../hooks/store/useTranslatedMessages';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { Comment } from '../../../models/comment';
import userGetInactive from '../../../utils/user/userGetInactive';
import userGetName from '../../../utils/user/userGetName';
import Avatar from '../../Common/Avatar';
import SkeletonParagraph from '../../Loading/SkeletonParagraph';

import * as Styled from './styled';

export type Props = {
  comment: Comment;
};

/**
 * A single comment
 *
 * @param props         Props passed to the component
 * @param props.comment The comment to show
 * @returns             The component itself
 */
const CommentSingle: FC<Props> = ({ comment }) => {
  const isMobile = useIsMobile();

  const isTranslated = useTranslatedMessages().has(comment.message.id);

  const createdAt = new Date(comment.createdAt);
  const creator = comment.creator === undefined ? null : comment.creator;
  const isInactive = userGetInactive(creator?.status);
  const usernameFallback = translate('FALLBACK__GUEST');
  const username = userGetName(creator, usernameFallback);

  return (
    <Styled.Wrapper
      data-is-external={comment.isForExternal}
      data-is-guest={creator === null}
      data-is-mobile={isMobile}
    >
      <Avatar size={40} user={creator} />
      <Styled.Content>
        <Styled.Header>
          <Styled.Username data-inactive={isInactive}>
            {username}
          </Styled.Username>
          <Styled.Date date={createdAt} />
        </Styled.Header>
        {isTranslated && comment?.translation?.text === undefined && (
          <SkeletonParagraph nrLines={1} />
        )}
        <Styled.Text data-inactive={isInactive}>
          {isTranslated ? comment?.translation?.text : comment.text}
        </Styled.Text>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default CommentSingle;
