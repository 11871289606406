/**
 * @file Functions to extract localized value
 */

import { reportWarning } from '../../services/reporting';

/**
 * Extract en or 1st value from the object
 *
 * @param data The data to get the value from
 * @returns    The appropriate localized value or null if not found
 */
const extractFallback = (data: Record<string, string>): string | null => {
  if ('en' in data) {
    return data.en;
  }

  const keys = Object.keys(data);

  if (keys.length === 0) {
    reportWarning('extractFallback: Empty value object.');
    return null;
  }

  return data[keys[0]];
};

/**
 * Extract localized value from various options record
 *
 * @param data               The data to get the value from
 * @param submissionLanguage The language concierge was submitted in
 * @returns                  The appropriate localized value or null if not found
 */
export const getValueLocalized = (
  data: Record<string, string> | string | null,
  submissionLanguage: string | null = null,
): string | null => {
  if (data === null) {
    return null;
  }

  if (typeof data === 'string') {
    return data;
  }

  if (submissionLanguage !== null && submissionLanguage in data) {
    return data[submissionLanguage];
  }

  return extractFallback(data);
};
