/**
 * @file A group was deleted, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  UserGroupDeleteMutation,
  UserGroupDeleteMutationVariables,
  UserGroupsDocument,
} from '../../../../../generated/graphql';
import queryDeletion from '../utils/updaters/queryDeletion';

/**
 * Handle group deletion
 *
 * @param _result Mutation result
 * @param args    Arguments of a graphql operation
 * @param cache   URQL graphql cache
 */
const groupDeleteMutation: UpdateResolver<
  UserGroupDeleteMutation,
  UserGroupDeleteMutationVariables
> = (_result, args, cache) => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: UserGroupsDocument,
    queryName: 'userGroups',
  });
};

export default groupDeleteMutation;
