/**
 * @file contains helper functions for smart views
 */
import { SearchQueriesQuery, SearchQuery } from '../generated/graphql';
import { extractTitle } from '../hooks/elasticSearch/useEsSmartViewQuery/helpers';

export type SmartViewsLink = {
  label: string;
  id: SearchQuery['id'];
};

/**
 * Generates smart view links from the data provided from the backend
 *
 * @param data Data from the searchQueries query from the backend
 * @returns    SmartView links array
 */
export const generateSmartViewsLinks = (
  data: SearchQueriesQuery['searchQueries'],
) => {
  return (
    data
      .map(({ id, title }) => ({ id, label: extractTitle(title) }))
      // Make sure not to allow empty labeled smart views to be visible
      .filter(({ label }) => label !== '')
  );
};
