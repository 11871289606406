import { styled } from 'styled-components';

import devices from '../../../../styles/devices';
import CommonButtonSingle from '../../ButtonSingle';

// Button container, right-aligned, with all of them equal height
export const Ul = styled.ul`
  align-items: stretch;
  display: flex;
  gap: var(--spacing-medium);
  justify-content: flex-end;
`;

export const ButtonSingle = styled(CommonButtonSingle)`
  padding: var(--spacing-medium) var(--spacing-small);

  @media ${devices.mobileM} {
    padding: var(--spacing-medium) var(--spacing-big);
  }
`;
