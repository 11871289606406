import { styled } from 'styled-components';

// Wrapper for the entire list
export const Ul = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  max-width: 100%;
`;

export const WrapperAvatars = styled.li`
  align-items: center;
  display: flex;
  justify-content: center;
`;
