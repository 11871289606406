import { styled } from 'styled-components';

// Left column, showing field icons and labels
export const Dt = styled.dt`
  font-weight: 600;
`;

// Field value
export const Dd = styled.dd`
  /* Reset user-agent style */
  margin-left: 0;
`;
