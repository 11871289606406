import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import RightSidebarHeader from '../../../components/RightSidebar/RightSidebarDesktop/Header';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamMessageEditMode from '../../../hooks/router/params/useParamMessageEditMode';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useTargetLanguage from '../../../hooks/useTargetLanguage';
import { getTopicUrl } from '../../../routes/urls/topic';
import ButtonTranslateContainer from '../../buttonTranslate';

/**
 * Header component for the sidebar (desktop only)
 *
 * @returns The component itself
 */
const SidebarHeaderDesktop: FC = () => {
  const hotelId = useParamHotelId();
  const location = useLocation();
  const messageId = useParamMessageId();
  const messageEditMode = useParamMessageEditMode();
  const targetLanguage = useTargetLanguage();
  const topicId = useParamTopicId();

  // When message mode available, we show the header from the meta inline component
  if (messageId === null || topicId === null || messageEditMode !== null) {
    return null;
  }

  const contentLeft =
    targetLanguage !== null ? (
      <ButtonTranslateContainer container="details" messageId={messageId} />
    ) : null;

  return (
    <RightSidebarHeader
      backUrl={getTopicUrl(hotelId, location, topicId)}
      contentLeft={contentLeft}
    />
  );
};

export default SidebarHeaderDesktop;
