import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import PickerValue from '../PickerValue';

import * as Styled from './styled';

export type Props = {
  onChange: (time: Date) => void;
  time: Date | null;
};

// Hours go 00-23 and minutes 00-59
const hoursMax = 23;
const minutesMax = 59;

/**
 * Time picker:
 * Consists of hour and minute values
 * that have up and down arrow buttons to change
 *
 * @param props          Props passed to the component
 * @param props.time     The time to show
 * @param props.onChange Callback invoked whenever the user has changed the value
 * @returns              The component itself
 */
const PickerTime: FC<Props> = ({ time, onChange }) => {
  const timeShown = time ?? new Date();

  const hours = timeShown.getHours();
  const minutes = timeShown.getMinutes();

  const labelHourIncrease = translate('DUE_DATE__HOUR_INCREASE');
  const labelHourDecrease = translate('DUE_DATE__HOUR_DECREASE');
  const labelMinuteIncrease = translate('DUE_DATE__MINUTE_INCREASE');
  const labelMinuteDecrease = translate('DUE_DATE__MINUTE_DECREASE');

  /**
   * The user has changed the hours part of the time
   *
   * @param value The new hour value
   */
  const onChangeHours = (value: number): void => {
    const timeUpdated = new Date(timeShown.getTime());
    timeUpdated.setHours(value);
    onChange(timeUpdated);
  };

  /**
   * The user has changed the minutes part of the time
   *
   * @param value The new minute value
   */
  const onChangeMinutes = (value: number): void => {
    const timeUpdated = new Date(timeShown.getTime());
    timeUpdated.setMinutes(value);
    onChange(timeUpdated);
  };

  return (
    <Styled.Wrapper>
      <PickerValue
        labelDecrease={labelHourDecrease}
        labelIncrease={labelHourIncrease}
        max={hoursMax}
        onChange={onChangeHours}
        value={hours}
      />

      <PickerValue
        labelDecrease={labelMinuteDecrease}
        labelIncrease={labelMinuteIncrease}
        max={minutesMax}
        onChange={onChangeMinutes}
        value={minutes}
      />
    </Styled.Wrapper>
  );
};

export default PickerTime;
