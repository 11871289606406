/**
 * @file Reading, storing and removing auth tokens
 */

import cookie from 'js-cookie';

import { COOKIE_AUTH } from '../../config';
import { PREFERENCE_COOKIE_OPTIONS_INTERNAL } from '../../constants/preferences';

/**
 * Get current access token
 *
 * @returns Access token if it exists
 */
export const getAuthToken = (): string | null => {
  return cookie.get(COOKIE_AUTH) ?? null;
};

/**
 * Remove auth cookies from cookie storage
 */
export const removeAuthCookies = (): void => {
  cookie.remove(COOKIE_AUTH);
};

/**
 * Save auth token
 *
 * @param token Access token
 */
export const setAuthToken = (token: string): void => {
  cookie.set(COOKIE_AUTH, token, PREFERENCE_COOKIE_OPTIONS_INTERNAL);
};
