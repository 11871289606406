/**
 * @file Handle subscription to everything related to templates
 */
import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  TemplateInfoSubscription,
  TemplateInfoSubscriptionVariables,
  TemplatesDocument,
  TemplatesInTopicDocument,
} from '../../../../../generated/graphql';

import queryCreation from '../utils/updaters/queryCreation';
import templatesUpdate from '../utils/updaters/templates/templatesUpdate';

/**
 * Handle subscription to everything related to templates
 *
 * @param result Result of a graphql operation
 * @param _args  Arguments of a graphql operation (unused)
 * @param cache  URQL graphql cache
 * @returns      void
 */
const templateInfoSubscription: UpdateResolver<
  TemplateInfoSubscription,
  TemplateInfoSubscriptionVariables
> = (result, _args, cache) => {
  const { action, template } = result.templateInfo;

  if (action === ActionType.Created) {
    return queryCreation({
      cache,
      queries: [
        { name: 'templatesEditable', query: TemplatesDocument },
        {
          name: 'templatesInTopic',
          query: TemplatesInTopicDocument,
          variables: { topicId: template.topic.id },
        },
      ],
      result: template,
    });
  }

  if (action === ActionType.Deleted) {
    return cache.invalidate({
      __typename: 'Template',
      id: template.id,
    });
  }

  if (
    action === ActionType.Updated &&
    'templateInfo' in result &&
    result.templateInfo.template
  ) {
    templatesUpdate(cache, result.templateInfo.template);
  }
};

export default templateInfoSubscription;
