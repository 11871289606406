import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';

import { colorNeutral, colorPrimary } from '../../../../../styles/mui/colors';
import { colorSecondaryStyle } from '../../../../../styles/mui/components/shared';

export const MuiListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'var(--font-weight-label-bold)',
    padding: 'var(--spacing-tiny)',
    right: 'var(--spacing-tiny)',
    top: -2,
  },
  '& a:focus-visible': {
    boxShadow: 'none',
  },
  '&:focus-within': {
    backgroundColor: colorNeutral[500],
  },
  '&:hover': {
    backgroundColor: colorNeutral[400],
  },
  "&[aria-current='true']": {
    ...colorSecondaryStyle,
    '& .MuiListItemText-primary': {
      fontWeight: 'var(--font-weight-label-bold)',
    },
  },

  borderRadius: 'var(--border-radius-circle)',
  height: 'var(--preference-select-item-min-height)',
  // when we hover over the item, and the next to it is active, there is no gap between them.
  // Just a small margin hardcoded to make it look better.
  margin: '2px 0',
  padding: 0,
  transition: 'background-color var(--preference-transition-duration-normal)',
}));

export const MuiListItemButton = styled(ListItemButton)({
  borderRadius: 'var(--border-radius-circle)',
  height: '100%',
  padding: '0 var(--spacing-big)',
});

export const MuiListItemAvatar = styled(ListItemAvatar)({
  '& .MuiAvatar-colorDefault': {
    backgroundColor: colorPrimary[800],
  },
  marginLeft: 'calc(var(--spacing-medium) * -1)',
  marginRight: 'var(--spacing-medium)',
  minWidth: 'auto',
});

export const MuiListItemText = styled(ListItemText)(({ theme }) => ({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'var(--preference-label-line-clamp)',
  color: theme.palette.secondary.contrastText,
  display: '-webkit-box',
  overflow: 'hidden',
  textAlign: 'start',
  textWrap: 'wrap',
}));
