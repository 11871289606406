import { css, styled } from 'styled-components';

import { visuallyHidden } from '../../../../styles/common';

// Wrapper around the component
export const Label = styled.div`
  color: var(--color-text-regular);
`;

type LabelTextProps = {
  'data-is-visible': boolean;
};

/**
 * Get additional styles to apply to the label based on its state
 *
 * @param props Props passed to the label
 * @returns     The CSS to apply
 */
const getLabelHiddenStyles = (props: LabelTextProps) => {
  if (props['data-is-visible'] === true) {
    return css`
      display: block;
    `;
  } else {
    return visuallyHidden;
  }
};

// Label text
export const LabelText = styled.label<LabelTextProps>`
  ${props => getLabelHiddenStyles(props)}
  font-size: var(--font-lg);
  font-weight: var(--font-weight-form-label);
  margin-bottom: var(--spacing-small);
`;
