import { styled } from 'styled-components';

import DateTime from '../DateTime';

type Props = {
  'data-is-late': boolean;
  'data-is-resolved': boolean;
};

/**
 * Determine which color the DueDate for the ride sidebar should be,
 * depending on which status/date the message has
 *
 * @param props Props passed to the component
 * @returns     CSS variable string
 */
const getColor = (props: Props): string => {
  if (props['data-is-resolved']) {
    return 'var(--color-message-resolved)';
  }

  if (props['data-is-late']) {
    return 'var(--color-message-late)';
  }

  return 'var(--color-placeholder)';
};

// Due date text itself
export const DateDue = styled(DateTime)<Props>`
  color: ${getColor};
  margin-right: var(--spacing-tiny);
`;
