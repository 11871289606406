/**
 * @file Contains shared customizations across different colors and states
 */

import {
  colorError,
  colorNeutral,
  colorPrimary,
  colorSecondary,
  colorSuccess,
  colorTertiary,
} from '../colors';

/**
 * Error Color
 */

export const colorErrorBaseStyle = {
  backgroundColor: colorError[100],
  color: colorError.contrastText,
};

export const colorErrorFocusStyle = {
  backgroundColor: colorError[300],
};

export const colorErrorHoverStyle = {
  backgroundColor: colorError[200],
};

export const colorErrorStyle = {
  '&:focus-visible': colorErrorFocusStyle,
  '&:hover': colorErrorHoverStyle,
  ...colorErrorBaseStyle,
};

/**
 * Neutral Color
 */

export const colorNeutralBaseStyle = {
  backgroundColor: colorNeutral[700],
  color: colorNeutral.contrastText,
};

export const colorNeutralFocusStyle = {
  backgroundColor: colorNeutral[900],
};

export const colorNeutralHoverStyle = {
  backgroundColor: colorNeutral[800],
};

export const colorNeutralStyle = {
  '&:focus-visible': colorNeutralFocusStyle,
  '&:hover': colorNeutralHoverStyle,
  ...colorNeutralBaseStyle,
};

/**
 * Primary Color
 */

export const colorPrimaryBaseStyle = {
  backgroundColor: colorPrimary[700],
  color: colorPrimary.contrastText,
};

export const colorPrimaryFocusStyle = {
  backgroundColor: colorPrimary[900],
};

export const colorPrimaryHoverStyle = {
  backgroundColor: colorPrimary[800],
};

export const colorPrimaryStyle = {
  '&:focus-visible': colorNeutralFocusStyle,
  '&:hover': colorNeutralHoverStyle,
  ...colorNeutralBaseStyle,
};

/**
 * Secondary color
 */

export const colorSecondaryFocusStyle = {
  backgroundColor: colorSecondary[700],
};

export const colorSecondaryHoverStyle = {
  backgroundColor: colorSecondary[600],
};

export const colorSecondaryBaseStyle = {
  backgroundColor: colorSecondary[500],
  color: colorSecondary.contrastText,
};

export const colorSecondaryStyle = {
  '&:focus-visible': colorSecondaryFocusStyle,
  '&:hover': colorSecondaryHoverStyle,
  ...colorSecondaryBaseStyle,
};

/**
 * Success color
 */

export const colorSuccessBaseStyle = {
  backgroundColor: colorSuccess[100],
  color: colorSuccess.contrastText,
};

export const colorSuccessFocusStyle = {
  backgroundColor: colorSuccess[300],
};

export const colorSuccessHoverStyle = {
  backgroundColor: colorSuccess[200],
};

export const colorSuccessStyle = {
  '&:focus-visible': colorSuccessFocusStyle,
  '&:hover': colorSuccessHoverStyle,
  ...colorSuccessBaseStyle,
};

/**
 * Tertiary color
 */

export const colorTertiaryBaseStyle = {
  backgroundColor: colorTertiary[500],
  color: colorTertiary.contrastText,
};

export const colorTertiaryFocusStyle = {
  backgroundColor: colorTertiary[300],
};

export const colorTertiaryHoverStyle = {
  backgroundColor: colorTertiary[400],
};

export const colorTertiaryStyle = {
  '&:focus-visible': colorTertiaryFocusStyle,
  '&:hover': colorTertiaryHoverStyle,
  ...colorTertiaryBaseStyle,
};

export const railListItemIconRootStyle = {
  borderRadius: 'var(--border-radius-circle)',
  justifyContent: 'center',
  minWidth: 0,
  padding: 'var(--spacing-tiny) var(--spacing-medium)',
  ...colorPrimaryBaseStyle,
};
