/**
 * @file Redux mappers for messages container
 */

import { MapStateToProps } from 'react-redux';

import AppState from '../../models/state';

import { OwnProps, StateProps } from './props';

/**
 * Map part of Redux state to container props
 *
 * @param state The current state
 * @returns     Props to add to the container
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  networkMessages: state.debug.networkMessages,
});
