/**
 * @file Mobile app nav links
 */

import { Hotel } from '../../generated/graphql';
import { createChatPath } from '../../routes/paths/chat';
import { createFeedPath } from '../../routes/paths/feed';
import { createHomePath } from '../../routes/paths/home';
import { createKanbanPath } from '../../routes/paths/kanban';
import { createMorePath } from '../../routes/paths/more';
import {
  createSearchPath,
  createSearchTextPath,
} from '../../routes/paths/search';
import { createSettingsPath } from '../../routes/paths/settings';
import { createSmartViewPath } from '../../routes/paths/smartViews';
import { getChatUrl } from '../../routes/urls/chat';

import { getHomeUrl } from '../../routes/urls/home';
import { getMoreUrl } from '../../routes/urls/more';
import { getSearchUrl } from '../../routes/urls/search';

import { FooterNavLink } from './types';

type GetNavLinksOptions = {
  hotelId: Hotel['id'] | null;
  isChatEnabled: boolean | null;
  unreadMessagesCount: number;
};

const chatRootPaths = [createChatPath()];
const homeRootPaths = [
  createHomePath(),
  createFeedPath(),
  createKanbanPath(),
  createSmartViewPath(),
];
const moreRootPaths = [createMorePath(), createSettingsPath()];
const searchRootPaths = [createSearchPath(), createSearchTextPath()];

/**
 * Small screen nav links
 *
 * @param options                     Options
 * @param options.hotelId             ID of the hotel
 * @param options.isChatEnabled       Whether chat is enabled
 * @param options.unreadMessagesCount Number of messages that are unread
 *
 * @returns                           Array of navigation links data
 */
export const getNavLinks = ({
  hotelId,
  isChatEnabled,
  unreadMessagesCount,
}: GetNavLinksOptions): FooterNavLink[] => {
  const chatLabel = 'GENERAL__CHAT';

  const links: FooterNavLink[] = [
    {
      badgeCount: unreadMessagesCount,
      iconName: 'home',
      label: 'GENERAL__HOME',
      paths: homeRootPaths,
      url: getHomeUrl(hotelId),
    },
    {
      iconName: 'chatBubble',
      isDisabled: isChatEnabled === null,
      label: chatLabel,
      paths: chatRootPaths,
      url: getChatUrl(hotelId),
    },
    {
      iconName: 'search',
      label: 'GENERAL__SEARCH',
      paths: searchRootPaths,
      url: getSearchUrl(hotelId),
    },
    {
      iconName: 'more',
      label: 'GENERAL__MORE',
      paths: moreRootPaths,
      url: getMoreUrl(hotelId),
    },
  ];

  if (isChatEnabled === false) {
    return links.filter(link => link.label !== chatLabel);
  }

  return links;
};

/**
 * Get which link in the bottom navigation should be selected
 *
 * @param currentPath The current path
 * @param links       Link items shown in the bottom navigation
 * @returns           Position of active link
 */
export const getActiveNavItemIndex = (
  currentPath: string,
  links: FooterNavLink[],
) => {
  // Home is shown on /:hotelId and /:hotelId/home paths.
  // In case of /:hotelId, currentPath === ''
  if (currentPath === '') {
    return 0;
  }

  return links.findIndex(({ paths }) =>
    paths.some(path => currentPath.startsWith(path)),
  );
};
