import React, { ComponentProps, FC, ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import translate from '../../../i18n/translate';

import ButtonBack from '../../MUI/ButtonBack';

import HeaderSegmentedButtons from '../SegmentedButtons';

import HeaderMobileTitle from './HeaderMobileTitle';
import { goBack } from './helpers';

import * as Styled from './styled';

export type Props = {
  backUrl?: string | true;
  children?: ReactNode;
  contentRight?: ReactNode;
  isLoading?: boolean;
  segmentedButtons?: ComponentProps<typeof HeaderSegmentedButtons>['buttons'];
  title: ReactNode;
};

/**
 * Mobile header component for feed and details view
 *
 * @param props                  props passed to the component
 * @param props.backUrl          Route back button should redirect to / If true, use native back
 * @param props.children         Children that will be shown in the subheader (segmented buttons, quick filters...)
 * @param props.contentRight     Content that should be shown on the right side of the header
 * @param props.isLoading        Whether data required for the header is loading
 * @param props.segmentedButtons Segmented buttons that will be shown within the subheader to navigate between different views
 * @param props.title            title that will be shown in the header
 *
 * @returns                      The component itself
 */
const BaseHeaderMobile: FC<Props> = ({
  backUrl = null,
  children = null,
  contentRight = null,
  isLoading = false,
  segmentedButtons = [],
  title,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Styled.HeaderContent>
        <Styled.LeftElementContainer>
          {backUrl !== null && (
            <ButtonBack
              label={translate('GENERAL__BACK')}
              onClick={() => {
                goBack(backUrl, navigate);
              }}
            />
          )}
        </Styled.LeftElementContainer>

        <HeaderMobileTitle isLoading={isLoading} title={title} />

        <Styled.RightElementContainer>
          {contentRight}
        </Styled.RightElementContainer>
      </Styled.HeaderContent>
      <Styled.SubheaderWrapper>
        <Styled.SegmentedButtons buttons={segmentedButtons} />
        {children}
      </Styled.SubheaderWrapper>
    </>
  );
};

export default BaseHeaderMobile;
