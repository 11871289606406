import React, { FC } from 'react';
import { connect } from 'react-redux';

import ComposeMetaTitle from '../../../components/Compose/Common/ComposeMeta/ComposeMetaTitle';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Container for compose title
 *
 * @param props                         Props passed to the container
 * @param props.formId                  The ID of the form the title belongs to
 * @param props.requestDraftTitleUpdate Request that the title is updated in the draft
 * @param props.title                   The typed value
 * @returns                             The container itself
 */
const ComposeMetaTitleContainer: FC<Props> = ({
  formId,
  requestDraftTitleUpdate,
  title,
}) => {
  return (
    <ComposeMetaTitle
      formId={formId}
      onChange={requestDraftTitleUpdate}
      value={title}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaTitleContainer);
