import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import FeedKanbanHeaderDesktop from './desktop';
import FeedKanbanHeaderMobile from './mobile';

/**
 * Header component for feed and kanban view
 *
 * @returns The component itself
 */
const FeedKanbanHeader: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile === true) {
    return <FeedKanbanHeaderMobile />;
  }

  return <FeedKanbanHeaderDesktop />;
};

export default FeedKanbanHeader;
