import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

// Wraps around the entire component
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  transition: background-color var(--preference-transition-duration-normal);

  @media ${devices.tabletS} {
    padding: 0 var(--spacing-tiny);
  }

  &[aria-disabled='true'] {
    color: var(--color-grey-darkest);

    /* unsupported on iOS, but doesn't matter since that's touch input anyway */
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }

  @media (hover: hover) {
    &[aria-disabled='false'] {
      &:hover {
        background-color: var(--color-background-selected);
      }
    }
  }
`;

// Wraps around checkbox and item title
export const Label = styled.label`
  align-items: center;
  display: flex;
  flex-grow: 1;

  /**
  * Use min-width because flex child with flex-grow: 1; prop will go outside of parents container.
  * That way, if we have long text, we don't have horizontal scrollbar on sidebar
  */
  min-width: 0;
  padding: var(--spacing-small) 0;
`;

// Item title
export const Text = styled.p`
  flex-grow: 1;
  margin: 0 var(--spacing-tiny);
  ${Wrapper}[aria-disabled='true'] & {
    color: var(--color-grey-darkest);

    /* Supported only on Safari */
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }

  overflow: hidden;
  text-overflow: ellipsis;
`;
