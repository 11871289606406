/**
 * @file helpers for compose header component
 */

import React, { ReactNode } from 'react';
import { Location } from 'react-router-dom';

import { getComposeTitle } from '../../../components/Compose/helpers';
import { SEARCH_PARAM__LABEL_MODE } from '../../../constants/routing/searchParams';
import translate from '../../../i18n/translate';
import { ComposeModeParam } from '../../../models/pageParams';
import { removeParamsKeysFromUrl } from '../../../routes/helpers/router';
import { getSearchParamLabelMode } from '../../../routes/helpers/searchParams';
import { getLabelModeUrl } from '../../../routes/urls/label';
import { getCloseComposeSectionUrl } from '../../../routes/urls/searchParams/compose';
import { ComposeNodeId, ComposeNodeType } from '../../compose/props';
import { getLabelsHeading } from '../helpers';
import ContentRightAttachments from '../mobileContentRight/attach';
import ContentRightLabel from '../mobileContentRight/label';

/**
 * Get the back URL for the labels mode
 *
 * @param location History location object
 * @returns        Back URL for the labels mode
 */
const getLabelsModeBackUrl = (location: Location) => {
  const labelMode = getSearchParamLabelMode(location, 'compose');

  if (labelMode === null) {
    return getCloseComposeSectionUrl(location);
  }

  if (labelMode === 'MODE_VIEW') {
    return removeParamsKeysFromUrl(location, SEARCH_PARAM__LABEL_MODE);
  }

  return getLabelModeUrl(location, 'compose', 'MODE_VIEW');
};

type Callback = (
  location: Location,
  nodeId: ComposeNodeId,
  nodeType: ComposeNodeType,
) => { title: string; contentRight: ReactNode; backUrl: true | string };

export const mapping = new Map<ComposeModeParam | null, Callback>([
  [
    'assign',
    location => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: translate('ASSIGNMENT__EDIT__PLACEHOLDER'),
    }),
  ],
  [
    'attach',
    (location, _, nodeType) => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: <ContentRightAttachments nodeType={nodeType} />,
      title: translate('ATTACHMENT__EDIT__PLACEHOLDER'),
    }),
  ],
  [
    'checklist',
    location => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: translate('CHECKLIST__PLACEHOLDER'),
    }),
  ],
  [
    'date',
    location => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: translate('DUE_DATE__PLACEHOLDER'),
    }),
  ],
  [
    'description',
    (location, nodeId, nodeType) => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: getComposeTitle(nodeId, nodeType),
    }),
  ],
  [
    'label',
    location => ({
      backUrl: getLabelsModeBackUrl(location),
      contentRight: <ContentRightLabel where="compose" />,
      title: getLabelsHeading(location, 'compose'),
    }),
  ],
  [
    'offset',
    location => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: translate('MESSAGE__HEADING__DATE_DUE'),
    }),
  ],
  [
    'template',
    location => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: translate('COMPOSE__PLACEHOLDER__TEMPLATE'),
    }),
  ],
  [
    'title',
    (location, nodeId, nodeType) => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: getComposeTitle(nodeId, nodeType),
    }),
  ],
  [
    'topic',
    (location, nodeId, nodeType) => ({
      backUrl: getCloseComposeSectionUrl(location),
      contentRight: null,
      title: getComposeTitle(nodeId, nodeType),
    }),
  ],
]);
