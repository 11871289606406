/**
 * @file Helper functions for picker value component
 */

import { reportError } from '../../../services/reporting';

/**
 * Check if passed min/max/current values are valid, report error if not
 *
 * @param current Current value
 * @param min     Minimal allowed value
 * @param max     Maximal allowed value
 * @returns       Whether they're valid
 */
const validateValues = (current: number, min: number, max: number): boolean => {
  if (min > max) {
    reportError(`PickerValue: min (${min}) is larger than max (${max}).`);
    return false;
  }

  if (current < min) {
    reportError(
      `PickerValue: current (${current}) is smaller than min (${min}).`,
    );
    return false;
  }

  if (current > max) {
    reportError(
      `PickerValue: current (${current}) is larger than max(${max}).`,
    );
    return false;
  }

  return true;
};

/**
 * Get next value if we want to increase current
 * (loops around if at max already)
 *
 * @param current Current value
 * @param min     Minimal allowed value
 * @param max     Maximal allowed value
 * @returns       The next value
 */
export const getValueNextIncrease = (
  current: number,
  min: number,
  max: number,
): number => {
  const isValid = validateValues(current, min, max);

  if (isValid === false) {
    return current;
  }

  if (current === max) {
    return min;
  }

  return current + 1;
};

/**
 * Get next value if we want to increase current
 * (loops around if at min already)
 *
 * @param current Current value
 * @param min     Minimal allowed value
 * @param max     Maximal allowed value
 * @returns       The next value
 */
export const getValueNextDecrease = (
  current: number,
  min: number,
  max: number,
): number => {
  const isValid = validateValues(current, min, max);

  if (isValid === false) {
    return current;
  }

  if (current === min) {
    return max;
  }

  return current - 1;
};
