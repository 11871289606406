import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import CommentsContainer from '../../../containers/comments';
import useIsMobile from '../../../hooks/useIsMobile';

import { AttachmentMode } from '../../../models/attachment';
import { MessageCommon } from '../../../models/message';
import { ScrollIntoViewCallback } from '../../../models/scrollIntoView';
import { TranslationModel } from '../../../models/translations';
import { getIsConcierge, getIsCreatorActive } from '../../../utils/message';

import {
  getCanArchiveMessage,
  getCanDeleteMessage,
} from '../../../utils/permissions/message';

import { MessageContent } from './MessageContent';

import MessageFooter from './MessageFooter';
import MessageIcon from './MessageIcon';
import MessageInfoCreator from './MessageInfoCreator';
import MessageRibbon from './MessageRibbon';
import MessageTitle from './MessageTitle';

import * as Styled from './styled';

export type Props = {
  className?: string;
  isExpanded: boolean;
  message: MessageCommon;
  mode: AttachmentMode;
  scrollIntoViewCallback?: ScrollIntoViewCallback;
  shouldClamp?: boolean;
  shouldFocus?: boolean;
  shouldRenderLinks: boolean;
  shouldShowComments?: boolean;
  shouldShowRibbon?: boolean;
  translation?: TranslationModel;
  shouldHideBorderBottom?: boolean;
  url?: string;
};

/**
 * Show a single message in the feed
 *
 * @param props                        Props passed to the component
 * @param props.className              styled-components generated class name, needed for styling
 * @param props.isExpanded             Whether to expand concierge table by default
 * @param props.message                The message to show
 * @param props.mode                   Mode to render Attachment components in
 * @param props.scrollIntoViewCallback Callback that calls scrollIntoView if needed
 * @param props.shouldClamp            Whether to clamp message text after x lines
 * @param props.shouldFocus            Whether to focus the message
 * @param props.shouldRenderLinks      Whether to render links as <a />
 * @param props.shouldShowComments     Whether to show "Replies" toggle
 * @param props.shouldShowRibbon       Whether to show the edit/delete "Ribbon" (on hover)
 * @param props.translation            Translations for the message
 * @param props.url                    Url for message that is rendered as link
 * @param props.shouldHideBorderBottom Should hide/show message bottom border
 * @returns                            The component itself
 */
const MessageSingle: FC<Props> = ({
  className,
  isExpanded,
  message,
  mode,
  scrollIntoViewCallback,
  shouldClamp = false,
  shouldFocus = false,
  shouldRenderLinks,
  shouldShowComments = true,
  shouldShowRibbon = false,
  translation,
  url,
  shouldHideBorderBottom = false,
}) => {
  const {
    createdAt,
    creator,
    creatorType,
    id,
    isArchived,
    numberOfComments,
    title,
    isSeen,
    thread,
    topic,
    type,
  } = message;

  const isMobile = useIsMobile();

  const creatorNotActive = !getIsCreatorActive(message);
  const isFullWidth = getIsConcierge(type) || isMobile;
  const messageTitle = translation?.title ?? title;

  return (
    <Styled.OuterWrapper
      data-is-expanded={isExpanded}
      data-is-mobile={isMobile}
      data-should-dim={isArchived}
      data-should-focus={shouldFocus}
      data-should-hide-border-bottom={shouldHideBorderBottom}
    >
      <Styled.ContentWrapper
        as={url ? Link : 'div'}
        className={className}
        to={url}
      >
        {shouldShowRibbon && (
          <MessageRibbon
            messageId={id}
            shouldShowArchiveButton={getCanArchiveMessage()}
            shouldShowDeleteButton={getCanDeleteMessage(message)}
          />
        )}

        <MessageIcon message={message} />
        <Styled.Header>
          <MessageInfoCreator
            createdAt={createdAt}
            creator={creator}
            creatorType={creatorType}
            isArchived={isArchived}
          />
          {isMobile === false && (
            <MessageTitle isDimmed={creatorNotActive}>
              {messageTitle}
            </MessageTitle>
          )}
        </Styled.Header>

        <MessageContent
          message={message}
          mode={mode}
          shouldClamp={shouldClamp}
          shouldRenderLinks={shouldRenderLinks}
          translation={translation}
        />
      </Styled.ContentWrapper>
      <Styled.InnerWrapper data-is-full-width={isFullWidth}>
        <MessageFooter
          isExpanded={isExpanded}
          isUnread={isSeen === false}
          messageId={id}
          numberOfComments={numberOfComments}
          shouldShowComments={shouldShowComments}
          topicId={topic.id}
        />
        {shouldShowComments && isExpanded && (
          <CommentsContainer
            isArchived={isArchived}
            messageId={id}
            numberOfComments={numberOfComments}
            scrollIntoViewCallback={scrollIntoViewCallback}
            thread={thread}
          />
        )}
      </Styled.InnerWrapper>
    </Styled.OuterWrapper>
  );
};

export default MessageSingle;
