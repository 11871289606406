/**
 * @file Hook that tells us whether the membersFilters are active
 */
import { SEARCH_PARAM__FILTER__MEMBERS } from '../../../constants/routing/searchParams';

import useSearchParams from './useSearchParams';

/**
 * Hook that tells us whether the membersFilters are active
 * based on the searchParams
 *
 * @returns Whether the membersFilters are active
 */
const useIsFilterMembersActive = (): boolean => {
  const query = useSearchParams();

  const membersFiltersId = query.get(SEARCH_PARAM__FILTER__MEMBERS);

  return membersFiltersId !== null;
};

export default useIsFilterMembersActive;
