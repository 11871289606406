/**
 * @file Util functions for keyboard shortcuts
 */

import { Shortcut } from '../constants/keyboardShortcuts';

/**
 * Check whether a keyboard event was triggered
 * using the passed key combination
 *
 * @param event          The event that took place
 * @param event.altKey   Whether Alt/⎇ key was pressed
 * @param event.code     The code for the key pressed
 * @param event.ctrlKey  Whether Ctrl key was pressed
 * @param event.metaKey  Whether ⊞/⌘/Super button was pressed
 * @param event.shiftKey Whether Shift/⇧ key was pressed
 * @param shortcut       The shortcut to check
 * @returns              Whether the event matches the shortcut
 */
export const getIsShortcut = (
  { altKey, code, ctrlKey, metaKey, shiftKey }: KeyboardEvent,
  shortcut: Shortcut,
): boolean => {
  if (code !== shortcut.code) {
    return false;
  }

  if (altKey !== shortcut.altKey) {
    return false;
  }

  if (ctrlKey !== shortcut.ctrlKey) {
    return false;
  }

  if (metaKey !== shortcut.metaKey) {
    return false;
  }

  if (shiftKey !== shortcut.shiftKey) {
    return false;
  }

  return true;
};
