import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Wrapper = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-medium) var(--spacing-medium) var(--spacing-small);
`;

export const CloseSidebarDesktopLink = styled(Link)`
  margin-left: auto;
`;
