/**
 * @file Subscribe to MessageInfo subscription whenever the topic changes
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import { SubscribeToMessages } from '../../../models/subscriptions';
import { subscribeToMessagesSet } from '../../../store/actions/subscriptions';

/**
 * Subscribe to MessageInfo subscription whenever the topic changes
 */
const useMessageInfoSubscription = () => {
  const dispatch = useDispatch<Dispatch<SubscribeToMessages>>();
  const topicId = useParamTopicId();

  useEffect(() => {
    if (topicId === null) {
      return;
    }

    /**
     * We're never unsubscribing
     * because we want to still track changes when the user switches topics,
     * and update the urql cache,
     * so that if the user returns to the previously open topic,
     * they'll see correct data
     */
    dispatch(subscribeToMessagesSet(topicId));
  }, [dispatch, topicId]);
};

export default useMessageInfoSubscription;
