/**
 * @file Deletes a node from the cache
 */

import { Cache, Data } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';

import { Scalars } from '../../../../../../generated/graphql';

type Params = {
  cache: Cache;
  entityId: Scalars['ID']['output'];
  query: TypedDocumentNode;
  queryName: string;
};
/**
 * Deletes a node from the cache
 *
 * @param args           Arguments passed to the updater
 * @param args.cache     Graphql cache object
 * @param args.entityId  Id of entity that will be deleted
 * @param args.query     Document node of the query to update
 * @param args.queryName Name of the query in cache
 */
const queryDeletion = ({ cache, entityId, query, queryName }: Params): void => {
  cache.updateQuery({ query }, data => {
    if (
      /** Optional chaining doesn't work here, we need to handle both data and data.labels === null */
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      data === null ||
      data[queryName] === null ||
      data[queryName] === undefined
    ) {
      return null;
    }

    /**
     * Updates the data in the cache
     */
    (data[queryName] as Data[]) = (data[queryName] as Data[]).filter(
      el => el.id !== entityId,
    );
    return data;
  });
};
export default queryDeletion;
