import { styled } from '@mui/material';

import { Link } from 'react-router-dom';

import IconCommon from '../../../../Common/Icon';

export const Icon = styled(IconCommon)`
  margin: var(--spacing-big) auto var(--spacing-medium) auto;
`;

export const StyledLink = styled(Link)`
  display: flex;
`;
