/**
 * @file Concierge field types
 */

import {
  CHECKBOX,
  ConciergeFieldType,
  ConciergeFieldTypeRaw,
  DATE,
  DATETIME,
  DESCRIPTION,
  DIVIDER,
  DROPDOWN,
  EMAIL,
  FEEDBACK_EMOJI,
  FEEDBACK_STARS,
  RADIOBOX_GROUP,
  RAW_CHECKBOX,
  RAW_DATE,
  RAW_DATETIME,
  RAW_DESCRIPTION,
  RAW_DIVIDER,
  RAW_DROPDOWN,
  RAW_EMAIL,
  RAW_FEEDBACK_EMOJI,
  RAW_FEEDBACK_STARS,
  RAW_RADIOBOX_GROUP,
  RAW_SIGNATURE,
  RAW_SUBMIT,
  RAW_SUBTITLE,
  RAW_TEXT,
  RAW_TEXT_AREA,
  RAW_TIME,
  RAW_TITLE,
  SIGNATURE,
  SUBMIT,
  SUBTITLE,
  TEXT,
  TEXT_AREA,
  TIME,
  TITLE,
} from '../../constants/conciergeFieldTypes';

/**
 * Mapping between Messaging API field types and frontend field types
 */
const mapping: Record<ConciergeFieldTypeRaw, ConciergeFieldType> = {
  [RAW_CHECKBOX]: CHECKBOX,
  [RAW_DATE]: DATE,
  [RAW_DATETIME]: DATETIME,
  [RAW_DESCRIPTION]: DESCRIPTION,
  [RAW_DIVIDER]: DIVIDER,
  [RAW_DROPDOWN]: DROPDOWN,
  [RAW_EMAIL]: EMAIL,
  [RAW_FEEDBACK_EMOJI]: FEEDBACK_EMOJI,
  [RAW_FEEDBACK_STARS]: FEEDBACK_STARS,
  [RAW_RADIOBOX_GROUP]: RADIOBOX_GROUP,
  [RAW_SIGNATURE]: SIGNATURE,
  [RAW_SUBMIT]: SUBMIT,
  [RAW_SUBTITLE]: SUBTITLE,
  [RAW_TEXT]: TEXT,
  [RAW_TEXT_AREA]: TEXT_AREA,
  [RAW_TIME]: TIME,
  [RAW_TITLE]: TITLE,
};

/**
 * Get frontend field type based on backend field type
 *
 * @param typeRaw The type coming from the backend
 * @returns       The type used on the frontend
 */
const getFieldType = (typeRaw: ConciergeFieldTypeRaw): ConciergeFieldType => {
  return mapping[typeRaw];
};

export default getFieldType;
