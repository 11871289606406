import React, { FC } from 'react';

import { useMutation, useQuery } from 'urql';

import {
  Message,
  MessageTextDocument,
  MessageUpdateDocument,
} from '../../../generated/graphql';
import useRedirectOnError from '../../../hooks/useRedirectOnError';
import translate from '../../../i18n/translate';
import { reportApiErrors } from '../../../utils/error';
import { PropsInner } from '../props';

import Inner from './inner';

/**
 * Title and description mode for message full
 *
 * @param props                       Props passed to the component
 * @param props.messageId             The message that the assignment is for
 * @param props.networkMessageDetails Whether the debug option for fetching this is on
 * @param props.requestModeOverview   Request that messageFull switches to overview mode
 * @returns                           The component itself
 */
const MessageText: FC<PropsInner> = ({
  messageId,
  networkMessageDetails,
  requestModeOverview,
}) => {
  const handleErrorRedirect = useRedirectOnError();
  const [{ data, error, fetching }] = useQuery({
    query: MessageTextDocument,
    variables: { messageId },
  });

  const [, messageUpdateMutation] = useMutation(MessageUpdateDocument);

  if (
    data === null ||
    data === undefined ||
    fetching === true ||
    networkMessageDetails === true
  ) {
    return <div>{translate('PLACEHOLDER__LOADING')}</div>;
  }

  if (error) {
    handleErrorRedirect(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  /**
   * Request that title is updated
   *
   * @param description New description
   * @param title       New title
   */
  const requestSave = (
    description: Message['text'],
    title: Message['title'],
  ) => {
    messageUpdateMutation({
      data: {
        text: description,
        title,
      },
      id: messageId,
    }).catch(reportApiErrors);
  };

  return (
    <Inner
      description={data?.message?.text}
      requestModeOverview={requestModeOverview}
      requestSave={requestSave}
      title={data?.message?.title}
    />
  );
};

export default MessageText;
