import { ChatWindow } from '@gastfreund/roq-ui-react';
import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import FloatingBottomNav from '../../../components/FloatingBottomNav';
import AppLayout from '../../../components/layouts/AppLayout';
import ChatConversationPageHeader from '../../../containers/header/chat';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../hooks/useIsMobile';
import { getHomeUrl } from '../../../routes/urls/home';

import { useIsChatEnabled } from '../useIsChatEnabled';

/**
 * App layout containing the chat window (conversation)
 * as the main component.
 *
 * @returns Chat page
 */
export const ChatConversationPage: FC = () => {
  const { conversationId } = useParams();
  const isMobile = useIsMobile();
  const isEnabled = useIsChatEnabled();
  const hotelId = useParamHotelId();

  if (isEnabled === null) {
    return null;
  }

  if (isEnabled === false || conversationId === undefined) {
    return <Navigate to={getHomeUrl(hotelId)} />;
  }

  return (
    <AppLayout
      footer={isMobile ? <FloatingBottomNav /> : null}
      header={<ChatConversationPageHeader />}
    >
      <ChatWindow conversationId={conversationId} isControlled={true} />
    </AppLayout>
  );
};
