/**
 * @file contains redux actions related to draftsMain state
 */
import {
  DRAFTS__MAIN__CLEAR,
  DRAFTS__MAIN__INIT,
  DRAFTS__MAIN__POPULATE,
  DRAFTS__MAIN__TITLE_SET,
  DRAFTS__MAIN__TOPIC_SAVE,
  DRAFTS__MAIN__TOPIC_SET,
} from '../../constants/actionTypes';
import { ComposeNodeType, ComposeTitle } from '../../containers/compose/props';
import { TemplateFullFragment, Topic } from '../../generated/graphql';

import {
  DraftActionMainClear,
  DraftActionMainInit,
  DraftActionMainPopulate,
  DraftActionMainTitleSet,
  DraftActionMainTopicSave,
  DraftActionMainTopicSet,
} from '../../models/draftMain';

/**
 *
 * CREATE actions
 *
 */

/**
 * Request that a draft is initialized for a specified parent
 *
 * @param parentId The parent to init a draft for (0 for root)
 * @returns        The action itself
 */
export const initDraftRequest = (parentId: string): DraftActionMainInit => ({
  parentId,
  type: DRAFTS__MAIN__INIT,
});

/**
 * Request that a draft's template gets updated
 *
 * @param parentId The draft to set the topic for
 * @param data     Template data
 * @returns        The action itself
 */
export const populateDraftRequest = (
  parentId: ComposeNodeType,
  data: TemplateFullFragment,
): DraftActionMainPopulate => ({
  data,
  parentId,
  type: DRAFTS__MAIN__POPULATE,
});

/**
 * Request that a draft's title gets updated
 *
 * @param parentId The draft to set the title for
 * @param title    The new title
 * @returns        The action itself
 */
export const updateDraftTitleRequest = (
  parentId: string,
  title: ComposeTitle,
): DraftActionMainTitleSet => ({
  parentId,
  title,
  type: DRAFTS__MAIN__TITLE_SET,
});

/**
 * Request that a draft's topic gets updated
 *
 * @param parentId The draft to set the topic for
 * @param topicId  The ID of the topic to put the draft in
 * @returns        The action itself
 */
export const updateDraftTopicRequest = (
  parentId: string,
  topicId: Topic['id'],
): DraftActionMainTopicSet => ({
  parentId,
  topicId,
  type: DRAFTS__MAIN__TOPIC_SET,
});

/**
 * Request that a draft's topic gets saved
 *
 * @param parentId The draft to save the topic for
 * @returns        The action itself
 */
export const saveDraftTopicRequest = (
  parentId: string,
): DraftActionMainTopicSave => ({
  parentId,
  type: DRAFTS__MAIN__TOPIC_SAVE,
});

/**
 * Request that a draft is cleared
 *
 * @param parentId The draft to clear
 * @returns        The action itself
 */
export const clearDraftRequest = (parentId: string): DraftActionMainClear => ({
  parentId,
  type: DRAFTS__MAIN__CLEAR,
});
