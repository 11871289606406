/**
 * @file contains functions used to create paths for use in Route related to the dashboard page
 */
import { ROUTE__DYNAMIC_PREFIX } from '../../constants/routing/routes';

/**
 * Generate a path for the dashboard page
 *
 * @returns The generated path
 */
export const createDashboardPath = (): string => {
  return ROUTE__DYNAMIC_PREFIX;
};
