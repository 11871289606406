/**
 * @file Enum of names for the Web View events
 */

import { AttachmentActionType } from '../../models/attachment';

export enum WebViewEventNames {
  AttachmentAction = 'attachmentAction',
  BackButtonPress = 'backButtonPress',
  CookiesFlush = 'cookiesFlush',
  DeviceInfo = 'deviceInfo',
  DeviceInfoResponse = 'deviceInfoResponse',
  DownloadInfoResponse = 'downloadInfoResponse',
  FirebaseToken = 'firebaseToken',
  FirebaseTokenResponse = 'firebaseTokenResponse',
  NotificationPermission = 'notificationPermission',
  NotificationPermissionResponse = 'notificationPermissionResponse',
}

/**
 * Request data for CookiesFlush event
 * (nothing for now)
 */
export type CookiesFlushRequestPayload = null;

/**
 * Response data for CookiesFlush event
 * (nothing for now)
 */
export type CookiesFlushResponsePayload = never;

/**
 * @todo Finish docs
 * deviceId               - Id of the device
 * hasFirebaseSupport     - Does the device support firebase
 * hasPushSupport         - Does the device support push notifications
 * notificationPermission - Current state of notification permission
 */
export type DeviceInfoResponsePayload = {
  appVersion?: string;
  deviceId: string;
  hasFirebaseSupport: boolean;
  hasPushSupport: boolean;
  notificationPermission: NotificationPermission;
};

/**
 * Response data from downloadInfoResponse Web View event
 * message   - Message regarding download status
 * isSuccess - Whether the download was successful
 * url - Presigned url used for file download
 */
export type DownloadInfoResponsePayload = {
  message: string;
  isSuccess: boolean;
  url: string;
};

/**
 * @todo Finish docs
 * fcmToken - Token for handling notifications
 */
export type FirebaseTokenResponsePayload = {
  fcmToken?: string;
};

/**
 * Response data from notificationPermissionResponse Web View event
 * accepted   - Did the user accept notification request
 * deviceId   - Id of the device
 * deviceName - Name of the device
 * deviceType - Type of the device
 * fcmToken - Token for handling notifications
 */
export type NotificationPermissionResponsePayload = {
  /** @todo Remove accepted in favor of isAccepted in when new native version is released  */
  accepted?: boolean;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  fcmToken: string;
  isAccepted?: boolean;
};

/**
 * Request data for attachment action
 *
 * type - Attachment action type (download/preview)
 *
 * url - Attachment download url
 */
export type AttachmentActionPayload = {
  type: AttachmentActionType;
  url: string;
};

/**
 * All response payloads combined into one type
 */
export type ResponsePayload = {
  cookiesFlush: CookiesFlushResponsePayload;
  deviceInfoResponse: DeviceInfoResponsePayload;
  downloadInfoResponse: DownloadInfoResponsePayload;
  firebaseTokenResponse: FirebaseTokenResponsePayload;
  notificationPermissionResponse: NotificationPermissionResponsePayload;
};

/**
 * All response callbacks combined into one type
 */
type ResponseCallback = (
  response: // | ResponsePayload[WebViewEventNames.CookiesFlush]
  | ResponsePayload[WebViewEventNames.DeviceInfoResponse]
    | ResponsePayload[WebViewEventNames.DownloadInfoResponse]
    | ResponsePayload[WebViewEventNames.FirebaseTokenResponse]
    | ResponsePayload[WebViewEventNames.NotificationPermissionResponse],
) => void;

export type WebViewEvent = {
  callback: ResponseCallback;
  eventName: WebViewEventNames;
};

/**
 * Used for event message data sent to the Native app
 * data      - Data sent to the native app
 * eventName - Name of the event
 */
export type WebViewEventMessage =
  | {
      data: AttachmentActionPayload;
      eventName: WebViewEventNames.AttachmentAction;
    }
  | {
      data: CookiesFlushRequestPayload;
      eventName: WebViewEventNames.CookiesFlush;
    }
  | {
      data?: never;
      eventName: WebViewEventNames.DeviceInfo;
    }
  | {
      data?: never;
      eventName: WebViewEventNames.FirebaseToken;
    }
  | {
      data?: never;
      eventName: WebViewEventNames.NotificationPermission;
    };

/**
 * Used for event response data received from the Native app
 * data      - Data received from the native app
 * eventName - Name of the event
 */
export type WebViewEventResponse =
  | {
      data: ResponsePayload[WebViewEventNames.CookiesFlush];
      eventName: WebViewEventNames.CookiesFlush;
    }
  | {
      data: ResponsePayload[WebViewEventNames.DeviceInfoResponse];
      eventName: WebViewEventNames.DeviceInfoResponse;
    }
  | {
      data: ResponsePayload[WebViewEventNames.FirebaseTokenResponse];
      eventName: WebViewEventNames.FirebaseTokenResponse;
    }
  | {
      data: ResponsePayload[WebViewEventNames.NotificationPermissionResponse];
      eventName: WebViewEventNames.NotificationPermissionResponse;
    };
