/**
 * @file All permissions checks related to updating checklist items
 */

import { MessageCreatorAndAssigned } from '../../../models/message';
import { getIsAssigned, getIsAuthor } from '../messageHelper';

import {
  CHECKLIST__CHECK,
  CHECKLIST__REORDER,
  CHECKLIST__UPDATE,
} from '../permissionsAll';

import { getIsAdmin, hasPermission } from './checklistHelper';

/**
 *
 * UPDATE
 *
 */

/**
 * Check whether the user has permission
 * to mark a checklist item as checked
 * for a new message
 *
 * @returns Whether the user has permission
 */
export const getCanSetStateForNewMessage = (): boolean => {
  // If the user can create a message, they can also check checklist items
  return true;
};

/**
 * Check whether the user has permission
 * to mark a checklist item as checked
 * for an existing message
 *
 * @param message The message to check the permission for
 * @returns       Whether the user has permission
 */
export const getCanSetStateForExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(CHECKLIST__CHECK) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};

/**
 * Check whether the user has permission
 * to mark a checklist item as checked
 * for a new message
 *
 * @returns Whether the user has permission
 */
export const getCanSetTextForNewMessage = (): boolean => {
  // If the user can create a message, they can also check checklist items
  return true;
};

/**
 * Check whether the user has permission
 * to mark a checklist item as checked
 * for an existing message
 *
 * @param message The message to check the permission for
 * @returns       Whether the user has permission
 */
export const getCanSetTextForExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(CHECKLIST__UPDATE) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};

/**
 * Check whether the user has permission
 * to reorder checklist items for a new message
 *
 * @returns Whether the user has permissions
 */
export const getCanReorderItemForNewMessage = (): boolean => {
  // If the user can create a message, they can also reorder checklist items
  return true;
};

/**
 * Check whether the user has permission
 * to reorder checklist items for an existing message
 *
 * @param message The message to check the permission for
 * @returns       Whether the user has permissions
 */
export const getCanReorderItemForExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(CHECKLIST__REORDER) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};
