import React, { FC } from 'react';

import translate from '../../i18n/translate';
import { getCanChangeTargetLanguage } from '../../utils/permissions/user';

import SpinnerDots from '../Common/SpinnerDots';

import Icon from './Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  targetLanguage: string;
};

/**
 * Button used for text translation
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.isDisabled     Whether the button is disabled
 * @param props.isLoading      Whether the button is in the loading state
 * @param props.onClick        Mouse click handler
 * @param props.targetLanguage User target language code
 * @returns                    The component itself
 */
const ButtonTranslate: FC<Props> = ({
  className,
  isDisabled = false,
  isLoading = false,
  onClick,
  targetLanguage,
}) => {
  const label = translate('ARIA__LABEL__TRANSLATE');

  const tooltip = translate(
    getCanChangeTargetLanguage()
      ? 'TRANSLATE__BUTTON'
      : 'TRANSLATE__BUTTON__LIMITED_PERMISSION',
  );

  return (
    <Styled.Button
      aria-label={label}
      className={className}
      isDisabled={isDisabled}
      label={tooltip}
      onClick={onClick}
    >
      {isLoading ? <SpinnerDots /> : <Icon text={targetLanguage} />}
    </Styled.Button>
  );
};

export default ButtonTranslate;
