/**
 * @file contains functions for getting the external urls used in the app
 */
import { SSO_BASE_URL } from '../../config';

/**
 * Creates IAM dashboard url for the user profile
 *
 * @param userEmail Email of the user
 * @returns         IAM url for user profile
 */
export const getProfileExternalUrl = (userEmail: string): string => {
  return `${SSO_BASE_URL}profile/${userEmail}`;
};
