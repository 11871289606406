import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  className?: string;
  color?: string;
  width?: number;
};

/**
 * A single line in skeleton view
 * (it will fill the parent width and have an animation)
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     CSS variable to be used as the background image
 * @param props.width     The line width, as %, in relation to the parent
 * @returns               The component itself
 */
const SkeletonLine: FC<Props> = ({ className, color, width }) => (
  <Styled.Line
    aria-busy="true"
    aria-hidden="true"
    className={className}
    data-color={color}
    data-width={width}
  />
);

export default SkeletonLine;
