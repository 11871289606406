/**
 * @file Optimistically update the cache with new checklist item state
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  ChecklistItemSetStateMutation,
  MessageFullDocument,
  MessageFullQuery,
  MessageFullQueryVariables,
  MutationChecklistCheckItemArgs,
} from '../../../../../generated/graphql';

/**
 * Optimistically reorder checklist items,
 * making it look much more responsive
 *
 * Without this, there would be a delay from the time the user (un)checks the checkbox,
 * as we would be waiting for server response to change the UI
 *
 * @todo                          Debounce requests, looks weird now if the user toggles too fast
 * @param args  The args passed along with the mutation
 * @param cache Current GraphQL cache
 * @returns     Updated message
 */
const checklistSetState: OptimisticMutationResolver<
  MutationChecklistCheckItemArgs,
  ChecklistItemSetStateMutation['checklistCheckItem'] | null
> = (args, cache) => {
  const {
    id: itemId,
    data: { checked },
    messageId,
  } = args;

  const dataQuery = cache.readQuery<
    MessageFullQuery,
    MessageFullQueryVariables
  >({
    query: MessageFullDocument,
    variables: {
      messageId,
    },
  });

  // The message wasn't found in cache
  if (dataQuery?.message === undefined || dataQuery.message === null) {
    return null;
  }

  const { checklist: checklistOld } = dataQuery.message;
  const checklistNew = checklistOld.map(item => ({
    ...item,
    checked: item.id === itemId ? checked : item.checked,
  }));

  return {
    __typename: 'Message',
    checklist: checklistNew,
    id: messageId,
  };
};

export default checklistSetState;
