import React, { FC, useId } from 'react';

import { Reminder } from '../../../generated/graphql';
import { ReminderString } from '../../../models/reminder';
import ButtonListItemDelete from '../../Common/ButtonListItemDelete';
import { getItems, getReminderSelectLabel } from '../helpers';
import * as Styled from '../styled';

type Props = {
  reminder: Reminder & { position: number };
  requestChange: (newReminder: ReminderString) => void;
  requestDelete: () => void;
};

/**
 * Reminders Select
 *
 * @param props               Props passed to the component
 * @param props.reminder      Reminder item
 * @param props.requestChange Request that the reminder is changed
 * @param props.requestDelete Request that the reminder is deleted
 * @returns                   The component itself
 */
const SelectReminder: FC<Props> = ({
  reminder,
  requestChange,
  requestDelete,
}) => {
  const selectReminderId = useId();
  const items = getItems();
  const defaultItem = items.find(
    item => item.value === `${reminder.amount}_${reminder.unit}`,
  );

  return (
    <>
      <Styled.Select
        defaultItem={defaultItem}
        isLabelVisible={false}
        items={items}
        label={getReminderSelectLabel(defaultItem)}
        name="select-reminder"
        onChange={requestChange}
        placeholder=""
        placement="up"
        selectId={selectReminderId}
      />
      <ButtonListItemDelete onClick={requestDelete} />
    </>
  );
};

export default SelectReminder;
