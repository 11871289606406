/**
 * @file Helper functions for the settings page mobile header
 */

import { Button, ButtonProps } from '@mui/material';
import React from 'react';

import { Template } from '../../../../generated/graphql';
import { createReservationsPath } from '../../../../routes/paths/reservations';
import {
  createSettingsGroupsPath,
  createSettingsLabelsPath,
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsEmailSinglePath,
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
  createSettingsPath,
  createSettingsPeoplePath,
  createSettingsTemplateEditPath,
  createSettingsTemplatesPath,
  createSettingsTemplatesRecurringPath,
  createSettingsTopicsPath,
} from '../../../../routes/paths/settings';
import { getMoreUrl } from '../../../../routes/urls/more';

import {
  getSettingsNotificationsEmailUrl,
  getSettingsNotificationsPushUrl,
  getSettingsTemplatesUrl,
  getSettingsUrl,
} from '../../../../routes/urls/settings';

type BackCallback = (
  hotelId: string | null,
  isTablet: boolean,
  templateId?: Template['id'] | null,
) => string;

const backUrlMapping = new Map<string | null, BackCallback>([
  [createSettingsGroupsPath(), getSettingsUrl],
  [createSettingsLabelsPath(), getSettingsUrl],
  [createSettingsNotificationsEmailPath(), getSettingsUrl],
  [
    createSettingsNotificationsEmailSinglePath(),
    getSettingsNotificationsEmailUrl,
  ],
  [createSettingsNotificationsPushPath(), getSettingsUrl],
  [
    createSettingsNotificationsPushSinglePath(),
    getSettingsNotificationsPushUrl,
  ],
  [createSettingsPath(), getMoreUrl],
  [createSettingsPeoplePath(), getSettingsUrl],
  [createSettingsTemplateEditPath(), getSettingsTemplatesUrl],
  [createSettingsTemplatesPath(), getSettingsUrl],
  [createSettingsTemplatesRecurringPath(), getSettingsTemplatesUrl],
  [createSettingsTopicsPath(), getSettingsUrl],
]);

/**
 * In order to not use navigate(-1), we need to know where to go back to.
 *
 * @param currentPath The current path
 * @param hotelId     Hotel ID
 * @param isTablet    Whether the device is a tablet
 * @returns           The URL to go back to
 */
export const getSettingsBackUrl = (
  currentPath: string | null,
  hotelId: string | null,
  isTablet: boolean,
): string => {
  return (
    backUrlMapping.get(currentPath)?.(hotelId, isTablet) ?? getMoreUrl(hotelId)
  );
};

/**
 * Get the proper content that we want to render on the right side of the header
 * for the current page. In most cases it's an action button which can vary between
 * different pages
 *
 * @param currentPath The current path
 * @param buttonProps The button props to render on the right side of the header
 *
 * @returns           The content to render on the right side of the header
 */
export const getContentRight = (
  currentPath: string | null,
  buttonProps: ButtonProps | null = null,
) => {
  if (buttonProps === null || currentPath !== createReservationsPath()) {
    return null;
  }

  return <Button {...buttonProps} color="secondary" variant="text" />;
};
