/**
 * @file Filter passed members
 */
import { User } from '../../generated/graphql';

import filter, { Options } from '../filter';

type Data = Pick<User, 'id' | 'firstName' | 'lastName' | 'status'>;

const options: Options<Data> = {
  keys: ['firstName', 'lastName'],
};

/**
 * Filter passed members
 *
 * First return all selected ones
 * (these are visible no matter the search)
 *
 * And then return the rest, filtered by what's typed into search
 *
 * @param search     What the user typed in search box
 * @param membersAll All the members available
 * @param userIds    IDs of selected members
 * @returns          Filtered array of members
 */
export const searchMembers = (
  search: string,
  membersAll: Data[],
  userIds: Set<User['id']>,
): Data[] => {
  // If nothing is searched, then just return everything
  if (search.trim().length === 0) {
    return membersAll;
  }

  const membersAssigned = membersAll.filter(user => userIds.has(user.id));
  const membersUnassigned = membersAll.filter(user => !userIds.has(user.id));
  const membersSearched = filter(membersUnassigned, search, options);

  return [...membersAssigned, ...membersSearched];
};

/**
 * Filter passed members
 * Only show assigned ones
 *
 * @param search     What the user typed in search box
 * @param membersAll All the members available
 * @param userIds    IDs of selected members
 * @returns          Filtered array of members
 */
export const searchGroupMembers = (
  search: string,
  membersAll: Data[],
  userIds: Set<User['id']>,
): Data[] => {
  const membersAssigned = membersAll.filter(user => userIds.has(user.id));
  // If nothing is searched, then just return everything
  if (search.trim().length === 0) {
    return membersAssigned;
  }

  return filter(membersAssigned, search, options);
};
