/**
 * @file Helper functions for hooks related to messages
 */

import { MessageFeed } from '../../../models/message';

/**
 * Get whether whether the messages request is the initial one
 *
 * @param nodes                       MessageFeed nodes
 * @param isFeedFetching              Whether the messages query is fetching
 * @param isMessageAccessibleFetching Whether the message full query is fetching
 * @returns                           True if data is fetching for the first time, otherwise false
 */
export const getIsInitialLoad = (
  nodes: MessageFeed[],
  isFeedFetching: boolean,
  isMessageAccessibleFetching: boolean,
) => {
  return nodes.length === 0 && (isFeedFetching || isMessageAccessibleFetching);
};
