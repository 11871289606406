/**
 * @file Get the current page for search results
 */
import { useParams } from 'react-router-dom';

import { SearchParams } from '../../../models/pageParams';

/**
 * Get the current page for search results
 *
 * @returns Current page (1 if param not present)
 */
const useParamSearchPage = (): number | null => {
  const { page } = useParams<SearchParams>();

  if (page === undefined) {
    return null;
  }

  const pageNumeric = parseInt(page, 10);

  return Number.isNaN(pageNumeric) ? null : pageNumeric;
};

export default useParamSearchPage;
