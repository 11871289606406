/**
 * @file Contains hook that shows the message un-archive modal if necessary.
 */

import { useEffect } from 'react';

import { MODAL__POST_UNARCHIVE } from '../../../constants/routeModalParams';
import { Message } from '../../../generated/graphql';
import useMessageAccessible from '../../../hooks/message/useMessageAccessible';
import { useModal } from '../useModal';

/**
 * Checks whether the user has opened an archived message link (messageFull) and
 * if that's the case, it'll open the message un-archive modal.
 *
 * @param messageId The id of the message we want to access.
 */
export const useMessageUnArchiveCheck = (messageId: Message['id'] | null) => {
  const { openModal } = useModal();
  const [message] = useMessageAccessible(messageId);

  const { isArchived } = message ?? {};

  /**
   * In case the user accessed a message that is archived,
   * we need to open the un-archive modal.
   */
  useEffect(() => {
    if (isArchived === true && messageId !== null) {
      openModal(MODAL__POST_UNARCHIVE, { messageId });
    }

    // We need to exclude openModal, because it will recreate when the location
    // changes and open the modal again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchived, messageId]);
};
