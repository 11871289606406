import { Divider } from '@mui/material';
import React, { FC, useMemo } from 'react';

import SmartViewsContainer from '../../../../containers/smartViews';
import TopicsContainer from '../../../../containers/topics';
import { ChatList } from '../../../../integrations/roqChat/ChatList';

import HotelNavigation from '../../../Navigation/Hotels';
import SettingsNavigation from '../../../Navigation/Settings';

import { CollapsibleItem } from '../types';

import DrawerUpperContent from './DrawerUpperContent';
import * as Styled from './styled';

const mappedContent: Record<CollapsibleItem, FC | null> = {
  // Chat is collapsible but the content is always rendered, so here we avoid the duplication
  chat: ChatList,
  hotels: HotelNavigation,
  settings: SettingsNavigation,
  smartViews: SmartViewsContainer,
  topics: TopicsContainer,
};

export type Props = {
  id: CollapsibleItem;
  isOpen: boolean;
};

/**
 * Collapsible content of navigation displaying the different content based on
 * selected tab for tabs that have content
 *
 * @param props        Props for drawer content
 * @param props.id     Id of collapsible tab currently selected
 * @param props.isOpen Whether the drawer is open and has content
 * @returns            The component itself
 */
const DrawerContent: FC<Props> = ({ id, isOpen }) => {
  const Component = useMemo(
    () => (isOpen === true ? mappedContent[id] : null),
    [id, isOpen],
  );

  return (
    <Styled.Wrapper hidden={isOpen === false}>
      <Styled.TopContentWrapper>
        {isOpen === true && <DrawerUpperContent />}
        <Divider />
      </Styled.TopContentWrapper>
      {Component ? (
        <Styled.StyledNavigation>
          <Component />
        </Styled.StyledNavigation>
      ) : null}
    </Styled.Wrapper>
  );
};

export default DrawerContent;
