/**
 * @file helper functions for ModalPageSheet component
 */

import translate from '../../../i18n/translate';
import { NAMES } from '../Icon';

import { GetSheetModeOnMoveEndInfo, PageSheetMode } from './types';

/**
 * Get properties for the drag button to show the proper
 * content for each page sheet mode
 *
 * @param mode Current page sheet mode
 * @returns    Object containing labels and icon name
 */
export const getDragButtonPropertiesByMode = (mode: PageSheetMode) => {
  const buttonDragLabel = translate(
    mode === 'open' ? 'GENERAL__CLOSE' : 'GENERAL__OPEN',
  );

  const buttonFullScreenIcon =
    mode === 'full'
      ? NAMES.GENERAL__FULL_SCREEN_OFF
      : NAMES.GENERAL__FULL_SCREEN_ON;

  const buttonFullScreenLabel = translate(
    mode === 'full' ? 'MODAL__FULL_SCREEN__EXIT' : 'MODAL__FULL_SCREEN__ENTER',
  );

  return {
    buttonDragLabel,
    buttonFullScreenIcon,
    buttonFullScreenLabel,
  };
};

/**
 * Get whether the sheet should be closed in the move end event handler
 *
 * @param info                 Information about the current state of the sheet
 * @param info.mode            Current mode the sheet is in
 * @param info.heightAnimated  Height used for move animation
 * @param info.heightByModeMap Fixed height map for all sheet modes
 * @returns                    Whether the sheet should be closed
 */
const getShouldCloseSheet = ({
  mode,
  heightAnimated,
  heightByModeMap,
}: GetSheetModeOnMoveEndInfo) => {
  // `heightModeOpen * 0.9` because the move trigger is triggered when clicking
  // the resize button for full height mode, so we make sure that the user
  // is dragging the sheet down to close it.
  return (
    mode !== 'closed' &&
    heightAnimated > 0 &&
    heightAnimated <= heightByModeMap.open * 0.9
  );
};

/**
 * Get whether the sheet should be full open in the move end event handler
 *
 * @param info                 Information about the current state of the sheet
 * @param info.mode            Current mode the sheet is in
 * @param info.heightAnimated  Height used for move animation
 * @param info.heightByModeMap Fixed height map for all sheet modes
 * @returns                    Whether the sheet should be full open
 */
const getShouldFullOpenSheet = ({
  mode,
  heightAnimated,
  heightByModeMap,
}: GetSheetModeOnMoveEndInfo) => {
  // If the user drags the sheet to this height, it will open in full mode.
  const fullModeTransitionThreshold = heightByModeMap.open * 1.1;

  return mode !== 'full' && heightAnimated >= fullModeTransitionThreshold;
};

/**
 * Get whether the sheet should be open in the move end event handler
 *
 * @param info                 Information about the current state of the sheet
 * @param info.mode            Current mode the sheet is in
 * @param info.heightAnimated  Height used for move animation
 * @param info.heightByModeMap Fixed height map for all sheet modes
 * @returns                    Whether the sheet should be open
 */
const getShouldOpenSheet = ({
  mode,
  heightAnimated,
  heightByModeMap,
}: GetSheetModeOnMoveEndInfo) => {
  // If the user drags the sheet up to this height, it will open.
  const transitionClosedToOpenThreshold = heightByModeMap.closed * 1.5;

  if (mode === 'open') {
    return false;
  }

  return (
    heightAnimated > transitionClosedToOpenThreshold &&
    heightAnimated < heightByModeMap.full
  );
};

/**
 * Get the mode of the sheet when the user stops dragging
 *
 * @param info                 Information about the current state of the sheet
 * @param info.mode            Current mode the sheet is in
 * @param info.heightAnimated  Height used for move animation
 * @param info.heightByModeMap Fixed height map for all sheet modes
 * @returns                    The next mode for the sheet if changed, otherwise null
 */
export const getSheetModeOnMoveEnd = (
  info: GetSheetModeOnMoveEndInfo,
): PageSheetMode | null => {
  const shouldClose = getShouldCloseSheet(info);

  if (shouldClose) {
    return 'closed';
  }

  const shouldFull = getShouldFullOpenSheet(info);

  if (shouldFull) {
    return 'full';
  }

  const shouldOpen = getShouldOpenSheet(info);

  if (shouldOpen) {
    return 'open';
  }

  // The sheet mode does not need to be updated
  return info.mode;
};
