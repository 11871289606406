/**
 * @file Contains hook for observing whether an element is overflowing the container
 */

import { RefObject, useLayoutEffect, useState } from 'react';

/**
 * Hook for observing whether an element is overflowing the container
 *
 * @param ref Reference to the element to observe
 * @returns   Whether the element is overflowing the container
 */
const useIsOverflowed = (ref: RefObject<HTMLElement | null>): boolean => {
  const [isOverflowed, setIsOverflowed] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current === null) {
      return;
    }

    /**
     * Triggers the overflow update
     */
    const trigger = () => {
      const { clientHeight, scrollHeight } = current;

      setIsOverflowed(scrollHeight > clientHeight);
    };

    const observer = new ResizeObserver(trigger);

    trigger();

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [ref]);

  return isOverflowed;
};

export default useIsOverflowed;
