/**
 * @file Use ROQ locale hook
 */

import { Locale } from '../../generated/graphql';
import useLocale from '../../hooks/useLocale';
import { getLanguageCode } from '../../utils/user/language';

/**
 * All locale languages that we support, in format for ROQ chat.
 */
export type ROQLocale = 'de-DE' | 'en-US' | 'fr-FR' | 'it-IT' | 'nl-NL';

/**
 * Map our locale language codes to ROQ codes.
 */
const localeMap: Record<Locale, ROQLocale> = {
  [Locale.De]: 'de-DE',
  [Locale.En]: 'en-US',
  [Locale.Fr]: 'fr-FR',
  [Locale.It]: 'it-IT',
  [Locale.Nl]: 'nl-NL',
};

/**
 * Use ROQ locale hook
 *
 * @returns The locale formatted for ROQ chat
 */
export const useRoqLocale = (): ROQLocale => {
  const language = useLocale() ?? getLanguageCode();

  return localeMap[language];
};
