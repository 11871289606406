import { styled } from 'styled-components';

type RingProps = {
  'data-color-border': string;
  'data-color-track': string;
  'data-size': number;
};

/**
 * Get which background color to use
 *
 * @param props Props passed to the component
 * @returns     The value to use for background color
 */
const getBackgroundColor = (props: RingProps): string => {
  return `${props['data-color-border']} transparent transparent transparent`;
};

export const Ring = styled.div<RingProps>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  border-radius: 50%;
  height: ${props => `${props['data-size']}px`};
  min-width: ${props => `${props['data-size']}px`};
  position: relative;
  width: ${props => `${props['data-size']}px`};

  /* Ring track */
  & span {
    background-color: transparent;
    border: ${props => `3px solid ${props['data-color-track']}`};
    border-radius: 50%;
    display: block;
    height: ${props => `${props['data-size']}px`};
    position: absolute;
    width: ${props => `${props['data-size']}px`};
  }

  /* Ring spinning part */
  & div {
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid;
    border-color: ${getBackgroundColor};
    border-radius: 50%;
    display: block;
    height: ${props => `${props['data-size']}px`};
    position: absolute;
    width: ${props => `${props['data-size']}px`};
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
