/**
 * @file contains capitalize string function
 */

/**
 * Capitalize the first letter of the passed string
 *
 * @param value The value to capitalize
 * @returns     The capitalized string
 */
const capitalize = (value: string): string => {
  if (value.length < 2) {
    return value.toUpperCase();
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export default capitalize;
