/**
 * @file Get whether the user created their first message
 */

import { useQuery } from 'urql';

import { MeDocument } from '../../../../../generated/graphql';

/**
 * Get whether the user created their first message
 *
 * @returns Whether it's true (null if fetching/error)
 */
const useHasMessages = (): boolean | null => {
  const [{ data }] = useQuery({ query: MeDocument, variables: {} });

  return data?.me.hasMessages ?? null;
};

export default useHasMessages;
