/**
 * @file After successful message status update mutation, update the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MessageStatusUpdateMutation,
  MessageStatusUpdateMutationVariables,
} from '../../../../../generated/graphql';
import { messageStatusUpdate } from '../utils/updaters/messages/messageStatusUpdate';

/**
 * After successful message status update mutation, update the status of the message
 * and tasks
 *
 * @param _result The result of the mutation (unused)
 * @param args    The arguments passed along with the mutation
 * @param cache   Current GraphQL cache
 */
const messageStatusUpdateMutation: UpdateResolver<
  MessageStatusUpdateMutation,
  MessageStatusUpdateMutationVariables
> = (_result, args, cache) => {
  messageStatusUpdate(args, cache);
};

export default messageStatusUpdateMutation;
