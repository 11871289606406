/**
 * @file Hook that parses search params of the url
 */

import { useLocation } from 'react-router-dom';

/**
 * Hook that parses search params of the url
 * and returns them.
 *
 * @returns URLSearchParams object
 */
const useSearchParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export default useSearchParams;
