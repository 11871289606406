import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import SettingsHeaderDesktop from './desktop';
import SettingsHeaderMobile from './mobile';

/**
 * Header component for the settings page
 *
 * @returns The component itself
 */
const SettingsHeader: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <SettingsHeaderMobile />;
  }

  return <SettingsHeaderDesktop />;
};

export default SettingsHeader;
