import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { TopicsListQuery } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useIsTablet from '../../../hooks/useIsTablet';
import { getNavbarTopicUrl } from '../../../routes/urls/topic';
import LoadingTopics from '../../Loading/Topics';

import DrawerEntry from '../../MUI/Drawer/DrawerContent/DrawerEntry';

import * as Styled from '../styled';

export type Props = {
  isLoading: boolean;
  topics: TopicsListQuery['topics'];
};

/**
 * List of items for the Topics navigation content
 *
 * @param props           Props passed to the component
 * @param props.isLoading Whether the topics are being fetched
 * @param props.topics    List of topics or tasks
 * @returns               FC
 */
const TopicsNavigation: FC<Props> = ({ isLoading, topics }) => {
  const activeTopicId = useParamTopicId();
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();

  if (isLoading === true) {
    return <LoadingTopics />;
  }

  return (
    <Styled.ScrollableList>
      {topics.map(({ id, title, unreadMessages }) => {
        const isSelected = activeTopicId === id;

        return (
          <DrawerEntry
            href={getNavbarTopicUrl(hotelId, isTablet, location, id)}
            isSelected={isSelected === true}
            key={id}
            label={title}
            notificationsNumber={unreadMessages}
          />
        );
      })}
    </Styled.ScrollableList>
  );
};

export default TopicsNavigation;
