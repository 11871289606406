import React, { FC } from 'react';

import { ILLUSTRATION__TASK } from '../../../constants/illustrations';
import { KpiSingle as KpiSingleType } from '../../../models/dashboard';

import Illustration from '../../Common/Illustration';
import KpiSingle from '../KpiSingle';

import * as Styled from './styled';

export type Props = {
  tasks: KpiSingleType[];
};

const ILLUSTRATION_HEIGHT = 200;
const ILLUSTRATION_WIDTH = 215;

/**
 * Desktop version of UI for kpi type TASK
 *
 * @param props       Props passed to the component
 * @param props.tasks Tasks to be rendered
 * @returns           The component itself
 */
const TasksDesktop: FC<Props> = ({ tasks }) => {
  return (
    <Styled.TasksWrapper>
      <Styled.TaskDataWrapper>
        {tasks.map(({ amount, label, url }) => {
          return (
            <Styled.KpiWrapper key={label}>
              <KpiSingle amount={amount} label={label} url={url} />
            </Styled.KpiWrapper>
          );
        })}
      </Styled.TaskDataWrapper>
      <Styled.TaskImageWrapper>
        <Illustration
          height={ILLUSTRATION_HEIGHT}
          name={ILLUSTRATION__TASK}
          width={ILLUSTRATION_WIDTH}
        />
      </Styled.TaskImageWrapper>
    </Styled.TasksWrapper>
  );
};

export default TasksDesktop;
