import React, { Children, FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode[] | ReactNode;
  className?: string;
};

/**
 * Common Ribbon used in MessageSingle and AttachmentSingle overlay
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.children  Children passed to the component
 * @returns               The component itself
 */
const Ribbon: FC<Props> = ({ className, children }) => {
  return (
    <Styled.List className={className} role="group">
      {Children.toArray(children).map((listItem, index) => {
        // These items will never change in order
        // eslint-disable-next-line react/no-array-index-key
        return <li key={index}>{listItem}</li>;
      })}
    </Styled.List>
  );
};

export default Ribbon;
