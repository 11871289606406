/**
 * @file contains helper function for the FeedFooter component
 */
import { Topic } from '../../../../../generated/graphql';

/**
 * Get whether the create button should be shown
 *
 * @param canCreateMessage Whether the current user can create a message
 * @param topicId          Current topic ID
 * @returns                Whether the create button should be shown
 */
export const getShouldShowButtonCreate = (
  canCreateMessage: boolean,
  topicId: Topic['id'] | null,
): boolean => {
  return canCreateMessage && topicId !== null;
};
