/**
 * @file Helpers from the settings header component
 */

import { ComponentProps } from 'react';
import { Frequency } from 'rrule';

import HeaderSegmentedButtons from '../../../components/Header/SegmentedButtons';
import { Hotel, Template } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { RecurringFrequencyParam } from '../../../models/pageParams';
import {
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsPushPath,
  createSettingsTemplatesRecurringPath,
} from '../../../routes/paths/settings';
import {
  getSettingsNotificationsEmailUrl,
  getSettingsNotificationsPushUrl,
  getSettingsTemplatesRecurringUrl,
} from '../../../routes/urls/settings';

const emailPath = createSettingsNotificationsEmailPath();
const pushPath = createSettingsNotificationsPushPath();
const recurringPath = createSettingsTemplatesRecurringPath();

type Buttons = ComponentProps<typeof HeaderSegmentedButtons>['buttons'];

/**
 * Get the buttons props needed to shown the buttons on the notification settings page
 *
 * @param currentPath The current path
 * @param hotelId     The current hotel id
 * @param isTablet    Whether the user is on a tablet
 * @returns           Button props that can be passed to the Header component
 */
export const getNotificationSettingsSegmentedButtons = (
  currentPath: string | null,
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): Buttons => {
  const labelPush = translate('SETTINGS__NOTIFICATIONS__PUSH__BUTTON');
  const labelEmail = translate('SETTINGS__NOTIFICATIONS__EMAIL__BUTTON');

  const buttons = [
    {
      children: labelPush,
      isActive: currentPath === pushPath,
      to: getSettingsNotificationsPushUrl(hotelId, isTablet),
    },
    {
      children: labelEmail,
      isActive: currentPath === emailPath,
      to: getSettingsNotificationsEmailUrl(hotelId, isTablet),
    },
  ];

  return buttons;
};

/**
 * Get the buttons props needed to shown the correct button on the settings page
 *
 * @param frequency  The current recurring frequency
 * @param hotelId    The current hotel id
 * @param isTablet   Whether the user is on a tablet
 * @param templateId The current template id
 * @returns          Button props that can be passed to the Header component
 */
export const getTemplateRecurringSettingsSegmentedButtons = (
  frequency: RecurringFrequencyParam | null,
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
  templateId: Template['id'] | null,
): Buttons => {
  const labelDaily = translate('RRULE__TYPE__DAILY');
  const labelWeekly = translate('RRULE__TYPE__WEEKLY');
  const labelMonthly = translate('RRULE__TYPE__MONTHLY');

  const buttons = [
    {
      children: labelDaily,
      isActive: frequency === 'daily',
      to: getSettingsTemplatesRecurringUrl(
        Frequency.DAILY,
        hotelId,
        isTablet,
        templateId,
      ),
    },
    {
      children: labelWeekly,
      isActive: frequency === 'weekly',
      to: getSettingsTemplatesRecurringUrl(
        Frequency.WEEKLY,
        hotelId,
        isTablet,
        templateId,
      ),
    },
    {
      children: labelMonthly,
      isActive: frequency === 'monthly',
      to: getSettingsTemplatesRecurringUrl(
        Frequency.MONTHLY,
        hotelId,
        isTablet,
        templateId,
      ),
    },
  ];

  return buttons;
};

/**
 * Returns the appropriate button properties based on the current settings page context.
 * This is used to dynamically configure which segmented buttons to display on the settings page header.
 *
 * @param params                    The parameters object
 * @param params.currentPath        The current path
 * @param params.hotelId            The current path hotel id
 * @param params.isTablet           Whether the user is on a tablet
 * @param params.recurringFrequency The current path recurring frequency
 * @param params.templateId         The current path template id
 * @returns                         Button props that can be passed to the Header component
 */
export const getSettingsSegmentedButtons = ({
  currentPath,
  hotelId,
  isTablet,
  recurringFrequency,
  templateId,
}: {
  currentPath: string | null;
  hotelId: Hotel['id'] | null;
  isTablet: boolean;
  recurringFrequency: RecurringFrequencyParam | null;
  templateId: Template['id'] | null;
}): Buttons => {
  if (currentPath === emailPath || currentPath === pushPath) {
    return getNotificationSettingsSegmentedButtons(
      currentPath,
      hotelId,
      isTablet,
    );
  }

  if (currentPath === recurringPath) {
    return getTemplateRecurringSettingsSegmentedButtons(
      recurringFrequency,
      hotelId,
      isTablet,
      templateId,
    );
  }

  return [];
};
