/**
 * @file All urql cache exchanges
 */

import { cacheExchange } from '@urql/exchange-graphcache';
import { Exchange } from 'urql';

import keys from './keys';
import optimistic from './optimistic';
import resolvers from './resolvers';
import updates from './updates';

const exchange: Exchange = cacheExchange({
  keys,
  optimistic,
  resolvers,
  updates,
});

export default exchange;
