import React, { FC } from 'react';

import { DashboardKpiType } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { CardType, FormattedData } from '../../../models/dashboard';
import DashboardCard from '../Card';
import { KpiSingleCard } from '../styled';

import * as Styled from './styled';

export type Props = {
  data: FormattedData;
};

export const TYPES: Record<DashboardKpiType, CardType> = {
  CONCIERGE: 'REQUEST_ANSWERED',
  REQUEST: 'REQUEST_UNANSWERED',
  TASK: 'REQUEST',
};

/**
 * Dashboard mobile
 *
 * @param props      Props passed to the component
 * @param props.data KPIs data from which UI is mapped
 * @returns          The component itself
 */
const ContentMobile: FC<Props> = ({ data }) => {
  return (
    <>
      <Styled.PageTitle>{translate('DASHBOARD__OVERVIEW')}</Styled.PageTitle>
      <Styled.PageDescription>
        {translate('DASHBOARD__DESCRIPTION')}
      </Styled.PageDescription>
      <Styled.CardsWrapper>
        {Object.entries(data).map(([kpiType, kpiData]) => {
          if (kpiData.length === 0) {
            return null;
          }

          return (
            <li key={kpiType}>
              <DashboardCard type={TYPES[kpiType as DashboardKpiType]}>
                {kpiData.map(singleKpi => {
                  return <KpiSingleCard {...singleKpi} key={singleKpi.label} />;
                })}
              </DashboardCard>
            </li>
          );
        })}
      </Styled.CardsWrapper>
    </>
  );
};

export default ContentMobile;
