/**
 * @file Creates functions that handle moving of the focus for the select
 */

import { RefObject, useState } from 'react';

type UseFocusHandlersParams = {
  buttonRef: RefObject<HTMLDivElement>;
  itemCount: number;
  itemRefs: RefObject<HTMLLIElement>[];
};

type UseFocusHandlersReturn = {
  currentFocusIndex: number;
  focusHandlers: FocusHandlers;
};

export type FocusHandlers = {
  toItem: (itemIndex: number) => void;
  toLastItem: () => void;
  toFirstItem: () => void;
  toButton: () => void;
};

/**
 * Creates functions that handle moving of the focus for the select
 *
 * @param args           Args passed to the hook
 * @param args.buttonRef React ref for the button that opens the select
 * @param args.itemCount Number of items in the list
 * @param args.itemRefs  Refs for all the list option items
 * @returns              A tuple of the currentFocusIndex and focus handlers methods
 */
const useFocusHandlers = ({
  buttonRef,
  itemCount,
  itemRefs,
}: UseFocusHandlersParams): UseFocusHandlersReturn => {
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(-1);

  /**
   * Focuses an item given its index
   *
   * @param itemIndex Index of the item we want to move focus to
   */
  const toItem = (itemIndex: number) => {
    if (itemRefs.length > 0) {
      setCurrentFocusIndex(itemIndex);
      itemRefs[itemIndex]?.current?.focus();
    }
  };

  /**
   * Focuses the first item in the list
   */
  const toFirstItem = () => {
    toItem(0);
  };

  /**
   * Focuses the last item in the list
   */
  const toLastItem = () => {
    toItem(itemCount - 1);
  };

  /**
   * Focuses the button
   */
  const toButton = () => {
    setCurrentFocusIndex(-1);
    buttonRef.current?.focus();
  };

  const focusHandlers = {
    toButton,
    toFirstItem,
    toItem,
    toLastItem,
  };

  return { currentFocusIndex, focusHandlers };
};

export default useFocusHandlers;
