/**
 * @file Redux mappers for composeMetaTitle container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import { updateDraftTitleRequest } from '../../../store/actions/draftsMain';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  requestDraftTitleUpdate: title => {
    dispatch(updateDraftTitleRequest(nodeType, title));
  },
});

/**
 * Map Redux app state to component props
 *
 * @param appState          Current app state
 * @param appState.drafts   Drafts in the state
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ drafts }, { nodeType }) => ({
  title: drafts.get(nodeType)?.title ?? '',
});
