import { styled } from 'styled-components';

import CommonLinkIconWithText from '../../Common/LinkIconWithText';
import CommonMessageSingle from '../../Common/MessageSingle';
import CommonMessageSectionChecklist from '../MessageSection/MessageSectionChecklist';
import CommonMessageSectionStatus from '../MessageSectionStatus';

// Message section
export const MessageSingle = styled(CommonMessageSingle)`
  padding: var(--spacing-medium) var(--spacing-medium) 0;
`;

// Checklist section
export const MessageSectionChecklist = styled(CommonMessageSectionChecklist)`
  border-top: 0;
`;

// Status section
export const MessageSectionStatus = styled(CommonMessageSectionStatus)`
  border-top: 0;
`;

// Edit Title/Description Link
export const LinkIconWithText = styled(CommonLinkIconWithText)`
  margin: 0 var(--spacing-medium);
  padding-bottom: var(--spacing-medium);
`;
