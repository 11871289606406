/**
 * @file Get topicId if accessible, first one if not, or null if none available
 */

import { useQuery } from 'urql';

import { Maybe, Topic, TopicsDocument } from '../../generated/graphql';
import getTopicAccessible from '../../utils/topics/getTopicAccessible';

type TopicID = Maybe<Topic['id']>;

/**
 * Get topicId if accessible, first one if not, or null if none available
 *
 * @param topicId The ID of the topic we want to check
 * @returns       The ID of the accessible topic
 */
const useTopicAccessible = (topicId: TopicID): [TopicID, boolean] => {
  const [{ data, error, fetching }] = useQuery({
    pause: topicId === null,
    query: TopicsDocument,
    variables: {},
  });

  if (error) {
    return [null, fetching];
  }

  return [getTopicAccessible(data, topicId), fetching];
};
export default useTopicAccessible;
