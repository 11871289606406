import React, { FC } from 'react';

import { State, Text } from '../../../../models/checklist';
import ButtonListItemDelete from '../../ButtonListItemDelete';

import Checkbox from '../../Checkbox';

import * as Styled from './styled';

export type Props = {
  isChecked: State;
  requestDelete: (() => void) | null;
  requestSetState: ((state: State) => void) | null;
  requestSetText: ((text: Text) => void) | null;
  text: Text;
};

/**
 * A single checklist item
 *
 * @param props                 Props passed to the component
 * @param props.isChecked       Whether the item is checked
 * @param props.requestDelete   Request that the current item is removed
 * @param props.requestSetState Request that the current item is (un)checked in the state
 * @param props.text            Item text
 * @returns                     The component itself
 */
const ChecklistItem: FC<Props> = ({
  isChecked,
  requestDelete,
  requestSetState,
  text,
}) => {
  const disabled = requestSetState === null;
  return (
    <Styled.Wrapper aria-disabled={disabled}>
      <Styled.Label>
        <Checkbox
          colorChecked={'var(--color-primary-light)'}
          isChecked={isChecked}
          isDisabled={disabled}
          onChange={requestSetState}
        />
        <Styled.Text>{text}</Styled.Text>
      </Styled.Label>

      {requestDelete !== null && (
        <ButtonListItemDelete onClick={requestDelete} />
      )}
    </Styled.Wrapper>
  );
};

export default ChecklistItem;
