/**
 * @file contains the draftsMain reducer function
 */

import {
  DRAFTS__MAIN__CLEAR,
  DRAFTS__MAIN__INIT,
  DRAFTS__MAIN__POPULATE,
  DRAFTS__MAIN__TITLE_SET,
  DRAFTS__MAIN__TOPIC_SAVE,
  DRAFTS__MAIN__TOPIC_SET,
} from '../../constants/actionTypes';

import { DraftDataFormatted, DraftsState } from '../../models/draft';
import {
  DraftActionMain,
  DraftActionMainClear,
  DraftActionMainInit,
  DraftActionMainPopulate,
  DraftActionMainTitleSet,
  DraftActionMainTopicSave,
  DraftActionMainTopicSet,
} from '../../models/draftMain';
import getDueDateFromOffset from '../../utils/date/getDueDateFromOffset';
import stateDraftsClone from '../../utils/stateDraftsClone';
import stateDraftsInitDraft from '../../utils/stateDraftsInitDraft';
import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionMain) => DraftsState;

/**
 *
 * CREATE callbacks
 *
 */

/**
 * A request was made to create a new draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onInitDraftRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainInit,
): DraftsState => {
  const { parentId } = action;
  const stateNext = stateDraftsClone(stateCurrent);

  // Only create a draft if parentId doesn't have one already
  // Otherwise, the data will be overwritten on mobile <-> desktop switch
  if (stateNext.has(parentId) === false) {
    const draft = stateDraftsInitDraft(parentId);
    stateNext.set(parentId, draft);
  }

  return stateNext;
};

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to populate draft
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state to reflect this
 */
const onPopulateDraftRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainPopulate,
): DraftsState => {
  const {
    assignedGroups,
    assignedMembers,
    checklist,
    dueDateOffset,
    id,
    labels,
    reminders,
    text,
    title,
    topic,
  } = action.data;

  const checklistWithPosition = checklist.map((item, index) => ({
    ...item,
    position: index,
  }));
  const dueDate = getDueDateFromOffset(dueDateOffset ?? null);
  const groupsSet = new Set(assignedGroups.map(group => group.id));
  const labelsSet = new Set(labels.map(label => label.id));
  const membersSet = new Set(assignedMembers.map(member => member.id));
  // Removes __typename from the object
  const dueDateOffsetFormatted = dueDateOffset
    ? { amount: dueDateOffset.amount, unit: dueDateOffset.unit }
    : null;

  // Removes __typename from the object
  const remindersFormatted = reminders.map(reminder => ({
    amount: reminder.amount,
    unit: reminder.unit,
  }));

  const draft: DraftDataFormatted = {
    ...stateDraftsInitDraft(action.parentId),
    assigneeGroupsCurrent: groupsSet,
    assigneeGroupsSaved: groupsSet,
    assigneeMembersCurrent: membersSet,
    assigneeMembersSaved: membersSet,
    checklistCurrent: checklistWithPosition,
    checklistSaved: checklistWithPosition,
    dateDueCurrent: dueDate,
    dateDueSaved: dueDate,
    labelsCurrent: labelsSet,
    labelsSaved: labelsSet,
    nodeId: id,
    offsetCurrent: dueDateOffsetFormatted,
    offsetSaved: dueDateOffsetFormatted,
    remindersCurrent: remindersFormatted,
    remindersSaved: remindersFormatted,
    templateCurrent: id,
    templateSaved: id,
    templateTopicCurrent: topic.id,
    templateTopicSaved: topic.id,
    text: text.trim().length === 0 ? '' : text,
    title: title.trim().length === 0 ? '' : title,
  };

  const stateNext = stateDraftsClone(stateCurrent);
  stateNext.set(action.parentId, draft);
  return stateNext;
};

/**
 * A request was made to update a draft's title
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state to reflect this
 */
const onUpdateDraftTitleRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainTitleSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.title = action.title.trim().length === 0 ? '' : action.title;
  }

  return stateNext;
};

/**
 * A request was made to update a draft's topic
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state to reflect this
 */
const onUpdateDraftTopicRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainTopicSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.templateTopicCurrent = action.topicId;
  }

  return stateNext;
};

/**
 * A request was made to save topic,
 * so we store the data from topicIdCurrent to topicIdSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveDraftTopicRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainTopicSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.templateTopicSaved = draftNext.templateTopicCurrent;
  }

  return stateNext;
};

/**
 *
 * DELETE callbacks
 *
 */

/**
 * A request was made to clear a draft
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state
 */
const onClearDraftRequest = (
  stateCurrent: DraftsState,
  action: DraftActionMainClear,
): DraftsState => {
  const { parentId } = action;
  const stateNext = stateDraftsClone(stateCurrent);

  const draft = stateDraftsInitDraft(parentId);
  stateNext.set(parentId, draft);

  return stateNext;
};

/**
 * action:reducer mapping for draft labels
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__MAIN__CLEAR, onClearDraftRequest],
  [DRAFTS__MAIN__INIT, onInitDraftRequest],
  [DRAFTS__MAIN__POPULATE, onPopulateDraftRequest],
  [DRAFTS__MAIN__TITLE_SET, onUpdateDraftTitleRequest],
  [DRAFTS__MAIN__TOPIC_SET, onUpdateDraftTopicRequest],
  [DRAFTS__MAIN__TOPIC_SAVE, onSaveDraftTopicRequest],
]);

/**
 * Drafts main reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsMain = (
  state = initialState.drafts,
  action: DraftActionMain,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsMain;
