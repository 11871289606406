/**
 * @file Helpers for MuiIcon component
 */

import { MuiIconName, MuiSymbolIconName } from './types';

const muiSymbolNames = new Set<MuiSymbolIconName>(['home', 'settings']);

/**
 * We use different mappings for @mui/icons-material and custom icons (material-symbols).
 * To satisfy typescript we need to know of which type the icon name is. Otherwise
 * typescript complains, because the return types of the icons are not the same.
 *
 * @param iconName Name of the icon we want to check
 * @returns        Whether the iconName belongs to material symbol icons
 */
export const getIsMuiSymbolIconName = (
  iconName: MuiIconName | MuiSymbolIconName,
): iconName is MuiSymbolIconName => {
  return muiSymbolNames.has(iconName as MuiSymbolIconName);
};
