import { styled } from 'styled-components';

import ButtonCommon from '../Common/Button';

export const Button = styled(ButtonCommon)`
  align-items: center;
  background-color: var(--color-primary-light);
  border-radius: var(--border-radius-small);
  color: var(--color-text-alt);
  display: flex;
  font-weight: bold;
  height: 1.25rem;
  justify-content: center;
  min-width: 4rem;

  &:disabled {
    background-color: transparent;
    border: 1px solid var(--color-button-disabled);
    color: var(--color-button-disabled);
  }

  @media (hover: hover) {
    &:hover:enabled {
      background-color: var(--color-hover-dark);
    }
  }
`;
