/**
 * @file contains all error codes coming from the backend
 */

/**
 * type:
 * - none     -> Handle error within the UI, not via global handler
 * - screen   -> Redirect the user to error screen
 * - toast    -> Show a toast message
 */
export type ApiError = {
  code: string;
  message: string;
  type: 'none' | 'screen' | 'toast';
};

/**
 * Error codes we are currently handling
 */
export const ApiErrors: Record<string, ApiError> = {
  AUTH_HOTEL_NOT_FOUND: {
    code: 'AUTH_HOTEL_NOT_FOUND',
    message: 'ERROR__MESSAGE__AUTH_HOTEL_NOT_FOUND',
    type: 'screen',
  },
  AUTH_USER_NOT_FOUND: {
    code: 'AUTH_USER_NOT_FOUND',
    message: 'ERROR__MESSAGE__AUTH_USER_NOT_FOUND',
    type: 'none', // auth exchange will redirect the user to IAM
  },
  DEACTIVATED_HOTEL: {
    code: 'DEACTIVATED_HOTEL',
    message: 'ERROR__MESSAGE__DEACTIVATED__HOTEL',
    type: 'screen',
  },
  MESSAGE_NOT_FOUND: {
    code: 'MESSAGE_NOT_FOUND',
    message: 'ERROR__MISSING_POST__DESCRIPTION',
    type: 'toast',
  },
  MISSING_HOTEL: {
    code: 'MISSING_HOTEL',
    message: 'ERROR__MESSAGE__MISSING__HOTELS',
    type: 'screen',
  },
  MISSING_PERMISSION: {
    code: 'MISSING_PERMISSION',
    message: 'ERROR__MESSAGE__MISSING_PERMISSION',
    type: 'toast',
  },
  MISSING_TERMS_AND_CONDITIONS: {
    code: 'MISSING_TERMS_AND_CONDITIONS',
    message: 'ERROR__MESSAGE__MISSING_TERMS_AND_CONDITIONS',
    type: 'screen',
  },
  RESTRICTED_HOTEL: {
    code: 'RESTRICTED_HOTEL',
    message: 'ERROR__MESSAGE__RESTRICTED_HOTEL',
    type: 'toast',
  },
  TEMPLATE_NOT_CREATED: {
    code: 'TEMPLATE_NOT_CREATED',
    message: 'TOAST__ERROR__TEMPLATE_NOT_CREATED__DESCRIPTION',
    type: 'toast',
  },
  TEMPLATE_NOT_DELETED: {
    code: 'TEMPLATE_NOT_DELETED',
    message: 'TOAST__ERROR__TEMPLATE_NOT_DELETED__DESCRIPTION',
    type: 'toast',
  },
  TEMPLATE_NOT_UPDATED: {
    code: 'TEMPLATE_NOT_UPDATED',
    message: 'TOAST__ERROR__TEMPLATE_NOT_UPDATED__DESCRIPTION',
    type: 'toast',
  },
  TOPIC_NOT_FOUND: {
    code: 'TOPIC_NOT_FOUND',
    message: 'ERROR__MISSING_TOPIC__DESCRIPTION',
    type: 'toast',
  },
  TOPIC_TITLE_NOT_UNIQUE: {
    code: 'TOPIC_TITLE_NOT_UNIQUE',
    message: 'ERROR__MESSAGE__DUPLICATE_TITLE',
    type: 'none', // Show the error right in the topic create/edit modal
  },
};
