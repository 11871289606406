/**
 * @file A label was deleted, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  LabelDeleteMutation,
  LabelDeleteMutationVariables,
  LabelsDocument,
} from '../../../../../generated/graphql';
import queryDeletion from '../utils/updaters/queryDeletion';

/**
 * Resolve cache after a label was deleted
 * We need to cover all queries that fetch labels,
 * and filter out the deleted one
 *
 * @param _result The result of the mutation (unused)
 * @param args    Arguments of a graphQL operation
 * @param cache   URQL graphQL cache
 */
const labelDeleteMutation: UpdateResolver<
  LabelDeleteMutation,
  LabelDeleteMutationVariables
> = (_result, args, cache): void => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: LabelsDocument,
    queryName: 'labels',
  });
};

export default labelDeleteMutation;
