/**
 * @file contains key-value translations that are used
 * when the user is not yet authenticated
 *
 * Language: German
 */

export default {
  ERROR__404__ACTION: 'Zur Startseite',
  ERROR__404__DESCRIPTION:
    'Diese Seite existiert leider nicht. Bitte versuchen Sie es noch einmal mit einer gültigen URL.',
  ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION:
    'Wir konnten das Objekt leider nicht finden. Bitte wenden Sie sich an Ihren Administrator oder an unser Support-Team.',
  ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION:
    'Zu dieser Kennung konnten wir leider keinen Benutzer finden. Bitte versuchen Sie es noch einmal mit gültigen Zugangsdaten. Falls der Fehler erneut auftritt, wenden Sie sich bitte an den Support.',
  ERROR__DEACTIVATED_HOTEL__DESCRIPTION:
    'Ihr Benutzer wurde für das Objekt deaktiviert. Bitte wenden Sie sich an Ihren Administrator oder an unser Support-Team.',
  ERROR__HOTEL__SWITCH_DESCRIPTION:
    'Möchten Sie stattdessen zu einem anderen Objekt wechseln?',
  ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX: 'Zum Objekt „',
  ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX: '” wechseln',
  ERROR__MISSING_HOTEL__DESCRIPTION:
    'Auf das Objekt haben Sie leider keinen Zugriff. Bitte wenden Sie sich an Ihren Administrator oder an unser Support-Team.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION:
    'Um Zugriff zu erhalten, akzeptieren Sie bitte die allgemeinen Geschäftsbedingungen.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE: 'Huch, da fehlt noch etwas!',
  GENERAL__BACK_TO_LOGIN: 'Zurück zum Login',
  GENERAL__OOPS: 'Ups, etwas ist schiefgegangen.',
};
