/**
 * @file Hook that gets currently active topicId from url
 */
import { useParams } from 'react-router-dom';

import { Topic } from '../../../generated/graphql';

import { FeedParams } from '../../../models/pageParams';

/**
 * Hook that gets currently active topicId from url
 *
 * @returns Current active topicId
 */
const useParamTopicId = (): Topic['id'] | null => {
  const { topicId } = useParams<FeedParams>();

  if (!topicId) {
    return null;
  }

  return topicId;
};

export default useParamTopicId;
