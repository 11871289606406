/**
 * @file contains permission checks for message attachments
 */

import {
  MessageCreator,
  MessageCreatorAndAssigned,
  MessageCreatorTypeField,
  MessageIsArchived,
} from '../../models/message';
import { getIsExternal } from '../message';
import { getIsTask } from '../message/tasks';

import {
  getIsAdmin,
  getIsAssigned,
  getIsAuthor,
  hasPermission,
} from './messageHelper';
import {
  MESSAGE__CREATE,
  MESSAGE__DELETE,
  MESSAGE__READ_BY,
  MESSAGE__UPDATE,
} from './permissionsAll';
import { getIsBasic } from './storage';

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user has permissions
 * to add a checklist item to a new message
 *
 * @returns Whether the user has permissions
 */
export const getCanCreateMessage = (): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  return getIsAdmin() || hasPermission(MESSAGE__CREATE);
};

/**
 *
 * READ
 *
 */

/**
 * Get whether the user has permissions to see the read by section
 *
 * @returns Whether the user has permissions
 */
export const getCanSeeReadBy = (): boolean => {
  return getIsAdmin() || hasPermission(MESSAGE__READ_BY);
};

/**
 *
 * UPDATE
 *
 * Only plain text messages are able to be updated,
 * concierge messages are NOT updatable
 */

/**
 * Check whether the user is allowed to archive the message
 *
 * @returns Whether the user has permissions
 */
export const getCanArchiveMessage = (): boolean => {
  return hasPermission(MESSAGE__UPDATE);
};

/**
 * Check whether the user is allowed to unarchive the message
 *
 * @returns Whether the user has permissions
 */
export const getCanUnArchiveMessage = (): boolean => {
  return hasPermission(MESSAGE__UPDATE);
};

/**
 * Check whether the user is allowed to (un)assign users and groups to/from a message
 *
 * @param message The message to check if a message is archived
 * @returns       Whether the user has permissions
 */
export const getCanUpdateMessageAssignment = (
  message: MessageIsArchived,
): boolean => {
  if (message.isArchived) {
    return false;
  }
  return getIsAdmin() || hasPermission(MESSAGE__UPDATE);
};

/**
 * Check whether the user is allowed to update
 * due date, attach labels...
 *
 * @param message The message to check the permissions for
 * @returns       Whether the user has permissions
 */
export const getCanUpdateMessageMeta = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(MESSAGE__UPDATE) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};

/**
 * Check whether the user is allowed to update
 * message title and description
 *
 * @param isTranslated Whether the message is translated
 * @param message      The message to check the permissions for
 * @returns            Whether the user has permissions
 */
export const getCanUpdateMessageBasics = (
  isTranslated: boolean,
  message: MessageCreator & MessageCreatorTypeField & MessageIsArchived,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsExternal(message.creatorType) || isTranslated) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  return getIsAuthor(message) && hasPermission(MESSAGE__UPDATE);
};

/**
 * Check whether the user is allowed to update
 * message title and description
 *
 * @param message The message to check the permissions for
 * @returns       Whether the user has permissions
 */
export const getCanUpdateMessageStatus = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (getIsTask(message) === false || message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  const canUpdate = hasPermission(MESSAGE__UPDATE);
  return canUpdate && (getIsAuthor(message) || getIsAssigned(message));
};

/**
 *
 * DELETE
 *
 */

/**
 * Check whether the user is allowed to delete the message
 *
 * @param message The message to check the permissions for
 * @returns       Whether the user has permissions
 */
export const getCanDeleteMessage = (
  message: MessageCreator & MessageCreatorTypeField & MessageIsArchived,
): boolean => {
  if (getIsExternal(message.creatorType) || message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  return getIsAuthor(message) && hasPermission(MESSAGE__DELETE);
};
