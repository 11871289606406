import React, { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { MODAL__POST_ARCHIVE } from '../../../constants/routeModalParams';
import { getOpenModalUrl } from '../../../containers/modals/helpers';
import { Message } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import ButtonIcon, { NAMES } from '../../Common/ButtonIcon';
import MessageSectionActions from '../../Message/MessageSectionActions';

import * as Styled from './styled';

export type Props = {
  messageId: Message['id'];
  shouldShowContent: boolean;
  shouldUseIcon?: boolean;
};

const ICON_SIZE = 19;

/**
 * Message section which returns archive button if user is authorized to archive the message.
 * If button is clicked, confirmation modal pops up.
 *
 * @param props                   Props passed to the component
 * @param props.messageId         Id of message
 * @param props.shouldShowContent If archive button should be visible, depending on permission
 * @param props.shouldUseIcon     If to use a archive icon instead of a archive button
 * @returns                       The component itself or null
 */
const MessageArchive: FC<Props> = ({
  messageId,
  shouldUseIcon = false,
  shouldShowContent,
}) => {
  const location = useLocation();
  const label = translate('GENERAL__ARCHIVE');

  if (shouldShowContent === false) {
    return null;
  }

  const modalUrl = getOpenModalUrl(location, MODAL__POST_ARCHIVE, {
    messageId,
  });

  return (
    <>
      {shouldUseIcon ? (
        <Link to={modalUrl}>
          <ButtonIcon
            color="var(--color-icons-inactive)"
            colorHover="var(--color-hover-dark)"
            iconHeight={ICON_SIZE}
            iconName={NAMES.GENERAL__ARCHIVE}
            iconWidth={ICON_SIZE}
            label={label}
          />
        </Link>
      ) : (
        <MessageSectionActions>
          <Link to={modalUrl}>
            <Styled.ButtonArchive />
          </Link>
        </MessageSectionActions>
      )}
    </>
  );
};

export default MessageArchive;
