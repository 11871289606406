/**
 * @file Adds a newly created node to the cache
 */

import { Cache, Variables } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';

/**
   All possible keys that we're checking against when sorting cache, currently topics(title) and labels(text)
 */
const keyCandidates: ('text' | 'title')[] = ['title', 'text'];

// fields commonly available (? is left so we have to check if they actually are)
type BasicFields = {
  id?: string;
  title?: string;
  text?: string;
  description?: string | null;
};

type Query = {
  name: string;
  variables?: Variables;
  query: TypedDocumentNode;
};

type Params<ResultType> = {
  cache: Cache;
  queries: Query[];
  result: ResultType;
};

/**
 * Adds a newly created node to the cache
 *
 * @param args         Args passed
 * @param args.cache   Graphql cache object
 * @param args.queries Names of the queries to update
 * @param args.result  Result of the operation to add to cache
 */
const queryCreation = <ResultType extends BasicFields>({
  cache,
  queries,
  result,
}: Params<ResultType>): void => {
  queries.forEach(({ name, variables, query }) => {
    cache.updateQuery({ query, variables: variables ?? undefined }, data => {
      const queryData = (data?.[name] ?? null) as ResultType[] | null;

      if (queryData === null) {
        return null;
      }

      /**
       * Checks if node exists in the cache, and if it does, does not update data.
       * Data in urql cache is being via mutating it and that is ok, since we are
       * working with the copy of the cache data, and not the real cache data
       * More info: https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#updating-lists-or-links
       *
       * Note on typecasting: It is required at this moment because types are dynamic,
       * if we ever find a way to avoid this being typecast, we should implement it
       */
      if (queryData.find(node => node.id === result.id)) {
        return data;
      }

      const key = keyCandidates.find(candidate => {
        return queryData[0]?.[candidate] !== undefined;
      });

      /**
       * Calculate at which point to insert the new item
       */
      const index =
        key === undefined
          ? -1
          : queryData.findIndex(node => {
              const valueA = node[key] ?? '';
              const valueB = result[key] ?? '';

              return valueA.localeCompare(valueB) !== -1;
            });

      /**
       * Updates the data in the cache
       * Index can be -1 if the item wasn't found or there was no key set for this query,
       * and in that case, we just push to the end
       */
      if (index === -1) {
        queryData.push(result);
      } else {
        queryData.splice(index, 0, result);
      }

      return data;
    });
  });
};

export default queryCreation;
