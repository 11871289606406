import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  count?: number;
};

/**
 * Skeletons for Kanban tasks
 *
 * @param props       Props passed to the component
 * @param props.count Number of skeletons to show
 * @returns           The component itself
 */
const TasksSkeleton: FC<Props> = ({ count = 3 }) => {
  const skeletons = Array.from({ length: count }, (_, i) => i);
  return skeletons.map(i => (
    <Styled.MuiSkeleton key={i} variant="rectangular" />
  ));
};

export default TasksSkeleton;
