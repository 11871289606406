import React, { FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { TemplateBasicFragment } from '../../generated/graphql';
import translate from '../../i18n/translate';
import MessageTemplateForm from '../Forms/MessageTemplateForm';
import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

import { getTemplateTitle, getWarningTitle } from './helpers';

type Props = {
  className?: string;
  dataTemplateAll: TemplateBasicFragment[];
  dataTemplateCurrent: TemplateBasicFragment['id'] | null;
  dataTemplateSaved: TemplateBasicFragment['id'] | null;
  dataSearchValue: string;
  inputCustom?: ReactNode;
  isActive: boolean;
  isLoading: boolean;
  linkToggleProps?: LinkProps;
  onSearch: (term: string) => void;
  requestTemplateChange: (templateId: TemplateBasicFragment['id']) => void;
  requestTemplateClose?: () => void;
  requestTemplateRemove: () => void;
  requestTemplateSave: () => void;
};

/**
 * Template popup for the compose block
 *
 * @param props                       Props passed to the component
 * @param props.className             styled-components generated class name, needed for styling
 * @param props.dataTemplateAll       All available templates
 * @param props.dataTemplateCurrent   Currently selected template
 * @param props.dataTemplateSaved     Currently saved template
 * @param props.dataSearchValue       <input type="search" /> value attribute
 * @param props.inputCustom           Component to use for overview in mobile view
 * @param props.isActive              Whether the component should be visible
 * @param props.isLoading             Whether the data is still being loaded
 * @param props.linkToggleProps       Props for the toggle link
 * @param props.onSearch              Callback to invoke when search term is changed
 * @param props.requestTemplateChange Request that selected template is changed
 * @param props.requestTemplateClose  Request to close template popup
 * @param props.requestTemplateRemove Request that current template is set to null
 * @param props.requestTemplateSave   Request that the current template is saved
 * @returns                           The component itself
 */
const MetaTemplate: FC<Props> = ({
  className,
  dataSearchValue,
  dataTemplateAll,
  dataTemplateCurrent,
  dataTemplateSaved,
  inputCustom,
  isActive,
  isLoading,
  linkToggleProps = null,
  onSearch,
  requestTemplateChange,
  requestTemplateClose,
  requestTemplateRemove,
  requestTemplateSave,
}) => {
  /**
   * We need to check if currently selected template is equal to draft/saved template
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled = dataTemplateCurrent === dataTemplateSaved;

  const idForm = useId();
  const idHeading = useId();

  // Popup top buttons' labels
  const labelAction = translate('GENERAL__RESET');
  const labelActionColor = 'var(--color-button-danger)';
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textPlaceholder = translate('COMPOSE__PLACEHOLDER__TEMPLATE');
  const textHeading = textPlaceholder;

  const templateTitle = getTemplateTitle(dataTemplateAll, dataTemplateSaved);
  const warningTitle = getWarningTitle(dataTemplateCurrent, dataTemplateSaved);

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    action:
      dataTemplateCurrent !== null
        ? {
            color: labelActionColor,
            label: labelAction,
            onClick: requestTemplateRemove,
          }
        : null,
    className,
    hasMobilePadding: false,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose: requestTemplateClose,
    textHeading,
  };

  const children = (
    <MessageTemplateForm
      ariaDescribedBy={idHeading}
      ariaLabelledBy={idHeading}
      dataSearchValue={dataSearchValue}
      dataTemplateAll={dataTemplateAll}
      dataTemplateCurrent={dataTemplateCurrent}
      idForm={idForm}
      isLoading={isLoading}
      onSearch={onSearch}
      requestTemplateChange={requestTemplateChange}
      requestTemplateSave={requestTemplateSave}
    />
  );

  if (linkToggleProps === null) {
    return isActive ? (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    ) : null;
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={templateTitle}
      warningTitle={warningTitle}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaTemplate;
