import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

/**
 * Component that renders invalid search query text
 *
 * @returns Invalid search query component
 */
const SearchQueryInvalid: FC = () => {
  const navigate = useNavigate();

  /**
   * The user has clicked on back button, so we just navigate back
   */
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Styled.Wrapper>
      {translate('SEARCH__INVALID__MESSAGE')}
      <Styled.Button onClick={goBack} type="button">
        {translate('GENERAL__BACK_TO_PREVIOUS_PAGE')}
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default SearchQueryInvalid;
