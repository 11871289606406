/**
 * @file Helper types for dashboard page
 */

import {
  DashboardKpiQuery,
  DashboardKpiType,
  SearchInfo,
} from '../generated/graphql';

export type CardType = 'REQUEST' | 'REQUEST_ANSWERED' | 'REQUEST_UNANSWERED';

export type FormattedData = Record<DashboardKpiType, KpiSingle[]>;

export type FormattedDataArgs = {
  dashboardKpi: DashboardKpiQuery['dashboardKpi'];
  hotelId: string | null;
  isTablet: boolean;
};

export type KpiSingle = {
  amount: SearchInfo['total'];
  label: string;
  url: string;
};

export const cardIllustrationTypes: CardType[] = [
  'REQUEST',
  'REQUEST_ANSWERED',
  'REQUEST_UNANSWERED',
];
