import React, { FC, FormEventHandler, useState } from 'react';

import {
  LABEL__COLOR_DEFAULT,
  LABEL__COLOR_REQUIRED,
  LABEL__MAX_TEXT_LENGTH,
  LABEL__MIN_TEXT_LENGTH,
  LABEL__TEXT_REQUIRED,
  LABEL__TEXT_UNIQUE,
} from '../../../constants/forms';
import { Label } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import PickerColor from '../../Common/PickerColor';

import * as Styled from './styled';

export type Props = {
  ariaDescribedBy: string;
  ariaLabelledBy: string;
  dataColor: string;
  dataTextSelected?: string;
  requestSaveDisabled?: (text: string, color: string) => void;
  idForm: string;
  labelsExisting: Label['text'][];
  requestSave: (text: string, color: string) => void;
};

const nameColor = 'color';
const nameText = 'text';

/**
 * Form for updating a single label
 *
 * @param props                     Props passed to the component
 * @param props.ariaDescribedBy     Accessible form description
 * @param props.ariaLabelledBy      Accessible form title
 * @param props.dataColor           Initial label color
 * @param props.dataTextSelected    Initial label text which is currently selected
 * @param props.requestSaveDisabled Callback to enable/disable save button (Takes current text and color as args so we can compare them with initial text/color)
 * @param props.idForm              ID to use for the label update <form /> element
 * @param props.labelsExisting      Labels already present
 * @param props.requestSave         Request that the updated data is saved
 * @returns                         The component itself
 */
const LabelUpdateForm: FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  dataColor,
  dataTextSelected = '',
  idForm,
  labelsExisting,
  requestSave,
  requestSaveDisabled,
}) => {
  // Doing via local state instead of container or app level state
  // because it's not supposed to be used anywhere else, and this way,
  // we could have multiple independent label creation forms rendered at once
  const [color, setColor] = useState(dataColor);
  const [text, setText] = useState(dataTextSelected);

  const placeholder = translate('LABELS__TITLE__PLACEHOLDER');
  const labelColor = translate('LABELS__COLOR__LABEL');
  const labelTitle = translate('LABELS__TITLE__LABEL');

  /**
   * Set color value and check if save button should be disabled
   *
   * @param value New value of color input
   */
  const handleColorChange = (value: string): void => {
    setColor(value);
    if (requestSaveDisabled) {
      requestSaveDisabled(text, value);
    }
  };

  /**
   * Something was changed within the form,
   * so we check whether its validity has changed,
   * and if so, set disabled state on submit button accordingly
   *
   * @param event The event that took place
   */
  const handleChange: FormEventHandler<HTMLFormElement> = event => {
    const form = event.currentTarget;
    const inputText = form.elements.namedItem(nameText) as HTMLInputElement;

    if (LABEL__TEXT_UNIQUE === true) {
      // We check here if the current value of label.text is unique among all labels.
      // If that's not the case we show an error message and the form is marked as invalid.
      const { value: textValue } = inputText;
      const isTextDuplicate =
        textValue !== dataTextSelected &&
        labelsExisting.includes(textValue) === true;

      const errorMessage =
        isTextDuplicate === true
          ? translate('LABELS__ERROR__DUPLICATE__TITLE')
          : '';

      inputText.setCustomValidity(errorMessage);
    }
  };

  /**
   * Set text value and check if save button should be disabled
   *
   * @param value New value of text input
   */
  const handleTextChange = (value: string): void => {
    setText(value);
    if (requestSaveDisabled) {
      requestSaveDisabled(value, color);
    }
  };

  /**
   * The form was submitted (perhaps by pressing enter within an input)
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestSave(text, color);
  };

  return (
    <form
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      id={idForm}
      onChange={handleChange}
      onSubmit={onSubmit}
    >
      <Styled.Title
        ariaLabel={labelTitle}
        isRequired={LABEL__TEXT_REQUIRED}
        maxLength={LABEL__MAX_TEXT_LENGTH}
        minLength={LABEL__MIN_TEXT_LENGTH}
        name={nameText}
        onChange={handleTextChange}
        placeholder={placeholder}
        shouldFocus={true}
        type="text"
        value={text}
      />
      <PickerColor
        isRequired={LABEL__COLOR_REQUIRED}
        label={labelColor}
        name={nameColor}
        onChange={handleColorChange}
        placeholder={LABEL__COLOR_DEFAULT}
        value={color}
      />
    </form>
  );
};

export default LabelUpdateForm;
