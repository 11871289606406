import React, { FC } from 'react';

import { dateFormatInput } from '../../../utils/date/format';
import getDateText from '../../../utils/date/getDateText';

import * as Styled from './styled';

type Props = {
  date: Date;
};

/**
 * Heading to group dates in the feed, in format:
 * ---- DATE ----
 *
 * @param props      Props passed to the component
 * @param props.date The date to use
 * @returns          The component itself
 */
const MessageDateHeading: FC<Props> = ({ date }) => {
  return (
    <Styled.Wrapper>
      <Styled.Separator />
      <Styled.Date dateTime={dateFormatInput(date)}>
        {getDateText(date)}
      </Styled.Date>
    </Styled.Wrapper>
  );
};

export default MessageDateHeading;
