import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';

import translate from '../../../i18n/translate';
import { getDashboardUrl } from '../../../routes/urls/dashboard';

import * as Styled from './styled';
/**
 * Link that leads to dashboard page
 *
 * @returns The component itself
 */
const DashboardLink: FC = () => {
  const hotelId = useParamHotelId();
  const { pathname } = useLocation();

  const dashboardUrl = getDashboardUrl(hotelId);

  const isSelected = dashboardUrl === pathname;

  return (
    <Styled.Link aria-current={isSelected ? 'page' : 'false'} to={dashboardUrl}>
      {translate('DASHBOARD__TITLE')}
    </Styled.Link>
  );
};

export default DashboardLink;
