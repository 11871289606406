/**
 * @file contains the recurring rrule reducer function
 */
import {
  RRULE__BY_MONTH_DAY__SAVE,
  RRULE__BY_MONTH_DAY__SET,
  RRULE__BY_SET_POS__SAVE,
  RRULE__BY_WEEK_DAY__SAVE,
  RRULE__BY_WEEK_DAY_SINGLE__SAVE,
  RRULE__DTSTART__SAVE,
  RRULE__DTSTART__SET,
  RRULE__FREQUENCY__SAVE,
  RRULE__INTERVAL__SAVE,
  RRULE__UNTIL__SAVE,
  RRULE__UNTIL__SET,
} from '../../constants/actionTypes';

import { RecurringAction, RecurringState } from '../../models/recurring';

import {
  RecurringActionByMonthDaySet,
  RecurringActionBySetPosSave,
  RecurringActionByWeekDaySave,
  RecurringActionByWeekDaySingleSave,
  RecurringActionDtStartSet,
  RecurringActionFrequencySave,
  RecurringActionIntervalSave,
  RecurringActionUntilSet,
} from '../../models/recurringRrule';
import { getOneDayAfterDate } from '../../utils/date/getOneDayAfterDate';
import stateRecurringClone from '../../utils/stateRecurringClone';

import initialState from '../initialState';

type Reducer = (
  state: RecurringState,
  action: RecurringAction,
) => RecurringState;

/**
 * A request was made to save bymonthday set,
 * so we store the data from bymonthdayCurrent to bymonthdaySaved
 *
 * @param stateCurrent Current drafts state
 * @returns            The updated state
 */
const onSaveByMonthDayRequest = (
  stateCurrent: RecurringState,
): RecurringState => {
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.bymonthdaySaved = stateNext.bymonthdayCurrent;

  return stateNext;
};

/**
 * A request was made to update current bymonthday set
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetByMonthDayRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionByMonthDaySet,
): RecurringState => {
  const { monthdays } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.bymonthdayCurrent = monthdays;

  return stateNext;
};

/**
 * A request was made to save bysetpos number,
 * so we directly store the data to bysetpos
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveBySetPosRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionBySetPosSave,
): RecurringState => {
  const { position } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.bysetpos = position;

  return stateNext;
};

/**
 * A request was made to save byweekday array,
 * so we directly store the data to byweekday
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveByWeekDayRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionByWeekDaySave,
): RecurringState => {
  const { weekdays } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.byweekday = weekdays;

  return stateNext;
};

/**
 * A request was made to save byweekdaySingle,
 * so we directly store the data to byweekdaySingle
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveByWeekDaySingleRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionByWeekDaySingleSave,
): RecurringState => {
  const { weekday } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.byweekdaySingle = weekday;

  return stateNext;
};

/**
 * A request was made to save dtstart date,
 * so we store the data from dtstartCurrent to dtstartSaved
 *
 * @param stateCurrent Current drafts state
 * @returns            The updated state
 */
const onSaveDtStartRequest = (stateCurrent: RecurringState): RecurringState => {
  const stateNext = stateRecurringClone(stateCurrent);

  const oneDayFromDtStartSaved = getOneDayAfterDate(stateNext.dtstartCurrent);

  stateNext.dtstartSaved = new Date(stateNext.dtstartCurrent.getTime());

  if (stateNext.untilSaved && stateNext.untilSaved < oneDayFromDtStartSaved) {
    stateNext.untilCurrent = oneDayFromDtStartSaved;
    stateNext.untilSaved = oneDayFromDtStartSaved;
  }

  return stateNext;
};

/**
 * A request was made to update current dtstart date
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetDtStartRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionDtStartSet,
): RecurringState => {
  const { date } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.dtstartCurrent = new Date(date.getTime());

  return stateNext;
};

/**
 * A request was made to save frequency number,
 * so we directly store the data to dateDueSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveFrequencyRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionFrequencySave,
): RecurringState => {
  const { value } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.frequency = value;

  return stateNext;
};

/**
 * A request was made to save frequency number,
 * so we directly store the data to dateDueSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveIntervalRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionIntervalSave,
): RecurringState => {
  const { value } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.intervalSaved = value;

  return stateNext;
};

/**
 * A request was made to save until date,
 * so we store the data from dateUntilCurrent to dateUntilSaved
 *
 * @param stateCurrent Current drafts state
 * @returns            The updated state
 */
const onSaveUntilRequest = (stateCurrent: RecurringState): RecurringState => {
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.untilSaved = stateNext.untilCurrent;

  return stateNext;
};

/**
 * A request was made to update current until date
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetUntilRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionUntilSet,
): RecurringState => {
  const { date } = action;
  const stateNext = stateRecurringClone(stateCurrent);

  stateNext.untilCurrent = date;

  return stateNext;
};

/**
 * action:reducer mapping for templates recurring
 */
const mapping = new Map<string, Reducer>([
  [RRULE__BY_MONTH_DAY__SAVE, onSaveByMonthDayRequest],
  [RRULE__BY_MONTH_DAY__SET, onSetByMonthDayRequest],
  [RRULE__BY_SET_POS__SAVE, onSaveBySetPosRequest],
  [RRULE__BY_WEEK_DAY__SAVE, onSaveByWeekDayRequest],
  [RRULE__BY_WEEK_DAY_SINGLE__SAVE, onSaveByWeekDaySingleRequest],
  [RRULE__DTSTART__SAVE, onSaveDtStartRequest],
  [RRULE__DTSTART__SET, onSetDtStartRequest],
  [RRULE__FREQUENCY__SAVE, onSaveFrequencyRequest],
  [RRULE__INTERVAL__SAVE, onSaveIntervalRequest],
  [RRULE__UNTIL__SAVE, onSaveUntilRequest],
  [RRULE__UNTIL__SET, onSetUntilRequest],
]);

/**
 * Drafts templates recurring reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const recurringRrule = (
  state = initialState.draftRecurring,
  action: RecurringAction,
): RecurringState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default recurringRrule;
