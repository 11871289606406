import { Paper, PaperProps, Stack, styled } from '@mui/material';

import { colorNeutral } from '../../styles/mui/colors';

type Props = {
  'data-is-dragging': boolean;
};

export const MuiPaper = styled(Paper)<PaperProps & Props>(
  ({ theme, ...props }) => ({
    '&:focus-visible': {
      borderColor: colorNeutral[600],
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    border: '1px solid transparent',
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[props['data-is-dragging'] === true ? 8 : 1],
    flexShrink: 0,
    padding: theme.spacing(2),
    transition:
      'border-color var(--preference-transition-duration-normal), box-shadow var(--preference-transition-duration-normal)',
  }),
);

export const MuiStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
