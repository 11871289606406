import { styled } from 'styled-components';

import Progress from '../../Progress';

export const MessageProgressBar = styled(Progress)`
  /**
   * Because of a firefox bug we need to set width directly to message ProgressBar with rem,
   * width:100% is not working for firefox
   */
  width: 3rem;
`;
