import { styled } from 'styled-components';

import FormInput from '../FormElements/FormInput';
import FormTextarea from '../FormElements/FormTextarea';

export const Description = styled(FormTextarea)`
  margin-bottom: var(--spacing-medium);
`;

export const Field = styled(FormInput)`
  margin-bottom: var(--spacing-medium);
  margin-top: var(--spacing-small);
`;
