/**
 * @file Optimistically update the cache, with deleted label filtered out
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  LabelDeleteMutation,
  LabelsDocument,
  MutationLabelDeleteArgs,
} from '../../../../../generated/graphql';
import queryDeletion from '../../updates/utils/updaters/queryDeletion';

/**
 * Optimistically delete a label
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const labelDelete: OptimisticMutationResolver<
  MutationLabelDeleteArgs,
  LabelDeleteMutation['labelDelete'] | null
> = (args, cache) => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: LabelsDocument,
    queryName: 'labels',
  });

  cache.invalidate({
    __typename: 'Label',
    id: args.id,
  });

  return {
    __typename: 'ActionResult',
    status: true,
  };
};

export default labelDelete;
