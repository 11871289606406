/* eslint-disable max-lines */
/**
 * @file Contains helpers for modals
 */

import { Location } from 'react-router-dom';

import {
  SEARCH_PARAM__ATTACHMENT_ID,
  SEARCH_PARAM__GROUP_ID,
  SEARCH_PARAM__LABEL_ID,
  SEARCH_PARAM__MESSAGE_ID,
  SEARCH_PARAM__MODAL_ID,
  SEARCH_PARAM__REDIRECT,
  SEARCH_PARAM__TEMPLATE_ID,
  SEARCH_PARAM__TOPIC_ID,
  SEARCH_PARAM__USER_ID,
} from '../../constants/routing/searchParams';
import {
  UserBasicFragment,
  UserGroupBasicWithMembersFragment,
} from '../../generated/graphql';
import { ModalId, ModalParams } from '../../models/modal';
import { SearchParamsKey } from '../../models/searchParams';
import {
  parseUrlString,
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../routes/helpers/router';
import {
  getSearchParam,
  getSearchParamModalId,
} from '../../routes/helpers/searchParams';
import { reportError } from '../../services/reporting';

import { ModalAccessibleOptions, ParamConfig } from './types';

const attachmentParams: ParamConfig = {
  required: [SEARCH_PARAM__ATTACHMENT_ID, SEARCH_PARAM__MESSAGE_ID],
};

const groupParams: ParamConfig = {
  required: [SEARCH_PARAM__GROUP_ID],
};

const labelParams: ParamConfig = {
  required: [SEARCH_PARAM__LABEL_ID],
};

const postParams: ParamConfig = {
  required: [SEARCH_PARAM__MESSAGE_ID],
};

const templateParams: ParamConfig = {
  required: [SEARCH_PARAM__TEMPLATE_ID],
};

const topicParams: ParamConfig = {
  required: [SEARCH_PARAM__TOPIC_ID],
};

/**
 * Modal specific params that are required for each modal
 */
const modalParamsConfig: Record<ModalId, ParamConfig | null> = {
  'group-create': null,
  'group-delete': groupParams,
  'group-edit': groupParams,
  'group-view': groupParams,
  'label-create': null,
  'label-delete': labelParams,
  'label-edit': labelParams,
  'post-archive': postParams,
  'post-attachment-delete': attachmentParams,
  'post-delete': postParams,
  'post-unarchive': postParams,
  'template-delete': templateParams,
  'template-recurring-rule-delete': templateParams,
  'topic-create': {
    optional: [SEARCH_PARAM__REDIRECT],
  },
  'topic-delete': topicParams,
  'topic-edit': topicParams,
  'user-edit': {
    required: [SEARCH_PARAM__USER_ID],
  },
};

/**
 * Get the required params for modal, that are required for it to be visible
 *
 * @param modalId The ModalId
 * @returns       Required searchParam keys for the requested modal
 */
export const getRequiredParamsByModalId = (modalId: ModalId) =>
  modalParamsConfig[modalId]?.required ?? [];

/**
 * Get the optional params for modal
 *
 * @param modalId The ModalId
 * @returns       Optional SearchParam keys for the requested modal
 */
export const getOptionalParamsByModalId = (modalId: ModalId) =>
  modalParamsConfig[modalId]?.optional ?? [];

/**
 * Checks whether the URL has all the required search params, so the modal
 * can be visible.
 *
 * @param location History Location
 * @param modalId  ModalId
 * @returns        Whether the URL has all required params for requested modal
 */
export const getHasRequiredModalParams = (
  location: Location,
  modalId: ModalId,
) => {
  const modalIdParam = getSearchParamModalId(location);
  if (modalIdParam !== modalId) return false;

  const params = getRequiredParamsByModalId(modalId);
  return params.every(param => getSearchParam(param, location) !== null);
};

/**
 * Get whether we should and can show a modal.
 * "Should see" means that the URL has all params that tell us to open a modal.
 * "Can see" means that the user can access the modal. Some reasons why the user
 * can not see the modal are: not enough permissions, the entity was deleted...
 *
 * @param location           History's location object
 * @param modalId            Id of the modal
 * @param options            Additional things we want to check before displaying
 * @param options.fetching   Whether any data we need to validate is fetching
 * @param options.validators Validators that show whether the user CAN access the modal
 * @returns                  Whether the modal should and can be shown [should, can].
 */
export const getShouldShowModal = (
  location: Location,
  modalId: ModalId,
  options?: ModalAccessibleOptions,
) => {
  const { validators = [], fetching } = options ?? {};

  if (fetching === true) {
    return [false, false];
  }

  const searchParamModalId = getSearchParamModalId(location);

  if (searchParamModalId !== modalId) {
    return [false, false];
  }

  const hasRequiredModalParams = getHasRequiredModalParams(location, modalId);

  return [hasRequiredModalParams, validators.every(bool => bool === true)];
};

/**
 * Creates an URL with params that will open a modal
 *
 * @param location    History location object
 * @param modalId     The modal id of the modal we want to open
 * @param paramConfig Modal specific params that need to be added
 * @returns           A string in URL format
 */
export const getOpenModalUrl = <T extends ModalId>(
  location: Location,
  modalId: T,
  paramConfig?: ModalParams[T],
) => {
  const allowedParams = new Set([
    ...getRequiredParamsByModalId(modalId),
    ...getOptionalParamsByModalId(modalId),
  ]);

  const invalidParams = new Set();

  const params: Record<string, string> = {
    [SEARCH_PARAM__MODAL_ID]: modalId,
  };

  if (paramConfig !== undefined) {
    Object.entries(paramConfig).forEach(([key, value]) => {
      if (
        allowedParams.has(key as SearchParamsKey) === false ||
        value === null
      ) {
        invalidParams.add(key);
      } else {
        params[key] = value as string;
      }
    });
  }

  const url = replaceOrAddParamsToUrl(location, params);

  if (invalidParams.size > 0) {
    const [, search] = parseUrlString(url);
    reportError(
      `Invalid modal param in url for modal "${modalId}": ${[
        ...invalidParams,
      ].join(', ')} (${search})`,
    );
  }

  return url;
};

/**
 * Creates an URL without modal related params that will close any modal if open.
 *
 * @param location History location object
 * @returns        A string in URL format
 */
export const getCloseModalUrl = (location: Location) => {
  const modalId = getSearchParamModalId(location);

  // There is no opened modal
  if (modalId === null) {
    const [pathname, search] = parseUrlString(
      `${location.pathname}?${location.search}`,
    );
    return `${pathname}${search}`;
  }

  return removeParamsKeysFromUrl(
    location,
    SEARCH_PARAM__MODAL_ID,
    ...getRequiredParamsByModalId(modalId),
    ...getOptionalParamsByModalId(modalId),
  );
};

/**
 * Extract members ID from selected group
 *
 * @param group Currently selected group
 * @returns     Members ID if group is selected, if not - empty Set
 */
export const getGroupMembers = (
  group: UserGroupBasicWithMembersFragment | null,
): Set<UserBasicFragment['id']> => {
  return new Set(group?.members.map(member => member.id) ?? []);
};

/**
 * Helper to reduce code repetition and standardize what accessibility hooks
 * return.
 *
 * @param isAccessible Whether the modal is accessible
 * @param data         The data that the modal needs
 * @param fetching     Whether the data is fetching
 * @returns            Data or null and whether it's fetching
 */
export const getModalAccessibleData = <T>(
  isAccessible: boolean,
  data: T | null,
  fetching: boolean,
): [T | null, boolean] => {
  if (isAccessible === false || fetching === true) {
    return [null, fetching];
  }

  return [data, false];
};
