/**
 * @file Gets message edit section url in order to open it
 */

import { useLocation } from 'react-router-dom';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';

import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useIsTablet from '../../../hooks/useIsTablet';
import { MessageEditModeParam } from '../../../models/pageParams';
import { getMessageEditSectionDesktopUrl } from '../../../routes/urls/messageEdit';

type UseMessageSectionUrlParams = {
  mode: MessageEditModeParam | null;
};

/**
 * Gets message edit section url in order to open it
 *
 * @param args      Args passed to the function
 * @param args.mode Message section edit mode
 * @returns         The URL to use
 */
const useMessageSectionUrl = ({ mode }: UseMessageSectionUrlParams): string => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();
  const messageId = useParamMessageId();
  const topicId = useParamTopicId();

  return getMessageEditSectionDesktopUrl(
    hotelId,
    isTablet,
    location,
    messageId,
    mode,
    topicId,
  );
};

export default useMessageSectionUrl;
