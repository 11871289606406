import React, { FC } from 'react';

import SkeletonLine from '../SkeletonLine';

import * as Styled from './styled';

type Props = {
  nrLabels?: number;
};

/**
 * Skeleton view for label list
 *
 * @param props          Props passed to the component
 * @param props.nrLabels The number of labels to show the skeleton for
 * @returns              The component itself
 */
const SkeletonLabelList: FC<Props> = ({ nrLabels = 5 }) => {
  return (
    <ul>
      {Array.from({ length: nrLabels }).map((e, index) => (
        // No way to generate a key for this
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Li key={index}>
          <SkeletonLine />
        </Styled.Li>
      ))}
    </ul>
  );
};

export default SkeletonLabelList;
