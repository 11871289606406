/**
 * @file contains a custom react hook for adding toast message
 */

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Message } from '../../generated/graphql';
import { MessageTranslation } from '../../models/translations';

import {
  translateMessage,
  untranslateMessage,
} from '../../store/actions/translations';

/**
 *
 * Custom hook for adding toast message
 *
 * @returns Action which adds toast message
 */
const useTranslationsActions = () => {
  const dispatch = useDispatch();

  /**
   * Dispatches a redux action to put message into translated messages
   */
  const requestTranslateMessageAction = useCallback<
    (messageId: Message['id'], translation: MessageTranslation) => void
  >(
    (messageId, translation) => {
      dispatch(translateMessage(messageId, translation));
    },
    [dispatch],
  );

  /**
   * Dispatches a redux action to remove message from translated messages
   */
  const requestUntranslateMessageAction = useCallback<
    (messageId: Message['id']) => void
  >(
    messageId => {
      dispatch(untranslateMessage(messageId));
    },
    [dispatch],
  );

  return { requestTranslateMessageAction, requestUntranslateMessageAction };
};

export default useTranslationsActions;
