import React, { FC } from 'react';

import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useIsMobile from '../../../../hooks/useIsMobile';

import { mapping } from './helpers';
import * as Styled from './styled';
import { Mode, Props } from './types';

/**
 * Metadata for a message or a template, contains:
 * - Title
 * - Template
 * - Topic
 * - Attachments
 * - Assignment
 * - Labels
 * - Due date
 * - Offset
 * - Repeat settings
 * - Checklist
 *
 * @param props            Props passed to the component
 * @param props.breakpoint Breakpoint to show
 * @param props.formId     The ID of the compose form
 * @param props.nodeId     The ID of the node (template) being edited (null for creation)
 * @param props.nodeType   Whether the compose form is being used for messages or templates
 * @returns                The component itself
 */
const ComposeMeta: FC<Props> = props => {
  const { nodeType, position } = props;
  const isMobile = useIsMobile();
  const mode: Mode = `${isMobile ? 'MOBILE' : 'DESKTOP'}-${position}`;
  const topicIdUrl = useParamTopicId();
  const topicId = nodeType === 'MESSAGE' ? topicIdUrl : null;

  return (
    <Styled.Ul>
      {mapping[mode][nodeType].map((Component, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Component {...props} topicId={topicId} />
        </li>
      ))}
    </Styled.Ul>
  );
};

export default ComposeMeta;
