import React, { FC } from 'react';

import { Position, State, Text } from '../../../models/checklist';

import ChecklistItemSingle from './ChecklistItemSingle';
import { PropsSingleDraft } from './props';

/**
 * A single list item in the checklist (for drafts)
 * Extracted like this so that it can be used by both
 * ChecklistDraggable and ChecklistStatic
 *
 * @param props                 Props passed to the component
 * @param props.item            The item to render
 * @param props.item.checked    Whether the item is checked
 * @param props.item.position   The position for the item
 * @param props.item.text       Item text
 * @param props.requestDelete   Callback to request that an item is removed
 * @param props.requestSetState Callback to request that an item is (un)checked
 * @param props.requestSetText  Callback to request that an item's text is updated
 * @returns                     The component itself
 */
const ListItemDraft: FC<PropsSingleDraft> = ({
  item: { checked, position: itemPosition, text: itemText },
  requestDelete,
  requestSetState,
  requestSetText,
}) => {
  /**
   * Generate a callback for removing an item
   *
   * @param position The position to generate the callback for
   * @returns        The callback itself
   */
  const helperDelete = (position: Position): (() => void) | null => {
    if (requestDelete === null) {
      return null;
    }

    return () => requestDelete(position);
  };

  /**
   * Generate a callback for setting an item's state
   *
   * @param position The position to generate the callback for
   * @returns        The callback itself
   */
  const helperSetState = (
    position: Position,
  ): ((state: State) => void) | null => {
    if (requestSetState === null) {
      return null;
    }

    return state => requestSetState(position, state);
  };

  /**
   * Generate a callback for setting an item's text
   *
   * @param position The position to generate the callback for
   * @returns        The callback itself
   */
  const helperSetText = (position: Position): ((text: Text) => void) | null => {
    if (requestSetText === null) {
      return null;
    }

    return text => requestSetText(position, text);
  };

  return (
    <ChecklistItemSingle
      isChecked={checked}
      requestDelete={helperDelete(itemPosition)}
      requestSetState={helperSetState(itemPosition)}
      requestSetText={helperSetText(itemPosition)}
      text={itemText}
    />
  );
};

export default ListItemDraft;
