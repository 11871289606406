import { Stack } from '@mui/material';
import React, { FC } from 'react';

import {
  HASH_MESSAGE__INFO__DESCRIPTION,
  HASH_MESSAGE__INFO__TITLE,
} from '../../../constants/routing/hashLinks';
import { getMessageText } from '../../../formatters/concierge/form';
import useTranslation from '../../../hooks/store/useTranslation';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';
import RichText from '../../Common/RichText';

import * as Styled from './styled';

type Props = { task: MessageFeed; url: string };

/**
 * Content of task title and description
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the text and title of
 * @param props.url  Message details URL in the feed
 * @returns          The component itself
 */
const TaskTextContent: FC<Props> = ({ task, url }) => {
  const { id, title } = task;

  const textContent = useTranslation(id)?.text ?? getMessageText(task);

  return (
    <Stack spacing={1}>
      <Styled.LinkText to={getUrlWithHash(url, HASH_MESSAGE__INFO__TITLE)}>
        <Styled.Typography component="h3" variant="body1">
          {title}
        </Styled.Typography>
      </Styled.LinkText>

      <Styled.LinkText
        to={getUrlWithHash(url, HASH_MESSAGE__INFO__DESCRIPTION)}
      >
        <RichText shouldRenderLinks={false}>{textContent}</RichText>
      </Styled.LinkText>
    </Stack>
  );
};

export default TaskTextContent;
