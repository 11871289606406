/**
 * @file contains helper functions for AttachmentSingle component
 */
import { FILE__EXTENSIONS } from '../../../constants/forms';
import { File as CustomFile, Message } from '../../../generated/graphql';
import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
  ExtensionType,
} from '../../../models/attachment';

export const iconMap: Record<string, string> = {
  image: 'attachment__image',
  other: 'attachment__file',
  pdf: 'attachment__pdf',
};

/**
 * Get icon for attachment with extension
 *
 * @param fileExtension Extension of file
 * @returns             Icon name
 */
export const getFileIcon = (
  fileExtension?: CustomFile['extension'],
): string => {
  const fileExtensionExists =
    fileExtension !== undefined && fileExtension in FILE__EXTENSIONS;

  if (fileExtensionExists) {
    return iconMap[FILE__EXTENSIONS[fileExtension as ExtensionType]];
  }

  return iconMap.other;
};

/**
 * Check if attachment is being uploaded
 *
 * @param attachment Current attachment
 * @returns          Whether the current attachment is uploading
 */
export const getIsUploading = (
  attachment: Attachment | AttachmentDraft,
): boolean | undefined =>
  'isUploading' in attachment ? attachment.isUploading : undefined;

/**
 * Remove file from draft or call delete mutation.
 * Depends on attachment status (draft or existing)
 *
 * @param attachment        Current attachment
 * @param canDeleteFile     Whether the current user has permissions to delete a file
 * @param requestFileRemove Callback for file removal
 */
export const handleFileRemove = (
  attachment: Attachment | AttachmentDraft,
  canDeleteFile: boolean,
  requestFileRemove?: (key: CustomFile['key']) => void,
) => {
  const isAttachmentDraft = 'isDraft' in attachment && requestFileRemove;
  const isAttachmentExisting =
    'id' in attachment && requestFileRemove && canDeleteFile === true;

  if (isAttachmentDraft) {
    requestFileRemove(attachment.key);
  }

  if (isAttachmentExisting) {
    requestFileRemove(attachment.id);
  }
};

/**
 * Decide whether the preview fragment should be rendered
 *
 * @param isCompatible Whether current app version is compatible with AttachmentPreview feature
 * @param mode         Attachment mode
 * @param messageId    Id of the current message
 * @returns            Whether preview fragment should be rendered
 */
export const shouldRenderPreviewFragment = (
  isCompatible: boolean,
  mode: AttachmentMode,
  messageId?: Message['id'],
): boolean => {
  return (
    isCompatible &&
    (mode === 'details' || mode === 'detailsEdit') &&
    messageId !== undefined
  );
};
