import { styled } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--font-sm);
  gap: 1px;
`;

export const Text = styled.span`
  text-transform: uppercase;
`;
