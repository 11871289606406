/**
 * @file User settings mapper
 */
import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorUser } from './navigators';

const mapperUser = {
  // Users and roles
  [NotificationEvent.UserActivated]: navigatorUser,
  [NotificationEvent.UserDeactivated]: navigatorUser,
  [NotificationEvent.UserFirstLogIn]: navigatorUser,
  [NotificationEvent.UserRoleUpdated]: navigatorUser,
};

export default mapperUser;
