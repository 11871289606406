/**
 * @file Communication between the native wrapper app and this project
 */

import { UseMutationExecute } from 'urql';

import {
  Exact,
  PushSubscribeMutation,
  PushSubscribeMutationVariables,
} from '../../generated/graphql';
import { AttachmentActionType } from '../../models/attachment';

import { reportError } from '../../services/reporting';

import { reportApiErrors } from '../error';

import { getIsReactNativeWebView } from './helpers';

import {
  NotificationPermissionResponsePayload,
  WebViewEventMessage,
  WebViewEventNames,
} from './payloadTypes';

/**
 *
 * POST MESSAGES
 *
 * (Web -> Native)
 *
 */

/**
 * Check if running in webview and send event message
 *
 * @param message Web view event message object
 */
const postWebViewEvent = (message: WebViewEventMessage): void => {
  const isWebView = getIsReactNativeWebView();

  if (isWebView === false) {
    return;
  }

  try {
    if (window.ReactNativeWebView === undefined) {
      throw new Error('postWebViewEvent called outside WebView');
    }

    const dataString = JSON.stringify(message);
    window.ReactNativeWebView.postMessage(dataString);
  } catch (error) {
    reportError(error as Error);
  }
};

/**
 * If app is running inside of the webview inform the native app that user has logged in
 */
export const postWebViewCookiesFlushEvent = (): void => {
  postWebViewEvent({
    data: null,
    eventName: WebViewEventNames.CookiesFlush,
  });
};

/**
 * Request device info from the native app
 */
export const postWebViewDeviceInfoEvent = (): void => {
  postWebViewEvent({
    eventName: WebViewEventNames.DeviceInfo,
  });
};

/**
 * Request firebase token from the native app
 */
export const postWebViewFirebaseTokenEvent = (): void => {
  postWebViewEvent({
    eventName: WebViewEventNames.FirebaseToken,
  });
};

/**
 * Request notification permissions from the native app
 */
export const postWebViewNotificationPermissionEvent = (): void => {
  postWebViewEvent({
    eventName: WebViewEventNames.NotificationPermission,
  });
};

/**
 * Inform the native app that user has triggered attachment action
 *
 * @param actionType   Attachment action type (download/preview)
 * @param downloadLink Download link for the current attachment
 */
export const postWebViewAttachmentActionEvent = (
  actionType: AttachmentActionType,
  downloadLink: string,
): void => {
  postWebViewEvent({
    data: {
      type: actionType,
      url: downloadLink,
    },
    eventName: WebViewEventNames.AttachmentAction,
  });
};

/**
 *
 * POST MESSAGES RESPONSE
 *
 * (Native -> Web)
 *
 */

/**
 * Handle response received from the Native app after asking for notification permissions
 *
 * @param callback Mutation for subscribing to push notifications
 * @param data     Data received from native app
 */
export const handleWebViewNotificationPermissionResponse = (
  callback: UseMutationExecute<
    PushSubscribeMutation,
    Exact<PushSubscribeMutationVariables>
  >,
  data: NotificationPermissionResponsePayload,
): void => {
  const {
    accepted,
    deviceId,
    deviceName,
    deviceType,
    fcmToken: token,
    isAccepted,
  } = data;

  if ((accepted === true || isAccepted === true) && token !== null) {
    callback({
      deviceId,
      deviceName,
      deviceType,
      token,
    }).catch(reportApiErrors);
  }
};
