/**
 * @file Helper function for meta input chevron direction
 */

import { ChevronDirection } from '../../Common/Chevron';

/**
 * Get which direction should the chevron point to
 *
 * @param isOpen     Whether the popup is open
 * @param isVertical Whether the popup is opening above (happens for desktop)
 * @returns          The direction to point to
 */
const getChevronDirection = (
  isOpen: boolean,
  isVertical: boolean,
): ChevronDirection => {
  if (isVertical) {
    return isOpen ? 'up' : 'down';
  }

  return 'right';
};

export default getChevronDirection;
