/**
 * @file Optimistically update the cache with new checklist item ordering
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  ChecklistItemReorderMutation,
  MessageFullDocument,
  MessageFullQuery,
  MessageFullQueryVariables,
  MutationChecklistReorderArgs,
} from '../../../../../generated/graphql';
import reorder from '../../../../../utils/reorder';

/**
 * Optimistically reorder checklist items,
 * making it look much more responsive
 *
 * Without this, there would be a small flicker,
 * where items would revert and then be ordered correctly once server response arrives
 *
 * @param args  Arguments of the GraphQL operation
 * @param cache Current GraphQL cache
 * @returns     Updated message
 */
const checklistReorder: OptimisticMutationResolver<
  MutationChecklistReorderArgs,
  ChecklistItemReorderMutation['checklistReorder'] | null
> = (args, cache) => {
  const { data, messageId } = args;

  const dataQuery = cache.readQuery<
    MessageFullQuery,
    MessageFullQueryVariables
  >({
    query: MessageFullDocument,
    variables: {
      messageId,
    },
  });

  const checklistOld = dataQuery?.message?.checklist;

  // The message or the checklist weren't found in cache
  if (checklistOld === undefined || checklistOld.length === 0) {
    return null;
  }

  const [{ checklistId, newPosition }] = data;

  const oldPosition = checklistOld.findIndex(item => item.id === checklistId);

  const checklistNew = reorder(checklistOld, oldPosition, newPosition);

  return {
    __typename: 'Message',
    checklist: checklistNew,
    id: messageId,
  };
};

export default checklistReorder;
