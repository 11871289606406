/**
 * @file Hook that returns topicEditMode if there is one
 */
import { useParams } from 'react-router-dom';

import { FeedParams, TopicEditModeParam } from '../../../models/pageParams';

/**
 * Hook that returns topicEditMode if there is one
 *
 * @returns Current topicEditMode or null
 */
const useParamTopicEditMode = (): TopicEditModeParam | null => {
  const { topicEditMode } = useParams<FeedParams>();

  if (!topicEditMode) {
    return null;
  }

  return topicEditMode;
};

export default useParamTopicEditMode;
