import { styled } from 'styled-components';

import CommonMessageSingle from '../../../Common/MessageSingle';

type MessageSingleProps = {
  'data-is-seen': boolean;
};

// Wrapper for a single message in the list
export const MessageSingle = styled(CommonMessageSingle)<MessageSingleProps>`
  position: relative;

  &::before {
    background-color: var(--color-message-new);
    content: '';
    display: ${props => (props['data-is-seen'] === false ? 'block' : 'none')};
    height: calc(100% - 2 * var(--spacing-medium));
    left: 0;
    position: absolute;
    top: var(--spacing-medium);
    width: var(--spacing-tiny);
  }
`;
