import React, { FC, useLayoutEffect, useState } from 'react';

import { HASH_MESSAGE__INFO__ATTACHMENT } from '../../constants/routing/hashLinks';
import { File as CustomFile, Message } from '../../generated/graphql';
import useAttachmentsUpload from '../../hooks/attachments/useAttachmentsUpload';

import translate from '../../i18n/translate';
import { Attachment, AttachmentDraft } from '../../models/attachment';
import MessageAttachment from '../Common/MessageAttachments';

import MessageSection from './MessageSection';
import { getCombinedAttachments } from './helpers/attachments';

type Props = {
  canDeleteFile: boolean;
  canDownloadFile: boolean;
  canUploadFile: boolean;
  files: CustomFile[];
  messageId: Message['id'];
};

/**
 * Section in the message that displays the attachments
 *
 * @param props                 Props passed to the component
 * @param props.canDeleteFile   Whether user has permission to delete attachment
 * @param props.canDownloadFile Whether user has permission to delete attachment
 * @param props.canUploadFile   Whether the user can upload attachments
 * @param props.files           Files attached to the current message
 * @param props.messageId       Id of the current message
 * @returns                     The component itself
 */
const MessageSectionAttachments: FC<Props> = ({
  canDeleteFile,
  canDownloadFile,
  canUploadFile,
  files,
  messageId,
}) => {
  const [attachmentsCombined, setAttachmentsCombined] = useState<
    (Attachment | AttachmentDraft)[]
  >([]);
  const { attachmentsDraft, inputProps } = useAttachmentsUpload(
    'EDIT' + messageId,
    messageId,
    attachmentsCombined.length,
  );

  useLayoutEffect(() => {
    setAttachmentsCombined(getCombinedAttachments(files, attachmentsDraft));
  }, [attachmentsDraft, files]);

  return (
    <MessageSection
      heading={translate('MESSAGE__HEADING__ATTACHMENTS')}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__ATTACHMENT}
    >
      <MessageAttachment
        attachments={attachmentsCombined}
        canDeleteFile={canDeleteFile}
        canDownloadFile={canDownloadFile}
        canUploadFile={canUploadFile}
        inputFileProps={inputProps}
        messageId={messageId}
      />
    </MessageSection>
  );
};

export default MessageSectionAttachments;
