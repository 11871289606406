/**
 * @file Handles cache data formatting after data has been retrieved from the API
 */

import { ResolverConfig } from '@urql/exchange-graphcache';

import { relayPagination } from '@urql/exchange-graphcache/extras';

import { esPagination } from './esPagination';
import { esPaginationRanked } from './esPaginationRanked';

/**
 * Handles cache data formatting after data has been retrieved from the API
 */
const resolvers: ResolverConfig = {
  Query: {
    comments: relayPagination(),
    messageRankedSearch: esPaginationRanked(),
    messageSearch: esPagination,
    messages: relayPagination(),
  },
};

export default resolvers;
