import { Close, Root } from '@radix-ui/react-toast';
import { keyframes, styled } from 'styled-components';

import { ToastMessageModel } from '../../../models/toastMessage';
import devices from '../../../styles/devices';

import { getToastColorBackground, getToastColorPrimary } from './helpers';

type MessageWrapperProps = {
  'data-message-type': ToastMessageModel['type'];
};

const hide = keyframes`
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0)
  }
`;

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x))
  }
  to {
    transform: translateX(100%)
  }
`;

export const MessageWrapper = styled(Root)<MessageWrapperProps>`
  background-color: ${getToastColorBackground};
  border-bottom: 1px solid ${getToastColorPrimary};
  border-radius: var(--border-radius-small);
  display: flex;
  font-size: var(--font-baseline);
  margin-bottom: var(--spacing-small);
  padding: var(--spacing-small);
  position: relative;
  width: 100%;

  @media ${devices.tabletS} {
    border: 1px solid ${getToastColorPrimary};
    width: 380px;
  }

  @media (prefers-reduced-motion: no-preference) {
    /**
      * Timings for all these animations come from the RadixUi library
      * @see https://www.radix-ui.com/docs/primitives/components/toast#animating-swipe-gesture
     */
    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in forwards;
    }

    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }

    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out forwards;
    }
  }
`;

export const MessageWrapperNoAnimation = styled(MessageWrapper)`
  &[data-state='open'] {
    animation: none;
  }
`;

export const MessageClose = styled(Close)`
  display: none;
  position: absolute;
  right: var(--spacing-tiny);
  top: var(--spacing-tiny);

  @media ${devices.tabletS} {
    display: block;
  }
`;
