import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import { MessageCommon } from '../../../../models/message';
import intlPluralFormatter from '../../../../utils/intlPluralFormat';
import { getCreatorName } from '../../../../utils/message';
import userGetName from '../../../../utils/user/userGetName';
import 'tippy.js/dist/tippy.css';

export type Props = Pick<MessageCommon, 'creator' | 'creatorType'>;

/**
 * Shows info about message creator and when its created
 *
 * @param props             Props passed to the component
 * @param props.creator     Author of the message
 * @param props.creatorType Message creator type
 * @returns                 The component itself
 */
const MessageInfoCreatorName: FC<Props> = ({ creator = null, creatorType }) => {
  const groups = creator?.userGroups ?? [];

  if (groups.length > 0) {
    const content = intlPluralFormatter(groups.map(group => group.title));

    return (
      <Tippy content={content} touch={false}>
        <p tabIndex={0}>{userGetName(creator)}</p>
      </Tippy>
    );
  }

  const name = getCreatorName(creatorType, creator);

  return <p>{name}</p>;
};

export default MessageInfoCreatorName;
