/**
 * @file GroupUpdate mutation was successfully executed, so we update the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  GroupUpdateMutation,
  GroupUpdateMutationVariables,
} from '../../../../../generated/graphql';
import groupTitleUpdate from '../utils/updaters/groups/groupTitleUpdate';

/**
 * GroupUpdate mutation was successfully executed, so we update the cache
 *
 * @param _result The result of the mutation (unused)
 * @param args    Arguments of a graphQL operation
 * @param cache   URQL graphQL cache
 */
const groupUpdateMutation: UpdateResolver<
  GroupUpdateMutation,
  GroupUpdateMutationVariables
> = (_result, args, cache) => {
  // "AlwaysSort" needs to be true for alphabetically sorting after editing a group
  const alwaysSort = true;
  groupTitleUpdate(cache, args, alwaysSort);
};

export default groupUpdateMutation;
