/**
 * @file contains mapDispatchToProps function for comments container
 */
import { Dispatch } from 'redux';

import { Message } from '../../generated/graphql';
import { SubscriptionsAction } from '../../models/subscriptions';
import { subscribeToCommentsSet } from '../../store/actions/subscriptions';

import { DispatchProps } from './props';

/**
 * Add Redux dispatch actions to component props
 *
 * @param dispatch Redux dispatch callback
 * @returns        The props to add
 */
const mapDispatchToProps = (
  dispatch: Dispatch<SubscriptionsAction>,
): DispatchProps => ({
  requestSubscribeToComments: (messageId: Message['id']) => {
    dispatch(subscribeToCommentsSet(messageId));
  },
});

export default mapDispatchToProps;
