import React from 'react';

import Drawer from '../../components/MUI/Drawer';

import useUserSelectedHotel from '../../hooks/hotel/useUserSelectedHotel';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';

import useUnreadMessagesCount from './useUnreadMessagesCount';

/**
 * The left sidebar used in desktop navigation
 *
 * @returns The container itself
 */
const LeftSidebarContainer = () => {
  const hotelId = useParamHotelId();
  const { hasMore, hotel } = useUserSelectedHotel(hotelId);
  const topicNotificationsCount = useUnreadMessagesCount();

  return (
    <Drawer
      currentHotel={hotel}
      hasMoreHotels={hasMore}
      nrUnreadTopics={topicNotificationsCount}
    />
  );
};

export default LeftSidebarContainer;
