/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import {
  ILLUSTRATION__CONCIERGE,
  ILLUSTRATION__TASK,
} from '../../../../constants/illustrations';
import { cardIllustrationTypes } from '../../../../models/dashboard';

import Illustration from '../../../Common/Illustration';
import DashboardCard from '../../../Dashboard/Card';
import {
  ConciergeDataWrapper,
  ConciergeImageWrapper,
  ConciergesWrapper,
} from '../../../Dashboard/ConciergesDesktop/styled';
import { List } from '../../../Dashboard/RequestsDesktop/styled';
import {
  TaskDataWrapper,
  TaskImageWrapper,
  TasksWrapper,
} from '../../../Dashboard/TasksDesktop/styled';

import KpiSingle from '../KpiSingle';
import { CardContentWrapper } from '../styled';

type Props = {
  kpiNumber?: number;
};
/**
 * Loading content for dashboard desktop
 *
 * @param props           Props passed to the component
 * @param props.kpiNumber Number of skeletons to be shown for each kpi
 * @returns               The component itself
 */
const DashboardSkeletonDesktop: FC<Props> = ({ kpiNumber = 3 }) => {
  const sample = Array.from({ length: kpiNumber });
  return (
    <>
      <TasksWrapper aria-busy="true">
        <TaskDataWrapper>
          {sample.map((_element, index) => {
            return <KpiSingle direction="row" key={index} />;
          })}
        </TaskDataWrapper>
        <TaskImageWrapper>
          <Illustration height={200} name={ILLUSTRATION__TASK} width={215} />
        </TaskImageWrapper>
      </TasksWrapper>
      <List aria-busy="true">
        {sample.map((_element, index) => {
          return (
            <li key={index}>
              <DashboardCard type={cardIllustrationTypes[index]} url="#">
                <CardContentWrapper>
                  <KpiSingle direction="column" />
                </CardContentWrapper>
              </DashboardCard>
            </li>
          );
        })}
      </List>
      <ConciergesWrapper aria-busy="true">
        <ConciergeImageWrapper>
          <Illustration
            height={200}
            name={ILLUSTRATION__CONCIERGE}
            width={215}
          />
        </ConciergeImageWrapper>
        <ConciergeDataWrapper>
          {sample.map((_element, index) => {
            return <KpiSingle direction="column" key={index} />;
          })}
        </ConciergeDataWrapper>
      </ConciergesWrapper>
    </>
  );
};

export default DashboardSkeletonDesktop;
