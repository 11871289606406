import React, {
  FC,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  MESSAGE__TEXT_REQUIRED,
  MESSAGE__TITLE_REQUIRED,
} from '../../../constants/forms';

import translate from '../../../i18n/translate';
import { EditorUsage } from '../../Common/Editor/types';

import * as Styled from './styled';

export type Props = {
  apiError?: string | null;
  descriptionUsage: EditorUsage;
  formId?: string;
  isDisabled: boolean;
  maxLength?: HTMLInputElement['maxLength'];
  requestSaveDisabled: (isDisabled: HTMLButtonElement['disabled']) => void;
  shouldFocus?: boolean;
  requestSave: (text: string, title: string) => void;
  text: string;
  title: string;
};

const inputValueText = 'inputElementText';
const inputValueTitle = 'inputElementTitle';

/**
 * Update Message title or description form component
 *
 * @param props                     Props passed to the component
 * @param props.apiError            ApiError if request fails (eg. duplicate topic title)
 * @param props.isDisabled          Whether that user has the permission to edit the message
 * @param props.descriptionUsage    What the textarea is used for
 * @param props.formId              The formId, optional argument
 * @param props.maxLength           Maximum number of characters for the form field
 * @param props.requestSave         Callback that updates the message's title
 * @param props.requestSaveDisabled Callback that toggles save disabled
 * @param props.text                Message current text value (or title if mode is 'title')
 * @param props.title               Message current title value (only used if mode is 'titleAndDescription')
 * @param props.shouldFocus         Should the input be auto focused
 * @returns                         The component itself
 */
const MessageTextForm: FC<Props> = ({
  apiError = null,
  descriptionUsage,
  formId,
  isDisabled,
  maxLength,
  requestSave,
  requestSaveDisabled,
  shouldFocus,
  text,
  title = '',
}) => {
  const [inputText, setInputText] = useState(text);
  const [inputTitle, setInputTitle] = useState(title);

  const formRef = useRef<HTMLFormElement>(null);
  const formLabel = translate('ARIA__FORM__MESSAGE_UPDATE');

  const inputFieldText = formRef.current?.elements.namedItem(
    inputValueText,
  ) as HTMLInputElement;
  const inputFieldTitle = formRef.current?.elements.namedItem(
    inputValueTitle,
  ) as HTMLInputElement;

  useEffect(() => {
    if (text === inputText && title === inputTitle) {
      requestSaveDisabled(true);
    }

    /**
     * Disable rule because we only want this to be fired when saved text is changed.
     * Other cases are covered in handleTextChange().
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, title]);

  /**
   * If an apiError occurs show the user the errorMessage
   */
  useEffect(() => {
    if (apiError !== null) {
      if (inputFieldText !== undefined) {
        inputFieldText.setCustomValidity(apiError);
        inputFieldText.reportValidity();
      }

      if (inputFieldTitle !== undefined) {
        inputFieldTitle.setCustomValidity(apiError);
        inputFieldTitle.reportValidity();
      }
    }
  }, [apiError, inputFieldText, inputFieldTitle]);

  /**
   * Set text value and check if save button should be disabled
   * Also reset customValidity (red border input field) when an
   * apiError was triggered previously
   *
   * @param value New value of text input
   */
  const handleTextChange = (value: string): void => {
    if (apiError && inputFieldText !== undefined) {
      inputFieldText.setCustomValidity('');
    }

    setInputText(value);

    if (requestSaveDisabled) {
      requestSaveDisabled(text === value);
    }
  };

  /**
   * Set text value and check if save button should be disabled
   * Also reset customValidity (red border input field) when an
   * apiError was triggered previously
   *
   * @param value New value of text input
   */
  const handleTitleChange = (value: string): void => {
    if (apiError && inputFieldTitle !== undefined) {
      inputFieldTitle.setCustomValidity('');
    }
    setInputTitle(value);

    if (requestSaveDisabled) {
      requestSaveDisabled(title === value);
    }
  };

  /**
   * The form was submitted (perhaps by pressing enter within an input)
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (inputText !== text || inputTitle !== title) {
      requestSave(inputText, inputTitle);
    }
  };

  const propsCommon = {
    isDisabled,
    isLabelVisible: false,
    maxLength,
  };

  return (
    <form aria-label={formLabel} id={formId} onSubmit={onSubmit} ref={formRef}>
      <Styled.Field
        {...propsCommon}
        isRequired={MESSAGE__TITLE_REQUIRED}
        label={translate('GENERAL__TITLE')}
        name={inputValueTitle}
        onChange={handleTitleChange}
        shouldFocus={shouldFocus}
        value={inputTitle}
      />
      <Styled.Description
        {...propsCommon}
        isRequired={MESSAGE__TEXT_REQUIRED}
        label={translate('GENERAL__DESCRIPTION')}
        name={inputValueText}
        onChange={handleTextChange}
        usage={descriptionUsage}
        value={inputText}
      />
    </form>
  );
};

export default MessageTextForm;
