import React, { FC } from 'react';

import { Navigate } from 'react-router-dom';

import useToastPageNotFound from '../../../hooks/store/useToastPageNotFound';

import useRedirectPath from './hooks/useRedirectPath';

/**
 * Proxy route that redirects users after they get notifications
 *
 * @returns The component itself
 */
const PageProxy: FC = () => {
  const pushToast = useToastPageNotFound();

  const [shouldPushToast, redirectUrl] = useRedirectPath();

  // There was a problem, push 404 toast
  if (shouldPushToast) {
    pushToast();
  }

  // The data is being fetched + validated, waiting
  if (redirectUrl === null) {
    return null;
  }

  return <Navigate to={redirectUrl} />;
};

export default PageProxy;
