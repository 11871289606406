import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import { MODAL__TOPIC_CREATE } from '../constants/routeModalParams';
import { TopicsListDocument } from '../generated/graphql';

import translate from '../i18n/translate';
import AppState from '../models/state';
import { reportError } from '../services/reporting';
import { getCanCreateTopic } from '../utils/permissions/topic';

import LeftSidebarFeedContent from './leftSidebarFeed/LeftSidebarFeedContent';
import { getOpenModalUrl } from './modals/helpers';

type StateProps = {
  networkTopics: AppState['debug']['networkTopics'];
};

type Props = StateProps;

/**
 * The container for the topic list
 * Connects to the store, makes a data fetch request
 * and then uses the TopicList component to render the actual list
 *
 * @param props               Props passed to the container
 * @param props.networkTopics Whether the dev option for setting topics to fetching state is on
 * @returns                   The component itself
 */
const TopicsContainer: FC<Props> = ({ networkTopics }) => {
  const location = useLocation();
  const [{ data, error, fetching }] = useQuery({
    query: TopicsListDocument,
    variables: {},
  });

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  const getUrlCreate = getCanCreateTopic()
    ? getOpenModalUrl(location, MODAL__TOPIC_CREATE, { redirect: true })
    : null;

  return (
    <LeftSidebarFeedContent
      getUrlCreate={getUrlCreate}
      isLoading={networkTopics === true || fetching === true}
      topics={data?.topics ?? []}
    />
  );
};

/**
 * Redux map state to props function
 *
 * @param state Entire redux app state
 * @returns     Props for a react component derived from redux state
 */
const mapStateToProps = (state: AppState): StateProps => ({
  networkTopics: state.debug.networkTopics,
});

export default connect(mapStateToProps)(TopicsContainer);
