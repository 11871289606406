import { ListItem } from '@mui/material';
import React, { FC } from 'react';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import useLogout from '../../../../hooks/useLogout';
import translate from '../../../../i18n/translate';
import getAvatarAltText from '../../../../utils/getAvatarAltText';
import { memberGetImageUrl } from '../../../../utils/imageUrlGet';
import userGetInitials from '../../../../utils/user/userGetInitials';

import * as Styled from './styled';

/**
 * Logout link as list item within the left sidebar rail
 *
 * @returns Component itself
 */
const RailListItemUser: FC = () => {
  const currentUser = useCurrentUser();
  const logout = useLogout();

  if (currentUser === null) {
    return null;
  }

  const { firstName, lastName, imageUrl } = currentUser;

  const userInitials = userGetInitials(firstName, lastName);
  const srcAbsolute = memberGetImageUrl(imageUrl);
  const altText = getAvatarAltText(currentUser);

  return (
    <ListItem disablePadding={true}>
      <Styled.LogoutButton onClick={logout} type="button">
        <Styled.AvatarBase alt={altText} src={srcAbsolute ?? undefined}>
          {userInitials}
        </Styled.AvatarBase>
        <Styled.RailListItemLabel
          // Prevents wrapping child in typography
          disableTypography={true}
        >
          {translate('GENERAL__LOGOUT')}
        </Styled.RailListItemLabel>
      </Styled.LogoutButton>
    </ListItem>
  );
};

export default RailListItemUser;
