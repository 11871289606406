import { styled } from 'styled-components';

/**
 * Wrapper for plain input,
 * used for everything except labels on mobile
 */
export const WrapperPlain = styled.div`
  /** Cut off labels that can't fit in a single line */
  overflow: hidden;
  width: 100%;
`;

/**
 * This looks like input, but actually it doesn't behave like one
 * (when the user clicks on it, the popup appears, they can't type within)
 */
export const Input = styled.span`
  border: none;
  display: block;
  flex-grow: 1;
  font-weight: var(--font-normal);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * The same as above, just with slightly different text styling,
 * to make it look like placeholder
 */
export const Placeholder = styled(Input)`
  color: var(--color-placeholder);
`;
