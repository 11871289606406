/**
 * @file Map Redux dispatch to compose props
 */

import { MapDispatchToProps } from 'react-redux';

import { clearDraftRequest } from '../../store/actions/draftsMain';
import {
  saveDraftTemplateRequest,
  setDraftTemplateRequest,
} from '../../store/actions/draftsTemplate';

import { DispatchProps, OwnProps } from './props';

/**
 * Map Redux dispatch to compose props
 *
 * @param dispatch          Redux dispatch
 * @param ownProps          Props passed to the container
 * @param ownProps.nodeType The key for the Redux store draft
 * @returns                 Props to add to compose
 */
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  requestDraftClear: () => dispatch(clearDraftRequest(nodeType)),
  requestTemplateSave: () => dispatch(saveDraftTemplateRequest(nodeType)),
  requestTemplateSet: templateId => {
    dispatch(setDraftTemplateRequest(nodeType, templateId));
  },
});

export default mapDispatchToProps;
