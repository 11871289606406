import { styled } from 'styled-components';

import Icon from '../../Icon';

// Common styles for all toast icons, not used directly
const IconCommon = styled(Icon)`
  border-radius: var(--border-radius-small);
  flex-shrink: 0;
`;

// The icon for 'error' toast type
export const IconError = styled(IconCommon)`
  background-color: var(--color-icons-danger);
`;

// The icon for 'info' toast type
export const IconInfo = styled(IconCommon)`
  background-color: var(--color-primary-light);
`;

// The icon for 'notification' toast type
export const IconNotification = styled(IconCommon)`
  background-color: var(--color-background-avatar);
  padding: var(--spacing-small);
`;
