import { styled } from 'styled-components';

export const Editor = styled.div`
  word-break: break-word;

  & a {
    color: var(--color-primary-light);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & ol {
    list-style-type: decimal;
  }

  & dl,
  & ul {
    list-style-type: disc;
  }

  & ol,
  & ul {
    margin: var(--spacing-medium) 0;
    padding-left: var(--spacing-large);
  }

  & ul ul {
    list-style-type: disc;
  }

  & ol ol,
  & ul ul {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
