/**
 * @file Hook to generate redirect path for proxy page
 */

import { NotificationEvent } from '../../../../generated/graphql';
import useMessageAccessible from '../../../../hooks/message/useMessageAccessible';
import useSearchParams from '../../../../hooks/router/search/useSearchParams';
import useTopicAccessible from '../../../../hooks/topics/useTopicAccessible';
import { getNoMatchUrl } from '../../../urls/404';
import { getTopicUrl } from '../../../urls/topic';
import { getProxyData, validateProxyData } from '../helpers';
import mappers from '../mappers';

/**
 * Hook to generate redirect path for proxy page
 *
 * @returns Whether there was an error and where to redirect to
 */
const useRedirectPath = (): [boolean, string | null] => {
  const paramsQuery = useSearchParams();
  const isValid = validateProxyData(paramsQuery);

  const paramsProxy = getProxyData(paramsQuery);
  const { event, hotelId, messageId, topicId } = paramsProxy;

  const [messageAccessible, fetchingMessage] = useMessageAccessible(
    // Don't want to fetch message that doesn't exist (and cause error)
    event === NotificationEvent.MessageDeleted ? null : messageId,
  );
  const [topicIdAccessible, fetchingTopic] = useTopicAccessible(topicId);

  if (isValid === false) {
    // No need to report error here, it's done within validateProxyData
    return [true, getNoMatchUrl()];
  }

  // Safe to use typecasting, because isValid would be false
  // if event is not of type NotificationEvent
  const pathGenerator = mappers[event as NotificationEvent];

  /**
   * Get whether we should redirect the user to a default topic
   * (meaning they don't have access to requested topic and/or message)
   *
   * @returns Whether we should redirect
   */
  const getShouldRedirect = (): boolean => {
    // For message/topic deleted event, message/topicId are going to be non null
    // But obviously inaccessible, so we don't redirect in that case
    return (
      (event !== NotificationEvent.TopicDeleted &&
        topicId !== null &&
        topicId !== topicIdAccessible) ||
      (event !== NotificationEvent.MessageDeleted &&
        messageId !== null &&
        messageId !== messageAccessible?.id)
    );
  };

  // While fetching, don't redirect
  if (fetchingMessage || fetchingTopic) {
    return [false, null];
  }

  if (getShouldRedirect()) {
    reportError('Proxy: Could not access message or topic');
    return [true, getTopicUrl(hotelId, null, topicIdAccessible)];
  }

  return [false, pathGenerator(paramsProxy)];
};

export default useRedirectPath;
