import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
} from '@hello-pangea/dnd';
import React, { FC, ReactElement } from 'react';

import reorder from '../../../utils/reorder';

import ListItemDraft from './ListItemDraft';
import ListItemExisting from './ListItemExisting';
import { PropsDraggableUnion } from './props';

/**
 * Draggable checklist
 *
 * @param props                 Props passed to the component
 * @param props.items           The items to display
 * @param props.mode            Whether we're working with draft or existing message's checklist
 * @param props.requestDelete   Request that the current item is removed
 * @param props.requestReorder  Request that the list is reordered
 * @param props.requestSetState Request that the current item is (un)checked in the state
 * @param props.requestSetText  Request that a checklist item's text is updated
 * @returns                     The component itself
 */
const ChecklistDraggable: FC<PropsDraggableUnion> = props => {
  /**
   * The user has finished dragging an item
   *
   * @param result The data related to dragging finish
   */
  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (result.destination === null || result.destination === undefined) {
      return;
    }

    if (props.mode === 'DRAFT') {
      const reordered = reorder(
        props.items,
        result.source.index,
        result.destination.index,
      );
      props.requestReorder(reordered);
    } else {
      if (props.requestReorder !== null) {
        props.requestReorder(result.draggableId, result.destination.index);
      }
    }
  };

  /**
   * Render the draggable list itself
   * Extracted like this to have DRAFT and EXISTING modes covered
   *
   * @param provided Metadata needed to make items draggable
   * @returns        The list to inject
   */
  const renderList = (provided: DroppableProvided): ReactElement => {
    if (props.mode === 'DRAFT') {
      return (
        <ul {...provided.droppableProps} ref={provided.innerRef}>
          {props.items.map((item, index) => {
            const itemId = item.position.toString();

            return (
              <Draggable draggableId={itemId} index={index} key={itemId}>
                {providedData => (
                  <li
                    ref={providedData.innerRef}
                    {...providedData.draggableProps}
                    {...providedData.dragHandleProps}
                    role="listitem"
                  >
                    <ListItemDraft
                      item={item}
                      mode={props.mode}
                      requestDelete={props.requestDelete}
                      requestSetState={props.requestSetState}
                      requestSetText={props.requestSetText}
                    />
                  </li>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </ul>
      );
    }

    return (
      <ul {...provided.droppableProps} ref={provided.innerRef}>
        {props.items.map((item, index) => {
          const itemId = item.id;

          return (
            <Draggable draggableId={itemId} index={index} key={itemId}>
              {providedData => (
                <li
                  ref={providedData.innerRef}
                  {...providedData.draggableProps}
                  {...providedData.dragHandleProps}
                  role="listitem"
                >
                  <ListItemExisting
                    item={item}
                    mode={props.mode}
                    requestDelete={props.requestDelete}
                    requestSetState={props.requestSetState}
                    requestSetText={props.requestSetText}
                  />
                </li>
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </ul>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">{renderList}</Droppable>
    </DragDropContext>
  );
};

export default ChecklistDraggable;
