import { ChipProps } from '@mui/material';
import React, { ElementType } from 'react';

import * as Styled from './styled';

type Props = {
  backgroundColor?: string;
  borderColor?: string;
} & ChipProps;

/**
 * Squared variant of chip component
 *
 * @param props Props passed to the component
 * @returns     The component itself
 */
export const ChipSquared = <C extends ElementType>(
  props: Props & ChipProps<C, { component?: C }>,
) => {
  const { backgroundColor, borderColor, ...chipProps } = props;

  return (
    <Styled.CustomChip
      {...chipProps}
      data-bg-color={backgroundColor}
      data-border-color={borderColor}
      variant={borderColor !== undefined ? 'outlined' : 'filled'}
    />
  );
};
