/**
 * @file contains key-value translations that are used
 * when the user is not yet authenticated
 *
 * Language: Italian
 */

export default {
  ERROR__404__ACTION: 'Vai alla pagina iniziale',
  ERROR__404__DESCRIPTION:
    'Siamo spiacenti, questa pagina non esiste. Si prega di riprovare con un URL valido.',
  ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION:
    "Purtroppo non è stato possibile trovare l'oggetto. Contatta il tuo amministratore o la nostra assistenza.",
  ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION:
    "Purtroppo non è stato possibile trovare un utente per questo identificativo. Si prega di riprovare con dati di accesso validi. Se l'errore persiste, contatta l'assistenza.",
  ERROR__DEACTIVATED_HOTEL__DESCRIPTION:
    "Il tuo nome utente è stato disattivato per l'oggetto. Contatta il tuo amministratore o la nostra assistenza.",
  ERROR__HOTEL__SWITCH_DESCRIPTION: 'Si desidera passare ad un altro oggetto?',
  ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX: 'Apri oggetto "',
  ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX: '"',
  ERROR__MISSING_HOTEL__DESCRIPTION:
    "Purtroppo non è possibile accedere all'oggetto. Contatta l'amministratore o la nostra assistenza",
  ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION:
    'Per accedere, si prega di accettare i termini e le condizioni generali.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE: 'Oops, manca qualcosa!',
  GENERAL__BACK_TO_LOGIN: 'Torna al login',
  GENERAL__OOPS: 'Oops, qualcosa è andato storto.',
};
