import React, { FC } from 'react';

import HotelAvatar from '../../components/MUI/HotelAvatar';
import useCurrentHotel from '../../hooks/useCurrentHotel';

/**
 * Hotel Avatar component for the header component
 *
 * @returns The component itself
 */
const HotelAvatarContainer: FC = () => {
  const hotel = useCurrentHotel();

  if (hotel === null) {
    return null;
  }

  return <HotelAvatar hotel={hotel} shouldShowHotelName={true} />;
};

export default HotelAvatarContainer;
