/**
 * @file contains functions used to create paths for use in Route related to the reservations page
 */
import { ROUTE__RESERVATIONS } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the reservations page
 *
 * @returns The generated path
 */
export const createReservationsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__RESERVATIONS}`;
};
