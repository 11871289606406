/**
 * @file Redux actions related to debug state
 */

import {
  MARK_AS_READ__DEBOUNCE,
  MARK_AS_READ__DELAY,
} from '../../constants/actionTypes';
import {
  MarkAsReadDebounceSetAction,
  MarkAsReadDelaySetAction,
} from '../../models/markAsRead';

/**
 * Generate the action for setting the amount of time we wait
 * before making a mark-as-read API call
 *
 * @param payload The value to set
 * @returns       The generated action
 */
export const setMarkAsReadDebounce = (
  payload: number,
): MarkAsReadDebounceSetAction => ({
  payload,
  type: MARK_AS_READ__DEBOUNCE,
});

/**
 * Generate the action for setting amount of time we wait
 * before considering the message as read
 *
 * @param payload The value to set
 * @returns       The generated action
 */
export const setMarkAsReadDelay = (
  payload: number,
): MarkAsReadDelaySetAction => ({
  payload,
  type: MARK_AS_READ__DELAY,
});
