/**
 * @file Based on client height, determines whether the popup should be opened upwards or downwards
 */

import { RefObject, useLayoutEffect, useRef } from 'react';

type Args = {
  isOpen: boolean;
  placement: 'up' | 'down' | 'auto';
  wrapperRef: RefObject<HTMLDivElement>;
};

type Return = {
  isUpwards: boolean;
  popupRef: RefObject<HTMLDivElement>;
};

/**
 * Based on client height, determines whether the popup should be opened upwards or downwards
 *
 * @param args            Arguments passed to the hook
 * @param args.isOpen     Whether the popup is open or not. Used to force a recalculation after popup is opened.
 * @param args.placement  Placement of the popup, up, down or auto
 * @param args.wrapperRef Reference to div wrapper element in <MetaContainerPopup />
 * @returns               Object with refs and boolean for whether container is opened upward
 */
const usePopupPlacement = ({ isOpen, placement, wrapperRef }: Args): Return => {
  const popupRef = useRef<HTMLDivElement>(null);

  // Use ref in order to keep the value over various re-renders
  const upwardsRef = useRef<boolean>(placement === 'up');

  useLayoutEffect(() => {
    if (placement !== 'auto') {
      return;
    }

    const viewHeight = window.innerHeight;

    const listBoundingClientRect = popupRef.current?.getBoundingClientRect();
    const buttonBoundingClientRect =
      wrapperRef.current?.getBoundingClientRect();

    if (
      listBoundingClientRect === undefined ||
      buttonBoundingClientRect === undefined
    ) {
      return;
    }
    const { top: buttonTop } = buttonBoundingClientRect;
    const { height: menuHeight } = listBoundingClientRect;

    const viewSpaceBelow = viewHeight - buttonTop;

    upwardsRef.current = viewSpaceBelow < menuHeight;
  }, [isOpen, placement, wrapperRef]);

  return { isUpwards: upwardsRef.current, popupRef };
};

export default usePopupPlacement;
