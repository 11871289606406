import React, { FC } from 'react';

import translate from '../../../../i18n/translate';

import * as Styled from './styled';

type Props = {
  className?: string;
  onClick: () => void;
};

/**
 * Close button for the modal
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.onClick   Callback to invoke when the button is clicked
 * @returns               The component itself
 */
const ButtonClose: FC<Props> = ({ className, onClick }) => {
  const label = translate('GENERAL__CLOSE');
  return (
    <Styled.Button
      aria-label={label}
      className={className}
      onClick={onClick}
      type="button"
    >
      {'✕'}
    </Styled.Button>
  );
};

export default ButtonClose;
