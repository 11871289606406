import React, { FC, ReactNode } from 'react';

import translate from '../../../i18n/translate';

import SkeletonMessages from '../Messages';

type Props = {
  children: ReactNode;
  isInitialLoad: boolean;
  shouldShowAbove: boolean;
  shouldShowBelow: boolean;
};

/**
 * Skeleton for the feed
 *
 * @param props                 Props passed to the component
 * @param props.children        Children passed to the component
 * @param props.isInitialLoad   Whether the messages are fetching for the first time
 * @param props.shouldShowAbove Whether to render skeleton above the messages
 * @param props.shouldShowBelow Whether to render skeleton below the messages
 * @returns                     The component itself
 */
const SkeletonFeedWrapper: FC<Props> = ({
  children,
  isInitialLoad,
  shouldShowAbove,
  shouldShowBelow,
}) => {
  const Skeleton = (
    <SkeletonMessages
      avatarSize={40}
      description={translate('LOADING__MESSAGES')}
      nrMessages={5}
      shouldShowSkeleton={true}
    />
  );

  if (isInitialLoad) {
    return Skeleton;
  }

  return (
    <>
      {shouldShowBelow && Skeleton}
      {children}
      {shouldShowAbove && Skeleton}
    </>
  );
};

export default SkeletonFeedWrapper;
