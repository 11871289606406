import React, { ChangeEventHandler, FC } from 'react';

import Chevron from '../../../Common/Chevron';
import Icon, { NAMES } from '../../../Common/Icon';

import * as Styled from './styled';

type Props = {
  onChange: (state: HTMLInputElement['checked']) => void;
  state: HTMLInputElement['checked'];
  text: string;
};

/**
 * Toggle to show/hide the compose form
 * It looks different based on state, when compose is visible, it's just a chevron
 * But when it's not, it's a bigger button, with edit icon, "Create a Post" title and a chevron
 *
 * @param props          Props passed to the component
 * @param props.onChange Callback that's invoked when the toggle state is changed
 * @param props.state    Whether the compose form is currently visible or not
 * @param props.text     The text to show when state === false
 * @returns              The component itself
 */
const ComposeToggle: FC<Props> = ({ onChange, state, text }) => {
  /**
   * The user has toggled the checkbox, so we react to that
   *
   * @param event The event that took place
   */
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event.currentTarget.checked);
  };

  const chevronDirection = state ? 'down' : 'up';
  const chevronSize = state ? 7 : 18;

  return (
    <label>
      <Styled.Toggle checked={state} onChange={handleChange} type="checkbox" />
      <Styled.Wrapper>
        {state === false && (
          <>
            <Icon name={NAMES.COMPOSE__LABEL_EDIT} />
            <Styled.Title>{text}</Styled.Title>
          </>
        )}
        <Chevron
          color="currentColor"
          direction={chevronDirection}
          size={chevronSize}
        />
      </Styled.Wrapper>
    </label>
  );
};

export default ComposeToggle;
