import React, { FC } from 'react';

import {
  MESSAGE__MAX_TEXT_LENGTH,
  MESSAGE__MAX_TITLE_LENGTH,
} from '../../../constants/forms';
import {
  HASH_MESSAGE__INFO__DESCRIPTION,
  HASH_MESSAGE__INFO__TITLE,
} from '../../../constants/routing/hashLinks';
import { getMessageText } from '../../../formatters/concierge/form';
import useIsMobile from '../../../hooks/useIsMobile';

import useRedirectOnError from '../../../hooks/useRedirectOnError';

import { dateToString } from '../../../utils/date/format';
import {
  getCanArchiveMessage,
  getCanDeleteMessage,
  getCanUpdateMessageAssignment,
  getCanUpdateMessageBasics,
  getCanUpdateMessageMeta,
  getCanUpdateMessageStatus,
} from '../../../utils/permissions/message';
import {
  getCanDeleteAttachment,
  getCanDownloadAttachment,
  getCanUploadAttachmentExistingMessage,
} from '../../../utils/permissions/messageAttachments';
import MessageArchive from '../../Common/MessageArchive';

import MessageDelete from '../MessageDelete';
import MessageSectionChecklist from '../MessageSection/MessageSectionChecklist';
import MessageSectionText from '../MessageSection/MessageSectionText';
import MessageSectionAssignees from '../MessageSectionAssignees';
import MessageSectionAttachments from '../MessageSectionAttachments';
import MessageSectionAuthor from '../MessageSectionAuthor';
import MessageSectionDateDue from '../MessageSectionDateDue';
import MessageSectionLabels from '../MessageSectionLabels';
import MessageSectionReadBy from '../MessageSectionReadBy';
import MessageSectionStatus from '../MessageSectionStatus';
import {
  requestCreateHelper,
  requestDeleteHelper,
  requestReorderHelper,
  requestSetStateHelper,
  requestSetTextHelper,
} from '../helpers/checklist';
import { MessageFullProps as Props } from '../props';

/**
 * Full message details, used for the right sidebar
 *
 * @param props                          Props passed to the component
 * @param props.message                  The message to show the details for
 * @param props.requestChecklistCreate   Request that a checklist item is added
 * @param props.requestChecklistDelete   Request that an item is deleted
 * @param props.requestChecklistReorder  Request reordering of checklist items
 * @param props.requestChecklistSetState Request state update for an item
 * @param props.requestChecklistSetText  Request text update for an item
 * @param props.requestTextUpdate        Request message text update
 * @param props.requestTitleUpdate       Request message title update
 * @param props.translation              Translations for the message
 * @returns                              The component itself
 */
const MessageFull: FC<Props> = ({
  message,
  requestChecklistCreate,
  requestChecklistDelete,
  requestChecklistReorder,
  requestChecklistSetState,
  requestChecklistSetText,
  requestTextUpdate,
  requestTitleUpdate,
  translation,
}) => {
  const isMobile = useIsMobile();
  const handleErrorRedirect = useRedirectOnError();

  /**
   * If user directly navigates to details page of a message that doesn't exist error will be thrown
   * and empty right sidebar will be shown. To recover from that we redirect to the topic page.
   */
  if (message === null) {
    handleErrorRedirect();
    return null;
  }

  const canDelete = getCanDeleteMessage(message);
  const canUpdateBasics = getCanUpdateMessageBasics(
    translation !== null,
    message,
  );
  const canUpdateMeta = getCanUpdateMessageMeta(message);
  const canUpdateStatus = getCanUpdateMessageStatus(message);

  const messageTitle = translation?.title ?? message.title;
  const messageText = translation?.text ?? getMessageText(message);

  return (
    <>
      <MessageSectionText
        canUpdateMessage={canUpdateBasics}
        id={HASH_MESSAGE__INFO__TITLE}
        maxLength={MESSAGE__MAX_TITLE_LENGTH}
        mode="title"
        requestMessageTextUpdate={requestTitleUpdate}
        text={messageTitle}
      />
      <MessageSectionText
        canUpdateMessage={canUpdateBasics}
        id={HASH_MESSAGE__INFO__DESCRIPTION}
        maxLength={MESSAGE__MAX_TEXT_LENGTH}
        mode="description"
        requestMessageTextUpdate={requestTextUpdate}
        text={messageText}
      />
      <MessageSectionAttachments
        canDeleteFile={getCanDeleteAttachment(message)}
        canDownloadFile={getCanDownloadAttachment(message)}
        canUploadFile={getCanUploadAttachmentExistingMessage(message)}
        files={message.files}
        messageId={message.id}
      />
      <MessageSectionAssignees
        canAssignUsers={getCanUpdateMessageAssignment(message)}
        groups={message.assignedGroups}
        users={message.assignedMembers}
      />
      {isMobile === false && (
        <MessageSectionStatus
          canUpdateStatus={canUpdateStatus}
          messageId={message.id}
          status={message.status}
        />
      )}
      <MessageSectionDateDue
        canUpdateDate={canUpdateMeta}
        date={dateToString(message.dueDate)}
        status={message.status}
      />
      <MessageSectionChecklist
        items={message.checklist}
        requestCreate={requestCreateHelper(message, requestChecklistCreate)}
        requestDelete={requestDeleteHelper(message, requestChecklistDelete)}
        requestReorder={requestReorderHelper(message, requestChecklistReorder)}
        requestSetState={requestSetStateHelper(
          message,
          requestChecklistSetState,
        )}
        requestSetText={requestSetTextHelper(message, requestChecklistSetText)}
        translation={translation?.checklist}
      />
      <MessageSectionLabels
        canUpdateLabels={canUpdateMeta}
        labels={message.labels}
      />
      <MessageSectionReadBy message={message} />
      <MessageSectionAuthor
        author={message.creator}
        createdAtRaw={dateToString(message.createdAt)}
        creatorType={message.creatorType}
      />
      {isMobile && (
        <>
          <MessageDelete messageId={message.id} shouldShowContent={canDelete} />
          <MessageArchive
            messageId={message.id}
            shouldShowContent={getCanArchiveMessage()}
          />
        </>
      )}
    </>
  );
};

export default MessageFull;
