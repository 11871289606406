/**
 * @file Helper functions for MessageDetails page
 */

import { CombinedError } from 'urql';

import { Message, MessageFullQuery } from '../../../generated/graphql';

/**
 * Get whether there was a problem accessing the message
 * And if so, to redirect the user away from it
 *
 * @param data      The fetched data
 * @param error     The error occurred during fetching
 * @param fetching  Whether the message is being fetched
 * @param messageId The ID of the message being viewed
 * @returns         Whether to redirect the user
 */
export const getShouldRedirect = (
  data: MessageFullQuery | undefined,
  error: CombinedError | undefined,
  fetching: boolean,
  messageId: Message['id'],
) => {
  return (
    error !== undefined ||
    messageId === '' ||
    // IMPORTANT: Data will be null when error is not undefined
    (fetching === false && !data?.message)
  );
};
