import React, { FC } from 'react';

import translate from '../../../../i18n/translate';

import * as Styled from './styled';

/**
 * "Nothing to show" message for the checklist
 *
 * @returns The component itself
 */
const ChecklistEmpty: FC = () => {
  const message = translate('CHECKLIST__EMPTY');
  return <Styled.Title>{message}</Styled.Title>;
};

export default ChecklistEmpty;
