/**
 * @file contains active concierge field types
 */

import {
  CHECKBOX,
  ConciergeFieldType,
  DATE,
  DATETIME,
  DESCRIPTION,
  FEEDBACK_EMOJI,
  FEEDBACK_STARS,
  RADIOBOX_GROUP,
  SUBTITLE,
  TEXT,
  TEXT_AREA,
  TIME,
} from './conciergeFieldTypes';

/**
 * Concierge form field types that should show up in hotelboard
 * (eg. we don't want SUBMIT or DIVIDER)
 */
const conciergeFieldTypesActive: ConciergeFieldType[] = [
  CHECKBOX,
  DATE,
  DATETIME,
  DESCRIPTION,
  FEEDBACK_EMOJI,
  FEEDBACK_STARS,
  RADIOBOX_GROUP,
  SUBTITLE,
  TEXT_AREA,
  TEXT,
  TIME,
];

export default conciergeFieldTypesActive;
