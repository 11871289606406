import React, { FC } from 'react';

import { TopicBasicFragment } from '../../../../../generated/graphql';

import translate from '../../../../../i18n/translate';
import MessageNoResult from '../../../../Common/MessageNoResult';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  isEditMode: boolean;
  topic?: TopicBasicFragment;
};

/**
 * Section in the template compose that displays the topics
 *
 * @param props            Props passed to the component
 * @param props.isEditMode Whether compose is in edit mode
 * @param props.topic      Currently selected topic
 * @returns                The component itself
 */
const ComposeMetaTopics: FC<Props> = ({ isEditMode, topic }) => {
  const heading = translate(
    isEditMode ? 'GENERAL__TOPIC' : 'MESSAGE__HEADING__TOPIC',
  );

  return (
    <ComposeMetaSection heading={heading} isDisabled={isEditMode}>
      {topic?.title ?? (
        <MessageNoResult
          message={translate('TEMPLATE__TOPICS__MESSAGE__NONE')}
        />
      )}
    </ComposeMetaSection>
  );
};

export default ComposeMetaTopics;
