/**
 * @file contains redux actions related to toast messages state
 */

import {
  TOAST__ADD,
  TOAST__REMOVE,
  TOAST__SET__DURATION,
} from '../../constants/actionTypes';
import {
  ToastMessageActionAdd,
  ToastMessageActionDuration,
  ToastMessageActionRemove,
  ToastMessageArgs,
} from '../../models/toastMessage';
import generateUuid from '../../utils/user/uuidGenerator';

/**
 * Generate the action for adding toast message
 *
 * @param payload Message
 * @returns       The generated action
 */
export const addToastMessage = (
  payload: ToastMessageArgs,
): ToastMessageActionAdd => ({
  payload: { ...payload, id: generateUuid() },
  type: TOAST__ADD,
});

/**
 * Generate the action for adding duration to toast message
 *
 * @param payload Message
 * @returns       The generated action
 */
export const setDurationToastMessage = (
  payload: number,
): ToastMessageActionDuration => ({
  payload,
  type: TOAST__SET__DURATION,
});

/**
 * Generate the action for deleting toast message
 *
 * @param payload Message ID
 * @returns       The generated action
 */
export const removeToastMessage = (
  payload: string,
): ToastMessageActionRemove => ({
  payload,
  type: TOAST__REMOVE,
});
