/**
 * @file contains functions that handle global data storing
 */

import { Hotel, HotelStatus, MeQuery, Scalars } from '../../generated/graphql';
import { reportError, setBugsnagUserId } from '../../services/reporting';

let meData: MeQuery['me'] | null = null;
let hotelCurrentId: Scalars['ID']['output'] | null = null;

/**
 * Get permissions that the current user has
 * For the currently open hotel
 *
 * @returns The permissions that the user has (values from permissionsAll)
 */
export const getPermissionsCurrent = (): string[] => {
  if (meData === null || hotelCurrentId === null) {
    reportError(
      'getPermissionsCurrent: Reading user current before initializing it.',
    );
    return [];
  }
  const info = meData.hotelPermissions.find(
    row => row.hotel.id === hotelCurrentId,
  );

  if (info === undefined) {
    reportError(
      `getPermissionsCurrent: Permissions not found for current hotel: ${hotelCurrentId}.`,
    );
    return [];
  }

  return info.permissions;
};

/**
 * Get the current user info
 *
 * @returns The current user, if set or null if not
 */
export const getUserCurrent = (): MeQuery['me'] | null => {
  if (meData === null) {
    reportError('getUserCurrent: Reading user current before initializing it.');
  }

  return meData;
};

/**
 * Set the current user info
 *
 * @param data The user info
 */
export const setUserCurrent = (data: MeQuery['me']): void => {
  meData = data;
  setBugsnagUserId(meData.id);
};

/**
 * Set the ID for which hotel is the current board for
 *
 * @param hotelId The ID of the current hotel
 */
export const setHotelCurrentId = (hotelId: Hotel['id']): void => {
  hotelCurrentId = hotelId;
};

/**
 * Set the status of the currently viewed hotel
 *
 * @returns The hotel status
 */
export const getHotelStatus = (): HotelStatus | null => {
  if (meData === null || hotelCurrentId === null) {
    reportError('getHotelStatus: Reading user current before initializing it.');
    return null;
  }

  const permissions = meData.hotelPermissions.find(data => {
    return data.hotel.id === hotelCurrentId;
  });

  return permissions?.hotel.status ?? null;
};

/**
 * Get whether the hotel is a basic one
 * (has reduced functionality)
 *
 * @returns Whether the hotel is basic
 */
export const getIsBasic = (): boolean => {
  return getHotelStatus() === HotelStatus.Basic;
};
