import { css, styled } from 'styled-components';

const commonStyles = css`
  align-items: center;
  display: flex;
  font-weight: var(--font-semibold);
  padding: var(--spacing-medium) 0;
  width: 50%;
`;

export const TasksWrapper = styled.div`
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  display: flex;
  margin-bottom: var(--spacing-medium);
`;

export const TaskDataWrapper = styled.ul`
  flex-direction: column;
  ${commonStyles}
`;

export const TaskImageWrapper = styled.div`
  background-color: var(--color-background-list);
  justify-content: center;
  ${commonStyles}
`;

export const KpiWrapper = styled.li`
  width: 55%;
`;
