import React, { FC } from 'react';

import { PREFERENCE_IMAGES_ILLUSTRATIONS_BASE } from '../../../constants/preferences';

import { reportError } from '../../../services/reporting';

type Props = {
  className?: string;
  height: number;
  name: string;
  width: number;
};

/**
 * Common Illustration component to be used whenever we need to insert illustrations
 *
 * @param props           Props passed to the component
 * @param props.className Styled components className to give us styling options
 * @param props.height    Image height
 * @param props.name      Name of the illustration we want to use
 * @param props.width     Image width
 * @returns               Illustration
 */
const Illustration: FC<Props> = ({ className, height, name, width }) => {
  if (name === undefined) {
    reportError('Illustration: Invalid name supplied for the illustration.');
    return null;
  }

  return (
    <img
      alt=""
      className={className}
      height={height}
      src={`/${PREFERENCE_IMAGES_ILLUSTRATIONS_BASE}${name}`}
      width={width}
    />
  );
};

export default Illustration;
