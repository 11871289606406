/**
 * @file A label was created, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TemplateCreateMutation,
  TemplateCreateMutationVariables,
} from '../../../../../generated/graphql';
import templatesUpdate from '../utils/updaters/templates/templatesUpdate';

/**
 * Handle subscription to everything related to labels
 *
 * @param result The result of the mutation
 * @param _args  Arguments of a graphQL operation
 * @param cache  URQL graphQL cache
 */
const templateCreateMutation: UpdateResolver<
  TemplateCreateMutation,
  TemplateCreateMutationVariables
> = (result, _args, cache) => {
  templatesUpdate(cache, result.templateCreate);
};

export default templateCreateMutation;
