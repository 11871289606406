/**
 * @file contains main redux store configurations
 */

import {
  applyMiddleware,
  CombinedState,
  compose as composeWithoutDevTools,
  createStore,
  Middleware,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
// import logger from 'redux-logger';

import AppState from '../models/state';
import { isProduction, isTest } from '../utils/environment';

import initialState from './initialState';

import rootReducer from './reducers';

/**
 * Whether to use Redux's compose function or redux-devtools-extension's
 * wrapper around the same thing depends on the environment the code is running in
 * (no DevTools extension support for production)
 */
const compose: typeof composeWithDevTools = isProduction
  ? composeWithoutDevTools
  : composeWithDevTools;

/**
 * Middleware to be included for local/dev environments
 */
const middlewareDev: Middleware<unknown, unknown>[] = [
  // logger,
  reduxImmutableStateInvariant(),
];

/**
 * Middleware to be included for test environments
 */
const middlewareTest: Middleware<unknown, unknown>[] = [];

/**
 * Middleware to be included for production environments
 */
const middlewareProd: Middleware<unknown, unknown>[] = [];

/**
 * Configure Redux store and attach passed middleware
 *
 * @param middleware Which middleware to include
 * @returns          The created store
 */
const configureStore = (
  middleware: Middleware<unknown, unknown>[],
): Store<CombinedState<AppState>> => {
  const store = createStore(
    rootReducer(),
    initialState,
    compose(applyMiddleware(...middleware)),
  );

  return store;
};

/**
 * Configure Redux store for local or dev environments
 * (includes logging, hot reloading and DevTools support)
 *
 * @returns The created store
 */
const configureStoreDev = () => {
  const store = configureStore(middlewareDev);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    module.hot.accept('./reducers', async () => {
      const nextReducer = (await import('./reducers')).default;
      store.replaceReducer(nextReducer());
    });
  }

  return store;
};

/**
 * Configure Redux store for production environment
 *
 * @returns The created store
 */
const configureStoreTest = () => {
  return configureStore(middlewareTest);
};

/**
 * Configure Redux store for production environment
 *
 * @returns The created store
 */
const configureStoreProd = () => {
  return configureStore(middlewareProd);
};

/**
 * Configure appropriate store,
 * depending on the environment
 *
 * @returns Redux store config
 */
const configureStoreForEnv = () => {
  if (isTest) {
    return configureStoreTest();
  }

  if (isProduction) {
    return configureStoreProd();
  }

  return configureStoreDev();
};

export default configureStoreForEnv();
