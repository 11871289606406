import React, { FC } from 'react';

import { ILLUSTRATION__TOPIC } from '../../constants/illustrations';
import { Topic } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { getCanCreateMessage } from '../../utils/permissions/message';
import EmptyState from '../Common/EmptyState';

import * as Styled from './styled';

type Props = {
  title: Topic['title'] | null;
};

/**
 * Empty feed component
 *
 * @param props       Props passed to the component
 * @param props.title The title of the topic for which the feed is
 * @returns           The component itself
 */
const MessagesEmpty: FC<Props> = ({ title }) => {
  const keySubtitle = getCanCreateMessage()
    ? 'TOPIC__EMPTY__SUBTITLE'
    : 'TOPIC__EMPTY__SUBTITLE_LIMITED_PERMISSION';

  return (
    <Styled.EmptyStateWrapper>
      <EmptyState
        illustrationName={ILLUSTRATION__TOPIC}
        subtitle={translate(keySubtitle)}
        title={translate('TOPIC__EMPTY__TITLE', title ?? '')}
      />
    </Styled.EmptyStateWrapper>
  );
};

export default MessagesEmpty;
