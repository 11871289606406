import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol home icon
 *
 * @param props Props passed to the component
 * @returns     Home icon
 */
const MaterialSymbolHomeIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="home" />
);

export default MaterialSymbolHomeIcon;
