import React, { FC } from 'react';

import { LabelBasicFragment } from '../../../generated/graphql';

import * as Styled from './styled';

export type Props = {
  label: LabelBasicFragment;
};

/**
 * A single label
 *
 * @param props       Props passed to the component
 * @param props.label The label to show
 * @returns           The component itself
 */
const Label: FC<Props> = ({ label }) => {
  return <Styled.Title data-color={label.color}>{label.text}</Styled.Title>;
};

export default Label;
