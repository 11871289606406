import { styled } from 'styled-components';

import devices from '../../../styles/devices';

type WrapperProps = {
  'data-full-screen': boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: ${props =>
    props['data-full-screen'] ? 'var(--height-full)' : 'auto'};
  justify-content: center;
  padding-bottom: var(--spacing-large);
  padding-top: var(--spacing-large);

  @media ${devices.tabletS} {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const Content = styled.section`
  max-width: min(100%, 400px);
  padding: 0 var(--spacing-medium);

  @media ${devices.tabletS} {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  font-size: var(--font-xl);
  font-weight: var(--font-semibold);
  margin: var(--spacing-small) 0;
`;

export const Description = styled.p`
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-medium);
`;

export const ActionButton = styled.button`
  align-self: start;
  color: var(--color-link);
  font-weight: var(--font-semibold);

  &:hover {
    text-decoration: underline;
  }
`;
