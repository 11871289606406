/**
 * @file Hook that matches current path with out route paths to determine which page url is
 */
import { matchRoutes, useLocation } from 'react-router-dom';

import * as chatPathCreators from '../routes/paths/chat';
import * as devPanelPathsCreators from '../routes/paths/devPanel';
import * as errorPathsCreators from '../routes/paths/error';
import * as feedPathsCreators from '../routes/paths/feed';
import * as homePathsCreators from '../routes/paths/home';
import * as kanbanPathsCreators from '../routes/paths/kanban';
import * as morePathsCreators from '../routes/paths/more';
import * as proxyPathsCreators from '../routes/paths/proxy';
import * as reservationsPathsCreators from '../routes/paths/reservations';
import * as searchPathsCreators from '../routes/paths/search';
import * as settingsPathsCreators from '../routes/paths/settings';
import * as smartViewsPathsCreators from '../routes/paths/smartViews';
/**
 * Creates routes in order to be matched
 *
 * @param pathCreators Functions that create paths
 * @returns            An array of routes
 */
const createRoutes = (pathCreators: Record<string, () => string>) =>
  Object.values(pathCreators).map(createPath => {
    return {
      path: createPath(),
    };
  });

/**
 * All routes to be matched
 */
const routes = [
  ...createRoutes(chatPathCreators),
  ...createRoutes(devPanelPathsCreators),
  ...createRoutes(errorPathsCreators),
  ...createRoutes(feedPathsCreators),
  ...createRoutes(homePathsCreators),
  ...createRoutes(kanbanPathsCreators),
  ...createRoutes(morePathsCreators),
  ...createRoutes(proxyPathsCreators),
  ...createRoutes(reservationsPathsCreators),
  ...createRoutes(searchPathsCreators),
  ...createRoutes(settingsPathsCreators),
  ...createRoutes(smartViewsPathsCreators),
];

/**
 * Hook that determines the current rendered router path
 *
 * @returns Current path string
 */
const useCurrentPath = (): string | null => {
  const location = useLocation();

  const matches = matchRoutes(routes, location);

  if (!matches?.[0].route.path) {
    return null;
  }

  return matches[0].route.path;
};

export default useCurrentPath;
