import React, { ComponentProps, FC } from 'react';

import { TOAST__ICON_SIZE } from '../../../../constants/preferences';

import { ToastMessageModel } from '../../../../models/toastMessage';
import Icon, { NAMES } from '../../Icon';

import * as Styled from './styled';

type ToastType = ToastMessageModel['type'];
type Props = {
  type: ToastType;
};

const mappingComponents: Record<ToastType, FC<ComponentProps<typeof Icon>>> = {
  error: Styled.IconError,
  info: Styled.IconInfo,
  notification: Styled.IconNotification,
};

const mappingIcons: Record<ToastType, string> = {
  error: NAMES.TOAST__EXCLAMATION_MARK,
  info: NAMES.TOAST__EXCLAMATION_MARK,
  notification: NAMES.TOAST__BELL,
};

/**
 * Toast message icon
 *
 * @param props      Props passed to the component
 * @param props.type Message type
 * @returns          The component itself
 */
const ToastMessageIcon: FC<Props> = ({ type }) => {
  const Component = mappingComponents[type];

  return (
    <Component
      color="var(--color-icons-alt)"
      height={TOAST__ICON_SIZE}
      name={mappingIcons[type]}
      width={TOAST__ICON_SIZE}
    />
  );
};

export default ToastMessageIcon;
