/**
 * @file Date helper function
 */

import { ONE_DAY } from '../../constants/dateTime';

/**
 * Add one day to passed date or current date if nothing is passed
 *
 * @param date Date
 * @returns    Passed date + 1 day
 */
export const getOneDayAfterDate = (date?: Date): Date => {
  if (date === undefined) {
    return new Date(Date.now() + ONE_DAY);
  }

  return new Date(date.getTime() + ONE_DAY);
};
