import React, { ChangeEventHandler, FC, useId } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import { NAMES } from '../Icon';

import * as Styled from './styled';

const defaultSizeDesktop = 18;
const defaultSizeMobile = 20;

export type Props = {
  className?: HTMLInputElement['className'];
  colorChecked?: string;
  colorUnchecked?: string;
  isDisabled?: HTMLInputElement['disabled'];
  isChecked: HTMLInputElement['checked'];
  isIndeterminate?: HTMLInputElement['indeterminate'];
  label?: string;
  onChange: ((isChecked: HTMLInputElement['checked']) => void) | null;
  size?: number;
};

/**
 * Get the checkbox size (it's different on mobile, and can also be passed as a prop)
 *
 * @param isMobile Whether we are on mobile or desktop screens
 * @param size     Custom size
 * @returns        Custom size if passed or default size for desktop/mobile
 */
const getSize = (isMobile: boolean, size?: number): number => {
  return size ?? isMobile ? defaultSizeMobile : defaultSizeDesktop;
};

/**
 * Get which icon should be used for the checkbox
 *
 * @param isChecked       Whether we have a checked checkbox
 * @param isIndeterminate Whether the checkbox is in indeterminate state
 * @returns               The name of the icon to use
 */
const getIconName = (isChecked: boolean, isIndeterminate: boolean): string => {
  if (isChecked === true) {
    return NAMES.GENERAL__CHECKBOX_CHECKED;
  }

  if (isIndeterminate === true) {
    return NAMES.GENERAL__CHECKBOX_INDETERMINATE;
  }

  return NAMES.GENERAL__CHECKBOX_UNCHECKED;
};

/**
 * Generic checkbox component, wraps native input type=checkbox,
 * replacing its look with svg icons
 *
 * @param props                 Props passed to the component
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.colorUnchecked  The color of the checkbox (will default to default icon color)
 * @param props.colorChecked    The color of the checkbox when it is checked (will default to props.color)
 * @param props.isDisabled      Whether the checkbox is disabled
 * @param props.isChecked       Whether to check the checkbox
 * @param props.isIndeterminate Whether the checkbox is in indeterminate state
 * @param props.label           (invisible) label for the checkbox
 * @param props.onChange        Callback to be invoked on checked/unchecked
 * @param props.size            Checkbox size, in px
 * @returns                     The component itself
 */
const Checkbox: FC<Props> = ({
  className,
  colorUnchecked,
  colorChecked = colorUnchecked,
  isChecked,
  isDisabled = false,
  isIndeterminate = false,
  label = null,
  onChange = null,
  size,
}) => {
  const isMobile = useIsMobile();
  const checkboxSize = getSize(isMobile, size);
  /**
   * User has (un)checked the checkbox, so we trigger onChange callback
   *
   * @param event The event that took place
   */
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (onChange !== null) {
      onChange(event.currentTarget.checked);
    }
  };

  const id = useId();
  const iconName = getIconName(isChecked, isIndeterminate);
  const shouldShowLabel = label !== null;

  const colorIconDisabled = 'var(--color-grey-darkest)';
  const colorIconEnabled = isChecked ? colorChecked : colorUnchecked;

  const colorIcon = isDisabled ? colorIconDisabled : colorIconEnabled;

  return (
    <Styled.Wrapper className={className} data-size={checkboxSize}>
      <Styled.Input
        checked={isChecked}
        disabled={isDisabled}
        id={id}
        onChange={handleChange}
        ref={element => element && (element.indeterminate = isIndeterminate)}
        type="checkbox"
      />
      {shouldShowLabel && <Styled.Label htmlFor={id}>{label}</Styled.Label>}
      <Styled.Icon
        color={colorIcon}
        height={checkboxSize}
        name={iconName}
        width={checkboxSize}
      />
    </Styled.Wrapper>
  );
};

export default Checkbox;
