/**
 * @file Mark message as read when it should be
 */

import { useEffect } from 'react';
import { useQuery } from 'urql';

import { MessageFullDocument } from '../../../generated/graphql';
import useMutationMessagesRead from '../../../hooks/message/useMutationMessagesRead';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import getShouldMarkAsRead from '../helpers/getShouldMarkAsRead';

/**
 * Mark message as read when it should be
 * (it's not already read and there are no comments for it)
 */
const useMessageRead = () => {
  const messageId = useParamMessageId() ?? '';
  const [{ data }] = useQuery({
    query: MessageFullDocument,
    variables: { messageId },
  });

  const requestMessagesRead = useMutationMessagesRead();
  const shouldMarkAsRead = getShouldMarkAsRead(data);

  useEffect(() => {
    if (shouldMarkAsRead) {
      requestMessagesRead(messageId);
    }
    // Adding requestMessagesRead and requestTopicsList causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId, shouldMarkAsRead]);
};

export default useMessageRead;
