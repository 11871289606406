/**
 * @file Hook for determining what to show on Feed page
 */

import useCurrentPath from '../../../../../hooks/useCurrentPath';
import useIsMobile from '../../../../../hooks/useIsMobile';
import {
  createFeedMessageDetailsEditPath,
  createFeedMessageDetailsPath,
} from '../../../../paths/feed';

type UseFeedParamsReturn = {
  shouldShowMessageDetails: boolean;
};

const showMessageDetailsFor = [
  createFeedMessageDetailsPath(),
  createFeedMessageDetailsEditPath(),
];

/**
 * Hook for determining what to show on Feed page
 *
 * @returns Info about the feed page
 */
const useFeedRootParams = (): UseFeedParamsReturn => {
  const currentPath = useCurrentPath();
  const isMobile = useIsMobile();

  /**
   * Decide if messageDetails should be rendered.
   * Based on current path and whether app is rendered on mobile
   */
  const shouldShowMessageDetails =
    currentPath !== null &&
    isMobile &&
    showMessageDetailsFor.includes(currentPath);

  return {
    shouldShowMessageDetails,
  };
};

export default useFeedRootParams;
