/**
 * @file contains helper rrule function to get weekday for date
 */

/**
 * Get weekday from date or today if date is not passed
 *
 * @param date Date to get the weekday from
 * @returns    weekday
 */
export const getWeekday = (date?: Date): number => {
  const day = date === undefined ? new Date().getDay() : date.getDay();

  return day === 0 ? 6 : day - 1;
};
