import React, { FC, useId } from 'react';

import Editor from '../../../Common/Editor';
import { EditorUsage } from '../../../Common/Editor/types';
import RichText from '../../../Common/RichText';
import FormLabel from '../FormLabel';

export type Props = {
  className?: string;
  isDisabled?: boolean;
  isLabelVisible?: boolean;
  isRequired?: boolean;
  label: string;
  maxLength?: number;
  minLength?: number;
  name?: string;
  onBlur?: () => void;
  onChange: (text: string) => void;
  shouldFocus?: boolean;
  usage: EditorUsage;
  value: string;
};

/**
 * Full text editor field, using <Editor /> to show a textarea
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.isDisabled     Whether the field is disabled
 * @param props.isLabelVisible Whether label text is visible or just associated with the input
 * @param props.isRequired     Whether the field is required
 * @param props.label          Element label text
 * @param props.maxLength      Maximum text length
 * @param props.minLength      Minimum text length
 * @param props.name           Name attribute for the element
 * @param props.onBlur         Callback to be invoked on every text blur
 * @param props.onChange       Callback to be invoked on every text change
 * @param props.shouldFocus    Should the text area be auto focused
 * @param props.usage          What the textarea is used for
 * @param props.value          Current text
 * @returns                    The component itself
 */
const FormTextarea: FC<Props> = ({
  className,
  isDisabled,
  isLabelVisible,
  isRequired,
  label,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  shouldFocus,
  usage,
  value,
}) => {
  const inputId = useId();
  return (
    <FormLabel
      className={className}
      inputId={inputId}
      isVisible={isLabelVisible}
      label={label}
    >
      {isDisabled === true ? (
        <RichText>{value}</RichText>
      ) : (
        <Editor
          breakpointMin="tabletS"
          id={inputId}
          isDisabled={isDisabled}
          isRequired={isRequired}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={onBlur}
          onChangeText={onChange}
          shouldFocus={shouldFocus}
          usage={usage}
          value={value}
        />
      )}
    </FormLabel>
  );
};

export default FormTextarea;
