/**
 * @file Hook that returns a translated message from redux
 */
import { Message } from '../../generated/graphql';

import useTranslatedMessages from './useTranslatedMessages';

/**
 * Hook that returns a translated message from redux
 *
 * @param messageId Message ID
 * @returns         Translated message from redux
 */
const useTranslation = (messageId: Message['id']) => {
  return useTranslatedMessages().get(messageId) ?? null;
};

export default useTranslation;
