import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import translate from '../../../../i18n/translate';

import { NAMES } from '../../../Common/Icon';

import * as Styled from './styled';

const ICON_SIZE = 20;

type Props = {
  onClick: () => void;
};

/**
 * Button for adding attachments to existing message
 *
 * @param props         Props passed to the component
 * @param props.onClick Button click callback
 * @returns             The component itself
 */
const ButtonAttachmentAdd: FC<Props> = ({ onClick }) => {
  const label = translate('COMPOSE__ATTACH__FILE');
  return (
    <Tippy content={label} touch={false}>
      <span>
        <Styled.Button aria-label={label} onClick={onClick} type="button">
          <Styled.Icon
            height={ICON_SIZE}
            name={NAMES.GENERAL__ADD}
            width={ICON_SIZE}
          />
          {translate('ATTACHMENT__BUTTON__ADD')}
        </Styled.Button>
      </span>
    </Tippy>
  );
};

export default ButtonAttachmentAdd;
