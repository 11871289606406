/**
 * @file contains mapDispatchToProps function for devPanel container
 */

import { Dispatch } from 'redux';

import { DebugAction } from '../../models/debug';
import { MarkAsReadAction } from '../../models/markAsRead';
import { ToastMessageAction } from '../../models/toastMessage';
import {
  closeDebugPanel,
  setDebugHasMessages,
  setDebugNetworkComments,
  setDebugNetworkCompose,
  setDebugNetworkMessageDetails,
  setDebugNetworkMessages,
  setDebugNetworkQuickFilters,
  setDebugNetworkTopicBasics,
  setDebugNetworkTopicDetails,
  setDebugNetworkTopics,
  toggleDebugPanelState,
} from '../../store/actions/debug';
import {
  setMarkAsReadDebounce,
  setMarkAsReadDelay,
} from '../../store/actions/markAsRead';
import {
  addToastMessage,
  setDurationToastMessage,
} from '../../store/actions/toastMessage';

import { DispatchProps } from './props';

/**
 * Map Redux dispatch to component props
 *
 * @param dispatch Redux dispatch callback
 * @returns        Props to inject into the component
 */
const mapDispatchToProps = (
  dispatch: Dispatch<DebugAction | MarkAsReadAction | ToastMessageAction>,
): DispatchProps => ({
  requestClosePanel: () => dispatch(closeDebugPanel()),
  requestSetHasMessages: state => dispatch(setDebugHasMessages(state)),
  requestSetMarkAsReadDebounce: state => {
    dispatch(setMarkAsReadDebounce(state));
  },
  requestSetMarkAsReadDelay: state => {
    dispatch(setMarkAsReadDelay(state));
  },
  requestSetNetworkComments: state => {
    dispatch(setDebugNetworkComments(state));
  },
  requestSetNetworkCompose: state => {
    dispatch(setDebugNetworkCompose(state));
  },
  requestSetNetworkMessageDetails: state => {
    dispatch(setDebugNetworkMessageDetails(state));
  },
  requestSetNetworkMessages: state => {
    dispatch(setDebugNetworkMessages(state));
  },
  requestSetNetworkQuickFilters: state => {
    dispatch(setDebugNetworkQuickFilters(state));
  },
  requestSetNetworkTopicBasic: state => {
    dispatch(setDebugNetworkTopicBasics(state));
  },
  requestSetNetworkTopicDetails: state => {
    dispatch(setDebugNetworkTopicDetails(state));
  },
  requestSetNetworkTopics: state => dispatch(setDebugNetworkTopics(state)),
  requestSetToastMessageDuration: duration => {
    dispatch(setDurationToastMessage(duration));
  },
  requestToastMessageAdd: toastMessage => {
    dispatch(addToastMessage(toastMessage));
  },
  requestTogglePanel: () => dispatch(toggleDebugPanelState()),
});

export default mapDispatchToProps;
