import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__DUE_DATE } from '../../constants/routing/hashLinks';

import { MessageStatus as MessageStatusEnum } from '../../generated/graphql';
import translate from '../../i18n/translate';
import MessageDateDue from '../Common/MessageDateDue';

import MessageSection from './MessageSection';
import useMessageSectionUrl from './hooks/useMessageSectionUrl';

type Props = {
  canUpdateDate: boolean;
  date: string;
  status: MessageStatusEnum | undefined | null;
};

/**
 * Section in the message that displays due date
 *
 * @param props               Props passed to the component
 * @param props.canUpdateDate Whether the user can update date or not
 * @param props.date          Date value
 * @param props.status        Current status value
 * @returns                   The component itself
 */
const MessageSectionDateDue: FC<Props> = ({ canUpdateDate, date, status }) => {
  const linkTo = useMessageSectionUrl({
    mode: 'date',
  });

  return (
    <MessageSection
      heading={translate('MESSAGE__HEADING__DATE_DUE')}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__DUE_DATE}
      linkTo={canUpdateDate ? linkTo : undefined}
    >
      <MessageDateDue date={date} status={status} />
    </MessageSection>
  );
};

export default MessageSectionDateDue;
