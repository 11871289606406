import React, { FC } from 'react';

import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import translate from '../../../i18n/translate';
import ErrorWrapper from '../ErrorWrapper';

/**
 * Component that shows dashboard error
 *
 * @returns The component itself
 */
const DashboardError: FC = () => {
  return (
    <ErrorWrapper
      actionButton={null}
      description={translate('ERROR_TABLE__DESCRIPTION')}
      illustrationName={ILLUSTRATION__SEARCH}
      isFullScreen={false}
      title={translate('GENERAL__OOPS')}
    />
  );
};
export default DashboardError;
