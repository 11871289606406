/**
 * @file Hook that gets currently active search term for ES
 */
import { useParams } from 'react-router-dom';

import { SearchParams } from '../../../models/pageParams';

/**
 * Hook that gets currently active search term for ES
 *
 * @returns Current active searchTerm
 */
const useParamSearchTerm = (): string | null => {
  const { searchTerm } = useParams<SearchParams>();

  if (searchTerm === undefined) {
    return null;
  }

  return searchTerm;
};

export default useParamSearchTerm;
