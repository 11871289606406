import React, { FC } from 'react';

import { Thread } from '../../generated/graphql';

import translate from '../../i18n/translate';

import { Comment } from '../../models/comment';
import { getCanCreateComment } from '../../utils/permissions/comment';
import SkeletonComments from '../Loading/Comments';

import CommentCompose from './CommentCompose';
import CommentList from './CommentList';
import * as Styled from './styled';

type Props = {
  comments: Comment[];
  draftText: string;
  isArchived: boolean;
  isLoading: boolean;
  isNewComment: boolean;
  requestDraftUpdate: (text: string) => void;
  requestLoadMore: (() => void) | null;
  requestSubmit: (isForExternal: Comment['isForExternal']) => void;
  thread: Thread | null;
};

/**
 * Comments for a message
 *
 * @param props                    Props passed to the component
 * @param props.comments           Comments to display
 * @param props.draftText          Text in reply compose form
 * @param props.isArchived         Flag that indicate if parent message is archived
 * @param props.isLoading          If the comments are being fetched or not
 * @param props.isNewComment       Whether new comment was created by the user
 * @param props.requestDraftUpdate Request that draft text is updated
 * @param props.requestLoadMore    Request loading of more comments via pagination
 * @param props.requestSubmit      Request that a new comment is created
 * @param props.thread             Metadata about the comment thread (for external messaging only)
 * @returns                        The component itself
 */
const Comments: FC<Props> = ({
  comments,
  draftText,
  isArchived,
  isLoading,
  isNewComment,
  requestDraftUpdate,
  requestLoadMore,
  requestSubmit,
  thread,
}) => {
  const canReply = getCanCreateComment(isArchived);

  return (
    <>
      <Styled.Wrapper>
        {requestLoadMore !== null && (
          <Styled.LoadMoreButton onClick={requestLoadMore} type="button">
            {translate('COMMENTS__MORE_REPLIES')}
          </Styled.LoadMoreButton>
        )}
        {isLoading === true && <SkeletonComments />}
        <CommentList
          canReply={canReply}
          comments={comments}
          isNewComment={isNewComment}
        />
      </Styled.Wrapper>
      {canReply && (
        <CommentCompose
          requestDraftUpdate={requestDraftUpdate}
          requestSubmit={requestSubmit}
          text={draftText}
          thread={thread}
        />
      )}
    </>
  );
};

export default Comments;
