/**
 * @file contains react router paths for the error routes
 */
import {
  ROUTE__ERROR__AUTH_HOTEL_NOT_FOUND,
  ROUTE__ERROR__AUTH_USER_NOT_FOUND,
  ROUTE__ERROR__DEACTIVATED_HOTEL,
  ROUTE__ERROR__MISSING_HOTEL,
  ROUTE__ERROR__MISSING_TERMS_AND_CONDITIONS,
} from '../../constants/routing/routes';

/**
 * Generate a path for the error code AUTH_HOTEL_NOT_FOUND
 *
 * @returns The generated path
 */
export const createErrorAuthHotelNotFoundPath = (): string => {
  return ROUTE__ERROR__AUTH_HOTEL_NOT_FOUND;
};

/**
 * Generate a path for the error code AUTH_USER_NOT_FOUND
 *
 * @returns The generated path
 */
export const createErrorAuthUserNotFoundPath = (): string => {
  return ROUTE__ERROR__AUTH_USER_NOT_FOUND;
};

/**
 * Generate a path for the error code DEACTIVATED_HOTEL
 *
 * @returns The generated path
 */
export const createErrorDeactivatedHotelPath = (): string => {
  return ROUTE__ERROR__DEACTIVATED_HOTEL;
};

/**
 * Generate a path for the error code MISSING_HOTEL
 *
 * @returns The generated path
 */
export const createErrorMissingHotelPath = (): string => {
  return ROUTE__ERROR__MISSING_HOTEL;
};

/**
 * Generate a path for the error code MISSING_TERMS_AND_CONDITIONS
 *
 * @returns The generated path
 */
export const createErrorMissingTermsPath = (): string => {
  return ROUTE__ERROR__MISSING_TERMS_AND_CONDITIONS;
};
