import { List } from '@mui/material';
import React, { ComponentProps, FC } from 'react';

import NavigationLink from './NavigationLink';
import NavigationListButton from './NavigationListButton';

type Props = {
  buttons?: ComponentProps<typeof NavigationListButton>[];
  links: ComponentProps<typeof NavigationLink>[];
};

/**
 * Mobile navigation component used in pages that have only links to different
 * pages (More, Settings,...)
 *
 * @param props         Props passed to the component
 * @param props.buttons Buttons to display in the mobile navigation
 * @param props.links   Links to display in the mobile navigation
 *
 * @returns             The mobile navigation component
 */
const MobileFullViewNavigation: FC<Props> = ({ buttons, links }) => {
  return (
    <nav>
      <List>
        {links.map(link => (
          <NavigationLink key={link.url} {...link} />
        ))}
        {buttons?.map(button => (
          <NavigationListButton key={button.label} {...button} />
        ))}
      </List>
    </nav>
  );
};

export default MobileFullViewNavigation;
