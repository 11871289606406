import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  className?: string;
  children: ReactNode | ReactNode[];
  id?: string;
};

/**
 * Heading for a modal, large text shown up top
 *
 * @param props           Props passed to the component
 * @param props.children  The (translated) text to show
 * @param props.className styled-components generated class name, needed for styling
 * @param props.id        Element ID
 * @returns               The component itself
 */
const ModalHeading: FC<Props> = ({ className, children, id }) => {
  return (
    <Styled.Heading className={className} id={id}>
      {children}
    </Styled.Heading>
  );
};

export default ModalHeading;
