/**
 * @file A label was created, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  LabelCreateMutation,
  LabelCreateMutationVariables,
  LabelsDocument,
} from '../../../../../generated/graphql';
import queryCreation from '../utils/updaters/queryCreation';

/**
 * Handle subscription to everything related to labels
 *
 * @param result The result of the mutation
 * @param _args  Arguments of a graphQL operation
 * @param cache  URQL graphQL cache
 */
const labelCreateMutation: UpdateResolver<
  LabelCreateMutation,
  LabelCreateMutationVariables
> = (result, _args, cache) => {
  queryCreation<LabelCreateMutation['labelCreate']>({
    cache,
    queries: [{ name: 'labels', query: LabelsDocument }],
    result: result.labelCreate,
  });
};

export default labelCreateMutation;
