/**
 * @file Helpers for comment compose form
 */

import { Thread } from '../../../generated/graphql';

/**
 * Get whether recipient toggle should be disabled for this comment thread
 *
 * @param meta Thread meta
 * @returns    Whether it should be disabled
 */
export const getShouldDisableToggle = (meta: Thread | null): boolean => {
  return (
    meta === null ||
    meta.isArchivedByGuest === true ||
    meta.isSupportingReplies === false
  );
};
