/**
 * @file Update the selectedHotel param for the user
 */

import { useEffect } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Hotel,
  Maybe,
  MeDocument,
  UserSelectHotelDocument,
} from '../../generated/graphql';
import { reportApiErrors } from '../../utils/error';
import { getHotelPermissionsById } from '../../utils/hotel';

/**
 * Update the selectedHotel param for the user
 *
 * @param hotelId The ID of the hotel to mark as selected
 * @returns       Currently selected hotel and whether there are more hotels
 */
const useUserSelectedHotel = (
  hotelId: Maybe<Hotel['id']>,
): { hasMore: boolean; hotel: Hotel | null } => {
  const [{ data, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });
  const [, userSelectHotelMutation] = useMutation(UserSelectHotelDocument);

  const selectedHotel = data?.me.selectedHotel;

  // Check if current hotelId from the url matches hotelId from me query and update it if it doesn't on component mount
  useEffect(() => {
    if (fetching === false && selectedHotel !== hotelId) {
      userSelectHotelMutation({}).catch(reportApiErrors);
    }
  }, [fetching, hotelId, userSelectHotelMutation, selectedHotel]);

  const permissions = data?.me.hotelPermissions;

  if (permissions === undefined) {
    return { hasMore: false, hotel: null };
  }
  const hotel = getHotelPermissionsById(hotelId, permissions)?.hotel ?? null;
  const hasMore = permissions.length > 1;

  return {
    hasMore,
    hotel,
  };
};

export default useUserSelectedHotel;
