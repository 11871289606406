import { Button, styled } from '@mui/material';

import ButtonQuickFiltersToggle from '../../../../components/MUI/ButtonQuickFiltersToggle';

export const QuickFiltersButton = styled(ButtonQuickFiltersToggle)`
  grid-column: 2;
  grid-row: 2;
`;

export const QuickFiltersWrapper = styled('div')`
  /* Span quick filters across the grid */
  grid-column: 1 / 3;
  grid-row: 3;
`;

export const ButtonCreate = styled(Button)`
  grid-column: 1;
  grid-row: 4;
  justify-self: baseline;
`;
