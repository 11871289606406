import React, { FC, useId } from 'react';

import Modal from '../../../../components/Common/Modal';

import useConfig from './useConfig';

export type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

/**
 * Modal to inform the user that they can receive push notifications,
 * used on the settings page
 *
 * @param props           Props passed to the component
 * @param props.isOpen    Whether to show the modal
 * @param props.onCancel  The user has clicked "Cancel" button
 * @param props.onConfirm The user has clicked "Confirm" button
 * @returns               The component itself
 */
const ModalPushSettingsPage: FC<Props> = ({ isOpen, onCancel, onConfirm }) => {
  const idHeading = useId();
  const idSubheading = useId();

  const { buttons, textHeading, textSubheading } = useConfig(
    onConfirm,
    onCancel,
  );

  return (
    <Modal
      buttons={buttons}
      height="auto"
      idHeading={idHeading}
      idSubheading={idSubheading}
      image="PUSH_ALLOW"
      isOpen={isOpen}
      requestClose={onCancel}
      textHeading={textHeading}
      textSubheading={textSubheading}
    />
  );
};

export default ModalPushSettingsPage;
