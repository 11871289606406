import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import ButtonIcon, { NAMES } from '../ButtonIcon';

export type Props = {
  className?: HTMLElement['className'];
  color?: string;
  iconSize?: number;
  label?: string;
  onClick?: () => void;
};

/**
 * Default icon size
 */
export const ICON_SIZE = 15;

/**
 * Close button
 *
 * @param props           The props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     Icon color
 * @param props.label     Button label
 * @param props.iconSize  Icon size
 * @param props.onClick   Click callback for the button
 * @returns               The component itself
 */
const ButtonClose: FC<Props> = ({
  className,
  color = 'var(--color-button-secondary)',
  iconSize = ICON_SIZE,
  label = translate('GENERAL__CLOSE'),
  onClick,
}) => {
  return (
    <ButtonIcon
      className={className}
      color={color}
      iconHeight={iconSize}
      iconName={NAMES.GENERAL__CLOSE}
      iconWidth={iconSize}
      label={label}
      onClick={onClick}
    />
  );
};

export default ButtonClose;
