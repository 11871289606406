import React, { FC, useState } from 'react';

import { URL_IMAGES } from '../../config';

import Icon, { NAMES } from '../Common/Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  imageUrl: string | null | undefined;
  title: string;
};

const ICON_SIZE = 25;

/**
 * Info component that gives us information about the hotel and the status
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name
 * @param props.imageUrl  Image url
 * @param props.title     Hotel title
 * @returns               The component itself
 */
const HotelInfo: FC<Props> = ({ className, imageUrl, title }) => {
  const [hasError, setHasError] = useState(false);

  /**
   * If image src is broken, this function is triggered.
   * As a result of setting hasError to true,
   * placeholder icon will be shown.
   */
  const handleError = () => {
    setHasError(true);
  };

  return (
    <Styled.Wrapper className={className}>
      <Styled.LogoWrapper data-icon-size={ICON_SIZE}>
        {imageUrl && hasError === false ? (
          <Styled.Image
            alt={`${title} logo`}
            onError={handleError}
            src={URL_IMAGES + imageUrl}
          />
        ) : (
          <Icon
            color="var(--color-primary-lighter)"
            height={ICON_SIZE}
            name={NAMES.HOTEL__LOGO_PLACEHOLDER}
            width={ICON_SIZE}
          />
        )}
      </Styled.LogoWrapper>
      <Styled.Title data-icon-size={ICON_SIZE}>{title}</Styled.Title>
    </Styled.Wrapper>
  );
};

export default HotelInfo;
