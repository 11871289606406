/**
 * @file Contains customization for MuiDrawer Component
 */

import { Components } from '@mui/material';

const MuiDrawer: Components['MuiDrawer'] = {
  styleOverrides: {
    paper: {
      borderRight: 'none',
      zIndex: 'var(--z-sidebar)',
    },
  },
};

export default MuiDrawer;
