import { Link } from '@tiptap/extension-link';
import { StarterKit } from '@tiptap/starter-kit';
import React, { FC } from 'react';

import { parseText } from '../../../formatters/editor';

import * as Styled from './styled';
import useEditorCustom from './useEditorCustom';

type Props = {
  children: string | null | undefined;
  className?: string;
  shouldRenderLinks?: boolean;
};

const LinkPlain = Link.extend({
  /**
   * Renders link HTML
   *
   * @returns Array of html tags
   */
  renderHTML() {
    return ['p', 0];
  },
});

/**
 * Render tiptap-generated HTML
 *
 * @param props                   Props passed to the component
 * @param props.children          The children to render
 * @param props.className         styled-components generated class name
 * @param props.shouldRenderLinks Whether to render links as <a />
 * @returns                       The component itself
 */
const RichText: FC<Props> = ({
  children,
  className,
  shouldRenderLinks = true,
}) => {
  const editor = useEditorCustom(
    {
      content: parseText(children ?? ''),
      editable: false,
      extensions: [shouldRenderLinks ? Link : LinkPlain, StarterKit],
    },
    [children],
  );

  /**
   * @todo We dangerously set inner html to fix a bug. If an editor element is
   * wrapped with a link, clicking on the editor element will open that link
   * in a new tab. The link will also be opened in the current tab as well.
   */
  return (
    <Styled.Editor
      className={className}
      dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
    />
  );
};

export default RichText;
