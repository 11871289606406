/**
 * @file contains util functions used for native compatibility
 */
import { gt, lt } from 'semver';

export const comparisonFunctions: Record<'gt' | 'lt', typeof gt> = {
  gt: gt,
  lt: lt,
};

/**
 * Check if the condition for the operator is true
 *
 * @param currentVersion Current app version (semver)
 * @param targetVersion  Target version (semver)
 * @param operator       Operator to use
 * @returns              Whether the condition is true
 */
export const checkVersion = (
  currentVersion: string,
  targetVersion: string,
  operator: keyof typeof comparisonFunctions,
): boolean => {
  return comparisonFunctions[operator](currentVersion, targetVersion);
};
