import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import BaseHeaderMobile from '../../../../components/Header/BaseHeaderMobile';
import { TopicWithMembersDocument } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useIsComposeOpen from '../../../../hooks/router/search/useIsComposeOpen';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import translate from '../../../../i18n/translate';
import AppState from '../../../../models/state';
import { getHomeUrl } from '../../../../routes/urls/home';
import { reportError } from '../../../../services/reporting';

import ComposeHeader from '../../compose';
import ContentRightTopicEdit from '../../mobileContentRight/topicEdit';
import TopicFullHeaderMobile from '../../topicFull';

import { getFeedKanbanSegmentedButtons } from '../helpers';
import FeedKanbanQuickFilters from '../quickFilters';

import { getShouldShowTopicHeader } from './helpers';

type StateProps = {
  networkTopicBasic: boolean;
};

type Props = StateProps;

/**
 * The container for the topic header on the home page
 * Fetches the currently selected topic
 *
 * @param props                   Props passed to the component
 * @param props.networkTopicBasic Whether the debug option for fetching this is enabled
 * @returns                       The component itself
 */
const FeedKanbanHeaderMobile: FC<Props> = ({ networkTopicBasic }) => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const isComposeOpen = useIsComposeOpen();
  const location = useLocation();
  const shouldShowTopicHeader = getShouldShowTopicHeader(currentPath);
  const topicId = useParamTopicId();

  const [{ data, error, fetching }] = useQuery({
    pause: topicId === null,
    query: TopicWithMembersDocument,
    variables: { id: topicId ?? '' },
  });

  const isLoading = [fetching, networkTopicBasic].some(Boolean);

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  if (shouldShowTopicHeader) {
    return <TopicFullHeaderMobile />;
  }

  if (isComposeOpen) {
    return <ComposeHeader nodeId={null} nodeType="MESSAGE" />;
  }

  const topic = data?.topic;

  const topicBackUrl = getHomeUrl(hotelId);
  const topicTitle = topic?.title ?? translate('GENERAL__TOPICS');

  return (
    <BaseHeaderMobile
      backUrl={topicBackUrl}
      contentRight={<ContentRightTopicEdit topic={topic} />}
      isLoading={isLoading}
      segmentedButtons={getFeedKanbanSegmentedButtons(
        hotelId,
        location,
        topicId,
      )}
      title={topicTitle}
    >
      <FeedKanbanQuickFilters />
    </BaseHeaderMobile>
  );
};

/**
 * Redux map state to props function
 *
 * @param state Entire redux app state
 * @returns     Props for a react component derived from redux state
 */
const mapStateToProps = (state: AppState): StateProps => ({
  networkTopicBasic: state.debug.networkTopicBasic,
});

export default connect(mapStateToProps)(FeedKanbanHeaderMobile);
