import React, { FC, ReactNode } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  children?: ReactNode | ReactNode[];
  className?: HTMLButtonElement['className'];
  isDisabled?: HTMLButtonElement['disabled'];
  formId?: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
};

/**
 * Button wrapper that returns reused Button with string Save,
 * with onClick callback
 *
 * @param props            The props passed to the component
 * @param props.children   Children to render inside the button
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.formId     Whether to make the button disabled
 * @param props.isDisabled Whether to make the button disabled
 * @param props.onClick    Button click callback, can be omitted when type is submit
 * @param props.type       <button> type attribute
 * @returns                The component itself
 */
const ButtonSave: FC<Props> = ({
  children = translate('GENERAL__SAVE'),
  className,
  isDisabled = false,
  formId,
  onClick,
  type = 'button',
}) => {
  return (
    <Styled.ButtonSave
      className={className}
      formId={formId}
      isDisabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </Styled.ButtonSave>
  );
};

export default ButtonSave;
