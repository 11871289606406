/**
 * @file contains functions used to create label modal urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Location } from 'react-router-dom';

import { MODE_EDIT, ModeLabels } from '../../constants/metaModesLabels';
import {
  SEARCH_PARAM__COMPOSE_MODE,
  SEARCH_PARAM__LABEL_ID,
  SEARCH_PARAM__LABEL_MODE,
  SEARCH_PARAM__MESSAGE_FULL_LABEL_ID,
  SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE,
} from '../../constants/routing/searchParams';
import { LabelUsage } from '../../models/message';
import { SearchParamsKey } from '../../models/searchParams';
import {
  modifyUrlParams,
  parseUrlString,
  removeParamsKeysFromUrl,
} from '../helpers/router';

export const labelParamConfig = {
  compose: {
    labelId: SEARCH_PARAM__LABEL_ID,
    labelMode: SEARCH_PARAM__LABEL_MODE,
  },
  messageFull: {
    labelId: SEARCH_PARAM__MESSAGE_FULL_LABEL_ID,
    labelMode: SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE,
  },
} as const;

/**
 * Get url for navigating between modes in the label components within
 * compose and messageFull
 *
 * @param location Location object from the history
 * @param where    Where the url is requested
 * @param mode     The view mode we want to set
 * @param labelId  Id of the label we want to edit
 * @returns        A string in a url format
 */
export const getLabelModeUrl = (
  location: Location,
  where: LabelUsage,
  mode: ModeLabels,
  labelId?: string,
) => {
  const { labelId: labelIdParamKey, labelMode: labelModeParamKey } =
    labelParamConfig[where];

  const paramsToRemove: SearchParamsKey[] = [];

  const params: { [k in SearchParamsKey]?: string } = {
    [labelModeParamKey]: mode,
  };

  if (labelId) {
    params[labelIdParamKey] = labelId;
  }

  if (mode !== MODE_EDIT) {
    paramsToRemove.push(labelIdParamKey);
  }

  return modifyUrlParams(location, {
    params,
    paramsToRemove,
  });
};

/**
 * Get message details URL without search params related to labels
 *
 * @param location          History location object
 * @param messageDetailsUrl Message details url we need to navigate
 * @returns                 String in URL format
 */
export const getLabelMessageDetailsCloseUrl = (
  location: Location,
  messageDetailsUrl: string,
) => {
  const removeUrl = removeParamsKeysFromUrl(
    location,
    SEARCH_PARAM__MESSAGE_FULL_LABEL_ID,
    SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE,
  );

  const [pathname] = parseUrlString(messageDetailsUrl);
  const [, search] = parseUrlString(removeUrl);

  return `${pathname}${search}`;
};

/**
 * Get url for closing the compose label popup
 *
 * @param location Location object from the history
 * @returns        A string in a URL format
 */
export const getLabelPopupCloseUrl = (location: Location) => {
  return removeParamsKeysFromUrl(
    location,
    SEARCH_PARAM__COMPOSE_MODE,
    SEARCH_PARAM__LABEL_ID,
    SEARCH_PARAM__LABEL_MODE,
  );
};
