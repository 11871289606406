import { Stack, styled } from '@mui/material';

import breakpoints from '../../constants/breakpoints';

export const Wrapper = styled(Stack)(({ theme }) => ({
  gap: 'var(--spacing-large)',
  padding: 'var(--spacing-medium)',
  [theme.breakpoints.up(breakpoints.tabletS)]: {
    marginTop: 'var(--spacing-large)',
  },
}));
