/**
 * @file contains a custom react hook for adding toast message
 */

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ToastMessageArgs } from '../../models/toastMessage';

import { addToastMessage } from '../../store/actions/toastMessage';
/**
 *
 * Custom hook for adding toast message
 *
 * @returns Action which adds toast message
 */
const useToast = () => {
  const dispatch = useDispatch();

  const requestToastMessageAdd = useCallback<
    (toastMessage: ToastMessageArgs) => void
  >(
    toastMessage => {
      dispatch(addToastMessage(toastMessage));
    },
    [dispatch],
  );
  return requestToastMessageAdd;
};

export default useToast;
