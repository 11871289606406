import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

type ComposeLinkProps = {
  'data-size': number;
};

export const ComposeLink = styled(Link)<ComposeLinkProps>`
  background-color: var(--color-blue-darker);
  border-radius: 50%;
  box-shadow: var(--box-shadow-strong);
  display: block;
  height: ${props => props['data-size']}px;
  width: ${props => props['data-size']}px;
`;
