import React, { FC, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AppLayout from '../../../../components/layouts/AppLayout';
import ComposeContainer from '../../../../containers/compose';
import FeedKanbanHeader from '../../../../containers/header/feedKanban';
import Kanban from '../../../../containers/kanban';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../../hooks/useIsTablet';
import { getTopicKanbanUrl } from '../../../urls/kanban';
import FeedFooter from '../../Feed/Feed/FeedFooter';
import FeedRightSidebar from '../../Feed/Feed/FeedRightSidebar';
import { shouldStopClickOutsideExec } from '../../Feed/Feed/helpers';

import useKanbanParams from '../hooks/useKanbanParams';

/**
 * Kanban page
 *
 * @returns The component itself
 */
const PageKanban: FC = () => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    shouldShowCompose,
    shouldShowKanban,
    shouldShowRightSidebarDesktop,
    shouldShowRightSidebarMobile,
    topicId,
  } = useKanbanParams();

  const clickOutsideStopExec = shouldStopClickOutsideExec(isTablet, false);

  const requestRightSidebarClose = useCallback(() => {
    navigate(getTopicKanbanUrl(hotelId, location, topicId));
  }, [hotelId, location, navigate, topicId]);

  return (
    <AppLayout
      footer={<FeedFooter />}
      header={<FeedKanbanHeader />}
      rightSidebar={{
        clickOutsideOptions: {
          onClick: requestRightSidebarClose,
          stopExec: clickOutsideStopExec,
        },
        component: <FeedRightSidebar />,
        shouldShowDesktop: shouldShowRightSidebarDesktop,
        shouldShowMobile: shouldShowRightSidebarMobile,
      }}
    >
      {shouldShowKanban && <Kanban />}
      {shouldShowCompose && <ComposeContainer nodeType="MESSAGE" />}
    </AppLayout>
  );
};

export default PageKanban;
