/**
 * @file contains custom dimensions for Matomo
 */

import { Hotel, MeQuery, Message, Topic } from '../../generated/graphql';
import { getHotelPermissionsById } from '../../utils/hotel';
import { getAppType } from '../../utils/webview/helpers';

export type AdditionalConfig = {
  /**
   * The version of the wrapper native app
   * (undefined for PWA)
   */
  appVersionNative?: string;

  /**
   * The version of the wrapper web app
   * (present for native as well as PWA)
   */
  appVersionWeb?: string;

  /**
   * Defines if the action was triggered manually by a user or automatically
   */
  actionType?: 'manual' | 'automatic';
  /**
   * ID of the entity if it exists (e.g. user ID, message ID, etc.)
   */
  entityId?: string;
  /**
   * Type of the entity (e.g. user, message, etc.)
   */
  entityType?: string;
  /**
   * Value of the entity (e.g. target language for translation)
   */
  entityValue?: string | null;
  /**
   * Post/Message ID
   */
  messageId?: Message['id'] | null;
  /**
   * Post/Message origin (regular post, recurring post, concierge form, request)
   */
  messageOrigin?: string;
  /**
   * Post/Message title
   */
  messageTitle?: Message['title'];
  /**
   * Property type
   */
  propertyType?: string;
  /**
   * Property value
   */
  propertyValue?: string | null;
  /**
   * Wether the message/post has due date
   */
  task?: boolean;
  /**
   * Topic ID
   */
  topicId?: Topic['id'] | null;
};

export type CustomDimensions = Record<number, string>;

/**
 * Get the value to send as a custom dimension, when the source is a boolean
 *
 * @param value The value to convert
 * @returns     The value to use
 */
const getBooleanValue = (value: boolean | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value === true ? 'true' : 'false';
};

/**
 * Return a default value if value is null or undefined
 *
 * If we don’t set a custom dimension, matomo will use the old value,
 * which is bad. If we set null matomo will send the string "null".
 * But if we send an empty string, it will be removed in matomo.
 *
 * @param value Optional value
 * @returns     value or empty string
 */
const optional = (value: string | null | undefined) => {
  return value ?? '';
};

/**
 * Generate custom dimensions for Matomo. Some params are required,
 * and optional params are in the config object. Some required
 * params can be null, but most of the time they won't be.
 *
 * @param href    Required param - href - we always have a href
 * @param user    Required param - user - can be null but it shouldn't happen
 * @param hotelId Required param - Hotel ID - can be null but it shouldn't happen
 * @param config  Additional config with optional params
 * @returns       Custom dimensions
 */
export const getCustomDimensions = (
  href: string,
  user: MeQuery['me'] | null,
  hotelId: Hotel['id'] | null,
  config: AdditionalConfig = {},
): CustomDimensions => {
  const hotelPermission = getHotelPermissionsById(
    hotelId,
    user?.hotelPermissions,
  );

  // If we don’t set a custom dimension, matomo will use the old value,
  // which is bad. If we set null matomo will send the string "null".
  // But if we send an empty string, it will be removed in matomo.

  return {
    1: optional(hotelPermission?.hotel.uuid),
    2: optional(hotelPermission?.hotel.name),
    3: optional(user?.id),
    4: optional(hotelPermission?.roles.join(',')),
    5: new Date().toISOString(),
    6: optional(user?.language),
    7: href,
    8: optional(config.topicId),
    9: optional(config.messageId),
    10: optional(config.messageTitle),
    11: optional(config.messageOrigin),
    12: optional(config.entityType),
    13: optional(config.entityId),
    14: optional(config.entityValue),
    15: optional(config.propertyType),
    17: optional(config.propertyValue),
    18: getBooleanValue(config.task),
    19: config.actionType ?? 'manual',
    20: getAppType(),
    21: getBooleanValue(hotelPermission?.hotel.isTesting),
    22: optional(config.appVersionNative),
    23: optional(config.appVersionWeb),
  };
};

/**
 * Get href from custom dimensions
 *
 * @param customDimensions Custom dimensions
 * @returns                Href
 */
export const getHref = (customDimensions: CustomDimensions) => {
  return customDimensions[7];
};

/**
 * Get hotel name from custom dimensions
 *
 * @param customDimensions Custom dimensions
 * @returns                Hotel name
 */
export const getHotelName = (customDimensions: CustomDimensions) => {
  const hotelName = customDimensions[2];

  // The hotel name may be empty and in this case we use the document title.
  return hotelName.length === 0 ? document.title : hotelName;
};
