import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

import ButtonCommon from '../../../Common/Button';

import IconCommon from '../../../Common/Icon';

export const Button = styled(ButtonCommon)`
  align-items: center;
  color: var(--color-button-secondary);
  margin-top: var(--spacing-small);
  padding: var(--spacing-tiny) 0;
  width: 100%;

  &:hover {
    background-color: var(--color-background-selected);
  }

  @media ${devices.tabletS} {
    padding: var(--spacing-tiny);
  }
`;

export const Icon = styled(IconCommon)`
  margin-right: var(--spacing-small);
`;
