/**
 * @file State of the dropdown with callbacks that invoke state changes
 */

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Item } from './types';

type UseSelectStateReturn = {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  selectedItem: Item | undefined;
  setSelectedItem: Dispatch<SetStateAction<Item | undefined>>;
  toggle: () => void;
};

/**
 * State of the dropdown with callbacks that invoke state changes
 *
 * @param defaultItem Default selected item if any
 * @returns           An object that holds the state of the select dropdown
 */
const useSelectState = (
  defaultItem: Item | undefined,
): UseSelectStateReturn => {
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(
    defaultItem,
  );

  /**
   * Make sure that when Select component stays mounted but the defaultItem is changed
   * to set selectedItem to new defaultItem
   */
  useEffect(() => {
    setSelectedItem(defaultItem);

    /** Only change selected item on value change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItem?.value]);

  /**
   * Closes the select dropdown
   */
  const close = useCallback(() => setOpen(false), []);

  /**
   * Opens the select dropdown
   */
  const open = useCallback(() => setOpen(true), []);

  /**
   * Toggles the open state
   */
  const toggle = useCallback(() => setOpen(prevOpen => !prevOpen), []);

  return {
    close,
    isOpen,
    open,
    selectedItem,
    setSelectedItem,
    toggle,
  };
};

export default useSelectState;
