import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

// Inner component content
export const Content = styled.div`
  padding: 0 var(--spacing-medium);

  @media ${devices.tabletS} {
    padding: 0;
  }
`;
