/**
 * @file Helpers functions that check for which pages we are on
 */

import { matchPath } from 'react-router-dom';

import {
  ROUTE__KANBAN,
  ROUTE__RESERVATIONS,
  ROUTE__SETTINGS,
  ROUTE__SETTINGS__EMAIL,
  ROUTE__SETTINGS__PUSH,
  ROUTE__SETTINGS__RECURRING,
} from '../../constants/routing/routes';
import { Message } from '../../generated/graphql';
import {
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsPath,
  createSettingsNotificationsPushPath,
  createSettingsPath,
} from '../paths/settings';

/**
 * Check whether the current page is Kanban
 *
 * @returns Whether it's true
 */
export const getIsFeedKanbanPage = (): boolean => {
  return location.pathname.includes(ROUTE__KANBAN);
};

/**
 * Check whether the current page is Feed Message Details
 *
 * @param messageId Id of the current message
 * @returns         Whether it's true
 */
export const getIsFeedMessageDetailsPage = (
  messageId: Message['id'] | null,
): boolean => {
  if (messageId === null) {
    return false;
  }

  return location.pathname.includes(`/post/${messageId}/details`);
};

/**
 * Check whether the current page is Reservations
 *
 * @returns Whether it's true
 */
export const getIsReservationsPage = (): boolean => {
  return location.pathname.includes('/' + ROUTE__RESERVATIONS);
};

/**
 * Check whether the current page is any of the Settings pages
 *
 * @returns Whether it's true
 */
export const getIsSettingsPage = (): boolean => {
  return location.pathname.includes('/' + ROUTE__SETTINGS);
};

/**
 * Check whether the current page is Settings root page
 *
 * @returns Whether it's true
 */
export const getIsSettingsRootPage = (): boolean => {
  return matchPath(createSettingsPath(), location.pathname) !== null;
};

/**
 * Check whether the current page is Settings -> Notifications
 *
 * @returns Whether it's true
 */
export const getIsSettingsNotificationsRootPage = (): boolean => {
  const path = createSettingsNotificationsPath();
  return matchPath(path, location.pathname) !== null;
};

/**
 * Check whether the current page is Settings -> Templates
 *
 * @returns Whether it's true
 */
export const getIsTemplatesPage = (): boolean => {
  return (
    location.pathname.includes(`/${ROUTE__SETTINGS}/templates`) &&
    location.pathname.includes(ROUTE__SETTINGS__RECURRING) === false
  );
};

/**
 * Check whether the current page is Settings -> Templates -> Recurring
 *
 * @returns Whether it's true
 */
export const getIsTemplatesRecurringPage = (): boolean => {
  return (
    location.pathname.includes(`/${ROUTE__SETTINGS}/templates`) &&
    location.pathname.includes(ROUTE__SETTINGS__RECURRING) === true
  );
};

/**
 * Check whether the current page is
 * Settings -> Notifications -> Email
 * Settings -> Notifications -> Push
 *
 * @returns Whether it's true
 */
export const getIsSettingsNotificationsCategoryPage = (): boolean => {
  return [
    createSettingsNotificationsEmailPath(),
    createSettingsNotificationsPushPath(),
  ].some(path => matchPath(path, location.pathname) !== null);
};

/**
 * Check whether the current page is Settings for Email notifications
 *
 * @returns Whether it's true
 */
export const getIsSettingsEmailPage = (): boolean => {
  return location.pathname.includes('/' + ROUTE__SETTINGS__EMAIL);
};

/**
 * Check whether the current page is Settings for Push notifications
 *
 * @returns Whether it's true
 */
export const getIsSettingsPushPage = (): boolean => {
  return location.pathname.includes('/' + ROUTE__SETTINGS__PUSH);
};
