import { styled } from 'styled-components';

import breakpoints from '../../../constants/breakpoints';
import devices from '../../../styles/devices';

import {
  getRightSidebarMaxWidthDesktop,
  getRightSidebarMinWidthDesktop,
} from './helpers';

import {
  MainProps,
  RightAsideComponentWrapperProps,
  RightAsideProps,
} from './styledProps';

export const Wrapper = styled.div`
  display: grid;
  grid-template: min-content min-content minmax(0, 1fr) min-content / 1fr;
  grid-template-areas:
    'header'
    'sub-header'
    'main'
    'footer';
  height: var(--height-full);
  overflow: hidden;
  position: relative;

  @media ${devices.tabletS} {
    gap: var(--spacing-small);
    grid-template: min-content 1fr min-content / min-content 1fr min-content;
    grid-template-areas:
      'left-sidebar header right-sidebar'
      'left-sidebar main right-sidebar'
      'left-sidebar footer right-sidebar';
  }

  @media ${devices.laptopS} {
    position: static;
  }
`;

export const Header = styled.header`
  grid-area: header;
  position: relative;

  &:empty {
    display: none;
  }
`;

/**
 * MainContainer's job is to be static container on which we can attach absolutely
 * positioned elements, because container can have a scroll, and we don't want
 * everything to scroll with it.
 */
export const MainContainer = styled.div`
  display: flex;
  grid-area: main;
  overflow: hidden;
  position: relative;
`;

export const Main = styled.main<MainProps>`
  display: flex;

  /**
   * Since we are using inverted order for messages (bottom ones first),
   * best way to achieve natural scroll is to use column-reverse to reverse the order.
   */
  flex-direction: ${props =>
    props['data-direction'] === 'normal' ? 'column' : 'column-reverse'};
  flex-grow: 1;
  height: ${props => props['data-height']};
  max-height: 100%;

  /**
   * This enables scroll on the main area because only
   * middle part of the app should be scrollable for content.
   * Rest should be fixed without body scrolling.
   */
  overflow-y: auto;
`;

export const RightAside = styled.aside<RightAsideProps>`
  grid-area: main;
  max-width: 100vw;

  @media ${devices.tabletS} {
    grid-area: right-sidebar;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: ${props =>
      props['data-should-show-desktop'] ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform var(--preference-transition-duration-normal);
    width: ${props =>
      props['data-should-show-desktop']
        ? 'var(--preference-right-sidebar-open-width-tablet)'
        : 0};
    z-index: var(--z-sidebar);
  }

  @media ${devices.laptopS} {
    max-width: ${getRightSidebarMaxWidthDesktop};
    min-width: ${getRightSidebarMinWidthDesktop};
    position: relative;
    transform: none;
    transition: width var(--preference-transition-duration-normal);
    width: ${props =>
      props['data-should-show-desktop']
        ? 'var(--preference-right-sidebar-open-width-desktop)'
        : 0};
  }
`;

export const RightAsideComponentWrapper = styled.div<RightAsideComponentWrapperProps>`
  background-color: var(--color-background);
  border-left: var(--border-dark);
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: ${props =>
    props['data-should-show-mobile']
      ? 'translateX(0px); opacity: 1;'
      : `translateX(${breakpoints.tabletS}px); opacity: 0;`};
  transition: transform var(--preference-transition-duration-normal)
    var(--cubic-bezier-1);
  z-index: var(--z-sidebar);

  @media ${devices.tabletS} {
    opacity: ${props => (props['data-should-show-desktop'] ? 1 : 0)};
    position: absolute;
    transform: none;
    transition:
      transform var(--preference-transition-duration-normal),
      opacity var(--preference-transition-duration-short),
      visibility var(--preference-transition-duration-short);
    visibility: ${props =>
      props['data-should-show-desktop'] ? 'visible' : 'hidden'};
    width: var(--preference-right-sidebar-open-width-tablet);
  }

  @media ${devices.laptopS} {
    width: var(--preference-right-sidebar-open-width-desktop);
  }
`;
