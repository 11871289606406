import React, { FC, FormEventHandler, useId } from 'react';

import { CHECKLIST__MAX_TOTAL_ITEMS } from '../../constants/forms';
import translate from '../../i18n/translate';
import intlPluralFormatter from '../../utils/intlPluralFormat';
import ChecklistFormCreate from '../Common/ChecklistFormCreate';
import ChecklistItems from '../Common/ChecklistItems';
import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

import { PropsUnion } from './props';

/**
 * Checklist popup for the draft
 *
 * @param props                 Props passed to the component
 * @param props.inputCustom     The component to use for input instead of the plain one
 * @param props.isActive        Whether the component should be visible
 * @param props.itemsCurrent    The checklist items to render
 * @param props.itemsSaved      Saved checklist items (this will show up in input)
 * @param props.linkToggleProps Props for the toggle link
 * @param props.mode            Whether we're working with draft or existing message's checklist
 * @param props.requestCreate   Request that a new item is added
 * @param props.requestDelete   Request that an existing item ist removed
 * @param props.requestReorder  Request that the list is reordered
 * @param props.requestSave     Request that the checklist items are saved
 * @param props.requestSetState Request that isChecked state for an item is updated
 * @param props.requestSetText  Request that text for an item is updated
 * @returns                     The component itself
 */
const MetaChecklist: FC<PropsUnion> = props => {
  const {
    inputCustom,
    itemsCurrent,
    itemsSaved,
    isActive,
    linkToggleProps = null,
    requestCreate,
    requestSave,
  } = props;

  /**
   * We need to check if current checklist items are equal to draft/saved checklist items
   * so we can disable save button if they are equal.
   * JSON.stringify() is used here so we can check for deep equality.
   */
  const isSaveDisabled =
    JSON.stringify(itemsCurrent) === JSON.stringify(itemsSaved);

  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');
  const textPlaceholder = translate('CHECKLIST__PLACEHOLDER');

  const itemCount = itemsCurrent.length;
  const canCreateMore = itemCount < CHECKLIST__MAX_TOTAL_ITEMS;

  const idForm = useId();
  const idHeading = useId();

  const textHeading = translate('CHECKLIST__PLACEHOLDER');

  /**
   * Extracted rendering of the checklist itself
   * so that the same component can be used
   * both for drafts and existing messages' checklist
   *
   * It's true that the branches are identical,
   * however if not done like this, TypeScript wouldn't recognize
   * request callback types properly, as well as items type
   * (since for draft, we rely on position,
   * and for existing messages, we rely on item id for identification)
   *
   * @returns The appropriate checklist items component
   */
  const getList = () => {
    if (props.mode === 'DRAFT') {
      const {
        itemsCurrent: items,
        requestDelete,
        requestReorder,
        requestSetState,
        requestSetText,
      } = props;

      return (
        <ChecklistItems
          items={items}
          mode={props.mode}
          requestDelete={requestDelete}
          requestReorder={requestReorder}
          requestSetState={requestSetState}
          requestSetText={requestSetText}
        />
      );
    }

    const {
      itemsCurrent: items,
      requestDelete,
      requestReorder,
      requestSetState,
      requestSetText,
    } = props;

    return (
      <ChecklistItems
        items={items}
        mode={props.mode}
        requestDelete={requestDelete}
        requestReorder={requestReorder}
        requestSetState={requestSetState}
        requestSetText={requestSetText}
      />
    );
  };

  /**
   * Get the titles of saved checklist items
   * Branches are identical, but otherwise TypeScript gets confuse
   *
   * @returns The saved titles array
   */
  const getSavedTitles = () => {
    if (props.mode === 'DRAFT') {
      return props.itemsSaved.map(item => item.text);
    }

    return props.itemsSaved.map(item => item.text);
  };

  /**
   * The user has submitted the form, so we request that the checked items are saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestSave();
  };

  const itemsExisting = itemsCurrent.map(item => item.text);

  const children = (
    <>
      {canCreateMore && (
        <ChecklistFormCreate
          itemsExisting={itemsExisting}
          requestCreate={requestCreate}
        />
      )}
      <form id={idForm} onSubmit={onSubmit}>
        {getList()}
      </form>
    </>
  );

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    align: 'right',
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    textHeading,
  };

  if (linkToggleProps === null) {
    return (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    );
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={intlPluralFormatter(getSavedTitles())}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaChecklist;
