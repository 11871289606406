import { Title } from '@radix-ui/react-toast';
import { styled } from 'styled-components';

import CommonChevron from '../../Chevron';

// Chevron arrow, indicating whether additional text is visible or not
export const Chevron = styled(CommonChevron)`
  flex-shrink: 0;
  margin-left: var(--spacing-tiny);
`;

// Toast title
export const MessageTitle = styled(Title)`
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-label-bold);
`;
