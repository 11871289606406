/**
 * @file Optimistically update a template
 */
import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  TemplatesDocument,
  TemplatesQuery,
  TemplatesQueryVariables,
  TemplateUpdateMutation,
  TemplateUpdateMutationVariables,
} from '../../../../../generated/graphql';
import { getTemplateById } from '../../updates/utils/getters/template';
import templatesUpdate, {
  getUpdatedTemplate,
} from '../../updates/utils/updaters/templates/templatesUpdate';

/**
 * Optimistically update a template
 *
 * @param args  Arguments of the GraphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const templateUpdate: OptimisticMutationResolver<
  TemplateUpdateMutationVariables,
  TemplateUpdateMutation['templateUpdate'] | null
> = (args, cache) => {
  const templatesQuery = cache.readQuery<
    TemplatesQuery,
    TemplatesQueryVariables
  >({
    query: TemplatesDocument,
  });

  const { title } = args.data;

  const targetTemplate = templatesQuery?.templatesEditable.find(
    ({ id }) => id === args.id,
  );

  if (targetTemplate === undefined) {
    return null;
  }

  const updates: Pick<
    TemplateUpdateMutation['templateUpdate'],
    'id' | 'title'
  > = {
    id: args.id,
    title: title ?? targetTemplate.title,
  };

  templatesUpdate(cache, {
    ...updates,
    creator: targetTemplate.creator,
    topic: targetTemplate.topic,
  });
  const original = getTemplateById(cache, args.id);

  return original === null
    ? null
    : getUpdatedTemplate(original, {
        ...updates,
        creator: targetTemplate.creator,
        topic: targetTemplate.topic,
      });
};

export default templateUpdate;
