import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

// The entire message/template creation form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium) var(--spacing-medium) 0;

  @media ${devices.tabletS} {
    padding: 0;
  }
`;
