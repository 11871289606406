/**
 * @file Helpers for the comment single styles
 */

export type TextProps = {
  'data-inactive': boolean;
};

export type UsernameProps = {
  'data-inactive': boolean;
};

export type WrapperProps = {
  'data-is-guest': boolean;
  'data-is-external': boolean;
  'data-is-mobile': boolean;
};

/**
 * Get which background color to use for the comment
 *
 * @param props Props passed to the component
 * @returns     Variable used to show the background color
 */
export const getBackgroundColor = (props: WrapperProps): string => {
  if (props['data-is-guest'] === true) {
    return 'var(--color-background-comment-guest)';
  }

  if (props['data-is-external'] === true) {
    return 'var(--color-background-comment-staff)';
  }

  return props['data-is-mobile']
    ? 'var(--color-background)'
    : 'var(--color-hover-light)';
};
