import { styled } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 var(--spacing-small);
  padding-top: var(--spacing-large);
`;
