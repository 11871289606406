/**
 * @file contains hook for retrieving the number of unread messages from all topics
 */

import { useQuery } from 'urql';

import { TopicsUnreadDocument } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

/**
 * Custom hook for retrieving the number of unread messages from all topics
 *
 * @returns The number of unread messages from all topics
 */
const useUnreadMessagesCount = (): number => {
  const [{ data, error }] = useQuery({
    query: TopicsUnreadDocument,
    variables: {},
  });

  const topicNotificationsCount =
    data?.topics.reduce((total, topic) => total + topic.unreadMessages, 0) ?? 0;

  if (error) {
    reportError(error);
  }

  return topicNotificationsCount;
};

export default useUnreadMessagesCount;
