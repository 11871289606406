/* eslint-disable complexity */
/**
 * @file contains the draft edit template reducer function
 */

import { DRAFTS__EDIT_TEMPLATE_DATA_SAVE } from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import { DraftActionEditTemplateDataSave } from '../../models/draftEditTemplate';

import stateDraftsClone from '../../utils/stateDraftsClone';
import initialState from '../initialState';

type Reducer = (
  state: DraftsState,
  action: DraftActionEditTemplateDataSave,
) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to populate template,
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveEditTemplateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionEditTemplateDataSave,
): DraftsState => {
  const { data, parentId } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    const assignedGroups = new Set(data.assignedGroups);
    const assignedMembers = new Set(data.assignedMembers);
    const checklist =
      data.checklist?.map((item, index) => ({
        ...item,
        position: index,
      })) ?? [];
    const labels = new Set(data.labels);
    const offset = data.dueDateOffset ?? null;

    draftNext.assigneeGroupsCurrent = assignedGroups;
    draftNext.assigneeGroupsSaved = assignedGroups;
    draftNext.assigneeMembersSaved = assignedMembers;
    draftNext.assigneeMembersCurrent = assignedMembers;
    draftNext.checklistCurrent = checklist;
    draftNext.checklistSaved = checklist;
    draftNext.labelsCurrent = labels ?? draftNext.labelsCurrent;
    draftNext.labelsSaved = labels ?? draftNext.labelsSaved;
    draftNext.offsetCurrent = offset;
    draftNext.offsetSaved = offset;
    draftNext.remindersCurrent = data.reminders ?? [];
    draftNext.remindersSaved = data.reminders ?? [];
    draftNext.templateTopicCurrent = data.topic;
    draftNext.templateTopicSaved = data.topic;
    draftNext.text = data.text;
    draftNext.title = data.title;
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft checklists
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__EDIT_TEMPLATE_DATA_SAVE, onSaveEditTemplateRequest],
]);

/**
 * Drafts editTemplate reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsEditTemplate = (
  state = initialState.drafts,
  action: DraftActionEditTemplateDataSave,
): DraftsState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsEditTemplate;
