/**
 * @file Getter functions for templates in the urql cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  Template,
  TemplateDocument,
  TemplateFullFragment,
  TemplateQuery,
  TemplateQueryVariables,
} from '../../../../../../generated/graphql';

/**
 * Get a single template by its ID
 *
 * @param cache      GraphQL cache
 * @param templateId The ID of the requested template
 * @returns          The requested template, if found (null otherwise)
 */
export const getTemplateById = (
  cache: Cache,
  templateId: Template['id'],
): TemplateFullFragment | null => {
  const result = cache.readQuery<TemplateQuery, TemplateQueryVariables>({
    query: TemplateDocument,
    variables: { templateId },
  });

  if (result === null) {
    return null;
  }

  return result.template;
};
