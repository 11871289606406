/**
 * @file Getter functions for settings in the urql cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  UserSettingsDocument,
  UserSettingsQuery,
  UserSettingsQueryVariables,
} from '../../../../../../generated/graphql';
import { UserID } from '../../../../../../models/user';

/**
 * Use GraphQL cache to retrieve user data
 *
 * @param cache urql GraphQL cache
 * @param id    The ID of the user we want
 * @returns     The user, if found, or null if not
 */
export const getSettingsUserById = (cache: Cache, id: UserID) => {
  const data = cache.readQuery<UserSettingsQuery, UserSettingsQueryVariables>({
    query: UserSettingsDocument,
  });

  if (data === null) {
    return null;
  }

  const user = data.usersAll.find(u => u.id === id);

  if (user === undefined) {
    return null;
  }

  return { ...user, __typename: 'User' };
};
