import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  className?: string;
  label: string;
  onChange: (value: string) => void;
  placeholder: HTMLInputElement['placeholder'];
  shouldFocus?: boolean;
  value: string;
};

/**
 * Search input for filtering groups and members
 *
 * @param props             Props passed to the component
 * @param props.className   styled-components generated class name
 * @param props.label       Input label
 * @param props.onChange    When search input changes
 * @param props.placeholder Input placeholder
 * @param props.shouldFocus Should the input be auto focused
 * @param props.value       Current search value
 * @returns                 The component itself
 */
const InputSearch: FC<Props> = ({
  className,
  label,
  onChange,
  placeholder,
  shouldFocus,
  value,
}) => {
  return (
    <Styled.Input
      ariaLabel={label}
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      shouldFocus={shouldFocus}
      type="search"
      value={value}
    />
  );
};

export default InputSearch;
