import { styled } from 'styled-components';

import CommonButton from '../Button';

export type ButtonProps = {
  'data-color': string;
  'data-color-hover': string;
  'data-position': 'left' | 'right';
};

/**
 * Get the color to use for button text
 *
 * @param props The props passed to the button
 * @returns     The color to use
 */
const getColorHover = (props: ButtonProps) => {
  return props['data-color-hover'];
};

/**
 * Get the color to use for button text
 *
 * @param props The props passed to the button
 * @returns     The color to use
 */
const getColorRegular = (props: ButtonProps) => {
  return props['data-color'];
};

export const Button = styled(CommonButton)<ButtonProps>`
  align-items: center;
  color: ${getColorRegular};
  display: flex;
  flex-direction: ${props =>
    props['data-position'] === 'left' ? 'row' : 'row-reverse'};
  gap: var(--spacing-small);
  justify-content: left;

  @media (hover: hover) {
    &:hover {
      color: ${getColorHover};
    }
  }
`;
