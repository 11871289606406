import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'urql';

import ComposeMetaTemplates from '../../../components/Compose/Common/ComposeMeta/ComposeMetaTemplates';
import MetaTemplate from '../../../components/MetaTemplate';
import {
  TemplateDocument,
  TemplatesInTopicDocument,
} from '../../../generated/graphql';
import { ComposeModeParam } from '../../../models/pageParams';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Container for setting the template to be used for a message
 *
 * @param props                         Props passed to the container
 * @param props.dataTemplateCurrent     Currently selected template
 * @param props.dataTemplateSaved       Saved template
 * @param props.dataTemplateSearchValue The term to filter templates by
 * @param props.debugLoading            Whether to permanently enable loading state
 * @param props.onTemplatesSearch       Callback to invoke on search term change
 * @param props.requestDraftPopulate    Request to populate the message draft with template data
 * @param props.requestTemplateRemove   Request resetting the current template
 * @param props.requestTemplateSave     Request storing template, current -> saved
 * @param props.requestTemplateSet      Request setting the current template
 * @param props.topicId                 The topic the message is in
 * @returns                             The container itself
 */
const ComposeMetaTemplateContainer: FC<Props> = ({
  dataTemplateCurrent,
  dataTemplateSaved,
  dataTemplateSearchValue,
  debugLoading,
  onTemplatesSearch,
  requestDraftPopulate,
  requestTemplateRemove,
  requestTemplateSave,
  requestTemplateSet,
  topicId,
}) => {
  // Fetching the full info for the saved template
  // (so we can populate the message draft)
  const [{ data: dataSingle, fetching: fetchingSingle }] = useQuery({
    pause: dataTemplateSaved === null,
    query: TemplateDocument,
    variables: { templateId: dataTemplateSaved ?? '' },
  });

  // Fetching basic info for all templates available for the message
  const [{ data: dataAll, fetching: fetchingAll }] = useQuery({
    pause: topicId === null,
    query: TemplatesInTopicDocument,
    variables: { topicId: topicId ?? '' },
  });

  const dataTemplateAll = dataAll?.templatesInTopic ?? [];

  const mode: ComposeModeParam = 'template';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  const isLoading = fetchingSingle || fetchingAll || debugLoading;

  useEffect(() => {
    if (dataSingle?.template && dataTemplateSaved !== null) {
      requestDraftPopulate(dataSingle.template);
    }

    // adding requestDraftPopulate creates memory leak
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSingle?.template]);

  return (
    <MetaTemplate
      dataSearchValue={dataTemplateSearchValue}
      dataTemplateAll={dataTemplateAll}
      dataTemplateCurrent={dataTemplateCurrent}
      dataTemplateSaved={dataTemplateSaved}
      inputCustom={
        <ComposeMetaTemplates
          template={dataTemplateAll.find(
            template => template.id === dataTemplateSaved,
          )}
        />
      }
      isActive={isActive}
      isLoading={isLoading}
      linkToggleProps={linkToggleProps}
      onSearch={onTemplatesSearch}
      requestTemplateChange={requestTemplateSet}
      requestTemplateRemove={requestTemplateRemove}
      requestTemplateSave={() => {
        requestTemplateSave();
        goBack();
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaTemplateContainer);
