import {
  Typography as MuiTypography,
  styled,
  TypographyProps,
} from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

export const LinkText = styled(Link)<LinkProps>({
  '&:focus-visible, &:hover': {
    textDecoration: 'underline',
  },
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  boxOrient: 'vertical',
  display: '-webkit-box',
  lineClamp: 2,
  lineHeight: 1.2,
  overflow: 'hidden',
});

export const Typography = styled(MuiTypography)<TypographyProps>(
  ({ component }) => ({
    fontWeight: component === 'h3' ? 600 : undefined,
    wordBreak: 'break-word',
  }),
);
