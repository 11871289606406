import React, { FC } from 'react';

import * as Styled from './styled';

/**
 * Progressbar skeleton
 *
 * @returns The component itself
 */
const SkeletonProgress: FC = () => {
  return <Styled.Progress />;
};

export default SkeletonProgress;
