import { ChatMessageBell } from '@gastfreund/roq-ui-react';
import React, { FC, ReactNode, useEffect } from 'react';

type Props = {
  children: ReactNode;
};

/**
 * Chat bubble icon used for navigation
 *
 * @param props          Props passed to the component
 * @param props.children Bubble icon
 * @returns              Chat bubble
 *
 * @summary This icon is wrapped inside anchor for navigation and only used in navigation.
 * ROQ-chat component wraps children in button, and we do not want both anchor and button
 * to be focusable.
 */
export const ChatBubble: FC<Props> = ({ children }) => {
  // We are setting tabindex to -1 on button that wraps icon to disable keyword interaction
  useEffect(() => {
    const chatButton = document.querySelector('.roq-chat-message-bell__button');
    chatButton?.setAttribute('tabindex', '-1');
  }, []);

  return <ChatMessageBell icon={children} />;
};
