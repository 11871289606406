/**
 * @file Get text to show for a passed date
 */

import translate from '../../i18n/translate';

import { dateFormatUi } from './format';

import getDifferenceInDays from './getDifferenceInDays';
import getStartOfToday from './getStartOfToday';

/**
 * Get text to show for a passed date
 * (can be "Today", "Yesterday", or a date string in locale format)
 *
 * @param date The date to use
 * @returns    The text to show
 */
const getDateText = (date: Date): string => {
  const today = getStartOfToday();
  const diff = getDifferenceInDays(date, today);

  if (diff === 0) {
    return translate('DATE__TODAY');
  } else if (diff === 1) {
    return translate('DATE__YESTERDAY');
  } else {
    return dateFormatUi(date);
  }
};

export default getDateText;
