/**
 * @file Check whether the browser supports Firebase Messaging
 */

import { isSupported } from 'firebase/messaging';
import { useEffect, useState } from 'react';

import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import useWebViewDeviceInfo from '../webview/useWebViewDeviceInfo';

/**
 * Check whether the browser supports Firebase Messaging
 *
 * @returns Whether it's supported or not
 */
const useIsFirebaseSupported = (): boolean | null => {
  const deviceInfo = useWebViewDeviceInfo();
  const [hasSupport, setHasSupport] = useState<null | boolean>(null);
  const isReactNativeWebView = getIsReactNativeWebView();

  const hasNativeSupport = deviceInfo.deviceInfo?.hasFirebaseSupport ?? null;

  useEffect(() => {
    if (isReactNativeWebView) {
      setHasSupport(hasNativeSupport);
    } else {
      isSupported()
        .then(setHasSupport)
        .catch(() => setHasSupport(false));
    }
  }, [hasNativeSupport, isReactNativeWebView]);

  return hasSupport;
};

export default useIsFirebaseSupported;
