import React, { FC } from 'react';

import * as Styled from './styled';
import { Props } from './types';

/**
 * A single button with grey border and transparent background
 *
 * @param props             Props passed to the component
 * @param props.children    Text to show for the button
 * @param props.className   styled-components generated class name, needed for styling
 * @param props.formId      The form the button is associated with
 * @param props.isDisabled  Whether the button is disabled
 * @param props.onClick     Callback to be invoked on button click
 * @param props.shouldFocus Should the button be auto focused
 * @param props.type        The type attribute for the button
 * @param props.usage       Button usage, will determine its look
 * @param props.variant     Button variant, will determine its look
 * @returns                 The component itself
 */
const ButtonSingle: FC<Props> = ({
  children,
  className,
  formId,
  isDisabled = false,
  onClick,
  shouldFocus = false,
  type = 'button',
  usage = 'default',
  variant = 'solid',
}) => {
  return (
    <Styled.Button
      className={className}
      data-usage={`${usage}-${variant}`}
      formId={formId}
      isDisabled={isDisabled}
      onClick={onClick}
      shouldFocus={shouldFocus}
      type={type}
    >
      {children}
    </Styled.Button>
  );
};

export { type Props };
export default ButtonSingle;
