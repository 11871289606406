/**
 * @file Get the messages for the current pagination and topic
 */

import { useQuery } from 'urql';

import { MessagesDocument } from '../../generated/graphql';
import useMessageAccessible from '../../hooks/message/useMessageAccessible';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import { reportError } from '../../services/reporting';

import { getMessagesFromData, getPageInfoFromData } from './helpers';
import { PaginationState } from './hooks/useMessagesPaginationCursors';

/**
 * Get the messages for the current pagination and topic
 *
 * @param paginationState The current pagination state
 * @returns               The messages to show
 */
const useMessagesQuery = (paginationState: PaginationState) => {
  const paramMessageId = useParamMessageId();
  const topicId = useParamTopicId();

  const [, isMessageAccessibleFetching] = useMessageAccessible(paramMessageId);

  const [{ data, error, fetching }] = useQuery({
    // If the message we are fetching is not accessible, a pagination cursor error
    // will be thrown. To avoid that, we pause the query until we are sure that
    // the message is accessible
    pause: topicId === null || isMessageAccessibleFetching,
    query: MessagesDocument,
    variables: {
      topicId: topicId ?? '',
      ...paginationState,
    },
  });

  if (error) {
    reportError(error);
  }

  return {
    hasError: error !== undefined,
    isFetching: fetching,
    nodes: getMessagesFromData(topicId, data),
    pageInfo: getPageInfoFromData(data),
  };
};

export default useMessagesQuery;
