/**
 * @file Date helper function
 */

import { ONE_HOUR } from '../../constants/dateTime';

/**
 * Add one hour to passed date or current date if nothing is passed
 *
 * @param date Date
 * @returns    Passed date + 1 hour
 */
export const getOneHourAfterDate = (date?: Date): Date => {
  if (date === undefined) {
    return new Date(Date.now() + ONE_HOUR);
  }

  return new Date(date.getTime() + ONE_HOUR);
};
