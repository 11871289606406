/**
 * @file All permissions checks related to comments
 */

import { PermissionsComment } from '../../models/permissions';

import {
  COMMENT__ADMIN,
  COMMENT__CREATE,
  COMMENT__DELETE,
  COMMENT__UPDATE,
  COMMENT__VIEW,
} from './permissionsAll';

import { getPermissionsCurrent } from './storage';

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsComment): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the current user has admin permissions for comments
 *
 * @returns Whether it's true
 */
export const getIsAdmin = (): boolean => {
  return getPermissionsCurrent().includes(COMMENT__ADMIN);
};

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user is allowed to create a comment
 *
 * @param isArchived Flag that indicate if parent message is archived
 * @returns          Whether the user has permissions
 */
export const getCanCreateComment = (isArchived: boolean): boolean => {
  if (isArchived) {
    return false;
  }
  return getIsAdmin() || hasPermission(COMMENT__CREATE);
};

/**
 *
 * READ
 *
 */

/**
 * Check whether the user is allowed to view the comments
 *
 * @returns Whether the user has permissions
 */
export const getCanViewComment = (): boolean => hasPermission(COMMENT__VIEW);

/**
 *
 * UPDATE
 *
 * Comments are currently not updatable.
 */

/**
 * Check whether the user is allowed to update the comments
 *
 * @returns Whether the user has permissions
 */
export const getCanUpdateComment = (): boolean =>
  hasPermission(COMMENT__UPDATE);

/**
 *
 * DELETE
 *
 * Comments are currently not deletable.
 */

/**
 * Check whether the user is allowed to delete the comments
 *
 * @returns Whether the user has permissions
 */
export const getCanDeleteComment = (): boolean =>
  hasPermission(COMMENT__DELETE);
