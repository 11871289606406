import { ChipProps, Chip as MuiChip, styled } from '@mui/material';

type StyledChipProps = {
  'data-border-color': string;
  'data-bg-color': string;
};

export const CustomChip = styled(MuiChip)<Partial<StyledChipProps & ChipProps>>(
  props => ({
    backgroundColor: props['data-bg-color'],
    borderColor: props['data-border-color'],
    borderRadius: 8,
  }),
);
