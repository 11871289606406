import { styled } from 'styled-components';

import CommonInput from '../../../Common/Input';

// The input field itself
export const Input = styled(CommonInput)`
  display: block;
  padding: var(--spacing-small);
  width: 100%;
`;
