import React, { FC } from 'react';

import { COMPATIBILITY__ATTACHMENT_PREVIEW } from '../../../../constants/nativeCompatibility';
import useNativeCompatibility from '../../../../hooks/webview/useNativeCompatibility';
import { AttachmentAction } from '../../../../models/attachment';
import ButtonAttachmentAction from '../../../ButtonAttachmentAction';
import Ribbon from '../../../Common/Ribbon';

import * as Styled from './styled';

export type Props = {
  actionDownload: AttachmentAction;
  actionPreview: AttachmentAction;
  canDownloadFile: boolean;
  className?: string;
};

const ICON_SIZE = 20;

/**
 * A attachment ribbon component used to show download button
 *
 * @param props                 Props passed to the component
 * @param props.actionDownload  Attachment action download props
 * @param props.actionPreview   Attachment action preview props
 * @param props.canDownloadFile Whether download button should be shown
 * @param props.className       styled-components generated class name, needed for styling
 * @returns                     The component itself
 */
const AttachmentRibbon: FC<Props> = ({
  actionDownload,
  actionPreview,
  canDownloadFile,
  className,
}) => {
  /**
   * @todo Remove this after oldest used app version is >= 1.8
   * Check in App Conect and Play Console statistics.
   */
  const isCompatibleAttachmentPreview = useNativeCompatibility(
    COMPATIBILITY__ATTACHMENT_PREVIEW,
  );

  if (canDownloadFile === false) {
    return null;
  }

  return (
    <Styled.Wrapper className={className}>
      <Ribbon>
        {isCompatibleAttachmentPreview && (
          <ButtonAttachmentAction
            actionType="preview"
            iconSize={ICON_SIZE}
            isInProgress={actionPreview.isInProgress}
            link={actionPreview.link}
            linkRef={actionPreview.linkRef}
            requestButtonClick={actionPreview.requestButtonClick}
          />
        )}
        <ButtonAttachmentAction
          iconSize={ICON_SIZE}
          isInProgress={actionDownload.isInProgress}
          link={actionDownload.link}
          linkRef={actionDownload.linkRef}
          requestButtonClick={actionDownload.requestButtonClick}
        />
      </Ribbon>
    </Styled.Wrapper>
  );
};

export default AttachmentRibbon;
