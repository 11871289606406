import React, { FC } from 'react';

import { generateRandomInt } from '../../../utils/number';

import SkeletonLine from '../SkeletonLine';

import * as Styled from './styled';

const nrTopics = 5;

const sizeMin = 30;
const sizeMax = 80;

// Generate random widths for skeleton lines
const widths = Array.from({ length: nrTopics }, () => {
  return generateRandomInt(sizeMin, sizeMax);
});

/**
 * Generate a skeleton for topic list
 *
 * @returns The component itself
 */
const LoadingTopics: FC = () => {
  return (
    <Styled.Ul>
      {widths.map((width, index) => (
        // Nothing else to use as a key
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Li key={index}>
          <SkeletonLine width={width} />
        </Styled.Li>
      ))}
    </Styled.Ul>
  );
};

export default LoadingTopics;
