/**
 * @file Generate new initial draft state
 */

import { DraftDataFormatted } from '../models/draft';

/**
 * Generate new initial draft state
 *
 * @param parentId What the draft is for
 * @returns        The state to use
 */
const stateDraftsInitDraft = (parentId: string): DraftDataFormatted => ({
  assigneeGroupsCurrent: new Set(),
  assigneeGroupsSaved: new Set(),
  assigneeMembersCurrent: new Set(),
  assigneeMembersSaved: new Set(),
  assigneeSearch: '',
  checklistCurrent: [],
  checklistSaved: [],
  dataLabelsMode: 'VIEW',
  dateDueCurrent: null,
  dateDueSaved: null,
  labelCurrentId: null,
  labelsCurrent: new Set(),
  labelsSaved: new Set(),
  nodeId: null,
  offsetCurrent: null,
  offsetSaved: null,
  parentId,
  remindersCurrent: [],
  remindersSaved: [],
  templateCurrent: null,
  templateSaved: null,
  templateSearch: '',
  templateTopicCurrent: null,
  templateTopicSaved: null,
  templateTopicSearch: '',
  text: '',
  title: '',
});

export default stateDraftsInitDraft;
