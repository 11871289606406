/**
 * @file Get whether to add the updated topic to the list
 */

import { Cache } from '@urql/exchange-graphcache';

import { TopicInfoSubscription } from '../../../../../../../../generated/graphql';

import {
  getCanAccess,
  getIsMember,
} from '../../../../../../../../utils/permissions/topic';
import { getTopicsList, getTopicsSettings } from '../../../getters/topic';

type Callback = (
  cache: Cache,
  topicInfo: TopicInfoSubscription['topicInfo']['topic'],
) => boolean;

/**
 * Get whether the topic should be added to the topics list in the sidebar
 *
 * @param cache     urql GraphQL cache
 * @param topicInfo Info that came via the subscription
 * @returns         Whether the topic should be added to TopicsList query
 */
export const getShouldAddToList: Callback = (cache, topicInfo = null) => {
  // Missing info -> nothing to do
  if (topicInfo === null) {
    return false;
  }

  // If the topic is private but the user is not a member, don't add it
  if (topicInfo.isPrivate === true && getIsMember(topicInfo) === false) {
    return false;
  }

  const topics = getTopicsList(cache);

  // Missing info -> nothing to do
  if (topics === null) {
    return false;
  }

  // If the topic is accessible, only add if it's not already there
  const topicId = topicInfo.id;
  return topics.some(topic => topic.id === topicId) === false;
};

/**
 * Get whether the topic should be added to the topics list on the settings page
 *
 * @param cache     urql GraphQL cache
 * @param topicInfo Info that came via the subscription
 * @returns         Whether the topic should be added to TopicSettings query
 */
export const getShouldAddToSettings: Callback = (cache, topicInfo = null) => {
  const topics = getTopicsSettings(cache);

  // Missing info -> nothing to do
  if (topicInfo === null || topics === null) {
    return false;
  }

  // If the user can't access the topic at all, don't add it
  if (getCanAccess(topicInfo) === false) {
    return false;
  }

  // But if the topic is accessible, only add if it's not already there
  const topicId = topicInfo.id;
  return topics.some(topic => topic.id === topicId) === false;
};

/**
 * Get whether the user was removed from a topic
 *
 * @param cache     urql GraphQL cache
 * @param topicInfo Info that came via the subscription
 * @returns         Whether the topic should be removed from TopicsList query
 */
export const getShouldRemoveFromList: Callback = (cache, topicInfo = null) => {
  // Missing info -> nothing to do
  if (topicInfo === null) {
    return false;
  }

  // Topic is public, so it should remain
  if (topicInfo.isPrivate === false) {
    return false;
  }

  // Topic is private, but the user is still a member, so it should remain
  if (getIsMember(topicInfo)) {
    return false;
  }

  const topics = getTopicsList(cache);

  // Missing info -> nothing to do
  if (topics === null) {
    return false;
  }

  // Remove if it's present
  const topicId = topicInfo.id;
  return topics.some(topic => topic.id === topicId);
};

/**
 * Get whether to remove a topic from the settings page
 *
 * @param cache     url GraphQL cache
 * @param topicInfo Info that came via the topicInfo subscription
 * @returns         Whether the topic should be removed from TopicSettings query
 */
export const getShouldRemoveFromSettings: Callback = (
  cache,
  topicInfo = null,
) => {
  if (topicInfo === null) {
    return false;
  }

  if (getCanAccess(topicInfo)) {
    return false;
  }

  const topics = getTopicsSettings(cache);

  if (topics === null) {
    return false;
  }

  const topicId = topicInfo.id;
  return topics.some(topic => topic.id === topicId);
};
