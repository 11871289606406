import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  color?: string;
  colorTrack?: string;
  size?: number;
};

export const defaultColorBorder = 'var(--color-primary-light)';
export const defaultColorTrack = 'var(--color-grey-pale)';
export const defaultSize = 18;

/**
 * Common Spinner component
 *
 * @param props            Props passed to the component
 * @param props.color      Color of the spinner moving part
 * @param props.colorTrack Color of the spinner track
 * @param props.size       Size of the spinner
 * @returns                The component itself
 */
const Spinner: FC<Props> = ({
  color = defaultColorBorder,
  colorTrack = defaultColorTrack,
  size = defaultSize,
}) => {
  return (
    <Styled.Ring
      aria-busy="true"
      aria-live="polite"
      aria-valuetext={translate('ATTACHMENT__STATUS__UPLOAD')}
      data-color-border={color}
      data-color-track={colorTrack}
      data-size={size}
      role="progressbar"
    >
      <span />
      <div />
      <div />
      <div />
    </Styled.Ring>
  );
};

export default Spinner;
