import React, { FC } from 'react';

import { AttachmentAction, AttachmentMode } from '../../../models/attachment';
import ButtonAttachmentAction from '../../ButtonAttachmentAction';
import ButtonListItemDelete from '../../Common/ButtonListItemDelete';
import Spinner from '../../Common/Spinner';

import { DetailsEditFragmentWrapper as StyledWrapper } from './styled';

const SPINNER_SIZE = 18;

type CommonProps = {
  canDeleteFile: boolean;
  isUploading?: boolean;
  mode: AttachmentMode;
  requestButtonClick: () => void;
};

export type Props = CommonProps &
  (
    | {
        actionDownload?: AttachmentAction;
        canDownloadFile: false;
      }
    | {
        actionDownload: AttachmentAction;
        canDownloadFile: true;
      }
  );

const ICON_SIZE = 20;

/**
 * Fragment of AttachmentSingle rendered in detailsEdit mode
 *
 * @param props                    Props passed to the component
 * @param props.actionDownload     Attachment action download props
 * @param props.canDeleteFile      Whether the user can delete the file
 * @param props.canDownloadFile    Whether the user can download the file
 * @param props.isUploading        Whether the attachment is being uploaded
 * @param props.mode               In which mode to render the component
 * @param props.requestButtonClick Callback for delete button click
 * @returns                        The component itself
 */
const DetailsEditFragment: FC<Props> = ({
  actionDownload,
  canDeleteFile,
  canDownloadFile,
  isUploading = false,
  mode,
  requestButtonClick,
}) => {
  if (mode !== 'detailsEdit') {
    return null;
  }

  if (isUploading) {
    return <Spinner size={SPINNER_SIZE} />;
  }

  return (
    <StyledWrapper>
      {canDownloadFile && (
        <ButtonAttachmentAction
          color="var(--color-icons-primary)"
          iconSize={ICON_SIZE}
          isInProgress={actionDownload.isInProgress}
          link={actionDownload.link}
          linkRef={actionDownload.linkRef}
          requestButtonClick={actionDownload.requestButtonClick}
        />
      )}
      {canDeleteFile && <ButtonListItemDelete onClick={requestButtonClick} />}
    </StyledWrapper>
  );
};

export default DetailsEditFragment;
