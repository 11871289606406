/**
 * @file contains functions used to create paths for use in Route related to the smart-views page
 */
import { ROUTE__SMART_VIEWS } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the smart views
 *
 * @returns The generated path
 */
export const createSmartViewPath = () => {
  return `${createDashboardPath()}/${ROUTE__SMART_VIEWS}`;
};

/**
 * Generate a path for the smart views smart view page
 *
 * @returns The generated path
 */
export const createSmartViewsSmartViewPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SMART_VIEWS}/:smartView`;
};
