import React, { FC } from 'react';

import { generateRandomInt } from '../../../utils/number';
import SkeletonLine from '../SkeletonLine';

import * as Styled from './styled';

const sizeMin = 30;
const sizeMax = 70;

type Props = {
  nrGroups?: number;
};

/**
 * Skeleton loading for user groups
 *
 * @param props          The props passed to the component
 * @param props.nrGroups The number of groups to show the skeleton for
 * @returns              The component itself
 */
const AssigneesGroupsLoading: FC<Props> = ({ nrGroups = 5 }) => {
  // Generate random widths for skeleton lines, between sizeMin and sizeMax
  const widths = Array.from({ length: nrGroups }, () => {
    return generateRandomInt(sizeMin, sizeMax);
  });

  return (
    <Styled.Ul>
      {widths.map((width, index) => (
        // No way to generate a key for this
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Li data-width={width} key={index}>
          <SkeletonLine />
        </Styled.Li>
      ))}
    </Styled.Ul>
  );
};

export default AssigneesGroupsLoading;
