import { styled } from 'styled-components';

import devices from '../../styles/devices';

// Inner component content
export const Content = styled.div`
  padding: 0 calc(var(--spacing-medium));

  @media ${devices.tabletS} {
    padding-left: 0;
    padding-right: var(--spacing-small);
  }
`;

// Divider line between members and groups
export const Hr = styled.hr`
  background-color: var(--color-border-light);
  border: none;
  height: 1px;
  margin: var(--spacing-tiny) 0 var(--spacing-small);
`;
