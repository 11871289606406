/**
 * @file contains the draftTemplates reducer function
 */
import {
  DRAFTS__TEMPLATE__RESET,
  DRAFTS__TEMPLATE__SAVE,
  DRAFTS__TEMPLATE__SEARCH__SET,
  DRAFTS__TEMPLATE__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionTemplate,
  DraftActionTemplateReset,
  DraftActionTemplateSave,
  DraftActionTemplateSearchSet,
  DraftActionTemplateSet,
} from '../../models/draftTemplate';

import stateDraftsClone from '../../utils/stateDraftsClone';

import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionTemplate) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to reset the selected template
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onResetTemplateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateReset,
) => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId } = action;

  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateCurrent = null;
  }

  return stateNext;
};

/**
 * A request was made to set template search term, so we store it
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSearchTemplateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateSearchSet,
) => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, value } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateSearch = value;
  }

  return stateNext;
};

/**
 * A request was made to store currently selected template
 * so we transfer data from templateCurrent to templateSaved
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveTemplateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateSaved = draftCurrent.templateCurrent;
  }

  return stateNext;
};

/**
 * The user has changed current template,
 * so we update the state to reflect that
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onUpdateTemplateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, templateId } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateCurrent = templateId;
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft template
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__TEMPLATE__RESET, onResetTemplateRequest],
  [DRAFTS__TEMPLATE__SET, onUpdateTemplateRequest],
  [DRAFTS__TEMPLATE__SAVE, onSaveTemplateRequest],
  [DRAFTS__TEMPLATE__SEARCH__SET, onSearchTemplateRequest],
]);

/**
 * Drafts template reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftTemplates = (
  state = initialState.drafts,
  action: DraftActionTemplate,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftTemplates;
