import React, { FC, useId, useRef, useState } from 'react';
import { LinkProps } from 'react-router-dom';

import { Label } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import LabelUpdateForm from '../../Forms/LabelUpdateForm';
import MetaContainerInline, {
  Props as PropsInline,
} from '../../MetaContainerInline';
import MetaContainerPopup, {
  Props as PropsPopup,
} from '../../MetaContainerPopup';

type Props = {
  className?: string;
  dataColor: Label['color'];
  dataText?: Label['text'];
  dataTextSelected?: Label['text'];
  isActive: boolean;
  labelClose?: string;
  labelsExisting: Label['text'][];
  linkToggleProps?: LinkProps | null;
  requestModeView: () => void;
  requestSave: (text: Label['text'], color: Label['color']) => void;
  textHeading?: string;
};

/**
 * Label create/edit form
 *
 * @param props                  Props passed to the component
 * @param props.className        styled-components generated class name, needed for styling
 * @param props.dataColor        Label color
 * @param props.dataText         Label text
 * @param props.dataTextSelected The currently selected label/text to edit
 * @param props.isActive         Whether the component should be visible
 * @param props.labelClose       The text to use as the close button label
 * @param props.labelsExisting   Labels already present
 * @param props.linkToggleProps  Props for the toggle link
 * @param props.requestModeView  Request to switch to view mode, showing a list of labels
 * @param props.requestSave      Callback to invoke to request a label to be updated
 * @param props.textHeading      The text to show for heading
 * @returns                      The component itself
 */
const MetaLabelEdit: FC<Props> = ({
  className,
  dataColor,
  dataText = '',
  dataTextSelected = '',
  isActive,
  labelClose = translate('GENERAL__CLOSE'),
  labelsExisting,
  linkToggleProps = null,
  requestModeView,
  requestSave,
  textHeading = translate('LABELS__EDIT__HEADING'),
}) => {
  const initialColor = useRef(dataColor);
  const initialText = useRef(dataTextSelected);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const idForm = useId();
  const idHeading = useId();

  const labelConfirm = translate('GENERAL__SAVE');
  const labelPlaceholderMain = translate('COMPOSE__PLACEHOLDER__LABEL');

  /**
   * Check if current color/text of the label is equal to initial
   * and enable/disable save button
   *
   * @param text  Text of label that is currently edited
   * @param color Color of label that is currently edited
   */
  const handleSaveDisabled = (
    text: Label['text'],
    color: Label['color'],
  ): void => {
    setIsSaveDisabled(
      initialText.current === text && initialColor.current === color,
    );
  };

  // Content is the same for both inline and popup meta
  const children = (
    <LabelUpdateForm
      ariaDescribedBy={idHeading}
      ariaLabelledBy={idHeading}
      dataColor={dataColor}
      dataTextSelected={dataTextSelected}
      idForm={idForm}
      labelsExisting={labelsExisting}
      requestSave={requestSave}
      requestSaveDisabled={handleSaveDisabled}
    />
  );

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    className,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose: requestModeView,
    textHeading,
  };

  // If linkToggleProps are passed, this means that we want to render as a popup
  // If it's not (null is default), that means we want to show inline (and not mess with URL)
  if (linkToggleProps === null) {
    return (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    );
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      linkProps={linkToggleProps}
      placeholder={labelPlaceholderMain}
      shouldShowPopup={isActive}
      value={dataText}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaLabelEdit;
