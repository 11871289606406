import { styled } from 'styled-components';

/**
 * Feed empty state,
 * Showing an illustration that the topic is empty
 */
export const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: var(--spacing-medium);
  width: 100%;
`;
