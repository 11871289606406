import { styled } from 'styled-components';

// Wrapper around a single label
export const Li = styled.li`
  border: var(--border-dark);
  height: var(--spacing-medium);
  margin-bottom: var(--spacing-small);

  &:last-child {
    margin-bottom: 0;
  }
`;
