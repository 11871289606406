/**
 * @file Hook that gets currently active smart view
 */
import { useParams } from 'react-router-dom';

/**
 * Hook that gets currently active smart view
 *
 * @returns Current active smart view
 */
const useParamSmartView = (): string | null => {
  const { smartView } = useParams<{ smartView: string }>();

  if (!smartView) {
    return null;
  }

  return smartView;
};

export default useParamSmartView;
