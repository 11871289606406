import { styled } from 'styled-components';

import devices from '../../../styles/devices';
import Triangle from '../../Common/Icon/Triangle';

export const Wrapper = styled.div`
  background-color: var(--color-background);
  border-right: var(--border-dark);
  display: none;
  height: 100%;
  overflow-y: auto;

  @media ${devices.tabletS} {
    display: block;
  }
`;

/**
 * We are aligning sidebar position for left and right for top position.
 * Left position is calculated visually to this number.
 */
const leftSidebarTrianglePosition = '-17px';
const topSidebarTrianglePosition =
  'var(--preference-sidebar-triangle-position-top)';

export const TriangleIcon = styled(Triangle)`
  @media ${devices.tabletS} {
    left: ${leftSidebarTrianglePosition};
    position: absolute;
    top: ${topSidebarTrianglePosition};
  }
`;
