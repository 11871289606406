/**
 * @file formatters for concierge form
 */
import { DESCRIPTION } from '../../constants/conciergeFieldTypes';
import { MessageCommonFragment, MessageType } from '../../generated/graphql';
import {
  ContactFormDataFormatted,
  ContactFormDataRaw,
} from '../../models/message';

import { formatContactFormFields } from './field';

/**
 * Extract the form description from form data
 * (find field of type DESCRIPTION and get its value)
 * (it's optional, and so if it's not found, we return null)
 *
 * @param data Data from which to extract the description from
 * @returns    The form description, if present; null if not
 */
const extractDescription = (data: ContactFormDataRaw): string | null => {
  const field = data.fields.find(
    singleField => parseInt(singleField.type, 10) === DESCRIPTION,
  );

  return field === undefined ? null : field.value;
};

/**
 * Format raw contact form data as it comes from the backend
 *
 * @param dataRaw The data to format
 * @returns       Formatted (and filtered) data
 */
export const formatContactFormData = (
  dataRaw: string,
): ContactFormDataFormatted | null => {
  try {
    const data = JSON.parse(dataRaw) as ContactFormDataRaw;

    return {
      description: extractDescription(data),
      fields: formatContactFormFields(data.fields),
      languages: { ...data.languages },
      title: data.title,
    };
  } catch {
    return null;
  }
};

/**
 * Extract message text
 * NOTE: Can't just do return message.text because for concierge,
 * that field contains decoded JSON
 *
 * @param message      Message to extract the text from
 * @param message.text Text of the message
 * @param message.type Type of the message
 * @returns            Message text
 */
export const getMessageText = ({
  text,
  type,
}: MessageCommonFragment): string => {
  if (type === MessageType.PlainText) {
    return text;
  }

  const data = formatContactFormData(text);
  return data?.description ?? '';
};
