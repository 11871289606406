import React, { FC, ReactNode } from 'react';

import { HASH_MESSAGE__INFO__TITLE } from '../../../../constants/routing/hashLinks';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
  isDimmed?: boolean;
};

/**
 * A single message's title
 *
 * @param props          Props passed to the component
 * @param props.children The title to show
 * @param props.isDimmed Whether to dim the text color
 * @returns              The component itself
 */
const MessageTitle: FC<Props> = ({ children, isDimmed = false }) => {
  return (
    <Styled.Wrapper data-is-dimmed={isDimmed} id={HASH_MESSAGE__INFO__TITLE}>
      {children}
    </Styled.Wrapper>
  );
};

export default MessageTitle;
