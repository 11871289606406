import { styled } from 'styled-components';

type DueDateProps = {
  'data-is-late': boolean;
  'data-is-resolved': boolean;
};

/**
 * Determine which color the DueDate inside a message should be,
 * depending on which status/date the message has
 *
 * @param props Props passed to the component
 * @returns     CSS variable string
 */
const getColor = (props: DueDateProps): string => {
  if (props['data-is-resolved']) {
    return 'var(--color-message-resolved)';
  }

  if (props['data-is-late']) {
    return 'var(--color-message-late)';
  }

  return 'var(--color-placeholder)';
};

export const Time = styled.time<DueDateProps>`
  color: ${getColor};
`;
