/**
 * @file Get firebase token from native app
 */

import { useEffect, useState } from 'react';

import { postWebViewFirebaseTokenEvent } from '../../utils/webview/events';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import {
  FirebaseTokenResponsePayload,
  WebViewEvent,
  WebViewEventNames,
} from '../../utils/webview/payloadTypes';

import { reactNativeMessageHandler } from './reactNativeMessageHandler';

/**
 * Get firebase token from native app
 *
 * @returns Object containing firebase token
 */
const useNativeFirebaseToken = (): {
  firebaseToken: FirebaseTokenResponsePayload['fcmToken'];
} => {
  const [firebaseToken, setFirebaseToken] =
    useState<FirebaseTokenResponsePayload>();

  useEffect(() => {
    postWebViewFirebaseTokenEvent();
  }, []);

  useEffect(() => {
    if (getIsReactNativeWebView() === false) {
      return;
    }

    const webViewEvents: WebViewEvent[] = [
      {
        callback: (response: FirebaseTokenResponsePayload) => {
          setFirebaseToken(response);
        },
        eventName: WebViewEventNames.FirebaseTokenResponse,
      },
    ];

    /**
     * A message has come in from the native side
     *
     * @param event The event that took place
     */
    const messageEventHandler = (event: MessageEvent<string>) => {
      reactNativeMessageHandler(event, webViewEvents);
    };

    document.addEventListener('message', messageEventHandler, false);
    window.addEventListener('message', messageEventHandler, false);

    return () => {
      document.removeEventListener('message', messageEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
  }, []);

  return { firebaseToken: firebaseToken?.fcmToken };
};

export default useNativeFirebaseToken;
