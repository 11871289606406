import React, { FC, FormEventHandler } from 'react';

import { DateOffsetInput, Reminder } from '../../../generated/graphql';

import Reminders from '../../Reminders';
import TemplateOffset from '../../TemplateOffset';

type Props = {
  ariaDescribedBy: string;
  ariaLabelledBy: string;
  dataOffsetCurrent: DateOffsetInput | null;
  dataRemindersCurrent: Reminder[];
  idForm: string;
  requestOffsetRemove: () => void;
  requestOffsetSet: (offset: DateOffsetInput) => void;
  requestRemindersAdd: (reminder: Reminder) => void;
  requestRemindersRemove: (position?: number) => void;
  requestRemindersSet: (position: number, reminder: Reminder) => void;
  requestSave: () => void;
};

/**
 * Form to select offset for template
 *
 * @param props                        Props passed to the component
 * @param props.ariaDescribedBy        Accessible form description
 * @param props.ariaLabelledBy         Accessible form title
 * @param props.dataOffsetCurrent      Currently selected offset
 * @param props.dataRemindersCurrent   Current reminders
 * @param props.idForm                 The ID to use for the offset <form /> element
 * @param props.requestOffsetRemove    Request that the current offset is removed
 * @param props.requestOffsetSet       Request that the current offset is changed
 * @param props.requestRemindersAdd    Request that the new reminder is added
 * @param props.requestRemindersRemove Request that the current reminder is removed (or all of the current reminders are removed)
 * @param props.requestRemindersSet    Request that the reminder is changed
 * @param props.requestSave            Request that the current offset and current reminders are saved
 * @returns                            The component itself
 */
const TemplateOffsetForm: FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  dataOffsetCurrent,
  dataRemindersCurrent,
  idForm,
  requestOffsetRemove,
  requestOffsetSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSet,
  requestSave,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the assignment is saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestSave();
  };

  return (
    <form
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      id={idForm}
      onSubmit={onSubmit}
    >
      <TemplateOffset
        dataOffsetCurrent={dataOffsetCurrent}
        requestOffsetRemove={requestOffsetRemove}
        requestOffsetSet={requestOffsetSet}
      />
      <Reminders
        isDisabled={dataOffsetCurrent === null}
        itemsCurrent={dataRemindersCurrent}
        requestRemindersAdd={requestRemindersAdd}
        requestRemindersRemove={requestRemindersRemove}
        requestRemindersSet={requestRemindersSet}
      />
    </form>
  );
};

export default TemplateOffsetForm;
