/**
 * @file A label was updated, so we update it in the cache,
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  LabelBasicFragment,
  LabelsDocument,
  LabelsQuery,
  LabelsQueryVariables,
  LabelUpdateMutationVariables,
} from '../../../../../../../generated/graphql';

/**
 * Merge original with updates and return
 *
 * @param original   The old data
 * @param topic      Updated label data
 * @param topic.text Updated label text
 * @returns          Merged label data
 */
const getUpdatedLabel = (
  original: LabelBasicFragment,
  { text }: LabelUpdateMutationVariables,
): LabelBasicFragment => ({
  ...original,
  text: text ?? original.text,
});

/**
 * A label was updated, so we update it in the cache,
 * and also re-sort labels if the text changed
 *
 * @param cache      urql cache object
 * @param updates    The updates that occurred
 * @param alwaysSort Whether to sort the labels in cache (regardless of whether updates.text seems the same)
 */
const labelsUpdate = (
  cache: Cache,
  updates: LabelUpdateMutationVariables,
  alwaysSort = false,
): void => {
  cache.updateQuery<LabelsQuery, LabelsQueryVariables>(
    { query: LabelsDocument },
    data => {
      /** Optional chaining doesn't work here, we need to handle both data and data.labels === null */
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (data === null || data.labels === null) {
        return null;
      }

      const labels = data.labels;
      const index = labels.findIndex(label => label.id === updates.id);

      if (index === -1) {
        return null;
      }

      const label = labels[index];

      labels[index] = getUpdatedLabel(label, updates);
      if (alwaysSort || label.text !== updates.text) {
        labels.sort((topicA, topicB) => topicA.text.localeCompare(topicB.text));
      }

      data.labels = labels;
      return data;
    },
  );
};

export default labelsUpdate;
