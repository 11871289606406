import React, { FC } from 'react';

import { TOAST__MESSAGE_DESCRIPTION_LENGTH } from '../../../../constants/preferences';
import { ToastMessageModel } from '../../../../models/toastMessage';
import truncateText from '../../../../utils/string/truncateText';

import * as Styled from './styled';

type Props = {
  children: ToastMessageModel['text'];
};

/**
 * Toast message text
 *
 * @param props          Props passed to the component
 * @param props.children The text to show
 * @returns              The component itself
 */
const ToastDescription: FC<Props> = ({ children }) => {
  return (
    <Styled.MessageDescription>
      {truncateText(TOAST__MESSAGE_DESCRIPTION_LENGTH, children)}
    </Styled.MessageDescription>
  );
};

export default ToastDescription;
