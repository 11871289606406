import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Reason = 'empty' | 'error' | 'fetching' | 'searching';

export type Props = {
  reason: Reason;
};

/**
 * Mapping between no result reason and the translations key
 */
const mapping = new Map<Reason, string>([
  ['empty', 'ASSIGNMENT__MEMBERS__NO_RESULT_EMPTY'],
  ['error', 'ASSIGNMENT__MEMBERS__NO_RESULT_ERROR'],
  ['fetching', 'GENERAL__PLEASE_WAIT'],
  ['searching', 'ASSIGNMENT__MEMBERS__NO_RESULT_SEARCHING'],
]);

/**
 * Get the message to show, explaining why there's nothing to show
 *
 * @param reason The reason we want the message for
 * @returns      The (translated) message
 */
const getMessage = (reason: Reason): string => {
  const message = mapping.get(reason);
  return message ? translate(message) : '';
};

/**
 * No result for assignee list
 *
 * @param props        Props passed to the component
 * @param props.reason Why is there nothing to show
 * @returns            The component itself
 */
const AssigneesMembersNoResult: FC<Props> = ({ reason }) => {
  return <Styled.Title>{getMessage(reason)}</Styled.Title>;
};

export default AssigneesMembersNoResult;
