/**
 * @file Hook for registering keyboard shortcuts to be used within the app
 */

import { useEffect } from 'react';

import { Shortcut } from '../constants/keyboardShortcuts';
import { getIsShortcut } from '../utils/keyboardShortcuts';

export type ShortcutDefinition = {
  callback: () => void;
  shortcut: Shortcut;
};

const keysAll = new Set<string>();

/**
 * Hook for registering keyboard shortcuts to be used within the app
 * It accepts an array of shortcut definitions,
 * Where callback is defined where its used
 * but shortcut is coming from constants
 *
 * @param shortcuts The shortcuts to register
 * @param action    Which action is the shortcut for
 */
const useKeyboardShortcuts = (
  shortcuts: ShortcutDefinition[],
  action: 'keyup' | 'keydown' | 'keypress' = 'keyup',
): void => {
  const shortcutsNew = shortcuts.filter(
    ({ shortcut: { key } }) => keysAll.has(key) === false,
  );

  useEffect(() => {
    /**
     * The user has pressed a key (and released it)
     * so we check if there's a registered shortcut for it
     *
     * If so, call the appropriate callback
     *
     * @param event The event that took place
     */
    const handleKeyUp = (event: KeyboardEvent) => {
      shortcutsNew.forEach(({ callback, shortcut }) => {
        if (getIsShortcut(event, shortcut)) {
          callback();
        }
      });
    };

    window.addEventListener(action, handleKeyUp, false);

    return () => {
      window.removeEventListener(action, handleKeyUp);
    };
  }, [action, shortcutsNew]);
};

export default useKeyboardShortcuts;
