import React, { FC } from 'react';

import { MODAL__POST_ATTACHMENT_DELETE } from '../../../constants/routeModalParams';
import { useModal } from '../../../containers/modals/useModal';
import { Message } from '../../../generated/graphql';

import translate from '../../../i18n/translate';
import {
  Attachment,
  AttachmentDraft,
  InputFileProps,
} from '../../../models/attachment';

import AttachmentAdd from '../../Attachments/AttachmentAdd';
import AttachmentList from '../../Attachments/AttachmentList';

import MessageNoResult from '../MessageNoResult';

type Props = {
  attachments: (Attachment | AttachmentDraft)[];
  canDeleteFile?: boolean;
  canDownloadFile?: boolean;
  canUploadFile?: boolean;
  inputFileProps?: InputFileProps;
  messageId?: Message['id'];
};

/**
 * Show list of currently attached files
 *
 * @param props                 Props passed to the component
 * @param props.attachments     Attachments to show
 * @param props.canDeleteFile   Whether user has permission to delete attachment
 * @param props.canDownloadFile Whether user has permission to delete attachment
 * @param props.canUploadFile   Whether user has permission to upload attachment
 * @param props.inputFileProps  Props passed to the <input type="file" />
 * @param props.messageId       Id of the current message
 * @returns                     The component itself
 */
const MessageAttachment: FC<Props> = ({
  attachments,
  canDeleteFile = false,
  canDownloadFile = false,
  canUploadFile = false,
  inputFileProps,
  messageId,
}) => {
  const { openModal } = useModal();

  /**
   * Open file picker system window
   */
  const handleFilePickerClick = () => {
    inputFileProps?.ref.current?.click();
  };

  if (attachments.length === 0) {
    return (
      <>
        <MessageNoResult message={translate('ATTACHMENT__MESSAGE__NONE')} />
        <AttachmentAdd
          canUploadFile={canUploadFile}
          inputFileProps={inputFileProps}
          requestFilePicker={handleFilePickerClick}
        />
      </>
    );
  }

  /**
   * Opens a modal to delete an attachment
   *
   * @param key File key
   */
  const requestFileRemove = (key: string) => {
    if (messageId !== undefined) {
      openModal(MODAL__POST_ATTACHMENT_DELETE, {
        attachmentId: key,
        messageId,
      });
    }
  };

  return (
    <>
      <AttachmentList
        attachments={attachments}
        canDeleteFile={canDeleteFile}
        canDownloadFile={canDownloadFile}
        messageId={messageId}
        mode="detailsEdit"
        requestFileRemove={requestFileRemove}
      />
      <AttachmentAdd
        canUploadFile={canUploadFile}
        inputFileProps={inputFileProps}
        requestFilePicker={handleFilePickerClick}
      />
    </>
  );
};

export default MessageAttachment;
