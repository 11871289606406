/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import {
  ROUTE__MORE,
  ROUTE__MORE__SELECT_HOTEL,
} from '../../constants/routing/routes';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

/**
 * Get URL for /more route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getMoreUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getMoreUrl was null');
  }

  return `/${hotelId}/${ROUTE__MORE}`;
};

/**
 * Get URL for /more/change-object route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getSelectHotelUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSelectHotelUrl was null');
  }

  return `/${hotelId}/${ROUTE__MORE}/${ROUTE__MORE__SELECT_HOTEL}`;
};
