import { styled } from 'styled-components';

import SkeletonLine from '../SkeletonLine';

// Wrapper around a single assignee
export const Li = styled.li`
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-small);
`;

// The line representing an assignee
export const Name = styled(SkeletonLine)`
  height: var(--spacing-medium);
  margin: 0 var(--spacing-medium);
`;
