/**
 * @file Helper functions for the messages container
 */

import { MessagesQuery, PageInfo, Topic } from '../../generated/graphql';
import { MessageFeed, MessageFetchingDirection } from '../../models/message';

import { PaginationState } from './hooks/useMessagesPaginationCursors';

/**
 * Extract nodes data from what urql useQuery hook returned
 *
 * @param topicId The ID of the current topic
 * @param data    The data returned by urql
 * @returns       The data to use
 */
export const getMessagesFromData = (
  topicId: Topic['id'] | null,
  data?: MessagesQuery,
): MessageFeed[] => {
  const nodes = data?.messages.nodes as MessageFeed[] | undefined;

  if (topicId === null || nodes === undefined || nodes.length === 0) {
    return [];
  }

  // Only return data we have if it's for the current topic
  return nodes[0].topic.id === topicId ? nodes : [];
};

/**
 * Extract page info from what urql useQuery hook returned
 * (return default values while fetching)
 *
 * @param data The data returned by urql
 * @returns    The page info to use
 */
export const getPageInfoFromData = (data?: MessagesQuery): PageInfo => {
  // According to TypeScript, data is never null,
  // But we do get BugSnag reports that it does happen
  if (data === null || data === undefined) {
    return {
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
    };
  }

  return data?.messages.pageInfo;
};

/**
 * Get the skeleton to render above the current messages
 * (or nothing if it's not supposed to be visible)
 *
 * @param fetching        Whether the data is being fetched
 * @param networkMessages Debug configuration for feed
 * @param paginationState Current pagination state
 * @returns               What to render above the feed (skeleton or nothing)
 */
export const getShouldShowSkeletons = (
  fetching: boolean,
  networkMessages: MessageFetchingDirection,
  paginationState: PaginationState,
): [boolean, boolean] => {
  /**
   * Get whether to show skeletons, above and below, the messages
   *
   * @param pagination Current pagination state
   * @param position   The position to check for
   * @returns          Whether to render the skeleton
   */
  const getShouldShow = (
    pagination: string | null,
    position: 'above' | 'below',
  ) => {
    return (
      networkMessages === position ||
      networkMessages === 'both' ||
      (fetching === true && pagination === '')
    );
  };

  return [
    getShouldShow(paginationState.before, 'above'),
    getShouldShow(paginationState.after, 'below'),
  ];
};
