/**
 * @file contains redux actions related to subscriptions state
 */

import {
  SUBSCRIPTION__COMMENTS_SUBSCRIBE,
  SUBSCRIPTION__COMMENTS_UNSUBSCRIBE,
  SUBSCRIPTION__MESSAGES_SUBSCRIBE,
  SUBSCRIPTION__MESSAGES_UNSUBSCRIBE,
} from '../../constants/actionTypes';
import { Message, Topic } from '../../generated/graphql';
import {
  SubscribeToComments,
  SubscribeToMessages,
  UnsubscribeFromComments,
  UnsubscribeFromMessages,
} from '../../models/subscriptions';

/**
 * Action to set the subscribe to messages state
 *
 * @param messageId ID of the topic to subscribe to
 * @returns         Appropriately formatted redux action
 */
export const subscribeToCommentsSet = (
  messageId: Message['id'],
): SubscribeToComments => {
  return {
    payload: {
      messageId,
    },
    type: SUBSCRIPTION__COMMENTS_SUBSCRIBE,
  };
};

/**
 * Action to set the subscribe to messages state
 *
 * @param topicId ID of the topic to subscribe to
 * @returns       Appropriately formatted redux action
 */
export const subscribeToMessagesSet = (
  topicId: Topic['id'],
): SubscribeToMessages => {
  return {
    payload: {
      topicId,
    },
    type: SUBSCRIPTION__MESSAGES_SUBSCRIBE,
  };
};

/**
 * Action to set the unsubscribe from comments state
 *
 * @param messageId ID of the message to unsubscribe from
 * @returns         Appropriately formatted redux action
 */
export const unsubscribeFromCommentsSet = (
  messageId: Message['id'],
): UnsubscribeFromComments => {
  return {
    payload: {
      messageId,
    },
    type: SUBSCRIPTION__COMMENTS_UNSUBSCRIBE,
  };
};

/**
 * Action to set the unsubscribe from messages state
 *
 * @param topicId ID of the topic to unsubscribe from
 * @returns       Appropriately formatted redux action
 */
export const unsubscribeFromMessagesSet = (
  topicId: Topic['id'],
): UnsubscribeFromMessages => {
  return {
    payload: {
      topicId,
    },
    type: SUBSCRIPTION__MESSAGES_UNSUBSCRIBE,
  };
};
