/**
 * @file contains a set of helpers that convert HEX color codes to RGBA
 */

import { reportError } from '../services/reporting';

export type ColorRGBA = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
};

const lengthShorthandPlain = 4;
const lengthShorthandAlpha = 5;
const lengthRegularPlain = 7;
const lengthRegularAlpha = 9;
const allowedLengths = [
  lengthShorthandPlain,
  lengthShorthandAlpha,
  lengthRegularPlain,
  lengthRegularAlpha,
];

/**
 * Get whether the hex code is short format ('#abc' or '#abcd')
 * (as opposed to regular format, eg. '#aabbcc' or '#aabbccdd')
 *
 * @param hex The string to check against
 * @returns   Whether it's true
 */
const getIsShort = (hex: string): boolean => {
  const { length } = hex;
  return length === lengthShorthandAlpha || length === lengthShorthandPlain;
};

/**
 * Get whether the hex code has alpha value
 * ('#abcd' or '#aabbccdd' format)
 *
 * @param hex The string to check against
 * @returns   Whether it's true
 */
const getHasAlpha = (hex: string): boolean => {
  const { length } = hex;
  return length === lengthRegularAlpha || length === lengthShorthandAlpha;
};

/**
 * Get rgba components of the passed hex color code
 *
 * @param hex The raw hex code
 * @returns   Object with keys red, green, blue, alpha
 */
const getComponents = (hex: string): ColorRGBA => {
  const isShort = getIsShort(hex);
  const hasAlpha = getHasAlpha(hex);

  const radix = 16;
  const components = [];
  for (let i = 1; i <= (hasAlpha ? 4 : 3); i++) {
    // If short variant, duplicate each letter
    const componentRaw = isShort ? hex[i] + hex[i] : hex.substr(2 * i - 1, 2);
    components.push(parseInt(componentRaw, radix));
  }

  const [red, green, blue, alphaRaw = 255] = components;
  const alpha = parseFloat((alphaRaw / 255).toFixed(2));

  // Object keys sorted in rgb manner, we don't need the linter to correct this
  // eslint-disable-next-line sort-keys
  return { red, green, blue, alpha };
};

/**
 * Get a color's rgba values from its hex representation
 *
 * @param hex The hex code to convert
 * @returns   The converted value, if successful
 */
const colorHexToRgba = (hex: string): ColorRGBA | null => {
  if (allowedLengths.includes(hex.length) === false) {
    reportError(`colorHexToRgba: Invalid hex code length: ${hex.length}.`);
    return null;
  }

  if (!hex.startsWith('#')) {
    reportError(
      `colorHexToRgba: Hex code for colors didn't start with #: ${hex}.`,
    );
    return null;
  }

  if (hex.match(/^#[0-9a-f]*$/i) === null) {
    reportError(
      `colorHexToRgba: Hex code can only contain digits and letters a-f: ${hex}.`,
    );
    return null;
  }

  return getComponents(hex);
};

export default colorHexToRgba;
