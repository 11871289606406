import React, {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import getPlaceholder from '../../../utils/placeholder';

import * as Styled from './styled';

export type Props = {
  ariaLabel?: string;
  className?: HTMLInputElement['className'];
  enterKeyHint?: 'enter' | 'done' | 'go' | 'previous' | 'search' | 'send';
  formId?: string;
  id?: HTMLInputElement['id'];
  isDisabled?: HTMLInputElement['disabled'];
  isRequired?: HTMLInputElement['required'];
  max?: HTMLInputElement['max'];
  maxLength?: HTMLInputElement['maxLength'];
  min?: HTMLInputElement['min'];
  minLength?: HTMLInputElement['minLength'];
  name?: HTMLInputElement['name'];
  onBlur?: () => void;
  onChange: (value: HTMLInputElement['value']) => void;
  onFocus?: () => void;
  pattern?: string;
  placeholder?: HTMLInputElement['placeholder'];
  shouldFocus?: HTMLInputElement['autofocus'];
  type?: HTMLInputElement['type'];
  value: number | string | null;
};

/**
 * Just a wrapper around the native input component,
 * styled according to the design
 *
 * @param props              Props passed to the component
 * @param props.ariaLabel    aria-label attribute, for when there's no associated <label>
 * @param props.shouldFocus  Should the input be auto focused
 * @param props.className    styled-components generated class name, needed for styling
 * @param props.enterKeyHint Action shown on virtual keyboard
 * @param props.formId       The formId, optional argument
 * @param props.id           The ID of the <input /> element
 * @param props.isDisabled   If the element should be disabled
 * @param props.isRequired   Whether the input is required to be filled
 * @param props.max          Maximum number (type=number)
 * @param props.maxLength    Maximum text length
 * @param props.min          Minimum number (type=number)
 * @param props.minLength    Minimum text length
 * @param props.name         name attribute for the element
 * @param props.onBlur       Callback to be invoked every time input is blurred
 * @param props.onChange     Callback to be invoked every time input changes
 * @param props.onFocus      Callback to be invoked whenever the input is focused
 * @param props.pattern      Pattern for the input
 * @param props.placeholder  Placeholder text
 * @param props.type         Input type attribute
 * @param props.value        Current input value
 * @param ref                Forward ref for the input
 * @returns                  The component itself
 */
const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    shouldFocus = false,
    ariaLabel,
    className,
    enterKeyHint,
    formId,
    id,
    isDisabled = false,
    isRequired = false,
    onChange,
    onBlur,
    onFocus,
    max,
    maxLength,
    min,
    minLength,
    name,
    pattern,
    placeholder = '',
    type = 'text',
    value,
  },
  ref,
) => {
  /**
   * If field is required and placeholder is provided,
   * suffix * will be added
   */
  const computedPlaceholder = getPlaceholder(isRequired, placeholder);

  /**
   * The user has changed the input value, so we inform the parent about it
   *
   * @param event The event that took place
   */
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event.currentTarget.value);
  };

  return (
    <Styled.Input
      aria-label={ariaLabel}
      autoFocus={shouldFocus}
      className={className}
      disabled={isDisabled}
      enterKeyHint={enterKeyHint}
      form={formId}
      id={id}
      max={max}
      maxLength={maxLength}
      min={min}
      minLength={minLength}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      pattern={pattern}
      placeholder={computedPlaceholder}
      ref={ref}
      required={isRequired}
      type={type}
      value={value ?? ''}
    />
  );
};

export default forwardRef(Input);
