/**
 * @file Make a deep copy of attachment local state data
 */
import {
  DraftAttachmentDataFormatted,
  DraftAttachmentState,
} from '../models/draftAttachment';

/**
 * Make a deep copy of drafts attachment state data
 *
 * @param stateOriginal The state we want to clone
 * @returns             The cloned state
 */
const attachmentsDraftClone = (
  stateOriginal: DraftAttachmentState,
): DraftAttachmentState => {
  const stateCloned = new Map<string, DraftAttachmentDataFormatted>();
  stateOriginal.forEach((draft, parentId) => {
    stateCloned.set(parentId, {
      ...draft,
      attachments: new Map(draft.attachments),
    });
  });

  return stateCloned;
};

export default attachmentsDraftClone;
