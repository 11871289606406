import React, { FC } from 'react';

import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';

import ComposeFragment from './ComposeFragment';
import DetailsEditFragment from './DetailsEditFragment';
import FeedFragment from './FeedFragment';

import { getFileIcon, getIsUploading } from './helpers';

import * as Styled from './styled';

export type Props = {
  attachment: Attachment | AttachmentDraft;
  canDeleteFile: boolean;
  mode: AttachmentMode;
  requestFileRemove: () => void;
};

const iconHeight = 30;
const iconWidth = 20;

/**
 * Component that renders single attachment for draft message
 *
 * @param props                   Props passed to the component
 * @param props.attachment        File that is attached
 * @param props.canDeleteFile     Whether user has permission to delete attachment
 * @param props.mode              In which mode to render the component
 * @param props.requestFileRemove Callback for removing file from the drafts array or message
 * @returns                       The component itself
 */
const AttachmentSingleDraft: FC<Props> = ({
  attachment,
  canDeleteFile,
  mode,
  requestFileRemove,
}) => {
  const { extension, name } = attachment;

  const iconName = getFileIcon(extension);
  const isUploading = getIsUploading(attachment);
  const canDownloadFile = false;

  return (
    <Styled.Wrapper data-mode={mode}>
      <Styled.Icon height={iconHeight} name={iconName} width={iconWidth} />
      <Styled.Title>{name}</Styled.Title>
      <DetailsEditFragment
        canDeleteFile={canDeleteFile}
        canDownloadFile={canDownloadFile}
        isUploading={isUploading}
        mode={mode}
        requestButtonClick={requestFileRemove}
      />
      <Styled.ActionWrapper>
        <ComposeFragment
          isUploading={isUploading}
          mode={mode}
          requestButtonClick={requestFileRemove}
        />
      </Styled.ActionWrapper>
      {mode !== 'feed' && (
        <FeedFragment canDownloadFile={canDownloadFile} mode={mode} />
      )}
    </Styled.Wrapper>
  );
};

export default AttachmentSingleDraft;
