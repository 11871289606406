import React, { FC } from 'react';

import { cardIllustrationTypes } from '../../../../models/dashboard';

import DashboardCard from '../../../Dashboard/Card';

import { CardsWrapper } from '../../../Dashboard/ContentMobile/styled';
import KpiSingle from '../KpiSingle';
import { CardContentWrapper } from '../styled';

type Props = {
  kpiNumber?: number;
};

/**
 * Loading content for dashbord mobile
 *
 * @param props           Props passed to the component
 * @param props.kpiNumber Number of skeletons to be shown for each kpi
 * @returns               The component itself
 */
const DashboardSkeletonMobile: FC<Props> = ({ kpiNumber = 3 }) => {
  const sample = Array.from({ length: kpiNumber });
  const dashboardKpis = sample.map((_element, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <DashboardCard type={cardIllustrationTypes[index]} url="#">
          <CardContentWrapper>
            <KpiSingle direction="column" />
          </CardContentWrapper>
        </DashboardCard>
      </li>
    );
  });

  return <CardsWrapper aria-busy="true">{dashboardKpis}</CardsWrapper>;
};

export default DashboardSkeletonMobile;
