import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

export const Wrapper = styled.h3`
  color: var(--color-icons-inactive);
  display: inline-flex;
  flex-direction: column;
  font-size: var(--font-baseline);

  @media ${devices.tabletS} {
    flex-direction: row;
  }
`;

export const Time = styled.time`
  font-size: var(--font-baseline);

  @media ${devices.tabletS} {
    margin-left: var(--spacing-tiny);
  }
`;
