import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

import CommonIcon from '../Icon';

export const Input = styled.input`
  ${visuallyHidden}
`;

export const Icon = styled(CommonIcon)`
  flex-shrink: 0;
  margin-right: var(--spacing-small);

  ${Input}:focus-visible ~ & {
    background-color: var(--color-background-selected);
  }
`;

export const LabelText = styled.span`
  font-weight: var(--font-weight-label-regular);

  ${Input}:checked ~ & {
    font-weight: var(--font-weight-label-bold);
  }
`;

export const Label = styled.label`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;
