import { styled } from 'styled-components';

// Wrapper around the entire component
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
`;

// Wrapper around "Checklist x/y" text
export const Heading = styled.h6`
  font-size: var(--font-baseline);
  font-weight: var(--font-weight-heading);
`;

// "x/y" text
export const Status = styled.b`
  margin: 0 var(--spacing-small);
`;
