import React, { FC } from 'react';

import { MessageCreatorType, UserBasicFragment } from '../../generated/graphql';

import translate from '../../i18n/translate';

import MessageAuthor from './MessageAuthor';

import MessageSection from './MessageSection';

type Props = {
  author?: UserBasicFragment | null;
  createdAtRaw: string;
  creatorType: MessageCreatorType;
};

/**
 * Section in the message that displays the message author
 *
 * @param props              Props passed to the component
 * @param props.author       Author of the message
 * @param props.createdAtRaw Raw createdAt value
 * @param props.creatorType  Type of the message creator
 * @returns                  The component itself
 */
const MessageSectionAuthor: FC<Props> = ({
  author,
  createdAtRaw,
  creatorType,
}) => {
  return (
    <MessageSection heading={translate('MESSAGE__HEADING__AUTHOR')}>
      <MessageAuthor
        author={author}
        createdAtRaw={createdAtRaw}
        creatorType={creatorType}
      />
    </MessageSection>
  );
};

export default MessageSectionAuthor;
