/**
 * @file Hook for scrolling into element from hash tag in referenced DOM element
 */

import { useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

/**
 * When the data loads, scroll into the element referenced by the url hash
 *
 * @param hasLoaded Whether data was loaded (nothing to scroll into before that)
 */
const useDetailsScroll = (hasLoaded: boolean) => {
  const { hash } = useLocation();
  const theme = useTheme();

  /**
   * Debounced scroll into view to avoid scrolling before details are on screen
   */
  const scrollIntoView = useDebouncedCallback(() => {
    const element = document.querySelector(hash);
    if (element?.scrollIntoView === undefined) {
      return;
    }

    element.scrollIntoView({
      behavior: 'smooth',
    });
    // Same value used in css translations as --preference-transition-duration-normal
  }, theme.transitions.duration.standard);

  useLayoutEffect(() => {
    if (hash === '' || hasLoaded === false) {
      return;
    }

    scrollIntoView();
  }, [hash, hasLoaded, scrollIntoView]);
};

export default useDetailsScroll;
