/**
 * @file The user has successfully subscribed, so we update the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MutationPushNotificationSubscribeArgs,
  PushInfoDocument,
  PushInfoQuery,
  PushInfoQueryVariables,
  PushSubscribeMutation,
} from '../../../../../generated/graphql';

/**
 * The user has successfully subscribed,
 * so we update the cache
 *
 * @param result The response from the server
 * @param args   The arguments passed to the nutation
 * @param cache  urql cache instance
 */
const pushNotificationSubscribe: UpdateResolver<
  PushSubscribeMutation,
  MutationPushNotificationSubscribeArgs
> = (result, args, cache) => {
  cache.updateQuery<PushInfoQuery, PushInfoQueryVariables>(
    {
      query: PushInfoDocument,

      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      data.pushSubscriptions.push({
        __typename: 'PushNotification',
        device: {
          __typename: 'Device',
          ...args.device,
        },
        id: result.pushNotificationSubscribe.id,
        token: args.token,
      });

      return data;
    },
  );
};

export default pushNotificationSubscribe;
