/**
 * @file contains permission checks for message attachments
 */
import {
  MessageCommon,
  MessageCreatorAndAssigned,
  TypeMessageFull,
} from '../../models/message';

import {
  getIsAdmin,
  getIsAssigned,
  getIsAuthor,
  hasPermission,
} from './messageHelper';
import { MESSAGE__CREATE, MESSAGE__UPDATE } from './permissionsAll';
import { getIsBasic } from './storage';
import { getIsMember } from './topic';

/**
 * Check whether the user is allowed to upload an attachment to an existing message
 *
 * @param message The message to check permissions for
 * @returns       Whether the user has permissions
 */
export const getCanUploadAttachmentExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (hasPermission(MESSAGE__UPDATE) === false) {
    return false;
  }

  return getIsAdmin() || getIsAuthor(message) || getIsAssigned(message);
};

/**
 * Check whether the user is allowed to upload an attachment when creating new message
 *
 * @returns Whether the user has permissions
 */
export const getCanUploadAttachmentNewMessage = (): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  return getIsAdmin() || hasPermission(MESSAGE__CREATE);
};

/**
 * Check whether the user is allowed to download an attachment
 *
 * @param message The message to check permissions for
 * @returns       Whether the user has permissions
 */
export const getCanDownloadAttachment = (
  message: MessageCommon | TypeMessageFull,
): boolean => {
  const { isArchived, topic } = message;

  if (isArchived) {
    return false;
  }

  return topic.isPrivate === false || getIsMember(topic);
};

/**
 * Check whether the user is allowed to delete an attachment
 *
 * @param message The message to check permissions for
 * @returns       Whether the user has permissions
 */
export const getCanDeleteAttachment = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(MESSAGE__UPDATE) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};
