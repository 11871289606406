import React, { FC } from 'react';

import Chevron from '../../../../components/Common/Chevron';

import * as Styled from './styled';

export type Props = {
  handleToggle: () => void;
  isActive: boolean;
  text: string;
};

const ICON_SIZE = 12;

/**
 * Heading for the CollapsibleTopicsList
 *
 * @param props              Props passed to the component
 * @param props.handleToggle A callback that sets the collapsible to active
 * @param props.isActive     Boolean that tells us if the collapsible is active
 * @param props.text         Text content of the component
 * @returns                  The component itself
 */
const Heading: FC<Props> = ({ handleToggle, isActive, text }) => {
  return (
    <Styled.Button aria-pressed={isActive} onClick={handleToggle} type="button">
      <Chevron
        color="var(--color-text-regular)"
        direction={isActive ? 'down' : 'right'}
        size={ICON_SIZE}
      />
      <Styled.Title>{text}</Styled.Title>
    </Styled.Button>
  );
};

export default Heading;
