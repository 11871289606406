import React, { FC } from 'react';

import useIsMobile from '../../../../../hooks/useIsMobile';
import Chevron from '../../../Chevron';

import Icon, { NAMES } from '../../../Icon';

const ICON_SIZE_DESKTOP = 12;
const ICON_SIZE_MOBILE = 15;
const iconNameMobile = NAMES.GENERAL__REPLY;

type Props = {
  isExpanded: boolean;
  isUnread: boolean;
};

/**
 * Icon component for message reply
 *
 * @param props            Props passed to the component
 * @param props.isExpanded Whether the message reply is expanded
 * @param props.isUnread   Whether the message reply is expanded
 * @returns                The message reply icon component
 */
export const MessageReplyIcon: FC<Props> = ({ isExpanded, isUnread }) => {
  const isMobile = useIsMobile();
  const iconColor = isUnread
    ? 'var(--color-icons-danger)'
    : 'var(--color-link)';

  if (isMobile) {
    return (
      <Icon
        color={iconColor}
        height={ICON_SIZE_MOBILE}
        name={iconNameMobile}
        width={ICON_SIZE_MOBILE}
      />
    );
  }

  return (
    <Chevron
      color={iconColor}
      direction={isExpanded ? 'down' : 'right'}
      size={ICON_SIZE_DESKTOP}
    />
  );
};
