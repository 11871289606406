/**
 * @file contains redux actions for attachment state
 */
import {
  DRAFTS__ATTACHMENT__ADD,
  DRAFTS__ATTACHMENT__CLEAR,
  DRAFTS__ATTACHMENT__INIT,
  DRAFTS__ATTACHMENT__REMOVE,
} from '../../constants/actionTypes';
import { File as CustomFile } from '../../generated/graphql';
import { AttachmentDraft } from '../../models/attachment';
import {
  DraftActionAttachmentAdd,
  DraftActionAttachmentClear,
  DraftActionAttachmentInit,
  DraftActionAttachmentRemove,
} from '../../models/draftAttachment';

/**
 * Request that a draft is initialized for a specified parent
 *
 * @param parentId The parent to init a draft for attachments state (0 for root)
 * @returns        The action itself
 */
export const initDraftAttachmentsRequest = (
  parentId: string,
): DraftActionAttachmentInit => ({
  parentId,
  type: DRAFTS__ATTACHMENT__INIT,
});

/**
 *
 * CREATE actions
 *
 */

/**
 * Action to add a new attachment in a draft attachments
 *
 * @param parentId   The ID of the message the target draft is a reply to
 * @param attachment Attachment that should be added
 * @returns          The action itself
 */
export const addDraftAttachmentRequest = (
  parentId: string,
  attachment: AttachmentDraft,
): DraftActionAttachmentAdd => ({
  attachment,
  parentId,
  type: DRAFTS__ATTACHMENT__ADD,
});

/**
 *
 * DELETE actions
 *
 */

/**
 * Remove an attachment
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param key      Key of the current attachment
 * @returns        The action itself
 */
export const removeDraftAttachmentRequest = (
  parentId: string,
  key: CustomFile['key'],
): DraftActionAttachmentRemove => ({
  key,
  parentId,
  type: DRAFTS__ATTACHMENT__REMOVE,
});

/**
 * Request that a attachment draft is cleared
 *
 * @param parentId The draft to clear
 * @returns        The action itself
 */
export const clearDraftAttachmentRequest = (
  parentId: string,
): DraftActionAttachmentClear => ({
  parentId,
  type: DRAFTS__ATTACHMENT__CLEAR,
});
