/**
 * @file All permissions checks related to dashboard
 */

import { PermissionsDashboard } from '../../models/permissions';

import { DASHBOARD__VIEW } from './permissionsAll';
import { getPermissionsCurrent } from './storage';

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsDashboard): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the user is allowed to see dashboard page
 *
 * @returns Whether it's true
 */
export const getCanViewDashboard = (): boolean => {
  return hasPermission(DASHBOARD__VIEW);
};

/**
 * Check whether the user is allowed to edit the dashboard blocks
 *
 * @returns Whether the user is allowed to perform the action
 */
export const getCanEditDashboard = (): boolean => true;
