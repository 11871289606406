import React, { FC } from 'react';

import { reportWarning } from '../../../services/reporting';

import {
  dateTimeFormatInput,
  dateTimeFormatUi,
} from '../../../utils/date/format';

export type Props = {
  className?: string;
  date: string;
  label: string;
};

/**
 * Commonly used datetime display
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.date      The raw date string to use
 * @param props.label     Field label, for accessibility
 * @returns               The component itself
 */
const DateTime: FC<Props> = ({ className, date, label }) => {
  const dateObj = new Date(date);

  if (Number.isNaN(dateObj.getTime())) {
    reportWarning(`DateTime: Invalid time passed: ${date}.`);
    return null;
  }

  return (
    <time
      aria-label={label}
      className={className}
      dateTime={dateTimeFormatInput(dateObj)}
      role="time"
    >
      {dateTimeFormatUi(dateObj)}
    </time>
  );
};

export default DateTime;
