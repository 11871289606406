/**
 * @file Helper functions for message due date
 */

import { MessageDateQuery, Reminder } from '../../../generated/graphql';

/**
 * Extract message due date from retrieved data
 *
 * @param data The data retrieved from the server
 * @returns    The message due date (or null if not set)
 */
export const extractMessageDateDue = (data: MessageDateQuery): Date | null => {
  const dueDate = data.message?.dueDate ?? null;
  return dueDate === null ? dueDate : new Date(dueDate);
};

/**
 * Extract message reminder from retrieved data
 *
 * @param data The data retrieved from the server
 * @returns    The message reminder
 */
export const extractMessageReminders = (data: MessageDateQuery): Reminder[] => {
  return data.message?.reminders ?? [];
};
