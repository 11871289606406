/**
 * @file contains helper functions for Compose
 */

/**
 * Whether to just show the compose toggle
 * (do it when the form is not visible, on large screens)
 *
 * @param isMobile   Callback to request that a message is created
 * @param shouldShow Whether to even show the compose form
 * @returns          Boolean
 */
export const getShouldShowToggle = (isMobile: boolean, shouldShow: boolean) => {
  return shouldShow === false && isMobile === false;
};
