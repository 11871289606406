/**
 * @file Get whether the user account is in pending activation status
 */

import { UserStatus } from '../../generated/graphql';

/**
 * Get whether the user account is in pending activation status
 *
 * @param status The user's status
 * @returns      Whether it is in pending activation status
 */
const userGetPending = (status?: UserStatus): boolean => {
  return status === UserStatus.ActivationPending;
};

export default userGetPending;
