import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const HeadingWrapperButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--spacing-small);
  padding: var(--spacing-tiny) var(--spacing-medium);
  width: 100%;

  &:hover {
    background-color: var(--color-hover-light);
  }
`;

export const HeadingWrapperLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--spacing-small);
  padding: var(--spacing-tiny) var(--spacing-medium);

  &:hover {
    background-color: var(--color-hover-light);
  }
`;
