import React, { FC } from 'react';

import { ILLUSTRATION__ATTACHMENT } from '../../../../constants/illustrations';
import translate from '../../../../i18n/translate';
import EmptyState from '../../../Common/EmptyState';

import * as Styled from './styled';

/**
 * Component that renders empty state for attachment list
 *
 * @returns AttachmentsList empty state component
 */
const AttachmentListEmpty: FC = () => {
  const title = translate('ATTACHMENT__EMPTY__TITLE');
  const subtitle = translate('COMPOSE__ATTACH__FILE');

  return (
    <Styled.Wrapper>
      <EmptyState
        illustrationName={ILLUSTRATION__ATTACHMENT}
        subtitle={subtitle}
        title={title}
      />
    </Styled.Wrapper>
  );
};

export default AttachmentListEmpty;
