import { styled } from 'styled-components';

export const CardsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: var(--font-baseline);
  font-weight: var(--font-semibold);
  row-gap: var(--spacing-medium);
`;

export const PageTitle = styled.h2`
  font-size: inherit;
  font-weight: var(--font-semibold);
  margin-bottom: var(--spacing-small);
`;

export const PageDescription = styled.p`
  font-size: var(--font-baseline);
  margin-bottom: var(--spacing-medium);
`;
