import React, { FC, useId } from 'react';

import Modal from '../../../../components/Common/Modal';

import useConfig from './useConfig';

export type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

/**
 * Modal to show after the user has performed some action
 * (eg. send their 1st message)
 * to ask them to subscribe to push notifications
 *
 * @param props           Props passed to the component
 * @param props.isOpen    Whether to show the modal
 * @param props.onCancel  The user has clicked "Cancel" button
 * @param props.onConfirm The user has clicked "Confirm" button
 * @returns               The component itself
 */
const ModalPushSubscribe: FC<Props> = ({ isOpen, onCancel, onConfirm }) => {
  const idHeading = useId();
  const idSubheading = useId();

  const { buttons, textHeading, textSubheading } = useConfig(
    onConfirm,
    onCancel,
  );

  return (
    <Modal
      buttons={buttons}
      height="auto"
      idHeading={idHeading}
      idSubheading={idSubheading}
      image="PUSH_ALLOW"
      isOpen={isOpen}
      requestClose={onCancel}
      textHeading={textHeading}
      textSubheading={textSubheading}
    />
  );
};

export default ModalPushSubscribe;
