/**
 * @file Matomo tracking code
 */

import { MATOMO_ENABLED, MATOMO_SITE_ID } from '../../config';
import { Hotel, MeQuery } from '../../generated/graphql';
import { checkIsFirstPageView } from '../../utils/storage/firstLogin';

import {
  CustomDimensions,
  getCustomDimensions,
  getHotelName,
  getHref,
} from './customDimensions';

/**
 * Matomo track types that are used in hotelboard
 */
export const TRACK_TYPES = {
  TRACK_EVENT: 'trackEvent',
  TRACK_SITE_SEARCH: 'trackSiteSearch',
  TRACK_VIEW: 'trackPageView',
};

const MATOMO_SCRIPT_SRC_URL = 'https://data.gastfreund.net/matomo.js';
const MATOMO_TRACKER_URL = 'https://data.gastfreund.net/matomo.php';

/**
 * Pushes an instruction to Matomo for execution.
 *
 * @param args The instruction name and other arguments
 */
const pushInstruction = (...args: [string, ...unknown[]]): void => {
  window._paq.push(args);
};

/**
 * Tracks an event or page view
 *
 * @param args             Main tracking instruction arguments
 * @param customDimensions Custom dimensions to be tracked
 */
export const track = (
  args: [string, ...unknown[]],
  customDimensions: CustomDimensions,
): void => {
  if (!MATOMO_ENABLED) {
    return;
  }

  if (window._paq === undefined) {
    reportError('Matomo not initialized');
    return;
  }

  Object.entries(customDimensions).forEach(([id, value]) =>
    pushInstruction('setCustomDimension', id, value),
  );

  // In hotelboard, document title is always the same. So we don't need
  // to pass it down, because there is no risk of it changing.
  pushInstruction('setDocumentTitle', getHotelName(customDimensions));
  pushInstruction('setCustomUrl', getHref(customDimensions));
  pushInstruction(...args);
};

/**
 * Tracks page views
 * https://developer.matomo.org/guides/spa-tracking#tracking-a-new-page-view
 *
 * @param customDimensions Custom dimensions to be tracked
 */
export const trackPageView = (customDimensions: CustomDimensions): void => {
  track([TRACK_TYPES.TRACK_VIEW], customDimensions);
};

/**
 * Track site search
 *
 * @param searchTerm       The term that the user is searching
 * @param numberOfResults  The number of search results returned
 * @param customDimensions Custom dimensions to be tracked
 */
export const trackSiteSearch = (
  searchTerm: string,
  numberOfResults: number,
  customDimensions: CustomDimensions,
): void => {
  // Using dot (.) for category because matomo doesn't accept an empty value.
  // It does accept zero (0) but we decided to have a dot, because then,
  // we get a smiley in matomo (search term - . - num results)
  track(
    [TRACK_TYPES.TRACK_SITE_SEARCH, searchTerm, '.', numberOfResults],
    customDimensions,
  );
};

/**
 * Loads the Matomo script into the DOM.
 */
const loadMatomoScript = (): void => {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = MATOMO_SCRIPT_SRC_URL;

  document.body.appendChild(script);
};

/**
 * Track first login event
 *
 * @param user    The current user's info
 * @param hotelId The ID of the hotel the board is for
 */
const trackFirstLoginEvent = (
  user: MeQuery['me'],
  hotelId: Hotel['id'],
): void => {
  const customDimensions = getCustomDimensions(
    window.location.href,
    user,
    hotelId,
    { entityId: user.id, entityType: 'user' },
  );

  track(
    [TRACK_TYPES.TRACK_EVENT, 'User', '1st time log in', 'User login 1st time'],
    customDimensions,
  );
};

/**
 * Initialize matomo global variable for a specific user, and load the matomo script.
 *
 * @param user    The current user's info
 * @param hotelId The ID of the hotel the board is for
 */
export const initMatomo = (user: MeQuery['me'], hotelId: Hotel['id']): void => {
  if (!MATOMO_ENABLED) {
    return;
  }

  // We must keep the original window._paq if it is already there.
  window._paq = window._paq ?? [];

  // If window._paq.length is undefined, it means that the script has
  // already been loaded, and we don't want to initialize matomo again.
  // If window._paq.length is greater than zero, it means that initial
  // instructions have already been pushed.
  if (window._paq.length !== 0) {
    return;
  }

  // We don't need cookies since we always have the user id
  pushInstruction('disableCookies', true);
  pushInstruction('enableHeartBeatTimer', 15);
  pushInstruction('setRequestMethod', 'POST');
  pushInstruction('setSiteId', MATOMO_SITE_ID);
  pushInstruction('setTrackerUrl', MATOMO_TRACKER_URL);
  pushInstruction('setUserId', user.id);

  if (checkIsFirstPageView()) {
    trackFirstLoginEvent(user, hotelId);
  }

  loadMatomoScript();
};
