/**
 * @file Helper functions for webview info
 */

import deviceInfo, {
  isWebViewAndroid,
  isWebViewIos,
} from '../device/deviceInfo';

type AppType = 'NATIVE' | 'PWA-BROWSER' | 'PWA-STANDALONE';

/**
 * Check if app is running in WebView
 *
 * @returns Is app running in WebView
 */
export const getIsReactNativeWebView = (): boolean =>
  window.ReactNativeWebView !== undefined;

/**
 * Check if app is running in WebView on iPad
 * Since iPad OS is forcing desktop view UA string is the same as on MacOS
 *
 * @returns Whether the app is running in iPad WebView
 */
const getIsReactNativeWebviewIpad = (): boolean =>
  getIsReactNativeWebView() && deviceInfo.macOS;

/**
 * Check if app is running in WebView on Android
 *
 * @returns Is app running in Android WebView
 */
export const getIsReactNativeAndroid = (): boolean =>
  getIsReactNativeWebView() && isWebViewAndroid;

/**
 * Check if app is running in WebView on iOS
 *
 * @returns Is app running in iOS WebView
 */
export const getIsReactNativeIos = (): boolean =>
  (getIsReactNativeWebView() && isWebViewIos) || getIsReactNativeWebviewIpad();

/**
 * Get the environment that the app is running in.
 *
 * @returns App type
 */
export const getAppType = (): AppType => {
  if (getIsReactNativeWebView()) {
    return 'NATIVE';
  }

  if (
    // Check if running in standalone mode on iOS
    window.navigator.standalone === true ||
    // Check if running in standalone mode on other browsers
    (window.matchMedia &&
      window.matchMedia('(display-mode: standalone)').matches === true)
  ) {
    return 'PWA-STANDALONE';
  }

  return 'PWA-BROWSER';
};

/**
 * Check if app is running in PWA iOS/iPadOS
 *
 * @returns Is app running in iOS/iPadOS PWA
 */
export const getIsPwaIos = (): boolean => {
  return (
    (deviceInfo.iPad || deviceInfo.iPhone) && getIsReactNativeIos() === false
  );
};
