/**
 * @file Hook that tells us if compose is open
 */
import { SEARCH_PARAM__COMPOSE } from '../../../constants/routing/searchParams';

import useSearchParams from './useSearchParams';

/**
 * Hook that tells us if compose is open
 * based on url
 *
 * @returns Whether the compose is open
 */
const useIsComposeOpen = (): boolean => {
  const query = useSearchParams();

  return query.get(SEARCH_PARAM__COMPOSE) === 'open';
};

export default useIsComposeOpen;
