import React, { FC, FormEventHandler } from 'react';

import { ComposeNodeType } from '../../../containers/compose/props';

import {
  User,
  UserBasicFragment,
  UserGroup,
  UserGroupBasicFragment,
} from '../../../generated/graphql';
import Assignment from '../../Assignment';

type Props = {
  dataGroupsAll: UserGroupBasicFragment[];
  dataGroupsAssigned: Set<UserGroup['id']>;
  dataMembersAll: UserBasicFragment[];
  dataMembersAssigned: Set<User['id']>;
  dataSearchValue: string;
  idForm: string;
  isLoading: boolean;
  nodeType: ComposeNodeType;
  onAssignUpdateGroup: (groupId: UserGroup['id'], state: boolean) => void;
  onAssignUpdateMember: (memberId: User['id'], state: boolean) => void;
  onSearch: (term: string) => void;
  requestAssignSave: () => void;
};

/**
 * Form to (un)assign people to/from a message
 *
 * @param props                      Props passed to the component
 * @param props.dataGroupsAll        All topic groups
 * @param props.dataGroupsAssigned   Groups checked
 * @param props.dataMembersAll       All topic members
 * @param props.dataMembersAssigned  Members checked
 * @param props.dataSearchValue      <input type="search" /> value attribute
 * @param props.idForm               The ID to use for the assignment <form /> element
 * @param props.isLoading            Whether the data is still being loaded
 * @param props.nodeType             Whether the compose form is being used for messages or templates
 * @param props.onAssignUpdateGroup  Callback to invoke when a group is (un)checked
 * @param props.onAssignUpdateMember Callback to invoke when a member is (un)checked
 * @param props.onSearch             Callback to invoke when search term is changed
 * @param props.requestAssignSave    Request that the current assignment is saved
 * @returns                          The component itself
 */
const MessageAssigneesForm: FC<Props> = ({
  dataGroupsAll,
  dataGroupsAssigned,
  dataMembersAll,
  dataMembersAssigned,
  dataSearchValue,
  idForm,
  isLoading,
  nodeType,
  onAssignUpdateGroup,
  onAssignUpdateMember,
  onSearch,
  requestAssignSave,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the assignment is saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestAssignSave();
  };

  return (
    <form id={idForm} onSubmit={onSubmit}>
      <Assignment
        dataGroupsAll={dataGroupsAll}
        dataGroupsAssigned={dataGroupsAssigned}
        dataMembersAll={dataMembersAll}
        dataMembersAssigned={dataMembersAssigned}
        dataSearchValue={dataSearchValue}
        isLoading={isLoading}
        nodeType={nodeType}
        onAssignUpdateGroup={onAssignUpdateGroup}
        onAssignUpdateMember={onAssignUpdateMember}
        onSearch={onSearch}
      />
    </form>
  );
};

export default MessageAssigneesForm;
