/**
 * @file A template was updated, so we update it in the cache,
 */
import { Cache } from '@urql/exchange-graphcache';

import {
  TemplateBasicFragment,
  TemplateFullFragment,
  TemplatesDocument,
  TemplatesInTopicDocument,
  TemplatesInTopicQuery,
  TemplatesInTopicQueryVariables,
  TemplatesQuery,
  TemplatesQueryVariables,
} from '../../../../../../../generated/graphql';

/**
 * Merge original with updates and return
 *
 * @param original The old data
 * @param updates  The updates that occurred
 * @returns        Merged template data
 */
export const getUpdatedTemplate = <T extends TemplateBasicFragment>(
  original: T,
  updates: TemplateBasicFragment | TemplateFullFragment,
): T => ({
  ...original,
  title: updates.title ?? original.title,
});

/**
 * Sort templates by topic and title
 *
 * @param templateA First template
 * @param templateB Second template
 * @returns         Sort result
 */
export const sortTemplatesByTopicAndTitle = (
  templateA: TemplateBasicFragment | TemplateFullFragment,
  templateB: TemplateBasicFragment | TemplateFullFragment,
) =>
  templateA.topic.title
    .concat(templateA.title)
    .localeCompare(templateB.topic.title.concat(templateB.title));

/**
 * A template was updated, so we update it in the cache,
 * and also re-sort templates if the title changed
 *
 * @param cache   urql cache object
 * @param updates The updates that occurred
 */
const templatesUpdate = (
  cache: Cache,
  updates: TemplateBasicFragment,
): void => {
  cache.updateQuery<TemplatesInTopicQuery, TemplatesInTopicQueryVariables>(
    {
      query: TemplatesInTopicDocument,
      variables: { topicId: updates.topic.id },
    },
    data => {
      if (data === null) {
        return null;
      }

      const templates = data.templatesInTopic;
      const index = templates.findIndex(template => template.id === updates.id);

      if (index === -1) {
        // Created template added to existing ones
        templates.push(updates);
      } else {
        const template = templates[index];
        templates[index] = getUpdatedTemplate(template, updates);
      }

      templates.sort(sortTemplatesByTopicAndTitle);

      data.templatesInTopic = templates;
      return data;
    },
  );

  cache.updateQuery<TemplatesQuery, TemplatesQueryVariables>(
    { query: TemplatesDocument },
    data => {
      if (
        /** Optional chaining doesn't work here, we need to handle both data and data.labels === null */
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        data === null ||
        data.templatesEditable === null ||
        updates === undefined
      ) {
        return null;
      }

      const templates = data.templatesEditable;
      const index = templates.findIndex(template => template.id === updates.id);

      if (index === -1) {
        // Created template added to existing ones
        templates.push(updates);
      } else {
        const template = templates[index];
        templates[index] = getUpdatedTemplate(template, updates);
      }

      templates.sort(sortTemplatesByTopicAndTitle);

      data.templatesEditable = templates;
      return data;
    },
  );
};

export default templatesUpdate;
