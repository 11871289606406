/**
 * @file Hook that gets currently active conversation id from url
 */

import { useParams } from 'react-router-dom';

import { Maybe, Scalars } from '../../../generated/graphql';
import { ChatParams } from '../../../models/pageParams';

/**
 * Hook that gets currently active conversation id from url
 *
 * @returns Current active hotelId
 */
const useParamConversationId = (): Maybe<Scalars['ID']['output']> => {
  const { conversationId } = useParams<ChatParams>();

  return conversationId ?? null;
};

export default useParamConversationId;
