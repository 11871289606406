/**
 * @file contains graphql client
 */
import { devtoolsExchange } from '@urql/devtools';

import { Client, createClient, Exchange, fetchExchange } from 'urql';

import { GRAPHQL_URL } from '../config';
import { getShouldUseUrqlDevTools } from '../utils/environment';

import authExchange from './exchanges/auth';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore The file is generated on build
// CircleCI build would fail with ts-expect-error
// eslint-disable-next-line import/no-unresolved
import cacheExchange from './exchanges/cache';
import errorExchange from './exchanges/error';
import { refocusExchange } from './exchanges/refocusExchange';
import subscriptionExchange from './exchanges/subscription';

const shouldUseUrqlDevTools = getShouldUseUrqlDevTools();

const exchanges: Exchange[] = [
  refocusExchange(),
  // The file is generated on build, so this'll resolve as any prior to that
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  cacheExchange as Exchange,
  errorExchange,
  authExchange,
  fetchExchange,
  subscriptionExchange,
];

if (shouldUseUrqlDevTools === true) {
  exchanges.unshift(devtoolsExchange);
}

const client: Client = createClient({
  exchanges,
  url: GRAPHQL_URL,
});

export default client;
