import React, { FC, MouseEvent } from 'react';

import useFocus from '../../../hooks/useFocus';

import Icon, { NAMES } from '../Icon';
import Spinner from '../Spinner';

import * as Styled from './styled';

export type Props = {
  className?: HTMLButtonElement['className'];
  color?: string;
  colorFocus?: string;
  colorHover?: string;
  iconHeight?: number;
  iconName: string;
  iconWidth?: number;
  isDisabled?: HTMLButtonElement['disabled'];
  isLoading?: boolean;
  label: NonNullable<HTMLButtonElement['ariaLabel']>;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
};

export const defaultColor = 'currentColor';
export const defaultType = 'button';

/**
 * Button which doesn't have any other content apart from the icon within it
 *
 * @param props            props passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.color      color of the icon
 * @param props.colorFocus color of the icon when the button is focused
 * @param props.colorHover color of the icon when the button is hovered
 * @param props.iconHeight the height of the included icon (button itself will be the same size)
 * @param props.iconName   which icon to show within the button
 * @param props.iconWidth  the width of the included icon (button itself will be the same size)
 * @param props.isDisabled Whether to make the button disabled
 * @param props.isLoading  Whether to show loading spinner
 * @param props.label      aria label for the button (since there's no text, just the icon)
 * @param props.onClick    click callback, not needed for submit buttons
 * @param props.type       <button> type attribute
 * @returns                the component itself
 */
const ButtonIcon: FC<Props> = ({
  className,
  color = defaultColor,
  colorFocus = color,
  colorHover = color,
  iconHeight,
  iconName,
  iconWidth,
  isDisabled = false,
  isLoading = false,
  label,
  onClick,
  type = defaultType,
  ...rest
}) => {
  const [isFocused, bind] = useFocus();

  return (
    <Styled.Button
      aria-label={label}
      className={className}
      data-color={color}
      data-color-hover={colorHover}
      isDisabled={isDisabled}
      label={label}
      onClick={onClick}
      type={type}
      {...rest}
      {...bind}
    >
      {isLoading ? (
        <Spinner size={iconWidth} />
      ) : (
        <Icon
          color={isFocused ? colorFocus : color}
          colorHover={colorHover}
          height={iconHeight}
          name={iconName}
          width={iconWidth}
        />
      )}
    </Styled.Button>
  );
};

export { NAMES };
export default ButtonIcon;
