import React, { FC } from 'react';

import { NAMES } from '../Icon';

import * as Styled from './styled';

export type ChevronDirection = 'up' | 'down' | 'left' | 'right';

type Props = {
  className?: string;
  color?: string;
  direction: ChevronDirection;
  size?: number;
};

/**
 * Chevron icon component
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     The color of chevron
 * @param props.direction direction for the chevron
 * @param props.size      height and width for the chevron in px
 * @returns               The component itself
 */
const Chevron: FC<Props> = ({
  className,
  color = 'var(--color-icons-primary)',
  direction,
  size = 30,
}) => {
  return (
    <Styled.ChevronIcon
      className={className}
      color={color}
      data-direction={direction}
      height={size}
      name={NAMES.GENERAL__CHEVRON}
      width={size}
    />
  );
};

export default Chevron;
