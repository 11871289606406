/**
 * @file contains function for getting due date from template offset
 */
import { addDays, addHours, addMinutes } from 'date-fns';

import { DateOffset, TimeUnit } from '../../generated/graphql';

const mapping: Record<TimeUnit, (date: number | Date, amount: number) => Date> =
  {
    DAYS: addDays,
    HOURS: addHours,
    MINUTES: addMinutes,
  };

/**
 * Get due date for new post from template offset
 *
 * @param offset Template offset
 * @returns      Post due date
 */
const getDueDateFromOffset = (offset: DateOffset | null): Date | null => {
  if (offset === null) {
    return null;
  }

  return mapping[offset.unit](new Date(), offset.amount);
};

export default getDueDateFromOffset;
