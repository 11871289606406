/**
 * @file contains hook for notification settings path
 */
import useCurrentPath from '../../../../../hooks/useCurrentPath';

import {
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
} from '../../../../../routes/paths/settings';

/**
 * Check whether current page is Push notifications settings page
 *
 * @returns Whether current page is push notifications settings page
 */
const usePushNotificationsSettingsPage = (): boolean => {
  const currentPath = useCurrentPath();

  return (
    currentPath === createSettingsNotificationsPushPath() ||
    currentPath === createSettingsNotificationsPushSinglePath()
  );
};

export default usePushNotificationsSettingsPage;
