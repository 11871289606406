/**
 * @file contains functions used to create paths for use in Route related to the kanban page
 */
import { ROUTE__KANBAN } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the kanban
 *
 * @returns The generated path
 */
export const createKanbanPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__KANBAN}`;
};

/**
 * Generate the Path for the kanban showing the post
 *
 * @returns The generated path
 */
export const createKanbanMessagePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__KANBAN}/:topicId/post/:messageId`;
};

/**
 * Generate a path for the kanban showing the message details
 *
 * @returns The generated path
 */
export const createKanbanMessageDetailsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__KANBAN}/:topicId/post/:messageId/details`;
};

/**
 * Generate a path for the kanban showing the message details edit mode
 *
 * @returns The generated path
 */
export const createKanbanMessageDetailsEditPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__KANBAN}/:topicId/post/:messageId/details/:messageEditMode`;
};

/**
 * Generate the URL for the kanban showing the topic
 *
 * @returns The generated path
 */
export const createKanbanTopicPath = (): string => {
  return `${createKanbanPath()}/:topicId`;
};

/**
 * Generate the URL for the feed showing the topic details
 *
 * @returns The generated path
 */
export const createKanbanTopicDetailsPath = (): string => {
  return `${createKanbanTopicPath()}/details`;
};

/**
 * Generate the URL for the feed showing the topic details edit mode
 *
 * @returns The generated path
 */
export const createKanbanTopicDetailsEditPath = (): string => {
  return `${createKanbanTopicDetailsPath()}/:topicEditMode`;
};
