import { TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';

import getPlaceholder from '../../../utils/placeholder';

import MuiIcon from '../MuiIcon';

import * as Styled from './styled';

/**
 * Search input component
 *
 * @param props Props passed to the component
 * @returns     The component itself
 */
const SearchField: FC<TextFieldProps> = props => {
  const { required, placeholder } = props;

  /**
   * If field is required and placeholder is provided,
   * suffix * will be added
   */
  const computedPlaceholder = getPlaceholder(required === true, placeholder);

  return (
    <Styled.FormControl fullWidth={true}>
      <TextField
        {...props}
        InputProps={{
          // Remove default underline on input
          disableUnderline: true,
          startAdornment: <MuiIcon icon="search" />,
        }}
        // Disable auto-complete
        autoComplete="off"
        // Automatically focus input
        autoFocus={true}
        placeholder={computedPlaceholder}
        type="search"
        variant="filled"
      />
    </Styled.FormControl>
  );
};

export default SearchField;
