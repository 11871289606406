import React, { FC } from 'react';

import { NAMES } from '../../../../components/Common/Icon';
import useIsMobile from '../../../../hooks/useIsMobile';
import translate from '../../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  getUrlCreate: string | null;
};

const ICON_SIZE = 16;
const MOBILE_ICON_SIZE = 31;

/**
 * Link for creating topics and topic modal itself
 *
 * @param props              Props passed to the component
 * @param props.getUrlCreate Callback to generate URL to be used for href (null when the user has no permission to create topics)
 * @returns                  The component itself
 */
const ButtonTopicCreate: FC<Props> = ({ getUrlCreate }) => {
  const isMobile = useIsMobile();

  if (getUrlCreate === null) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <Styled.LinkCreateMobile to={getUrlCreate}>
          <Styled.IconMobile
            height={MOBILE_ICON_SIZE}
            name={NAMES.GENERAL__PLUS}
            width={MOBILE_ICON_SIZE}
          />
        </Styled.LinkCreateMobile>
      </>
    );
  }
  return (
    <>
      <Styled.LinkCreate to={getUrlCreate}>
        <Styled.Icon
          color="currentColor"
          height={ICON_SIZE}
          name={NAMES.GENERAL__ADD}
          width={ICON_SIZE}
        />
        {translate('TOPIC__CREATE')}
      </Styled.LinkCreate>
    </>
  );
};

export default ButtonTopicCreate;
