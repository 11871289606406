/**
 * @file contains commonly used css blocks
 */

import { css, RuleSet } from 'styled-components';

/**
 * Makes an element visually hidden from in the DOM
 *
 * @returns styles for visually hiding the element
 */
export const visuallyHidden = css`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-left: -1px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-top: -1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

/**
 * Removes box-shadow outline focus styles from elements
 *
 * @returns styles for removing box-shadow outline on elements
 */
export const resetShadowFocusStyle = css`
  &:focus-visible:not(input) {
    box-shadow: none;
  }
`;

/**
 * If an element is bordered, adds focus style to border instead of box-shadow
 * which is default for all non-border elements
 *
 * @returns styles for adding border styles on elements
 */
export const borderFocusStyle = css`
  &:focus {
    border: var(--border-outline);
  }

  ${resetShadowFocusStyle};
`;

type Direction = 'up' | 'down' | 'left' | 'right';

type TriangleProps = {
  direction: Direction;
  size?: number;
  color?: string;
  transform?: string;
};

/**
 * Makes an element into a shape of a triangle
 * note: This is only used in profile menu dropdown now
 *
 * @todo Remove this when dropdown menu is updated
 * @param props           Props passed to the function
 * @param props.direction Direction of the triangle
 * @param props.size      Relative size of the triangle
 * @param props.color     Color of the triangle
 * @param props.transform Transform value
 * @returns               CSS styles that describe a triangle
 */
export const triangle = ({
  direction,
  size = 4,
  color = 'var(--color-white)',
  transform = '',
}: TriangleProps): RuleSet => {
  const directionDownStyles = css`
    border-top: ${size * 1.5}px solid ${color};
  `;

  const directionUpStyles = css`
    border-bottom: ${size * 1.5}px solid ${color};
  `;

  const directionLeftStyles = css`
    border-bottom: ${size * 1.5}px solid ${color};
    transform: ${transform} rotate(-90deg);
  `;

  const directionRightStyles = css`
    border-bottom: ${size * 1.5}px solid ${color};
    transform: ${transform} rotate(90deg);
  `;

  /**
   * Get CSS to apply to get the triangle pointing in the correct direction
   *
   * @param triangleDirection The direction the triangle should point
   * @returns                 Styles to apply
   */
  const getDirectionStyles = (triangleDirection: Direction): RuleSet => {
    const mapping = new Map<Direction, RuleSet>([
      ['up', directionUpStyles],
      ['right', directionRightStyles],
      ['down', directionDownStyles],
      ['left', directionLeftStyles],
    ]);

    return mapping.get(triangleDirection)!;
  };

  return css`
    border-left: ${size}px solid transparent;
    border-right: ${size}px solid transparent;
    height: 0;
    ${getDirectionStyles(direction)}
    width: 0;
  `;
};

/**
 * Adds a box shadow that represents the outline
 *
 * @returns box-shadow outline styles
 */
export const shadowOutline = css`
  box-shadow: 0 0 0 1px var(--color-outline);
`;
