/**
 * @file Get download info
 */

import { useEffect, useState } from 'react';

import { reportError } from '../../services/reporting';
import { getIsReactNativeIos } from '../../utils/webview/helpers';
import {
  DownloadInfoResponsePayload,
  WebViewEvent,
  WebViewEventNames,
} from '../../utils/webview/payloadTypes';

import { reactNativeMessageHandler } from './reactNativeMessageHandler';

/**
 * Get download info
 *
 * @returns Object containing download info
 */
const useWebViewDownloadInfo = (): DownloadInfoResponsePayload | undefined => {
  const [downloadInfo, setDownloadInfo] = useState<
    DownloadInfoResponsePayload | undefined
  >();

  useEffect(() => {
    if (getIsReactNativeIos() === false) {
      return;
    }

    const webViewEvents: WebViewEvent[] = [
      {
        callback: (response: DownloadInfoResponsePayload) => {
          setDownloadInfo(response);
        },
        eventName: WebViewEventNames.DownloadInfoResponse,
      },
    ];

    /**
     * A message has come in from the native side
     *
     * @param event The event that took place
     */
    const messageEventHandler = (event: MessageEvent<string>) => {
      reactNativeMessageHandler(event, webViewEvents);
    };

    if (getIsReactNativeIos()) {
      window.addEventListener('message', messageEventHandler, false);
    } else {
      reportError(
        'useWebViewEventListener: Error occurred on webview event: Unknown WebView.',
      );
    }

    return () => {
      if (getIsReactNativeIos()) {
        window.removeEventListener('message', messageEventHandler);
      }
    };
  }, []);

  return downloadInfo;
};

export default useWebViewDownloadInfo;
