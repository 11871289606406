/**
 * @file * After successful messages read request this function updates message isSeen prop
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MessagesReadMutation,
  MessagesReadMutationVariables,
} from '../../../../../generated/graphql';
import { messagesSetSeen } from '../utils/updaters/messages/messagesSetSeen';

/**
 * After successful messages read request this function updates message isSeen prop
 *
 * @param _result The result of the mutation (unused)
 * @param args    The arguments passed along with the mutation
 * @param cache   Current GraphQL cache
 */
const messageReadMutation: UpdateResolver<
  MessagesReadMutation,
  MessagesReadMutationVariables
> = (_result, args, cache) => {
  messagesSetSeen(args, cache);
};

export default messageReadMutation;
