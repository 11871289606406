import React, { FC, FormEventHandler } from 'react';

import { Label, LabelBasicFragment } from '../../generated/graphql';
import translate from '../../i18n/translate';

import SkeletonLabelList from '../Loading/LabelList';

import LabelPickerItem from './LabelPickerItem';

export type Props = {
  dataAll: LabelBasicFragment[];
  dataSelected: Set<Label['id']>;
  idForm: string;
  isLoading: boolean;
  requestModeEdit: ((labelId: Label['id']) => void) | null;
  requestSave: () => void;
  requestToggle: ((labelId: Label['id'], state: boolean) => void) | null;
};

/**
 * A list of existing labels,
 * Highlighting the ones already selected,
 * Allowing to toggle them
 *
 * If a callback requestModeEdit is passed, an edit button appears for each label
 *
 * @param props                 Props passed to the component
 * @param props.dataAll         The labels to show
 * @param props.dataSelected    The labels that are already selected
 * @param props.idForm          The ID for the label picker <form /> element
 * @param props.isLoading       Whether the data is still being loaded
 * @param props.requestModeEdit Callback for when the edit button is clicked (if null, the button won't be shown)
 * @param props.requestSave     Request that the changes are saved
 * @param props.requestToggle   Request that a label is (un)selected
 * @returns                     The component itself
 */
const LabelPicker: FC<Props> = ({
  dataAll,
  dataSelected,
  idForm,
  isLoading,
  requestModeEdit,
  requestToggle,
  requestSave,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the selection is saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestSave();
  };

  /**
   * Don't lift up skeletons to container
   * in order to prevent data leak, because it will mess up designs
   */
  if (isLoading) {
    return <SkeletonLabelList />;
  }

  return (
    <form
      aria-label={translate('LABELS__PICKER')}
      id={idForm}
      onSubmit={onSubmit}
    >
      <ul>
        {dataAll.map(label => (
          <li key={`label-${label.id}`}>
            <LabelPickerItem
              isSelected={dataSelected.has(label.id)}
              label={label}
              onClickEdit={
                requestModeEdit ? () => requestModeEdit(label.id) : null
              }
              onToggle={
                requestToggle ? state => requestToggle(label.id, state) : null
              }
            />
          </li>
        ))}
      </ul>
    </form>
  );
};

export default LabelPicker;
