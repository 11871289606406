import React, { FC } from 'react';

import * as Styled from './styled';

const ICON_SIZE = 14;

type Props = {
  iconName: string;
  text: string | null;
};

/**
 * Field that has an icon next to the label
 * (used for radio & checkbox)
 *
 * @param props          Props passed to the component
 * @param props.iconName Which icon to show
 * @param props.text     The field value, which text to show
 * @returns              The component itself
 */
const FieldIcon: FC<Props> = ({ iconName, text }) => {
  return (
    <Styled.Wrapper>
      <Styled.Icon
        color="var(--color-primary-lighter)"
        height={ICON_SIZE}
        name={iconName}
        width={ICON_SIZE}
      />
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default FieldIcon;
