/**
 * @file cached translations
 */

import { Locale } from '../generated/graphql';
import { getDefaultLanguageCode } from '../utils/user/language';

import de from './de';
import en from './en';
import fr from './fr';
import it from './it';
import nl from './nl';

/**
 * The shape of the translations object.
 */
export type Translations = Record<string, string>;

/**
 * Translations defined in app, that are used when the user is not authenticated.
 */
const unauthenticatedTranslations: Record<Locale, Translations> = {
  de,
  en,
  fr,
  it,
  nl,
};

/**
 * Cached translations for easier access.
 */
let translations: Translations =
  unauthenticatedTranslations[getDefaultLanguageCode()];

/**
 * Set translations that should be used in the app
 *
 * @param value All the translations to be used in the app
 */
export const setTranslations = (value: Translations) => {
  translations = value;
};

/**
 * Get translations
 *
 * @returns Translations
 */
export const getTranslations = () => {
  return translations;
};
