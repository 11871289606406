/**
 * @file All permissions checks related to labels
 */

import { PermissionsLabel } from '../../models/permissions';

import { LABEL__CREATE, LABEL__DELETE, LABEL__UPDATE } from './permissionsAll';

import { getPermissionsCurrent } from './storage';

/**
 *
 * HELPER
 *
 */

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsLabel): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user has permissions
 * to add a checklist item to a new message
 *
 * @returns Whether the user has permissions
 */
export const getCanCreateLabel = (): boolean => hasPermission(LABEL__CREATE);

/**
 *
 * READ
 *
 */

// Everyone can read all labels

/**
 *
 * UPDATE
 *
 */

/**
 * Check whether the user can update a label
 *
 * @returns Whether the user has permission
 */
export const getCanUpdateLabel = (): boolean => hasPermission(LABEL__UPDATE);

/**
 *
 * DELETE
 *
 */

/**
 * Check whether the user can delete a label
 *
 * @returns Whether the user has permission
 */
export const getCanDeleteLabel = (): boolean => hasPermission(LABEL__DELETE);
