/**
 * @file contains mappings of error codes to different handler data
 */

import { ApiErrors } from '../constants/errors';

import {
  ROUTE__ERROR__AUTH_HOTEL_NOT_FOUND,
  ROUTE__ERROR__AUTH_USER_NOT_FOUND,
  ROUTE__ERROR__DEACTIVATED_HOTEL,
  ROUTE__ERROR__MISSING_HOTEL,
  ROUTE__ERROR__MISSING_TERMS_AND_CONDITIONS,
} from '../constants/routing/routes';
import { ToastMessageArgs } from '../models/toastMessage';

/**
 * Map that connects error code to a route where error should be viewed
 */
export const errorCodeToRouteMap = new Map([
  [ApiErrors.AUTH_USER_NOT_FOUND.code, ROUTE__ERROR__AUTH_USER_NOT_FOUND],
  [ApiErrors.AUTH_HOTEL_NOT_FOUND.code, ROUTE__ERROR__AUTH_HOTEL_NOT_FOUND],
  [ApiErrors.DEACTIVATED_HOTEL.code, ROUTE__ERROR__DEACTIVATED_HOTEL],
  [ApiErrors.MISSING_HOTEL.code, ROUTE__ERROR__MISSING_HOTEL],
  [
    ApiErrors.MISSING_TERMS_AND_CONDITIONS.code,
    ROUTE__ERROR__MISSING_TERMS_AND_CONDITIONS,
  ],
]);

/**
 * Map that connects error code to a toast message that is to be shown
 */
export const errorCodeToToastMap = new Map<string, ToastMessageArgs>([
  [
    ApiErrors.RESTRICTED_HOTEL.code,
    {
      text: 'TOAST__ERROR__RESTRICTED_HOTEL__DESCRIPTION',
      title: 'TOAST__ERROR__RESTRICTED_HOTEL__TITLE',
      type: 'error',
    },
  ],
  [
    ApiErrors.MESSAGE_NOT_FOUND.code,
    {
      text: 'ERROR__MISSING_POST__DESCRIPTION',
      title: 'MESSAGE__DELETED',
      type: 'error',
    },
  ],
  [
    ApiErrors.MISSING_PERMISSION.code,
    {
      text: 'TOAST__ERROR__MISSING_PERMISSION__DESCRIPTION',
      title: 'TOAST__ERROR__MISSING_PERMISSION__TITLE',
      type: 'error',
    },
  ],
  [
    ApiErrors.TEMPLATE_NOT_CREATED.code,
    {
      text: 'TOAST__ERROR__TEMPLATE_NOT_CREATED__DESCRIPTION',
      title: 'GENERAL__OOPS',
      type: 'error',
    },
  ],
  [
    ApiErrors.TEMPLATE_NOT_UPDATED.code,
    {
      text: 'TOAST__ERROR__TEMPLATE_NOT_UPDATED__DESCRIPTION',
      title: 'GENERAL__OOPS',
      type: 'error',
    },
  ],
  [
    ApiErrors.TEMPLATE_NOT_DELETED.code,
    {
      text: 'TOAST__ERROR__TEMPLATE_NOT_DELETED__DESCRIPTION',
      title: 'GENERAL__OOPS',
      type: 'error',
    },
  ],
  [
    ApiErrors.TOPIC_NOT_FOUND.code,
    {
      text: 'ERROR__MISSING_TOPIC__DESCRIPTION',
      title: 'GENERAL__OOPS',
      type: 'error',
    },
  ],
]);
