import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * Right Sidebar mobile component
 *
 * @param props          Props passed to the component
 * @param props.children Children of the component
 * @returns              The component itself
 */
const RightSidebarMobile: FC<Props> = ({ children }) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default RightSidebarMobile;
