/**
 * @file Hook for checking if browser's tab in view or not
 */

import { useEffect, useState } from 'react';

/**
 * Hook for checking if browser's tab in view or not
 *
 * @returns Is browser's tab in view or not
 */
const useIsAppInView = () => {
  const [isAppInView, setIsAppInView] = useState(
    document.visibilityState === 'visible',
  );

  useEffect(() => {
    /**
     * Handler function for visibility change event which
     * which runs internal state setter with true if app is in focus
     * and false if it's not
     *
     * @returns undefined
     */
    const handleFocus = () =>
      setIsAppInView(document.visibilityState === 'visible');

    globalThis.addEventListener('visibilitychange', handleFocus, false);

    return () => {
      globalThis.removeEventListener('visibilitychange', handleFocus, false);
    };
  }, []);

  return isAppInView;
};

export default useIsAppInView;
