/**
 * @file Hook for DueDate chip component color
 */

import { useEffect, useState } from 'react';

import { MessageStatus } from '../../../generated/graphql';

import { getChipColor } from './helpers';

/**
 * Hook that updates color based on due date and status if necessary.
 *
 * @param status  The status of the task.
 * @param dueDate The due date of the task
 * @returns       Prop `color` value for the Chip component
 */
export const useDueDateColor = (
  status: MessageStatus | null,
  dueDate: string | null,
) => {
  const [color, setColor] = useState(getChipColor(status, dueDate));

  /**
   * Initially, the task can have a future due date, but if it becomes overdue
   * while the task card is visible, we update the color to reflect
   * its overdue status.
   */
  useEffect(() => {
    if (dueDate === null) {
      return;
    }

    const timeDiffMs = new Date(dueDate).getTime() - new Date().getTime();

    // Task is initially overdue, no color update needed.
    if (timeDiffMs < 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setColor(getChipColor(status, dueDate));
    }, timeDiffMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [dueDate, status]);

  return color;
};
