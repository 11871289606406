import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import {
  MessageCreatorType,
  UserBasicFragment,
} from '../../../generated/graphql';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import {
  getCreatorName,
  getIsExternal,
  getIsRecurring,
} from '../../../utils/message';
import Avatar from '../../Common/Avatar';
import AvatarRecurring from '../../Common/AvatarRecurring';
import DateTime from '../../Common/DateTime';

import { NAMES } from '../../Common/Icon';

import * as Styled from './styled';

export type Props = {
  author?: UserBasicFragment | null;
  createdAtRaw: string;
  creatorType: MessageCreatorType;
};

const ICON_SIZE = 40;

/**
 * Message author (and the time of creation)
 * !NOTE!: Backend sets type any for createdAt key,
 * so TypeScript doesn't do type checks,
 * but the type fetched is string
 *
 * @param props              Props passed to the component
 * @param props.author       Message author (can be null or undefined)
 * @param props.createdAtRaw When the message was created
 * @param props.creatorType  Message creator type
 * @returns                  The component itself
 */
const MessageAuthor: FC<Props> = ({
  author = null,
  createdAtRaw,
  creatorType,
}) => {
  const username = getCreatorName(creatorType, author);
  const isMobile = useIsMobile();

  const avatar = getIsExternal(creatorType) ? (
    <Styled.ConciergeIcon
      color="var(--color-primary-light)"
      height={ICON_SIZE}
      name={NAMES.CONCIERGE__MAIN}
      width={ICON_SIZE}
    />
  ) : (
    <Avatar fontSize={0.75} size={ICON_SIZE} user={author} />
  );

  return (
    <Styled.Wrapper>
      <Tippy content={username} touch={false}>
        {/* We need to wrap <span> around the children component, to make tippy work*/}
        <span>
          {getIsRecurring(creatorType) ? (
            <AvatarRecurring size={ICON_SIZE} />
          ) : (
            avatar
          )}
        </span>
      </Tippy>
      <div>
        {isMobile && username && <Styled.UserName>{username}</Styled.UserName>}
        <Styled.TextAt>{translate('MESSAGE__AT')}</Styled.TextAt>

        <DateTime
          date={createdAtRaw}
          label={translate('MESSAGE__CREATED_AT_LABEL')}
        />
      </div>
    </Styled.Wrapper>
  );
};

export default MessageAuthor;
