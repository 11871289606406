/**
 * @file contains redux actions related to draftsTemplate state
 */
import {
  DRAFTS__TEMPLATE__RESET,
  DRAFTS__TEMPLATE__SAVE,
  DRAFTS__TEMPLATE__SEARCH__SET,
  DRAFTS__TEMPLATE__SET,
} from '../../constants/actionTypes';
import { Template } from '../../generated/graphql';

import {
  DraftActionTemplateReset,
  DraftActionTemplateSave,
  DraftActionTemplateSearchSet,
  DraftActionTemplateSet,
} from '../../models/draftTemplate';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to reset the draft template
 *
 * @param parentId The ID of the draft to reset the template for
 * @returns        The action itself
 */
export const resetDraftTemplateRequest = (
  parentId: string,
): DraftActionTemplateReset => ({
  parentId,
  type: DRAFTS__TEMPLATE__RESET,
});

/**
 * Action to select template draft
 *
 * @param parentId   The ID of the message the target draft is a reply to
 * @param templateId The ID of current template
 * @returns          The action itself
 */
export const setDraftTemplateRequest = (
  parentId: string,
  templateId: Template['id'],
): DraftActionTemplateSet => ({
  parentId,
  templateId,
  type: DRAFTS__TEMPLATE__SET,
});

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftTemplateRequest = (
  parentId: string,
): DraftActionTemplateSave => ({
  parentId,
  type: DRAFTS__TEMPLATE__SAVE,
});

/**
 * Action to store search terms for template popup in compose block
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param value    The search term
 * @returns        The action itself
 */
export const searchDraftTemplatesRequest = (
  parentId: string,
  value: string,
): DraftActionTemplateSearchSet => ({
  parentId,
  type: DRAFTS__TEMPLATE__SEARCH__SET,
  value,
});
