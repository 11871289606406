import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__ASSIGNEE } from '../../../constants/routing/hashLinks';

import { MessageCommon } from '../../../models/message';

import Icon, { NAMES } from '../Icon';

import MessageInfo from './MessageInfo';
import * as Styled from './styled';

type Props = {
  assignedMembers: MessageCommon['assignedMembers'];
  className?: string;
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

const ICON_SIZE = 17;
const AVATAR_SIZE = 19;

/**
 * Shows members assigned to message
 *
 * @param props                 Props passed to the component
 * @param props.assignedMembers Members assigned to message
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.messageId       Message id
 * @param props.shouldBeLink    Whether info should be rendered as a link or not
 * @returns                     The component itself
 */
const MessageInfoAssignees: FC<Props> = ({
  assignedMembers,
  className,
  messageId,
  shouldBeLink,
}) => {
  return (
    <MessageInfo
      className={className}
      elementId={HASH_MESSAGE__INFO__ASSIGNEE}
      labelKey="ARIA__MESSAGE_INFO__ASSIGNEES"
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      <Icon
        color="var(--color-blue-dark)"
        height={ICON_SIZE}
        name={NAMES.MESSAGE__INFO__ASSIGNEE}
        width={ICON_SIZE}
      />
      <Styled.Avatars
        fontSize={0.5}
        size={AVATAR_SIZE}
        users={assignedMembers}
      />
    </MessageInfo>
  );
};

export default MessageInfoAssignees;
