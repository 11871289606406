/**
 * @file Hook for tracking attachment actions
 */

import { useEffect } from 'react';

import { useQuery } from 'urql';

import { Message, MessageFullDocument } from '../../generated/graphql';
import { AttachmentActionType } from '../../models/attachment';
import { trackAttachmentAction } from '../../services/matomo/trackAttachmentEvent';

/**
 * Hook for tracking user attachment actions
 *
 * @param messageId  Id of the current message
 * @param filename   File name
 * @param actionType Type of action (download/preview)
 * @returns          Track function
 */
const useTrackAttachmentAction = (
  messageId: Message['id'],
  filename: string,
  actionType: AttachmentActionType,
) => {
  const [{ data }, executeQuery] = useQuery({
    pause: true,
    query: MessageFullDocument,
    variables: {
      messageId,
    },
  });

  const { message } = data ?? {};

  /**
   * Requests to track an attachment action or, if message data is not available,
   * executes a query to fetch it. The tracking will be executed in the useEffect
   * when the message data becomes available.
   */
  const requestTrack = () => {
    if (message !== undefined && message !== null) {
      trackAttachmentAction({
        actionType,
        filename,
        message,
      });
    } else {
      // Message data is not available, so we execute a query to retrieve it.
      // Tracking will be performed within the useEffect once the data arrives.
      executeQuery();
    }
  };

  /**
   * This useEffect is triggered when the message data is ready for tracking
   * and was not available initially when requestTrack was called.
   */
  useEffect(() => {
    if (message === undefined || message === null) {
      return;
    }

    trackAttachmentAction({
      actionType,
      filename,
      message,
    });

    // actionType and filename are static values
  }, [actionType, filename, message]);

  return requestTrack;
};

export default useTrackAttachmentAction;
