import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  id?: string;
};

/**
 * Common title component
 *
 * @param props           The props passed to the component
 * @param props.children  The title to show
 * @param props.className styled-components generated class name
 * @param props.id        ID attribute for the component heading
 * @returns               The rendered component
 */
const Title: FC<Props> = ({ children, className, id }) => {
  return (
    <Styled.Title className={className} id={id}>
      {children}
    </Styled.Title>
  );
};

export default Title;
