/**
 * @file Use is chat enabled hook
 */

import useCurrentHotel from '../../hooks/useCurrentHotel';

/**
 * Returns true if chat is enabled
 *
 * @returns Whether chat is enabled (null while loading)
 */
export const useIsChatEnabled = (): boolean | null => {
  const hotel = useCurrentHotel();

  return hotel?.isChatEnabled ?? null;
};
