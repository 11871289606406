/**
 * @file contains redux actions related to draftChecklists state
 */

import {
  DRAFTS__CHECKLIST__ADD,
  DRAFTS__CHECKLIST__REMOVE,
  DRAFTS__CHECKLIST__REORDER,
  DRAFTS__CHECKLIST__SAVE,
  DRAFTS__CHECKLIST__SET_STATE,
  DRAFTS__CHECKLIST__SET_TEXT,
} from '../../constants/actionTypes';
import { ChecklistDataDraft } from '../../models/checklist';

import {
  DraftActionChecklistCreate,
  DraftActionChecklistDelete,
  DraftActionChecklistReorder,
  DraftActionChecklistSave,
  DraftActionChecklistSetState,
  DraftActionChecklistSetText,
} from '../../models/draftChecklist';

/**
 *
 * CREATE actions
 *
 */

/**
 * Action to create a new item in a draft checklist
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param text     Checklist text
 * @returns        The action itself
 */
export const addDraftChecklistItemRequest = (
  parentId: string,
  text: string,
): DraftActionChecklistCreate => ({
  parentId,
  text,
  type: DRAFTS__CHECKLIST__ADD,
});

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftChecklistRequest = (
  parentId: string,
): DraftActionChecklistSave => ({
  parentId,
  type: DRAFTS__CHECKLIST__SAVE,
});

/**
 * Action to reorder checklist items
 *
 * @param parentId The ID of the message target draft is a reply to
 * @param items    The new checklist items order
 * @returns        The action itself
 */
export const updateReorderDraftChecklistRequest = (
  parentId: string,
  items: ChecklistDataDraft[],
): DraftActionChecklistReorder => ({
  items,
  parentId,
  type: DRAFTS__CHECKLIST__REORDER,
});

/**
 * Action to (un)assign a member from a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param position Item position (used to identify it; we don't have an id yet)
 * @param state    true: checked; false: unchecked
 * @returns        The action itself
 */
export const updateStateDraftChecklistRequest = (
  parentId: string,
  position: number,
  state: boolean,
): DraftActionChecklistSetState => ({
  parentId,
  position,
  state,
  type: DRAFTS__CHECKLIST__SET_STATE,
});

/**
 * Action to (un)assign a member from a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param position Item position (used to identify it; we don't have an id yet)
 * @param text     New checklist item text
 * @returns        The action itself
 */
export const updateTextDraftChecklistRequest = (
  parentId: string,
  position: number,
  text: string,
): DraftActionChecklistSetText => ({
  parentId,
  position,
  text,
  type: DRAFTS__CHECKLIST__SET_TEXT,
});

/**
 *
 * DELETE actions
 *
 */

/**
 * Remove a checklist item
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param position Item position (used to identify it; we don't have an id yet)
 * @returns        The action itself
 */
export const removeDraftChecklistItemRequest = (
  parentId: string,
  position: number,
): DraftActionChecklistDelete => ({
  parentId,
  position,
  type: DRAFTS__CHECKLIST__REMOVE,
});
