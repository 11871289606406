import { styled } from 'styled-components';

import KpiSingle from '../KpiSingle';

export const ConciergesWrapper = styled.article`
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  display: flex;
`;

export const ConciergeDataWrapper = styled.ul`
  align-items: center;
  background-color: var(--color-background-list);
  display: flex;
  font-weight: var(--font-semibold);
  justify-content: space-evenly;
  padding: var(--spacing-medium) 0;
  width: 70%;
`;

export const ConciergeImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--spacing-large) 0;
  width: 30%;
`;

export const ConciergeKpiSingle = styled(KpiSingle)`
  flex-direction: column;
  text-align: center;
`;
