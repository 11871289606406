/**
 * @file Contains helpers for creating and changing params that are used for filtering
 */

import { Location } from 'react-router-dom';

import { SEARCH_PARAM__FILTER__MEMBERS } from '../../constants/routing/searchParams';

import {
  FilterMembersConfig,
  FilterMembersEntity,
  FilterMembersParam,
} from '../../models/searchParams';

import { removeParamsKeysFromUrl, replaceOrAddParamsToUrl } from './router';
import { getSearchParamFilterMembers } from './searchParams';

type ParamFn = (location: Location) => Set<string> | null;

const paramGettersMap: Record<FilterMembersEntity, ParamFn> = {
  members: getSearchParamFilterMembers,
};
const searchParamsMap: Record<FilterMembersEntity, FilterMembersParam> = {
  members: SEARCH_PARAM__FILTER__MEMBERS,
};

/**
 * Toggles param that is used for filtering to the url
 *
 * @param entity   The entity we want to apply the filter
 * @param key      The entity identifier
 * @param location History location object
 * @returns        String in URL format
 */
export const toggleFilterParam = (
  entity: FilterMembersEntity,
  key: string,
  location: Location,
) => {
  const paramsGetter = paramGettersMap[entity];
  const param = paramsGetter(location) ?? new Set<string>();
  const searchParam = searchParamsMap[entity];

  if (param.has(key) === true) {
    param.delete(key);
  } else {
    param.add(key);
  }

  if (param.size === 0) {
    return removeParamsKeysFromUrl(location, searchParam);
  }

  return replaceOrAddParamsToUrl(location, {
    [searchParam]: [...param].join(','),
  });
};

/**
 * Retrieves and serializes filters from the provided location object.
 * Filters are extracted from the URL to make them accessible for the kanban.
 *
 * @param location History location object
 * @returns        An object containing all serialized filters.
 */
export const getSerializedFilters = (
  location: Location,
): FilterMembersConfig => {
  return Object.entries(paramGettersMap).reduce(
    (filtersObject, entries) => {
      const [entity, paramsGetter] = entries as [FilterMembersEntity, ParamFn];

      const param = paramsGetter(location);

      if (param !== null) {
        filtersObject[entity] = param;
      }

      return filtersObject;
    },
    { members: new Set<string>() } as FilterMembersConfig,
  );
};
