import { Avatar, Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { URL_IMAGES } from '../../../config';
import { MOBILE_HEADER__MAX_TITLE_LENGTH } from '../../../constants/preferences';
import { Hotel } from '../../../generated/graphql';
import truncateText from '../../../utils/string/truncateText';

import Title from '../../Common/Title';

import MuiIcon from '../MuiIcon';

import * as Styled from './styled';

type Props = {
  hotel: Pick<Hotel, 'imageUrl' | 'name'>;
  shouldShowHotelName: boolean;
};

/**
 * Hotel Avatar component for the header component
 *
 * @param props                     Props passed to the component
 * @param props.hotel               Hotel the topic belongs to
 * @param props.shouldShowHotelName Whether to show the hotel name or not
 * @returns                         The component itself
 */
const HotelAvatar: FC<Props> = ({ hotel, shouldShowHotelName }) => {
  const imageUrl = hotel.imageUrl ?? '';

  const src = imageUrl === '' ? undefined : URL_IMAGES + imageUrl;

  const avatar = (
    <Avatar alt={hotel.name} src={src}>
      <MuiIcon icon="apartment" />
    </Avatar>
  );

  if (shouldShowHotelName === false) {
    return <Tooltip title={hotel.name}>{avatar}</Tooltip>;
  }

  return (
    <Styled.Wrapper>
      {avatar}
      <Title>{truncateText(MOBILE_HEADER__MAX_TITLE_LENGTH, hotel.name)}</Title>
    </Styled.Wrapper>
  );
};

export default HotelAvatar;
