/**
 * @file Hook that returns messageEditMode if there is one
 */

import { useParams } from 'react-router-dom';

import { FeedParams, MessageEditModeParam } from '../../../models/pageParams';

/**
 * Hook that returns messageEditMode if there is one
 *
 * @returns Current messageEditMode or null
 */
const useParamMessageEditMode = (): MessageEditModeParam | null => {
  const { messageEditMode } = useParams<FeedParams>();

  if (!messageEditMode) {
    return null;
  }

  return messageEditMode;
};

export default useParamMessageEditMode;
