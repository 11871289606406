import { styled } from 'styled-components';

import devices from '../../../styles/devices';

import CommonAvatars from '../Avatars';
import CommonDateDue from '../DateDue';

import { ICON_SIZE } from './MessageIcon';
import CommonMessageInfoWrapper from './MessageInfoWrapper';
import { Ribbon } from './MessageRibbon/styled';
import { ContentProps, getBackgroundColor, WrapperProps } from './helpers';

const gapSize = 10;
const horizontalPadding = 10;

// Wraps everything except for avatar image
export const Content = styled.div<ContentProps>`
  /* Handles font-size for whole message body (message text and concierge) */
  font-size: var(--font-baseline);
  grid-column: ${props => (props['data-is-full-width'] ? 1 : 2)} / span 2;

  @media ${devices.tabletS} {
    grid-column-start: 2;
  }
`;

// Covers the title and message metadata
export const Header = styled.header`
  align-self: end;

  /* Handles title and date font-size for header message */
  font-size: var(--font-baseline);
  grid-column-start: 2;

  @media ${devices.tabletS} {
    align-self: start;
  }
`;

// Component wrapper
export const OuterWrapper = styled.article<WrapperProps>`
  background-color: ${getBackgroundColor};
  border-bottom: ${props =>
    props['data-should-hide-border-bottom'] ? 'none' : 'var(--border-pale)'};
  font-size: var(--font-baseline);
  opacity: ${(props: WrapperProps) => (props['data-should-dim'] ? 0.5 : 1)};
  position: relative;
  transition: background-color var(--preference-transition-duration-normal);

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-hover-light);
    }
  }

  &:hover ${Ribbon} {
    display: flex;
  }
`;

export const ContentWrapper = styled.div`
  align-items: start;
  display: grid;
  gap: ${gapSize}px;
  grid-template: auto 1fr / auto 1fr;
  padding: var(--spacing-tiny) ${horizontalPadding}px 0;
  position: relative;
`;

export const InnerWrapper = styled.div`
  padding-bottom: var(--spacing-medium);
  padding-left: var(--spacing-tiny);

  @media ${devices.tabletS} {
    /* Decided to do it like this until subgrid is supported in all browsers
     * @todo change when subgrid is supported
     */
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding-left: ${gapSize + ICON_SIZE + horizontalPadding}px;
  }
`;

export const DateDue = styled(CommonDateDue)`
  margin-right: var(--spacing-small);
`;

export const MessageInfoWrapper = styled(CommonMessageInfoWrapper)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-baseline);
`;

export const Avatars = styled(CommonAvatars)`
  /* Pixel values used per design */
  gap: 2px;
`;
