import React, { FC } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  MessageDateDocument,
  MessageUpdateDocument,
  Reminder,
} from '../../../generated/graphql';
import useRedirectOnError from '../../../hooks/useRedirectOnError';
import translate from '../../../i18n/translate';
import { reportApiErrors } from '../../../utils/error';
import { PropsInner } from '../props';

import { extractMessageDateDue, extractMessageReminders } from './helpers';
import Inner from './inner';

/**
 * Date Due mode for message full
 *
 * @param props                       Props passed to the component
 * @param props.messageId             The id of current message
 * @param props.networkMessageDetails Whether the debug option for fetching this is on
 * @param props.requestModeOverview   Request that messageFull switches to overview mode
 * @returns                           The component itself
 */
const MessageDateDue: FC<PropsInner> = ({
  messageId,
  networkMessageDetails,
  requestModeOverview,
}) => {
  const handleErrorRedirect = useRedirectOnError();
  const [{ data, error, fetching }] = useQuery({
    query: MessageDateDocument,
    variables: { messageId },
  });

  const [, requestMessageUpdate] = useMutation(MessageUpdateDocument);

  if (error) {
    handleErrorRedirect(error);
    return null;
  }

  if (
    data === null ||
    data === undefined ||
    fetching === true ||
    networkMessageDetails === true
  ) {
    return <div>{translate('PLACEHOLDER__LOADING')}</div>;
  }

  /**
   * Request that message due date and reminders are updated
   *
   * @param dueDate   New message due date
   * @param reminders New message reminders
   */
  const requestSave = (dueDate: Date, reminders: Reminder[]) => {
    requestMessageUpdate({
      data: { dueDate: dueDate?.toString() ?? null, reminders: reminders },
      id: messageId,
    }).catch(reportApiErrors);
  };

  return (
    <Inner
      dateDueSaved={extractMessageDateDue(data)}
      remindersSaved={extractMessageReminders(data)}
      requestModeOverview={requestModeOverview}
      requestSave={requestSave}
    />
  );
};

export default MessageDateDue;
