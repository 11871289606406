import React, { FC } from 'react';

import { generateRandomInt } from '../../../utils/number';

import * as Styled from './styled';

type Props = {
  className?: string;
  color?: string;
  nrLines: number;
};

/**
 * Skeleton for a paragraph of text
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     CSS variable to use for skeleton line color
 * @param props.nrLines   The number of lines to show
 * @returns               The component itself
 */
const SkeletonParagraph: FC<Props> = ({ className, color, nrLines }) => {
  // Generate an array of numbers denoting widths for skeleton lines for each line of text
  // The last line will be shorter (40-100% of parent), and the rest will be 90-100%
  const lines = Array.from({ length: nrLines }, (element, index) => {
    const widthMin = index === nrLines - 1 ? 40 : 90;
    const widthMax = 100;
    return generateRandomInt(widthMin, widthMax);
  });

  return (
    <div className={className}>
      {lines.map((line, index) => (
        // No way to generate this properly
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Line color={color} key={index} width={line} />
      ))}
    </div>
  );
};

export default SkeletonParagraph;
