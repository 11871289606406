import React, { FC } from 'react';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getDashboardUrl } from '../../../routes/urls/dashboard';
import { hotelGetImageUrl } from '../../../utils/imageUrlGet';
import DrawerEntry from '../../MUI/Drawer/DrawerContent/DrawerEntry';

import * as Styled from '../styled';

/**
 * The component for the hotel(object) switch in navigation
 *
 * @returns The component itself
 */
const HotelNavigation: FC = () => {
  const hotelId = useParamHotelId();
  const currentUser = useCurrentUser();

  const hotels =
    currentUser?.hotelPermissions.map(permission => permission.hotel) ?? [];

  return (
    <Styled.ScrollableList>
      {hotels.map(({ id, imageUrl, name }) => {
        const isSelected = id === hotelId;
        const src = hotelGetImageUrl(imageUrl);

        return (
          <DrawerEntry
            avatarSrc={src}
            href={getDashboardUrl(id)}
            icon="apartment"
            isSelected={isSelected}
            key={id}
            label={name}
            // Do not reload page if hotel is already selected
            shouldReloadDocument={isSelected === false}
          />
        );
      })}
    </Styled.ScrollableList>
  );
};

export default HotelNavigation;
