import React, { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from 'urql';

import MetaLabels from '../../../components/MetaLabels';
import useLabelsMode from '../../../components/MetaLabels/useLabelsMode';
import { LabelBasicFragment, LabelsDocument } from '../../../generated/graphql';
import { Color, LabelID, Text } from '../props';

type Props = {
  isLoading: boolean;
  labelsAll: LabelBasicFragment[];
  labelsSaved: LabelBasicFragment[];
  requestModeOverview: () => void;
  requestCreate: (text: Text, color: Color) => void;
  requestUpdate: (id: LabelID, text: Text, color: Color) => void;
  requestSave: (labels: LabelID[]) => void;
};

/**
 * 2nd part of the message full labels container
 * Split like this because of urql + useState
 * we have branching in urql for fetching, error and done states
 * and useState doesn't allow for that
 *
 * @param props                     Props passed to the component
 * @param props.isLoading           Whether the debug option for fetching this is on
 * @param props.labelsAll           All labels present on board
 * @param props.labelsSaved         Already selected labels
 * @param props.requestCreate       Make a request to create a new label
 * @param props.requestModeOverview Request to switch to MessageFull component
 * @param props.requestSave         Request that the changes are saved
 * @param props.requestUpdate       Request that a label is updated
 * @returns                         The component itself
 */
const MessageLabelsInner: FC<Props> = ({
  isLoading,
  labelsAll,
  labelsSaved,
  requestCreate,
  requestModeOverview,
  requestSave,
  requestUpdate,
}) => {
  const [dataCurrent, mode, { setModeCreate, setModeEdit, setModeView }] =
    useLabelsMode('messageFull');

  const [labelsSelected, setLabelsSelected] = useState(
    new Set(labelsSaved.map(label => label.id)),
  );
  /**
   * Use useRef hook because labelsSelected value will get changed
   * and we need to compare it with initial value
   */
  const labelsSavedSet = useRef(labelsSelected);
  const [{ data }] = useQuery({ query: LabelsDocument, variables: {} });

  /**
   * Request that assignment is updated
   */
  const requestLabelsSave = () => {
    requestSave(Array.from(labelsSelected));
    requestModeOverview();
  };

  /**
   * Request that a label's checkbox state is set
   *
   * @param labelId The ID of the label to set the state for
   * @param state   The updated state
   */
  const requestToggle = (labelId: LabelID, state: boolean) => {
    const labels = new Set(labelsSelected);
    if (state === true) {
      labels.add(labelId);
    } else {
      labels.delete(labelId);
    }

    setLabelsSelected(labels);
  };

  const labelsExisting = data?.labels.map(label => label.text) ?? [];

  useEffect(() => {
    /**
     * While loading we set incorrect value for labelsSelected,
     * here reSet is done when data are loaded
     */
    if (isLoading === false) {
      setLabelsSelected(new Set(labelsSaved.map(label => label.id)));
    }
  }, [isLoading, labelsSaved]);

  return (
    <MetaLabels
      dataAll={labelsAll}
      dataCurrent={dataCurrent}
      dataSaved={labelsSavedSet.current}
      dataSelected={labelsSelected}
      isActive={true}
      isLoading={isLoading}
      labelsExisting={labelsExisting}
      mode={mode}
      onClose={requestModeOverview}
      requestCreate={requestCreate}
      requestModeCreate={setModeCreate}
      requestModeEdit={setModeEdit}
      requestModeView={setModeView}
      requestSave={requestLabelsSave}
      requestToggle={requestToggle}
      requestUpdate={requestUpdate}
    />
  );
};

export default MessageLabelsInner;
