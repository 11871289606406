/**
 * @file Helpers for urls that require usage of hashes
 */

import { UrlHash } from '../../models/urlHash';

/**
 * Get an url with a hash
 *
 * @param url  The url we want to concat a hash
 * @param hash The hash
 * @returns    String in URL format
 */
export const getUrlWithHash = (url: string, hash: UrlHash) => `${url}#${hash}`;
