/**
 * @file All available modal illustrations and their mapping to actual files
 */

/**
 * Supported filenames for images
 */
type Filename = `${string}.${'jpg' | 'jpeg' | 'png'}`;

/**
 * All the available image options
 */
export type Image = 'PUSH_ALLOW';

/**
 * Mapping between image options and actual pathnames
 */
export const filenames: Record<Image, Filename> = {
  PUSH_ALLOW: 'push-allow.png',
};
