import { styled } from 'styled-components';

import { getQueryByDevice } from '../../../styles/getQuery';

const mediaQuery = getQueryByDevice(null, 'tabletS');

// Common styles for chevron wrappers
// And we have vertical and horizontal variant there
// Because on small screens, the direction is horizontal,
// While it's vertical for larger
const ChevronWrapper = styled.span`
  align-items: center;
  display: flex;
  margin-left: var(--spacing-medium);
`;

export const ChevronWrapperHorizontal = styled(ChevronWrapper)`
  display: none;

  @media ${mediaQuery} {
    align-items: center;
    display: flex;
  }
`;

export const ChevronWrapperVertical = styled(ChevronWrapper)`
  /** alignment hack */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-top: -2px;

  @media ${mediaQuery} {
    display: none;
  }
`;
