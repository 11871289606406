import React, { FC, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { getIsSearchParamFilterActive } from '../../../../routes/helpers/searchParams';
import QuickFiltersContainer from '../../../quickFilters';

import * as Styled from './styled';

/**
 * The feed/kanban quick filters to filter out messages and tasks
 *
 * @returns The layout to use
 */
const FeedKanbanQuickFilters: FC = () => {
  const [quickFiltersVisible, setQuickFiltersVisible] = useState(false);
  const location = useLocation();

  const isFilter = getIsSearchParamFilterActive(location);

  return (
    <>
      <Styled.QuickFiltersButton
        badgeCount={isFilter ? 1 : 0}
        isSelected={quickFiltersVisible}
        requestChange={() => setQuickFiltersVisible(prev => !prev)}
      />
      {quickFiltersVisible && (
        <Styled.QuickFiltersWrapper>
          <QuickFiltersContainer />
        </Styled.QuickFiltersWrapper>
      )}
    </>
  );
};

export default FeedKanbanQuickFilters;
