import React, { FC } from 'react';

import { cardIllustrationTypes, KpiSingle } from '../../../models/dashboard';
import DashboardCard from '../Card';

import { KpiSingleCard } from '../styled';

import * as Styled from './styled';

export type Props = {
  requests: KpiSingle[];
};

/**
 * Desktop version of UI for kpi type REQUEST
 *
 * @param props          Props passed to the component
 * @param props.requests Requests to be rendered
 * @returns              The component itself
 */
const RequestsDesktop: FC<Props> = ({ requests }) => {
  if (requests.length === 0) {
    return null;
  }

  return (
    <Styled.List>
      {requests.map(({ amount, label, url }, index) => {
        return (
          <li key={label}>
            <DashboardCard type={cardIllustrationTypes[index]} url={url}>
              <KpiSingleCard amount={amount} label={label} />
            </DashboardCard>
          </li>
        );
      })}
    </Styled.List>
  );
};

export default RequestsDesktop;
