/**
 * @file Helper function for injecting viewport meta tag into head
 */
import { getIsReactNativeWebView } from './helpers';

/**
 * Inject correct meta viewport tag into head
 */
const injectMetaViewport = () => {
  const isReactNativeWebView = getIsReactNativeWebView();
  const content = isReactNativeWebView
    ? 'initial-scale=1, viewport-fit=contain, user-scalable=no'
    : 'initial-scale=1, viewport-fit=contain, width=device-width';

  const meta = document.createElement('meta');
  meta.setAttribute('content', content);
  meta.setAttribute('name', 'viewport');
  document.querySelector('head')?.insertAdjacentElement('beforeend', meta);
};

export default injectMetaViewport;
