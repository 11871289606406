import { Badge, styled } from '@mui/material';

export const NotificationsBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'var(--font-weight-label-bold)',
    height: 'var(--size-badge-notification)',
    minWidth: 'var(--size-badge-notification)',
    padding: '0 var(--spacing-tiny)',
  },
}));
