/**
 * @file Make a deep copy of recurring state data
 */

import { RecurringState } from '../models/recurring';

/**
 * Make a deep copy of recurring state data
 *
 * @param stateOriginal The state we want to clone
 * @returns             The cloned state
 */
const stateRecurringClone = (stateOriginal: RecurringState): RecurringState => {
  if ('structuredClone' in globalThis) {
    return structuredClone(stateOriginal);
  }

  const stateCloned = {
    ...stateOriginal,
    bymonthdayCurrent: new Set(stateOriginal.bymonthdayCurrent),
    bymonthdaySaved: new Set(stateOriginal.bymonthdaySaved),
    byweekday: new Set(stateOriginal.byweekday),
  };

  return stateCloned;
};

export default stateRecurringClone;
