/**
 * @file Contains customization for MuiAvatar Component
 */

import { Components } from '@mui/material';

import { colorPrimary } from '../colors';

import {
  colorPrimaryBaseStyle,
  colorPrimaryFocusStyle,
  colorPrimaryHoverStyle,
} from './shared';

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    colorDefault: {
      '&:focus-visible': {
        ...colorPrimaryFocusStyle,
        borderColor: colorPrimary[100],
      },
      '&:hover': {
        ...colorPrimaryHoverStyle,
        borderColor: colorPrimary[50],
      },
      ...colorPrimaryBaseStyle,
      fontSize: 'var(--font-sm)',
      fontWeight: 600,
    },
  },
};

export default MuiAvatar;
