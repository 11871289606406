import { styled } from 'styled-components';

export const Input = styled.input`
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  font-size: var(--font-baseline);
  outline: 0;
  padding: var(--spacing-tiny) var(--spacing-small);

  &::placeholder {
    color: var(--color-placeholder);
    font-size: var(--font-baseline);
  }

  &:hover {
    background-color: var(--color-hover-light);
  }

  &:disabled {
    background-color: var(--color-background);
    border: none;
    color: var(--color-text-regular);
    height: auto;
    padding: 0;

    &:hover {
      background-color: var(--color-background);
      cursor: text;
    }
  }

  /**
   + All required inputs that are invalid but have some text
   * and all non-required inputs that are invalid
   */
  &:not(:required):invalid,
  &:required:not([value='']):invalid {
    border-color: var(--color-button-danger);
  }
`;
