import { styled } from 'styled-components';

import CommonIcon from '../../../../../components/Common/Icon';
import devices from '../../../../../styles/devices';

const breakpoint = devices.tabletS;

type ButtonProps = {
  'data-is-compose-open': boolean;
};

const bottomPositionModifier = '0.5rem';

/**
 * Get the bottom positioning of the button
 *
 * @param props Props passed to the button
 * @returns     The value to use
 */
const getBottomValue = (props: ButtonProps): string => {
  if (props['data-is-compose-open']) {
    return `calc(var(--spacing-medium) + ${bottomPositionModifier})`;
  }
  return `calc(var(--preference-compose-toggle-closed-height) + ${bottomPositionModifier})`;
};

export const Button = styled.button<ButtonProps>`
  align-items: center;
  background-color: var(--color-button-darker);
  border-radius: var(--border-radius-small);

  /**
   * Value taken after looking at how it looks, cause design values are not working out well.
   * Used rem because font size can change.
   */
  bottom: ${bottomPositionModifier};
  color: var(--color-text-alt);
  display: inline-flex;
  font-weight: 600;
  left: 50%;
  padding: var(--spacing-small) var(--spacing-medium);
  position: absolute;
  transform: translateX(-50%);
  z-index: var(--z-1);

  @media ${breakpoint} {
    bottom: ${getBottomValue};
  }
`;

export const Text = styled.span`
  margin-left: var(--spacing-small);
`;

export const Icon = styled(CommonIcon)`
  transform: rotate(180deg);
`;
