/**
 * @file Checklist helpers for MessageFull component
 */

import { TypeMessageFull } from '../../../models/message';
import { reportApiErrors } from '../../../utils/error';

import { getCanCreateItemForExistingMessage } from '../../../utils/permissions/checklist/checklistCreate';
import { getCanDeleteItemFromExistingMessage } from '../../../utils/permissions/checklist/checklistDelete';
import {
  getCanReorderItemForExistingMessage,
  getCanSetStateForExistingMessage,
  getCanSetTextForExistingMessage,
} from '../../../utils/permissions/checklist/checklistUpdate';

import {
  PropsChecklist,
  TypeChecklistItemRequestCreate,
  TypeChecklistItemRequestDelete,
  TypeChecklistItemRequestReorder,
  TypeChecklistItemRequestSetState,
  TypeChecklistItemRequestSetText,
} from '../props/checklist';

/**
 * Generate mutation callback with minimal arguments,
 * to pass down as a prop
 *
 * @param message  The message to generate the callback for
 * @param callback Mutation to be invoked
 * @returns        Callback that can be passed down as a prop
 */
export const requestCreateHelper = (
  message: TypeMessageFull,
  callback: PropsChecklist['requestChecklistCreate'],
): TypeChecklistItemRequestCreate => {
  const canCreateItems = getCanCreateItemForExistingMessage(message);
  if (canCreateItems === false) {
    return null;
  }

  return text => {
    callback({
      isChecked: false,
      messageId: message.id,
      // message.checklist.length because position of the new item will be
      // 1 larger than the current number of items
      position: message.checklist.length,
      text,
    }).catch(reportApiErrors);
  };
};

/**
 * Generate delete mutation callback with minimal arguments,
 * to pass down as a prop
 *
 * @param message  The message to generate the callback for
 * @param callback Mutation to be invoked
 * @returns        Callback that can be passed down as a prop
 */
export const requestDeleteHelper = (
  message: TypeMessageFull,
  callback: PropsChecklist['requestChecklistDelete'],
): TypeChecklistItemRequestDelete => {
  const canDeleteItems = getCanDeleteItemFromExistingMessage(message);
  if (canDeleteItems === false) {
    return null;
  }

  return itemId => {
    callback({
      itemId,
      messageId: message.id,
    }).catch(reportApiErrors);
  };
};

/**
 * Generate reorder mutation callback with minimal arguments,
 * to pass down as a prop
 *
 * @param message  The message to generate the callback for
 * @param callback Mutation to be invoked
 * @returns        Callback that can be passed down as a prop
 */
export const requestReorderHelper = (
  message: TypeMessageFull,
  callback: PropsChecklist['requestChecklistReorder'],
): TypeChecklistItemRequestReorder => {
  const canReorder = getCanReorderItemForExistingMessage(message);

  if (canReorder === false) {
    return null;
  }

  return (itemId, position) => {
    callback({
      itemId,
      messageId: message.id,
      position,
    }).catch(reportApiErrors);
  };
};

/**
 * Generate set state mutation callback with minimal arguments,
 * to pass down as a prop
 *
 * @param message  The message to generate the callback for
 * @param callback Mutation to be invoked
 * @returns        Callback that can be passed down as a prop
 */
export const requestSetStateHelper = (
  message: TypeMessageFull,
  callback: PropsChecklist['requestChecklistSetState'],
): TypeChecklistItemRequestSetState => {
  const canSetState = getCanSetStateForExistingMessage(message);
  if (canSetState === false) {
    return null;
  }

  return (itemId, state) => {
    callback({
      itemId,
      messageId: message.id,
      state,
    }).catch(reportApiErrors);
  };
};

/**
 * Generate set text mutation callback with minimal arguments,
 * to pass down as a prop
 *
 * @param message  The message to generate the callback for
 * @param callback Mutation to be invoked
 * @returns        Callback that can be passed down as a prop
 */
export const requestSetTextHelper = (
  message: TypeMessageFull,
  callback: PropsChecklist['requestChecklistSetText'],
): TypeChecklistItemRequestSetText => {
  const canSetText = getCanSetTextForExistingMessage(message);
  if (canSetText === false) {
    return null;
  }

  return (itemId, text) => {
    callback({
      itemId,
      messageId: message.id,
      text,
    }).catch(reportApiErrors);
  };
};
