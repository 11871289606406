import { styled } from 'styled-components';

import ButtonIcon from '../ButtonIcon';

const size = '62px';

export const ButtonAdd = styled(ButtonIcon)`
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 50%;
  box-shadow: var(--box-shadow-strong);
  color: var(--color-icons-alt);
  display: flex;
  height: ${size};
  justify-content: center;
  width: ${size};
`;
