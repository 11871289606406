/**
 * @file Move an array item to a different place
 */

/**
 * Move an array item to a different place
 *
 * @param list     The array to reorder
 * @param indexOld Index at which the item was originally
 * @param indexNew Index at which to put the item now
 * @returns        New array, with the same data, but altered order
 */
const reorder = <T>(list: T[], indexOld: number, indexNew: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(indexOld, 1);
  result.splice(indexNew, 0, removed);

  return result;
};

export default reorder;
