import React, { FC } from 'react';

import useParamHotelId from '../../../../../hooks/router/params/useParamHotelId';
import translate from '../../../../../i18n/translate';
import { getDashboardUrl } from '../../../../../routes/urls/dashboard';

import { NAMES } from '../../../../Common/Icon';

import * as Styled from './styled';

const ICON_HEIGHT = 50;
const ICON_WIDTH = 170;

/**
 * Drawer upper content with logo
 *
 * @returns The component itself
 */
const DrawerUpperContent: FC = () => {
  const hotelId = useParamHotelId();

  return (
    <Styled.StyledLink
      aria-label={translate('DASHBOARD__TITLE')}
      to={getDashboardUrl(hotelId)}
    >
      <Styled.Icon
        height={ICON_HEIGHT}
        name={NAMES.GENERAL__LOGO__SPLASH}
        width={ICON_WIDTH}
      />
    </Styled.StyledLink>
  );
};

export default DrawerUpperContent;
