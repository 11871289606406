/**
 * @file Generate URL for the next search page
 */

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getSearchTextUrl } from '../../../routes/urls/search';
import useParamHotelId from '../../router/params/useParamHotelId';
import useParamSearchPage from '../../router/params/useParamSearchPage';
import useParamSearchTerm from '../../router/params/useParamSearchTerm';

import { Data, getPageDataSync } from './helpers';

/**
 * Generate URL for the next search page
 *
 * @param data The data fetched
 */
const useSearchPageSync = (data: Data): void => {
  const hotelId = useParamHotelId();
  const location = useLocation();
  const navigate = useNavigate();
  const pageUrl = useParamSearchPage();
  const searchTerm = useParamSearchTerm();

  const pageData = getPageDataSync(data, pageUrl);

  useEffect(() => {
    if (pageData === null || searchTerm === null) {
      return;
    }

    const to = getSearchTextUrl(hotelId, searchTerm, pageData, location.search);
    navigate(to, { replace: true });
  }, [hotelId, location.search, navigate, pageData, searchTerm]);
};

export default useSearchPageSync;
