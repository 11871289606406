/**
 * @file Get the role of the component (a dialog when it's a popup)
 */

import { AriaRole } from 'react';

import useIsMobile from '../../../../hooks/useIsMobile';

/**
 * Get the role of the component (a dialog when it's a popup)
 *
 * @param isPopup Whether the container is rendered as a popup
 * @returns       The role of the component
 */
export const useMetaContainerInlineRole = (
  isPopup: boolean,
): AriaRole | undefined => {
  const isMobile = useIsMobile();

  return isPopup === true && isMobile === false ? 'dialog' : undefined;
};
