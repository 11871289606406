import { de } from 'date-fns/locale/de';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import { nl } from 'date-fns/locale/nl';

import React, { FC } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import './styles.css';
import { getDateToUse } from '../../../utils/date/getDate';
import { getLanguageCode } from '../../../utils/user/language';

import * as Styled from './styled';

type Props = {
  dateCurrent: Date | null;
  dateMin: Date | null;
  shouldKeepTime?: boolean;
  onChange: (date: Date) => void;
};

// Since we're styling library-authored HTML,
// We will import its CSS, and then override what we want with ours.
// To be able to have multiple different looking date picker components,
// We'll wrap the imported picker with a div that has this class
// And then we'll prefix all selectors in styles.css with it.
const className = 'common_picker_date';

registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('it', it);
registerLocale('nl', nl);

/**
 * Common date picker component
 *
 * @param props                Props passed to the component
 * @param props.dateCurrent    The date to use
 * @param props.dateMin        Minimum allowed date
 * @param props.shouldKeepTime Whether to keep h:m:s.ms from dateCurrent (picking a date will set to midnight on that day otherwise)
 * @param props.onChange       Callback to be invoked when the user has changed the selected date
 * @returns                    The component itself
 */
const PickerDate: FC<Props> = ({
  dateCurrent,
  dateMin,
  shouldKeepTime = true,
  onChange,
}) => {
  /**
   * The user has picked a date, so we compensate the time if needed
   *
   * @param datePicked Midnight of the picked date
   */
  const handleChange = (datePicked: Date): void => {
    const dateNext = new Date(datePicked.getTime());
    const timeCurrent = getDateToUse(dateCurrent);

    // ReactDatePicker sets to midnight of the picked date by default
    if (shouldKeepTime) {
      dateNext.setHours(
        timeCurrent.getHours(),
        timeCurrent.getMinutes(),
        timeCurrent.getSeconds(),
        timeCurrent.getMilliseconds(),
      );
    }

    onChange(dateNext);
  };

  return (
    <Styled.Wrapper className={className}>
      <ReactDatePicker
        inline={true}
        // ReactDatePicker expects 2-letter codes,
        // which are available in language
        // whereas getLocaleCode returns in "en-GB" format
        locale={getLanguageCode()}
        minDate={dateMin}
        onChange={handleChange}
        selected={dateCurrent}
      />
    </Styled.Wrapper>
  );
};

export default PickerDate;
