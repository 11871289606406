import React, { FC } from 'react';

import { SearchInfo } from '../../../generated/graphql';

import * as Styled from './styled';

export type Props = {
  amount: SearchInfo['total'];
  className?: string;
  label: string;
  url?: string;
};
/**
 * Component that displays label and amount of a single dashboard KPI
 *
 * @param props           Props passed to the component
 * @param props.amount    Amount of tasks, requests or concierges that needs to be displayed beside KPI's label
 * @param props.className styled-components generated class name, needed for styling
 * @param props.label     Name of KPI to be displayed
 * @param props.url       Url to link to
 * @returns               The component itself
 */
const KpiSingle: FC<Props> = ({ amount, className, label, url }) => {
  const content = (
    <>
      {label} <Styled.Number> {amount}</Styled.Number>
    </>
  );

  if (url === undefined) {
    return <Styled.KpiSingle className={className}>{content}</Styled.KpiSingle>;
  }

  return (
    <Styled.KpiSingleLink className={className} to={url}>
      {content}
    </Styled.KpiSingleLink>
  );
};
export default KpiSingle;
