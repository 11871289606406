/**
 * @file helper functions for MessageSingle component
 */
import translate from '../../../i18n/translate';
import { MessageCommon } from '../../../models/message';
import intlPluralFormatter from '../../../utils/intlPluralFormat';

export type WrapperProps = {
  'data-is-expanded': boolean;
  'data-is-mobile': boolean;
  'data-should-dim': boolean;
  'data-should-focus': boolean;
  'data-should-hide-border-bottom': boolean;
};

export type ContentProps = {
  'data-is-full-width': boolean;
};

/**
 * Get message background color
 *
 * @param props Props passed to the component
 * @returns     Color variable for background color
 */
export const getBackgroundColor = (props: WrapperProps): string => {
  if (props['data-should-focus']) {
    return 'var(--color-hover-light)';
  }

  if (props['data-is-expanded']) {
    return props['data-is-mobile']
      ? 'var(--color-background)'
      : 'var(--color-hover-light)';
  }

  return 'transparent';
};

/**
 * Generate a plurality string out of assignedGroups array
 *
 * @param groups The data to generate the text for
 * @returns      The generated text
 */
export const getGroupsPlural = (
  groups: MessageCommon['assignedGroups'],
): string => {
  return intlPluralFormatter(groups.map(group => group.title));
};

/**
 * Generate n group/s text for assignedGroups part of the message info
 *
 * @param numberOfGroups The data to generate the text for
 * @returns              The generated text
 */
export const getGroupsText = (numberOfGroups: number): string => {
  const groupsText =
    numberOfGroups > 1
      ? translate('MESSAGE__INFO__GROUPS')
      : translate('MESSAGE__INFO__GROUP');
  return `${numberOfGroups} ${groupsText}`;
};
