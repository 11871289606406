import { Avatar, Badge, Tooltip, useTheme } from '@mui/material';
import React, { FC, useRef } from 'react';

import { PREFERENCE_BADGE_LIMIT } from '../../../../../constants/preferences';
import useIsOverflowed from '../../../../../hooks/useIsOverflowed';
import useScrollIntoView from '../../../../../hooks/useScrollIntoView';
import { getPolymorphicLinkProps } from '../../../../../utils/mui';

import MuiIcon from '../../../MuiIcon';
import { IconName } from '../../../MuiIcon/mapping';

import * as Styled from './styled';

export type Props = {
  avatarSrc?: string;
  icon?: IconName;
  href: string;
  isExternalLink?: boolean;
  isSelected?: boolean;
  label: string;
  notificationsNumber?: number;
  shouldReloadDocument?: boolean;
};

/**
 * List item for left sidebar opened content that wraps content in the link
 *
 * @param props                      Props passed to the component
 * @param props.avatarSrc            Source url for avatar
 * @param props.icon                 Icon to display if avatar is not available
 * @param props.href                 Url for the link
 * @param props.isExternalLink       Whether the link is external
 * @param props.isSelected           Whether the entry is selected
 * @param props.label                Label to display in item
 * @param props.notificationsNumber  Number of notifications
 * @param props.shouldReloadDocument Whether the document should be reloaded
 * @returns                          The component itself
 */
const DrawerEntry: FC<Props> = ({
  avatarSrc,
  icon,
  href,
  isExternalLink = false,
  isSelected = false,
  label,
  notificationsNumber = 0,
  shouldReloadDocument = false,
}) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const isOverflowed = useIsOverflowed(labelRef);

  const theme = useTheme();
  // Set scroll delay to match opening drawer content transition duration
  const setRef = useScrollIntoView(theme.transitions.duration.enteringScreen);

  const shouldDisableListeners = isOverflowed === false;

  /**
   * Get the visual content to render on the left side of the drawer entry.
   * Can be an icon or an avatar.
   *
   * @returns Content to render ont the left side of the drawer entry
   */
  const renderVisual = () => {
    if (avatarSrc === undefined && icon === undefined) {
      return null;
    }

    return (
      <Styled.MuiListItemAvatar>
        <Avatar src={avatarSrc}>
          {icon !== undefined && <MuiIcon icon={icon} />}
        </Avatar>
      </Styled.MuiListItemAvatar>
    );
  };

  const linkProps = getPolymorphicLinkProps({
    isExternal: isExternalLink,
    reloadDocument: shouldReloadDocument,
    url: href,
  });

  return (
    <Styled.MuiListItem
      aria-current={isSelected === true}
      ref={isSelected === true ? setRef : null}
      secondaryAction={
        <Badge
          badgeContent={notificationsNumber}
          color="error"
          max={PREFERENCE_BADGE_LIMIT}
        />
      }
    >
      <Tooltip
        disableFocusListener={shouldDisableListeners}
        disableHoverListener={shouldDisableListeners}
        enterTouchDelay={0}
        placement="right"
        title={label}
      >
        <Styled.MuiListItemButton {...linkProps}>
          {renderVisual()}
          <Styled.MuiListItemText primary={label} ref={labelRef} />
        </Styled.MuiListItemButton>
      </Tooltip>
    </Styled.MuiListItem>
  );
};

export default DrawerEntry;
