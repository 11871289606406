import { styled } from 'styled-components';

// The entire member list
export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
`;

// Wrapper around each member row
export const Li = styled.li`
  display: flex;
  margin-bottom: var(--spacing-small);
  width: 100%;
`;
