/**
 * @file contains helper functions for fileUpload hook
 */

import { Dispatch } from 'react';

import { UseMutationExecute } from 'urql';

import {
  File as CustomFile,
  FilesAddToMessageMutation,
  Message,
} from '../../generated/graphql';
import {
  AttachmentDraft,
  AttachmentDraftPreUploadMapEntry,
  PresignedPostData,
} from '../../models/attachment';
import { DraftActionAttachment } from '../../models/draftAttachment';
import uploadFile from '../../services/uploadFile';
import {
  addDraftAttachmentRequest,
  removeDraftAttachmentRequest,
} from '../../store/actions/draftsAttachments';

import { reportApiErrors } from '../../utils/error';

import { getFileExtension } from './helpers';

/**
 * Add attachment to the store draft
 *
 * @param attachment Attachment file that we want to add
 * @param dispatch   Redux dispatch callback
 * @param parentId   The parent ID of current draft attachments local state
 */
export const requestAttachmentAdd = (
  attachment: AttachmentDraft,
  dispatch: Dispatch<DraftActionAttachment>,
  parentId: string,
) => {
  dispatch(addDraftAttachmentRequest(parentId, attachment));
};

/**
 * Remove attachment from the store draft
 *
 * @param dispatch Redux dispatch callback
 * @param key      Key of an attachment we want to remove
 * @param parentId The parent ID of current draft attachments local state
 */
export const requestAttachmentRemove = (
  dispatch: Dispatch<DraftActionAttachment>,
  key: CustomFile['key'],
  parentId: string,
) => {
  dispatch(removeDraftAttachmentRequest(parentId, key));
};

/**
 * Parse post fields from AWS S3
 *
 * @param postFields Post fields from AWS S3 used for attachment upload
 *
 * @returns          Parsed post fields
 */
export const parsePostFields = (postFields: string[]): PresignedPostData[] => {
  return postFields.map(
    filePostFields => JSON.parse(filePostFields) as PresignedPostData,
  );
};

/**
 * Create attachment from JS File objects
 *
 * @param files      Array of Files
 * @param postFields Post fields from AWS S3 used for attachment upload
 *
 * @returns          Mapp of attachments
 */
export const createAttachmentsFromFiles = (
  files: File[],
  postFields: string[],
) => {
  const parsedPostData = parsePostFields(postFields);

  return files.reduce((map, file, index) => {
    map.set(parsedPostData[index].fields.key, {
      extension: getFileExtension(file.name),
      file: file,
      isDraft: true,
      isUploading: true,
      name: file.name,
      postData: parsedPostData[index],
    });
    return map;
  }, new Map<CustomFile['key'], AttachmentDraftPreUploadMapEntry>());
};

/**
 * Create attachment drafts and add upload/add to message functions to Promise array
 *
 * @param attachments Message attachments
 * @param dispatch    Redux dispatch callback
 * @param parentId    The parent of current draft attachments local state
 *
 * @returns           Array of promises with file upload
 */
export const prepareAttachmentsForUpload = (
  attachments: Map<string, AttachmentDraftPreUploadMapEntry>,
  dispatch: Dispatch<DraftActionAttachment>,
  parentId: string,
): Promise<void | Response | undefined>[] => {
  const filesUpload: Promise<void | Response | undefined>[] = [];

  attachments.forEach(attachment => {
    const attachmentDraft: AttachmentDraft = {
      ...attachment,
      key: attachment.postData.fields.key,
    };

    requestAttachmentAdd(attachmentDraft, dispatch, parentId);

    filesUpload.push(
      uploadFile(attachment)
        .then(() => {
          requestAttachmentAdd(
            { ...attachmentDraft, isUploading: false },
            dispatch,
            parentId,
          );
        })
        .catch(reportApiErrors),
    );
  });

  return filesUpload;
};

/**
 * Upload attachments to AWS S3 bucket
 *
 * @param attachments               Message attachments
 * @param dispatch                  Redux dispatch callback
 * @param filesUpload               Array of promises with file upload
 * @param filesAddToMessageMutation Mutation for adding uploaded attachments to message
 * @param messageId                 ID of the current message
 * @param parentId                  The parent ID of current draft attachments local state
 */
export const uploadAttachments = async (
  attachments: Map<string, AttachmentDraftPreUploadMapEntry>,
  dispatch: Dispatch<DraftActionAttachment>,
  filesUpload: Promise<void | Response | undefined>[],
  filesAddToMessageMutation: UseMutationExecute<FilesAddToMessageMutation>,
  messageId: Message['id'] | undefined,
  parentId: string,
) => {
  // Upload attachments to AWS S3 bucket
  await Promise.all(filesUpload).finally(() => {
    const keys = Array.from(attachments, ([key]) => key);

    // If message exists add uploaded attachments to it
    // (if not user is creating a new message and attachments are added to it in create mutation)
    if (messageId !== undefined) {
      filesAddToMessageMutation({
        keys,
        messageId,
      })
        .then(() => {
          keys.forEach(key => requestAttachmentRemove(dispatch, key, parentId));
        })
        .catch(reportApiErrors);
    }
  });
};
