import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorWrapper from '../../../components/Errors/ErrorWrapper';

import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import translate from '../../../i18n/translate';

/**
 * NoMatch (404) page
 *
 * @returns NoMatch (404) page component
 */
const PageNoMatch: FC = () => {
  const navigate = useNavigate();

  /**
   * Reroutes to / page
   */
  const rerouteToIndex = () => {
    navigate('/');
  };

  return (
    <ErrorWrapper
      actionButton={{
        onClick: rerouteToIndex,
        text: translate('ERROR__404__ACTION'),
      }}
      description={translate('ERROR__404__DESCRIPTION')}
      illustrationName={ILLUSTRATION__SEARCH}
      title={translate('GENERAL__OOPS')}
    />
  );
};

export default PageNoMatch;
