/**
 * @file Handle topic update mutation by updating the cache
 */
import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicUpdateMutation,
  TopicUpdateMutationVariables,
} from '../../../../../generated/graphql';
import topicUpdateAll from '../utils/updaters/topics/topicUpdateAll';

/**
 * Handle topic update mutation by updating the cache
 *
 * @param result The result of the mutation
 * @param args   Arguments of a graphQL operation
 * @param cache  URQL graphQL cache
 * @param info   Additional resolver info
 */
const topicUpdate: UpdateResolver<
  TopicUpdateMutation,
  TopicUpdateMutationVariables
> = (result, args, cache, info): void => {
  topicUpdateAll(result, args, cache, info);
};

export default topicUpdate;
