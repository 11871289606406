import { Droppable } from '@hello-pangea/dnd';
import { Typography } from '@mui/material';
import React, { FC, useId, useRef } from 'react';

import { PAGINATION__MAX_ITEMS_PER_PAGE } from '../../constants/forms';
import { MESSAGE_STATUS_TRANSLATION_KEY } from '../../constants/message';
import { MessageStatus } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { MessageFeed } from '../../models/message';
import TasksSkeleton from '../Loading/Tasks';
import Task from '../Task';

import * as Styled from './styled';

export type Props = {
  isLoading: boolean;
  status: MessageStatus;
  tasks: MessageFeed[];
  totalTasks: number;
};

/**
 * Task section with DnD
 *
 * @todo Unsupported nested scroll container detected warning (shown only in dev env).
 * @see https://github.com/atlassian/react-beautiful-dnd/issues/131
 *
 * @param props            Props passed to the component
 * @param props.isLoading  Whether the data is fetching
 * @param props.status     The status that this section belongs to
 * @param props.tasks      List of tasks that belong to this section
 * @param props.totalTasks Total tasks that are within the corresponding status
 * @returns                The component itself
 */
const TaskSection: FC<Props> = ({ isLoading, status, tasks, totalTasks }) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  // We don't use `totalTasks - PAGINATION__MAX_ITEMS_PER_PAGE` here because
  // newly created tasks are not hidden
  const hiddenTasksCount = totalTasks - tasks.length;

  const id = useId();
  const tasksCountText =
    totalTasks > PAGINATION__MAX_ITEMS_PER_PAGE
      ? `${PAGINATION__MAX_ITEMS_PER_PAGE}+`
      : totalTasks;
  const title = translate(MESSAGE_STATUS_TRANSLATION_KEY[status]);

  /**
   * Scrolls the section to the top
   */
  const requestScrollToTop = () => {
    scrollableRef.current?.scroll({ behavior: 'smooth', top: 0 });
  };

  const content =
    isLoading === true ? (
      <TasksSkeleton />
    ) : (
      tasks.map((task, index) => (
        <Task index={index} key={task.id} task={task} />
      ))
    );

  return (
    <Styled.Section aria-labelledby={id} component="section" role="feed">
      <Styled.SectionHeader
        component="button"
        id={id}
        onClick={requestScrollToTop}
        type="button"
      >
        {title}
        {isLoading === false && <span>{tasksCountText}</span>}
      </Styled.SectionHeader>

      <Droppable droppableId={status}>
        {(provided, snapshot) => (
          // If we want to have drag & scroll on mobile, the scrollable
          // element must be wrapped with a non-scrollable element
          <>
            <Styled.DroppableContent
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Styled.ScrollArea ref={scrollableRef}>
                {content}
                {provided.placeholder}
                {hiddenTasksCount > 0 && (
                  <Styled.HiddenTasksWrapper component="footer">
                    <Typography>
                      {translate('KANBAN__PAGINATION__END', [
                        hiddenTasksCount.toString(),
                      ])}
                    </Typography>
                  </Styled.HiddenTasksWrapper>
                )}
              </Styled.ScrollArea>
            </Styled.DroppableContent>
            {snapshot.isDraggingOver === true && <Styled.DragOverlay />}
          </>
        )}
      </Droppable>
    </Styled.Section>
  );
};

export default TaskSection;
