/**
 * @file Contains hook that returns information about the left sidebar open state
 */
import { useLocation } from 'react-router-dom';

import { getSearchParamSidebar } from '../../../routes/helpers/searchParams';

/**
 * Hook for getting the information about the left sidebar open state
 *
 * @returns Whether left sidebar is open
 */
const useIsLeftSidebarOpen = () => {
  const location = useLocation();
  const isOpen = getSearchParamSidebar(location) !== null;

  return isOpen;
};

export default useIsLeftSidebarOpen;
