/**
 * @file All permissions checks related to checklist item creation
 */

import { MessageCreatorAndAssigned } from '../../../models/message';
import { getIsAssigned, getIsAuthor } from '../messageHelper';

import { CHECKLIST__CREATE } from '../permissionsAll';

import { getIsAdmin, hasPermission } from './checklistHelper';

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user has permissions
 * to add a checklist item to a new message
 *
 * @returns Whether the user has permissions
 */
export const getCanCreateItemForNewMessage = (): boolean => {
  // If the user can create a message, they can also add checklist items
  return true;
};

/**
 * Check whether the user has permissions
 * to add a checklist item to an existing message
 *
 * @param message The message to check the permission for
 * @returns       Whether the user has permissions
 */
export const getCanCreateItemForExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(CHECKLIST__CREATE) === false) {
    return false;
  }

  return getIsAuthor(message) || getIsAssigned(message);
};
