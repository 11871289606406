import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import AssigneesGroupsLoading from '../AssignmentGroups';
import SkeletonChecklist from '../Checklist';
import SkeletonLabelList from '../LabelList';
import SkeletonAvatar from '../SkeletonAvatar';
import SkeletonProgress from '../SkeletonProgress';

import * as Styled from './styled';

/**
 * Skeleton for the full message details, used for the right sidebar
 *
 * @returns The component itself
 */
const SkeletonMessageFull: FC = () => {
  return (
    <>
      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('GENERAL__TITLE')}
        </Styled.SectionHeading>
        <Styled.Title />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('GENERAL__DESCRIPTION')}
        </Styled.SectionHeading>
        <Styled.Description />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__ASSIGNEES')}
        </Styled.SectionHeading>

        <Styled.Assignees>
          <Styled.AssigneesItem>
            <SkeletonAvatar size={30} />
          </Styled.AssigneesItem>
          <Styled.AssigneesItem>
            <SkeletonAvatar size={30} />
          </Styled.AssigneesItem>
          <Styled.AssigneesItem>
            <SkeletonAvatar size={30} />
          </Styled.AssigneesItem>
          <Styled.AssigneesItem>
            <SkeletonAvatar size={30} />
          </Styled.AssigneesItem>
        </Styled.Assignees>

        <AssigneesGroupsLoading nrGroups={2} />
      </Styled.SectionWrapper>

      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__DATE_DUE')}
        </Styled.SectionHeading>

        <Styled.DateDue />
      </Styled.SectionWrapper>

      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__CHECKLIST')}

          <SkeletonProgress />
        </Styled.SectionHeading>

        <SkeletonChecklist nrItems={2} />
      </Styled.SectionWrapper>

      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__LABELS')}
        </Styled.SectionHeading>

        <SkeletonLabelList nrLabels={2} />
      </Styled.SectionWrapper>

      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__READ_BY')}
        </Styled.SectionHeading>

        <SkeletonProgress />
      </Styled.SectionWrapper>

      <Styled.SectionWrapper>
        <Styled.SectionHeading>
          {translate('MESSAGE__HEADING__AUTHOR')}
        </Styled.SectionHeading>

        <Styled.CreatorWrapper>
          <SkeletonAvatar size={38} />
          <Styled.CreationDate />
        </Styled.CreatorWrapper>
      </Styled.SectionWrapper>
    </>
  );
};

export default SkeletonMessageFull;
