/**
 * @file contains hook that handles clicking outside of ref to sidebars
 */

import { RefObject } from 'react';

import useClickOutside from '../../../hooks/useClickOutside';

type Props = {
  rightSidebarOptions?: {
    onClick: () => void;
    stopExec: boolean;
  };
  rightSidebarRef: RefObject<HTMLDivElement>;
};

/**
 * Custom hook that handles click outside of sidebar refs
 *
 * @param props                     Props passed to the hook
 * @param props.rightSidebarOptions Options passed to the useClickOutside hook for right sidebar
 * @param props.rightSidebarRef     Ref that we listen to for outside clicks (right sidebar)
 */
const useSidebarsClickOutside = ({
  rightSidebarOptions,
  rightSidebarRef,
}: Props) => {
  useClickOutside({
    onClick: rightSidebarOptions?.onClick,
    ref: rightSidebarRef,
    stopExec: rightSidebarOptions ? rightSidebarOptions.stopExec : true,
  });
};

export default useSidebarsClickOutside;
