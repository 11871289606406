/**
 * @file Hook that tells us whether the quickFilters are active
 */
import { SEARCH_PARAM__FILTER__QUICK } from '../../../constants/routing/searchParams';

import useSearchParams from './useSearchParams';

/**
 * Hook that tells us whether the quickFilters are active
 * based on the searchParams
 *
 * @returns Whether the quickFilters are active
 */
const useIsFilterQuickActive = (): boolean => {
  const query = useSearchParams();

  const quickFilterId = query.get(SEARCH_PARAM__FILTER__QUICK);

  return quickFilterId !== null;
};

export default useIsFilterQuickActive;
