import { Editor } from '@tiptap/react';
import React, { ChangeEventHandler, FC, ReactNode, useId } from 'react';

import useIsMobile from '../../../../../hooks/useIsMobile';
import { InputFileProps } from '../../../../../models/attachment';
import { configurations } from '../../configurations';
import { ButtonEnabled, EditorUsage, StyleMethod } from '../../types';
import ButtonAttachFile from '../ButtonAttachFile';

import ButtonStyleListBullets from '../ButtonStyleListBullets';
import ButtonStyleListNumbers from '../ButtonStyleListNumbers';
import ButtonStyleTextBold from '../ButtonStyleTextBold';
import ButtonStyleTextItalic from '../ButtonStyleTextItalic';
import ButtonStyleTextStrikethrough from '../ButtonStyleTextStrikethrough';

import * as Styled from './styled';

type Props = {
  editor: Editor;
  inputFileProps?: InputFileProps;
  onToggleStyles: (state: boolean) => void;
  usage: EditorUsage;
};

/**
 * Editor controls default version
 *
 * @param props                The props passed to the component
 * @param props.editor         The instance of the tiptap editor
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @param props.onToggleStyles Callback to be invoked when tools are toggled
 * @param props.usage          What the editor is used for
 * @returns                    The component itself
 */
const ControlsDefault: FC<Props> = ({
  editor,
  inputFileProps,
  onToggleStyles,
  usage,
}) => {
  const inputId = useId();
  const isMobile = useIsMobile();

  /**
   * Focus current method
   *
   * @param method All available actions from tiptap
   */
  const onStyle = (method: StyleMethod) => {
    editor.chain().focus()[method]().run();
  };

  /**
   * Toggle Text Bold control
   *
   * @returns onStyle callback
   */
  const onTextStyleBold = () => onStyle('toggleBold');
  /**
   * Toggle Text Italic control
   *
   * @returns onStyle callback
   */
  const onTextStyleItalic = () => onStyle('toggleItalic');
  /**
   * Toggle Text Strike control
   *
   * @returns onStyle callback
   */
  const onTextStyleStrikethrough = () => onStyle('toggleStrike');
  /**
   * Toggle Text Bullet List control
   *
   * @returns onStyle callback
   */
  const onTextListBullets = () => onStyle('toggleBulletList');
  /**
   * Toggle Text Ordered List control
   *
   * @returns onStyle callback
   */
  const onTextListNumbers = () => onStyle('toggleOrderedList');

  const configuration = configurations[usage].buttons;

  /**
   * The user has toggled style buttons,
   * and based on that we hide or show attachment buttons
   *
   * @param event The event that took place
   */
  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const state = event.currentTarget.checked;
    onToggleStyles(state);
  };

  const buttonsAll: Record<ButtonEnabled, ReactNode> = {
    buttonSend: null,
    textBold: (
      <ButtonStyleTextBold
        isActive={editor.isActive('bold')}
        onClick={onTextStyleBold}
      />
    ),
    textItalic: (
      <ButtonStyleTextItalic
        isActive={editor.isActive('italic')}
        onClick={onTextStyleItalic}
      />
    ),
    textStrikethrough: (
      <ButtonStyleTextStrikethrough
        isActive={editor.isActive('strike')}
        onClick={onTextStyleStrikethrough}
      />
    ),
    // Not sorting alphabetically because the definition order is the visual order
    // eslint-disable-next-line sort-keys
    listBullets: (
      <ButtonStyleListBullets
        isActive={editor.isActive('bulletList')}
        onClick={onTextListBullets}
      />
    ),
    listNumbers: (
      <ButtonStyleListNumbers
        isActive={editor.isActive('orderedList')}
        onClick={onTextListNumbers}
      />
    ),
    // Not sorting alphabetically because the definition order is the visual order
    // eslint-disable-next-line sort-keys
    buttonAttach: inputFileProps ? (
      <ButtonAttachFile inputFileProps={inputFileProps} />
    ) : null,
  };

  return (
    <>
      {isMobile && usage !== 'commentExternal' && (
        <>
          <Styled.Toggle id={inputId} onChange={onChange} type="checkbox" />
          <Styled.Label htmlFor={inputId}>{'Aa'}</Styled.Label>
        </>
      )}

      <Styled.Ul>
        {Object.entries(buttonsAll).map(([key, value]: [string, ReactNode]) => {
          if (
            configuration.has(key as ButtonEnabled) === false ||
            value === null
          ) {
            return null;
          }

          return <Styled.Li key={key}>{value}</Styled.Li>;
        })}
      </Styled.Ul>
    </>
  );
};

export default ControlsDefault;
