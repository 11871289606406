import { styled } from 'styled-components';

import CommonEditor from '../../Common/Editor';

// The entire compose form
export const Form = styled.form`
  border-radius: var(--border-radius-small);
  margin-top: var(--spacing-small);

  /* Fixes a scrollbar issue on mobile */
  position: relative;
`;

// The whole text editor
export const Editor = styled(CommonEditor)`
  background-color: var(--color-background);
`;
