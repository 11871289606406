import { Tooltip } from '@mui/material';
import React, { FC, useId, useRef } from 'react';

import useIsOverflowed from '../../../../hooks/useIsOverflowed';
import { hotelGetImageUrl } from '../../../../utils/imageUrlGet';

import MuiIcon from '../../MuiIcon';
import { NavItem } from '../types';

import * as Styled from './styled';

export type Props = {
  hasMoreHotels: boolean;
  imageUrl?: string | null;
  isSelected: boolean;
  item: NavItem;
};

/**
 * Rail list item that opens hotel(object) selection list
 *
 * @param props               Props for the hotel selection list item
 * @param props.hasMoreHotels Whether user has more hotels
 * @param props.imageUrl      Hotel image url
 * @param props.isSelected    Whether the list item is selected
 * @param props.item          Item made from hotel info to match the type
 * @returns                   The component itself
 */
const RailListItemHotel: FC<Props> = ({
  hasMoreHotels,
  imageUrl,
  isSelected,
  item,
}) => {
  const labelRef = useRef<HTMLDivElement>(null);

  const { icon, label, to } = item;
  const ariaLabelledBy = useId();
  const isOverflowed = useIsOverflowed(labelRef);
  const shouldDisableListeners = isOverflowed === false;
  const src = hotelGetImageUrl(imageUrl);

  const itemContent = (
    <>
      <Styled.HotelAvatar
        alt={label}
        aria-current={isSelected === true}
        aria-labelledby={ariaLabelledBy}
        src={src}
      >
        <MuiIcon icon={icon} />
      </Styled.HotelAvatar>
      <Styled.RailListItemLabel
        // Prevents wrapping child in typography
        disableTypography={true}
        id={ariaLabelledBy}
        ref={labelRef}
      >
        {label}
      </Styled.RailListItemLabel>
    </>
  );

  return (
    <Tooltip
      disableFocusListener={shouldDisableListeners}
      disableHoverListener={shouldDisableListeners}
      enterTouchDelay={0}
      placement="right"
      role="tooltip"
      title={label}
    >
      <Styled.HotelListItem disablePadding={true}>
        {hasMoreHotels === true ? (
          <Styled.StyledLink
            aria-current={isSelected === true ? 'page' : false}
            replace={true}
            to={to}
          >
            {itemContent}
          </Styled.StyledLink>
        ) : (
          <Styled.StyledHotelWrapper>{itemContent}</Styled.StyledHotelWrapper>
        )}
      </Styled.HotelListItem>
    </Tooltip>
  );
};

export default RailListItemHotel;
