import React, { FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { Label, LabelBasicFragment } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import intlPluralFormatter from '../../../utils/intlPluralFormat';
import LabelPicker from '../../LabelPicker';
import MetaContainerInline, {
  Props as PropsInline,
} from '../../MetaContainerInline';
import MetaContainerPopup, {
  Props as PropsPopup,
} from '../../MetaContainerPopup';

type Props = {
  className?: string;
  dataAll: LabelBasicFragment[];
  dataSaved: Set<Label['id']>;
  dataSelected: Set<Label['id']>;
  headerAction: (() => void) | null;
  inputCustom?: ReactNode;
  isActive: boolean;
  isLoading: boolean;
  labelAction: string;
  labelClose: string;
  labelConfirm: string;
  linkToggleProps?: LinkProps;
  onClose?: () => void;
  onSave: () => void;
  requestModeEdit: ((labelId: Label['id']) => void) | null;
  requestToggle: ((labelId: Label['id'], state: boolean) => void) | null;
  textPlaceholder?: string;
};

/**
 * Labels portion for the compose block
 *
 * @param props                 Props passed to the component
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.dataAll         All available labels
 * @param props.dataSaved       The labels already saved
 * @param props.dataSelected    Which labels are already selected for the message
 * @param props.headerAction    Callback to be invoked on action (eg. add new, remove, etc.)
 * @param props.inputCustom     Custom component to be used for displaying the (saved) value
 * @param props.isActive        Whether the component should be visible
 * @param props.isLoading       Whether the data is still being loaded
 * @param props.labelAction     The text to show for action label
 * @param props.labelClose      The text to display for closing the popup
 * @param props.labelConfirm    The text to display for saving the attached labels
 * @param props.linkToggleProps Props for the toggle link
 * @param props.onClose         Request to close label view
 * @param props.onSave          Request that the current labels state is saved
 * @param props.requestModeEdit Callback to be invoked when the user clicks the label edit button
 * @param props.requestToggle   Callback to be invoked when a label is a/detached
 * @param props.textPlaceholder The text to show in the input, if dataSaved.length === 0
 * @returns                     The component itself
 */
const MetaLabelsView: FC<Props> = ({
  className,
  headerAction,
  dataAll,
  dataSaved,
  dataSelected,
  inputCustom,
  isActive,
  isLoading,
  labelAction,
  labelClose,
  labelConfirm,
  linkToggleProps = null,
  onClose,
  onSave,
  requestModeEdit,
  requestToggle,
  textPlaceholder = '',
}) => {
  /**
   * We need to check if currently selected labels are equal to draft/saved labels
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled =
    dataSelected.size === dataSaved.size &&
    Array.from(dataSelected).every(value => dataSaved.has(value));

  const idForm = useId();
  const idHeading = useId();

  const textHeading = translate('COMPOSE__PLACEHOLDER__LABEL');

  const labelsSaved = dataAll.filter(label => dataSaved.has(label.id));
  const titlesSaved = labelsSaved.map(label => label.text);

  const children = (
    <LabelPicker
      dataAll={dataAll}
      dataSelected={dataSelected}
      idForm={idForm}
      isLoading={isLoading}
      requestModeEdit={requestModeEdit}
      requestSave={onSave}
      requestToggle={requestToggle}
    />
  );

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    action: headerAction
      ? {
          label: labelAction,
          onClick: headerAction,
        }
      : null,
    className,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose,
    textHeading,
  };

  if (linkToggleProps === null) {
    return (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    );
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={intlPluralFormatter(titlesSaved)}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaLabelsView;
