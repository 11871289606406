import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from 'urql';

import ComposeMetaLabels from '../../../components/Compose/Common/ComposeMeta/ComposeMetaLabels';
import MetaLabels from '../../../components/MetaLabels';
import useLabelsMode from '../../../components/MetaLabels/useLabelsMode';
import {
  Label,
  LabelCreateDocument,
  LabelsDocument,
  LabelUpdateDocument,
} from '../../../generated/graphql';
import { ComposeModeParam } from '../../../models/pageParams';
import { reportApiErrors } from '../../../utils/error';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Metadata for a message, render labels
 *
 * @param props                    Props passed to the component
 * @param props.dataSaved          Labels already saved to the draft
 * @param props.dataSelected       Labels currently selected (not yet saved)
 * @param props.debugLoading       Whether to permanently enable loading state
 * @param props.requestLabelAdd    Request that a label is added to selection
 * @param props.requestLabelRemove Request that a label is removed from selection
 * @param props.requestLabelsSave  Request that dataSelected is stored in dataSaved
 * @returns                        The component itself
 */
const ComposeMetaLabelsContainer: FC<Props> = ({
  dataSaved,
  dataSelected,
  debugLoading,
  requestLabelAdd,
  requestLabelRemove,
  requestLabelsSave,
}) => {
  const composeMode: ComposeModeParam = 'label';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(composeMode);

  const [dataCurrent, mode, { setModeCreate, setModeEdit, setModeView }] =
    useLabelsMode('compose');

  const [{ data, fetching }] = useQuery({
    query: LabelsDocument,
    variables: {},
  });
  const [, labelCreateMutation] = useMutation(LabelCreateDocument);
  const [, labelUpdateMutation] = useMutation(LabelUpdateDocument);

  const dataAll = data?.labels ?? [];
  const labelsExisting = dataAll.map(label => label.text);
  const labelsSaved = dataAll.filter(label => dataSaved.has(label.id));

  /**
   * Request that a label is created
   *
   * @param text  The label text
   * @param color The label color
   */
  const requestCreate = (text: Label['text'], color: Label['color']) => {
    labelCreateMutation({ color, text }).catch(reportApiErrors);
  };

  /**
   * Request that a label is updated
   *
   * @param id    The id of the label to update
   * @param text  The updated label text
   * @param color The updated label color
   */
  const requestUpdate = (
    id: Label['id'],
    text: Label['text'],
    color: Label['color'],
  ) => {
    labelUpdateMutation({ color, id, text }).catch(reportApiErrors);
  };

  return (
    <MetaLabels
      dataAll={dataAll}
      dataCurrent={dataCurrent}
      dataSaved={dataSaved}
      dataSelected={dataSelected}
      inputCustom={<ComposeMetaLabels labels={labelsSaved} />}
      isActive={isActive}
      isLoading={debugLoading || fetching}
      labelsExisting={labelsExisting}
      linkToggleProps={linkToggleProps}
      mode={mode}
      requestCreate={requestCreate}
      requestModeCreate={setModeCreate}
      requestModeEdit={setModeEdit}
      requestModeView={setModeView}
      requestSave={() => {
        requestLabelsSave();
        goBack();
      }}
      requestToggle={(labelId, state) => {
        if (state) {
          requestLabelAdd(labelId);
        } else {
          requestLabelRemove(labelId);
        }
      }}
      requestUpdate={requestUpdate}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaLabelsContainer);
