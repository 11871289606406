import React, { FC, useState } from 'react';

import { Mode } from '../../../models/recurringMain';
import {
  dateFormatInput,
  dateFormatUi,
  timeFormatInput,
  timeFormatUi,
} from '../../../utils/date/format';
import { getDateToUse } from '../../../utils/date/getDate';

import PickerDate from '../PickerDate';
import PickerTime from '../PickerTime';

import PickerDateTimeHeader from './PickerDateTimeHeader';

import * as Styled from './styled';

export type Props = {
  dateCurrent: Date | null;
  dateMin?: Date | null;
  initialMode?: Mode;
  requestSet: (date: Date) => void;
  shouldShowHeader?: boolean;
};

/**
 * Date (and time) picker for the compose draft and recurring rules,
 * Contains regular text inputs for this,
 * But also a calendar and visual time picker below
 *
 * @param props                  Props passed to the component
 * @param props.dateCurrent      Currently selected date
 * @param props.dateMin          Earliest selectable date
 * @param props.initialMode      Which mode to show on initial render
 * @param props.requestSet       Callback to request that the date is set
 * @param props.shouldShowHeader Whether header should be rendered
 * @returns                      The component itself
 */
const PickerDateTime: FC<Props> = ({
  dateCurrent,
  dateMin = null,
  initialMode = 'DATE',
  requestSet,
  shouldShowHeader = true,
}) => {
  const dateUsed = getDateToUse(dateCurrent);

  const textDate = dateFormatUi(dateUsed);
  const textTime = timeFormatUi(dateUsed);

  // Date and time pickers occupy the same spot
  // But depending on the current mode, we EITHER show date or time picker
  const [mode, setMode] = useState<Mode>(initialMode);

  const valueDate = dateFormatInput(dateUsed);
  const valueTime = timeFormatInput(dateUsed);

  /**
   * The user has changed the selected date
   * (time is unchanged)
   *
   * @param value The date the user picked
   */
  const handleChangeDate = (value: string) => {
    const date = new Date(`${value} ${valueTime}`);
    if (Number.isNaN(date.getTime()) === false) {
      requestSet(date);
    }
  };

  /**
   * The user has picked a date,
   * so we store that in local state and request a redux update
   * Prevent the request when user tries to select the same date again
   *
   * @param date The date the user picked
   */
  const handlePickDate = (date: Date): void => {
    const selectedDate = dateFormatUi(date);

    if (selectedDate !== textDate) {
      requestSet(date);
    }
  };

  /**
   * The user has changed the selected time
   * (date is unchanged)
   *
   * @param value The date the user picked
   */
  const handleChangeTime = (value: string) => {
    const date = new Date(`${valueDate} ${value}`);
    if (Number.isNaN(date.getTime()) === false) {
      requestSet(date);
    }
  };

  /**
   * The user has updated the time,
   * so we store that in local state and request a redux update
   *
   * @param date The date the user picked
   */
  const handlePickTime = (date: Date): void => {
    requestSet(date);
  };

  return (
    <>
      {shouldShowHeader && (
        <PickerDateTimeHeader
          dateMin={dateMin}
          mode={mode}
          requestChangeDate={handleChangeDate}
          requestChangeTime={handleChangeTime}
          setMode={setMode}
          textDate={textDate}
          textTime={textTime}
          valueDate={valueDate}
        />
      )}

      <Styled.Contents>
        {mode === 'DATE' && (
          <PickerDate
            dateCurrent={dateCurrent}
            dateMin={dateMin}
            onChange={handlePickDate}
          />
        )}

        {mode === 'TIME' && (
          <PickerTime onChange={handlePickTime} time={dateUsed} />
        )}
      </Styled.Contents>
    </>
  );
};

export default PickerDateTime;
