// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-infinite-list__empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.roq-empty-state__inner__icon-wrapper {
  align-items: center;
  background-color: var(--color-blue-lightest);
  border: 1px solid var(--color-blue-lighter);
  border-radius: 50%;
  color: var(--color-background);
  display: flex;
  height: var(--size-no-messages-icon);
  justify-content: center;
  margin: auto;
  width: var(--size-no-messages-icon);
}

.roq-empty-state__inner__description {
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-tiny);
  margin-top: var(--spacing-medium);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_window-empty-state.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,4CAA4C;EAC5C,2CAA2C;EAC3C,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,oCAAoC;EACpC,uBAAuB;EACvB,YAAY;EACZ,mCAAmC;AACrC;;AAEA;EACE,6BAA6B;EAC7B,kCAAkC;EAClC,iCAAiC;AACnC","sourcesContent":[".roq-infinite-list__empty {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-align: center;\n}\n\n.roq-empty-state__inner__icon-wrapper {\n  align-items: center;\n  background-color: var(--color-blue-lightest);\n  border: 1px solid var(--color-blue-lighter);\n  border-radius: 50%;\n  color: var(--color-background);\n  display: flex;\n  height: var(--size-no-messages-icon);\n  justify-content: center;\n  margin: auto;\n  width: var(--size-no-messages-icon);\n}\n\n.roq-empty-state__inner__description {\n  font-weight: var(--font-bold);\n  margin-bottom: var(--spacing-tiny);\n  margin-top: var(--spacing-medium);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
