/**
 * @file Media queries for devices we want to support
 */

// Keys sorted by size, not alphabetically
/*  eslint-disable sort-keys  */
import breakpoints from '../constants/breakpoints';
import Device from '../models/device';

const devices: Record<Required<Device>, string> = {
  mobileS: `(min-width: ${breakpoints.mobileS}px)`,
  mobileM: `(min-width: ${breakpoints.mobileM}px)`,
  mobileL: `(min-width: ${breakpoints.mobileL}px)`,

  tabletS: `(min-width: ${breakpoints.tabletS}px)`,
  tabletM: `(min-width: ${breakpoints.tabletM}px)`,
  tabletL: `(min-width: ${breakpoints.tabletL}px)`,

  laptopS: `(min-width: ${breakpoints.laptopS}px)`,
  laptopM: `(min-width: ${breakpoints.laptopM}px)`,
  laptopL: `(min-width: ${breakpoints.laptopL}px)`,

  desktopS: `(min-width: ${breakpoints.desktopS}px)`,
  desktopM: `(min-width: ${breakpoints.desktopM}px)`,
  desktopL: `(min-width: ${breakpoints.desktopL}px)`,
};

export default devices;
