/**
 * @file contains hook that handles is user allowed to see link for reservations page
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import { ReservationStatusDocument } from '../generated/graphql';
import { canViewReservationsPage } from '../utils/permissions/reservations';

/**
 * Custom hook for deciding should user be able to see
 * link that leads to reservations page
 *
 * @returns Boolean that tells us should link be shown
 */
const useCanUserAccessReservations = (): boolean => {
  const hasPermission = canViewReservationsPage();

  const [shouldShow, setShouldShow] = useState(false);
  const [{ data, error, fetching }] = useQuery({
    pause: hasPermission === false,
    query: ReservationStatusDocument,
    variables: {},
  });

  // Data can be undefined
  const reservationStatus = data?.reservationStatus.connected ?? false;

  useEffect(() => {
    setShouldShow(reservationStatus && !error && hasPermission && !fetching);
  }, [reservationStatus, error, fetching, hasPermission]);

  return shouldShow;
};

export default useCanUserAccessReservations;
