import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const Link = styled(RouterLink)`
  background-color: transparent;
  border-radius: var(--border-radius-small);
  display: block;
  font-weight: var(--font-semibold);
  padding: var(--spacing-small);
  transition: background-color var(--preference-transition-duration-normal);

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-hover-light);
    }
  }

  &[aria-current='page'] {
    background-color: var(--color-background-selected);
  }
`;
