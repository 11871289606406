import { styled } from 'styled-components';

// Wrapper around the entire component
export const Wrapper = styled.div`
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  margin-bottom: var(--spacing-medium);
  margin-top: var(--spacing-small);
`;

// Wrapper around the table;
// Used because table border can't have border-radius
export const Content = styled.div`
  background-color: var(--color-background);
  box-sizing: content-box;
  min-height: 8rem;
  overflow: hidden;
  padding: var(--spacing-medium) 0;
  position: relative;
`;

export const Dl = styled.dl`
  margin: 0;
`;

// Single field within the form
export const Li = styled.li`
  margin-left: var(--spacing-medium);
`;
