/**
 * @file Remove a topic from the TopicList query
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  Topic,
  TopicSettingsDocument,
  TopicSettingsQuery,
  TopicSettingsQueryVariables,
  TopicsListDocument,
  TopicsListQuery,
  TopicsListQueryVariables,
} from '../../../../../../../generated/graphql';

type Callback = (cache: Cache, topicId: Topic['id']) => void;

/**
 * Remove a topic from the TopicList query
 *
 * @param cache   urql GraphQL cache
 * @param topicId The ID of the topic to remove from the TopicList query
 */
export const topicRemoveFromList: Callback = (cache, topicId) => {
  cache.updateQuery<TopicsListQuery, TopicsListQueryVariables>(
    {
      query: TopicsListDocument,
      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      data.topics = data.topics.filter(topic => topic.id !== topicId);

      return data;
    },
  );
};

/**
 * Remove a topic from the TopicSettings query
 *
 * @param cache   urql GraphQL cache
 * @param topicId The ID of the topic to remove from the TopicSettings query
 */
export const topicRemoveFromSettings: Callback = (cache, topicId) => {
  cache.updateQuery<TopicSettingsQuery, TopicSettingsQueryVariables>(
    {
      query: TopicSettingsDocument,
      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      data.topicsAll = data.topicsAll.filter(topic => topic.id !== topicId);

      return data;
    },
  );
};
