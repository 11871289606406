import React, { FC } from 'react';

import Icon, { NAMES } from '../Common/Icon';

import * as Styled from './styled';

type Props = {
  handleClick: () => void;
  url: string;
};

const composeButtonSize = 62;

/**
 * Button that opens compose popup on mobiles
 *
 * @param props             Props passed to component
 * @param props.url         Url to link to
 * @param props.handleClick Handle link click
 * @returns                 Button react component
 */
const OpenComposeButton: FC<Props> = ({ handleClick, url }) => {
  return (
    <Styled.ComposeLink
      data-size={composeButtonSize}
      onClick={handleClick}
      to={url}
    >
      <Icon
        height={composeButtonSize}
        name={NAMES.BOTTOM_NAV__COMPOSE}
        width={composeButtonSize}
      />
    </Styled.ComposeLink>
  );
};

export default OpenComposeButton;
