import React, { FC, ReactNode } from 'react';

import translate from '../../../../i18n/translate';
import { MessageCommon } from '../../../../models/message';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  elementId: string;
  labelKey: string;
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

/**
 * Shows current status of message
 *
 * @param props              Props passed to the component
 * @param props.children     Children passed to the component
 * @param props.className    styled-components generated class name, needed for styling
 * @param props.elementId    Id used for hash link
 * @param props.labelKey     Label used for hash link that will be translated
 * @param props.messageId    Message id
 * @param props.shouldBeLink Whether a message should be rendered as a link or not
 * @returns                  The component itself
 */
const MessageInfo: FC<Props> = ({
  children,
  className,
  elementId,
  labelKey,
  messageId,
  shouldBeLink,
}) => {
  return (
    <Styled.HashLink
      className={className}
      hashValue={`#${elementId}`}
      label={translate(labelKey)}
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      {children}
    </Styled.HashLink>
  );
};

export default MessageInfo;
