import { styled } from 'styled-components';

import CommonBadgeIndicator from '../../Common/BadgeIndicator';

export const Wrapper = styled.div`
  padding: var(--spacing-tiny) var(--spacing-small) 0;
  position: relative;
`;

export const BadgeIndicator = styled(CommonBadgeIndicator)`
  position: absolute;
  right: 0;
  top: 0;
`;
