import React, { FC, FormEventHandler } from 'react';
import { CombinedError } from 'urql';

import { TopicBasicFragment } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import ListSearch from '../../Compose/Common/ComposeListSearch';

type Props = {
  ariaDescribedBy: string;
  ariaLabelledBy: string;
  dataTopicAll: TopicBasicFragment[];
  dataTopicCurrent: TopicBasicFragment['id'] | null;
  dataSearchValue: string;
  error?: CombinedError;
  idForm: string;
  isLoading: boolean;
  onSearch: (term: string) => void;
  requestTopicSave: () => void;
  requestTopicChange: (topicId: TopicBasicFragment['id']) => void;
};

/**
 * Form to select topic for template
 *
 * @param props                    Props passed to the component
 * @param props.ariaDescribedBy    Accessible form description
 * @param props.ariaLabelledBy     Accessible form title
 * @param props.dataTopicAll       All topic groups
 * @param props.dataTopicCurrent   Currently selected topic
 * @param props.dataSearchValue    <input type="search" /> value attribute
 * @param props.error              Topics fetching error
 * @param props.idForm             The ID to use for the assignment <form /> element
 * @param props.isLoading          Whether the data is still being loaded
 * @param props.onSearch           Callback to invoke when search term is changed
 * @param props.requestTopicSave   Request that the current topic is saved
 * @param props.requestTopicChange Request that the current topic is changed
 * @returns                        The component itself
 */
const TemplateTopicForm: FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  dataTopicAll,
  dataTopicCurrent,
  dataSearchValue,
  error,
  idForm,
  isLoading,
  onSearch,
  requestTopicChange,
  requestTopicSave,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the selected topic is saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestTopicSave();
  };

  return (
    <form
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      id={idForm}
      onSubmit={onSubmit}
    >
      <ListSearch
        dataAll={dataTopicAll}
        dataCurrent={dataTopicCurrent}
        dataSearchValue={dataSearchValue}
        dataType="topic"
        error={error}
        inputSearchLabel={translate('TEMPLATE__TOPICS__SEARCH_LABEL')}
        inputSearchPlaceholder={translate('TEMPLATE__TOPICS__SEARCH_LABEL')}
        isLoading={isLoading}
        noResultsText={translate('TEMPLATE__TOPICS__NO_RESULT_EMPTY')}
        onSearch={onSearch}
        requestChange={requestTopicChange}
      />
    </form>
  );
};

export default TemplateTopicForm;
