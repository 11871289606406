import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Message } from '../../../../generated/graphql';

import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
  getCloseRepliesUrl,
  getMessageDetailsRepliesUrl,
  getOpenRepliesUrl,
} from '../../../../routes/urls/message';
import MessageReply from '../MessageReply';

type Props = {
  isExpanded: boolean;
  isUnread: boolean;
  messageId: Message['id'];
  numberOfComments: Message['numberOfComments'];
  shouldShowComments: boolean;
  topicId: Message['topic']['id'];
};

/**
 * Footer of the message containing labels and comments
 *
 * @param props                    Props passed to the component
 * @param props.isExpanded         Are comments expanded
 * @param props.isUnread           Whether the message is unread
 * @param props.messageId          ID of the message
 * @param props.numberOfComments   The numberOfComments of the user
 * @param props.shouldShowComments Should the comments be shown
 * @param props.topicId            Id of the topic to which message belongs
 * @returns                        The component itself
 */
const MessageFooter: FC<Props> = ({
  isExpanded,
  isUnread,
  messageId,
  numberOfComments,
  shouldShowComments,
  topicId,
}) => {
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const location = useLocation();

  /**
   * Creates a url for toggling replies
   *
   * @returns void
   */
  const toggleRepliesUrl = isExpanded
    ? getCloseRepliesUrl(location)
    : getOpenRepliesUrl(location, messageId);

  /**
   * Based on device size creates url string
   *
   * @returns Url string
   */
  const getRepliesUrl = () => {
    if (isMobile) {
      return getMessageDetailsRepliesUrl(hotelId, location, messageId, topicId);
    }
    return toggleRepliesUrl;
  };

  return (
    <footer>
      {shouldShowComments && (
        <MessageReply
          isExpanded={isExpanded}
          isUnread={isUnread}
          numberOfComments={numberOfComments}
          toggleRepliesUrl={getRepliesUrl()}
        />
      )}
    </footer>
  );
};

export default MessageFooter;
