import { styled } from '@mui/material';

import devices from '../../../styles/devices';
import MuiSegmentedButtonGroup from '../../MUI/SegmentedButtonGroup';

export const SegmentedButtonGroupWrapper = styled('div')`
  display: flex;
  justify-content: stretch;

  @media ${devices.tabletS} {
    justify-content: flex-start;
  }
`;

export const SegmentedButtonGroup = styled(MuiSegmentedButtonGroup)`
  align-self: center;
  width: 100%;

  @media ${devices.tabletS} {
    /* Hardcoded min-width to match the requirements of having fixed width of the buttons */
    min-width: var(--header-segmented-buttons-min-width);
    width: auto;
  }
`;
