/**
 * @file contains key-value translations that are used
 * when the user is not yet authenticated
 *
 * Language: Dutch
 */

export default {
  ERROR__404__ACTION: 'Naar de startpagina',
  ERROR__404__DESCRIPTION:
    'Helaas, deze pagina bestaat niet. Probeer het opnieuw met een geldige URL.',
  ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION:
    'Helaas, we konden het object niet vinden. Neem contact op met uw beheerder of onze klantenservice.',
  ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION:
    'Helaas, we konden geen gebruiker vinden. Probeer het opnieuw met geldige toegangsgegevens. Als de fout zich opnieuw voordoet, neem dan contact op met onze klantenservice.',
  ERROR__DEACTIVATED_HOTEL__DESCRIPTION:
    'Uw gebruiker is gedeactiveerd voor het object. Neem contact op met uw beheerder of onze klantenservice.',
  ERROR__HOTEL__SWITCH_DESCRIPTION:
    'Wilt u in plaats daarvan overschakelen naar een ander object?',
  ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX: 'Schakel over naar object "',
  ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX: '"',
  ERROR__MISSING_HOTEL__DESCRIPTION:
    'Helaas, u heeft geen toegang tot het object. Neem contact op met uw beheerder of onze klantenservice.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION:
    'Wilt u toegang? Ga ga eerst akkoord met de algemene voorwaarden.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE: 'Oeps, er ontbreekt iets!',
  GENERAL__BACK_TO_LOGIN: 'Ga terug naar het aanmeldscherm',
  GENERAL__OOPS: 'Oeps, er ging iets mis.',
};
