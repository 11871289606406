import React, { FC } from 'react';

import translate from '../../../../i18n/translate';
import { ToastMessageModel } from '../../../../models/toastMessage';

import * as Styled from './styled';

type Props = {
  isVisible: boolean;
  onClick: () => void;
  type: ToastMessageModel['type'];
};

/**
 * Toggle the visibility of the additional text
 *
 * @param props           Props passed to the component
 * @param props.isVisible Whether the additional text is currently visible
 * @param props.type      The type of toast message
 * @param props.onClick   Callback to invoke on button click
 * @returns               The component itself
 */
const ToastToggle: FC<Props> = ({ isVisible, onClick, type }) => {
  return (
    <Styled.Toggle data-message-type={type} onClick={onClick} type="button">
      {isVisible
        ? translate('TOAST__HIDE_DETAILS_TEXT')
        : translate('TOAST__SHOW_DETAILS_TEXT')}
    </Styled.Toggle>
  );
};

export default ToastToggle;
