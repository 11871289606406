import React, { ComponentProps, FC, ReactNode, RefObject } from 'react';

import ToastContent from '../Common/ToastMessage/ToastContent';

import MetaHeader from './MetaHeader';

import { useMetaContainerInlineRole } from './MetaHeader/hooks/useMetaContainerInlineRole';
import * as Styled from './styled';

type CommonProps = ComponentProps<typeof MetaHeader> & {
  children?: ReactNode | ReactNode[];
  className?: string;
  hasMobilePadding?: boolean;
  isSaveDisabled: boolean;
  labelConfirm: string;
  popupRef?: RefObject<HTMLDivElement>;
  shouldHideSave?: boolean;
  warningTitle?: string | null;
};

export type Props = CommonProps &
  (
    | {
        idForm: string;
        requestSave?: never;
      }
    | {
        idForm?: never;
        requestSave: () => void;
      }
  );

/**
 * Meta box, used for compose and in sidebar, for
 * assignment, labels, due date and checklist items
 *
 * @param props                  Props passed to the component
 * @param props.action           Props for an additional button in the heading
 * @param props.children         Contents of the meta wrapper
 * @param props.className        styled-components generated class name, needed for styling
 * @param props.hasMobilePadding Should the wrapper padding be removed on mobile
 * @param props.idForm           id attribute for the form related to submit button
 * @param props.idHeading        id attribute for the component heading
 * @param props.idSubheading     id attribute for the component subheading
 * @param props.isPopup          Is container popup or not (used for height)
 * @param props.isSaveDisabled   Is save button disabled
 * @param props.labelClose       The label for the close, x, button
 * @param props.labelConfirm     The text to show for confirm label
 * @param props.onClose          Callback to be invoked on cancel
 * @param props.popupRef         Wrapper ref
 * @param props.requestSave      Request that the data is saved
 * @param props.shouldHideSave   Whether the save button should be hidden
 * @param props.textHeading      The text to show for heading
 * @param props.textSubheading   The text to show for subheading
 * @param props.warningTitle     Warning shown above Save button
 * @returns                      The component itself
 */
const MetaContainerInline: FC<Props> = ({
  action,
  children,
  className,
  hasMobilePadding = true,
  idForm,
  idHeading,
  idSubheading,
  isPopup = false,
  isSaveDisabled,
  labelClose,
  labelConfirm,
  onClose,
  popupRef,
  requestSave,
  shouldHideSave = false,
  textHeading,
  textSubheading,
  warningTitle = null,
}) => {
  const role = useMetaContainerInlineRole(isPopup);

  return (
    <Styled.Wrapper
      aria-describedby={idSubheading}
      aria-labelledby={idHeading}
      className={className}
      data-is-popup={isPopup}
      ref={popupRef}
      role={role}
    >
      <MetaHeader
        action={action}
        idHeading={idHeading}
        idSubheading={idSubheading}
        isPopup={isPopup}
        labelClose={labelClose}
        onClose={onClose}
        textHeading={textHeading}
        textSubheading={textSubheading}
      />
      <Styled.Content data-padding-mobile={hasMobilePadding}>
        {children}
      </Styled.Content>
      {shouldHideSave === false && (
        <Styled.Footer>
          {warningTitle && (
            <Styled.WarningWrapper>
              <ToastContent text="" title={warningTitle} type="info" />
            </Styled.WarningWrapper>
          )}
          <Styled.ButtonSave
            formId={idForm}
            isDisabled={isSaveDisabled}
            onClick={requestSave}
            type={idForm ? 'submit' : 'button'}
          >
            {labelConfirm}
          </Styled.ButtonSave>
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};

export default MetaContainerInline;
