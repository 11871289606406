import React, { FC } from 'react';

import { NAMES } from '../../../../Icon';
import FieldIcon from '../FieldIcon';

import { Props } from '../props';

/**
 * Checkbox field has a checkmark in front of the text value
 *
 * @param props             Props passed to the component
 * @param props.field       The field to render
 * @param props.field.label Concierge field label
 * @param props.field.value Concierge field value
 * @returns                 The component itself
 */
const FieldCheckbox: FC<Props> = ({ field: { label, value } }) => {
  const iconName =
    value === '1'
      ? NAMES.CONCIERGE__CHECKBOX_CHECKED
      : NAMES.CONCIERGE__CHECKBOX_UNCHECKED;

  return <FieldIcon iconName={iconName} text={label} />;
};

export default FieldCheckbox;
