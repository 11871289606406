import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { SearchQueryBasicFragment } from '../../generated/graphql';
import { extractTitle } from '../../hooks/elasticSearch/useEsSmartViewQuery/helpers';

import translate from '../../i18n/translate';
import { getIsFeedKanbanPage } from '../../routes/helpers/pages';
import { getFilterMembersUrl } from '../../routes/urls/searchParams/kanbanFilters';
import { getFilterQuickUrl } from '../../routes/urls/searchParams/quickFilters';
import { generateRandomInt } from '../../utils/number';
import { getUserCurrent } from '../../utils/permissions/storage';
import SkeletonListColumns from '../Loading/SkeletonListColumns';

import QuickFilterLink from './QuickFilterLink';
import {
  getIsKanbanQuickFilterActive,
  getIsQuickFilterActive,
} from './helpers';
import * as Styled from './styled';

type Props = {
  isLoading: boolean;
  quickFilters: SearchQueryBasicFragment[];
};

/**
 * Quick Filters component that renders links for ES
 *
 * @param props              Props passed to the component
 * @param props.isLoading    Whether the data is being fetched
 * @param props.quickFilters The quick filters to render
 * @returns                  Topic filters links component
 */
const QuickFilters: FC<Props> = ({ isLoading, quickFilters }) => {
  const location = useLocation();

  if (isLoading) {
    const numberOfLines = generateRandomInt(2, 5);

    return (
      <Styled.Wrapper>
        <SkeletonListColumns numberOfLines={numberOfLines} />
      </Styled.Wrapper>
    );
  }

  const isKanban = getIsFeedKanbanPage();
  const user = getUserCurrent();
  const kanbanIsActive = getIsKanbanQuickFilterActive(location);
  const kanbanUrl = getFilterMembersUrl(location, kanbanIsActive, user?.id);

  return (
    <Styled.Wrapper>
      <Styled.Ul>
        {isKanban ? (
          <Styled.Li>
            <QuickFilterLink
              isActive={kanbanIsActive}
              label={translate('FILTER__ASSIGNED_TO_ME')}
              url={kanbanUrl}
            />
          </Styled.Li>
        ) : (
          quickFilters.map(({ id, title }) => {
            const isActive = getIsQuickFilterActive(location, id);

            /**
             * Create url based on if the filter is selected or not
             */
            const url = getFilterQuickUrl(location, isActive, id);
            return (
              <Styled.Li key={id}>
                <QuickFilterLink
                  isActive={isActive}
                  label={extractTitle(title)}
                  url={url}
                />
              </Styled.Li>
            );
          })
        )}
      </Styled.Ul>
    </Styled.Wrapper>
  );
};

export default QuickFilters;
