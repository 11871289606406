/**
 * @file Hook that gets currently active messageId from url
 */

import { useParams } from 'react-router-dom';

import { Message } from '../../../generated/graphql';
import { FeedParams } from '../../../models/pageParams';

/**
 * Hook that gets currently active messageId from url
 *
 * @returns Current active messageId
 */
const useParamMessageId = (): Message['id'] | null => {
  const { messageId } = useParams<FeedParams>();

  if (!messageId) {
    return null;
  }

  return messageId;
};

export default useParamMessageId;
