/**
 * @file Map Redux state to compose props
 */

import { MapStateToProps } from 'react-redux';

import AppState from '../../models/state';

import { getShouldDisableSubmit } from './helpers';

import { OwnProps, StateProps } from './props';

/**
 * Map Redux state to compose props
 *
 * @param appState                   Global Redux state
 * @param appState.drafts            Drafts in Redux store
 * @param appState.draftsAttachments Drafts' attachments data
 * @param ownProps                   Props passed to compose container
 * @param ownProps.nodeType          The ID of the draft
 * @returns                          Props to add to compose
 */
const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  { drafts, draftsAttachments },
  { nodeType },
) => ({
  dataDraft: drafts.get(nodeType),
  shouldDisableSubmit: getShouldDisableSubmit(
    nodeType,
    drafts.get(nodeType),
    draftsAttachments.get(nodeType),
  ),
});

export default mapStateToProps;
