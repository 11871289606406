/**
 * @file Optimistically update the cache, with updated topic data
 */

import { Cache, OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MutationTopicUpdateArgs,
  TopicUpdateInput,
  TopicUpdateMutation,
  TopicWithMembersQuery,
} from '../../../../../generated/graphql';
import { getGroupsSortedWithMembers } from '../../updates/utils/getters/group';
import { getTopicWithMembersById } from '../../updates/utils/getters/topic';
import { getUsersByIds } from '../../updates/utils/getters/user';
import topicUpdateAll from '../../updates/utils/updaters/topics/topicUpdateAll';

/**
 * Update topic cache props
 *
 * @param cache    urql cache
 * @param original Cache data
 * @param updates  New data
 * @returns        Updated cache data
 */
const getUpdates = (
  cache: Cache,
  original: TopicWithMembersQuery['topic'] | null | undefined,
  updates: TopicUpdateInput,
): TopicUpdateMutation['topicUpdate'] | null => {
  if (original === null || original === undefined) {
    return null;
  }

  return {
    ...original,
    description: updates.description ?? original.description,
    groups: getGroupsSortedWithMembers(cache, updates.groups),
    members: getUsersByIds(cache, updates.members ?? []),
    title: updates.title ?? original.title,
  };
};

/**
 * Optimistically update a topic
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @param info  Info regarding the mutation (involving variables sent)
 * @returns     The updated value
 */
const topicUpdate: OptimisticMutationResolver<
  MutationTopicUpdateArgs,
  TopicUpdateMutation['topicUpdate'] | null
> = (args, cache, info) => {
  topicUpdateAll({ __typename: 'Mutation' }, args, cache, info);
  const topic = getTopicWithMembersById(cache, args.id);

  if (topic === null) {
    return null;
  }

  return getUpdates(cache, topic, args.data);
};

export default topicUpdate;
