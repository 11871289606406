/**
 * @file contains utility functions for error handling
 */

import { CombinedError, Operation } from 'urql';

import { ApiError, ApiErrors } from '../constants/errors';

import translate from '../i18n/translate';
import { ToastMessageArgs } from '../models/toastMessage';
import { reportError } from '../services/reporting';

import { errorCodeToToastMap } from './errorMaps';

/**
 * Convert GraphQL API error to generic JS Error
 *
 * @param apiError GraphQL API error
 * @returns        Generic JS error
 */
export const convertApiError = (apiError: ApiError | null): Error | null => {
  if (apiError === null) {
    return null;
  }

  return new Error(apiError.message);
};

/**
 * Based on the error code, generate toast message data
 *
 * @param code Error code to generate the toast for
 * @returns    The data for toast message
 */
export const convertApiErrorToToast = (
  code: string,
): ToastMessageArgs | null => {
  const data = errorCodeToToastMap.get(code);

  if (data === undefined) {
    return null;
  }

  const { text, title, type } = data;

  return {
    text: translate(text),
    title: translate(title),
    type,
  };
};

/**
 * Extract API errors
 *
 * @param errors Errors thrown
 * @returns      Formatted
 */
export const extractApiErrors = (errors?: CombinedError): ApiError[] | null => {
  /** @todo Investigate why indexeddb Safari error is getting to exchange error handling */
  if (errors?.graphQLErrors === undefined) {
    return null;
  }

  return errors.graphQLErrors.flatMap(error => {
    const errorCode = error.extensions.code as string;

    if (errorCode in ApiErrors === false) {
      reportError('Unknown errorCode: ' + errorCode);
      return [];
    }

    return ApiErrors[errorCode];
  });
};

/**
 * Extract error messages from errors
 *
 * @param errors Errors received
 * @returns      The extracted messages
 */
export const getApiErrorsMessages = (
  errors?: CombinedError,
): string[] | null => {
  return (
    extractApiErrors(errors)?.map(error => translate(error.message)) ?? null
  );
};

/**
 * If an API call failed (errors !== undefined),
 * report all of them (to Bugsnag and/or console, depending on the config)
 *
 * @param errors Errors received
 */
export const reportApiErrors = (errors?: CombinedError): void => {
  const errorMessages = getApiErrorsMessages(errors);

  // There was no error
  if (errorMessages === null) {
    return;
  }

  errorMessages.forEach(message => reportError(message));
};

/**
 * Extract urql operation data
 *
 * @param operation The operation to extract data from
 * @returns         The data to report
 */
export const extractOperationData = (operation: Operation): string[] => {
  return operation.query.definitions.map(definition => definition.kind);
};

/**
 * Redirects the user to an error route we specify
 *
 * @param errorRoute Error route we want to redirect to
 */
export const redirectToErrorRoute = (errorRoute: string) => {
  /**
   * Do nothing if user is already on this error route
   */
  if (errorRoute === window.location.pathname) {
    return;
  }

  window.location.href = `${window.location.origin}${errorRoute}`;
};
