import React, { FC } from 'react';

import { NAMES } from '../../../../../components/Common/Icon';
import { Topic } from '../../../../../generated/graphql';
import useIsComposeOpen from '../../../../../hooks/router/search/useIsComposeOpen';
import useFeedScrollState from '../../../../../hooks/store/useFeedScrollState';

import translate from '../../../../../i18n/translate';

import * as Styled from './styled';

type Props = {
  topicId: Topic['id'];
};

/**
 * Indicator button with an arrow pointing down
 *
 * @param props         Props passed to the component
 * @param props.topicId Callback to scroll to bottom of the feed
 * @returns             The component itself
 */
const LatestPostButton: FC<Props> = ({ topicId }) => {
  const text = translate('FEED__LATEST_POST');
  const isComposeOpen = useIsComposeOpen();

  const { requestScrollResetFeed } = useFeedScrollState(topicId);

  return (
    <Styled.Button
      data-is-compose-open={isComposeOpen}
      onClick={requestScrollResetFeed}
      type="button"
    >
      <Styled.Icon
        aria-hidden="true"
        height={14}
        name={NAMES.GENERAL__ARROW}
        width={14}
      />
      <Styled.Text>{text}</Styled.Text>
    </Styled.Button>
  );
};

export default LatestPostButton;
