/**
 * @file contains hook for checking whether we are on tablet view
 */
import breakpoints from '../constants/breakpoints';

import useMatchMedia from './useMatchMedia';

/**
 * Custom hook for checking if we are on tablet view or not
 * Tablet separator determined by us.
 *
 * @returns Whether we are on tablet screens or not
 */
const useIsTablet = (): boolean => {
  /**
   * In order to avoid empty screen on 1280px caused by
   * 1px gap between max-width and min-width -1 is added
   */
  return useMatchMedia(`(max-width: ${breakpoints.laptopS - 1}px)`);
};

export default useIsTablet;
