/**
 * @file contains the recurringMain reducer function
 */
import { Frequency, Weekday } from 'rrule';

import {
  RECURRING__DRAFT__INIT,
  RECURRING__DRAFT__POPULATE,
  RECURRING__DRAFT__RESET,
} from '../../constants/actionTypes';

import { RecurringAction, RecurringState } from '../../models/recurring';
import {
  RecurringActionDraftInit,
  RecurringActionDraftPopulate,
} from '../../models/recurringMain';
import { getOneDayAfterDate } from '../../utils/date/getOneDayAfterDate';
import { getOneHourAfterDate } from '../../utils/date/getOneHourAfterDate';

import getParsedMonthdays from '../../utils/rrule/getParsedMonthdays';
import {
  getParsedWeekdays,
  getParsedWeekdaySingle,
} from '../../utils/rrule/getParsedWeekdays';
import { getWeekday } from '../../utils/rrule/getWeekday';
import stateRecurringClone from '../../utils/stateRecurringClone';
import initialState from '../initialState';

type Reducer = (
  state: RecurringState,
  action: RecurringAction,
) => RecurringState;

/**
 * A request was made to create a new draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onInitRecurringDraftRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionDraftInit,
): RecurringState => {
  const { frequency } = action;
  let stateNext = stateRecurringClone(stateCurrent);

  // Only create a draft if mode doesn't have one already
  // Otherwise, the data will be overwritten on mobile <-> desktop switch
  if (stateNext === undefined) {
    stateNext = {
      bymonthdayCurrent: new Set(),
      bymonthdaySaved: new Set(),
      bysetpos: 1,
      byweekday: new Set([getWeekday()]),
      byweekdaySingle: 0,
      dtstartCurrent: getOneHourAfterDate(),
      dtstartSaved: getOneHourAfterDate(),
      frequency,
      intervalSaved: 1,
      templateId: null,
      untilCurrent: getOneDayAfterDate(),
      untilSaved: getOneDayAfterDate(),
    };
  }

  return stateNext;
};

/**
 * A request was made to reset current draft
 *
 * @param stateCurrent Current drafts state
 * @returns            The updated state
 */
const onResetRecurringDraftRequest = (
  stateCurrent: RecurringState,
): RecurringState => {
  let stateNext = stateRecurringClone(stateCurrent);

  stateNext = {
    ...stateNext,
    bymonthdayCurrent: new Set(),
    bymonthdaySaved: new Set(),
    bysetpos: 1,
    byweekday: new Set([getWeekday()]),
    byweekdaySingle: 0,
    dtstartCurrent: getOneHourAfterDate(),
    dtstartSaved: getOneHourAfterDate(),
    intervalSaved: 1,
    templateId: null,
    untilCurrent: getOneDayAfterDate(),
    untilSaved: getOneDayAfterDate(),
  };

  return stateNext;
};

/**
 * A request was made to populate current draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onPopulateRecurringDraftRequest = (
  stateCurrent: RecurringState,
  action: RecurringActionDraftPopulate,
): RecurringState => {
  const { data } = action;
  let stateNext = stateRecurringClone(stateCurrent);

  const { rrule, templateId } = data;

  // Extract rrule props from object
  const {
    bymonthday,
    bysetpos = 1,
    byweekday,
    dtstart,
    freq,
    interval = 1,
    until = null,
  } = rrule.origOptions;

  if (!dtstart || !freq) {
    return stateNext;
  }

  /**
   * Common options shared between all the rrule frequencies
   */
  const commonState = {
    dtstartCurrent: dtstart,
    dtstartSaved: dtstart,
    frequency: freq,
    intervalSaved: interval,
    templateId,
    untilCurrent: until,
    untilSaved: until,
  };

  if (freq === Frequency.MONTHLY) {
    const parsedMonthdays = getParsedMonthdays(bymonthday);
    const parsedWeekdaySingle = getParsedWeekdaySingle(
      byweekday as Weekday | Weekday[],
    );

    /**
     * Populate state with common options,
     * set monthly options and set other options to inital state
     */
    stateNext = {
      ...commonState,
      bymonthdayCurrent: parsedMonthdays,
      bymonthdaySaved: parsedMonthdays,
      bysetpos: bysetpos as number,
      byweekday: new Set([getWeekday()]),
      byweekdaySingle: parsedWeekdaySingle,
    };

    return stateNext;
  }

  /**
   * For frequency weekly we need set of weekdays
   */
  const parsedWeekdays = getParsedWeekdays(byweekday as Weekday | Weekday[]);

  /**
   * Populate state with common options,
   * set daily/weekly options and set monthly options to inital state
   */
  stateNext = {
    ...commonState,
    bymonthdayCurrent: new Set(),
    bymonthdaySaved: new Set(),
    bysetpos: 1,
    byweekday: parsedWeekdays,
    byweekdaySingle: 0,
  };

  return stateNext;
};

/**
 * action:reducer mapping for templates recurring
 */
const mapping = new Map<string, Reducer>([
  [RECURRING__DRAFT__INIT, onInitRecurringDraftRequest],
  [RECURRING__DRAFT__POPULATE, onPopulateRecurringDraftRequest],
  [RECURRING__DRAFT__RESET, onResetRecurringDraftRequest],
]);

/**
 * Drafts templates recurring reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const recurringMain = (
  state = initialState.draftRecurring,
  action: RecurringAction,
): RecurringState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default recurringMain;
