import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TopicsListQuery } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useCollapsibleStyles from '../../../../hooks/useCollapsibleStyles';
import { getTopicChangeUrl } from '../../../../routes/urls/topic';
import LoadingTopics from '../../../Loading/Topics';
import TopicNavigationLink from '../TopicNavigationLink';

import * as Styled from './styled';

export type Props = {
  isActive: boolean;
  isLoading: boolean;
  topics: TopicsListQuery['topics'];
};

/**
 * Content for the CollapsibleTopicsList.
 *
 * @param props           Props passed to the component
 * @param props.isActive  Whether the collapsible is active
 * @param props.isLoading Whether the topics are being fetched
 * @param props.topics    List of topics or tasks
 * @returns               FC
 */
const TopicsContent: FC<Props> = ({ isActive, isLoading, topics }) => {
  const hotelId = useParamHotelId();
  const activeTopicId = useParamTopicId();
  const location = useLocation();

  const [collapsibleWrapperStyle, setCollapsibleWrapperHeight] =
    useCollapsibleStyles({
      isActive,
    });

  useEffect(() => {
    /*
     * Topics length has changed so we
     * call the container to adjust its height
     */
    setCollapsibleWrapperHeight();
  }, [isLoading, topics.length, setCollapsibleWrapperHeight]);

  if (isLoading === true) {
    return (
      <div style={collapsibleWrapperStyle}>
        <LoadingTopics />
      </div>
    );
  }

  return (
    <Styled.Ul style={collapsibleWrapperStyle}>
      {topics.map(({ id, title, unreadMessages }) => {
        return (
          <li key={id}>
            <TopicNavigationLink
              isSelected={activeTopicId === id}
              label={title}
              unreadMessages={unreadMessages}
              url={getTopicChangeUrl(hotelId, location, id)}
            />
          </li>
        );
      })}
    </Styled.Ul>
  );
};

export default TopicsContent;
