/**
 * @file Common config params for all environments
 */

import { BrowserConfig } from '@bugsnag/js';
import { FirebaseOptions } from 'firebase/app';

/**
 * Query param where auth code is (used to retrieve access token)
 */
export const AUTH__CODE = 'code';

/**
 * The number of days before auth token expires
 *
 * @see https://bitbucket.org/gastfreund/hotelboard-api/src/master/src/api/Token/service.ts
 */
export const AUTH__EXPIRATION_IN_DAYS = 365;

/**
 * Used to authenticate Hotelboard with Bugsnag
 */
export const BUGSNAG_API_KEY = '33b665bf1d443696fdea29d99584bcdd';

/**
 * Static part of Bugsnag config
 */
export const BUGSNAG_CONFIG: BrowserConfig = {
  apiKey: BUGSNAG_API_KEY,
  collectUserIp: false,
  enabledReleaseStages: ['production', 'development'],
};

/**
 * Config options for Firebase
 * Required for subscribing to and receiving push notifications
 */
export const FIREBASE_OPTIONS: FirebaseOptions = {
  apiKey: 'AIzaSyCzAGjLtorf27Lxo2AT7Fk-1Ar4ucCKBjM',
  appId: '1:358345871041:web:7ceac7124a0c2cbcbcce40',
  authDomain: 'hotelboard-a1295.firebaseapp.com',
  measurementId: 'G-5H07MRBLX5',
  messagingSenderId: '358345871041',
  projectId: 'hotelboard-a1295',
  storageBucket: 'hotelboard-a1295.appspot.com',
};

/**
 * Firebase Voluntary Application Server Identification for push notifications
 */
export const FIREBASE_VAPID_KEY =
  'BDTBvnrllEW9ETnKDU2vD4i5Bwy1EwEpdxeIHDrV8Rfwp1KhcxZ0c4VqOsG1R6fTFgjoCVbputRIS9bLap6DKRs';

/**
 * URL for Gastfreund help desk
 * "BASE" because we need to append language
 */
export const HELPDESK_BASE_URL = 'https://gastfreund.zendesk.com/hc/';

/**
 * Path to the service worker file
 * This is the default name used by InjectManifest Webpack plugin we use
 */
export const SW_URL = 'firebase-messaging-sw.js';

/**
 * The base url for the Wisepops script
 */
export const WISEPOPS__URL = 'https://wisepops.net/loader.js?v=2';
