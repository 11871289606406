/**
 * @file Optimistically update the cache, filtering out removed user groups
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MutationUserGroupDeleteArgs,
  UserGroupDeleteMutation,
  UserGroupsDocument,
} from '../../../../../generated/graphql';
import queryDeletion from '../../updates/utils/updaters/queryDeletion';

/**
 * Optimistically delete a group
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const groupDelete: OptimisticMutationResolver<
  MutationUserGroupDeleteArgs,
  UserGroupDeleteMutation['userGroupDelete'] | null
> = (args, cache) => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: UserGroupsDocument,
    queryName: 'userGroups',
  });

  cache.invalidate({
    __typename: 'UserGroup',
    id: args.id,
  });

  return {
    __typename: 'ActionResult',
    status: true,
  };
};

export default groupDelete;
