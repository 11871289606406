import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import ButtonIcon, { NAMES } from '../ButtonIcon';

export type Props = {
  onClick: () => void;
};

const ICON_SIZE = 20;

/**
 * Trashcan icon for removing items from the list (checklist and attachment list)
 *
 * @param props         Props passed to the component
 * @param props.onClick Callback to be invoked on button click
 * @returns             The component itself
 */
const ButtonListItemDelete: FC<Props> = ({ onClick }) => {
  const label = translate('GENERAL__REMOVE');

  return (
    <ButtonIcon
      color="var(--color-text-light)"
      colorFocus="var(--color-icons-danger)"
      colorHover="var(--color-icons-danger)"
      iconHeight={ICON_SIZE}
      iconName={NAMES.GENERAL__TRASH}
      iconWidth={ICON_SIZE}
      label={label}
      onClick={onClick}
    />
  );
};

export default ButtonListItemDelete;
