import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import useFocus from '../../../hooks/useFocus';

import Icon, { NAMES } from '../Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  color?: string;
  colorFocus?: string;
  colorHover?: string;
  iconHeight?: number;
  iconName: string;
  iconPosition?: 'left' | 'right';
  iconWidth?: number;
  label: string;
  onClick?: () => void;
  text?: string;
  url: string;
};

const defaultColor = 'currentColor';

/**
 * Link with icon and optional text
 *
 * @param props              Props passed to the component
 * @param props.className    Styled-components generated class name, needed for styling
 * @param props.color        Color of the icon
 * @param props.colorFocus   Color of the icon when the button is focused
 * @param props.colorHover   Color of the icon when the button is hovered
 * @param props.iconHeight   The height of the included icon (button itself will be the same size)
 * @param props.iconName     Which icon to show within the button
 * @param props.iconPosition Icon position in relation to text
 * @param props.iconWidth    The width of the included icon (button itself will be the same size)
 * @param props.label        Aria label for the button (since there's no text, just the icon)
 * @param props.onClick      Click callback, not needed for submit buttons
 * @param props.url          Url to link to
 * @param props.text         Text shown
 * @returns                  The component itself
 */
const LinkIconWithText: FC<Props> = ({
  className,
  color = defaultColor,
  colorFocus = color,
  colorHover = color,
  iconHeight,
  iconName,
  iconPosition = 'left',
  iconWidth,
  label,
  onClick,
  text,
  url,
}) => {
  const [isFocused, bind] = useFocus();

  return (
    <Tippy content={label} touch={false}>
      <span>
        <Styled.Link
          aria-label={label}
          className={className}
          data-color={color}
          data-position={iconPosition}
          onClick={onClick}
          to={url}
          {...bind}
        >
          <Icon
            color={isFocused ? colorFocus : color}
            colorHover={colorHover}
            height={iconHeight}
            name={iconName}
            width={iconWidth}
          />
          {text}
        </Styled.Link>
      </span>
    </Tippy>
  );
};

export { NAMES };
export default LinkIconWithText;
