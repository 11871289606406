import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicEditMode from '../../../hooks/router/params/useParamTopicEditMode';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import translate from '../../../i18n/translate';

import { defaultProps, mapping } from './helpers';

/**
 * Header component for topic full view on mobile
 *
 * @returns The component itself
 */
const TopicFullHeaderMobile: FC = () => {
  const hotelId = useParamHotelId();
  const location = useLocation();
  const topicEditMode = useParamTopicEditMode();
  const topicId = useParamTopicId();

  const { title, backCallback, contentRight } =
    mapping.get(topicEditMode) ?? defaultProps;

  return (
    <BaseHeaderMobile
      backUrl={backCallback(hotelId, location, topicId)}
      contentRight={contentRight}
      title={translate(title)}
    />
  );
};

export default TopicFullHeaderMobile;
