import React, { FC } from 'react';

import {
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../../generated/graphql';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import userGetName from '../../../utils/user/userGetName';
import Avatar from '../Avatar';
import GroupList from '../GroupList';
import MessageNoResult from '../MessageNoResult';

import * as Styled from './styled';

export type Props = {
  groups: UserGroupBasicFragment[];
  users: UserBasicFragment[];
};

const avatarSize = 30;
const fontSize = 0.75;

/**
 * Show all the groups and people that are assigned to a message
 *
 * @param props        Props passed to the component
 * @param props.groups All the groups assigned to the message
 * @param props.users  All the people assigned to the message
 * @returns            The component itself
 */
const MessageAssignees: FC<Props> = ({ groups, users }) => {
  const isEmpty = groups.length + users.length === 0;
  const isMobile = useIsMobile();

  if (isEmpty) {
    return <MessageNoResult message={translate('ASSIGNMENT__MESSAGE__NONE')} />;
  }

  /**
   * On mobile show the avatar and full username of the assignees
   */
  if (isMobile) {
    return (
      <>
        <ul>
          {users.map(user => (
            <Styled.AssigneesWrapper key={user.id}>
              <Avatar fontSize={fontSize} size={avatarSize} user={user} />
              <Styled.AssigneeName>{userGetName(user)}</Styled.AssigneeName>
            </Styled.AssigneesWrapper>
          ))}
        </ul>
        <GroupList groups={groups} />
      </>
    );
  }

  /**
   * On desktop just show the avatar and the username
   * of the assignees by hovering over the avatar
   */
  return (
    <>
      <Styled.Avatars fontSize={fontSize} size={avatarSize} users={users} />
      <GroupList groups={groups} />
    </>
  );
};

export default MessageAssignees;
