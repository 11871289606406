/**
 * @file All permissions checks related to reservations
 */

import { RESERVATIONS__VIEW } from './permissionsAll';
import { getPermissionsCurrent } from './storage';

/**
 * Check whether the user is allowed to view the reservations page
 *
 * @returns Whether the user is allowed to perform the action
 */
export const canViewReservationsPage = (): boolean => {
  return getPermissionsCurrent().includes(RESERVATIONS__VIEW);
};
