import React, { ComponentProps, FC } from 'react';

import EmptyState from '../../components/Common/EmptyState';
import SearchForm from '../../components/Forms/SearchForm';
import { ILLUSTRATION__SEARCH } from '../../constants/illustrations';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';

import * as Styled from './styled';

/**
 * Search component with input and illustration for search page
 *
 * @returns Search component itself
 */
const Search: FC = () => {
  const isMobile = useIsMobile();

  const headingProps: Omit<
    ComponentProps<typeof EmptyState>,
    'illustrationName'
  > = isMobile
    ? {
        subtitle: translate('SEARCH__MOBILE__SUBTITLE'),
        title: translate('SEARCH__MOBILE__TITLE'),
      }
    : { subtitle: null, title: null };

  return (
    <Styled.Wrapper>
      <SearchForm />

      <EmptyState {...headingProps} illustrationName={ILLUSTRATION__SEARCH} />
    </Styled.Wrapper>
  );
};

export default Search;
