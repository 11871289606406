import React, { FC, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import {
  MODE_CREATE,
  MODE_EDIT,
  MODE_VIEW,
  ModeLabels,
} from '../../constants/metaModesLabels';
import { Label, LabelBasicFragment } from '../../generated/graphql';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';

import MetaLabelCreate from './MetaLabelCreate';
import MetaLabelsView from './MetaLabelsView';

import ModeEdit from './ModeEdit';

type Props = {
  className?: string;
  dataAll: LabelBasicFragment[];
  dataCurrent: Label['id'] | null;
  dataSaved: Set<Label['id']>;
  dataSelected: Set<Label['id']>;
  inputCustom?: ReactNode;
  isActive: boolean;
  isLoading: boolean;
  labelsExisting: Label['text'][];
  linkToggleProps?: LinkProps;
  mode: ModeLabels;
  onClose?: () => void;
  requestCreate: (title: Label['text'], color: Label['color']) => void;
  requestModeCreate: (() => void) | null;
  requestModeEdit: ((labelId: Label['id']) => void) | null;
  requestModeView: () => void;
  requestSave: () => void;
  requestToggle: ((labelId: Label['id'], state: boolean) => void) | null;
  requestUpdate: (
    id: Label['id'],
    title: Label['text'],
    color: Label['color'],
  ) => void;
};

/**
 * Labels popup for the compose block,
 * (can be in view, but also create and edit mode)
 *
 * @param props                   Props passed to the component
 * @param props.className         styled-components generated class name, needed for styling
 * @param props.dataAll           All available labels
 * @param props.dataCurrent       The label that's currently being edited
 * @param props.dataSaved         The labels already saved
 * @param props.dataSelected      Which labels are already selected for the message
 * @param props.inputCustom       Component to use for overview in mobile view
 * @param props.isActive          Whether the component should be visible
 * @param props.isLoading         Whether the data is still being loaded
 * @param props.labelsExisting    Labels already present
 * @param props.linkToggleProps   Props for the toggle link
 * @param props.mode              The mode in which the popup should be (create|edit|view)
 * @param props.onClose           Callback to be invoked when the user clicks close button
 * @param props.requestCreate     Request that a new label is created (will be null if the user has no permission to create labels)
 * @param props.requestModeCreate Callback to be invoked when the user clicks "Create new label"
 * @param props.requestModeEdit   Callback to be invoked when the user clicks the label edit button
 * @param props.requestModeView   Callback to show label list (when the user clicks "x" on create/edit)
 * @param props.requestSave       Request that the current labels state is saved
 * @param props.requestToggle     Callback to be invoked when a label is a/detached
 * @param props.requestUpdate     Request that a label is updated
 * @returns                       The component itself
 */
const MetaLabels: FC<Props> = ({
  className,
  dataAll,
  dataCurrent,
  dataSelected,
  dataSaved,
  inputCustom,
  isActive,
  isLoading,
  labelsExisting,
  linkToggleProps,
  mode,
  onClose,
  requestCreate,
  requestModeCreate = null,
  requestModeEdit = null,
  requestModeView,
  requestSave,
  requestToggle,
  requestUpdate,
}) => {
  // Popup top buttons labels
  const isMobile = useIsMobile();
  const labelAction = isMobile
    ? translate('GENERAL__CREATE')
    : translate('MODAL__LABEL_CREATE_HEADING');
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');
  const textPlaceholder = translate('COMPOSE__PLACEHOLDER__LABEL');

  switch (mode) {
    case MODE_CREATE:
      return (
        <MetaLabelCreate
          className={className}
          isActive={isActive}
          labelsExisting={labelsExisting}
          linkToggleProps={linkToggleProps}
          requestCreate={requestCreate}
          requestModeView={requestModeView}
        />
      );
    case MODE_EDIT:
      return (
        <ModeEdit
          className={className}
          dataAll={dataAll}
          dataCurrent={dataCurrent}
          dataSaved={dataSaved}
          isActive={isActive}
          labelsExisting={labelsExisting}
          linkToggleProps={linkToggleProps}
          requestModeView={requestModeView}
          requestUpdate={requestUpdate}
        />
      );
    case MODE_VIEW:
      return (
        <MetaLabelsView
          className={className}
          dataAll={dataAll}
          dataSaved={dataSaved}
          dataSelected={dataSelected}
          headerAction={requestModeCreate}
          inputCustom={inputCustom}
          isActive={isActive}
          isLoading={isLoading}
          labelAction={labelAction}
          labelClose={labelClose}
          labelConfirm={labelConfirm}
          linkToggleProps={linkToggleProps}
          onClose={onClose}
          onSave={requestSave}
          requestModeEdit={requestModeEdit}
          requestToggle={requestToggle}
          textPlaceholder={textPlaceholder}
        />
      );
  }
};

export default MetaLabels;
