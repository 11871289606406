import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import { UserBasicFragment } from '../../../generated/graphql';
import userGetName from '../../../utils/user/userGetName';
import Avatar from '../Avatar';

import * as Styled from './styled';

export type Props = {
  className?: string;
  fontSize?: number;
  size: number;
  users: UserBasicFragment[];
};

/**
 * Horizontal list of avatars for passed users
 *
 * @param props           Props passed to the component
 * @param props.className styled-components className
 * @param props.fontSize  The font-size of the avatar initials
 * @param props.size      Avatar icon size
 * @param props.users     The users to render the avatars for
 * @returns               The component itself
 */
const Avatars: FC<Props> = ({ className, fontSize, size, users }) => {
  if (users.length === 0) {
    return null;
  }

  return (
    <Styled.Ul className={className}>
      {users.map(user => {
        const username = userGetName(user);

        return (
          <Tippy content={username} key={`user-${user.id}`} touch={false}>
            <Styled.WrapperAvatars>
              <Avatar fontSize={fontSize} size={size} user={user} />
            </Styled.WrapperAvatars>
          </Tippy>
        );
      })}
    </Styled.Ul>
  );
};

export default Avatars;
