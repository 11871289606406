/**
 * @file MessagesRead mutation that also refreshes the topic list
 */

import { useMutation, useQuery } from 'urql';

import {
  Message,
  MessagesReadDocument,
  TopicsListDocument,
} from '../../generated/graphql';
import { reportApiErrors } from '../../utils/error';

/**
 * MessagesRead mutation that also refreshes the topic list
 *
 * @returns The callback to invoke the mutation
 */
const useMutationMessagesRead = () => {
  const [, requestMessagesRead] = useMutation(MessagesReadDocument);
  const [, refreshTopicList] = useQuery({
    pause: true,
    query: TopicsListDocument,
  });

  return (messageIds: Message['id'] | Message['id'][]) => {
    requestMessagesRead({ messageIds })
      .then(() => {
        /**
         * Force refresh topics list data,
         * So that the sidebar unread badge counters are updated
         */
        return refreshTopicList({ requestPolicy: 'network-only' });
      })
      .catch(reportApiErrors);
  };
};

export default useMutationMessagesRead;
