import { ListItemText } from '@mui/material';
import React, { FC } from 'react';

import MuiIcon from '../../MuiIcon';
import { IconName } from '../../MuiIcon/mapping';

import * as Styled from '../styled';

export type NavigationListButtonProps = {
  icon: IconName;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

/**
 * Button list item component that can be reused within the navigation
 *
 * @param props            Props passed to the component
 * @param props.icon       The icon to display on the left
 * @param props.isDisabled If the button is disabled
 * @param props.label      Label of the button
 * @param props.onClick    Callback to be called when the button is clicked
 * @returns                The component itself
 */
const NavigationListButton: FC<NavigationListButtonProps> = ({
  icon,
  isDisabled,
  label,
  onClick,
}) => {
  return (
    <Styled.MuiListItem disablePadding={true}>
      <Styled.MuiListItemButton disabled={isDisabled} onClick={onClick}>
        <Styled.MuiListItemIcon>
          <MuiIcon icon={icon} />
        </Styled.MuiListItemIcon>
        <ListItemText primary={label} />
      </Styled.MuiListItemButton>
    </Styled.MuiListItem>
  );
};

export default NavigationListButton;
