import React, { FC } from 'react';
import { HexColorPicker } from 'react-colorful';

import * as Styled from './styled';

type Props = {
  isRequired?: boolean;
  label: string;
  name?: string;
  onChange: (color: string) => void;
  placeholder?: string;
  value: string;
};

/**
 * Common color picker
 *
 * @param props             Props passed to the component
 * @param props.isRequired  Whether it's isRequired to pick a value
 * @param props.label       aria-label for the input
 * @param props.name        name attribute for the input
 * @param props.onChange    Callback to invoke when the value has changed
 * @param props.placeholder Input placeholder
 * @param props.value       Currently selected color
 * @returns                 The component itself
 */
const PickerColor: FC<Props> = ({
  isRequired,
  label,
  name,
  onChange,
  placeholder,
  value,
}) => {
  return (
    <Styled.Wrapper>
      <HexColorPicker color={value} onChange={onChange} />
      <Styled.HexInputLabel>
        {label}
        <Styled.HexInput
          color={value}
          name={name}
          onChange={onChange}
          // Matches backend validation (GENERAL__COLOR_REGEX), just without "#" prefix.
          pattern="^(([a-fA-F0-9]{3}){1,2}|([a-fA-F0-9]{4}){1,2})$"
          placeholder={placeholder}
          required={isRequired}
        />
      </Styled.HexInputLabel>
    </Styled.Wrapper>
  );
};

export default PickerColor;
