import { styled } from 'styled-components';

import Button from '../../components/Common/Button';

export const ErrorText = styled.span`
  padding: var(--spacing-medium);
`;

export const ReloadButton = styled(Button)`
  text-decoration: underline;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
