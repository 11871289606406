import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { ChipSquared } from '../../MUI/ChipSquared';
import MuiIcon from '../../MUI/MuiIcon';

type Props = {
  isActive: boolean;
  label: string;
  url: string;
};

/**
 * A single quick filter link component
 *
 * @param props          Props passed to the component
 * @param props.isActive Whether the filter is active
 * @param props.label    The quick filter text
 * @param props.url      Url to redirect to
 * @returns              Styled router link component
 */
const QuickFilterLink: FC<Props> = ({ isActive, label, url }) => {
  return (
    <ChipSquared
      clickable={true}
      color={isActive ? 'secondary' : 'default'}
      component={Link}
      icon={isActive ? <MuiIcon icon="check" /> : undefined}
      label={label}
      size="small"
      to={url}
    />
  );
};

export default QuickFilterLink;
