import { styled } from 'styled-components';

type Props = {
  'data-icon-size': number;
};

export const Wrapper = styled.div`
  align-items: center;
  display: flex;

  /**
   * In order to prevent the select's chevron icon from being
   * pushed to the right when ... (three dots) from truncated text appears,
   * width of chevron is subtracted from element's max-width.
   */
  max-width: calc(100% - var(--spacing-small));
`;

export const LogoWrapper = styled.div<Props>`
  border-radius: var(--border-radius-small);
  display: flex;
  flex-shrink: 1;
  height: ${props => props['data-icon-size']}px;
  margin-right: var(--spacing-tiny);
  width: ${props => props['data-icon-size']}px;
`;

/**
 * A placeholder if we do not have the image for the hotel
 */
export const LogoPlaceholder = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Title = styled.h2<Props>`
  font-size: var(--font-baseline);
  font-weight: 600;

  /** Limit width of the title so logo does not get squished */
  max-width: calc(
    100% - (${props => `${props['data-icon-size']}px`} + var(--spacing-tiny))
  );
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Image = styled.img`
  border-radius: inherit;
  margin: auto;
`;
