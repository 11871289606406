/**
 * @file Helper functions for modal component
 */

import { PREFERENCE_IMAGES_MODALS_BASE } from '../../../constants/preferences';

import { filenames } from './images';
import { HeadingProps, ReactModalProps } from './types';

/**
 * Generate a full URL to an image with provided filename
 *
 * @param props The props passed to the component
 * @returns     The generated path to the image
 */
export const getImagePath = (props: ReactModalProps): string => {
  if (props['data-image']) {
    return `/${PREFERENCE_IMAGES_MODALS_BASE}${filenames[props['data-image']]}`;
  }

  return '';
};

/**
 * Get whether the title should be moved down
 * That's needed in case we're rendering an image within the modal header
 *
 * @param props The props passed to the component
 * @returns     The amount of top margin to apply
 */
export const getTitleMarginTop = (props: HeadingProps): string => {
  return props['data-has-image'] ? 'var(--background-size)' : '0';
};
