/**
 * @file Filter passed groups
 */

import { UserGroup } from '../../generated/graphql';

import filter, { Options } from '../filter';

type Data = Pick<UserGroup, 'id' | 'title'>;

const options: Options<Data> = {
  keys: ['title'],
};

/**
 * Filter passed groups
 *
 * First return all selected ones
 * (these are visible no matter the search)
 *
 * And then return the rest, filtered by what's typed into search
 *
 * @param search    What the user typed in search box
 * @param groupsAll All the groups available
 * @param groupIds  IDs of selected groups
 * @returns         Filtered array of groups
 */
const getVisibleGroups = (
  search: string,
  groupsAll: Data[],
  groupIds: Set<UserGroup['id']>,
): Data[] => {
  // If nothing is searched, then just return everything
  if (search.trim().length === 0) {
    return groupsAll;
  }

  const groupsAssigned = groupsAll.filter(group => groupIds.has(group.id));
  const groupsUnassigned = groupsAll.filter(group => !groupIds.has(group.id));
  const groupsSearched = filter(groupsUnassigned, search, options);

  return [...groupsAssigned, ...groupsSearched];
};

export default getVisibleGroups;
