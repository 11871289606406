import React, { FC } from 'react';

import { generateRandomInt } from '../../../utils/number';

import SkeletonAvatar from '../SkeletonAvatar';

import * as Styled from './styled';

const sizeMin = 10;
const sizeMax = 40;

/**
 * Skeleton view for member assignment
 *
 * @returns The component itself
 */
const AssigneesMembersLoading: FC = () => {
  const nrMembers = 3;

  // Generate random widths for skeleton lines, between sizeMin and sizeMax
  const widths = Array.from({ length: nrMembers }, () => {
    return generateRandomInt(sizeMin, sizeMax);
  });

  return (
    <ul>
      {widths.map((width, index) => (
        // No way to generate a key for this
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Li key={index}>
          <SkeletonAvatar size={33} />
          <Styled.Name width={width} />
        </Styled.Li>
      ))}
    </ul>
  );
};

export default AssigneesMembersLoading;
