/**
 * @file Contains helper functions for the drawer
 */
import { Location } from 'react-router-dom';

import translate from '../../../i18n/translate';
import { getDashboardUrl } from '../../../routes/urls/dashboard';
import { getReservationsUrl } from '../../../routes/urls/reservations';
import { getSearchUrl } from '../../../routes/urls/search';
import { getSidebarToggleUrl } from '../../../routes/urls/searchParams/sidebar';

import { CollapsibleItem, NavItem, NavTab } from './types';

/**
 * Array of collapsible item ids
 */
const collapsibleItems: CollapsibleItem[] = [
  'chat',
  'hotels',
  'settings',
  'smartViews',
  'topics',
];

/**
 * Type guard function to check whether the id of item is a CollapsibleItem
 *
 * @param id Id of the item
 * @returns  Whether item is collapsible
 */
export const getIsCollapsibleItem = (id: NavTab): id is CollapsibleItem => {
  return collapsibleItems.includes(id as CollapsibleItem);
};

/**
 * Generates rail list item for hotel change
 *
 * @param hotelName Name of the hotel
 * @param location  Current location
 * @returns         Rail list item for hotel change
 */
export const getRailListItemHotel = (
  hotelName: string,
  location: Location,
): NavItem => ({
  icon: 'apartment',
  id: 'hotels',
  label: hotelName,
  to: getSidebarToggleUrl(location, 'hotels'),
});

/**
 * Generates array of main rail list items
 *
 * @param canAccessReservations    Whether the user is allowed to access reservations
 * @param canViewDashboardPage     Whether the user is allowed to see dashboard page
 * @param hotelId                  Id of the hotel
 * @param isChatEnabled            Whether the chat is enabled
 * @param location                 Current location
 * @param topicsNotificationsCount Number of notifications for topics
 * @returns                        Array of rail list items
 */
export const getRailListItemsMain = (
  canAccessReservations: boolean,
  canViewDashboardPage: boolean,
  hotelId: string,
  isChatEnabled: boolean | null,
  location: Location,
  topicsNotificationsCount: number,
): NavItem[] => [
  // Show home/dashboard icon only for users with permission to view the dashboard
  ...(canViewDashboardPage
    ? [
        {
          icon: 'home',
          id: 'home',
          label: translate('GENERAL__HOME'),
          to: getDashboardUrl(hotelId),
        } as NavItem,
      ]
    : []),
  {
    icon: 'topic',
    id: 'topics',
    label: translate('NAVIGATION__TOPICS'),
    notificationsCount: topicsNotificationsCount,
    to: getSidebarToggleUrl(location, 'topics'),
  },
  {
    icon: 'filter',
    id: 'smartViews',
    label: translate('GENERAL__SMART_VIEWS'),
    to: getSidebarToggleUrl(location, 'smartViews'),
  },
  // Show chat icon before chat permission initialization and remove if not enabled
  ...(isChatEnabled !== false
    ? [
        {
          icon: 'chatBubble',
          id: 'chat',
          label: translate('GENERAL__CHAT'),
          to: getSidebarToggleUrl(location, 'chat'),
        } as NavItem,
      ]
    : []),
  {
    icon: 'search',
    id: 'search',
    label: translate('NAVIGATION__SEARCH'),
    to: getSearchUrl(hotelId),
  },
  // Show reservations icon only for users with permission to access the reservations
  ...(canAccessReservations
    ? [
        {
          icon: 'hotel',
          id: 'bookings',
          label: translate('NAVIGATION__RESERVATIONS'),
          to: getReservationsUrl(hotelId),
        } as NavItem,
      ]
    : []),
];

/**
 * Generates rail list item for settings
 *
 * @param location Current location
 * @returns        Rail list item for settings
 */
export const getRailListItemSettings = (location: Location): NavItem => ({
  icon: 'settings',
  id: 'settings',
  label: translate('NAVIGATION__SETTINGS'),
  to: getSidebarToggleUrl(location, 'settings'),
});
