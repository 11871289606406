/**
 * @file Get unused tokens for current device
 *
 * (can happen when the user changes browser permissions manually)
 */

import { useQuery } from 'urql';

import { PushSubscriptionsDocument } from '../../generated/graphql';

/**
 * Get unused tokens for current device
 *
 * @param deviceId      The device to get the tokens for
 * @param firebaseToken The current Firebase token
 * @returns             Array of unused tokens for current device
 */
const useOrphanTokens = (
  deviceId: string | null,
  firebaseToken: string | null,
): string[] => {
  const [{ data }] = useQuery({
    query: PushSubscriptionsDocument,
    variables: {},
  });

  if (data === null || data === undefined) {
    return [];
  }

  return data.pushSubscriptions
    .filter(
      ({ device, token }) => device.id === deviceId && token !== firebaseToken,
    )
    .map(subscription => subscription.token);
};

export default useOrphanTokens;
