import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * Section which wraps actions
 *
 * @param props          Props passed to the component
 * @param props.children What to render within the section
 * @returns              The component itself
 */
const MessageSectionActions: FC<Props> = ({ children }) => {
  return <Styled.ActionsWrapper>{children}</Styled.ActionsWrapper>;
};

export default MessageSectionActions;
