import Bugsnag from '@bugsnag/js';
import React, { FC, ReactNode } from 'react';

import { REPORT_LEVEL_POST } from '../../config';
import { LEVEL_NONE } from '../../constants/reportingLevels';
import { getBugsnagConfig } from '../../services/reporting';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * Bugsnag error boundary wrapper component
 *
 * @param props          Props sent to the component
 * @param props.children ErrorBoundary content
 * @returns              The component itself
 */
const ErrorBoundary: FC<Props> = ({ children }) => {
  // TypeScript complains that this is always true
  // because it depends on the env file
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (REPORT_LEVEL_POST !== LEVEL_NONE) {
    Bugsnag.start(getBugsnagConfig());

    const BugsnagErrorBoundary =
      Bugsnag.getPlugin('react')?.createErrorBoundary(React);

    if (BugsnagErrorBoundary !== undefined) {
      return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>;
    }
  }

  return <>{children}</>;
};

export default ErrorBoundary;
