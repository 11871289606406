import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'urql';

import ComposeMetaAssignees from '../../../components/Compose/Common/ComposeMeta/ComposeMetaAssignees';
import MetaAssignment from '../../../components/MetaAssignment';
import { TopicAssignableDocument } from '../../../generated/graphql';
import { ComposeModeParam } from '../../../models/pageParams';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Assignment for groups and members
 *
 * @param props                           Props passed to the container
 * @param props.dataAssigneeSearchValue   Current search value
 * @param props.dataGroupsAssigned        Groups currently checked
 * @param props.dataGroupsSaved           Groups saved
 * @param props.dataMembersAssigned       Members currently checked
 * @param props.dataMembersSaved          Members saved
 * @param props.debugLoading              Whether to permanently enable loading state
 * @param props.nodeType                  Whether the compose form is being used for messages or templates
 * @param props.requestAssigneeSearch     Request update to search term
 * @param props.requestAssignGroupUpdate  Request to (un)check a group
 * @param props.requestAssignMemberUpdate Request to (un)check a member
 * @param props.requestAssignSave         Request to store currently selected items to saved
 * @param props.topicId                   The ID of the topic we're composing for
 * @returns                               The container itself
 */
const ComposeMetaAssignmentContainer: FC<Props> = ({
  dataAssigneeSearchValue,
  dataGroupsAssigned,
  dataGroupsSaved,
  dataMembersAssigned,
  dataMembersSaved,
  debugLoading,
  nodeType,
  requestAssigneeSearch,
  requestAssignGroupUpdate,
  requestAssignMemberUpdate,
  requestAssignSave,
  topicId,
}) => {
  const [{ data, fetching }] = useQuery({
    pause: topicId === null,
    query: TopicAssignableDocument,
    variables: { topicId: topicId ?? '' },
  });

  const mode: ComposeModeParam = 'assign';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  const dataGroupsAll = data?.topicAssignableGroups ?? [];
  const dataMembersAll = data?.topicAssignableMembers ?? [];
  const groups = dataGroupsAll.filter(({ id }) => dataGroupsSaved.has(id));
  const users = dataMembersAll.filter(({ id }) => dataMembersSaved.has(id));

  const isLoading = debugLoading || fetching;

  return (
    <MetaAssignment
      dataGroupsAll={dataGroupsAll}
      dataGroupsAssigned={dataGroupsAssigned}
      dataGroupsSaved={dataGroupsSaved}
      dataMembersAll={dataMembersAll}
      dataMembersAssigned={dataMembersAssigned}
      dataMembersSaved={dataMembersSaved}
      dataSearchValue={dataAssigneeSearchValue}
      inputCustom={<ComposeMetaAssignees groups={groups} users={users} />}
      isActive={isActive}
      isLoading={isLoading}
      linkToggleProps={linkToggleProps}
      nodeType={nodeType}
      onAssignUpdateGroup={requestAssignGroupUpdate}
      onAssignUpdateMember={requestAssignMemberUpdate}
      onSearch={requestAssigneeSearch}
      requestAssignSave={() => {
        requestAssignSave();
        goBack();
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaAssignmentContainer);
