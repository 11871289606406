/**
 * @file Contains function for getting user's initials
 */

import { User } from '../../generated/graphql';

import { getNameChecked } from './userGetName';

/**
 * Helper function that check does user have full name
 *
 * @param firstName User's first name
 * @param lastName  User's last name
 * @returns         Boolean that tells does user have full name
 */
const hasNoName = (
  firstName: User['firstName'],
  lastName: User['lastName'],
): boolean => {
  return firstName === undefined && lastName === undefined;
};

/**
 * Returns user's initials based on his name
 * if null, empty or undefined, returns null
 *
 * @param firstName User's first name
 * @param lastName  User's last name
 * @returns         "GF" or trimmed string
 */
const userGetInitials = (
  firstName: User['firstName'],
  lastName: User['lastName'],
): string => {
  const firstNameChecked = getNameChecked(firstName);
  const lastNameChecked = getNameChecked(lastName);

  if (hasNoName(firstNameChecked, lastNameChecked)) {
    return 'GF';
  }
  if (firstNameChecked === undefined && lastNameChecked !== undefined) {
    return lastNameChecked.substring(2, 0).toUpperCase();
  }
  if (firstNameChecked !== undefined && lastNameChecked === undefined) {
    return firstNameChecked.substring(2, 0).toUpperCase();
  }
  const firstInitial = firstNameChecked?.substring(1, 0);
  const lastInitial = lastNameChecked?.substring(1, 0);

  return `${firstInitial}${lastInitial}`.toUpperCase();
};

export default userGetInitials;
