import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import ButtonTranslateContainer from '../../../../containers/buttonTranslate';

import { Message } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useIsTablet from '../../../../hooks/useIsTablet';
import useTargetLanguage from '../../../../hooks/useTargetLanguage';

import translate from '../../../../i18n/translate';
import { getMessageDetailsUrl } from '../../../../routes/urls/message';

import MessageDelete from '../../../Message/MessageDelete';

import { NAMES } from '../../LinkIcon';

import MessageArchive from '../../MessageArchive';

import * as Styled from './styled';

type Props = {
  messageId: Message['id'];
  shouldShowArchiveButton: boolean;
  shouldShowDeleteButton: boolean;
};

const ICON_SIZE = 20;

/**
 * A message ribbon component used to open message details or delete message or archive message
 *
 * @param props                         Props passed to the component
 * @param props.messageId               ID of the message
 * @param props.shouldShowArchiveButton Whether to show archive button when user has permissions
 * @param props.shouldShowDeleteButton  Whether to show delete button when user has permissions
 * @returns                             A message ribbon component
 */
const MessageRibbon: FC<Props> = ({
  messageId,
  shouldShowArchiveButton,
  shouldShowDeleteButton,
}) => {
  const isTablet = useIsTablet();
  const location = useLocation();
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const targetLanguage = useTargetLanguage();

  return (
    <Styled.Ribbon>
      {targetLanguage !== null && (
        <ButtonTranslateContainer container="feed" messageId={messageId} />
      )}
      <Styled.EditIcon
        color="var(--color-icons-inactive)"
        colorHover="var(--color-hover-dark)"
        iconHeight={ICON_SIZE}
        iconName={NAMES.COMPOSE__LABEL_EDIT}
        iconWidth={ICON_SIZE}
        label={translate('GENERAL__EDIT')}
        url={getMessageDetailsUrl(
          hotelId,
          isTablet,
          location,
          messageId,
          topicId,
        )}
      />
      <MessageArchive
        messageId={messageId}
        shouldShowContent={shouldShowArchiveButton}
        shouldUseIcon={true}
      />
      {/* This has to be stopped from rendering if we should not */}
      {/* because ribbon will render an empty <li> tag for it */}
      {/* and we don't want that. */}
      {shouldShowDeleteButton && (
        <MessageDelete
          messageId={messageId}
          shouldShowContent={shouldShowDeleteButton}
          shouldUseIcon={true}
        />
      )}
    </Styled.Ribbon>
  );
};

export default MessageRibbon;
