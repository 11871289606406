/**
 * @file contains helper functions for QuickFilters
 */
import { Location } from 'react-router-dom';

import { SearchQuery } from '../../generated/graphql';
import {
  getSearchParamFilterMembers,
  getSearchParamFilterQuick,
} from '../../routes/helpers/searchParams';

/**
 * Check whether the kanban quick filter is active
 *
 * @param location Current location
 * @returns        Whether it's true
 */
export const getIsKanbanQuickFilterActive = (location: Location): boolean => {
  return getSearchParamFilterMembers(location) !== null;
};

/**
 * Check whether the feed quick filter is active
 *
 * @param location Current location
 * @param filterId Quick filter ID
 * @returns        Whether it's true
 */
export const getIsQuickFilterActive = (
  location: Location,
  filterId: SearchQuery['id'],
): boolean => {
  return getSearchParamFilterQuick(location) === filterId;
};
