import React, { FC, useId, useState } from 'react';

import { Message } from '../../generated/graphql';
import translate from '../../i18n/translate';
import MessageTextForm from '../Forms/MessageTextForm';
import MetaContainerInline from '../MetaContainerInline';

type Props = {
  className?: string;
  description: Message['text'];
  onClose?: () => void;
  requestSave: (description: Message['text'], title: Message['title']) => void;
  title: Message['title'];
};

/**
 * Title inline component for message compose/edit on mobile
 *
 * @param props             Props passed to the component
 * @param props.className   styled-components generated class name, needed for styling
 * @param props.description Message description
 * @param props.onClose     Callback to be invoked when the user clicks close button
 * @param props.requestSave Callback to be invoked when user submits the form
 * @param props.title       Message title
 * @returns                 The component itself
 */
const MetaText: FC<Props> = ({
  className,
  description,
  onClose,
  requestSave,
  title,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const idForm = useId();
  const idHeading = useId();
  const textHeading = `${translate('GENERAL__TITLE')} & ${translate(
    'GENERAL__DESCRIPTION',
  )}`;

  // Popup top buttons labels
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  return (
    <MetaContainerInline
      className={className}
      idForm={idForm}
      idHeading={idHeading}
      isSaveDisabled={isSaveDisabled}
      labelClose={labelClose}
      labelConfirm={labelConfirm}
      onClose={onClose}
      textHeading={textHeading}
    >
      <MessageTextForm
        descriptionUsage="messageEdit"
        formId={idForm}
        isDisabled={false}
        requestSave={requestSave}
        requestSaveDisabled={setIsSaveDisabled}
        text={description}
        title={title}
      />
    </MetaContainerInline>
  );
};

export default MetaText;
