import React, { FC } from 'react';

import { User, UserStatus } from '../../../generated/graphql';
import getAvatarAltText from '../../../utils/getAvatarAltText';

import { memberGetImageUrl } from '../../../utils/imageUrlGet';
import userGetInactive from '../../../utils/user/userGetInactive';
import userGetInitials from '../../../utils/user/userGetInitials';
import AvatarWrapper from '../AvatarWrapper';
import Icon, { NAMES } from '../Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  fontSize?: number;
  size?: number;
  user: Pick<User, 'firstName' | 'lastName' | 'status' | 'imageUrl'> | null;
};

// Fallback values, in case user prop passed is null or undefined
const userDefault: Props['user'] = {
  firstName: null,
  imageUrl: null,
  lastName: null,
  status: UserStatus.Active,
};

// The icon to use if imageUrl is null
const iconName = NAMES.GENERAL__AVATAR;

/**
 * Member avatar
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.fontSize  The font-size of the avatar initials
 * @param props.size      Size of the the avatar image
 * @param props.user      The user to generate the avatar for
 * @returns               The component itself
 */
const Avatar: FC<Props> = ({ className, fontSize = 0.65, size = 33, user }) => {
  const { imageUrl, status } = user ?? userDefault;

  // src could be an absolute path, but will usually be relative,
  // and that needs to be prefixed with Media Library API URL,
  // so we call this to check what we got and format it properly
  const srcAbsolute = memberGetImageUrl(imageUrl);
  const isDeleted = status === UserStatus.Deleted;
  // Used for avatar styles shared between deleted, deactivated and not assigned users
  const isInactive = userGetInactive(status);

  if (srcAbsolute !== null) {
    return (
      <Styled.ImgWrapper data-reduced-opacity={isInactive} data-size={size}>
        <Styled.Img
          alt={getAvatarAltText(user)}
          className={className}
          src={srcAbsolute}
        />
      </Styled.ImgWrapper>
    );
  }

  const { firstName, lastName } = user ?? userDefault;

  return (
    <AvatarWrapper
      aria-hidden="true"
      className={className}
      fontSize={fontSize}
      isDeleted={isDeleted}
      isInactive={isInactive}
      size={size}
    >
      {(isDeleted && <Icon height={size} name={iconName} width={size} />) ||
        userGetInitials(firstName, lastName)}
    </AvatarWrapper>
  );
};

export default Avatar;
