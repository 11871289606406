/**
 * @file contains functions used to create paths for use in Route related to the feed page
 */
import { ROUTE__FEED } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the feed
 *
 * @returns The generated path
 */
export const createFeedPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__FEED}`;
};

/**
 * Generate the URL for the feed showing the topic
 *
 * @returns The generated path
 */
export const createFeedTopicPath = (): string => {
  return `${createFeedPath()}/:topicId`;
};

/**
 * Generate the URL for the feed showing the topic details
 *
 * @returns The generated path
 */
export const createFeedTopicDetailsPath = (): string => {
  return `${createFeedTopicPath()}/details`;
};

/**
 * Generate the URL for the feed showing the topic details edit mode
 *
 * @returns The generated path
 */
export const createFeedTopicDetailsEditPath = (): string => {
  return `${createFeedTopicDetailsPath()}/:topicEditMode`;
};

/**
 * Generate the Path for the feed showing the post
 *
 * @returns The generated path
 */
export const createFeedMessagePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__FEED}/:topicId/post/:messageId`;
};

/**
 * Generate a path for the feed showing the message details
 *
 * @returns The generated path
 */
export const createFeedMessageDetailsPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__FEED}/:topicId/post/:messageId/details`;
};

/**
 * Generate a path for the feed showing the message details edit mode
 *
 * @returns The generated path
 */
export const createFeedMessageDetailsEditPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__FEED}/:topicId/post/:messageId/details/:messageEditMode`;
};
