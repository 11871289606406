/**
 * @file Helper functions related to service workers
 * (registering, un-registering, support check)
 */

import { SW_URL } from './config';
import { reportInfo, reportWarning } from './services/reporting';
import { reportApiErrors } from './utils/error';

/**
 * Check if service worker is supported in the browser
 * Also, don't use service worker in web views
 *
 * @returns Whether to use it
 */
export const checkServiceWorkerSupport = (): boolean => {
  if ('ReactNativeWebView' in globalThis) {
    return false;
  }

  return 'serviceWorker' in navigator;
};

/**
 * Check whether the browser supports showing notifications
 * (in general, push support is not checked)
 *
 * @returns Whether it's possible
 */
export const checkWebNotificationSupport = (): boolean => {
  return 'Notification' in globalThis;
};

/**
 * Check the current permission level for notifications
 * (if they're unsupported, will return null)
 *
 * @returns The permission level or null if unsupported
 */
export const checkPermissionLevel = (): NotificationPermission | null => {
  if ('ReactNativeWebView' in globalThis) {
    return null;
  }

  return checkWebNotificationSupport() ? Notification.permission : null;
};

/**
 * Check whether the browser can send push notifications
 *
 * @returns Whether it's available
 */
export const checkWebPushSupport = (): boolean => {
  return (
    checkWebNotificationSupport() &&
    checkServiceWorkerSupport() &&
    // Push is not supported when running Cypress in headless mode
    // Even though all regular checks say it is
    window.Cypress?.browser.isHeadless !== true &&
    'PushManager' in globalThis &&
    'ServiceWorkerRegistration' in globalThis &&
    'showNotification' in ServiceWorkerRegistration.prototype
  );
};

/**
 * Try to register a service worker
 *
 * We are reporting info instead of error because of FireFox behavior:
 * - registering serviceWorker fails when "Delete cookies and site data when Firefox is closed" is checked
 * - serviceWorker not working in Private browsing mode
 *
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=1755167
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=1320796
 * @returns The registered service worker
 */
export const register = async (): Promise<ServiceWorkerRegistration | null> => {
  if (checkServiceWorkerSupport() === false) {
    reportWarning('Service worker not supported');
  }

  reportInfo('ServiceWorker: Register');

  try {
    // Wait for sw to register to avoid FF error in local build
    const sw = await navigator.serviceWorker.register('/' + SW_URL);

    return sw;
  } catch (error) {
    reportInfo(error as string);
    return null;
  }
};

/**
 * Unregister the registered service worker
 */
export const unregister = async (): Promise<void> => {
  if (checkServiceWorkerSupport()) {
    const registration = await navigator.serviceWorker.ready;
    registration.unregister().catch(reportApiErrors);
  }
};
