import React, { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import useUserInitialize from '../../hooks/useUserInitialize';
import { ModalId } from '../../models/modal';
import { getSearchParamModalId } from '../../routes/helpers/searchParams';

const AttachmentDelete = lazy(() => import('./AttachmentDelete'));
const GroupCreate = lazy(() => import('./GroupCreate'));
const GroupDelete = lazy(() => import('./GroupDelete'));
const GroupUpdate = lazy(() => import('./GroupUpdate'));
const GroupView = lazy(() => import('./GroupView'));
const LabelCreate = lazy(() => import('./LabelCreate'));
const LabelDelete = lazy(() => import('./LabelDelete'));
const LabelUpdate = lazy(() => import('./LabelUpdate'));
const MessageArchive = lazy(() => import('./MessageArchive'));
const MessageDelete = lazy(() => import('./MessageDelete'));
const MessageUnArchive = lazy(() => import('./MessageUnArchive'));
const TemplateDelete = lazy(() => import('./TemplateDelete'));
const TemplateRecurringRuleDelete = lazy(
  () => import('./TemplateRecurringRuleDelete'),
);
const TopicCreate = lazy(() => import('./TopicCreate'));
const TopicDelete = lazy(() => import('./TopicDelete'));
const TopicUpdate = lazy(() => import('./TopicUpdate'));
const UserUpdate = lazy(() => import('./UserUpdate'));

const modalComponents: Record<ModalId, React.FC> = {
  'group-create': GroupCreate,
  'group-delete': GroupDelete,
  'group-edit': GroupUpdate,
  'group-view': GroupView,
  'label-create': LabelCreate,
  'label-delete': LabelDelete,
  'label-edit': LabelUpdate,
  'post-archive': MessageArchive,
  'post-attachment-delete': AttachmentDelete,
  'post-delete': MessageDelete,
  'post-unarchive': MessageUnArchive,
  'template-delete': TemplateDelete,
  'template-recurring-rule-delete': TemplateRecurringRuleDelete,
  'topic-create': TopicCreate,
  'topic-delete': TopicDelete,
  'topic-edit': TopicUpdate,
  'user-edit': UserUpdate,
};

/**
 * Modal container that uses deep linking to determine which modal to show
 * if any.
 *
 * @returns Modal component if any should be shown
 */
const ModalsContainer = () => {
  const location = useLocation();
  const isInitialized = useUserInitialize();

  // Currently all modals are for authenticated users
  if (isInitialized === false) {
    return null;
  }

  const modalId = getSearchParamModalId(location);

  if (modalId === null) {
    return null;
  }

  const ModalComponent = modalComponents[modalId];

  return (
    <Suspense fallback={null}>
      <ModalComponent />
    </Suspense>
  );
};

export default ModalsContainer;
