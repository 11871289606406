/**
 * @file Deep copy of attachment local state data
 */
import { DraftAttachmentDataFormatted } from '../models/draftAttachment';

/**
 * Initialize attachment state for passed parentId
 *
 * @param parentId The parent to init a draft for attachments local state (0 for root)
 * @returns        void
 */
const initAttachmentsDraft = (
  parentId: string,
): DraftAttachmentDataFormatted => ({
  attachments: new Map(),
  parentId,
});

export default initAttachmentsDraft;
