import { BottomNavigation, BottomNavigationAction, Chip } from '@mui/material';
import React, { FC } from 'react';

import useUnreadMessagesCount from '../../containers/leftSidebar/useUnreadMessagesCount';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';

import useCurrentPath from '../../hooks/useCurrentPath';
import translate from '../../i18n/translate';
import { ChatBubble } from '../../integrations/roqChat/ChatBubble';
import { useIsChatEnabled } from '../../integrations/roqChat/useIsChatEnabled';
import MuiIcon from '../MUI/MuiIcon';

import NotificationsBadge from '../MUI/NotificationsBadge';

import { getActiveNavItemIndex, getNavLinks } from './helpers';
import * as Styled from './styled';
import { FooterNavLink, Props } from './types';

/**
 * Bottom Navigation Bar that is shown only on mobile screens
 *
 * @param props                Props passed to the component
 * @param props.floatingButton Floating button to show about the navigation
 * @returns                    The component itself
 */
const FloatingBottomNav: FC<Props> = ({ floatingButton }) => {
  const currentPath = useCurrentPath() ?? '';
  const hotelId = useParamHotelId();
  const isChatEnabled = useIsChatEnabled();
  const unreadMessagesCount = useUnreadMessagesCount();

  const links = getNavLinks({
    hotelId,
    isChatEnabled,
    unreadMessagesCount,
  });

  const activeIndex = getActiveNavItemIndex(currentPath, links);

  /**
   * Get navigation icon based on icon name or ROQ chat bell component for chat
   *
   * @param link Link with navigation item information
   * @returns    Icon wrapped in badge or ROQ chat component for chat
   *
   * @summary We have to use ROQ chat component to show proper number of unread chat messages
   */
  const getNavIcon = (link: FooterNavLink) => {
    const { iconName, badgeCount } = link;

    if (iconName === 'chatBubble') {
      return (
        <ChatBubble>
          <MuiIcon fontSize="small" icon={iconName} />
        </ChatBubble>
      );
    }

    return (
      <NotificationsBadge badgeContent={badgeCount}>
        <MuiIcon fontSize="small" icon={iconName} />
      </NotificationsBadge>
    );
  };

  return (
    <Styled.Nav>
      {floatingButton && (
        <Styled.FloatingButtonWrapper>
          {floatingButton}
        </Styled.FloatingButtonWrapper>
      )}
      <BottomNavigation showLabels={true} value={activeIndex}>
        {links.map((link, index) => (
          <BottomNavigationAction
            aria-current={index === activeIndex ? 'page' : false}
            component={Styled.NavLink}
            disableTouchRipple={true}
            disabled={link.isDisabled}
            icon={<Chip icon={getNavIcon(link)} />}
            key={link.url}
            label={translate(link.label)}
            to={link.url}
          />
        ))}
      </BottomNavigation>
    </Styled.Nav>
  );
};

export default FloatingBottomNav;
