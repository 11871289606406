/**
 * @file contains a custom react hook for using topic latest post functionality
 */
import { useEffect } from 'react';

import { Message, Topic, User } from '../../../generated/graphql';
import useFeedScrollState from '../../../hooks/store/useFeedScrollState';
import { getUserCurrent } from '../../../utils/permissions/storage';

type Params = {
  creator: User['id'] | null;
  isActionCreated: boolean;
  messageId: Message['id'] | null;
  topicId: Topic['id'];
};

/**
 * Hook that sets redux state of the topics that should show the `latest post` button
 *
 * @param params                 Params passed to the hook
 * @param params.creator         ID of the message creator
 * @param params.isActionCreated Whether the action is a create action
 * @param params.messageId       ID of the message, we need to react if the new message arrives
 * @param params.topicId         ID of the topic
 */
const useLatestPost = ({
  creator,
  isActionCreated,
  messageId,
  topicId,
}: Params): void => {
  const currentUser = getUserCurrent();

  const {
    isTopicScrolled,
    requestScrollResetFeed,
    requestScrollTopicsLatestPostAdd,
    topicHasLatestPost,
  } = useFeedScrollState(topicId);

  useEffect(() => {
    // Stop execution if message isn't created
    if (isActionCreated === false) {
      return;
    }

    /**
     * Scroll topic to bottom if creator is the current user
     */
    if (currentUser?.id === creator) {
      requestScrollResetFeed();
      return;
    }

    // Only show latest post for topic if these conditions are met
    const shouldAddTopicToLatestPosts =
      isTopicScrolled && topicHasLatestPost === false;

    if (shouldAddTopicToLatestPosts) {
      requestScrollTopicsLatestPostAdd();
    }

    /**
     * We only want to call this effect when messageId changes, meaning
     * that something new has happened to the messages.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);
};

export default useLatestPost;
