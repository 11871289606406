import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

export type Props = {
  children: ReactNode | ReactNode[];
  linkTo: string;
};

/**
 * MessageSection heading
 *
 * @param props          Props passed to the component
 * @param props.children The heading text
 * @param props.linkTo   Location for Link component
 * @returns              The component itself
 */
const MessageSectionHeading: FC<Props> = ({ children, linkTo }) => {
  return (
    <header>
      <Styled.HeadingWrapperLink to={linkTo} type="button">
        {children}
      </Styled.HeadingWrapperLink>
    </header>
  );
};

export default MessageSectionHeading;
