import { styled } from 'styled-components';

import Icon from '../';

export type Direction = 'up' | 'down' | 'left' | 'right';

type TriangleProps = {
  'data-direction': Direction;
};

/**
 * Get the rotation angle to get the triangle
 * pointing in the desired direction
 *
 * @param props Props passed to the component
 * @returns     The rotation, in degrees
 */
const getRotation = (props: TriangleProps): number => {
  const mapping: Record<Required<Direction>, number> = {
    up: 270,
    // Sorting by direction
    // eslint-disable-next-line sort-keys
    down: 90,
    left: 180,
    right: 0,
  };

  return mapping[props['data-direction']];
};

export const Triangle = styled(Icon)<TriangleProps>`
  display: block;
  transform: rotate(${getRotation}deg);
  transform-origin: center;
`;
