/**
 * @file Exchange factory that re-executes operations after a user returns to the tab.
 */

import type { Exchange, Operation } from '@urql/core';
import { OperationType } from 'urql';
import { pipe, tap } from 'wonka';

/**
 * Exchange factory that re-executes operations after a user returns to the tab.
 *
 * The `refocusExchange` will re-execute `Operation`s with the `cache-and-network`
 * policy when a user switches back to your application's browser tab. This can
 * effectively update all on-screen data when a user has stayed inactive for a
 * long time.
 *
 * The `cache-and-network` policy will refetch data in the background, but will
 * only refetch queries that are currently active.
 *
 * @returns a new refocus {@link Exchange}.
 */
export const refocusExchange = (): Exchange => {
  return ({ client, forward }) =>
    ops$ => {
      if (typeof window === 'undefined') {
        return forward(ops$);
      }

      const watchedOperations: Record<OperationType, Map<number, Operation>> = {
        mutation: new Map(),
        query: new Map(),
        subscription: new Map(),
        teardown: new Map(),
      };

      /**
       * On coming into focus, re-execute watched queries and subscriptions
       */
      window.addEventListener('visibilitychange', () => {
        if (
          typeof document !== 'object' ||
          document.visibilityState === 'visible'
        ) {
          Object.entries(watchedOperations).forEach(([operation, map]) => {
            map.forEach(op => {
              client.reexecuteOperation(
                client.createRequestOperation(operation as OperationType, op, {
                  ...op.context,
                  requestPolicy: 'cache-and-network',
                }),
              );
            });
          });
        }
      });

      /**
       * Process the operation that's taking place
       *
       * If it's teardown, stop watching the operation
       * (we don't want to re-query data for unmounted components on refocus)
       *
       * If it's a query or a subscription, add it to the set of operations
       * to be re-executed after refocusing
       *
       * Mutations don't need to be re-executed on refocus
       *
       * And then just forward the operation to the next exchange
       *
       * @param op The operation that took place
       */
      const processIncomingOperation = (op: Operation) => {
        if (op.kind === 'teardown') {
          Object.keys(watchedOperations).forEach(operation => {
            watchedOperations[operation as OperationType].delete(op.key);
          });
        } else if (op.kind !== 'mutation') {
          watchedOperations[op.kind].set(op.key, op);
        }
      };

      return forward(pipe(ops$, tap(processIncomingOperation)));
    };
};
