/**
 * @file URL mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';
import { NavigationFunction } from '../types';

import mapperChat from './mapperChat';
import mapperChecklist from './mapperChecklist';
import mapperComment from './mapperComment';
import mapperLabel from './mapperLabel';
import mapperMessage from './mapperMessage';
import mapperReminder from './mapperReminder';
import mapperTopic from './mapperTopic';
import mapperUser from './mapperUser';
import mapperUserGroup from './mapperUserGroup';

const mapping: Record<NotificationEvent, NavigationFunction> = {
  ...mapperChat,
  ...mapperChecklist,
  ...mapperComment,
  ...mapperLabel,
  ...mapperMessage,
  ...mapperReminder,
  ...mapperTopic,
  ...mapperUser,
  ...mapperUserGroup,
};

export default mapping;
