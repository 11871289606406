import { styled } from 'styled-components';

import CustomHashLink from '../CustomHashLink';

export const Wrapper = styled.article`
  align-items: center;
  display: inline-flex;
`;

export const HashLink = styled(CustomHashLink)`
  display: inline-grid;
  gap: var(--spacing-tiny);
  grid-template-columns: max-content auto;
  margin: 0 var(--spacing-medium) var(--spacing-tiny) 0;
  place-items: center;
`;
