/**
 * @file contains hook that checks whether the compose form should be shown
 */
import { getIsFeedKanbanPage } from '../../../routes/helpers/pages';
import { getCanCreateMessage } from '../../../utils/permissions/message';
import useIsMobile from '../../useIsMobile';
import useParamTopicId from '../params/useParamTopicId';

import useIsFilterMembersActive from './useIsFilterMembersActive';
import useIsFilterQuickActive from './useIsFilterQuickActive';

/**
 * Whether we should show the compose form / button or not
 *
 * @returns Whether it's true
 */
const useComposeModeShown = (): boolean => {
  const createMessagePermission = getCanCreateMessage();
  const isMobile = useIsMobile();
  const isKanban = getIsFeedKanbanPage();
  const isFilterMembersActive = useIsFilterMembersActive();
  const isFilterQuickActive = useIsFilterQuickActive();
  const topicId = useParamTopicId();

  const sharedCondition =
    isMobile === false && createMessagePermission && topicId !== null;

  if (isKanban) {
    return sharedCondition && !isFilterMembersActive;
  }

  return sharedCondition && !isFilterQuickActive;
};

export default useComposeModeShown;
