import { List, Stack, styled } from '@mui/material';

export const NavigationList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  width: 'var(--preference-left-sidebar-closed-width-desktop)',
}));

export const Rail = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: theme.spacing(3.75, 0),
  scrollbarColor: `${theme.palette.primary.dark} transparent`,
  width: '100%',
}));

export const RailWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  width: 'var(--preference-left-sidebar-closed-width-desktop)',
}));
