import { styled } from 'styled-components';

import devices from '../../../styles/devices';

export const Wrapper = styled.div`
  padding: var(--spacing-medium);

  @media ${devices.tabletS} {
    padding: 0 var(--spacing-medium);
  }
`;
