/**
 * @file Hook for handling attachment download
 */

import { useEffect } from 'react';

import { useQuery } from 'urql';

import {
  File as CustomFile,
  FileSignedDownloadUrlDocument,
  Message,
} from '../../generated/graphql';
import { AttachmentActionType } from '../../models/attachment';
import { reportError } from '../../services/reporting';

/**
 * Hook for handling attachment download
 *
 * @param actionType Type of action (download/preview)
 * @param key        Key of the attachment to download
 * @param messageId  Id of the current message
 * @returns          Object with necessary props for handling file download
 */
const useAttachmentGetDownloadUrl = (
  actionType: AttachmentActionType,
  key: CustomFile['key'],
  messageId: Message['id'],
) => {
  const [queryData, reExecuteQuery] = useQuery({
    pause: true,
    query: FileSignedDownloadUrlDocument,
    requestPolicy: 'network-only',
    variables: {
      isDownloadUrl: actionType === 'download',
      key,
      messageId,
    },
  });

  const { error } = queryData;

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);

  return { queryData, reExecuteQuery };
};

export default useAttachmentGetDownloadUrl;
