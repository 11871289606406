/**
 * @file Determine which notifications modal to show, if any,
 * based on the linked diagrams
 * This one is for "First message created" action
 */

import { useEffect, useState } from 'react';

import useIsSupported from '../../../../../hooks/notifications/useIsSupported';

import useNotificationPermissionStatus from '../../../../../hooks/notifications/useNotificationPermissionStatus';
import { getModalPurpleDismissed } from '../../../../../utils/storage/notifications';
import { NotificationModal } from '../helpers';

import useFirstMessageCreated from './useFirstMessageCreated';

/**
 * Determine which notifications modal to show, if any,
 * based on the linked diagrams
 * This one is for "First message created" action
 *
 * @see     https://app.diagrams.net/#G1belOmzoJ_04rEnWU8WMkeGsToqeQyvty
 * @returns Which modal to show (if any)
 */
const useShouldShowOnFirstMessage = () => {
  const [toShow, setToShow] = useState<NotificationModal | null>(null);

  const firstMessageCreated = useFirstMessageCreated();
  const isSupported = useIsSupported();
  const permission = useNotificationPermissionStatus();

  useEffect(() => {
    if (isSupported === false) {
      return setToShow(null);
    }

    if (permission === null || permission === 'granted') {
      return setToShow(null);
    }

    if (firstMessageCreated === false) {
      return setToShow(null);
    }

    if (getModalPurpleDismissed() === true) {
      return setToShow(null);
    }

    setToShow('purple');
  }, [firstMessageCreated, isSupported, permission]);

  return toShow;
};

export default useShouldShowOnFirstMessage;
