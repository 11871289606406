/**
 * @file Optimistic update configuration,
 * Resolvers here update local cache before the response from the server arrives
 */

import { OptimisticMutationConfig } from '@urql/exchange-graphcache';

import checklistReorder from './checklist/checklistReorder';
import checklistSetState from './checklist/checklistSetState';
import userGroupDelete from './groups/groupDelete';
import userGroupUpdate from './groups/groupUpdate';
import labelDelete from './labels/labelDelete';
import labelUpdate from './labels/labelUpdate';
import messageArchive from './messages/messageArchive';
import messageStatusUpdate from './messages/messageStatusUpdate';
import messageUnarchive from './messages/messageUnarchive';
import messageUpdate from './messages/messageUpdate';
import messagesRead from './messages/messagesRead';
import templateUpdate from './templates/templateUpdate';
import topicDelete from './topics/topicDelete';
import topicUpdate from './topics/topicUpdate';
import userActivate from './user/userActivate';
import userDeactivate from './user/userDeactivate';
import userUpdate from './user/userUpdate';

/**
 * Handles cache optimistic updates when a mutation is performed. Usually used on update operations, when we want
 * the UI to make a seamless transition
 */
const optimistic: OptimisticMutationConfig = {
  checklistCheckItem: checklistSetState,
  checklistReorder,
  labelDelete,
  labelUpdate,
  messageArchive,
  messageStatusUpdate,
  messageUnarchive,
  messageUpdate,
  messagesRead,
  templateUpdate,
  topicDelete,
  topicUpdate,
  userActivate,
  userDeactivate,
  userGroupDelete,
  userGroupUpdate,
  userUpdate,
};

export default optimistic;
