/**
 * @file Contains customization for MuiChip Component
 */

import { Components } from '@mui/material';

import {
  colorErrorStyle,
  colorNeutralStyle,
  colorSecondaryStyle,
  colorSuccessStyle,
} from './shared';

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {},
  variants: [
    {
      props: { color: 'error' },
      style: colorErrorStyle,
    },
    {
      props: { color: 'neutral' },
      style: colorNeutralStyle,
    },
    {
      props: { color: 'secondary' },
      style: colorSecondaryStyle,
    },
    {
      props: { color: 'success' },
      style: colorSuccessStyle,
    },
  ],
};

export default MuiChip;
