import { Stack, StackProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import { colorPrimary } from '../../../styles/mui/colors';

export const Wrapper = styled(Stack)<StackProps & LinkProps>(({ theme }) => ({
  '& .MuiChip-root': {
    backgroundColor: colorPrimary[200],
  },
  '&:focus-visible .MuiChip-root': {
    backgroundColor: colorPrimary[400],
  },
  '&:hover .MuiChip-root': {
    backgroundColor: colorPrimary[300],
  },
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));
