import React, { FC } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  LabelCreateDocument,
  LabelUpdateDocument,
  MessageLabelsDocument,
  MessageUpdateDocument,
} from '../../../generated/graphql';
import useRedirectOnError from '../../../hooks/useRedirectOnError';
import { reportApiErrors } from '../../../utils/error';
import { Color, LabelID, PropsInner, Text } from '../props';

import { getLabelsAll, getLabelsSelected } from './helpers';
import Inner from './inner';

/**
 * Labels mode for message full
 *
 * @param props                       Props passed to the component
 * @param props.messageId             The message that the assignment is for
 * @param props.networkMessageDetails Whether the debug option for fetching this is on
 * @param props.requestModeOverview   Request to revert to labels overview mode
 * @returns                           The component itself
 */
const MessageLabels: FC<PropsInner> = ({
  messageId,
  networkMessageDetails,
  requestModeOverview,
}) => {
  const handleErrorRedirect = useRedirectOnError();
  const [{ data, error, fetching }] = useQuery({
    query: MessageLabelsDocument,
    variables: { messageId },
  });

  const [, labelCreateMutation] = useMutation(LabelCreateDocument);
  const [, labelUpdateMutation] = useMutation(LabelUpdateDocument);
  const [, messageUpdateMutation] = useMutation(MessageUpdateDocument);

  if (error) {
    handleErrorRedirect(error);
    return null;
  }

  /**
   * Invoke the label create mutation
   *
   * @param text  New label title
   * @param color New label color
   */
  const requestCreate = (text: Text, color: Color): void => {
    labelCreateMutation({ color, text }).catch(reportApiErrors);
  };

  /**
   * Invoke label update mutation
   *
   * @param id    The ID of the label to update
   * @param text  New label title
   * @param color New label color
   */
  const requestUpdate = (id: LabelID, text: Text, color: Color): void => {
    labelUpdateMutation({ color, id, text }).catch(reportApiErrors);
  };

  /**
   * Request that assignment is updated
   *
   * @param labels All the labels for the message
   */
  const requestSave = (labels: LabelID[]): void => {
    messageUpdateMutation({
      data: { labels },
      id: messageId,
    }).catch(reportApiErrors);
  };

  return (
    <Inner
      isLoading={fetching === true || networkMessageDetails === true}
      labelsAll={getLabelsAll(data)}
      labelsSaved={getLabelsSelected(data)}
      requestCreate={requestCreate}
      requestModeOverview={requestModeOverview}
      requestSave={requestSave}
      requestUpdate={requestUpdate}
    />
  );
};

export default MessageLabels;
