/**
 * @file Reacts to message or topic being deleted with a redirect and unsubscribe action
 */

import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

type Params = {
  redirectUrl: string | null;
  unsubscribeAction: (() => void) | null;
};

/**
 * Reacts to message or topic being deleted with a redirect and unsubscribe action
 *
 * @param params                   Params passed to the hook
 * @param params.redirectUrl       Url to redirect to if redirect is required
 * @param params.unsubscribeAction Action to unsubscribe from messages or comments subscription
 */
const useActionDeleted = ({ redirectUrl, unsubscribeAction }: Params): void => {
  const navigate = useNavigate();

  const redirect = useCallback(() => {
    if (redirectUrl !== null) {
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, redirectUrl]);

  useEffect(() => {
    /**
     * Unsubscribe from comments or messages
     */
    if (unsubscribeAction !== null) {
      unsubscribeAction();
    }
  }, [unsubscribeAction]);

  useEffect(() => {
    /**
     * Redirect user if needed
     */
    if (redirectUrl !== null) {
      redirect();
    }
  }, [redirectUrl, redirect]);
};

export default useActionDeleted;
