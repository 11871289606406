import React, { FC } from 'react';

import { ComposeNodeType } from '../../../containers/compose/props';
import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  nodeType: ComposeNodeType;
};

/**
 * No result for assignment list
 *
 * @param props          Props passed to the component
 * @param props.nodeType Whether the compose form is being used for messages or templates
 * @returns              The component itself
 */
const AssignmentNoResult: FC<Props> = ({ nodeType }) => {
  const translationKey =
    nodeType === 'MESSAGE'
      ? 'ASSIGNMENT__GENERAL__NO_RESULTS'
      : 'ASSIGNMENT__GENERAL__NO_RESULTS_TOPIC';

  return <Styled.P>{translate(translationKey)}</Styled.P>;
};

export default AssignmentNoResult;
