/**
 * @file  Hook for attachment upload and removal in Compose
 */
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'urql';

import {
  FILE__EXTENSIONS,
  FILE__MAX_FILE_SIZE,
  FILE__MAX_FILES_UPLOAD,
} from '../../constants/forms';
import {
  File as CustomFile,
  FileSignedUploadPostFieldsDocument,
  Message,
} from '../../generated/graphql';
import {
  AttachmentDraftMapEntry,
  AttachmentDraftPreUploadMapEntry,
} from '../../models/attachment';
import {
  clearDraftAttachmentRequest,
  initDraftAttachmentsRequest,
  removeDraftAttachmentRequest,
} from '../../store/actions/draftsAttachments';
import { InitialState } from '../../store/initialState';
import deviceInfo from '../../utils/device/deviceInfo';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';

import {
  formatFilename,
  getAttachmentErrors,
  getFileExtension,
  showFileToastError,
} from './helpers';
import useFileUpload from './useFileUpload';

/**
 * Hook for handling file upload
 *
 * @param parentId       The parent of current draft attachments local state
 * @param messageId      Id of the current message
 * @param combinedLength Length of already uploaded and draft attachments
 * @returns              Object with necessary props for handling file upload
 */
const useAttachmentsUpload = (
  parentId: string,
  messageId?: Message['id'],
  combinedLength = 0,
) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadFiles } = useFileUpload(dispatch, parentId);

  const isReactNativeWebView = getIsReactNativeWebView();

  const fileExtensions = Object.keys(FILE__EXTENSIONS)
    .map(key => `.${key}`)
    .toString();

  /**
   * Because iOS and Android don't handle extensions well we need to allow all file types for file input
   */
  const inputAccept =
    isReactNativeWebView ||
    deviceInfo.Android ||
    deviceInfo.iPad ||
    deviceInfo.iPhone
      ? undefined
      : fileExtensions;

  const draftsAttachmentsState = useSelector(
    (state: InitialState) => state.draftsAttachments,
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const shouldInitialize = draftsAttachmentsState.get(parentId) === undefined;

  const [{ data, fetching }, reExecuteQuery] = useQuery({
    pause: true,
    query: FileSignedUploadPostFieldsDocument,
    requestPolicy: 'network-only',
    variables: {
      fileNames,
    },
  });

  useEffect(() => {
    if (shouldInitialize) {
      dispatch(initDraftAttachmentsRequest(parentId));
    }
  }, [dispatch, parentId, shouldInitialize]);

  useEffect(() => {
    if (fileNames.length > 0) {
      reExecuteQuery();
    }
  }, [fileNames.length, reExecuteQuery]);

  useEffect(() => {
    if (fetching === false && data) {
      setFileNames([]);
      uploadFiles(data.fileSignedUploadPostFields, filesToUpload, messageId);
    }
    // We only want this useEffect to trigger on change of data and fetching props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetching]);

  /**
   * Clear draft state from store
   */
  const requestDraftClear = () => {
    dispatch(clearDraftAttachmentRequest(parentId));
  };

  /**
   * Remove attachment from store
   *
   * @param key Key of an attachment we want to remove
   */
  const requestAttachmentRemove = (key: CustomFile['key']) => {
    dispatch(removeDraftAttachmentRequest(parentId, key));
  };

  /**
   * Handle changes for files to be uploaded
   *
   * @param event onchange event
   */
  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (inputRef.current?.files) {
      const attachmentsSize =
        (draftsAttachmentsState.get(parentId)?.attachments.size ?? 0) +
        combinedLength;
      const fileList = Array.from(inputRef.current.files);

      const toastErrors = getAttachmentErrors(fileList, attachmentsSize);

      showFileToastError(dispatch, toastErrors);

      const filteredAttachments = fileList
        .filter(
          file =>
            file.size + combinedLength <= FILE__MAX_FILE_SIZE &&
            Object.keys(FILE__EXTENSIONS).includes(
              getFileExtension(file.name) ?? '',
            ) === true,
        )
        .slice(0, FILE__MAX_FILES_UPLOAD - attachmentsSize);

      if (filteredAttachments.length > 0) {
        filteredAttachments.forEach(file => formatFilename(file));

        setFileNames(filteredAttachments.map(file => file.name));
        setFilesToUpload(filteredAttachments);
      }

      // Clear current value so user can select same file(s) one after another
      event.target.value = '';
    }
  };

  /**
   * Remove file from local files array
   *
   * @param key Array index of file that we want to remove
   */
  const handleFileRemove = (key: CustomFile['key']) => {
    requestAttachmentRemove(key);
  };

  const inputProps = {
    accept: inputAccept,
    multiple: true,
    onChange,
    ref: inputRef,
  };

  const attachmentsDraft =
    draftsAttachmentsState.get(parentId)?.attachments ??
    new Map<
      CustomFile['key'],
      AttachmentDraftMapEntry | AttachmentDraftPreUploadMapEntry
    >();

  const attachmentsFormatted = Array.from(
    attachmentsDraft,
    ([key, { extension, file, isUploading }]) => ({
      extension: extension?.toLowerCase(),
      file,
      isDraft: true,
      isUploading,
      key,
      name: file.name,
    }),
  );

  return {
    attachmentsDraft,
    attachmentsFormatted,
    handleClearDraft: requestDraftClear,
    handleFileRemove,
    inputProps,
    inputRef,
  };
};

export default useAttachmentsUpload;
