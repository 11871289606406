import { styled } from 'styled-components';

type NameProps = {
  'data-width': number;
};

// Wrapper for all groups
export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-tiny);
`;

// Group name
export const Li = styled.li<NameProps>`
  height: var(--spacing-medium);
  width: ${props => props['data-width']}%;

  &:first-child {
    margin-left: 0;
  }
`;
