import { ButtonBase } from '@mui/material';
import { styled } from 'styled-components';

import ButtonIcon from '../ButtonIcon';

type WrapperProps = {
  style: {
    '--height': string;
  };
};

// Styling the base popup look
export const Wrapper = styled.div<WrapperProps>`
  background-color: var(--color-background);
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  bottom: 0;
  box-shadow: var(--box-shadow-strong);
  display: flex;
  flex-direction: column;
  height: var(--height);
  outline: none;
  place-self: flex-end center;
  position: absolute;

  /* touch-action: none is supported on 97.7% of mobile phones */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  touch-action: none;
  transition: height var(--preference-transition-duration-normal) ease-out;
  width: 100%;
  z-index: var(--z-1);
`;

export const HeaderContent = styled.div`
  display: inline-grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  justify-items: center;
  width: 100%;
`;

export const TrayButton = styled(ButtonBase)`
  background-color: transparent;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
`;

export const TrayButtonContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: flex-start;
  padding: var(--spacing-small);
`;

export const ResizeButton = styled(ButtonIcon)`
  justify-self: end;
  padding: var(--spacing-small);
`;

export const Content = styled.section`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;
