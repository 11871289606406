/**
 *
 * @file contains reporting-related functions
 *
 * This is one-stop for both logging and posting (eg. to Bugsnag)
 * So, based on config.ts, in production we wouldn't log errors but would post them,
 * and in development, it would be the other way around
 */

import Bugsnag, { BrowserConfig, Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  REPORT_LEVEL_ALERT,
  REPORT_LEVEL_LOG,
  REPORT_LEVEL_POST,
} from '../config';
import { BUGSNAG_CONFIG } from '../config-common';
import {
  LEVEL_CRITICAL,
  LEVEL_DEBUG,
  LEVEL_ERROR,
  LEVEL_INFO,
  LEVEL_WARNING,
} from '../constants/reportingLevels';
import { User } from '../generated/graphql';
import { getIsReactNativeWebView } from '../utils/webview/helpers';

type ErrorReport = Error | string;

/**
 * Get Bugsnag configuration to use for this project
 *
 * @returns The config to use
 */
export function getBugsnagConfig(): BrowserConfig {
  return {
    ...BUGSNAG_CONFIG,
    appType: getIsReactNativeWebView() ? 'webview' : 'browser',
    appVersion: APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage: ENVIRONMENT,
    // Used for reports before permissions are checked
    user: { id: 'not-logged-in' },
  };
}

/**
 * Get predefined Bugsnag severity level of event based on our level constants
 *
 * @param level The level of the message
 * @returns     Bugsnag predefined severity level
 */
function getBugsnagSeverity(level: number): Event['severity'] {
  if (level < 1 || level > 4) {
    return 'info';
  }

  const severityMapper: Record<string, Event['severity']> = {
    '1': 'info',
    '2': 'warning',
    '3': 'error',
    '4': 'error',
  };

  return severityMapper[level];
}

/**
 * Set the ID of the user being sent to Bugsnag
 *
 * @param userId The ID of the current user
 */
export function setBugsnagUserId(userId: User['id']): void {
  if (Bugsnag.isStarted() === true) {
    reportInfo('Setting Bugsnag user: ' + userId);
    Bugsnag.setUser(userId);
  }
}

/**
 * Get console method to use to show the message
 *
 * @param level The level of the message
 * @returns     Function for passed log level
 */
function getLogMethod(level: number): (message: unknown) => void {
  if (level <= LEVEL_DEBUG) {
    // eslint-disable-next-line no-console
    return console.log;
  }

  if (level <= LEVEL_INFO) {
    // eslint-disable-next-line no-console
    return console.info;
  }

  if (level <= LEVEL_WARNING) {
    // eslint-disable-next-line no-console
    return console.warn;
  }

  // eslint-disable-next-line no-console
  return console.error;
}

/**
 * Check whether we should show an alert with this message
 *
 * @param level The level to check
 * @returns     Whether to alert
 */
function shouldAlert(level: number) {
  return level >= REPORT_LEVEL_ALERT;
}

/**
 * Check whether we should log a message of this level to the console
 *
 * @param level The level to check
 * @returns     Whether to log
 */
function shouldLog(level: number) {
  return level >= REPORT_LEVEL_LOG;
}

/**
 * Check whether we should post (eg. to Bugsnag) a message of this level
 *
 * @param level The level to check
 * @returns     Whether to log
 */
function shouldPost(level: number) {
  return level >= REPORT_LEVEL_POST;
}

/**
 * Show an alert popup
 *
 * @param message The message to display
 */
function alertMessage(message: string) {
  alert(message);
}

/**
 * Log the message to the browser console
 *
 * @param message The message to display
 * @param level   The severity level
 */
function logMessage(message: string, level: number) {
  const method = getLogMethod(level);
  method(message);
}

/**
 * Post the message to bug tracking software (eg. Bugsnag)
 *
 * @param errorReport The error report to post
 * @param level       The severity level
 */
function postMessage(errorReport: Error, level: number) {
  Bugsnag.notify(errorReport, event => {
    event.severity = getBugsnagSeverity(level);
  });
}

/**
 * Depending on the configuration,
 * Log and/or Post the error report
 *
 * @param errorReport The message to report
 * @param level       The severity level
 */
function report(errorReport: ErrorReport, level: number) {
  const error =
    typeof errorReport === 'string' ? new Error(errorReport) : errorReport;

  if (shouldAlert(level)) {
    alertMessage(error.message);
  }

  if (shouldLog(level)) {
    logMessage(error.message, level);
  }

  if (shouldPost(level)) {
    postMessage(error, level);
  }
}

/**
 * Report a debug message
 *
 * @param message The message to report
 */
export function reportDebug(message: string): void {
  const level = LEVEL_DEBUG;
  report(message, level);
}

/**
 * Report an info message
 *
 * @param message The message to report
 */
export function reportInfo(message: string): void {
  const level = LEVEL_INFO;
  report(message, level);
}

/**
 * Report a warning
 *
 * @param errorReport The message to report
 */
export function reportWarning(errorReport: ErrorReport): void {
  const level = LEVEL_WARNING;
  report(errorReport, level);
}

/**
 * Report an non-fatal error
 *
 * @param errorReport The message to report
 */
export function reportError(errorReport: ErrorReport): void {
  const level = LEVEL_ERROR;
  report(errorReport, level);
}

/**
 * Report a fatal error
 *
 * @param errorReport The message to report
 */
export function reportCritical(errorReport: ErrorReport): void {
  const level = LEVEL_CRITICAL;
  report(errorReport, level);
}
