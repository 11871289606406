import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import useUserSelectedHotel from '../../../hooks/hotel/useUserSelectedHotel';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import translate from '../../../i18n/translate';
import { getCanViewDashboard } from '../../../utils/permissions/dashboard';
import { getUserCurrent } from '../../../utils/permissions/storage';
import { getHomeUrl } from '../../urls/home';

import PageDashboard from './Dashboard';

/**
 * Dashboard page
 *
 * @returns Dashboard page component.
 */
const DashboardPage: FC = () => {
  const hotelId = useParamHotelId();
  const user = getUserCurrent();
  useUserSelectedHotel(hotelId);

  const canViewDashboardPage = getCanViewDashboard();

  if (user === null || user === undefined) {
    /**
     * @todo Create GENERAL__USER and replace 'User' or ask some higher
     * thinking creature what should be the best message in this case
     */
    return <div>{translate('PLACEHOLDER__DATA_UNAVAILABLE', 'User')}</div>;
  }

  if (canViewDashboardPage) {
    return <PageDashboard />;
  }

  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default DashboardPage;
