/**
 * @file Hook to debounce a callback
 */

import { useCallback, useRef } from 'react';

/**
 * Get a memoized function that will only call the passed callback
 * when it hasn't been called for ${delay} amount of time
 *
 * @param callback The function to be called
 * @param delay    Wait period after function hasn't been called for
 * @returns        A memoized function that is debounced
 */
const useDebouncedCallback = <Args extends unknown[]>(
  callback: (...args: Args) => void,
  delay: number,
) => {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<number>();

  return useCallback(
    (...args: Args) => {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => callback(...args), delay);
    },
    // callback reference changes, we don't want to re-execute on that
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay],
  );
};

export default useDebouncedCallback;
