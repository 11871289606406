/**
 * @file Hook that gets currently active navigation tab
 */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavTab } from '../../../components/MUI/Drawer/types';
import {
  ROUTE__CHAT,
  ROUTE__RESERVATIONS,
  ROUTE__SEARCH,
  ROUTE__SETTINGS,
  ROUTE__SMART_VIEWS,
} from '../../../constants/routing/routes';
import {
  SIDEBAR__PARAM_CHAT,
  SIDEBAR__PARAM_HOME,
  SIDEBAR__PARAM_RESERVATIONS,
  SIDEBAR__PARAM_SEARCH,
  SIDEBAR__PARAM_SETTINGS,
  SIDEBAR__PARAM_SMART_VIEWS,
  SIDEBAR__PARAM_TOPICS,
} from '../../../constants/sidebar';
import { getSearchParamSidebar } from '../../../routes/helpers/searchParams';
import useParamTopicId from '../params/useParamTopicId';

/**
 * Paths and their corresponding navigation tabs
 */
const paths: [string, NavTab][] = [
  [ROUTE__SETTINGS, SIDEBAR__PARAM_SETTINGS],
  [ROUTE__RESERVATIONS, SIDEBAR__PARAM_RESERVATIONS],
  [ROUTE__SMART_VIEWS, SIDEBAR__PARAM_SMART_VIEWS],
  [ROUTE__CHAT, SIDEBAR__PARAM_CHAT],
  [ROUTE__SEARCH, SIDEBAR__PARAM_SEARCH],
];

/**
 * Function that gets the default navigation tab
 *
 * @param topicId  Id of the topic from params
 * @param pathname Pathname from current location
 * @returns        The default navigation tab
 */
const getDefaultTab = (topicId: string | null, pathname: string): NavTab => {
  if (topicId !== null) return SIDEBAR__PARAM_TOPICS;

  const path = paths.find(([route]) => pathname.includes(route));
  return path ? path[1] : SIDEBAR__PARAM_HOME;
};

/**
 * Hook that gets currently active navigation tab
 *
 * @returns Currently active navigation tab
 */
const useActiveSidebarTab = () => {
  const location = useLocation();
  const topicId = useParamTopicId();

  // Get default navigation tab from location as starting point
  const defaultTab = getDefaultTab(topicId, location.pathname);
  const [activeTab, setActiveTab] = useState<NavTab>(defaultTab);

  useEffect(() => {
    const sidebarParam = getSearchParamSidebar(location);

    // Don't set active tab to null when sidebar is closed, keep the last active
    if (sidebarParam !== null) {
      setActiveTab(sidebarParam as NavTab);
    }
  }, [location]);

  return activeTab;
};

export default useActiveSidebarTab;
