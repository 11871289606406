/**
 * @file A label was updated, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  LabelUpdateMutation,
  LabelUpdateMutationVariables,
} from '../../../../../generated/graphql';
import labelUpdate from '../utils/updaters/labels/labelsUpdate';

/**
 * Resolve cache after a label was updated
 *
 * @param _result The result of the mutation (unused)
 * @param args    Arguments of a graphQL operation
 * @param cache   URQL graphQL cache
 */
const labelUpdateMutation: UpdateResolver<
  LabelUpdateMutation,
  LabelUpdateMutationVariables
> = (_result, args, cache) => {
  labelUpdate(cache, args);
};

export default labelUpdateMutation;
