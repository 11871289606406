/**
 * @file contains sidebar param values
 */

export const SIDEBAR__PARAM_CHAT = 'chat';
export const SIDEBAR__PARAM_HOME = 'home';
export const SIDEBAR__PARAM_RESERVATIONS = 'bookings';
export const SIDEBAR__PARAM_SEARCH = 'search';
export const SIDEBAR__PARAM_SETTINGS = 'settings';
export const SIDEBAR__PARAM_SMART_VIEWS = 'smartViews';
export const SIDEBAR__PARAM_TOPICS = 'topics';
