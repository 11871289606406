/**
 * @file All permissions checks related to dev panel
 */

import { PermissionsDevPanel } from '../../models/permissions';

import { DEV__PANEL__VIEW } from './permissionsAll';
import { getPermissionsCurrent } from './storage';

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsDevPanel): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the user is allowed to see dev panel page
 *
 * @returns Whether it's true
 */
export const getCanViewDevPanel = (): boolean => {
  return hasPermission(DEV__PANEL__VIEW);
};
