/**
 * @file contains truncate string function
 */

import { reportError } from '../../services/reporting';

/**
 * Trim whitespace and truncate string to maximum length
 * (if it exceeds it, append '...')
 *
 * @param maxTextLength The maximum text length
 * @param originalText  The full text
 * @returns             The truncated text
 */
const truncateText = (maxTextLength: number, originalText?: string | null) => {
  if (originalText === null || originalText === undefined) {
    return '';
  }

  if (maxTextLength < 0) {
    reportError('truncateText: negative length: ' + maxTextLength);
    return originalText;
  }

  const trimmedText = originalText.trim();

  return trimmedText.length > maxTextLength
    ? trimmedText.substring(0, maxTextLength) + '...'
    : trimmedText;
};

export default truncateText;
