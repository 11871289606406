import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  color?: string;
  size: number;
};

/**
 * Skeleton for the user avatar
 *
 * @param props       Props passed to the component
 * @param props.color CSS variable to use as the background color
 * @param props.size  The size of the avatar, in px
 * @returns           The component itself
 */
const SkeletonAvatar: FC<Props> = ({ color, size }) => {
  return <Styled.Avatar data-color={color} data-size={size} />;
};

export default SkeletonAvatar;
