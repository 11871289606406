/**
 * @file contains function for uploading attachment to S3 bucket
 */
import { AttachmentDraftPreUploadMapEntry } from '../models/attachment';

import { reportError } from './reporting';

/**
 * Upload file to S3 bucket
 *
 * @param attachment Attachment to upload
 * @returns          Promise for upload file request
 */
const uploadFile = async (
  attachment: AttachmentDraftPreUploadMapEntry,
): Promise<Response | undefined> => {
  try {
    const formData = new FormData();

    const fields = attachment.postData.fields;
    const url = attachment.postData.url;

    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('Content-Type', attachment.file.type);
    formData.append('file', attachment.file);

    return fetch(url, {
      body: formData,
      method: 'POST',
    });
  } catch (error) {
    reportError(error as Error);
    return Promise.reject();
  }
};

export default uploadFile;
