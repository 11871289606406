import { styled } from 'styled-components';

import LabelListCommon from '../../LabelList';

export const LabelList = styled(LabelListCommon)`
  margin-bottom: var(--spacing-tiny);
`;

export const SkeletonWrapper = styled.div`
  margin-bottom: var(--spacing-tiny);
  padding-right: var(--spacing-large);
`;
