import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const Link = styled(RouterLink)`
  color: var(--color-link);
  display: block;
  font-size: var(--font-sm);
  font-weight: var(--font-semibold);
  margin-top: var(--spacing-small);

  &:hover {
    text-decoration: underline;
  }
`;
