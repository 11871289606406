import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  text: string;
};

/**
 * Button translate dynamic icon based on target lang
 *
 * @param props      Props passed to the component
 * @param props.text Text on the icon
 * @returns          The component itself
 */
const Icon: FC<Props> = ({ text }) => {
  return (
    <Styled.Wrapper>
      <span>{'ヌ'}</span>
      <span>{'→'}</span>
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default Icon;
