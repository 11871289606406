/**
 * @file Hook that returns recurringFrequency if there is one
 */

import { useParams } from 'react-router-dom';

import {
  RecurringFrequencyParam,
  SettingsTemplatesParams,
} from '../../../models/pageParams';

/**
 * Hook that returns recurringFrequency if there is one
 *
 * @returns Current recurringFrequency or null
 */
const useParamRecurringFrequency = (): RecurringFrequencyParam | null => {
  const { frequency } = useParams<SettingsTemplatesParams>();

  return frequency ?? null;
};

export default useParamRecurringFrequency;
