import React, { FC } from 'react';

import { InputFileProps } from '../../../models/attachment';
import InputFileAttachment from '../../Common/InputFileAttachment';

import ButtonAttachmentAdd from './ButtonAttachmentAdd';

type Props = {
  canUploadFile?: boolean;
  inputFileProps?: InputFileProps;
  requestFilePicker: () => void;
};

/**
 * Component that contains button and input for adding attachments
 *
 * @param props                   Props passed to the component
 * @param props.canUploadFile     Whether user has permission to upload attachment
 * @param props.inputFileProps    Props passed to the <input type="file" />
 * @param props.requestFilePicker Open file picker
 * @returns                       The component itself
 */
const AttachmentAdd: FC<Props> = ({
  canUploadFile = true,
  inputFileProps,
  requestFilePicker,
}) => {
  if (inputFileProps === undefined || canUploadFile === false) {
    return null;
  }

  return (
    <>
      <ButtonAttachmentAdd onClick={requestFilePicker} />
      <InputFileAttachment inputFileProps={inputFileProps} />
    </>
  );
};

export default AttachmentAdd;
