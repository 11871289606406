import React, { FC } from 'react';

import ListItemDraft from './ListItemDraft';
import ListItemExisting from './ListItemExisting';

import { PropsStaticUnion } from './props';

/**
 * Checklist, variant without drag and drop
 *
 * @param props                 Props passed to the component
 * @param props.items           The items to display
 * @param props.mode            Whether we're working with draft or existing message's checklist
 * @param props.requestDelete   Request that the current item is removed
 * @param props.requestReorder  Request that the list is reordered
 * @param props.requestSetState Request that the current item is (un)checked in the state
 * @param props.requestSetText  Request that a checklist item's text is updated
 * @returns                     The component itself
 */
const ChecklistStatic: FC<PropsStaticUnion> = props => {
  if (props.mode === 'DRAFT') {
    // Can't destructure above, because otherwise TypeScript won't make the connection
    // that when mode === 'DRAFT', items have position but not id (and vice versa)
    const { mode, requestDelete, requestSetState, requestSetText } = props;
    return (
      <ul>
        {props.items.map(item => (
          <li key={`checklist-item-${item.position}`}>
            <ListItemDraft
              item={item}
              mode={mode}
              requestDelete={requestDelete}
              requestSetState={requestSetState}
              requestSetText={requestSetText}
            />
          </li>
        ))}
      </ul>
    );
  }

  const { mode, requestDelete, requestSetState, requestSetText } = props;
  return (
    <ul>
      {props.items.map(item => (
        <li key={`checklist-item-${item.id}`}>
          <ListItemExisting
            item={item}
            mode={mode}
            requestDelete={requestDelete}
            requestSetState={requestSetState}
            requestSetText={requestSetText}
          />
        </li>
      ))}
    </ul>
  );
};

export default ChecklistStatic;
