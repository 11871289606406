/**
 * @file Hook that returns translated messages from redux
 */
import { useSelector } from 'react-redux';

import AppState from '../../models/state';

/**
 * Hook that returns translated messages from redux
 *
 * @returns Translated messages from redux
 */
const useTranslatedMessages = () => {
  return useSelector<AppState, AppState['translations']['messages']>(
    state => state.translations.messages,
  );
};

export default useTranslatedMessages;
