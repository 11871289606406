/**
 * @file contains formatter function for plural elements in different languages
 */
import translate from '../i18n/translate';

import formatOrdinalNumber from './intlPluralRulesOrdinalNumber';
import { getLocaleCode } from './user/locale';

/**
 * Create a plurality string out of a list
 * (eg. ['a', 'b', 'c'] gives 'a, b and c')
 * if isOrdinal is true use ordinal numbers format
 * (eg. ['1', '2', '3'] gives '1st, 2nd and 3rd')
 *
 * @param items     The items to format
 * @param isOrdinal Whether we are formatting ordinal numbers
 * @returns         The formatted input
 */
const intlPluralFormatter = (items: string[], isOrdinal = false): string => {
  const currentLocale = getLocaleCode();
  const formatter = new Intl.ListFormat(currentLocale, {
    style: 'long',
    type: 'conjunction',
  });

  if (isOrdinal) {
    const ordinalNumbersStringArray = items.map(item => {
      if (item === '-1') {
        return translate('RRULE__LAST');
      }
      return formatOrdinalNumber(item);
    });

    return formatter.format(ordinalNumbersStringArray);
  }

  return formatter.format(items);
};

export default intlPluralFormatter;
