import React, { FC } from 'react';

import ChecklistDraggable from './ChecklistDraggable';
import ChecklistEmpty from './ChecklistEmpty';
import ChecklistStatic from './ChecklistStatic';

import { PropsListUnion } from './props';

/**
 * Checklist for messages
 * There are two sub components for it,
 * depending on whether the user has permissions to reorder items or not
 *
 * request... callbacks are nullable,
 * and are null when the user doesn't have permissions to perform the said action
 *
 * @param props                 Props passed to the component
 * @param props.items           Checklist items to show
 * @param props.mode            Whether we're working with draft or existing message's checklist
 * @param props.noResult        ReactNode to use when the passed array is empty
 * @param props.requestSetState Request that an item is (un)checked
 * @param props.requestSetText  Request that an item's text is updated
 * @param props.requestDelete   Request that an item is removed from the list
 * @param props.requestReorder  Request that the items are reordered
 * @returns                     The component itself
 */
const ChecklistItems: FC<PropsListUnion> = props => {
  if (props.items.length === 0) {
    return props.noResult ? <>{props.noResult}</> : <ChecklistEmpty />;
  }

  // requestReorder={props.requestReorder} is needed
  // because otherwise, TypeScript engine doesn't recognize the check in if
  if (props.requestReorder === null) {
    return <ChecklistStatic {...props} requestReorder={props.requestReorder} />;
  }

  // Again, logically, branching makes no sense because identical thing is returned
  // But, otherwise, TypeScript doesn't recognize which callback is which
  if (props.mode === 'DRAFT') {
    return (
      <ChecklistDraggable {...props} requestReorder={props.requestReorder} />
    );
  }

  return (
    <ChecklistDraggable {...props} requestReorder={props.requestReorder} />
  );
};

export default ChecklistItems;
