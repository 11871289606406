/**
 * @file Add Redux state to container props
 */

import { MapStateToProps } from 'react-redux';

import AppState from '../../models/state';

import { OwnProps, StateProps } from './props';

/**
 * Add Redux state to container props
 *
 * @param state The current app state
 * @returns     The props to add to the container
 */
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  networkQuickFilters: state.debug.networkQuickFilters,
});

export default mapStateToProps;
