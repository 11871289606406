/**
 * @file contains drafts reducer function
 */

import { DraftAction, DraftsState } from '../../models/draft';
import { DraftActionAssignee } from '../../models/draftAssignee';
import { DraftActionChecklist } from '../../models/draftChecklist';
import { DraftActionDueDate } from '../../models/draftDueDate';
import { DraftActionEditTemplateDataSave } from '../../models/draftEditTemplate';
import { DraftActionLabel } from '../../models/draftLabel';
import { DraftActionMain } from '../../models/draftMain';
import { DraftActionOffset } from '../../models/draftOffset';
import { DraftActionReminders } from '../../models/draftReminders';
import { DraftActionTemplate } from '../../models/draftTemplate';
import { DraftActionTemplateTopic } from '../../models/draftTemplateTopic';

import { DraftActionText } from '../../models/draftText';

import initialState from '../initialState';

import draftsAssignees, {
  actionTypes as draftAssigneesActionTypes,
} from './draftsAssignees';
import draftsChecklists, {
  actionTypes as draftChecklistsActionTypes,
} from './draftsChecklists';
import draftsDueDate, {
  actionTypes as draftDueDateActionTypes,
} from './draftsDueDate';
import draftsEditTemplate, {
  actionTypes as draftEditTemplateActionTypes,
} from './draftsEditTemplate';
import draftsLabels, {
  actionTypes as draftLabelsActionTypes,
} from './draftsLabels';
import draftsMain, { actionTypes as draftMainActionTypes } from './draftsMain';
import draftsOffset, {
  actionTypes as draftOffsetActionTypes,
} from './draftsOffset';
import draftsReminders, {
  actionTypes as draftRemindersActionTypes,
} from './draftsReminders';
import draftsTemplate, {
  actionTypes as draftTemplateActionTypes,
} from './draftsTemplate';
import draftsTemplateTopic, {
  actionTypes as draftTemplateTopicActionTypes,
} from './draftsTemplateTopic';
import draftsText, { actionTypes as draftTextActionTypes } from './draftsText';

/**
 *
 * Drafts main reducer;
 * Combines all the drafts... reducers and returns a single one
 *
 */

type Reducer = {
  // The actual reducer function
  reducer: (state: DraftsState, action: DraftAction) => DraftsState;
  // Action types this reducer reacts on
  types: string[];
};

const reducers: Reducer[] = [
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsAssignees(state, action as DraftActionAssignee);
    },
    types: draftAssigneesActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsChecklists(state, action as DraftActionChecklist);
    },
    types: draftChecklistsActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsDueDate(state, action as DraftActionDueDate);
    },
    types: draftDueDateActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsLabels(state, action as DraftActionLabel);
    },
    types: draftLabelsActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsOffset(state, action as DraftActionOffset);
    },
    types: draftOffsetActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsReminders(state, action as DraftActionReminders);
    },
    types: draftRemindersActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsTemplate(state, action as DraftActionTemplate);
    },
    types: draftTemplateActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsTemplateTopic(state, action as DraftActionTemplateTopic);
    },
    types: draftTemplateTopicActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsMain(state, action as DraftActionMain);
    },
    types: draftMainActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsText(state, action as DraftActionText);
    },
    types: draftTextActionTypes,
  },
  {
    reducer: (state: DraftsState, action: DraftAction) => {
      return draftsEditTemplate(
        state,
        action as DraftActionEditTemplateDataSave,
      );
    },
    types: draftEditTemplateActionTypes,
  },
];

/**
 * Drafts reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const drafts = (
  state = initialState.drafts,
  action: DraftAction,
): DraftsState => {
  const reducer = reducers.find(singleReducer =>
    singleReducer.types.includes(action.type),
  );

  if (reducer) {
    return reducer.reducer(state, action);
  }

  return state;
};

export default drafts;
