/**
 * @file UseSubmitData hook for compose form
 */

import { OperationResult, useMutation } from 'urql';

import {
  ChecklistItem,
  MessageCreateDocument,
  MessageCreateMutation,
  MessageInput,
  TemplateCreateDocument,
  TemplateCreateMutation,
  TemplateInput,
  TemplateUpdateDocument,
  TemplateUpdateInput,
  TemplateUpdateMutation,
} from '../../../generated/graphql';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import { AttachmentDraft } from '../../../models/attachment';
import { DraftDataFormatted } from '../../../models/draft';
import { ComposeNodeType } from '../props';

type Mutations =
  | MessageCreateMutation
  | TemplateCreateMutation
  | TemplateUpdateMutation;
type Return = {
  fetching: boolean;
  submitData: (() => Promise<OperationResult<Mutations>>) | null;
};

/**
 * Generate a submit callback for a compose form
 *
 * @param attachmentsFormatted Attachments to submit
 * @param dataDraft            Draft data to send
 * @param existingChecklistIds Array of existing checklist item IDs
 * @param nodeId               The ID of the node (template) being edited (null for creation)
 * @param nodeType             Whether the compose form is being used for messages or templates
 * @returns                    Submit callback
 */
const useSubmitData = (
  attachmentsFormatted: AttachmentDraft[],
  dataDraft: DraftDataFormatted | null = null,
  existingChecklistIds: ChecklistItem['id'][] | null,
  nodeId: string | null,
  nodeType: ComposeNodeType,
): Return => {
  const [{ fetching: isCreatingMessage }, messageCreateMutation] = useMutation(
    MessageCreateDocument,
  );
  const [{ fetching: isCreatingTemplate }, templateCreateMutation] =
    useMutation(TemplateCreateDocument);
  const [{ fetching: isUpdatingTemplate }, templateUpdateMutation] =
    useMutation(TemplateUpdateDocument);
  const topicIdUrl = useParamTopicId();

  if (dataDraft === null) {
    return { fetching: false, submitData: null };
  }

  const {
    assigneeGroupsSaved,
    assigneeMembersSaved,
    checklistSaved,
    dateDueSaved,
    labelsSaved,
    offsetSaved: dueDateOffset,
    remindersSaved,
    templateTopicSaved,
    text,
    title,
  } = dataDraft;

  const dueDate = dateDueSaved?.toString() ?? null;
  const files = attachmentsFormatted.map(attachment => attachment.key);
  const topic = nodeType === 'MESSAGE' ? topicIdUrl : templateTopicSaved;

  if (topic === null) {
    return { fetching: false, submitData: null };
  }

  /**
   * Only add checklist item IDs if checklist item existed
   * and if user is editing template
   */
  const shouldAddChecklistId = existingChecklistIds && nodeType === 'TEMPLATE';

  /**
   * ChecklistDataDraft only contains 'checked', 'position' and 'text' props
   * from ChecklistItem but we also need the 'id'
   */
  const checklist = checklistSaved.map((item: ChecklistItem) => {
    if (shouldAddChecklistId && existingChecklistIds.includes(item.id)) {
      return {
        checked: item.checked,
        id: item.id,
        text: item.text,
      };
    }
    return {
      checked: item.checked,
      text: item.text,
    };
  });

  // Data common for all actions
  const dataCommon = {
    assignedGroups: Array.from(assigneeGroupsSaved),
    assignedMembers: Array.from(assigneeMembersSaved),
    checklist,
    labels: Array.from(labelsSaved),
    reminders: remindersSaved,
    text,
    title,
  };

  /**
   * Submit callback
   *
   * @returns Mutation to create or update
   */
  const submitData = () => {
    if (nodeType === 'MESSAGE') {
      const data: MessageInput = { ...dataCommon, dueDate, files, topic };
      return messageCreateMutation({ data });
    } else if (nodeId === null) {
      const data: TemplateInput = { ...dataCommon, dueDateOffset, topic };
      return templateCreateMutation({ data });
    } else {
      const data: TemplateUpdateInput = { ...dataCommon, dueDateOffset };
      return templateUpdateMutation({ data, id: nodeId });
    }
  };

  return {
    fetching: [isCreatingMessage, isCreatingTemplate, isUpdatingTemplate].some(
      isFetching => isFetching === true,
    ),
    submitData,
  };
};

export default useSubmitData;
