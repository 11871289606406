/**
 * @file Generate redirect path after submitting/resetting the compose form
 */

import { useLocation } from 'react-router-dom';

import useIsComposeOpen from '../../../hooks/router/search/useIsComposeOpen';
import useIsMobile from '../../../hooks/useIsMobile';
import {
  getCloseComposeUrl,
  getOpenComposeUrl,
} from '../../../routes/urls/searchParams/compose';
import { ComposeNodeId } from '../props';

/**
 * Generate redirect path after submitting/resetting the compose form
 *
 * @param nodeId The ID of the node the compose is for
 * @returns      The path to redirect to (or null if we shouldn't redirect)
 */
const useRedirectPath = (nodeId: ComposeNodeId) => {
  const isMobile = useIsMobile();
  const isOpen = useIsComposeOpen();
  const location = useLocation();

  const urlOpen = getOpenComposeUrl(location);
  const urlClosed = getCloseComposeUrl(location);

  // We only close the compose form when the user is on a small-screen device,
  // Because it takes over the whole screen in that case

  if (nodeId !== null) {
    if (isOpen === false) {
      return null;
    }

    return isMobile
      ? urlClosed.replace('/' + nodeId, '')
      : urlOpen.replace('/' + nodeId, '');
  }

  return isMobile && isOpen ? urlClosed : null;
};

export default useRedirectPath;
