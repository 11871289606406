/**
 * @file Info regarding push notifications
 */

import {
  getHasUnsubscribed,
  getSubscriptionPaused,
} from '../../utils/storage/notifications';

import useDeviceId from './useDeviceId';
import useIsSubscribed from './useIsSubscribed';
import useNotificationPermissionStatus from './useNotificationPermissionStatus';

/**
 * Get info regarding push notifications
 * - canSubscribe: Whether we can subscribe the user to push notifications
 * - canSubscribe: Whether we can unsubscribe the current user from push notifications
 * - deviceId: The ID of the user's device (auto generated, but can be null)
 *
 * @returns The related info
 */
const usePushInfo = () => {
  const deviceId = useDeviceId();
  const hasUnsubscribed = getHasUnsubscribed();
  const isPaused = getSubscriptionPaused();
  const isSubscribed = useIsSubscribed();
  const permission = useNotificationPermissionStatus();

  /**
   * Whether we the user can subscribe to push notifications right now
   */
  const canSubscribe =
    deviceId !== null &&
    hasUnsubscribed === false &&
    isPaused === false &&
    isSubscribed === false &&
    permission === 'granted';

  /**
   * Whether the user can unsubscribe
   * (permission === 'granted' is required to be able to get Firebase token)
   * (and Firebase token is used to identify the correct subscription)
   */
  const canUnsubscribe = permission === 'granted' && isSubscribed === true;

  return {
    canSubscribe,
    canUnsubscribe,
    deviceId,
  };
};

export default usePushInfo;
