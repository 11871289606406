import React, { FC, useState } from 'react';

import Heading from '../../../containers/leftSidebarFeed/LeftSidebarFeedContent/Heading';
import { TopicsListQuery } from '../../../generated/graphql';
import translate from '../../../i18n/translate';

import TopicsContent from './TopicsContent';

export type Props = {
  isLoading: boolean;
  topics: TopicsListQuery['topics'];
};

/**
 * Profile menu component with a dropdown that opens on click.
 *
 * @param props           props passed to the component
 * @param props.isLoading Whether the topics are being fetched
 * @param props.topics    The list of topics or tasks
 * @returns               The component itself
 */
const Topics: FC<Props> = ({ isLoading, topics }) => {
  const [isActive, setIsActive] = useState(true);

  /**
   * The user has toggled the collapsed state of the list,
   * so we update the local state
   */
  const handleToggle = () => {
    setIsActive(state => !state);
  };

  return (
    <>
      <Heading
        handleToggle={handleToggle}
        isActive={isActive}
        text={translate('GENERAL__TOPICS')}
      />
      <TopicsContent
        isActive={isActive}
        isLoading={isLoading}
        topics={topics}
      />
    </>
  );
};

export default Topics;
