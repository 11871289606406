/**
 * @file Optimistically update a label
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  LabelsDocument,
  LabelsQuery,
  LabelsQueryVariables,
  LabelUpdateMutation,
} from '../../../../../generated/graphql';
import { LabelUpdateVariables } from '../../types';
import labelsUpdate from '../../updates/utils/updaters/labels/labelsUpdate';

/**
 * Optimistically update a label
 *
 * @param args  Arguments of the GraphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const labelUpdate: OptimisticMutationResolver<
  LabelUpdateVariables,
  LabelUpdateMutation['labelUpdate'] | null
> = (args, cache) => {
  const labelsQuery = cache.readQuery<LabelsQuery, LabelsQueryVariables>({
    query: LabelsDocument,
  });

  const { color, text } = args.data;

  const targetLabel = labelsQuery?.labels.find(({ id }) => id === args.id);

  if (targetLabel === undefined) {
    return null;
  }

  const updates: LabelUpdateMutation['labelUpdate'] = {
    color: color ?? targetLabel.color,
    id: args.id,
    text: text ?? targetLabel.text,
  };

  labelsUpdate(cache, updates);

  return {
    ...targetLabel,
    ...updates,
  };
};

export default labelUpdate;
