/**
 * @file React hook that handles clicking outside of a ref
 */
import { MutableRefObject, useEffect } from 'react';

type Props = {
  excludedRefs?: MutableRefObject<HTMLElement | null>[];
  onClick?: () => void;
  ref: MutableRefObject<HTMLElement | null>;
  stopExec?: boolean;
};

/**
 * React hook that handles clicking outside of a ref
 *
 * @param props              Props passed to the hook
 * @param props.excludedRefs array of refs on which this hook won't activate
 * @param props.onClick      onClick outside handler
 * @param props.ref          ref that we listen to for outside clicks
 * @param props.stopExec     boolean that determines if the execution should be stopped
 */
const useClickOutside = ({
  excludedRefs = [],
  onClick,
  ref,
  stopExec = false,
}: Props): void => {
  useEffect(() => {
    if (stopExec) {
      return;
    }

    /**
     * Handle click anywhere
     *
     * @param event The event that took place
     */
    const onClickOutside = (event: MouseEvent) => {
      if (event.button !== 0) {
        return;
      }

      const isExcludedByRefs = excludedRefs.find(excludedRef =>
        excludedRef.current?.contains(event.target as Node),
      );

      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !isExcludedByRefs &&
        onClick
      ) {
        onClick();
      }
    };

    window.addEventListener('mousedown', onClickOutside, false);

    return () => {
      window.removeEventListener('mousedown', onClickOutside);
    };
  }, [onClick, ref, stopExec, excludedRefs]);
};

export default useClickOutside;
