import React, { FC } from 'react';

import SplashScreenContainer from '../../containers/splashScreen';

/**
 * Main application entry point
 *
 * @returns The component itself
 */
const AppUnauthenticated: FC = () => {
  return <SplashScreenContainer />;
};

export default AppUnauthenticated;
