import React, { FC } from 'react';

import * as Styled from './styled';

/**
 * Skeleton for mobile header
 * (sits between burger and info icons, up top)
 *
 * @returns The component itself
 */
const SkeletonHeaderMobile: FC = () => {
  return <Styled.Line />;
};

export default SkeletonHeaderMobile;
