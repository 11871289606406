import React, { ChangeEventHandler } from 'react';

import { CombinedError } from 'urql';

import {
  TemplateBasicFragment,
  TopicBasicFragment,
} from '../../../../../generated/graphql';
import LoadingTopics from '../../../../Loading/Topics';
import ListNoResult from '../ListNoResult';
import { DataType } from '../helpers';

import * as Styled from './styled';

export type Props<T> = {
  className?: HTMLUListElement['className'];
  dataSelected: string | null;
  dataType: DataType;
  dataVisible: T[];
  error?: CombinedError;
  isLoading: boolean;
  isSearching: boolean;
  requestChange: (id: string) => void;
};

/**
 * List (RadioGroup) used for picking template/topic
 *
 * @param props               Props passed to the component
 * @param props.className     styled-components generated class name, needed for styling
 * @param props.dataSelected  Currently selected data
 * @param props.dataType      Type of data rendered inside of list
 * @param props.dataVisible   Visible data
 * @param props.error         Topics fetching error
 * @param props.isLoading     Whether the data is being loaded
 * @param props.isSearching   Whether something was typed into search
 * @param props.requestChange Request that current data is changed
 * @returns                   The component itself
 */
const List = <T extends TemplateBasicFragment | TopicBasicFragment>({
  className,
  dataSelected,
  dataType,
  dataVisible,
  error,
  isLoading,
  isSearching,
  requestChange,
}: Props<T>) => {
  if (isLoading === true) {
    return <LoadingTopics />;
  }

  if (error) {
    return <ListNoResult dataType={dataType} reason="error" />;
  }

  if (dataVisible.length === 0) {
    return isSearching === true ? (
      <ListNoResult dataType={dataType} reason="searching" />
    ) : null;
  }

  /**
   * The user changed the topic option for template topic
   *
   * @param event The event that took place
   */
  const handleOptionChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.target;
    requestChange(value);
  };

  return (
    <Styled.Ul className={className} role="radiogroup">
      {dataVisible.map(item => {
        return (
          <li key={`data-${item.id}`}>
            <label>
              <Styled.Input
                checked={item.id === dataSelected}
                name="compose-list"
                onChange={handleOptionChange}
                type="radio"
                value={item.id}
              />
              <Styled.LabelText>{item.title}</Styled.LabelText>
            </label>
          </li>
        );
      })}
    </Styled.Ul>
  );
};

export default List;
