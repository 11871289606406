/**
 * @file Helper function for the compose form, that are used in multiple places
 */

import { ComposeNodeType } from '../../containers/compose/props';
import translate from '../../i18n/translate';

/**
 * Get Send button label based on usage and mode
 *
 * @param nodeType   Whether the compose form is being used for messages or templates
 * @param isEditMode Whether Editor is in template edit mode
 * @returns          Translation key
 */
export const getLabelConfirm = (
  nodeType: ComposeNodeType,
  isEditMode: boolean,
) => {
  if (nodeType === 'TEMPLATE') {
    return translate(isEditMode ? 'GENERAL__SAVE' : 'GENERAL__CREATE');
  }

  return translate('GENERAL__SEND');
};
