import React, { FC } from 'react';

import DashboardSkeletonDesktop from './DashboardSkeletonDesktop';
import DashboardSkeletonMobile from './DashboardSkeletonMobile';

type Props = {
  isMobile: boolean;
};

/**
 * Dashboard loader component with KPIs
 *
 * @param props          Props passed to the component
 * @param props.isMobile Is current device mobile
 * @returns              The component itself
 */
const DashboardSkeleton: FC<Props> = ({ isMobile }) => {
  if (isMobile) {
    return <DashboardSkeletonMobile />;
  }

  return <DashboardSkeletonDesktop />;
};

export default DashboardSkeleton;
