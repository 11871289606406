/**
 * @file Helper functions related to user locale preference
 */

export const LOCALE__DEFAULT = 'en-GB';

/**
 * Gets default locale
 *
 * @returns Locale string
 */
export const getDefaultLocaleCode = (): string => {
  // Google lighthouse is accessing this code while the window is not defined.
  if (typeof window === 'undefined') {
    return LOCALE__DEFAULT;
  }

  return new Intl.Locale(window.navigator.language).baseName;
};

let currentLocale = getDefaultLocaleCode();

/**
 * Set the currently used locale
 *
 * @param code The code to set
 */
export const setLocaleCode = (code: string): void => {
  currentLocale = code;
};

/**
 * Get the currently used locale
 * (falls back to using LOCALE__DEFAULT value)
 *
 * @returns The locale code (eg. 'de-DE')
 */
export const getLocaleCode = (): string => {
  return currentLocale;
};
