import React, { ComponentProps, FC } from 'react';

import { TOAST__MESSAGE_DETAILS_LENGTH } from '../../../../constants/preferences';

import { ToastMessageModel } from '../../../../models/toastMessage';
import truncateText from '../../../../utils/string/truncateText';
import ToastToggle from '../ToastToggle';

import * as Styled from './styled';

type Props = ComponentProps<typeof ToastToggle> & {
  children: ToastMessageModel['additionalText'];
};

/**
 * Additional text for the toast, if available
 *
 * @param props           Props passed to the component
 * @param props.children  The text to show
 * @param props.type      Toast message type
 * @param props.isVisible Is visible
 * @param props.onClick   On Click
 * @returns               The component itself
 */
const ToastAdditionalText: FC<Props> = ({
  children,
  isVisible,
  onClick,
  type,
}) => {
  return (
    <>
      {isVisible && (
        <Styled.AdditionalText>
          {truncateText(TOAST__MESSAGE_DETAILS_LENGTH, children)}
        </Styled.AdditionalText>
      )}

      <ToastToggle isVisible={isVisible} onClick={onClick} type={type} />
    </>
  );
};

export default ToastAdditionalText;
