import React from 'react';
import { RouteProps } from 'react-router-dom';

import { ChatConversationPage } from '../../integrations/roqChat/ChatConversationPage';
import { ChatListPageMobile } from '../../integrations/roqChat/ChatListPageMobile';
import DashboardPage from '../pages/DashboardPage';
import PageFeed from '../pages/Feed';
import PageHome from '../pages/Home';
import PageKanban from '../pages/Kanban';
import PageMore from '../pages/More';
import PageHotelSelect from '../pages/More/HotelSelect';
import PageProxy from '../pages/Proxy';
import PageSearch from '../pages/Search';
import PageTextSearchResults from '../pages/Search/TextSearchResults';
import PageSmartViews from '../pages/SmartViews';
import { createChatConversationPath, createChatPath } from '../paths/chat';
import { createDashboardPath } from '../paths/dashboard';
import {
  createFeedMessageDetailsEditPath,
  createFeedMessageDetailsPath,
  createFeedMessagePath,
  createFeedPath,
  createFeedTopicDetailsEditPath,
  createFeedTopicDetailsPath,
  createFeedTopicPath,
} from '../paths/feed';
import { createHomePath } from '../paths/home';
import {
  createKanbanMessageDetailsEditPath,
  createKanbanMessageDetailsPath,
  createKanbanMessagePath,
  createKanbanTopicDetailsEditPath,
  createKanbanTopicDetailsPath,
  createKanbanTopicPath,
} from '../paths/kanban';
import { createMorePath, createMoreSelectHotelPath } from '../paths/more';
import { createNotificationsProxyPath } from '../paths/proxy';
import { createSearchPath, createSearchTextPath } from '../paths/search';
import { createSmartViewsSmartViewPath } from '../paths/smartViews';

const mappingRoutesAuthenticated: RouteProps[] = [
  { element: <ChatConversationPage />, path: createChatConversationPath() },
  { element: <ChatListPageMobile />, path: createChatPath() },
  { element: <DashboardPage />, path: createDashboardPath() },
  { element: <PageFeed />, path: createFeedMessageDetailsEditPath() },
  { element: <PageFeed />, path: createFeedMessageDetailsPath() },
  { element: <PageFeed />, path: createFeedMessagePath() },
  { element: <PageFeed />, path: createFeedPath() },
  { element: <PageFeed />, path: createFeedTopicDetailsEditPath() },
  { element: <PageFeed />, path: createFeedTopicDetailsPath() },
  { element: <PageFeed />, path: createFeedTopicPath() },
  { element: <PageHome />, path: createHomePath() },
  { element: <PageHotelSelect />, path: createMoreSelectHotelPath() },
  { element: <PageKanban />, path: createKanbanMessageDetailsEditPath() },
  { element: <PageKanban />, path: createKanbanMessageDetailsPath() },
  { element: <PageKanban />, path: createKanbanMessagePath() },
  { element: <PageKanban />, path: createKanbanTopicPath() },
  { element: <PageKanban />, path: createKanbanTopicDetailsEditPath() },
  { element: <PageKanban />, path: createKanbanTopicDetailsPath() },
  { element: <PageMore />, path: createMorePath() },
  { element: <PageProxy />, path: createNotificationsProxyPath() },
  { element: <PageSearch />, path: createSearchPath() },
  { element: <PageSmartViews />, path: createSmartViewsSmartViewPath() },
  { element: <PageTextSearchResults />, path: createSearchTextPath() },
];

export default mappingRoutesAuthenticated;
