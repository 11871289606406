import React, { FC } from 'react';

import { NAMES } from '../';

import * as Styled from './styled';

type Props = {
  className?: string;
  color?: string;
  direction: Styled.Direction;
  height?: number;
  width?: number;
};

/**
 * Common triangle icon
 * Extracted in a subfolder to allow for direction options
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     The color of the icon
 * @param props.direction Which direction should the triangle point to
 * @param props.height    Triangle height
 * @param props.width     Triangle width
 * @returns               The component itself
 */
const Triangle: FC<Props> = ({
  className,
  color,
  direction,
  height = 18,
  width = 13,
}) => {
  return (
    <Styled.Triangle
      className={className}
      color={color}
      data-direction={direction}
      height={height}
      name={NAMES.GENERAL__TRIANGLE}
      width={width}
    />
  );
};

export default Triangle;
