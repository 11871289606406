/**
 * @file Params needed for the page MessageDetails
 */
import { Topic } from '../../../../generated/graphql';
import useParamMessageEditMode from '../../../../hooks/router/params/useParamMessageEditMode';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useCurrentPath from '../../../../hooks/useCurrentPath';

import { createFeedMessageDetailsEditPath } from '../../../paths/feed';
import { createKanbanMessageDetailsEditPath } from '../../../paths/kanban';

type UseMessageDetailsParamsReturn = {
  shouldShowHeader: boolean;
  shouldShowReplyTray: boolean;
  shouldShowRightSidebarMobile: boolean;
  topicId: Topic['id'] | null;
};

const showRightSidebarMobileFor = [
  createFeedMessageDetailsEditPath(),
  createKanbanMessageDetailsEditPath(),
];

/**
 * Params needed for the page MessageDetails
 *
 * @param isArchived Whether the message is archived
 * @returns          The params needed
 */
const useMessageDetailsParams = (
  isArchived: boolean,
): UseMessageDetailsParamsReturn => {
  const topicId = useParamTopicId();
  const currentPath = useCurrentPath();

  // If there's an active edit mode, it's gonna have their own header
  const shouldShowHeader = useParamMessageEditMode() === null;
  // The same logic applies for replies sheet, show it only on main message details page
  const shouldShowReplyTray = shouldShowHeader && isArchived === false;

  const shouldShowRightSidebarMobile =
    currentPath !== null && showRightSidebarMobileFor.includes(currentPath);

  return {
    shouldShowHeader,
    shouldShowReplyTray,
    shouldShowRightSidebarMobile,
    topicId,
  };
};

export default useMessageDetailsParams;
