import React, { FC } from 'react';

import translate from '../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  onReload: () => void;
};

/**
 * Error component for smart views
 *
 * @param props          Props passed to the component
 * @param props.onReload Function that handles click on reload button
 * @returns              The component itself
 */
const Error: FC<Props> = ({ onReload }) => {
  return (
    <>
      <Styled.ErrorText>
        {translate('ERROR__MESSAGE__SMART_VIEWS')}
        <Styled.ReloadButton onClick={onReload}>
          {translate('ERROR__TOPICS__NOT_FETCHED__ACTION')}
        </Styled.ReloadButton>
      </Styled.ErrorText>
    </>
  );
};

export default Error;
