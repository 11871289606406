import { css, styled } from 'styled-components';

const dotSize = '6px';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
  text-align: center;
`;

const dotStyles = css`
  @keyframes dots-bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }

  animation: dots-bounce 1.4s infinite ease-in-out both;
  background-color: var(--color-text-alt);
  border-radius: 50%;
  display: inline-block;
  height: ${dotSize};
  width: ${dotSize};
`;

export const Dot1 = styled.div`
  animation-delay: -0.32s;
  ${dotStyles}
`;
export const Dot2 = styled.div`
  animation-delay: -0.16s;
  ${dotStyles}
`;

export const Dot3 = styled.div`
  ${dotStyles}
`;
