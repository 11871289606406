/**
 * @file Check whether the user can access the requested label
 */

import { useQuery } from 'urql';

import {
  Label,
  LabelBasicFragment,
  LabelsDocument,
} from '../../generated/graphql';
import { getDataById } from '../../utils/array';

type Return = [LabelBasicFragment | null, boolean];

/**
 * Check whether the user can access the requested label
 *
 * @param labelId The label to check
 * @returns       The accessible label and whether the data was fetched
 */
export const useLabelAccessible = (labelId: Label['id'] | null): Return => {
  const [{ data, error, fetching }] = useQuery({
    pause: labelId === null,
    query: LabelsDocument,
  });

  return [getDataById(labelId, data?.labels, fetching, error), fetching];
};
