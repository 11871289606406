/**
 * @file Hook that handles React Native web view messages
 */

import { useEffect } from 'react';
import { useMutation } from 'urql';

import { PushSubscribeDocument } from '../../generated/graphql';
import { handleWebViewNotificationPermissionResponse } from '../../utils/webview/events';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import {
  NotificationPermissionResponsePayload,
  WebViewEvent,
  WebViewEventNames,
} from '../../utils/webview/payloadTypes';

import { reactNativeMessageHandler } from './reactNativeMessageHandler';

/**
 * Hook that handles React Native web view messages
 */
const useWebViewEventListener = (): void => {
  const [, pushSubscribe] = useMutation(PushSubscribeDocument);

  useEffect(() => {
    if (getIsReactNativeWebView() === false) {
      return;
    }

    const webViewEvents: WebViewEvent[] = [
      {
        callback: (response: NotificationPermissionResponsePayload) => {
          handleWebViewNotificationPermissionResponse(pushSubscribe, response);
        },
        eventName: WebViewEventNames.NotificationPermissionResponse,
      },
    ];

    /**
     * A message has come from the native wrapped
     *
     * @param event The event that took place
     */
    const messageEventHandler = (event: MessageEvent<string>) => {
      reactNativeMessageHandler(event, webViewEvents);
    };

    document.addEventListener('message', messageEventHandler, false);
    window.addEventListener('message', messageEventHandler, false);

    return () => {
      document.removeEventListener('message', messageEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
    /** We don't want this to cause rerender, especially changes in history object. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useWebViewEventListener;
