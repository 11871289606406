/**
 * @file Helpers related to Task card component
 */

import {
  KANBAN__CHIP__MAX_TEXT_LENGTH,
  KANBAN__CHIPS_SHOWN,
} from '../../../constants/preferences';
import { MessageFeed } from '../../../models/message';
import truncateText from '../../../utils/string/truncateText';
import { getSurplusTooltipText } from '../helpers';

type GroupToShow = { key: string; title: string; tooltip: string | null };

/**
 * Get visible groups titles
 *
 * @param groups Groups assigned to the message
 * @returns      Array of strings
 */
export const getGroupsToShow = (
  groups: MessageFeed['assignedGroups'],
): GroupToShow[] => {
  const nrHidden = groups.length - KANBAN__CHIPS_SHOWN;
  const toShow: GroupToShow[] = groups
    .slice(0, KANBAN__CHIPS_SHOWN)
    .map(({ id, title }) => ({
      key: id,
      title: truncateText(KANBAN__CHIP__MAX_TEXT_LENGTH, title),
      tooltip: null,
    }));

  if (nrHidden > 0) {
    const tooltip = getSurplusTooltipText(
      groups.map(group => group.title),
      KANBAN__CHIPS_SHOWN,
    );

    toShow.push({ key: 'additional', title: `+${nrHidden}`, tooltip });
  }

  return toShow;
};
