import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  actionLink?: {
    href: string;
    text: string;
  } | null;
  className?: string;
  illustrationName: string | null;
  subtitle: string | null;
  title: string | null;
};

/**
 * Basic empty state component
 *
 * @param props                  Props passed to the component
 * @param props.actionLink       Link for user action
 * @param props.className        className used by styled components
 * @param props.illustrationName Name of the illustration we are adding
 * @param props.subtitle         Subtitle in the empty state
 * @param props.title            Title in the empty state
 * @returns                      Basic empty state component
 */
const EmptyState: FC<Props> = ({
  actionLink = null,
  className,
  illustrationName = null,
  subtitle,
  title,
}) => {
  return (
    <Styled.Wrapper className={className}>
      {illustrationName !== null && (
        <Styled.Illustration height={200} name={illustrationName} width={215} />
      )}

      {title !== null && <Styled.Title>{title}</Styled.Title>}

      {subtitle !== null && <p>{subtitle}</p>}

      {actionLink !== null && (
        <Styled.ActionButton href={actionLink.href}>
          {actionLink.text}
        </Styled.ActionButton>
      )}
    </Styled.Wrapper>
  );
};

export default EmptyState;
