/**
 * @file Constants related to messages
 */

import { MessageStatus } from '../generated/graphql';
import { TasksQueryAlias } from '../models/message';

export const MESSAGE_STATUS_TRANSLATION_KEY: Record<MessageStatus, string> = {
  IN_PROGRESS: 'SELECT__LABEL__MESSAGE_STATUS__IN_PROGRESS',
  OPEN: 'SELECT__LABEL__MESSAGE_STATUS__OPEN',
  RESOLVED: 'SELECT__LABEL__MESSAGE_STATUS__RESOLVED',
};

/**
 * In the optimized task query we use aliases, and the query
 * returns `data.tasksInProgress` & `data.tasksOpen` & `data.tasksResolved`.
 * For cache updates, we need to access the query key, and we can use this map
 * to connect the alias with the actual status.
 */
export const TASK_QUERY_ALIASES_MAP: Record<MessageStatus, TasksQueryAlias> = {
  [MessageStatus.InProgress]: 'tasksInProgress',
  [MessageStatus.Open]: 'tasksOpen',
  [MessageStatus.Resolved]: 'tasksResolved',
};
