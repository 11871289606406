import React, { FC } from 'react';

import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import translate from '../../../i18n/translate';
import EmptyState from '../../Common/EmptyState';

import * as Styled from './styled';

export type Props = {
  searchTerm: string | null;
};

/**
 * Component that renders empty state for search results
 *
 * @param props            Props passed to the component
 * @param props.searchTerm Search term used if any
 * @returns                Search Results empty state component
 */
const SearchResultsEmpty: FC<Props> = ({ searchTerm }) => {
  /**
   * Get which text to show as the title
   *
   * @returns The text to show
   */
  const getTitleText = () => {
    if (searchTerm !== null) {
      return translate('SEARCH__NO_RESULTS__TITLE', searchTerm);
    }

    return translate('SEARCH__NO_RESULTS__TITLE_FALLBACK');
  };

  /**
   * Get which text to show as the subtitle
   *
   * @returns The text to show (or null if none)
   */
  const getSubtitleText = (): string | null => {
    if (searchTerm === null) {
      return null;
    }

    return translate('SEARCH__NO_RESULTS__SUBTITLE');
  };

  return (
    <Styled.Wrapper>
      <EmptyState
        illustrationName={ILLUSTRATION__SEARCH}
        subtitle={getSubtitleText()}
        title={getTitleText()}
      />
    </Styled.Wrapper>
  );
};

export default SearchResultsEmpty;
