import React, { FC } from 'react';

import { TOAST__MESSAGE_TITLE_LENGTH } from '../../../../constants/preferences';

import { ToastMessageModel } from '../../../../models/toastMessage';
import truncateText from '../../../../utils/string/truncateText';

import * as Styled from './styled';

type Props = {
  children: ToastMessageModel['title'];
  chevronDirection: 'down' | 'right' | null;
};

const chevronSize = 9;

/**
 * Toast message title
 *
 * @param props                  Props passed to the component
 * @param props.children         The text to show
 * @param props.chevronDirection Whether to show the chevron next to the title
 * @returns                      The component itself
 */
const ToastTitle: FC<Props> = ({ children, chevronDirection }) => {
  return (
    <Styled.MessageTitle>
      {truncateText(TOAST__MESSAGE_TITLE_LENGTH, children)}

      {chevronDirection !== null && (
        <Styled.Chevron
          color="var(--color-text-regular)"
          direction={chevronDirection}
          size={chevronSize}
        />
      )}
    </Styled.MessageTitle>
  );
};

export default ToastTitle;
