/**
 * @file contains redux actions related to subscriptions state
 */
import {
  TRANSLATIONS__TRANSLATE_MESSAGE,
  TRANSLATIONS__UNTRANSLATE_MESSAGE,
} from '../../constants/actionTypes';
import { Message } from '../../generated/graphql';

import {
  MessageTranslation,
  TranslateMessage,
  UntranslateMessage,
} from '../../models/translations';

/**
 * Action to set the message to be translated
 *
 * @param messageId   ID of the topic to subscribe to
 * @param translation The translated message (title + text)
 * @returns           Appropriately formatted redux action
 */
export const translateMessage = (
  messageId: Message['id'],
  translation: MessageTranslation,
): TranslateMessage => {
  return {
    payload: {
      messageId,
      translation,
    },
    type: TRANSLATIONS__TRANSLATE_MESSAGE,
  };
};

/**
 * Action to set the message to be untranslated
 *
 * @param messageId ID of the topic to subscribe to
 * @returns         Appropriately formatted redux action
 */
export const untranslateMessage = (
  messageId: Message['id'],
): UntranslateMessage => {
  return {
    payload: {
      messageId,
    },
    type: TRANSLATIONS__UNTRANSLATE_MESSAGE,
  };
};
