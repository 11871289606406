/**
 * @file Handle topic create mutation by updating the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicCreateMutation,
  TopicCreateMutationVariables,
} from '../../../../../generated/graphql';
import topicCreate from '../utils/updaters/topics/topicCreate';

/**
 * Handle subscription to everything related to topics
 *
 * @param result Result of a graphql operation
 * @param args   Arguments of a graphql operation
 * @param cache  URQL graphql cache
 * @param info   Additional resolver info
 */
const topicCreateMutation: UpdateResolver<
  TopicCreateMutation,
  TopicCreateMutationVariables
> = (result, args, cache, info) => {
  topicCreate(result, args, cache, info);
};

export default topicCreateMutation;
