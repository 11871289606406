/**
 * @file Contains customization for MuiAvatar Component
 */

import { Components } from '@mui/material';

import { colorError, colorPrimary } from '../colors';

const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      color: colorPrimary[700],
    },
  },
  variants: [
    {
      props: { color: 'error' },
      style: {
        '&:focus-visible': {
          backgroundColor: colorError[100],
        },
        '&:hover': {
          backgroundColor: colorError[50],
        },
      },
    },
  ],
};

export default MuiIconButton;
