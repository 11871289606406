/**
 * @file contains redux actions related to draftTemplateTopic state
 */
import {
  DRAFTS__TEMPLATE__TOPIC__SAVE,
  DRAFTS__TEMPLATE__TOPIC__SEARCH__SET,
  DRAFTS__TEMPLATE__TOPIC__SET,
} from '../../constants/actionTypes';

import {
  DraftActionTemplateTopicSave,
  DraftActionTemplateTopicSearchSet,
  DraftActionTemplateTopicSet,
} from '../../models/draftTemplateTopic';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to (un)assign a group from a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param topicId  Topic ID
 * @returns        The action itself
 */
export const updateDraftTemplateTopicRequest = (
  parentId: string,
  topicId: string,
): DraftActionTemplateTopicSet => ({
  parentId,
  topicId,
  type: DRAFTS__TEMPLATE__TOPIC__SET,
});

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftTemplateTopicRequest = (
  parentId: string,
): DraftActionTemplateTopicSave => ({
  parentId,
  type: DRAFTS__TEMPLATE__TOPIC__SAVE,
});

/**
 * Action to store search terms for assignees popup in compose block
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param value    The search term
 * @returns        The action itself
 */
export const searchDraftTemplateTopicRequest = (
  parentId: string,
  value: string,
): DraftActionTemplateTopicSearchSet => ({
  parentId,
  type: DRAFTS__TEMPLATE__TOPIC__SEARCH__SET,
  value,
});
