import { styled } from 'styled-components';

/**
 * The wrapper around the entire form
 * We limit the height, so that, when rendered within the compose form popup,
 * it doesn't get out of screen for short displays (~700px height)
 */
export const Form = styled.form`
  /**
   * We use 50vh for assignment popup
   * but here we have additional buttons, hence lower value
   */
  max-height: 40vh;
`;
