/**
 * @file contains search param constants
 */

import { LabelUsage } from '../../models/message';

export const SEARCH_PARAM__ATTACHMENT_ID = 'attachmentId';
export const SEARCH_PARAM__COMPOSE = 'compose';
export const SEARCH_PARAM__COMPOSE_MODE = 'composeMode';
export const SEARCH_PARAM__FILTER__MEMBERS = 'filterMembers';
export const SEARCH_PARAM__FILTER__QUICK = 'quickFilters';
export const SEARCH_PARAM__GROUP_ID = 'groupId';
export const SEARCH_PARAM__HOTEL_ID = 'hotelId';
export const SEARCH_PARAM__LABEL_ID = 'labelId';
export const SEARCH_PARAM__LABEL_MODE = 'labelMode';
export const SEARCH_PARAM__SIDEBAR = 'sidebar';

/**
 * Specific params for message details labels. We can have the compose popup and
 * messages details labels open at the same time and so we do need different params
 * for each.
 */
export const SEARCH_PARAM__MESSAGE_FULL_LABEL_ID = 'mLabelId';
export const SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE = 'mLabelMode';

export const SEARCH_PARAM__MESSAGE_ID = 'messageId';
export const SEARCH_PARAM__MODAL_ID = 'modalId';

export const SEARCH_PARAM__RECURRING_MODE = 'recurringMode';
/**
 * Currently used only to indicate that we want to redirect after topic is created.
 */
export const SEARCH_PARAM__REDIRECT = 'redirect';
export const SEARCH_PARAM__REPLIES = 'replies';

export const SEARCH_PARAM__TEMPLATE_ID = 'templateId';
export const SEARCH_PARAM__TOPIC_ID = 'topicId';
export const SEARCH_PARAM__USER_ID = 'userId';

export const LABEL_PARAMS__BY_USAGE: Record<
  LabelUsage,
  typeof SEARCH_PARAM__LABEL_MODE | typeof SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE
> = {
  compose: SEARCH_PARAM__LABEL_MODE,
  messageFull: SEARCH_PARAM__MESSAGE_FULL_LABEL_MODE,
};
