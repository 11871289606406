/**
 * @file A topic was deleted, so we update the cache accordingly
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicDeleteMutation,
  TopicDeleteMutationVariables,
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables,
  TopicsDocument,
  TopicSettingsDocument,
} from '../../../../../../../generated/graphql';
import { reportError } from '../../../../../../../services/reporting';
import queryDeletion from '../queryDeletion';

type Mutations = TopicDeleteMutation | TopicInfoSubscription;
type Variables = TopicDeleteMutationVariables | TopicInfoSubscriptionVariables;

/**
 * A topic was deleted, so we remove it from all queries
 * This helper is only used for topic subscriptions.
 *
 * @param result The result of the mutation (unused)
 * @param _args  Arguments of a graphQL operation
 * @param cache  URQL graphQL cache
 */
const topicDelete: UpdateResolver<Mutations, Variables> = (
  result: TopicInfoSubscription,
  _args,
  cache,
) => {
  /**
   * For DELETE action, topic id is passed differently into the sub,
   * it is attached directly to `topicInfo` object. In all other actions,
   * it is in the `topicInfo.topic` object.
   */
  const { id: topicId } = result.topicInfo;

  if (!topicId) {
    reportError('topicDelete: Delete subscription missing topicId.');
    return;
  }

  queryDeletion({
    cache,
    entityId: topicId,
    query: TopicsDocument,
    queryName: 'topics',
  });

  queryDeletion({
    cache,
    entityId: topicId,
    query: TopicSettingsDocument,
    queryName: 'topicsAll',
  });

  /**
   * If element is not in the cache, the app will break on
   * trying to invalidate it, this is just safety check.
   */
  const isItInTheCache = !!cache.resolve(
    { __typename: 'Topic', id: topicId },
    'id',
  );

  // This alone is enough to remove this topic from all queries,
  // but it's slower than direct cache manipulation
  // so queryDeletion is used to instantly remove from UI,
  // and then this is added to clean up all the links
  // and from any queries we might have forgotten
  if (isItInTheCache) {
    cache.invalidate({
      __typename: 'Topic',
      id: topicId,
    });
  }
};

export default topicDelete;
