import React, { FC, useState } from 'react';

import translate from '../../../../../i18n/translate';
import { InputFileProps } from '../../../../../models/attachment';
import InputFileAttachment from '../../../InputFileAttachment';
import ButtonControl, { NAMES } from '../ButtonControl';

export type Props = {
  inputFileProps: InputFileProps;
};

const iconName = NAMES.COMPOSE__CONTROL_ATTACH_FILE;

/**
 * The button for attaching a file
 *
 * @param props                Props passed to the component
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @returns                    The component itself
 */
const ButtonAttachFile: FC<Props> = ({ inputFileProps }) => {
  /** @todo Find a way to catch user closing file uploader and set isActive to false */
  const [isActive] = useState(false);
  const label = translate('COMPOSE__ATTACH__FILE');

  /**
   * Open file picker system window
   */
  const handleButtonClick = () => {
    inputFileProps.ref.current?.click();
  };

  return (
    <>
      <ButtonControl
        iconName={iconName}
        isActive={isActive}
        label={label}
        onClick={handleButtonClick}
      />
      <InputFileAttachment inputFileProps={inputFileProps} />
    </>
  );
};

export default ButtonAttachFile;
