/**
 * @file Helper functions for Assignment component
 */

import {
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../generated/graphql';

/**
 * Get whether the assignment modal is empty (no groups or members to show)
 *
 * @param groups  The groups that are able to be assigned
 * @param members The members that are able to be assigned
 * @returns       Whether it's true
 */
export const getIsEmpty = (
  groups: UserGroupBasicFragment[],
  members: UserBasicFragment[],
): boolean => {
  return members.length + groups.length === 0;
};

/**
 * Get whether the user is searching groups and members
 * (whitespace doesn't count)
 *
 * @param searchTerm What is typed in the search form
 * @returns          Whether it's true
 */
export const getIsSearching = (searchTerm: string): boolean => {
  return searchTerm.trim().length !== 0;
};

/**
 * Get whether to show no results text
 *
 * @param isEmpty     Whether the assignment modal is empty (no groups or members to show)
 * @param isLoading   Whether the data is being fetched
 * @param isSearching Whether the user is searching groups and members
 * @returns           Whether to show no results text
 */
export const getShouldShowNoResults = (
  isEmpty: boolean,
  isLoading: boolean,
  isSearching: boolean,
): boolean => {
  return isEmpty === true && isLoading === false && isSearching === false;
};

/**
 * Get whether to show the search box
 *
 * @param isEmpty     Whether the assignment modal is empty (no groups or members to show)
 * @param isSearching Whether the user is searching groups and members
 * @returns           Whether to show the search box
 */
export const getShouldShowSearchBox = (
  isEmpty: boolean,
  isSearching: boolean,
): boolean => {
  return isEmpty === false || isSearching === true;
};

/**
 * Get whether to show the separator line between groups and members
 *
 * @param groups  The groups that are able to be assigned
 * @param members The members that are able to be assigned
 * @returns       Whether to show it
 */
export const getShouldShowSeparator = (
  groups: UserGroupBasicFragment[],
  members: UserBasicFragment[],
): boolean => {
  return groups.length !== 0 && members.length !== 0;
};
