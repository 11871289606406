/**
 * @file Get whether the user is subscribed to push notifications
 */

import { useQuery } from 'urql';

import {
  PushSubscriptionsDocument,
  PushSubscriptionsQuery,
} from '../../generated/graphql';

import useFirebaseToken from './useFirebaseToken';
import useIsSupported from './useIsSupported';
import useNotificationPermissionStatus from './useNotificationPermissionStatus';

/**
 * Check whether the current token is among those subscribed
 *
 * @param data  The subscription data
 * @param token The token to check against
 * @returns     Whether it's true
 */
const getIsSubscribed = (
  data?: PushSubscriptionsQuery['pushSubscriptions'],
  token?: string | null,
): boolean | null => {
  return data?.some(subscription => subscription.token === token) ?? null;
};

/**
 * Get whether the user is subscribed to push notifications
 *
 * @returns Whether it's true (or null if can't be determined)
 */
const useIsSubscribed = (): boolean | null => {
  const [{ data, error, fetching }] = useQuery({
    query: PushSubscriptionsDocument,
    variables: {},
  });
  const firebaseToken = useFirebaseToken();
  const isSupported = useIsSupported();
  const permission = useNotificationPermissionStatus();

  if (isSupported === false) {
    return false;
  }

  if (error !== undefined || fetching === true || permission !== 'granted') {
    return null;
  }

  return getIsSubscribed(data?.pushSubscriptions, firebaseToken);
};

export default useIsSubscribed;
