import { styled } from 'styled-components';

import devices from '../../../styles/devices';

import MessageSeparator from '../MessageSeparator';

// Wrapper for the entire component
export const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

// - - - line below the date box
export const Separator = styled(MessageSeparator)`
  position: absolute;
  top: 50%;
  width: 100%;
`;

// The date box; Needs to be relative with z-index to be above Separator
export const Date = styled.time`
  align-items: center;
  background-color: var(--color-background);
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  display: flex;
  font-size: var(--font-sm);
  font-weight: var(--font-light);

  /* Do horizontal padding with calc so we don't add yet another var for one use case */
  padding: var(--spacing-tiny) calc(var(--spacing-medium) + var(--spacing-tiny));
  position: relative;

  @media ${devices.laptopS} {
    padding: var(--spacing-small)
      calc(var(--spacing-medium) + var(--spacing-small));
  }
`;
