import { styled } from 'styled-components';

export const TitleComponentWrapper = styled.div`
  display: flex;
  justify-content: center;

  /* 80px = leftPadding(15px) + 2 x sideWidthMin +  rightPadding(15px) */
  max-width: calc(100vw - 80px);
  overflow: hidden;
`;
