/**
 * @file Contains base mui styles shared across all components
 */
import { css, Theme } from '@mui/material';

import devices from '../devices';

import { colorNeutral } from './colors';

/**
 * Generates basic styles for desktop header component
 *
 * @param theme MUI theme
 * @returns     Base styles for desktop header component
 */
export const getDesktopHeaderStyle = (theme: Theme) => ({
  backgroundColor: colorNeutral[100],
  padding: theme.spacing(0, 1),
  [`@media ${devices.tabletS}`]: {
    borderRadius: 'var(--border-radius-huge)',
    padding: theme.spacing(3.75, 2),
  },
});

/**
 * Makes an element visually hidden from in the DOM
 *
 * @returns styles for visually hiding the element
 */
export const visuallyHidden = css({
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: '1px',
  marginLeft: '-1px',
  marginTop: '-1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
  // Adding !important is not directly supported in JSS,
  // but you can add it using a function or inline styles when applying
});
