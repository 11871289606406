import React, { FC } from 'react';

import SkeletonLine from '../../SkeletonLine';

import * as Styled from './styled';

export type Props = {
  direction: 'column' | 'row';
};
/**
 * Loader component for single dashboard KPI
 *
 * @param props           Props passed to the component
 * @param props.direction Direction in which flex will be applied (row or column)
 * @returns               The component itself
 */
const KpiSingle: FC<Props> = ({ direction }) => {
  const Wrapper =
    direction === 'row' ? Styled.DataWrapper : Styled.DataWrapperColumn;

  return (
    <Wrapper>
      <Styled.KpiName>
        <SkeletonLine />
      </Styled.KpiName>
      <Styled.Number>
        <SkeletonLine />
      </Styled.Number>
    </Wrapper>
  );
};
export default KpiSingle;
