import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

type LinkProps = {
  'data-color': string;
  'data-position': 'left' | 'right';
};

/**
 * Get the color to use for link text
 *
 * @param props The props passed to the component
 * @returns     The color to use
 */
const getColor = (props: LinkProps) => {
  return props['data-color'];
};

export const Link = styled(RouterLink)<LinkProps>`
  align-items: center;
  color: ${getColor};
  display: flex;
  flex-direction: ${props =>
    props['data-position'] === 'left' ? 'row' : 'row-reverse'};
  gap: var(--spacing-small);
  justify-content: left;
  overflow-x: hidden;
`;
