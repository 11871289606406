import React, { FC } from 'react';

import { ChecklistItem } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import Progress from '../Progress';

import * as Styled from './styled';

export type Props = {
  className?: string;
  items: Pick<ChecklistItem, 'checked'>[];
};

/**
 * Checklist progress
 * Has the bar but also x/y label
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.items     Checklist items to get the progress for
 * @returns               The component itself
 */
const ProgressChecklist: FC<Props> = ({ className, items }) => {
  const max = items.length;
  const value = items.filter(item => item.checked).length;

  return (
    <Styled.Wrapper className={className}>
      <Styled.Heading>
        {translate('MESSAGE__CHECKLIST')}
        <Styled.Status>{`${value}/${max}`}</Styled.Status>
      </Styled.Heading>
      <Progress max={max} value={value} />
    </Styled.Wrapper>
  );
};

export default ProgressChecklist;
