/**
 * @file Map Redux state to DevPanel props
 */

import { MapStateToProps } from 'react-redux';

import AppState from '../../models/state';

import { OwnProps, StateProps } from './props';

/**
 * Map Redux state to component props
 *
 * @param state            Global Redux state
 * @param state.debug      Debug state
 * @param state.markAsRead Mark as read state
 * @returns                Object with props to inject
 */
const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = ({
  debug,
  markAsRead,
}) => ({ ...debug, markAsRead });

export default mapStateToProps;
