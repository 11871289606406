import { styled } from 'styled-components';

import { getToastColorPrimary } from '../helpers';
import { DataProps } from '../types';

// The button for hiding/showing additional text
export const Toggle = styled.button<DataProps>`
  color: ${getToastColorPrimary};
  font-weight: var(--font-weight-label-bold);
`;
