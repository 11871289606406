/**
 * @file Various getters for date and time
 */

import { InputHTMLAttributes } from 'react';

import { dateFormatInput, timeFormatInput } from './format';

/**
 * Add 1h offset time to current datetime for better UX
 *
 * @param dateCurrent The value of date passed to the component wit +1h offset
 * @returns           The date to use
 */
export const addOffsetTime = (dateCurrent: Date): Date => {
  const dateShifted = new Date(dateCurrent.getTime());
  dateShifted.setHours(dateShifted.getHours() + 1);
  return dateShifted;
};

/**
 * Get which datetime to use within the component
 * (if null is passed, use current time)
 *
 * @param dateCurrent The value of date passed to the component
 * @returns           The date to use
 */
export const getDateToUse = (dateCurrent: Date | null): Date => {
  return dateCurrent ?? addOffsetTime(new Date());
};

/**
 * If dateMin is not null, return the appropriately formatted date string
 * that can be inserted into <input /> as the value
 *
 * If it is, return undefined, because we don't want to be bound (InputHTMLAttributes['min'] can't be null)
 *
 * @param dateMin The date to parse
 * @returns       Valid input value (undefined is valid)
 */
export const getDateMinValue = (
  dateMin: Date | null,
): InputHTMLAttributes<HTMLInputElement>['min'] => {
  return dateMin === null ? undefined : dateFormatInput(dateMin);
};

/**
 * If dateMin is not null, return the appropriately formatted time string
 * that can be inserted into <input /> as the value
 *
 * If it is, return undefined, because we don't want to be bound (InputHTMLAttributes['min'] can't be null)
 *
 * @param dateMin The date to parse
 * @returns       Valid input value (undefined is valid)
 */
export const getTimeMinValue = (
  dateMin: Date | null,
): InputHTMLAttributes<HTMLInputElement>['min'] => {
  return dateMin === null ? undefined : timeFormatInput(dateMin);
};
