import React, { FC, useId } from 'react';

import RichText from '../../../Common/RichText';

import FormLabel from '../FormLabel';

import * as Styled from './styled';

export type Props = {
  className?: string;
  isDisabled?: boolean;
  isLabelVisible?: boolean;
  isRequired?: boolean;
  label: string;
  maxLength?: number;
  minLength?: number;
  name?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  shouldFocus?: boolean;
  value: string;
};

/**
 * A single text input for a form
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.isDisabled     Whether the field is required
 * @param props.isLabelVisible Whether label text is visible or just associated with the input
 * @param props.isRequired     Whether the field is required
 * @param props.label          Label text
 * @param props.maxLength      Maximum text length
 * @param props.minLength      Minimum text length
 * @param props.name           Name attribute for the element
 * @param props.onBlur         Callback to be invoked whenever the input is blurred
 * @param props.onChange       Callback to be invoked whenever the text changes
 * @param props.shouldFocus    Should the input be auto focused
 * @param props.value          Current text
 * @returns                    The component itself
 */
const FormInput: FC<Props> = ({
  className,
  isDisabled,
  isLabelVisible,
  isRequired,
  label,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  shouldFocus = false,
  value,
}) => {
  const inputId = useId();

  return (
    <FormLabel
      className={className}
      inputId={inputId}
      isVisible={isLabelVisible}
      label={label}
    >
      {isDisabled === true ? (
        <RichText>{value}</RichText>
      ) : (
        <Styled.Input
          id={inputId}
          isDisabled={isDisabled}
          isRequired={isRequired}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          shouldFocus={shouldFocus}
          value={value}
        />
      )}
    </FormLabel>
  );
};

export default FormInput;
