import { css, styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';
import CommonChevron from '../Chevron';

type LabelTextProps = {
  'data-is-visible': boolean;
};

/**
 * Get additional styles to apply to the label based on its state
 *
 * @param props Props passed to the label
 * @returns     The CSS to apply
 */
const getLabelVisibleStyles = (props: LabelTextProps) => {
  if (props['data-is-visible'] === true) {
    return css`
      display: block;
    `;
  } else {
    return visuallyHidden;
  }
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const Select = styled.select`
  align-items: center;

  /* Has to be there for Samsung browser */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features, property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  background-color: var(--color-background);
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  color: var(--color-text-regular);
  display: flex;
  font-size: var(--font-size-base);
  justify-content: space-between;
  padding: var(--spacing-tiny);
  padding-right: var(--spacing-big);
  position: relative;
  text-align: left;
`;

export const Chevron = styled(CommonChevron)`
  position: absolute;
  right: var(--spacing-small);
`;

export const Label = styled.label<LabelTextProps>`
  color: var(--color-text-light);
  ${props => getLabelVisibleStyles(props)}
  font-size: var(--font-sm);
  font-weight: var(--font-weight-label-bold);
  padding-bottom: var(--spacing-tiny);
`;
