/**
 * @file Get the current status of a specific permission for the user
 */

import { ChangeEventHandler, useEffect, useReducer } from 'react';

import initialState from './initialState';
import reducer from './reducer';

/**
 * Get the current status of a specific permission for the user
 *
 * @param name Which permission type this to get the status for
 * @returns    The current permission state
 */
const usePermissionStatus = (name: PermissionName): PermissionState | null => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    /**
     * The user has changed the permission level,
     * so we update the local state
     *
     * @param event The event that took place
     */
    const handleChange: ChangeEventHandler<PermissionStatus> = event => {
      const status = event.currentTarget;

      dispatch({
        name,
        status,
      });
    };

    /**
     * In order to prevent memory leaks,
     * we only initialize a single listener per permission type
     *
     * We don't need to keep initialized info in state
     */
    if (state[name] === null && 'permissions' in navigator) {
      navigator.permissions
        .query({ name })
        .then(response => {
          dispatch({
            name,
            status: response,
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          response.addEventListener('change', handleChange, false);
        })
        .catch(error => {
          /**
           * @todo Handle permissions for unsupported browsers (HOT-1829)
           */
          if (name !== 'notifications') {
            reportError(error);
          }
        });
    }

    return () => {
      const status = state[name];
      if (status !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        status.removeEventListener('change', handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return state[name]?.state ?? null;
};

export default usePermissionStatus;
