import { BadgeProps } from '@mui/material';
import React, { ElementType } from 'react';

import { PREFERENCE_BADGE_LIMIT } from '../../../constants/preferences';

import * as Styled from './styled';

/**
 * Rounded red badge for notifications number
 *
 * @param props Props passed to the component
 * @returns     The circle notification with number or nothing if no notifications
 */
const NotificationsBadge = <C extends ElementType>(
  props: BadgeProps<C, { component?: C }>,
) => {
  return (
    <Styled.NotificationsBadge
      {...props}
      color="error"
      max={PREFERENCE_BADGE_LIMIT}
    />
  );
};

export default NotificationsBadge;
