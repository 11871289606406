import React, { FC } from 'react';

import SplashScreen from '../../components/SplashScreen';

import * as Styled from './styled';

/**
 * Splash screen for the app
 *
 * @returns The container itself
 */
const SplashScreenContainer: FC = () => {
  return (
    <Styled.Wrapper>
      <SplashScreen />
    </Styled.Wrapper>
  );
};

export default SplashScreenContainer;
