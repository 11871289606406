import { styled } from 'styled-components';

import { CssVariable } from '../../../styles/cssTypes';

type WrapperProps = {
  'data-deleted'?: boolean;
  'data-font-size'?: number;
  'data-inactive'?: boolean;
  'data-size': number;
};

/**
 * Get which color to use for the background
 *
 * @param props The props passed to the wrapper
 * @returns     The variable to use
 */
const getColorBackground = (props: WrapperProps): CssVariable => {
  if (props['data-inactive']) {
    return 'var(--color-background-avatar-inactive)';
  }

  return 'var(--color-background-avatar)';
};

/**
 * Get which color to use for the border
 *
 * @param props The props passed to the wrapper
 * @returns     The variable to use
 */
const getColorBorder = (props: WrapperProps): CssVariable => {
  if (props['data-inactive']) {
    return 'var(--color-border-avatar-inactive)';
  }

  return 'var(--color-border-avatar)';
};

/**
 * Get which color to use for the text
 *
 * @param props The props passed to the wrapper
 * @returns     The variable to use
 */
const getColorText = (props: WrapperProps): CssVariable => {
  if (props['data-inactive']) {
    return 'var(--color-text-selected)';
  }

  return 'var(--color-background)';
};

/**
 * Get the padding for the wrapper
 *
 * @param props The props passed to the wrapper
 * @returns     The value to use
 */
const getPadding = (props: WrapperProps): CssVariable => {
  if (props['data-deleted']) {
    return 'var(--spacing-tiny)';
  }

  return 0;
};

export const Wrapper = styled.div<WrapperProps>`
  align-items: center;
  background-color: ${getColorBackground};
  border: 1px solid;
  border-color: ${getColorBorder};
  border-radius: var(--border-radius-small);
  color: ${getColorText};
  display: inline-flex;
  flex-shrink: 0;
  font-size: ${props => props['data-font-size']}rem;
  font-weight: var(--font-weight-avatar-initials);
  height: ${props => props['data-size']}px;
  justify-content: center;
  padding: ${getPadding};
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: ${props => props['data-size']}px;
`;
