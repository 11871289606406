/**
 * @file contains redux actions related to draftReminders state
 */

import {
  DRAFTS__REMINDERS__ADD,
  DRAFTS__REMINDERS__REMOVE,
  DRAFTS__REMINDERS__SAVE,
  DRAFTS__REMINDERS__SET,
} from '../../constants/actionTypes';
import { Reminder } from '../../generated/graphql';

import {
  DraftActionRemindersAdd,
  DraftActionRemindersRemove,
  DraftActionRemindersSave,
  DraftActionRemindersSet,
} from '../../models/draftReminders';

/**
 *
 * CREATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param reminder Reminder
 * @returns        The action itself
 */
export const addDraftRemindersRequest = (
  parentId: string,
  reminder: Reminder,
): DraftActionRemindersAdd => ({
  parentId,
  reminder,
  type: DRAFTS__REMINDERS__ADD,
});

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftRemindersRequest = (
  parentId: string,
): DraftActionRemindersSave => ({
  parentId,
  type: DRAFTS__REMINDERS__SAVE,
});

/**
 * Action to set the reminders for a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param position Array index of the reminder
 * @param reminder New reminder
 * @returns        The action itself
 */
export const setDraftRemindersRequest = (
  parentId: string,
  position: number,
  reminder: Reminder,
): DraftActionRemindersSet => ({
  parentId,
  position,
  reminder,
  type: DRAFTS__REMINDERS__SET,
});

/**
 *
 * DELETE actions
 *
 */

/**
 * Action to remove a reminder from a particular draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param position Array index of the reminder (if undefined remove all of the items)
 * @returns        The action itself
 */
export const removeDraftRemindersRequest = (
  parentId: string,
  position?: number,
): DraftActionRemindersRemove => ({
  parentId,
  position,
  type: DRAFTS__REMINDERS__REMOVE,
});
