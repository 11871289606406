import React, { FC } from 'react';

import Chevron from '../Chevron';

import { getValueNextDecrease, getValueNextIncrease } from './helper';
import * as Styled from './styled';

export type Props = {
  labelIncrease: string;
  labelDecrease: string;
  min?: number;
  max: number;
  onChange: (value: number) => void;
  value: number;
};

const chevronSize = 15;

/**
 * Pick value that's between min and max (inclusive)
 * by using up and down arrows to change it
 *
 * @param props               Props passed to the component
 * @param props.labelDecrease aria-label for decrease button
 * @param props.labelIncrease aria-label for increase button
 * @param props.max           The maximal allowed value
 * @param props.min           The minimal allowed value
 * @param props.onChange      Callback to invoke when the selected value changes
 * @param props.value         Current picker value
 * @returns                   The component itself
 */
const PickerValue: FC<Props> = ({
  labelDecrease,
  labelIncrease,
  min = 0,
  max,
  value,
  onChange,
}) => {
  /**
   * The user has clicked the up arrow
   */
  const handleClickIncrease = () => {
    const next = getValueNextIncrease(value, min, max);
    onChange(next);
  };

  /**
   * The user has clicked the down arrow
   */
  const handleClickDecrease = () => {
    const next = getValueNextDecrease(value, min, max);
    onChange(next);
  };

  return (
    <Styled.Wrapper>
      <button
        aria-label={labelIncrease}
        onClick={handleClickIncrease}
        type="button"
      >
        <Chevron direction="up" size={chevronSize} />
      </button>

      <Styled.Value>{value.toString().padStart(2, '0')}</Styled.Value>

      <button
        aria-label={labelDecrease}
        onClick={handleClickDecrease}
        type="button"
      >
        <Chevron direction="down" size={chevronSize} />
      </button>
    </Styled.Wrapper>
  );
};

export default PickerValue;
