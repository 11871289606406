/**
 * @file Generate modal config depending on notification permission state
 */

import useNotificationPermissionStatus from '../../../../hooks/notifications/useNotificationPermissionStatus';
import translate from '../../../../i18n/translate';
import { Config } from '../types';

/**
 * Generate modal config depending on notification permission state
 * (buttons, heading and subheading)
 *
 * @param onConfirm Callback to invoke once the user clicks confirm button
 * @param onCancel  Callback to request that the modal is closed
 * @returns         The config to use
 */
const useConfig = (onConfirm: () => void, onCancel: () => void): Config => {
  const permission = useNotificationPermissionStatus();

  const mapping: Record<NotificationPermission, Config> = {
    default: {
      buttons: [
        {
          children: translate('GENERAL__DONT_ALLOW'),
          key: 'cancel',
          onClick: onCancel,
        },

        {
          children: translate('GENERAL__ALLOW'),
          key: 'confirm',
          onClick: onConfirm,
          usage: 'confirm',
        },
      ],
      textHeading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__HEADING',
      ),
      textSubheading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__SUBHEADING',
      ),
    },

    denied: {
      buttons: [
        {
          children: translate('GENERAL__OK'),
          key: 'cancel',
          onClick: onCancel,
        },
      ],
      textHeading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DENIED__HEADING',
      ),
      textSubheading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DENIED__SUBHEADING',
      ),
    },

    granted: {
      buttons: [
        {
          children: translate('GENERAL__OK'),
          key: 'confirm',
          onClick: onConfirm,
          usage: 'confirm',
        },
      ],
      textHeading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__HEADING',
      ),
      textSubheading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__SUBHEADING',
      ),
    },
  };

  if (permission === null) {
    return {
      buttons: [],
      textHeading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__HEADING',
      ),
      textSubheading: translate(
        'MODAL__NOTIFICATIONS__MESSAGE_SENT_DEFAULT__SUBHEADING',
      ),
    };
  }

  return mapping[permission];
};

export default useConfig;
