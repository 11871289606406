/* eslint-disable complexity */
import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import useSelect, { Item } from '../../../hooks/useSelect';
import translate from '../../../i18n/translate';
import SelectMobile from '../SelectMobile';

import SelectChevron from './SelectChevron';
import * as Styled from './styled';
import useListPlacement from './useListPlacement';

export type Props = {
  className?: HTMLSelectElement['className'];
  defaultItem?: Item;
  disabled?: HTMLSelectElement['disabled'];
  formId?: string;
  isLabelVisible?: boolean;
  isLoading?: boolean;
  isRequired?: HTMLSelectElement['required'];
  isTextOnly?: boolean;
  items: Item[];
  label?: string;
  name: HTMLSelectElement['name'];
  onChange?: (value: string) => void;
  placeholder: string;
  placement?: 'up' | 'down' | 'auto';
  selectId: HTMLSelectElement['id'];
};

/**
 * Accessible <select /> component with all basic features
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.defaultItem    Default selected item, if any
 * @param props.disabled       Whether the Select is disabled
 * @param props.formId         The ID of the form the dropdown is associated with
 * @param props.isLabelVisible Whether the label is hidden
 * @param props.isLoading      Whether the data for dropdown list is still loading or ready
 * @param props.isRequired     If the select is isRequired in terms of a form
 * @param props.isTextOnly     Selected option will only show its label text
 * @param props.items          An array of items to be used as options
 * @param props.label          Label for the entire select dropdown
 * @param props.name           Name of the select dropdown
 * @param props.onChange       Callback to be invoked when a selection is made
 * @param props.placeholder    Placeholder for the dropdown when no option is selected
 * @param props.placement      Where the dropdown should physically be
 * @param props.selectId       Id used for generating Select (button) id
 * @returns                    A Select dropdown component
 */
const Select: FC<Props> = ({
  className,
  defaultItem,
  disabled = false,
  formId,
  isLabelVisible = true,
  isLoading = false,
  isRequired = false,
  isTextOnly = false,
  items,
  label,
  name,
  onChange,
  placeholder,
  placement = 'auto',
  selectId,
}) => {
  const isMobile = useIsMobile();
  const itemCount = items.length;

  const {
    getComboBoxProps,
    getItemProps,
    getListProps,
    getWrapperProps,
    state,
  } = useSelect({
    defaultItem,
    isDisabled: disabled,
    itemCount,
    name,
    onChange,
    selectId,
  });

  /**
   * Helper that renders selected item label
   *
   * @returns Selected Item label
   */
  const renderSelectedLabel = () => {
    if (state.selectedItem === undefined) {
      return <Styled.Placeholder>{placeholder}</Styled.Placeholder>;
    }

    return isTextOnly ? state.selectedItem.labelText : state.selectedItem.label;
  };

  const comboBoxProps = getComboBoxProps();
  /**
   * Whether the select is required or not
   */
  const required = isRequired && !state.selectedItem?.value;

  /**
   * Mobile select does not need all the complex items desktop one requires
   * so we map over it to simplify it.
   */
  const itemsMobile = items.map(item => {
    return {
      label: item.labelText ?? item.label,
      value: item.value,
    };
  });

  const { labelRef, labelHeight, listRef, isUpwards } = useListPlacement({
    comboBoxRef: comboBoxProps.ref,
    isOpen: state.isOpen,
    itemCount,
    placement,
  });

  if (isMobile) {
    return (
      <SelectMobile
        className={className}
        defaultValue={defaultItem?.value}
        disabled={disabled}
        isLabelVisible={isLabelVisible}
        items={itemsMobile}
        label={label}
        name={name}
        onChange={onChange}
        required={required}
      />
    );
  }

  return (
    <Styled.Wrapper
      {...getWrapperProps()}
      aria-disabled={disabled}
      className={className}
      data-open={state.isOpen}
    >
      {label !== undefined && (
        <Styled.Label
          data-is-visible={isLabelVisible}
          id={selectId}
          ref={labelRef}
        >
          {label}
        </Styled.Label>
      )}
      <Styled.ComboBox
        {...comboBoxProps}
        aria-disabled={disabled}
        data-open={state.isOpen}
        data-upwards={isUpwards}
      >
        {renderSelectedLabel()}
        <SelectChevron
          isDisabled={disabled}
          isLoading={isLoading}
          isOpen={state.isOpen}
        />
      </Styled.ComboBox>

      {disabled !== true && (
        <Styled.List
          {...getListProps()}
          data-label-height={labelHeight}
          data-open={state.isOpen}
          data-upwards={isUpwards}
          ref={listRef}
        >
          {items.length > 0 ? (
            items.map((item, index) => {
              const itemProps = getItemProps(item, index);
              return (
                <Styled.ListItem
                  key={item.value}
                  {...itemProps}
                  onMouseEnter={() => {
                    /**
                     * Change focus on hover cause default html select
                     * works like that
                     */
                    itemProps.ref.current?.focus();
                  }}
                >
                  {item.label}
                </Styled.ListItem>
              );
            })
          ) : (
            <Styled.NoItemsWrapper>
              {translate('EMPTY__SELECT')}
            </Styled.NoItemsWrapper>
          )}
        </Styled.List>
      )}

      {/* Hidden select is used to enforce validation if a selected option is isRequired */}
      <Styled.HiddenSelect
        aria-hidden="true"
        form={formId}
        name={name}
        required={required}
        tabIndex={-1}
      />
    </Styled.Wrapper>
  );
};

export default Select;
