// For some reason, es-lint recognizes this as a file with multiple components
// This started happening with React 18 update
/* eslint-disable react/no-multi-comp */
/**
 * @file Handles keys for cache for some models that are not accounted for.
 */

import { KeyingConfig } from '@urql/exchange-graphcache';

import {
  ChecklistItem,
  DashboardKpi,
  I18nLocalization,
  I18nRole,
  I18nTargetLanguage,
  Message,
  Reminder,
  SearchQuery,
  UserNotifications,
} from '../../../../generated/graphql';
import { ReminderString } from '../../../../models/reminder';

/**
 * Handles keys for cache for some models that are not accounted for.
 */
const keys = {
  ChecklistItemTranslation: ({ text }: ChecklistItem['translation']) => text,
  CommentTranslation: (): null => null,
  DashboardKpi: ({ searchQuery: { id } }: DashboardKpi): SearchQuery['id'] => {
    return id;
  },
  DateOffset: (): null => null,
  GroupedNotifications: (): null => null,
  HotelPermissions: (): null => null,
  I18n: (): null => null,
  I18nLocalization: ({ value }: I18nLocalization): I18nLocalization['value'] =>
    value,
  I18nRole: ({ value }: I18nRole): I18nRole['value'] => value,
  I18nTargetLanguage: ({
    value,
  }: I18nTargetLanguage): I18nTargetLanguage['value'] => value,
  MessageTranslation: ({ id }: Message) => id,
  // NodesInfo returns the fetched count and total count of nodes.
  // Since the response structure is fixed and only returns counts,
  // there is no need to use keys to access specific fields within the response.
  NodesInfo: (): null => null,
  PaginationResult: (): null => null,
  // PaginationResultExtended returns the applied pagination params that are fixed,
  // and therefore we don't need to use keys
  PaginationResultExtended: (): null => null,
  Reminder: ({ amount, unit }: Reminder): ReminderString => `${amount}_${unit}`,
  SearchInfo: (): null => null,
  SearchQueryTitle: (): null => null,
  SearchResult: (): null => null,
  UserNotifications: ({ type }: UserNotifications): UserNotifications['type'] =>
    type,
  /**
   * For some reason TS doesn't recognize
   * UserNotifications as compatible type with Data (data type for arguments of this callback),
   * probably because they are missing properties like "id"/"_id"
   * so i manually defined this object as KeyingConfig
   */
} as KeyingConfig;

export default keys;
