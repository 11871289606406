/**
 * @file contains mapStateToProps function for the topicHeader container
 */
import AppState from '../../../../models/state';

import { StateProps } from './props';

/**
 * Map global app state to what the container needs
 *
 * @param state Current global app state
 * @returns     Slice of the global state needed for the container
 */
const mapStateToProps = (state: AppState): StateProps => ({
  networkTopicBasic: state.debug.networkTopicBasic,
});

export default mapStateToProps;
