/**
 * @file Message details mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage, navigatorTopicMain } from './navigators';

const mapperMessage = {
  // Message is archived or unarchived
  [NotificationEvent.MessageArchived]: navigatorMessage,
  [NotificationEvent.MessageArchivedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageArchivedForCreator]: navigatorMessage,
  [NotificationEvent.MessageUnarchived]: navigatorMessage,
  [NotificationEvent.MessageUnarchivedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageUnarchivedForCreator]: navigatorMessage,

  // Message is created
  [NotificationEvent.MessageCreated]: navigatorMessage,
  [NotificationEvent.MessageCreatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageExternalCreated]: navigatorMessage,

  // Message description is updated
  [NotificationEvent.MessageDescriptionUpdated]: navigatorMessage,
  [NotificationEvent.MessageDescriptionUpdatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageDescriptionUpdatedForCreator]: navigatorMessage,

  // Message due date is updated or removed
  [NotificationEvent.MessageDueDateAdded]: navigatorMessage,
  [NotificationEvent.MessageDueDateAddedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageDueDateAddedForCreator]: navigatorMessage,
  [NotificationEvent.MessageDueDateUpdated]: navigatorMessage,
  [NotificationEvent.MessageDueDateUpdatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageDueDateUpdatedForCreator]: navigatorMessage,
  [NotificationEvent.MessageDueDateRemoved]: navigatorMessage,
  [NotificationEvent.MessageDueDateRemovedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageDueDateRemovedForCreator]: navigatorMessage,

  // Message status is updated
  [NotificationEvent.MessageStatusUpdated]: navigatorMessage,
  [NotificationEvent.MessageStatusUpdatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageStatusUpdatedForCreator]: navigatorMessage,

  // Message title is updated
  [NotificationEvent.MessageTitleUpdated]: navigatorMessage,
  [NotificationEvent.MessageTitleUpdatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageTitleUpdatedForCreator]: navigatorMessage,

  // Label is assigned to or unassigned from a message
  [NotificationEvent.MessageLabelAdded]: navigatorMessage,
  [NotificationEvent.MessageLabelAddedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageLabelAddedForCreator]: navigatorMessage,
  [NotificationEvent.MessageLabelRemoved]: navigatorMessage,
  [NotificationEvent.MessageLabelRemovedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageLabelRemovedForCreator]: navigatorMessage,

  // User is assigned to or unassigned from a message
  [NotificationEvent.MessageAssigneeAdded]: navigatorMessage,
  [NotificationEvent.MessageAssigneeAddedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageAssigneeAddedForCreator]: navigatorMessage,
  [NotificationEvent.MessageAssigneeRemoved]: navigatorMessage,
  [NotificationEvent.MessageAssigneeRemovedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageAssigneeRemovedForCreator]: navigatorMessage,

  // User group is assigned to or unassigned from a message
  [NotificationEvent.MessageGroupAdded]: navigatorMessage,
  [NotificationEvent.MessageGroupAddedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageGroupAddedForCreator]: navigatorMessage,
  [NotificationEvent.MessageGroupRemoved]: navigatorMessage,
  [NotificationEvent.MessageGroupRemovedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageGroupRemovedForCreator]: navigatorMessage,

  // A file is added or deleted from a message
  [NotificationEvent.MessageFileAdded]: navigatorMessage,
  [NotificationEvent.MessageFileAddedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageFileAddedForCreator]: navigatorMessage,
  [NotificationEvent.MessageFileDeleted]: navigatorMessage,
  [NotificationEvent.MessageFileDeletedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageFileDeletedForCreator]: navigatorMessage,

  // Message is deleted
  [NotificationEvent.MessageDeleted]: navigatorTopicMain,
  [NotificationEvent.MessageDeletedForAssignees]: navigatorTopicMain,
  [NotificationEvent.MessageDeletedForCreator]: navigatorMessage,
};

export default mapperMessage;
