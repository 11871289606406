import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__STATUS } from '../../constants/routing/hashLinks';

import {
  Message,
  MessageStatus as MessageStatusEnum,
} from '../../generated/graphql';

import translate from '../../i18n/translate';

import MessageSection from './MessageSection';
import MessageStatus from './MessageStatus';

type Props = {
  canUpdateStatus: boolean;
  className?: string;
  messageId: Message['id'];
  status: MessageStatusEnum | undefined | null;
};

/**
 * Section in the message that displays or lets user update status
 *
 * @param props                 Props passed to the component
 * @param props.canUpdateStatus Whether the user can update status or not
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.messageId       ID of the message
 * @param props.status          Current status value
 * @returns                     The component itself
 */
const MessageSectionStatus: FC<Props> = ({
  canUpdateStatus,
  className,
  messageId,
  status,
}) => {
  if (status === undefined || status === null) {
    return null;
  }

  return (
    <MessageSection
      className={className}
      heading={translate('MESSAGE__HEADING__STATUS')}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__STATUS}
    >
      <MessageStatus
        canUpdateStatus={canUpdateStatus}
        messageId={messageId}
        status={status}
      />
    </MessageSection>
  );
};

export default MessageSectionStatus;
