import { Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { HASH_MESSAGE__INFO__LABEL } from '../../../constants/routing/hashLinks';
import translate from '../../../i18n/translate';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';
import {
  getLabelBgColor,
  getLabelBorderColor,
} from '../../../styles/getLabelColors';
import truncateText from '../../../utils/string/truncateText';

import { ChipSquared } from '../../MUI/ChipSquared';

import { getLabelsToShow } from './helpers';
import * as Styled from './styled';

type Props = {
  task: Pick<MessageFeed, 'labels'>;
  url: string;
};

/**
 * The labels component which should display maximum two labels
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the labels of
 * @param props.url  The URL of the message details
 * @returns          The component itself or null
 */
const TaskLabels: FC<Props> = ({ task, url }) => {
  const { labels } = task;

  if (labels.length === 0) {
    return null;
  }

  const labelsToShow = getLabelsToShow(labels);

  return (
    <Styled.Wrapper
      aria-label={translate('MESSAGE__HEADING__LABELS')}
      component={Link}
      to={getUrlWithHash(url, HASH_MESSAGE__INFO__LABEL)}
    >
      {labelsToShow.map(({ color, key, text, tooltip }) => (
        <Tooltip key={key} title={tooltip}>
          {/* For some MUI components, it's necessary to wrap them with a div in order
          to show the tooltip */}
          <div>
            <ChipSquared
              backgroundColor={getLabelBgColor(color, 0.3)}
              borderColor={getLabelBorderColor(color)}
              label={truncateText(16, text)}
            />
          </div>
        </Tooltip>
      ))}
    </Styled.Wrapper>
  );
};

export default TaskLabels;
