import React, { ComponentProps, FC } from 'react';

import HeaderSearch from '../../../containers/header/search';
import useIsMobile from '../../../hooks/useIsMobile';

import FloatingBottomNav from '../../FloatingBottomNav';
import AppLayout from '../AppLayout';

export type Props = {
  children: ComponentProps<typeof AppLayout>['children'];
};

/**
 * Shared layout for search pages
 *
 * @param props          Props passed to the component
 * @param props.children React children
 * @returns              Search page layout
 */
const SearchLayout: FC<Props> = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <AppLayout
      footer={isMobile ? <FloatingBottomNav /> : null}
      header={<HeaderSearch />}
    >
      {children}
    </AppLayout>
  );
};

export default SearchLayout;
