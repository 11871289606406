import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import CommentsHeader from '../../../components/Comments/CommentsHeader';
import ModalPageSheet from '../../../components/Common/ModalPageSheet';
import FloatingBottomNav from '../../../components/FloatingBottomNav';
import SkeletonMessageFull from '../../../components/Loading/MessageFull';
import RightSidebar from '../../../components/RightSidebar';
import AppLayout from '../../../components/layouts/AppLayout';
import CommentsContainer from '../../../containers/comments';
import MessageDetailsHeader from '../../../containers/header/messageDetails';
import MessageFullContainer from '../../../containers/messageFull';

import {
  ChecklistItemCreateDocument,
  ChecklistItemDeleteDocument,
  ChecklistItemReorderDocument,
  ChecklistItemSetStateDocument,
  ChecklistItemSetTextDocument,
} from '../../../generated/graphql';
import useMutationMessagesRead from '../../../hooks/message/useMutationMessagesRead';
import useRedirectOnError from '../../../hooks/useRedirectOnError';
import { getCloseRepliesUrl, getOpenRepliesUrl } from '../../urls/message';

import { getShouldRedirect } from './helpers';
import useMessageDetails from './hooks/useMessageDetails';
import useShouldShowRightSidebar from './hooks/useShouldShowRightSidebar';
import * as Styled from './styled';

/**
 * Message details, showing main message content,
 * but also checklist progress,
 * checklist items
 * and replies
 *
 * @returns React component
 */
const PageMessageDetailsContainer: FC = () => {
  const handleErrorRedirect = useRedirectOnError();
  const shouldShowRightSidebar = useShouldShowRightSidebar();
  const location = useLocation();
  const navigate = useNavigate();

  const mainRef = useRef<HTMLDivElement>(null);

  const [, requestChecklistCreate] = useMutation(ChecklistItemCreateDocument);
  const [, requestChecklistDelete] = useMutation(ChecklistItemDeleteDocument);
  const [, requestChecklistReorder] = useMutation(ChecklistItemReorderDocument);
  const [, requestChecklistSetState] = useMutation(
    ChecklistItemSetStateDocument,
  );
  const [, requestChecklistSetText] = useMutation(ChecklistItemSetTextDocument);
  const requestMessagesRead = useMutationMessagesRead();

  const {
    data,
    error,
    fetching,
    messageId,
    shouldShowReplyTray,
    shouldShowRightSidebarMobile,
    translation,
  } = useMessageDetails();

  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    if (mainRef.current) {
      setParentHeight(mainRef.current.clientHeight);
    }
  }, [data]);

  const shouldRedirect = getShouldRedirect(data, error, fetching, messageId);
  useEffect(() => {
    if (shouldRedirect) {
      handleErrorRedirect(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleErrorRedirect, shouldRedirect]);

  if (!data?.message) {
    // There is a problem and we are already redirecting,
    // or the message is not yet loaded. In both cases, show skeleton.
    /** @todo implement skeleton for mobile view */
    return <SkeletonMessageFull />;
  }

  const message = data.message;

  /**
   * Close comments page sheet
   */
  const handleCommentsClose = () => {
    navigate(getCloseRepliesUrl(location), { replace: true });
  };

  /**
   * Open comments page sheet
   */
  const handleCommentsOpen = () => {
    navigate(getOpenRepliesUrl(location, message.id), { replace: true });
    requestMessagesRead(message.id);
  };

  return (
    <AppLayout
      footer={<FloatingBottomNav />}
      header={<MessageDetailsHeader />}
      mainRef={mainRef}
      rightSidebar={{
        component: shouldShowRightSidebar && (
          <RightSidebar>
            <MessageFullContainer />
          </RightSidebar>
        ),
        shouldShowDesktop: false,
        shouldShowMobile: shouldShowRightSidebarMobile,
      }}
    >
      <Styled.MessageWrapper
        message={message}
        mode="details"
        requestChecklistCreate={requestChecklistCreate}
        requestChecklistDelete={requestChecklistDelete}
        requestChecklistReorder={requestChecklistReorder}
        requestChecklistSetState={requestChecklistSetState}
        requestChecklistSetText={requestChecklistSetText}
        translation={translation}
      />
      {shouldShowReplyTray && (
        <ModalPageSheet
          heading={
            <CommentsHeader
              isSeen={message.isSeen}
              numberOfComments={message.numberOfComments}
            />
          }
          onClose={handleCommentsClose}
          onOpen={handleCommentsOpen}
          parentHeight={parentHeight}
        >
          <Styled.CommentsWrapper>
            <CommentsContainer
              isArchived={message.isArchived}
              messageId={messageId}
              numberOfComments={message.numberOfComments}
              thread={message.thread}
            />
          </Styled.CommentsWrapper>
        </ModalPageSheet>
      )}
    </AppLayout>
  );
};

export default PageMessageDetailsContainer;
