import { DragDropContext } from '@hello-pangea/dnd';
import React from 'react';

import TaskSection from '../../components/TaskSection';
import { TASK_QUERY_ALIASES_MAP } from '../../constants/message';
import { KANBAN__SECTIONS_ORDER } from '../../constants/preferences';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import useTopicAccessible from '../../hooks/topics/useTopicAccessible';

import useMessageInfoSubscription from '../messages/hooks/useMessageInfoSubscription';

import * as Styled from './styled';
import { useTasks } from './useTasks';

/**
 * Kanban board that includes a section for each task status
 *
 * @returns The component itself
 */
const Kanban = () => {
  useMessageInfoSubscription();

  const paramTopicId = useParamTopicId();
  const [, isTopicAccessibleFetching] = useTopicAccessible(paramTopicId);

  const { data, isFetching: isTasksFetching, requestMoveTask } = useTasks();

  const isLoading = isTopicAccessibleFetching || isTasksFetching;

  return (
    <DragDropContext onDragEnd={requestMoveTask}>
      <Styled.Wrapper>
        {KANBAN__SECTIONS_ORDER.map(status => {
          const alias = TASK_QUERY_ALIASES_MAP[status];
          const tasks = data[alias].nodes;
          const totalTasks = data[alias].total;

          return (
            <TaskSection
              isLoading={isLoading}
              key={status}
              status={status}
              tasks={tasks}
              totalTasks={totalTasks}
            />
          );
        })}
      </Styled.Wrapper>
    </DragDropContext>
  );
};

export default Kanban;
