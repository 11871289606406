/**
 * @file Helper functions for SearchResults component
 */

import { PREFERENCE__SEARCH_RESULTS__LIMIT as limit } from '../../constants/pagination';

/**
 * Get which index should we scroll to
 *
 * @param size The number of search results in total
 * @returns    The index (within messages array) to which to scroll to
 */
export const getIndexScroll = (size: number): number => {
  if (size === 0) {
    return 0;
  }

  const nrPages = Math.ceil(size / limit);
  return (nrPages - 1) * limit;
};
