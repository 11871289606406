/**
 * @file contains the scroll subscriptions function
 */

import {
  SUBSCRIPTION__COMMENTS_SUBSCRIBE,
  SUBSCRIPTION__COMMENTS_UNSUBSCRIBE,
  SUBSCRIPTION__MESSAGES_SUBSCRIBE,
  SUBSCRIPTION__MESSAGES_UNSUBSCRIBE,
} from '../../constants/actionTypes';

import {
  SubscribeToComments,
  SubscribeToMessages,
  SubscriptionsAction,
  SubscriptionsState,
  UnsubscribeFromComments,
  UnsubscribeFromMessages,
} from '../../models/subscriptions';

import initialState from '../initialState';

/**
 * Reducer for subscribe state
 */
type Reducer = (
  state: SubscriptionsState,
  action: SubscriptionsAction,
) => SubscriptionsState;

/**
 * Request to set the new subscribe state
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onSubscribeToComments = (
  state: SubscriptionsState,
  action: SubscribeToComments,
): SubscriptionsState => {
  /**
   * Check if item is already in state
   */
  if (state.messageIds.includes(action.payload.messageId) === true) {
    return state;
  }

  return {
    ...state,
    messageIds: state.messageIds.concat(action.payload.messageId),
  };
};

/**
 * Request to set the new subscribe state
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onSubscribeToMessages = (
  state: SubscriptionsState,
  action: SubscribeToMessages,
): SubscriptionsState => {
  /**
   * Check if item is already in state
   */
  if (state.topicIds.includes(action.payload.topicId) === true) {
    return state;
  }

  return {
    ...state,
    topicIds: state.topicIds.concat(action.payload.topicId),
  };
};

/**
 * Request to set the new subscribe state
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onUnsubscribeFromComments = (
  state: SubscriptionsState,
  action: UnsubscribeFromComments,
): SubscriptionsState => {
  const { messageId } = action.payload;

  return {
    ...state,
    messageIds: state.messageIds.filter(id => id !== messageId),
  };
};

/**
 * Request to set the new subscribe state
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onUnsubscribeFromMessages = (
  state: SubscriptionsState,
  action: UnsubscribeFromMessages,
): SubscriptionsState => {
  const { topicId } = action.payload;

  return {
    ...state,
    topicIds: state.topicIds.filter(id => id !== topicId),
  };
};

/**
 * action:reducer mapping for subscribe
 */
const mapping = new Map<string, Reducer>([
  [SUBSCRIPTION__COMMENTS_SUBSCRIBE, onSubscribeToComments],
  [SUBSCRIPTION__COMMENTS_UNSUBSCRIBE, onUnsubscribeFromComments],
  [SUBSCRIPTION__MESSAGES_SUBSCRIBE, onSubscribeToMessages],
  [SUBSCRIPTION__MESSAGES_UNSUBSCRIBE, onUnsubscribeFromMessages],
]);

/**
 * Reducer for all subscriptions actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const subscriptions = (
  state = initialState.subscriptions,
  action: SubscriptionsAction,
): SubscriptionsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default subscriptions;
