/**
 * @file Common props for links
 */

export const EXTERNAL_LINK_PROPS = {
  rel: 'noopener noreferrer',
  target: '_blank',
} as const;

export const ROUTER_LINK_PROPS = {
  // Prevent scrolling to top on route change
  preventScrollReset: true,
} as const;
