/**
 * @file Handle messages sent from native app to the web app
 */

import { reportError } from '../../services/reporting';
import {
  WebViewEvent,
  WebViewEventResponse,
} from '../../utils/webview/payloadTypes';

/**
 * Handle messages sent from native app to the web app
 * (for now only handling notificationPermissionResponse)
 *
 * @param event         Message event
 * @param webViewEvents Callbacks for incoming events
 */
export const reactNativeMessageHandler = (
  event: MessageEvent<string>,
  webViewEvents: WebViewEvent[],
) => {
  /**
   * Since this event listener is generic we need to eliminate events other than react-native ones.
   * This check prevents unnecessary error reports because JSON.parse() would fail.
   */
  if (typeof event.data !== 'string' || event.data === 'undefined') {
    return;
  }

  try {
    /** @todo Fix the types */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = JSON.parse(event.data);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (response.source !== 'react-native') {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const payload: WebViewEventResponse = response.payload;

    webViewEvents.forEach(webViewEvent => {
      if (payload.eventName === webViewEvent.eventName && payload.data) {
        webViewEvent.callback(payload.data);
      }
    });
  } catch (error) {
    reportError(error as Error);
  }
};
