import { styled } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const Button = styled.button`
  color: var(--color-text-selected);
  margin-top: var(--spacing-medium);

  &:hover {
    text-decoration: underline;
  }
`;
