// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /**
   * on iOS, if font size is below 1rem and the user focuses on that element,
   * the operating system will auto zoom screen
   */
  --font-sm: 0.875rem; /* 14px / 11pt - Used for labels and quick filters */
  --font-baseline: 1rem; /* 16px / 12pt - Used for everything else */
  --font-lg: 1.25rem; /* 18px / 14pt - Used for nav bar links */
  --font-xl: 1.5rem; /* 24px / 18pt - Used for modal titles */

  /* Font sizes by usage */
  --font-size-mobile-bottom-nav-label: 0.75rem;
}

@media (min-width: 768px) {
  :root {
    --font-sm: 0.75rem; /* 12px /  9pt - Used for labels and quick filters */
    --font-baseline: 0.875rem; /* 14px / 10.5pt - Used for everything else */
    --font-lg: 1rem; /* 16px / 12pt - Used for nav bar links */
    --font-xl: 1.625rem; /* 26px / 20pt - Used for modal titles */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_font-sizes.css"],"names":[],"mappings":"AAAA;EACE;;;IAGE;EACF,mBAAmB,EAAE,oDAAoD;EACzE,qBAAqB,EAAE,2CAA2C;EAClE,kBAAkB,EAAE,yCAAyC;EAC7D,iBAAiB,EAAE,wCAAwC;;EAE3D,wBAAwB;EACxB,4CAA4C;AAC9C;;AAEA;EACE;IACE,kBAAkB,EAAE,oDAAoD;IACxE,yBAAyB,EAAE,6CAA6C;IACxE,eAAe,EAAE,yCAAyC;IAC1D,mBAAmB,EAAE,wCAAwC;EAC/D;AACF","sourcesContent":[":root {\n  /**\n   * on iOS, if font size is below 1rem and the user focuses on that element,\n   * the operating system will auto zoom screen\n   */\n  --font-sm: 0.875rem; /* 14px / 11pt - Used for labels and quick filters */\n  --font-baseline: 1rem; /* 16px / 12pt - Used for everything else */\n  --font-lg: 1.25rem; /* 18px / 14pt - Used for nav bar links */\n  --font-xl: 1.5rem; /* 24px / 18pt - Used for modal titles */\n\n  /* Font sizes by usage */\n  --font-size-mobile-bottom-nav-label: 0.75rem;\n}\n\n@media (min-width: 768px) {\n  :root {\n    --font-sm: 0.75rem; /* 12px /  9pt - Used for labels and quick filters */\n    --font-baseline: 0.875rem; /* 14px / 10.5pt - Used for everything else */\n    --font-lg: 1rem; /* 16px / 12pt - Used for nav bar links */\n    --font-xl: 1.625rem; /* 26px / 20pt - Used for modal titles */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
