/**
 * @file Contains hook for scrolling selected item into view
 */
import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import useDebouncedCallback from './useDebouncedCallback';

/**
 * Hook for scrolling element into view if not in view
 *
 * @param delay How long to wait before scrolling into view
 * @returns     Callback for setting refs to element
 */
const useScrollIntoView = (
  delay = 0,
): ((node: HTMLLIElement | null) => void) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const [inViewRef, isInView] = useInView({
    /**
     * Threshold indicates how many % of the element should be visible in order to
     * consider it as "inView". Values go from 0 to 1. Default value is 0.
     */
    threshold: 0.7,
  });

  /**
   * Set refs callback that allows us to use Intersection Observer along with
   * normal ref functionality like accessing the `current` property
   *
   * @param node DOM node from ref callback
   */
  const setRefs = useCallback(
    (node: HTMLLIElement | null) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  /**
   * Scroll into view when element is not initially in view
   */
  const scrollIntoView = useDebouncedCallback(() => {
    if (ref.current?.scrollIntoView === undefined) {
      return;
    }
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, delay);

  useEffect(() => {
    const shouldScrollIntoView = isInView === false;

    if (shouldScrollIntoView) {
      scrollIntoView();
    }
    /**
     * We only want to run this initially, when element is not in view
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return setRefs;
};

export default useScrollIntoView;
