import { styled } from 'styled-components';

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const Li = styled.li`
  margin-bottom: var(--spacing-tiny);
  margin-right: var(--spacing-tiny);
`;
