import React, { FC } from 'react';

import FloatingBottomNav from '../../../../components/FloatingBottomNav';

import AppLayout from '../../../../components/layouts/AppLayout';
import DashboardContent from '../../../../containers/DashboardContent';
import HeaderDashboard from '../../../../containers/header/dashboard';
import useIsMobile from '../../../../hooks/useIsMobile';
import translate from '../../../../i18n/translate';
import { getUserCurrent } from '../../../../utils/permissions/storage';
import FeedFooter from '../../Feed/Feed/FeedFooter';

/**
 * Dashboard page with KPIs
 *
 * @returns The component itself
 */
const PageDashboard: FC = () => {
  const user = getUserCurrent();
  const isMobile = useIsMobile();

  if (user === null) {
    /**
     * @todo Create GENERAL__USER and replace 'User' or ask some higher
     * thinking creature what should be the best message in this case
     */
    return <div>{translate('PLACEHOLDER__DATA_UNAVAILABLE', 'User')}</div>;
  }

  const footer = isMobile ? <FloatingBottomNav /> : <FeedFooter />;

  return (
    <AppLayout footer={footer} header={<HeaderDashboard />}>
      <DashboardContent />
    </AppLayout>
  );
};

export default PageDashboard;
