/**
 * @file contains the debug reducer function
 */

import {
  DEBUG__HAS_MESSAGES,
  DEBUG__NETWORK__COMMENTS__SET,
  DEBUG__NETWORK__COMPOSE__SET,
  DEBUG__NETWORK__MESSAGE_DETAILS_SET,
  DEBUG__NETWORK__MESSAGES_SET,
  DEBUG__NETWORK__QUICK_FILTERS__SET,
  DEBUG__NETWORK__TOPIC_BASIC__SET,
  DEBUG__NETWORK__TOPIC_DETAILS__SET,
  DEBUG__NETWORK__TOPICS__SET,
  DEBUG__PANEL__CLOSE,
  DEBUG__PANEL__OPEN,
  DEBUG__PANEL__TOGGLE,
} from '../../constants/actionTypes';
import {
  DebugAction,
  DebugActionHasMessages,
  DebugActionNetworkCommentsSet,
  DebugActionNetworkComposeSet,
  DebugActionNetworkMessageDetailsSet,
  DebugActionNetworkMessagesSet,
  DebugActionNetworkQuickFiltersSet,
  DebugActionNetworkTopicBasicSet,
  DebugActionNetworkTopicDetailsSet,
  DebugActionNetworkTopicsSet,
  DebugState,
} from '../../models/debug';
import initialState from '../initialState';

type Reducer<State, Action> = (state: State, action: Action) => State;

/**
 * The user has requested that the hasMessages key is updated,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugHasMessagesSet = (
  state: DebugState,
  action: DebugActionHasMessages,
): DebugState => ({
  ...state,
  hasMessages: action.payload,
});

/**
 * The user has requested that the networkComments key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkCommentsSet = (
  state: DebugState,
  action: DebugActionNetworkCommentsSet,
): DebugState => ({
  ...state,
  networkComments: action.payload,
});

/**
 * The user has requested that networkCompose key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkComposeSet = (
  state: DebugState,
  action: DebugActionNetworkComposeSet,
): DebugState => ({
  ...state,
  networkCompose: action.payload,
});

/**
 * The user has requested that the networkMessageDetails is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkMessageDetailsSet = (
  state: DebugState,
  action: DebugActionNetworkMessageDetailsSet,
): DebugState => ({
  ...state,
  networkMessageDetails: action.payload,
});

/**
 * The user has requested that the networkMessages key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkMessagesSet = (
  state: DebugState,
  action: DebugActionNetworkMessagesSet,
): DebugState => ({
  ...state,
  networkMessages: action.payload,
});

/**
 * The user has requested that the networkQuickFilters key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkQuickFiltersSet = (
  state: DebugState,
  action: DebugActionNetworkQuickFiltersSet,
): DebugState => ({
  ...state,
  networkQuickFilters: action.payload,
});

/**
 * The user has requested that the networkTopicBasic key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkTopicBasicSet = (
  state: DebugState,
  action: DebugActionNetworkTopicBasicSet,
): DebugState => ({
  ...state,
  networkTopicBasic: action.payload,
});

/**
 * The user has requested that the networkTopicBasic key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkTopicDetailsSet = (
  state: DebugState,
  action: DebugActionNetworkTopicDetailsSet,
): DebugState => ({
  ...state,
  networkTopicDetails: action.payload,
});

/**
 * The user has requested that the networkTopics key is toggled,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onDebugNetworkTopicsSet = (
  state: DebugState,
  action: DebugActionNetworkTopicsSet,
): DebugState => ({
  ...state,
  networkTopics: action.payload,
});

/**
 * The user has requested that the debug panel be closed
 *
 * @param state The current app state
 * @returns     The new app state
 */
const onDebugPanelClose = (state: DebugState): DebugState => ({
  ...state,
  panelState: false,
});

/**
 * The user has requested that the debug panel be open
 *
 * @param state The current app state
 * @returns     The new app state
 */
const onDebugPanelOpen = (state: DebugState): DebugState => ({
  ...state,
  panelState: true,
});

/**
 * The user has requested that the panelState key is toggled,
 * so we reflect that
 *
 * @param state The current app state
 * @returns     The new app state
 */
const onDebugPanelToggle = (state: DebugState): DebugState => ({
  ...state,
  panelState: !state.panelState,
});

/**
 * action:reducer mapping for debug
 */
const mapping = new Map<string, Reducer<DebugState, DebugAction>>([
  [DEBUG__HAS_MESSAGES, onDebugHasMessagesSet],
  [DEBUG__NETWORK__COMMENTS__SET, onDebugNetworkCommentsSet],
  [DEBUG__NETWORK__COMPOSE__SET, onDebugNetworkComposeSet],
  [DEBUG__NETWORK__MESSAGE_DETAILS_SET, onDebugNetworkMessageDetailsSet],
  [DEBUG__NETWORK__MESSAGES_SET, onDebugNetworkMessagesSet],
  [DEBUG__NETWORK__QUICK_FILTERS__SET, onDebugNetworkQuickFiltersSet],
  [DEBUG__NETWORK__TOPIC_BASIC__SET, onDebugNetworkTopicBasicSet],
  [DEBUG__NETWORK__TOPIC_DETAILS__SET, onDebugNetworkTopicDetailsSet],
  [DEBUG__NETWORK__TOPICS__SET, onDebugNetworkTopicsSet],
  [DEBUG__PANEL__CLOSE, onDebugPanelClose],
  [DEBUG__PANEL__OPEN, onDebugPanelOpen],
  [DEBUG__PANEL__TOGGLE, onDebugPanelToggle],
]);

/**
 * Reducer for all debug actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const debug = (state = initialState.debug, action: DebugAction): DebugState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default debug;
