import { Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { HASH_MESSAGE__INFO__ASSIGNEE } from '../../../constants/routing/hashLinks';
import translate from '../../../i18n/translate';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';
import { ChipSquared } from '../../MUI/ChipSquared';

import { getGroupsToShow } from './helpers';
import * as Styled from './styled';

type Props = {
  task: Pick<MessageFeed, 'assignedGroups'>;
  url: string;
};

/**
 * Task groups
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the user groups of
 * @param props.url  Message details URL in the feed
 * @returns          The component itself
 */
const TaskGroups: FC<Props> = ({ task, url }) => {
  const { assignedGroups } = task;

  if (assignedGroups.length === 0) {
    return null;
  }

  const groupsToShow = getGroupsToShow(assignedGroups);

  return (
    <Styled.Wrapper
      aria-label={translate('ARIA__MESSAGE_INFO__GROUPS')}
      component={Link}
      to={getUrlWithHash(url, HASH_MESSAGE__INFO__ASSIGNEE)}
    >
      {groupsToShow.map(({ key, title, tooltip }) => (
        <Tooltip key={key} title={tooltip}>
          {/* For some MUI components, it's necessary to wrap them with a div in order
          to show the tooltip */}
          <div>
            <ChipSquared label={title} />
          </div>
        </Tooltip>
      ))}
    </Styled.Wrapper>
  );
};

export default TaskGroups;
