import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import IconCommon from '../../../../components/Common/Icon';

import devices from '../../../../styles/devices';

export const LinkCreateMobile = styled(Link)`
  --size: 62px;

  align-self: flex-end;
  background-color: var(--color-primary);
  border-radius: 50%;
  bottom: 100%;
  box-shadow: var(--box-shadow-strong);
  color: var(--color-icons-alt);
  display: block;
  height: var(--size);
  right: var(--spacing-medium);
  width: var(--size);
`;

export const IconMobile = styled(IconCommon)`
  inset: var(--spacing-medium);
  position: absolute;
`;

export const LinkCreate = styled(Link)`
  align-items: center;
  border-radius: var(--border-radius-small);
  color: var(--color-primary-lighter);
  display: flex;
  font-weight: 600;
  padding: var(--spacing-small) 0;
  transition: background-color var(--preference-transition-duration-normal);

  @media ${devices.tabletS} {
    padding: var(--spacing-small);
  }

  &:hover {
    background-color: var(--color-hover-light);
  }
`;

export const Icon = styled(IconCommon)`
  margin-right: var(--spacing-small);
`;
