/**
 * @file Hook for determining what to show on Feed page
 */
import { Topic } from '../../../../../generated/graphql';
import useParamTopicId from '../../../../../hooks/router/params/useParamTopicId';

import useCurrentPath from '../../../../../hooks/useCurrentPath';
import useIsMobile from '../../../../../hooks/useIsMobile';
import {
  createFeedMessageDetailsEditPath,
  createFeedMessageDetailsPath,
  createFeedPath,
  createFeedTopicDetailsEditPath,
  createFeedTopicDetailsPath,
} from '../../../../paths/feed';

import useShouldShowComposeMain from './useShouldShowComposeMain';

type UseFeedParamsReturn = {
  isFeed: boolean;
  shouldShowCompose: boolean;
  shouldShowFeed: boolean;
  shouldShowRightSidebarDesktop: boolean;
  shouldShowRightSidebarMobile: boolean;
  topicId: Topic['id'] | null;
};

const showForDesktop: (string | null)[] = [
  createFeedMessageDetailsPath(),
  createFeedMessageDetailsEditPath(),
  createFeedTopicDetailsPath(),
  createFeedTopicDetailsEditPath(),
];

const showForMobile: (string | null)[] = [
  createFeedTopicDetailsPath(),
  createFeedTopicDetailsEditPath(),
];

/**
 * Hook for determining what to show on Feed page
 * Including:
 * - Right sidebar
 * Along with giving info about the current topic ID
 *
 * @returns Info about the feed page
 */
const useFeedParams = (): UseFeedParamsReturn => {
  const currentPath = useCurrentPath();
  const isMobile = useIsMobile();
  const topicId = useParamTopicId();
  const shouldShowCompose = useShouldShowComposeMain();

  const shouldShowRightSidebarDesktop = showForDesktop.includes(currentPath);
  const shouldShowRightSidebarMobile = showForMobile.includes(currentPath);
  const isDetails = currentPath?.includes(createFeedTopicDetailsPath());
  const isFeed = currentPath === createFeedPath();

  const shouldShowFeed =
    (shouldShowCompose === false && isDetails === false) || isMobile === false;

  return {
    isFeed,
    shouldShowCompose,
    shouldShowFeed,
    shouldShowRightSidebarDesktop,
    shouldShowRightSidebarMobile,
    topicId,
  };
};

export default useFeedParams;
