import { styled } from 'styled-components';

import SkeletonLine from '../SkeletonLine';

type NameProps = {
  'data-width': number;
};

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  height: 2rem;
`;

export const Line = styled(SkeletonLine)<NameProps>`
  height: calc(50% + 2 * var(--spacing-tiny));
  margin-right: var(--spacing-tiny);
  width: ${props => props['data-width']}px;
`;
