/**
 * @file Helper functions for topicFull mobile header
 */

import { ReactNode } from 'react';

import { Location } from 'react-router-dom';

import { TopicEditModeParam } from '../../../models/pageParams';
import { getTopicDetailsUrl, getTopicUrl } from '../../../routes/urls/topic';

type BackCallback = (
  hotelId: string | null,
  location: Location,
  topicId: string | null,
) => string;

export const defaultProps = {
  backCallback: getTopicUrl,
  contentRight: null,
  title: 'GENERAL__DETAILS',
};

export const mapping = new Map<
  TopicEditModeParam | null,
  {
    title: string;
    backCallback: BackCallback;
    contentRight: ReactNode;
  }
>([
  [
    'assign',
    {
      backCallback: getTopicDetailsUrl,
      contentRight: null,
      title: 'ASSIGNMENT__EDIT__PLACEHOLDER',
    },
  ],
  [
    'description',
    {
      backCallback: getTopicDetailsUrl,
      contentRight: null,
      title: 'GENERAL__DESCRIPTION',
    },
  ],
  [
    'title',
    {
      backCallback: getTopicDetailsUrl,
      contentRight: null,
      title: 'GENERAL__TITLE',
    },
  ],
]);
