import React, { FC } from 'react';
import { connect } from 'react-redux';

import ComposeMetaDateDue from '../../../components/Compose/Common/ComposeMeta/ComposeMetaDateDue';
import MetaDateDue from '../../../components/MetaDateDue';
import { ComposeModeParam } from '../../../models/pageParams';
import { getDateToUse } from '../../../utils/date/getDate';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Setting and removing due date in compose form
 *
 * @param props                        Props passed to the container
 * @param props.dataDateDueCurrent     Currently selected due date
 * @param props.dataDateDueSaved       Saved due date
 * @param props.dataRemindersCurrent   Currently created reminders
 * @param props.dataRemindersSaved     Saved reminders
 * @param props.requestDateDueRemove   Request that the due date is cleared
 * @param props.requestDateDueSave     Request current -> save data transfer
 * @param props.requestDateDueSet      Request change of current due date
 * @param props.requestRemindersAdd    Request that one new reminder is added
 * @param props.requestRemindersRemove Request that one reminder is removed
 * @param props.requestRemindersSave   Request current -> save data transfer
 * @param props.requestRemindersSet    Request that adds one reminder
 * @returns                            The container itself
 */
const ComposeMetaDueDateContainer: FC<Props> = ({
  dataDateDueCurrent,
  dataDateDueSaved,
  dataRemindersCurrent,
  dataRemindersSaved,
  requestDateDueRemove,
  requestDateDueSave,
  requestDateDueSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSave,
  requestRemindersSet,
}) => {
  const dateMin = new Date();
  const mode: ComposeModeParam = 'date';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  /**
   * The user has clicked "Save"
   * If the date was previously set and is now null, remove the due date
   * If the date was previously set and now has a different value, use that value
   * If the date wasn't previously set and is now null, use now+1 hour
   * If the date wasn't previously set and now has a value, use that value
   */
  const handleRequestSave = () => {
    if (dataDateDueSaved !== null && dataDateDueCurrent === null) {
      requestDateDueRemove();
    } else {
      requestDateDueSet(getDateToUse(dataDateDueCurrent));
    }

    requestDateDueSave();
    requestRemindersSave();
    goBack();
  };

  return (
    <MetaDateDue
      dataDateDueCurrent={dataDateDueCurrent}
      dataDateDueMin={dateMin}
      dataDateDueSaved={dataDateDueSaved}
      dataRemindersCurrent={dataRemindersCurrent}
      dataRemindersSaved={dataRemindersSaved}
      inputCustom={<ComposeMetaDateDue date={dataDateDueSaved?.toString()} />}
      isActive={isActive}
      linkToggleProps={linkToggleProps}
      requestDateDueRemove={requestDateDueRemove}
      requestDateDueSet={requestDateDueSet}
      requestRemindersAdd={requestRemindersAdd}
      requestRemindersRemove={requestRemindersRemove}
      requestRemindersSet={requestRemindersSet}
      requestSave={handleRequestSave}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaDueDateContainer);
