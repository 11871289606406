/**
 * @file After successful message unarchive mutation, updates the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MessagesDocument,
  MessageUnArchiveMutation,
  MessageUnArchiveMutationVariables,
  Node,
} from '../../../../../generated/graphql';
import { addOrUpdateTask } from '../utils/updaters/messages/tasks';
import paginatedQueryCreation from '../utils/updaters/paginatedQueryCreation';
import {
  addNodeToPaginatedQuery,
  filterQueries,
} from '../utils/updaters/paginatedQueryHelpers';

/**
 * After successful unarchive request this function adds the message
 * to graphql cache.
 *
 * @param result                  The result of the mutation
 * @param result.messageUnarchive The message that was unarchived
 * @param _args                   The variables passed along with the mutation
 * @param cache                   Current GraphQL cache
 */
const messageUnarchiveMutation: UpdateResolver<
  MessageUnArchiveMutation,
  MessageUnArchiveMutationVariables
> = ({ messageUnarchive }, _args, cache) => {
  if (messageUnarchive === null) {
    return;
  }
  paginatedQueryCreation({
    cache,
    filterQueries: filterQueries({
      filterById: messageUnarchive.topic.id,
      parentIdName: 'topicId',
      queryName: 'messages',
    }),
    updateQuery: variables =>
      addNodeToPaginatedQuery({
        cache,
        node: messageUnarchive as Node,
        query: MessagesDocument,
        queryName: 'messages',
        variables,
      }),
  });

  addOrUpdateTask(messageUnarchive, cache);
};

export default messageUnarchiveMutation;
