import { styled } from 'styled-components';

import CommonAvatars from '../Avatars';

export const AssigneesWrapper = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 0 var(--spacing-small);
`;

export const AssigneeName = styled.div`
  margin-left: var(--spacing-tiny);
`;

export const Avatars = styled(CommonAvatars)`
  gap: var(--spacing-tiny) var(--spacing-tiny);
  margin-bottom: var(--spacing-tiny);
`;
