/**
 * @file contains react hook for using setInterval
 */

import { useCallback, useEffect, useRef } from 'react';

/**
 * Call callback every x milliseconds
 *
 * @param callback Function to call
 * @param timeout  Delay, in ms
 * @returns        An interval cancel function
 */
const useInterval = (callback: () => void, timeout = 0): (() => void) => {
  const timeoutIdRef = useRef<number>();

  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      window.clearInterval(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    timeoutIdRef.current = window.setInterval(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};

export default useInterval;
