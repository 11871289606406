/**
 * @file Contains utils for operations
 */

import { DefinitionNode, Kind, NameNode } from 'graphql';
import { Operation } from 'urql';

/**
 * Get the name of the operation
 *
 * @param operation Graphql query operation
 * @returns         The operation definition name if found, otherwise null
 */
export const getOperationName = (
  operation: Operation | null | undefined,
): NameNode['value'] | null => {
  if (operation === null || operation === undefined) {
    return null;
  }

  const definition: DefinitionNode | undefined =
    operation.query.definitions.find(
      def => def.kind === Kind.OPERATION_DEFINITION,
    );

  if (definition?.kind === Kind.OPERATION_DEFINITION) {
    return definition.name?.value ?? null;
  }

  return null;
};
