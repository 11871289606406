/**
 * @file Initial state for the reducer,
 * this covers all possible options, in uninitialized state
 */

import { State } from './types';

const initialState: State = {
  geolocation: null,
  notifications: null,
  'persistent-storage': null,
  push: null,
  'screen-wake-lock': null,
  'xr-spatial-tracking': null,
};

export default initialState;
