import React, { FC, ReactNode, useId, useState } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import { CssHeightValues, CssWidthValues } from '../../../styles/cssTypes';
import { Props as ButtonProps } from '../ButtonSingle/types';
import Chevron from '../Chevron';

import ModalButtonClose from './ModalButtonClose';

import ModalButtons from './ModalButtons';

import { Image } from './images';

import * as Styled from './styled';

/**
 * Props the component needs
 */
export type Props = {
  buttons?: ButtonProps[];
  children?: ReactNode | ReactNode[];
  height?: CssHeightValues;
  idHeading: string;
  idSubheading?: string;
  image?: Image | null;
  isOpen: boolean;
  isOverflowVisible?: boolean;
  onClickBack?: () => void;
  requestClose: () => void;
  textHeading: string;
  textSubheading?: string;
  width?: CssWidthValues;
};

export { type ButtonProps };

/**
 * Common modal component,
 * not meant to be used directly,
 * but rather by separate Modals components
 *
 * @param props                   Props passed to the component
 * @param props.buttons           Buttons to render in modal footer
 * @param props.children          Modal content
 * @param props.height            Modal height
 * @param props.idHeading         ID for the heading element
 * @param props.idSubheading      ID for the subheading element
 * @param props.image             The image to use within the modal
 * @param props.isOpen            Whether to open the modal
 * @param props.isOverflowVisible Should content overflow be visible (used for dropdown inside modal)
 * @param props.onClickBack       Callback for back button
 * @param props.requestClose      Request that a modal is closed
 * @param props.textHeading       Modal title
 * @param props.textSubheading    Modal subtitle
 * @param props.width             Modal width
 * @returns                       The component itself
 */
const Modal: FC<Props> = ({
  buttons = [],
  children,
  height,
  idHeading,
  idSubheading,
  image = null,
  isOpen,
  isOverflowVisible = false,
  onClickBack,
  requestClose,
  textHeading,
  textSubheading = '',
  width,
}) => {
  const aria = {
    describedby: idSubheading,
    labelledby: idHeading,
  };
  const [modalSize, setModalSize] = useState<{
    height: CssHeightValues;
    width: CssWidthValues;
  }>({
    height: 'auto',
    width: 'auto',
  });
  const idButtonBack = useId();
  const textButtonBack = translate('GENERAL__BACK');

  const isMobile = useIsMobile();

  /**
   * Gets the current height of the modal when its opening
   * and set it as fixed height
   * Its preventing the modal to change its size, every time the
   * user searches for other members
   *
   * @param node The HTMLDivElement to get the width and height
   */
  const handleModalSize = (node: HTMLDivElement) => {
    if (node === null) {
      return;
    }
    const { height: modalHeight, width: modalWidth } =
      node.getBoundingClientRect();

    setModalSize({ height: `${modalHeight}px`, width: `${modalWidth}px` });
  };

  const hasImage = image !== null;

  return (
    <Styled.StyledReactModal
      aria={aria}
      contentRef={handleModalSize}
      data-height={height ?? modalSize.height}
      data-image={image}
      data-width={width ?? modalSize.width}
      isOpen={isOpen}
      modalClassName={Styled.classModal}
      onRequestClose={requestClose}
      overlayClassName={Styled.classOverlay}
    >
      <Styled.Header>
        <Styled.WrapperHeading data-has-image={hasImage}>
          {onClickBack && (
            <>
              <Styled.Text id={idButtonBack}>{textButtonBack}</Styled.Text>
              <Styled.ButtonBack
                aria-labelledby={idButtonBack}
                onClick={onClickBack}
                type="button"
              >
                <Chevron direction="left" size={20} />
              </Styled.ButtonBack>
            </>
          )}
          <Styled.Heading data-has-image={hasImage} id={idHeading}>
            {textHeading}
          </Styled.Heading>
          <ModalButtonClose onClick={requestClose} />
        </Styled.WrapperHeading>
        <Styled.Subheading id={idSubheading}>
          {textSubheading}
        </Styled.Subheading>
      </Styled.Header>

      <Styled.Content data-overflow-visible={isOverflowVisible}>
        {children}
      </Styled.Content>
      {isMobile && <Styled.Separator />}
      <Styled.Footer>
        <ModalButtons buttons={buttons} />
      </Styled.Footer>
    </Styled.StyledReactModal>
  );
};

export default Modal;
