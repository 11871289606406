import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

import CommonIcon from '../Icon';

type WrapperProps = {
  'data-size': number;
};

export const Wrapper = styled.div<WrapperProps>`
  height: ${props => props['data-size']}px;

  /** 
   * In order to force reDraw on Safari and display items properly 
   * @see http://mir.aculo.us/2009/09/25/force-redraw-dom-technique-for-webkit-based-browsers/
   */
  transform: translateZ(0);
  transition: background-color var(--preference-transition-duration-normal);
  width: ${props => props['data-size']}px;
`;

export const Input = styled.input`
  ${visuallyHidden}
`;

export const Icon = styled(CommonIcon)`
  ${Input}:focus-visible ~ & {
    background-color: var(--color-background-selected);
  }
`;

export const Label = styled.label`
  ${visuallyHidden}
`;
