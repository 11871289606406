import { styled } from 'styled-components';

import Chevron from '../../../../Common/Chevron';

// Wrapper around the entire component
export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  padding: var(--spacing-small) var(--spacing-medium);

  /**
   * A fix for the scrolling issue on right sidebar for checklist overflow
   * pushing the page up
   * Resolved by creating another layer with position relative
   */
  position: relative;
  width: 100%;
`;

export const Heading = styled.h2`
  font-size: var(--font-baseline);
  font-weight: var(--font-weight-heading);
  margin: var(--spacing-tiny) 0;
`;

export const Content = styled.article`
  flex-grow: 1;

  /**
   * Use min-width because flex child with flex-grow: 1; prop
   * will go outside of parents container.
   * That way, if we have long text, we don't have horizontal scrollbar on sidebar
   */
  min-width: 0;
`;

// Chevron icon, visible on small screens and larger with touch
export const ChevronIcon = styled(Chevron)`
  flex-shrink: 0;
`;
