import React, { FC, lazy, Suspense } from 'react';

import RightSidebar from '../../../../../components/RightSidebar';

import useCurrentPath from '../../../../../hooks/useCurrentPath';
import {
  createFeedMessageDetailsEditPath,
  createFeedMessageDetailsPath,
  createFeedTopicDetailsEditPath,
  createFeedTopicDetailsPath,
} from '../../../../paths/feed';
import {
  createKanbanMessageDetailsEditPath,
  createKanbanMessageDetailsPath,
  createKanbanTopicDetailsEditPath,
  createKanbanTopicDetailsPath,
} from '../../../../paths/kanban';

const MessageFullContainer = lazy(
  () => import('../../../../../containers/messageFull'),
);
const TopicFullContainer = lazy(
  () => import('../../../../../containers/topicFull'),
);

/**
 * Component that shows feed right sidebar
 *
 * @returns Feed right sidebar component
 */
const FeedRightSidebar: FC = () => {
  const currentPath = useCurrentPath();

  const showTopicFor: (string | null)[] = [
    createFeedTopicDetailsPath(),
    createFeedTopicDetailsEditPath(),
    createKanbanTopicDetailsPath(),
    createKanbanTopicDetailsEditPath(),
  ];

  const showMessageFor: (string | null)[] = [
    createFeedMessageDetailsPath(),
    createFeedMessageDetailsEditPath(),
    createKanbanMessageDetailsPath(),
    createKanbanMessageDetailsEditPath(),
  ];

  const shouldShowMessage = showMessageFor.includes(currentPath);
  const shouldShowTopic = showTopicFor.includes(currentPath);

  /**
   * Get which component to render within the right sidebar
   *
   * @returns The component to use
   */
  const getComponent = () => {
    if (shouldShowMessage) {
      return <MessageFullContainer />;
    } else if (shouldShowTopic) {
      return <TopicFullContainer />;
    } else {
      return null;
    }
  };

  return (
    <RightSidebar>
      <Suspense fallback={null}>{getComponent()}</Suspense>
    </RightSidebar>
  );
};

export default FeedRightSidebar;
