/**
 * @file contains key-value translations that are used
 * when the user is not yet authenticated
 *
 * Language: French
 */

export default {
  ERROR__404__ACTION: "Passer à la page d'accueil",
  ERROR__404__DESCRIPTION:
    "Malheureusement, cette page n'existe pas. Veuillez réessayer avec une URL valide.",
  ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION:
    "Malheureusement, nous n'avons pas trouvé l'objet. Veuillez contacter votre administrateur ou notre équipe de support.",
  ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION:
    "Malheureusement, nous n'avons trouvé aucun utilisateur pour cet identifiant. Veuillez réessayer avec des données d'accès valides. Si l'erreur se reproduit, veuillez contacter le support.",
  ERROR__DEACTIVATED_HOTEL__DESCRIPTION:
    "Votre utilisateur a été désactivé pour l'objet. Veuillez contacter votre administrateur ou notre équipe de support.",
  ERROR__HOTEL__SWITCH_DESCRIPTION:
    'Souhaitez-vous passer à un autre objet à la place ?',
  ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX: 'Passer à " ',
  ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX: '"',
  ERROR__MISSING_HOTEL__DESCRIPTION:
    "Malheureusement, vous n'avez pas accès à l'objet. Veuillez contacter votre administrateur ou notre équipe de support.",
  ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION:
    "Pour obtenir l'accès, veuillez accepter les conditions générales.",
  ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE: 'Oups, il manque quelque chose !',
  GENERAL__BACK_TO_LOGIN: 'Retour au login',
  GENERAL__OOPS: 'Oups, il y a eu un problème.',
};
