import { css, styled } from 'styled-components';

import { AttachmentMode } from '../../../models/attachment';
import devices from '../../../styles/devices';

type ListWrapperProps = {
  'data-mode': AttachmentMode;
};

/**
 * Get styles for current mode
 *
 * @param props Props passed to the component
 * @returns     Styles for current mode
 */
const getStylesForMode = (props: ListWrapperProps) => {
  const mode = props['data-mode'];

  if (mode === 'details') {
    return css`
      flex-flow: row wrap;
      margin: var(--spacing-tiny) 0;

      @media ${devices.tabletS} {
        flex-flow: column nowrap;
      }
    `;
  }

  if (mode === 'detailsEdit') {
    return css`
      flex-flow: column nowrap;
    `;
  }

  if (mode === 'compose') {
    return css`
      flex-flow: row wrap;
      margin: var(--spacing-tiny) var(--spacing-small);
    `;
  }

  return css`
    flex-flow: row wrap;
    margin: var(--spacing-tiny) 0;
  `;
};

export const ListWrapper = styled.ul<ListWrapperProps>`
  display: flex;
  gap: var(--spacing-small);
  justify-content: flex-start;
  ${getStylesForMode}
`;
