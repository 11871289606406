// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-sidebar {
  height: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .roq-chat-sidebar {
    padding: var(--spacing-small);
  }
}

.roq-chat-window {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  height: 100%;
}

.roq-chat-sidebar__sidebar {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  height: 100%;
}

.roq-chat-panel-content {
  overflow-y: auto;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  scrollbar-color: var(--color-blue-dark) transparent;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,uEAAuE;EACvE,mDAAmD;AACrD","sourcesContent":[".roq-chat-sidebar {\n  height: 100%;\n  position: relative;\n}\n\n@media (max-width: 768px) {\n  .roq-chat-sidebar {\n    padding: var(--spacing-small);\n  }\n}\n\n.roq-chat-window {\n  display: flex;\n  flex-direction: column;\n  gap: var(--spacing-small);\n  height: 100%;\n}\n\n.roq-chat-sidebar__sidebar {\n  display: flex;\n  flex-direction: column;\n  gap: var(--spacing-medium);\n  height: 100%;\n}\n\n.roq-chat-panel-content {\n  overflow-y: auto;\n  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */\n  scrollbar-color: var(--color-blue-dark) transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
