import { styled } from 'styled-components';

import Button from '../../components/Common/Button';

export const ErrorText = styled.div`
  background-color: var(--color-grey-pale);
  padding: var(--spacing-medium);
`;

export const ReloadButton = styled(Button)`
  margin-top: var(--spacing-small);
  text-decoration: underline;
`;
