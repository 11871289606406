import { styled } from 'styled-components';

export const Button = styled.button`
  align-items: center;
  display: flex;
  padding: var(--spacing-small);
  transition: background-color var(--preference-transition-duration-normal);
  width: 100%;
`;

export const Title = styled.h4`
  font-size: var(--font-baseline);
  font-weight: 600;
  padding-left: var(--spacing-medium);
`;
