/**
 * @file contains functions used to create paths for use in Route related to the proxy page
 */

/**
 * Generate a path for the feed
 *
 * @returns The generated path
 */
export const createNotificationsProxyPath = (): string => {
  return '/proxy';
};
