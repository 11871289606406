/**
 * @file contains all breakpoint values we use
 */

import Device from '../models/device';

/**
 * Sorted by screen size, not alphabetically
 *
 * @see https://yesviz.com/devices.php
 */
/* eslint-disable sort-keys */
const breakpoints: Record<Required<Device>, number> = {
  mobileS: 320, // iPhone SE 1st gen
  mobileM: 360, // most common res among regular phones
  mobileL: 425, // iPhone +/max variants, Samsung Note

  tabletS: 768, // iPad portrait
  tabletM: 810, // iPad Air portrait, iPad Pro 11" portrait
  tabletL: 1024, // iPad Pro 12" portrait

  laptopS: 1280, // small laptops (10-13")
  laptopM: 1366, // most common laptop resolution (15")
  laptopL: 1440, // MBP 16" and other 16-17" laptops

  desktopS: 1920, // most common monitor resolution
  desktopM: 2560, // common 27" monitor resolution
  desktopL: 3840, // 4K screens on 100% scale
};

export default breakpoints;
