/**
 * @file contains the draftDateDue reducer function
 */

import {
  DRAFTS__LABEL__ADD,
  DRAFTS__LABEL__REMOVE,
  DRAFTS__LABEL__SAVE,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionLabel,
  DraftActionLabelAdd,
  DraftActionLabelRemove,
  DraftActionLabelSave,
} from '../../models/draftLabel';

import stateDraftsClone from '../../utils/stateDraftsClone';

import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionLabel) => DraftsState;

/**
 *
 * CREATE callbacks
 *
 */

/**
 * A request was made to add a new label to a draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onDraftLabelAddRequest = (
  stateCurrent: DraftsState,
  action: DraftActionLabelAdd,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.labelsCurrent.add(action.labelId);
  }

  return stateNext;
};

/**
 *
 * UPDATE request
 *
 */

/**
 * A request was made to transfer attached labels from current to saved keys
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onDraftLabelSaveRequest = (
  stateCurrent: DraftsState,
  action: DraftActionLabelSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.labelsSaved = new Set(draftNext.labelsCurrent);
  }

  return stateNext;
};

/**
 *
 * DELETE callbacks
 *
 */

/**
 * A request was made to detach a label from a draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onDraftLabelRemoveRequest = (
  stateCurrent: DraftsState,
  action: DraftActionLabelRemove,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.labelsCurrent.delete(action.labelId);
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft labels
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__LABEL__ADD, onDraftLabelAddRequest],
  [DRAFTS__LABEL__REMOVE, onDraftLabelRemoveRequest],
  [DRAFTS__LABEL__SAVE, onDraftLabelSaveRequest],
]);

/**
 * Drafts labels reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsLabels = (
  state = initialState.drafts,
  action: DraftActionLabel,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsLabels;
