/**
 * @file Hook that uses type ahead feature
 * where user can search for items in the dropdown by keyboard
 */

import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import useDebounce from '../useDebounce';

import { FocusHandlers } from './useFocusHandlers';

type UseTypeAheadParams = {
  focusItem: FocusHandlers['toItem'];
  itemRefs: RefObject<HTMLLIElement>[];
};

/**
 * Hook that uses type ahead feature where user
 * can search for items in the dropdown by keyboard
 *
 * @param args           Args passed to the hook
 * @param args.focusItem Focuses the item in the list based on the index passed in
 * @param args.itemRefs  Refs for all the list option items
 * @returns              State setter function that sets current typeAhead value
 */
const useTypeAhead = ({
  focusItem,
  itemRefs,
}: UseTypeAheadParams): Dispatch<SetStateAction<string>> => {
  const [typeAheadValue, setTypeAheadValue] = useState('');

  /**
   * We want to catch when user has stopped typing and reset that value
   */
  const debouncedTypeAhead = useDebounce(typeAheadValue, 500);

  /**
   * Effect that clears type-ahead value after some time
   */
  useEffect(() => {
    setTypeAheadValue('');
  }, [debouncedTypeAhead]);

  /**
   * Effect that handles printable keys search
   */
  useEffect(() => {
    if (typeAheadValue === '') {
      return;
    }

    const index = itemRefs.findIndex(ref => {
      const elem = ref.current;
      if (elem === null) {
        return -1;
      }

      const labelText =
        elem.innerText ?? elem.textContent ?? elem.getAttribute('aria-label');

      return labelText
        .toLowerCase()
        .replace(/\s/g, '')
        .startsWith(typeAheadValue.toLowerCase());
    });

    if (index !== -1) {
      focusItem(index);
    }
    /**
     * We only want to run this effect when isOpen changes
     * other dependencies don't interest us
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeAheadValue]);

  return setTypeAheadValue;
};

export default useTypeAhead;
