import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SettingsHeader from '../../../containers/header/settings';
import AppLayout from '../AppLayout';

import SettingsLayoutFooter from './SettingsLayoutFooter';
import useContentConfig from './hooks/useContentConfig';
import useLayoutConfig from './hooks/useLayoutConfig';
import * as Styled from './styled';

/**
 * The settings page general layout
 *
 * @returns The layout to use
 */
const SettingsLayout: FC = () => {
  const isPlain = useContentConfig();
  const { floatingButton } = useLayoutConfig();

  const hasFloatingButton = floatingButton !== null;

  return (
    <AppLayout
      footer={<SettingsLayoutFooter floatingButton={floatingButton} />}
      header={<SettingsHeader />}
    >
      {isPlain ? (
        <Outlet />
      ) : (
        <Styled.Content data-has-floating-button={hasFloatingButton}>
          <Outlet />
        </Styled.Content>
      )}
    </AppLayout>
  );
};

export default SettingsLayout;
