import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { CssVariable } from '../../../../styles/cssTypes';

import CommonAvatars from '../../Avatars';

type CommentsLinkProps = {
  'data-unread': boolean;
};

/**
 * Get show replies text color
 *
 * @param props Props passed to the link
 * @returns     The variable to use
 */
const getCommentColor = (props: CommentsLinkProps): CssVariable => {
  if (props['data-unread']) {
    return 'var(--color-text-danger)';
  }

  return 'var(--color-link)';
};

// Link for comment replies
export const CommentsLink = styled(Link)<CommentsLinkProps>`
  align-items: center;
  color: ${getCommentColor};
  display: flex;
  flex-shrink: 0;
  font-size: var(--font-baseline);
  font-weight: 600;
  margin-right: var(--spacing-medium);

  &:hover {
    text-decoration: underline;
  }
`;

// Label for comment replies
export const TextWrapper = styled.span`
  padding-left: var(--spacing-tiny);
`;

// Container which includes AuthorIcons, CommentsLink and CommentsToggle
export const ReplyContainer = styled.section`
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

export const Avatars = styled(CommonAvatars)`
  margin-right: var(--spacing-tiny);
`;
