import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

export type Props = {
  children: ReactNode | ReactNode[];
  heading: ReactNode;
  isDisabled?: boolean;
};

const ICON_SIZE = 20;

/**
 * Wraps a section for the message compose
 * (assignees, checklist, dateDue, label)
 *
 * @param props            Props passed to the component
 * @param props.children   Section contents
 * @param props.heading    What to render as the section heading (usually just a heading string)
 * @param props.isDisabled Should the chevron be shown (don't show for inline sections)
 * @returns                The component itself
 */
const ComposeMetaSection: FC<Props> = ({
  children,
  heading,
  isDisabled = false,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Heading>{heading}</Styled.Heading>
        {children}
      </Styled.Content>
      {isDisabled === false && (
        <Styled.ChevronIcon
          color="var(--color-placeholder)"
          direction="right"
          size={ICON_SIZE}
        />
      )}
    </Styled.Wrapper>
  );
};

export default ComposeMetaSection;
