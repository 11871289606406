/**
 * @file contains a react hook that determines whether the replies
 * are open for a message
 */
import { ROUTE__REPLIES } from '../../../constants/routing/routes';
import { Message } from '../../../generated/graphql';

import useSearchParams from './useSearchParams';

/**
 * React hook that tells us if replies are open for a message,
 * based on current url
 *
 * @param messageId ID of the message
 * @returns         Whether replies are open
 */
const useAreRepliesOpen = (messageId: Message['id']): boolean => {
  const query = useSearchParams();

  const areRepliesOpen = query.get(ROUTE__REPLIES) === messageId;

  return areRepliesOpen;
};

export default useAreRepliesOpen;
