import React, { FC } from 'react';
import ReactModal, { Props } from 'react-modal';

type AdapterProps = Props & {
  className?: string;
  modalClassName: string;
};

/**
 * Adapted react-model component, to utilize styled-components
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.modalClassName Class name to apply to the modal
 * @returns                    The component itself
 */
const ReactModalAdapter: FC<AdapterProps> = ({
  className,
  modalClassName,
  ...props
}) => {
  return (
    <ReactModal
      {...props}
      bodyOpenClassName="portalOpen"
      className={modalClassName}
      portalClassName={className}
    />
  );
};

export default ReactModalAdapter;
