import { ListItem, ListItemButton, ListItemIcon, styled } from '@mui/material';

export const MuiListItem = styled(ListItem)({
  padding: 0,
});

export const MuiListItemIcon = styled(ListItemIcon)({
  marginRight: 'var(--spacing-medium)',
  minWidth: 'auto',
});

export const MuiListItemButton = styled(ListItemButton)({
  padding: 'var(--spacing-medium) var(--spacing-small)',
});
