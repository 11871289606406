import { ButtonGroupProps } from '@mui/material';
import React, { FC } from 'react';

import * as Styled from './styled';

/**
 * Segmented button group mimicking MD3 style
 *
 * @param props ButtonGroup props passed to the component
 * @returns     The component itself
 */
const SegmentedButtonGroup: FC<ButtonGroupProps> = props => {
  return <Styled.MuiButtonGroup {...props} />;
};

export default SegmentedButtonGroup;
