import React, { FC, useId, useState } from 'react';

import {
  TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MAX,
  TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MIN,
} from '../../../constants/forms';

import { DateOffsetInput, TimeUnit } from '../../../generated/graphql';

import { Item } from '../../../hooks/useSelect';
import translate from '../../../i18n/translate';
import Input from '../../Common/Input';
import Select from '../../Common/Select';

import { getDefaultItem } from './helpers';

import * as Styled from './styled';

type Props = {
  isRequired: boolean;
  offset: DateOffsetInput | null;
  requestInputChange: ({ amount, unit }: DateOffsetInput) => void;
};

/**
 * CustomOffset panel
 *
 * @param props                    Props passed to the component
 * @param props.isRequired         Whether the input is required
 * @param props.offset             Current custom offset
 * @param props.requestInputChange Request that the custom offset is changed
 * @returns                        The component itself
 */
const CustomOffset: FC<Props> = ({
  isRequired,
  offset,
  requestInputChange,
}) => {
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnit.Minutes);
  const selectOffsetId = useId();

  const items: Item<TimeUnit>[] = [
    {
      label: translate('TIME__MINUTE__PLURAL'),
      value: TimeUnit.Minutes,
    },
    {
      label: translate('TIME__HOUR__PLURAL'),
      value: TimeUnit.Hours,
    },
    {
      label: translate('TIME__DAY__PLURAL'),
      value: TimeUnit.Days,
    },
  ];

  /**
   * Change offset amount
   *
   * @param amount New offset amount
   */
  const handleInputChange = (amount: string) => {
    requestInputChange({
      amount: amount ? parseInt(amount, 10) : 0,
      unit: timeUnit,
    });
  };

  /**
   * Change offset unit
   *
   * @param unit New offset unit
   */
  const handleSelectChange = (unit: TimeUnit) => {
    setTimeUnit(unit);
    if (offset?.amount) {
      requestInputChange({ amount: offset.amount, unit });
    }
  };

  return (
    <Styled.Wrapper data-is-checked={isRequired}>
      <Input
        ariaLabel={translate('MESSAGE__HEADING__DATE_DUE')}
        isRequired={isRequired}
        max={TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MAX.toString()}
        maxLength={4}
        min={TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MIN.toString()}
        onChange={handleInputChange}
        placeholder="5"
        type="number"
        value={offset?.amount ?? ''}
      />
      <Select
        defaultItem={getDefaultItem(items, offset?.unit)}
        items={items}
        name="offset"
        onChange={handleSelectChange}
        placeholder={translate('TIME__MINUTE__PLURAL')}
        placement="up"
        selectId={selectOffsetId}
      />
    </Styled.Wrapper>
  );
};

export default CustomOffset;
