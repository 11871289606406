/**
 * @file Reminder proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage } from './navigators';

const mapperReminder = {
  [NotificationEvent.MessageRemindersNotificationForAssignees]:
    navigatorMessage,
  [NotificationEvent.MessageRemindersNotificationForCreator]: navigatorMessage,
  [NotificationEvent.MessageRemindersNotification]: navigatorMessage,
  [NotificationEvent.MessageRemindersUpdatedForAssignees]: navigatorMessage,
  [NotificationEvent.MessageRemindersUpdatedForCreator]: navigatorMessage,
  [NotificationEvent.MessageRemindersUpdated]: navigatorMessage,
};

export default mapperReminder;
