/**
 * @file contains hook for redirecting to parent topic url
 */
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedError } from 'urql';

import { getTopicUrl } from '../routes/urls/topic';
import { reportError } from '../services/reporting';

import useParamHotelId from './router/params/useParamHotelId';
import useParamTopicId from './router/params/useParamTopicId';

/**
 * Redirect to parent topic url
 *
 * @returns Function for reporting error and redirect
 */
const useRedirectOnError = () => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Report error (if any) and redirect to parent topic url
   *
   * @param error a
   */
  const handleErrorRedirect = (error?: CombinedError) => {
    if (error !== undefined) {
      reportError(error);
    }
    navigate(getTopicUrl(hotelId, location, topicId));
  };

  return handleErrorRedirect;
};

export default useRedirectOnError;
