/**
 * @file contains TipTap useEditor custom hook
 *
 * We need this because default useEditor hook causes flickering on rerender
 */
import { EditorOptions } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { DependencyList, useEffect, useState } from 'react';

/**
 * Force update tiptap editor
 *
 * @returns setValue callback
 */
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

/**
 * TipTap custom editor hook
 *
 * @param options TipTap EditorOptions
 * @param deps    TipTap DependencyList
 * @returns       TipTap editor
 */
export const useEditor = (
  options: Partial<EditorOptions> = {},
  deps: DependencyList = [],
) => {
  const [editor, setEditor] = useState<Editor>(() => new Editor(options));
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let instance: Editor;

    if (editor.isDestroyed) {
      instance = new Editor(options);
      setEditor(instance);
    } else {
      instance = editor;
    }

    instance.on('transaction', () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          forceUpdate();
        });
      });
    });

    return () => {
      instance.destroy();
    };
    // Disable exhaustive-deps so we fully replicate TipTap useEditor hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return editor;
};
