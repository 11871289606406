import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__LABEL } from '../../constants/routing/hashLinks';
import translate from '../../i18n/translate';
import { TypeMessageFull } from '../../models/message';

import MessageLabels from '../Common/MessageLabels';
import SkeletonLabelList from '../Loading/LabelList';

import MessageSection from './MessageSection';
import useMessageSectionUrl from './hooks/useMessageSectionUrl';

type Props = {
  canUpdateLabels: boolean;
  labels: TypeMessageFull['labels'];
};

/**
 * Section in the message that displays labels
 *
 * @param props                 Props passed to the component
 * @param props.canUpdateLabels Whether the user can update labels or not
 * @param props.labels          An array of labels
 * @returns                     The component itself
 */
const MessageSectionLabels: FC<Props> = ({
  canUpdateLabels,
  labels: rawLabels,
}) => {
  const linkTo = useMessageSectionUrl({
    mode: 'label',
  });

  const labels = rawLabels || [];

  return (
    <MessageSection
      heading={translate('MESSAGE__HEADING__LABELS')}
      id={HASH_MESSAGE__INFO__LABEL}
      linkTo={canUpdateLabels ? linkTo : undefined}
    >
      {/* In case labels are fetching again after being deleted, in no other cases will raw labels be null */}
      {/* @todo remove this when backend implements non-nullable labels in a Message */}
      {rawLabels === null ? (
        <SkeletonLabelList nrLabels={2} />
      ) : (
        <MessageLabels labels={labels} />
      )}
    </MessageSection>
  );
};

export default MessageSectionLabels;
