/**
 * @file Getter functions for topic in the urql cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  Topic,
  TopicBasicDocument,
  TopicBasicFragment,
  TopicBasicQuery,
  TopicBasicQueryVariables,
  TopicSettingsDocument,
  TopicSettingsQuery,
  TopicSettingsQueryVariables,
  TopicsListDocument,
  TopicsListQuery,
  TopicsListQueryVariables,
  TopicWithMembersDocument,
  TopicWithMembersFragment,
  TopicWithMembersQuery,
  TopicWithMembersQueryVariables,
} from '../../../../../../generated/graphql';

/**
 * Get a single topic by its ID
 *
 * @param cache GraphQL cache
 * @param id    Topic ID
 * @returns     The requested topic, if found (null otherwise)
 */
export const getTopicById = (
  cache: Cache,
  id: Topic['id'],
): TopicBasicFragment | null => {
  const result = cache.readQuery<TopicBasicQuery, TopicBasicQueryVariables>({
    query: TopicBasicDocument,
    variables: { id },
  });

  if (result === null) {
    return null;
  }

  return result.topic;
};

/**
 * Get a single topic (and its members) by its ID
 *
 * @param cache GraphQL cache
 * @param id    Topic ID
 * @returns     The requested topic, if found (null otherwise)
 */
export const getTopicWithMembersById = (
  cache: Cache,
  id: Topic['id'],
): TopicWithMembersFragment | null => {
  const result = cache.readQuery<
    TopicWithMembersQuery,
    TopicWithMembersQueryVariables
  >({
    query: TopicWithMembersDocument,
    variables: { id },
  });

  if (result === null) {
    return null;
  }

  return result.topic;
};

/**
 * Get list of topics that appear in the left sidebar
 *
 * @param cache GraphQL cache
 * @returns     The topic list (if found)
 */
export const getTopicsList = (
  cache: Cache,
): TopicsListQuery['topics'] | null => {
  const result = cache.readQuery<TopicsListQuery, TopicsListQueryVariables>({
    query: TopicsListDocument,
    variables: {},
  });

  return result?.topics ?? null;
};

/**
 * Get list of topics that appear on the settings page
 *
 * @param cache GraphQL cache
 * @returns     The topic list (if found)
 */
export const getTopicsSettings = (
  cache: Cache,
): TopicSettingsQuery['topicsAll'] | null => {
  const result = cache.readQuery<
    TopicSettingsQuery,
    TopicSettingsQueryVariables
  >({
    query: TopicSettingsDocument,
    variables: {},
  });

  return result?.topicsAll ?? null;
};
