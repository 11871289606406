/**
 * @file helpers for the feed/kanban header component
 */

import { ComponentProps } from 'react';
import { Location } from 'react-router-dom';

import HeaderSegmentedButtons from '../../../components/Header/SegmentedButtons';
import { Hotel, Topic } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { getIsFeedKanbanPage } from '../../../routes/helpers/pages';
import { getTopicKanbanUrl } from '../../../routes/urls/kanban';
import { getTopicFeedUrl } from '../../../routes/urls/topic';

type Buttons = ComponentProps<typeof HeaderSegmentedButtons>['buttons'];

/**
 * Get the feed/kanban segmented buttons props
 *
 * @param hotelId  Id of the current hotel
 * @param location History location object
 * @param topicId  Id of the current topic
 * @returns        Button props that can be passed to the Header component
 */
export const getFeedKanbanSegmentedButtons = (
  hotelId: Hotel['id'] | null,
  location: Location,
  topicId: Topic['id'] | null,
): Buttons => {
  const isKanban = getIsFeedKanbanPage();

  const labelFeed = translate('GENERAL__FEED');
  const labelTasks = translate('GENERAL__TASKS');

  const buttons = [
    {
      children: labelFeed,
      isActive: !isKanban,
      to: getTopicFeedUrl(hotelId, location, topicId),
    },
    {
      children: labelTasks,
      isActive: isKanban,
      to: getTopicKanbanUrl(hotelId, location, topicId),
    },
  ];

  return buttons;
};
