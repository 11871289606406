import React, { FC } from 'react';

import BaseHeaderMobile from '../../../../components/Header/BaseHeaderMobile';
import HotelAvatarContainer from '../../hotelAvatar';

/**
 * Header for the dashboard page on mobile
 *
 * @returns The component itself
 */
const HeaderDashboardMobile: FC = () => {
  return <BaseHeaderMobile title={<HotelAvatarContainer />} />;
};

export default HeaderDashboardMobile;
