/**
 * @file contains utils for filtering data
 */

import { matchSorter, MatchSorterOptions } from 'match-sorter';

export type Options<T> = MatchSorterOptions<T>;

/**
 * Filter the passed array using needle for search
 *
 * @param haystack The array we want to filter
 * @param needle   The criteria to filter against
 * @param options  match-sorter options
 * @returns        Filtered array
 */
const filter = <T>(
  haystack: T[],
  needle: string,
  options?: MatchSorterOptions<T>,
): T[] => {
  if (options) {
    return matchSorter(haystack, needle, options);
  }

  return matchSorter(haystack, needle);
};

export default filter;
