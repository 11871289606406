import React, { FC, useState } from 'react';

import { ToastMessageModel } from '../../../../models/toastMessage';

import ToastAdditionalText from '../ToastAdditionalText';
import ToastDescription from '../ToastDescription';
import ToastIcon from '../ToastIcon';
import ToastTitle from '../ToastTitle';

import { getChevronDirection, getHasAdditionalText } from './helpers';
import * as Styled from './styled';

type Props = Omit<ToastMessageModel, 'id'>;

/**
 * Toast message content
 * WARNING: THIS COMPONENT IS USED WITHIN MetaContainerInline as well
 *
 * @param props                Props passed to the component
 * @param props.additionalText Text for collapsed section of toast message
 * @param props.text           Message text
 * @param props.title          Message title
 * @param props.type           Message type
 * @returns                    Component itself
 */
const ToastContent: FC<Props> = ({ additionalText, text, title, type }) => {
  const [isVisible, setIsVisible] = useState(false);

  const hasAdditionalText = getHasAdditionalText(additionalText);
  const chevronDirection = getChevronDirection(hasAdditionalText, isVisible);

  return (
    <Styled.Content>
      <ToastIcon type={type} />

      <div>
        <ToastTitle chevronDirection={chevronDirection}>{title}</ToastTitle>

        <ToastDescription>{text}</ToastDescription>

        {hasAdditionalText && (
          <ToastAdditionalText
            isVisible={isVisible}
            onClick={() => setIsVisible(prevState => !prevState)}
            type={type}
          >
            {additionalText}
          </ToastAdditionalText>
        )}
      </div>
    </Styled.Content>
  );
};

export default ToastContent;
