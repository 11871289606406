import React from 'react';

import HotelErrorWrapper from '../../../components/Errors/HotelErrorWrapper';

import useLogout from '../../../hooks/useLogout';

import translate from '../../../i18n/translate';

/**
 * Page that shows error when there is a AUTH_HOTEL_NOT_FOUND error
 *
 * @returns The component itself
 */
const AuthHotelNotFoundPage = () => {
  const logout = useLogout();

  return (
    <HotelErrorWrapper
      actionButton={{
        onClick: logout,
        text: translate('GENERAL__BACK_TO_LOGIN'),
      }}
      description={translate('ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION')}
      title={translate('GENERAL__OOPS')}
    />
  );
};

export default AuthHotelNotFoundPage;
