import React, { FC } from 'react';

import { Label } from '../../../generated/graphql';
import { getLabelBorderColor } from '../../../styles/getLabelColors';

import Checkbox from '../../Common/Checkbox';

import ButtonEdit from '../LabelPickerButtonEdit';

import * as Styled from './styled';

export type Props = {
  isSelected: boolean;
  label: Pick<Label, 'color' | 'text'>;
  onClickEdit: (() => void) | null;
  onToggle: ((state: boolean) => void) | null;
};

/**
 * A single label in the list
 * If onClickEdit is not null, the edit button will be shown
 *
 * @param props             Props passed to the component
 * @param props.isSelected  Whether the label is already selected
 * @param props.label       The label to show
 * @param props.label.color The label's color
 * @param props.label.text  The label's text
 * @param props.onClickEdit Callback on edit button click
 * @param props.onToggle    Callback to invoke when a label is toggled
 * @returns                 The component itself
 */
const LabelPickerItem: FC<Props> = ({
  isSelected,
  label: { color, text },
  onClickEdit = null,
  onToggle = null,
}) => {
  return (
    <Styled.Wrapper data-color={color}>
      <Styled.Label>
        {onToggle && (
          <Checkbox
            colorUnchecked={getLabelBorderColor(color)}
            isChecked={isSelected}
            onChange={onToggle}
          />
        )}
        <Styled.Title>{text}</Styled.Title>
      </Styled.Label>

      {onClickEdit !== null && (
        <ButtonEdit
          color="var(--color-primary)"
          onClick={onClickEdit}
          title={text}
        />
      )}
    </Styled.Wrapper>
  );
};

export default LabelPickerItem;
