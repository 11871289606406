/**
 * @file Get proper placeholder text
 */

/**
 * If field is required and placeholder is provided,
 * suffix * will be added
 *
 * @param isRequired  Is field required
 * @param placeholder Placeholder text
 * @returns           Placeholder text with suffix *, empty string or just placeholder
 */
const getPlaceholder = (isRequired: boolean, placeholder = '') => {
  return isRequired && placeholder.length !== 0
    ? `${placeholder}*`
    : placeholder;
};

export default getPlaceholder;
