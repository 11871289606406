import React, { FC } from 'react';

import { Locale, Message } from '../../generated/graphql';
import useMessageTranslationToggle from '../../hooks/store/useMessageTranslationToggle';
import { TranslationContainer } from '../../services/matomo/types';

import * as Styled from './styled';

export type Props = {
  className?: string;
  container: TranslationContainer;
  language: Locale;
  messageId: Message['id'];
};

/**
 * Translate button container
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name
 * @param props.container Container where the button is displayed
 * @param props.language  Language label displayed in the button
 * @param props.messageId ID of the message
 * @returns               The component itself
 */
const ButtonUntranslateMessage: FC<Props> = ({
  className,
  container,
  language,
  messageId,
}) => {
  const { isLoading, requestTranslationToggle } = useMessageTranslationToggle(
    messageId,
    container,
  );

  return (
    <Styled.ButtonUntranslate
      className={className}
      isLoading={isLoading}
      onClick={requestTranslationToggle}
      targetLanguage={language}
    />
  );
};

export default ButtonUntranslateMessage;
