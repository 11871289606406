import React, { FC, useRef } from 'react';

import { Message } from '../../../generated/graphql';

import useAttachmentAction from '../../../hooks/attachments/useAttachmentAction';
import {
  Attachment,
  AttachmentDraft,
  AttachmentMode,
} from '../../../models/attachment';

import ComposeFragment from './ComposeFragment';
import DetailsEditFragment from './DetailsEditFragment';
import FeedFragment from './FeedFragment';

import PreviewFragment from './PreviewFragment';
import { getFileIcon, getIsUploading } from './helpers';

import * as Styled from './styled';

export type Props = {
  attachment: Attachment | AttachmentDraft;
  canDeleteFile: boolean;
  canDownloadFile?: boolean;
  messageId?: Message['id'];
  mode: AttachmentMode;
  requestFileRemove: () => void;
};

const iconHeight = 30;
const iconWidth = 20;

/**
 * Component that renders single attachment for existing messages
 *
 * @param props                   Props passed to the component
 * @param props.attachment        File that is attached
 * @param props.canDeleteFile     Whether user has permission to delete attachment
 * @param props.canDownloadFile   Whether user has permission to download attachment
 * @param props.messageId         Id of the current message (undefined if mode === 'compose')
 * @param props.mode              In which mode to render the component
 * @param props.requestFileRemove Callback for removing file from the drafts array or message
 * @returns                       The component itself
 */
const AttachmentSingleExisting: FC<Props> = ({
  attachment,
  canDeleteFile,
  canDownloadFile = false,
  messageId = '',
  mode,
  requestFileRemove,
}) => {
  const { extension, key, name } = attachment;
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const previewLinkRef = useRef<HTMLAnchorElement>(null);
  const actionDownload = useAttachmentAction(
    name,
    key,
    downloadLinkRef.current,
    messageId,
  );
  const actionPreview = useAttachmentAction(
    name,
    key,
    previewLinkRef.current,
    messageId,
    'preview',
  );

  const actionDownloadWithLinkRef = {
    ...actionDownload,
    linkRef: downloadLinkRef,
  };
  const actionPreviewWithLinkRef = {
    ...actionPreview,
    linkRef: previewLinkRef,
  };

  const iconName = getFileIcon(extension);
  const isUploading = getIsUploading(attachment);

  return (
    <Styled.Wrapper data-mode={mode}>
      <PreviewFragment
        actionPreview={actionPreviewWithLinkRef}
        messageId={messageId}
        mode={mode}
      >
        <Styled.Icon height={iconHeight} name={iconName} width={iconWidth} />
        <Styled.Title>{name}</Styled.Title>
      </PreviewFragment>
      <DetailsEditFragment
        actionDownload={actionDownloadWithLinkRef}
        canDeleteFile={canDeleteFile}
        canDownloadFile={canDownloadFile}
        isUploading={isUploading}
        mode={mode}
        requestButtonClick={requestFileRemove}
      />
      <Styled.ActionWrapper>
        <ComposeFragment
          isUploading={isUploading}
          mode={mode}
          requestButtonClick={requestFileRemove}
        />
      </Styled.ActionWrapper>
      <FeedFragment
        actionDownload={actionDownloadWithLinkRef}
        actionPreview={actionPreviewWithLinkRef}
        canDownloadFile={canDownloadFile}
        mode={mode}
      />
    </Styled.Wrapper>
  );
};

export default AttachmentSingleExisting;
