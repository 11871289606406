import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  className?: string;
  children: ReactNode | ReactNode[];
  id?: string;
};

/**
 * Subheading for a modal, shown below the heading
 *
 * @param props           Props passed to the component
 * @param props.children  The (translated) text to show
 * @param props.className styled-components generated class name, needed for styling
 * @param props.id        Element ID
 * @returns               The component itself
 */
const ModalSubheading: FC<Props> = ({ className, children, id }) => {
  return (
    <Styled.Subheading className={className} id={id}>
      {children}
    </Styled.Subheading>
  );
};

export default ModalSubheading;
