/**
 * @file Cache deletion for paginated queries
 */

import { Cache, FieldArgs } from '@urql/exchange-graphcache';

import { QueryName } from '../../../types';

type Params = {
  cache: Cache;
  queryName: QueryName;
  updateQuery: (variables: FieldArgs) => void;
};

/**
 * Cache deletion for paginated queries
 *
 * @param args             Args passed to the updater
 * @param args.cache       Graphql cache object
 * @param args.queryName   Name of the query we want to update eg. 'messages'
 * @param args.updateQuery Query updater function
 */
const paginatedQueryDeletion = ({
  cache,
  queryName,
  updateQuery,
}: Params): void => {
  /**
   * Inspect all queries and filter for the ones that match
   */
  const fields = cache
    .inspectFields('Query')
    .filter(field => field.fieldName === queryName);

  /**
   * Iterate through each query and remove the item
   */
  fields.forEach(field => {
    updateQuery(field.arguments);
  });
};

export default paginatedQueryDeletion;
