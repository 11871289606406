/**
 * @file Template offset helpers
 */
import { DateOffsetInput, TimeUnit } from '../generated/graphql';
import translate from '../i18n/translate';

type OffsetEntry = {
  label: string;
  value: number | 'custom';
};

const timeMap: Record<TimeUnit, string> = {
  DAYS: 'TIME__DAY',
  HOURS: 'TIME__HOUR',
  MINUTES: 'TIME__MINUTE',
};

/**
 * Helper function which returns array of radio input configurations.
 *
 * @returns Array of radio input configurations
 */
export const getOffsetEntries = (): OffsetEntry[] => {
  const hourPlural = translate('TIME__HOUR__PLURAL');

  return [
    {
      label: translate('COMPOSE__PLACEHOLDER__OFFSET'),
      value: 0,
    },
    {
      label: `+ 1 ${translate('TIME__HOUR__SINGLE')}`,
      value: 1,
    },
    {
      label: '+ 2 ' + hourPlural,
      value: 2,
    },
    {
      label: '+ 3 ' + hourPlural,
      value: 3,
    },
    {
      label: '+ 6 ' + hourPlural,
      value: 6,
    },
    {
      label: '+ 12 ' + hourPlural,
      value: 12,
    },
    {
      label: '+ 24 ' + hourPlural,
      value: 24,
    },
  ];
};

/**
 * Get offset title based on amount and unit
 *
 * @param offset Offset to get the title for
 * @returns      Offset title or null if there is no offset
 */
export const getOffsetTitle = (
  offset: DateOffsetInput | null,
): null | string => {
  if (offset?.amount === undefined || offset.unit === undefined) {
    return null;
  }

  const unit =
    timeMap[offset.unit] + (offset.amount > 1 ? '__PLURAL' : '__SINGLE');

  return `+ ${offset.amount} ${translate(unit)}`;
};
