/**
 * @file contains redux actions related to draftAssignees state
 */

import {
  DRAFTS__ASSIGNEE__GROUP__SET,
  DRAFTS__ASSIGNEE__MEMBER__SET,
  DRAFTS__ASSIGNEE__SAVE,
  DRAFTS__ASSIGNEE__SEARCH__SET,
} from '../../constants/actionTypes';

import {
  DraftActionAssigneeGroupSet,
  DraftActionAssigneeMemberSet,
  DraftActionAssigneeSave,
  DraftActionAssigneeSearchSet,
} from '../../models/draftAssignee';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to (un)assign a group from a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param groupId  The group in question
 * @param state    true: assign; false: unassign
 * @returns        The action itself
 */
export const updateDraftAssigneeGroupRequest = (
  parentId: string,
  groupId: string,
  state: boolean,
): DraftActionAssigneeGroupSet => ({
  groupId,
  parentId,
  state,
  type: DRAFTS__ASSIGNEE__GROUP__SET,
});

/**
 * Action to (un)assign a member from a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param memberId The member in question
 * @param state    true: assign; false: unassign
 * @returns        The action itself
 */
export const updateDraftAssigneeMemberRequest = (
  parentId: string,
  memberId: string,
  state: boolean,
): DraftActionAssigneeMemberSet => ({
  memberId,
  parentId,
  state,
  type: DRAFTS__ASSIGNEE__MEMBER__SET,
});

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftAssigneeRequest = (
  parentId: string,
): DraftActionAssigneeSave => ({
  parentId,
  type: DRAFTS__ASSIGNEE__SAVE,
});

/**
 * Action to store search terms for assignees popup in compose block
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param value    The search term
 * @returns        The action itself
 */
export const searchDraftAssigneesRequest = (
  parentId: string,
  value: string,
): DraftActionAssigneeSearchSet => ({
  parentId,
  type: DRAFTS__ASSIGNEE__SEARCH__SET,
  value,
});
