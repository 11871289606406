/**
 * @file Redux mappers for composeMetaDueDate container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  saveDraftDueDateRequest,
  setDraftDueDateRequest,
} from '../../../store/actions/draftsDateDue';

import {
  addDraftRemindersRequest,
  removeDraftRemindersRequest,
  saveDraftRemindersRequest,
  setDraftRemindersRequest,
} from '../../../store/actions/draftsReminders';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  requestDateDueRemove: () => {
    dispatch(setDraftDueDateRequest(nodeType, null));
  },
  requestDateDueSave: () => {
    dispatch(saveDraftDueDateRequest(nodeType));
  },
  requestDateDueSet: date => {
    dispatch(setDraftDueDateRequest(nodeType, date));
  },
  requestRemindersAdd: reminder => {
    dispatch(addDraftRemindersRequest(nodeType, reminder));
  },
  requestRemindersRemove: position => {
    dispatch(removeDraftRemindersRequest(nodeType, position));
  },
  requestRemindersSave: () => {
    dispatch(saveDraftRemindersRequest(nodeType));
  },
  requestRemindersSet: (position, reminder) => {
    dispatch(setDraftRemindersRequest(nodeType, position, reminder));
  },
});

/**
 * Map Redux state to container props
 *
 * @param appState          Current app state
 * @param appState.drafts   Drafts in Redux store
 * @param ownProps          Props passed directly
 * @param ownProps.nodeType Draft ID
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ drafts }, { nodeType }) => ({
  dataDateDueCurrent: drafts.get(nodeType)?.dateDueCurrent ?? null,
  dataDateDueSaved: drafts.get(nodeType)?.dateDueSaved ?? null,
  dataRemindersCurrent: drafts.get(nodeType)?.remindersCurrent ?? [],
  dataRemindersSaved: drafts.get(nodeType)?.remindersSaved ?? [],
});
