/**
 * @file Add a topic to the TopicList query
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  TopicInfoSubscription,
  TopicSettingsDocument,
  TopicSettingsQuery,
  TopicSettingsQueryVariables,
  TopicsListDocument,
  TopicsListQuery,
  TopicsListQueryVariables,
} from '../../../../../../../generated/graphql';

type Callback = (
  cache: Cache,
  topic: TopicInfoSubscription['topicInfo']['topic'],
) => void;

/**
 * Add a topic to the TopicList query
 *
 * @param cache urql GraphQL cache
 * @param topic The topic to add to the TopicList query
 */
export const topicAddToList: Callback = (cache, topic = null) => {
  if (topic === null) {
    return;
  }

  cache.updateQuery<TopicsListQuery, TopicsListQueryVariables>(
    {
      query: TopicsListDocument,
      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      data.topics.push(topic);
      data.topics.sort((a, b) => a.title.localeCompare(b.title));

      return data;
    },
  );
};

/**
 * Add a topic to the TopicSettings query
 *
 * @param cache urql GraphQL cache
 * @param topic The topic to add to the TopicList query
 */
export const topicAddToSettings: Callback = (cache, topic = null) => {
  if (topic === null) {
    return;
  }

  cache.updateQuery<TopicSettingsQuery, TopicSettingsQueryVariables>(
    {
      query: TopicSettingsDocument,
      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      data.topicsAll.push(topic);
      data.topicsAll.sort((a, b) => a.title.localeCompare(b.title));

      return data;
    },
  );
};
