/**
 * @file contains a react hook that gives current target language for the user
 */
import { TargetLanguage } from '../generated/graphql';
import { getUserCurrent } from '../utils/permissions/storage';

/**
 * React hook that tells us which target language user is using
 *
 * @returns User's target language if any
 */
const useTargetLanguage = (): TargetLanguage | null => {
  const user = getUserCurrent();

  return user?.targetLanguage ?? null;
};

export default useTargetLanguage;
