/**
 * @file Restore scroll position on page load
 */

import { useEffect, useRef } from 'react';

import { getIndexScroll } from '../helpers';

/**
 * Restore scroll position on page load
 * (scroll to first result of the last page)
 *
 * @param nrFetched The number of fetched results
 * @returns         The index of search results onto which to attach the ref and the ref itself
 */
const useScrollRestore = (nrFetched: number) => {
  const hasScrolled = useRef(false);
  const scrollToIndex = getIndexScroll(nrFetched);
  const scrollToRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (hasScrolled.current || scrollToRef.current === null) {
      return;
    }

    // We only want to auto scroll on page load, afterwards, we let the user do it
    hasScrolled.current = true;
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [scrollToIndex]);

  return { scrollToIndex, scrollToRef };
};

export default useScrollRestore;
