import React, { FC } from 'react';

import { UserGroup } from '../../../generated/graphql';

import * as Styled from './styled';

export type Props = {
  group: Pick<UserGroup, 'title'>;
  isAssigned: boolean;
  onChange: (state: boolean) => void;
};

/**
 * A single row in the group list within assignees box, for the compose portion
 *
 * @param props            Props passed to the component
 * @param props.group      Group data
 * @param props.isAssigned Whether the group is already assigned
 * @param props.onChange   When the group is toggled
 * @returns                The component itself
 */
const AssigneesGroupSingle: FC<Props> = ({ group, isAssigned, onChange }) => {
  /**
   * The user has toggled the checkbox,
   * so we call onChange with inverted isAssigned value
   */
  const handleChange = () => {
    onChange(!isAssigned);
  };

  return (
    <label>
      <Styled.Input
        checked={isAssigned}
        onChange={handleChange}
        type="checkbox"
      />
      <Styled.Heading>{group.title}</Styled.Heading>
    </label>
  );
};

export default AssigneesGroupSingle;
