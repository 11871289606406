/**
 * @file contains functions used to create paths for use in Route related to the more page
 */
import {
  ROUTE__MORE,
  ROUTE__MORE__SELECT_HOTEL,
} from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the more page
 *
 * @returns The generated path
 */
export const createMorePath = (): string => {
  return `${createDashboardPath()}/${ROUTE__MORE}`;
};

/**
 * Generate a path for the select hotel page
 *
 * @returns The generated path
 */
export const createMoreSelectHotelPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__MORE}/${ROUTE__MORE__SELECT_HOTEL}`;
};
