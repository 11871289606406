import { styled } from 'styled-components';

// Wrapper for the entire component
export const Wrapper = styled.div`
  min-width: 2rem;
  text-align: center;
`;

// The value shown, between the buttons
export const Value = styled.div`
  padding: var(--spacing-medium) 0;
  text-align: center;
`;
