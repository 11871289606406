import React, { FC } from 'react';

import { MessageCommon } from '../../../../models/message';
import { getIsExternal, getIsRecurring } from '../../../../utils/message';
import Avatar from '../../Avatar';
import AvatarRecurring from '../../AvatarRecurring';
import { NAMES } from '../../Icon';

import * as Styled from './styled';

type Props = {
  message: MessageCommon;
};

// Avatar and concierge icon width and height, in px
export const ICON_SIZE = 40;

/**
 * The icon to show next to the message title
 *
 * @param props         Props passed to the component
 * @param props.message The message to show the icon for
 * @returns             The component itself
 */
const MessageIcon: FC<Props> = ({ message }) => {
  const { creator, creatorType } = message;
  if (getIsExternal(creatorType)) {
    return (
      <Styled.ConciergeIcon
        color="var(--color-primary-light)"
        height={ICON_SIZE}
        name={NAMES.CONCIERGE__MAIN}
        width={ICON_SIZE}
      />
    );
  }

  if (getIsRecurring(creatorType)) {
    return <AvatarRecurring size={ICON_SIZE} />;
  }

  return <Avatar fontSize={0.95} size={ICON_SIZE} user={creator ?? null} />;
};

export default MessageIcon;
