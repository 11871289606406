/**
 * @file Mutation resolver for groupUpdate
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  GroupUpdateMutation,
  GroupUpdateMutationVariables,
  UserGroupBasicFragment,
  UserGroupBasicWithMembersFragment,
  UserGroupsDocument,
  UserGroupsQuery,
  UserGroupsQueryVariables,
  UserGroupsWithMembersDocument,
  UserGroupsWithMembersQuery,
  UserGroupsWithMembersQueryVariables,
} from '../../../../../../../generated/graphql';

import { getGroupWithMembers } from '../../getters/group';
import { getUsersByIds } from '../../getters/user';

/**
 * Merge original with updates and return
 *
 * @param cache      urql cache object
 * @param original   The old data
 * @param group      Updated group data
 * @param group.data Mutation data
 * @returns          Merged group data
 */
const getUpdatedGroup = <
  T extends UserGroupBasicFragment | UserGroupBasicWithMembersFragment,
>(
  cache: Cache,
  original: T,
  { data }: GroupUpdateMutationVariables,
): T => {
  const title = data.title ?? original.title;

  if ('members' in data) {
    return {
      ...original,
      members: getUsersByIds(cache, data?.members ?? []),
      title,
    };
  }

  return {
    ...original,
    title,
  };
};

/**
 * Update
 *
 * @param cache      urql cache object
 * @param updates    The updates that occurred
 * @param alwaysSort Whether to sort the group in cache (regardless of whether updates.title seems the same)
 * @param data       The groups data in the cache
 * @returns          Updated
 */
const getUpdatedGroups = <
  T extends UserGroupsQuery | UserGroupsWithMembersQuery,
>(
  cache: Cache,
  updates: GroupUpdateMutationVariables,
  alwaysSort: boolean,
  data: T | null,
): T | null => {
  if (data === null) {
    return null;
  }

  const groups = data.userGroups;
  const index = groups.findIndex(group => group.id === updates.id);
  if (index === -1) {
    return null;
  }

  const group = groups[index];
  groups[index] = getUpdatedGroup(cache, group, updates);
  if (alwaysSort || group.title !== updates.data.title) {
    groups.sort((groupA, groupB) => groupA.title.localeCompare(groupB.title));
  }

  data.userGroups = groups;
  return data;
};

/**
 * A group was updated, so we update it in the cache,
 * and also re-sort groups if the title changed
 *
 * @param cache      urql cache object
 * @param updates    The updates that occurred
 * @param alwaysSort Whether to sort the group in cache (regardless of whether updates.title seems the same)
 * @returns          The updated group
 */
const groupTitleUpdate = (
  cache: Cache,
  updates: GroupUpdateMutationVariables,
  alwaysSort = false,
): GroupUpdateMutation['userGroupUpdate'] | null => {
  cache.updateQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    { query: UserGroupsDocument },
    data => getUpdatedGroups(cache, updates, alwaysSort, data),
  );

  cache.updateQuery<
    UserGroupsWithMembersQuery,
    UserGroupsWithMembersQueryVariables
  >({ query: UserGroupsWithMembersDocument }, data => {
    return getUpdatedGroups(cache, updates, alwaysSort, data);
  });

  const group = getGroupWithMembers(cache, updates.id);

  if (group === null) {
    return null;
  }

  return {
    ...group,
    title: updates.data.title ?? group.title,
  };
};

export default groupTitleUpdate;
