/**
 * @file Automatically subscribe the user to push notifications
 */

import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'urql';

import { PREFERENCE_DEVICE_TYPE } from '../../constants/preferences';
import {
  PushSubscribeDocument,
  PushUnsubscribeDocument,
} from '../../generated/graphql';
import deviceClass from '../../utils/device/deviceClass';
import { reportApiErrors } from '../../utils/error';

import useFirebaseToken from './useFirebaseToken';
import useOrphanTokens from './useOrphanTokens';
import usePushInfo from './usePushInfo';

/**
 * Automatically subscribe the user to push notifications
 * When conditions for that are met
 */
const usePushSubscribe = (): void => {
  const { canSubscribe, deviceId } = usePushInfo();
  const firebaseToken = useFirebaseToken();
  const isSubscribingRef = useRef(false);
  const orphanTokens = useOrphanTokens(deviceId, firebaseToken);
  const [shouldSubscribe, setShouldSubscribe] = useState(false);
  const [, pushSubscribe] = useMutation(PushSubscribeDocument);
  const [, pushUnsubscribe] = useMutation(PushUnsubscribeDocument);

  // If we can subscribe the user, do so immediately
  useEffect(() => {
    setShouldSubscribe(canSubscribe && isSubscribingRef.current === false);
  }, [canSubscribe]);

  useEffect(() => {
    if (
      canSubscribe &&
      deviceId !== null &&
      firebaseToken !== null &&
      shouldSubscribe
    ) {
      setShouldSubscribe(false);

      if (isSubscribingRef.current) {
        return;
      }

      isSubscribingRef.current = true;

      Promise.all(
        orphanTokens.map(orphan => pushUnsubscribe({ token: orphan })),
      ).catch(reportApiErrors);

      pushSubscribe({
        deviceId,
        deviceName: deviceClass,
        deviceType: PREFERENCE_DEVICE_TYPE,
        token: firebaseToken,
      })
        .catch(reportApiErrors)
        .finally(() => (isSubscribingRef.current = false));
    }
  }, [
    canSubscribe,
    deviceId,
    firebaseToken,
    orphanTokens,
    pushSubscribe,
    pushUnsubscribe,
    shouldSubscribe,
  ]);
};

export default usePushSubscribe;
