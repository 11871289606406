/**
 * @file Helper functions for Compose component
 */

import { ComposeNodeId, ComposeNodeType } from '../../containers/compose/props';
import translate from '../../i18n/translate';

/**
 * Get which title to use for the compose form
 *
 * @param nodeId   The ID of the node (template) being edited (null for creation)
 * @param nodeType Whether the compose form is being used for messages or templates
 * @returns        The title to use
 */
export const getComposeTitle = (
  nodeId: ComposeNodeId,
  nodeType: ComposeNodeType,
): string => {
  if (nodeType === 'MESSAGE') {
    return translate('COMPOSE__TITLE');
  }

  if (nodeId === null) {
    return translate('SETTINGS__TEMPLATES__COMPOSE');
  }

  return translate('SETTINGS__TEMPLATES__COMPOSE__EDIT');
};
