/**
 * @file contains redux actions related to draftDateDue state
 */

import {
  DRAFTS__OFFSET__SAVE,
  DRAFTS__OFFSET__SET,
} from '../../constants/actionTypes';
import { DateOffsetInput } from '../../generated/graphql';

import {
  DraftActionOffsetSave,
  DraftActionOffsetSet,
} from '../../models/draftOffset';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftOffsetRequest = (
  parentId: string,
): DraftActionOffsetSave => ({
  parentId,
  type: DRAFTS__OFFSET__SAVE,
});

/**
 * Action to set the offset for a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param offset   New offset (null for no offset)
 * @returns        The action itself
 */
export const setDraftOffsetRequest = (
  parentId: string,
  offset: DateOffsetInput | null,
): DraftActionOffsetSet => ({
  offset,
  parentId,
  type: DRAFTS__OFFSET__SET,
});
