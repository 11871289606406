/**
 * @file Hook to force component rerender
 */

import { useState } from 'react';

/**
 * Gives us a function with which we can force a component rerender.
 * Used with tiptap editor to avoid flicker.
 *
 * @returns A function that will rerender a component
 */
const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

export default useForceUpdate;
