import * as Toast from '@radix-ui/react-toast';
import { styled } from 'styled-components';

import devices from '../../styles/devices';

export const MessageViewport = styled(Toast.Viewport)`
  display: grid;
  position: absolute;

  /**
   * Height of header in mobile view
   */
  width: 100vw;
  z-index: var(--z-toast);

  /**
   * In order to show all messages in the DOM 
   * and keep them stacked one on top of the other this approach was taken
   */
  & > * {
    grid-area: 1 / -1;
  }

  @media ${devices.tabletS} {
    bottom: 0;
    display: block;
    position: fixed;
    right: var(--spacing-small);
    width: auto;
  }
`;
