import React, { FC } from 'react';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useParamSmartView from '../../hooks/router/params/useParamSmartView';
import useCollapsibleStyles from '../../hooks/useCollapsibleStyles';
import useIsTablet from '../../hooks/useIsTablet';
import { getSmartViewsSmartViewUrl } from '../../routes/urls/smartViews';
import { SmartViewsLink } from '../../utils/smartViews';

import * as Styled from './styled';

export type Props = {
  isActive: boolean;
  smartViewsLinks: SmartViewsLink[];
};

/**
 * Content for the SmartViews component
 *
 * @param props                 Props passed to the component
 * @param props.isActive        Boolean that tells us if the collapsible is active
 * @param props.smartViewsLinks Array of smart view links
 * @returns                     The component itself
 */
const SmartViewsContent: FC<Props> = ({ isActive, smartViewsLinks }) => {
  const hotelId = useParamHotelId();
  const [collapsibleWrapperStyle] = useCollapsibleStyles({
    isActive,
  });
  const isTablet = useIsTablet();

  const currentSmartView = useParamSmartView();

  return (
    <ul style={collapsibleWrapperStyle}>
      {smartViewsLinks.map(({ id, label }) => {
        const isSelected = currentSmartView === id;

        return (
          <li key={label}>
            <Styled.Link
              aria-current={isSelected ? 'page' : 'false'}
              data-is-selected={isSelected}
              to={getSmartViewsSmartViewUrl(hotelId, id, isTablet)}
            >
              {label}
            </Styled.Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SmartViewsContent;
