/**
 * @file contains the draftAssignees reducer function
 */

import {
  DRAFTS__ASSIGNEE__GROUP__SET,
  DRAFTS__ASSIGNEE__MEMBER__SET,
  DRAFTS__ASSIGNEE__SAVE,
  DRAFTS__ASSIGNEE__SEARCH__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionAssignee,
  DraftActionAssigneeGroupSet,
  DraftActionAssigneeMemberSet,
  DraftActionAssigneeSave,
  DraftActionAssigneeSearchSet,
} from '../../models/draftAssignee';

import stateDraftsClone from '../../utils/stateDraftsClone';

import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionAssignee) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to set assignee search term, so we store it
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSearchAssigneeRequest = (
  stateCurrent: DraftsState,
  action: DraftActionAssigneeSearchSet,
) => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, value } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.assigneeSearch = value;
  }

  return stateNext;
};

/**
 * A request was made to store currently checked assignees
 * so we transfer data from assigneeGroupsCurrent and assigneeMembersCurrent
 * to assigneeGroupsSaved and assigneeMembersSaved, respectively
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveAssigneeRequest = (
  stateCurrent: DraftsState,
  action: DraftActionAssigneeSave,
): DraftsState => {
  const { parentId } = action;
  const draftCurrent = stateCurrent.get(parentId);
  const stateNext = stateDraftsClone(stateCurrent);

  if (draftCurrent) {
    stateNext.set(parentId, {
      ...draftCurrent,
      assigneeGroupsSaved: new Set(draftCurrent.assigneeGroupsCurrent),
      assigneeMembersSaved: new Set(draftCurrent.assigneeMembersCurrent),
    });
  }

  return stateNext;
};

/**
 * The user has changed assignment status for a group,
 * so we update the state to reflect that
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onUpdateAssigneeGroupRequest = (
  stateCurrent: DraftsState,
  action: DraftActionAssigneeGroupSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, groupId, state } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    if (state === true) {
      draftCurrent.assigneeGroupsCurrent.add(groupId);
    } else {
      draftCurrent.assigneeGroupsCurrent.delete(groupId);
    }
  }

  return stateNext;
};

/**
 * The user has changed assignment status for a group,
 * so we update the state to reflect that
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onUpdateAssigneeMemberRequest = (
  stateCurrent: DraftsState,
  action: DraftActionAssigneeMemberSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, memberId, state } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    if (state === true) {
      draftCurrent.assigneeMembersCurrent.add(memberId);
    } else {
      draftCurrent.assigneeMembersCurrent.delete(memberId);
    }
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft assignees
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__ASSIGNEE__GROUP__SET, onUpdateAssigneeGroupRequest],
  [DRAFTS__ASSIGNEE__MEMBER__SET, onUpdateAssigneeMemberRequest],
  [DRAFTS__ASSIGNEE__SAVE, onSaveAssigneeRequest],
  [DRAFTS__ASSIGNEE__SEARCH__SET, onSearchAssigneeRequest],
]);

/**
 * Drafts assignees reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftAssignees = (
  state = initialState.drafts,
  action: DraftActionAssignee,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftAssignees;
