import React, { FC, useState } from 'react';
import { format, register } from 'timeago.js';

import de from 'timeago.js/lib/lang/de';
import fr from 'timeago.js/lib/lang/fr';
import it from 'timeago.js/lib/lang/it';
import nl from 'timeago.js/lib/lang/nl';

import { PREFERENCE_TIME_REFRESH } from '../../../constants/preferences';

import useInterval from '../../../hooks/useInterval';
import { dateFormatInput } from '../../../utils/date/format';
import { getLocaleCode } from '../../../utils/user/locale';

export type Props = {
  className?: string;
  date: Date;
};

// Add interface languages to the bundle
// 'en' is always registered
register('de', de);
register('fr', fr);
register('it', it);
register('nl', nl);

/**
 * Show time in a relative format
 * eg. "10 minutes ago", "in 5 hours", "3 weeks ago"
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.date      The date to calculate the relative difference against
 * @returns               The component itself
 */
const TimeRelative: FC<Props> = ({ className, date }) => {
  const locale = getLocaleCode();
  const [text, setText] = useState(format(date, locale));

  useInterval(() => setText(format(date, locale)), PREFERENCE_TIME_REFRESH);

  return (
    <time className={className} dateTime={dateFormatInput(date)} role="timer">
      {text}
    </time>
  );
};

export default TimeRelative;
