/**
 * @file Get the ID of the topic that the user can access
 */

import { Topic, TopicsQuery } from '../../generated/graphql';

/**
 * Get the ID of the topic to use
 * - topicID from the url if the user can access it
 * - ID of the first accessible topic if not
 * - null for data missing or no accessible topics
 *
 * @param data    The data retrieved from the server
 * @param topicId The ID of the topic in the URL
 * @returns       The ID to use
 */
const getTopicAccessible = (
  data: TopicsQuery | undefined,
  topicId: Topic['id'] | null,
): Topic['id'] | null => {
  const topics = data?.topics;

  if (topics === undefined) {
    return topicId;
  }

  const isAccessible = topics.some(({ id }) => id === topicId);
  if (isAccessible) {
    return topicId;
  }

  if (topics.length !== 0) {
    return topics[0].id;
  }

  return null;
};

export default getTopicAccessible;
