/**
 * @file contains utils related to hotel data
 */

import { SEARCH_PARAM__HOTEL_ID } from '../constants/routing/searchParams';
import { Hotel, HotelPermissionsFragment, MeQuery } from '../generated/graphql';

import validateMongoId from './string/validateMongoId';

type HotelPermission = HotelPermissionsFragment['hotelPermissions'][0];

/**
 * Gets the hotelId from the proxy url if there is one
 *
 * @returns Hotel ID or null
 */
const getHotelIdFromProxyUrl = (): string | null => {
  const searchParams = new URLSearchParams(window.location.search);

  const hotelId = searchParams.get(SEARCH_PARAM__HOTEL_ID);
  if (hotelId === null || validateMongoId(hotelId) === false) {
    return null;
  }

  return hotelId;
};

/**
 * Parses the window.location.pathname for a hotelId
 *
 * @returns Hotel ID or null
 */
export const getHotelIdFromUrl = (): string | null => {
  /**
   * Get hotel ID from the proxy link if there is any
   */
  const idFromProxy = getHotelIdFromProxyUrl();

  if (idFromProxy !== null) {
    return idFromProxy;
  }

  const { pathname } = window.location;

  const parts = pathname.split('/');

  if (parts.length < 2) {
    return null;
  }

  /**
   * Hotel ID comes right after domain, hence [1]
   */
  const hotelId = parts[1];
  if (hotelId === '') {
    return null;
  }

  if (validateMongoId(hotelId) === false) {
    return null;
  }

  return hotelId;
};

/**
 * Extracts hotel permission from ME object based on hotelId
 *
 * @param hotelId          ID of the hotel
 * @param hotelPermissions Hotel permissions array
 * @returns                Hotel object
 */
export const getHotelPermissionsById = (
  hotelId: Hotel['id'] | null,
  hotelPermissions: HotelPermission[] = [],
): HotelPermission | null => {
  if (hotelId === null) {
    return null;
  }

  return (
    hotelPermissions.find(hotelPermission => {
      return hotelId === hotelPermission.hotel.id;
    }) ?? null
  );
};

/**
 * Get the ID of the first hotel (or null if there are none)
 *
 * @param permissions List of hotels the user has access to
 * @returns           The default hotel ID
 */
const getFirstHotelId = (
  permissions: MeQuery['me']['hotelPermissions'],
): Hotel['id'] | null => {
  if (permissions.length === 0) {
    return null;
  }

  return permissions[0].hotel.id;
};

/**
 * Checks if current selected hotel is in the hotel permissions
 *
 * @param hotelPermissions Hotel permissions array
 * @param selectedHotel    Hotel ID
 * @returns                User role string
 */
const getCanAccessSelectedHotel = (
  hotelPermissions: HotelPermission[] = [],
  selectedHotel?: string | null,
): boolean => {
  return hotelPermissions.some(
    permissions => permissions.hotel.id === selectedHotel,
  );
};

/**
 * Get which hotel is the default one for the current user
 *
 * @param data MeData retrieved from the server
 * @returns    The default hotel ID
 */
export const getDefaultHotelId = (
  data: MeQuery['me'] | null,
): Hotel['id'] | null => {
  if (data === null) {
    return null;
  }

  const { hotelPermissions, selectedHotel } = data;

  if (selectedHotel === undefined || selectedHotel === null) {
    return getFirstHotelId(hotelPermissions);
  }

  return getCanAccessSelectedHotel(hotelPermissions, selectedHotel)
    ? selectedHotel
    : getFirstHotelId(data.hotelPermissions);
};

/**
 * Gets hotelID from the url,
 * and if not found there, get the user's default hotel
 *
 * Used when initializing permissions, for deep links we have hotel ID in the URL
 * For proxy links, we have it as a query param
 * For when the user previously visited Hotelboard, they have selectedHotel
 * If nothing else, just get the first available hotel
 *
 * @param data The data retrieved
 * @returns    Hotel ID
 */
export const getInitialHotelId = (
  data: MeQuery['me'] | null,
): Hotel['id'] | null => {
  return getHotelIdFromUrl() ?? getDefaultHotelId(data);
};
