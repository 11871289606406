import { Editor } from '@tiptap/react';
import React, { FC } from 'react';

import translate from '../../../../i18n/translate';

import { InputFileProps } from '../../../../models/attachment';
import { getLabelConfirm } from '../../../../utils/forms/compose';

import { configurations } from '../configurations';
import { EditorUsage } from '../types';

import ButtonCancel from './ButtonCancel';
import ButtonSend from './ButtonSend';

import ControlsStyles from './ControlsStyles';

import * as Styled from './styled';

type Props = {
  className?: HTMLUListElement['className'];
  editor: Editor;
  inputFileProps?: InputFileProps;
  isEditMode: boolean;
  onToggleTools: () => void;
  requestEditCancel?: () => void;
  shouldDisableSubmit: HTMLButtonElement['disabled'];
  usage: EditorUsage;
};

/**
 * All the editor controls
 *
 * @param props                     The props passed to the component
 * @param props.className           styled-components generated class name, needed for styling
 * @param props.editor              Current tiptap editor
 * @param props.inputFileProps      Props passed to the <input type="file" />
 * @param props.isEditMode          Whether Editor is in template edit mode
 * @param props.onToggleTools       Callback to be invoked when tools are toggled
 * @param props.requestEditCancel   Callback which clears template draft and exits edit mode
 * @param props.shouldDisableSubmit Whether to disable the submit button (the form is in invalid state)
 * @param props.usage               What the editor is used for
 * @returns                         The component itself
 */
const EditorControls: FC<Props> = ({
  className,
  editor,
  inputFileProps,
  isEditMode,
  onToggleTools,
  requestEditCancel,
  shouldDisableSubmit,
  usage,
}) => {
  const shouldShowSubmit = configurations[usage].buttons.has('buttonSend');
  const sendLabel = getLabelConfirm(
    usage === 'template' ? 'TEMPLATE' : 'MESSAGE',
    isEditMode,
  );

  return (
    <Styled.Ul className={className}>
      <Styled.LiStyles>
        <ControlsStyles
          editor={editor}
          inputFileProps={inputFileProps}
          onToggleStyles={onToggleTools}
          usage={usage}
        />
      </Styled.LiStyles>

      {shouldShowSubmit && (
        <>
          {requestEditCancel && (
            <Styled.ItemButton>
              <ButtonCancel
                handleClick={requestEditCancel}
                label={translate('GENERAL__CANCEL')}
              />
            </Styled.ItemButton>
          )}
          <Styled.ItemButton>
            <ButtonSend isDisabled={shouldDisableSubmit} label={sendLabel} />
          </Styled.ItemButton>
        </>
      )}
    </Styled.Ul>
  );
};

export default EditorControls;
