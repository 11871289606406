/**
 * @file Number util functions
 */

/**
 * Generate a random integer between specified values
 *
 * @param min Minimal value to return
 * @param max Maximal value to return
 * @returns   A random integer between min and max
 */
export const generateRandomInt = (min: number, max: number): number => {
  return Math.round(min + Math.random() * (max - min));
};
