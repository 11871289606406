import { styled } from 'styled-components';

export const Title = styled.h1`
  font-size: var(--font-lg);
  font-weight: var(--font-medium);
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
