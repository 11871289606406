import { styled } from 'styled-components';

import {
  getLabelBgColor,
  getLabelBorderColor,
} from '../../../styles/getLabelColors';

type TitleProps = {
  'data-color': string;
};

/**
 * Get the color to use for the background
 *
 * @param props Props passed to the component
 * @returns     The color to use
 */
const getBackgroundColorRegular = (props: TitleProps) => {
  return getLabelBgColor(props['data-color']);
};

/**
 * Get the color to use for the background when hovering
 *
 * @param props Props passed to the component
 * @returns     The color to use
 */
const getBackgroundColorHover = (props: TitleProps) => {
  return getLabelBgColor(props['data-color'], 0.4);
};

export const Title = styled.p<TitleProps>`
  background-color: ${getBackgroundColorRegular};
  border: 1px solid ${props => getLabelBorderColor(props['data-color'])};
  border-radius: var(--border-radius-small);
  font-size: var(--font-sm);
  overflow: hidden;
  padding: var(--spacing-tiny);
  text-overflow: ellipsis;

  /* Don't break label text into multiple lines */
  white-space: nowrap;

  &:focus-within {
    background-color: ${getBackgroundColorHover};
  }
`;
