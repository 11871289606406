/**
 * @file contains the draftDateDue reducer function
 */

import {
  DRAFTS__DATE_DUE__SAVE,
  DRAFTS__DATE_DUE__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionDueDate,
  DraftActionDueDateSave,
  DraftActionDueDateSet,
} from '../../models/draftDueDate';
import { cloneDate } from '../../utils/date/cloneDate';

import stateDraftsClone from '../../utils/stateDraftsClone';
import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionDueDate) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to save due date,
 * so we store the data from dateDueCurrent to dateDueSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveDueDateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionDueDateSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.dateDueSaved = draftNext.dateDueCurrent
      ? new Date(draftNext.dateDueCurrent.getTime())
      : null;
  }

  return stateNext;
};

/**
 * A request was made to update current due date
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetDueDateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionDueDateSet,
): DraftsState => {
  const { date, parentId } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    const dateDueCurrent = cloneDate(date);
    dateDueCurrent?.setSeconds(0, 0);
    draftNext.dateDueCurrent = dateDueCurrent
      ? new Date(dateDueCurrent.getTime())
      : null;
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft due date
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__DATE_DUE__SAVE, onSaveDueDateRequest],
  [DRAFTS__DATE_DUE__SET, onSetDueDateRequest],
]);

/**
 * Drafts due date reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsDueDate = (
  state = initialState.drafts,
  action: DraftActionDueDate,
): DraftsState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsDueDate;
