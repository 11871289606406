/**
 * @file Set of helper functions for getting different media queries
 */

import breakpoints from '../constants/breakpoints';
import Device from '../models/device';

/**
 * Gets min width media query
 *
 * @param min Min-width in pixels for the media query
 * @returns   Min with media query
 */
const getQueryMinOnly = (min: number): string => {
  return `(min-width: ${min}px)`;
};

/**
 * Gets max width media query
 *
 * @param max Max-width in pixels for the media query
 * @returns   Max with media query
 */
const getQueryMaxOnly = (max: number): string => {
  return `(max-width: ${max - 1}px)`;
};

/**
 * Gets media query both with max and min values
 *
 * @param min Min-width in pixels for the media query
 * @param max Max-width in pixels for the media query
 * @returns   CSS media query definition
 */
const getQueryBoth = (min: number, max: number) => {
  if (min < 0) {
    throw new Error('Minimal value for min argument is 0');
  }

  if (max < min) {
    throw new Error('Minimal value has to be lower than max value');
  }

  return `(min-width: ${min}px) and (max-width: ${max - 1}px)`;
};

/**
 * Generate media query contents, with screen widths as constraints
 * (both min and max can be null, and in that case, there would be limit from one side only)
 *
 * @param min Lower bound, in px
 * @param max Upper bound, in px
 * @returns   CSS media query definition
 */
export const getQueryByResolution = (
  min: number | null,
  max: number | null = null,
): string => {
  if (min === null) {
    return max === null ? 'screen' : getQueryMaxOnly(max);
  } else {
    return max === null ? getQueryMinOnly(min) : getQueryBoth(min, max);
  }
};

/**
 * Generate media query contents, with device classes as constraints
 * (both min and max can be null, and in that case, there would be limit from one side only)
 *
 * @param deviceMin Smallest device that should match the query
 * @param deviceMax Largest device that should match the query
 * @returns         CSS media query definition
 */
export const getQueryByDevice = (
  deviceMin: Device | null,
  deviceMax: Device | null,
): string => {
  const resMin = deviceMin === null ? null : breakpoints[deviceMin];
  const resMax = deviceMax === null ? null : breakpoints[deviceMax];

  return getQueryByResolution(resMin, resMax);
};
