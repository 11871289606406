import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  className?: string;
  message: string;
};

/**
 * "None" or other no result text for the message edit/compose overview
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.message   The message to show (defaults to GENERAL__NONE translation)
 * @returns               The component itself
 */
const MessageNoResult: FC<Props> = ({ className, message }) => (
  <Styled.Title className={className}>{message}</Styled.Title>
);

export default MessageNoResult;
