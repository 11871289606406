import React, { FC } from 'react';

import BadgeNumber from '../../../Common/BadgeNumber';

import * as Styled from './styled';

export type Props = {
  isSelected: boolean;
  label: string;
  shouldReplace?: boolean;
  url: string;
  unreadMessages: number;
};

/**
 * Navigation link for changing topics
 *
 * @param props                Props passed to the component
 * @param props.isSelected     Whether the topic is selected
 * @param props.label          Label of the link
 * @param props.shouldReplace  If link should be replaced or push in history
 * @param props.unreadMessages Whether to show how many unread messages are in the sidebar links
 * @param props.url            A callback that is used to set a new url for filtering or a raw url
 * @returns                    Left sidebar desktop link component with styles
 */
const NavigationLink: FC<Props> = ({
  isSelected,
  label,
  shouldReplace = false,
  url,
  unreadMessages,
}) => {
  return (
    <Styled.Link data-is-selected={isSelected} replace={shouldReplace} to={url}>
      <Styled.LabelWrapper>{label}</Styled.LabelWrapper>
      <BadgeNumber number={unreadMessages} />
    </Styled.Link>
  );
};

export default NavigationLink;
