/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { ROUTE__RESERVATIONS } from '../../constants/routing/routes';
import { SEARCH_PARAM__SIDEBAR } from '../../constants/routing/searchParams';
import { SIDEBAR__PARAM_RESERVATIONS } from '../../constants/sidebar';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

/**
 * Get the url for the /reservations route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getReservationsUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getReservationsUrl was null');
  }

  return `/${hotelId}/${ROUTE__RESERVATIONS}?${SEARCH_PARAM__SIDEBAR}=${SIDEBAR__PARAM_RESERVATIONS}`;
};
