import {
  Badge as BadgeMui,
  styled as styledMui,
  ToggleButton as ToggleButtonMui,
} from '@mui/material';

export const ToggleButton = styledMui(ToggleButtonMui)({
  border: 'none',
  borderRadius: '50%',
});

export const Badge = styledMui(BadgeMui)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.tertiary.main,
  },
}));
