import React, { FC } from 'react';

import { HotelPermissions } from '../../generated/graphql';
import { getHomeUrl } from '../../routes/urls/home';
import HotelInfo from '../HotelInfo';

import * as Styled from './styled';

type Props = {
  className?: string;
  hotelPermissions: HotelPermissions[];
  selectedHotel?: string | null;
};

/**
 * Hotel select component for mobile
 *
 * @param props                  Props passed to the component
 * @param props.className        styled-components generated class name, needed for styling
 * @param props.hotelPermissions Array of all the hotels and their permissions
 * @param props.selectedHotel    Currently selected hotel (default hotel of the user)
 * @returns                      The component itself
 */
const HotelSelectMobile: FC<Props> = ({
  className,
  hotelPermissions,
  selectedHotel,
}) => (
  <Styled.Ul className={className}>
    {hotelPermissions.map(({ hotel }) => {
      const isCurrentHotel = hotel.id === selectedHotel;
      return (
        <Styled.Li data-is-current={isCurrentHotel} key={hotel.id}>
          <a href={isCurrentHotel ? undefined : getHomeUrl(hotel.id)}>
            <HotelInfo imageUrl={hotel.imageUrl} title={hotel.name} />
          </a>
        </Styled.Li>
      );
    })}
  </Styled.Ul>
);

export default HotelSelectMobile;
