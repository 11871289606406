import Tippy from '@tippyjs/react';
import React, { FC, ReactNode } from 'react';

import { COMPATIBILITY__ATTACHMENT_PREVIEW } from '../../../constants/nativeCompatibility';
import { Message } from '../../../generated/graphql';
import useNativeCompatibility from '../../../hooks/webview/useNativeCompatibility';
import translate from '../../../i18n/translate';
import { AttachmentAction, AttachmentMode } from '../../../models/attachment';

import { shouldRenderPreviewFragment } from './helpers';
import * as Styled from './styled';

type Props = {
  actionPreview: AttachmentAction;
  children: ReactNode;
  messageId?: Message['id'];
  mode: AttachmentMode;
};

/**
 * Fragment of AttachmentSingle used for file preview
 * (rendered in detailsEdit mode)
 *
 * @param props               Props passed to the component
 * @param props.actionPreview Attachment action preview props
 * @param props.children      Children passed to the component
 * @param props.mode          Attachment mode
 * @param props.messageId     Id of the current message
 * @returns                   The component itself
 */
const PreviewFragment: FC<Props> = ({
  actionPreview,
  children,
  messageId,
  mode,
}) => {
  /**
   * @todo Remove this after oldest used app version is >= 1.8
   * Check in App Connect and Play Console statistics.
   */
  const isCompatibleAttachmentPreview = useNativeCompatibility(
    COMPATIBILITY__ATTACHMENT_PREVIEW,
  );

  // Only render preview button/link on desktop details/detailsEdit and mobile details
  if (
    shouldRenderPreviewFragment(isCompatibleAttachmentPreview, mode, messageId)
  ) {
    return (
      <>
        <Tippy content={translate('FILE__PREVIEW')} touch={false}>
          <Styled.ButtonPreview
            disabled={actionPreview.isInProgress}
            onClick={actionPreview.requestButtonClick}
            type="button"
          >
            {children}
          </Styled.ButtonPreview>
        </Tippy>
        <Styled.HiddenLink
          aria-hidden="true"
          href={actionPreview.link}
          ref={actionPreview.linkRef}
          target="_blank"
        />
      </>
    );
  }

  return <>{children}</>;
};

export default PreviewFragment;
