/**
 * @file Mutation and Subscription resolvers for urql
 */

import { UpdatesConfig } from '@urql/exchange-graphcache';

import userGroupCreate from './groups/groupCreate';
import userGroupDelete from './groups/groupDelete';
import userGroupUpdate from './groups/groupUpdate';
import labelCreate from './labels/labelCreate';
import labelDelete from './labels/labelDelete';
import labelUpdate from './labels/labelUpdate';
import messageArchive from './messages/messageArchive';
import messageCreate from './messages/messageCreate';
import messageDelete from './messages/messageDelete';
import messageStatusUpdate from './messages/messageStatusUpdate';
import messageUnarchive from './messages/messageUnarchive';
import messagesRead from './messages/messagesRead';
import pushNotificationSubscribe from './push/pushNotificationSubscribe';
import pushNotificationUnsubscribe from './push/pushNotificationUnsubscribe';
import commentInfo from './subscriptions/commentInfo';
import labelInfo from './subscriptions/labelInfo';
import messageInfo from './subscriptions/messageInfo';
import templateInfo from './subscriptions/templateInfo';
import topicInfo from './subscriptions/topicInfo';
import userGroupInfo from './subscriptions/userGroupInfo';
import templateCreate from './templates/templateCreate';
import templateUpdate from './templates/templateUpdate';
import topicCreate from './topics/topicCreate';
import topicDelete from './topics/topicDelete';
import topicUpdate from './topics/topicUpdate';

/**
 * Handles cache updates when a mutation is performed. Usually used to invalidate cache on create/delete operations
 * or do an update for update operations. Handles both mutations and subscriptions.
 */
const updates: UpdatesConfig = {
  Mutation: {
    labelCreate,
    labelDelete,
    labelUpdate,
    messageArchive,
    messageCreate,
    messageDelete,
    messageStatusUpdate,
    messageUnarchive,
    messagesRead,
    pushNotificationSubscribe,
    pushNotificationUnsubscribe,
    templateCreate,
    templateUpdate,
    topicCreate,
    topicDelete,
    topicUpdate,
    userGroupCreate,
    userGroupDelete,
    userGroupUpdate,
  },
  Subscription: {
    commentInfo,
    labelInfo,
    messageInfo,
    templateInfo,
    topicInfo,
    userGroupInfo,
  },
};

export default updates;
