import { styled } from 'styled-components';

import CommonSelect from '../Common/Select';

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

export const Info = styled.p`
  margin-top: var(--spacing-small);
`;

export const Li = styled.li`
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-small);
  width: 100%;
`;

export const Select = styled(CommonSelect)`
  flex-grow: 1;
  margin-right: var(--spacing-small);
`;

export const Legend = styled.legend`
  font-size: var(--font-baseline);
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-medium);
  padding: 0;
`;
