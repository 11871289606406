import React, { FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { DateOffsetInput, Reminder } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { getOffsetTitle } from '../../utils/offset';
import TemplateOffsetForm from '../Forms/TemplateOffsetForm';
import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

import { getIsSaveDisabled } from './helpers';

type Props = {
  className?: string;
  dataOffsetCurrent: DateOffsetInput | null;
  dataOffsetSaved: DateOffsetInput | null;
  dataRemindersCurrent: Reminder[];
  dataRemindersSaved: Reminder[];
  inputCustom?: ReactNode;
  isActive: boolean;
  linkToggleProps?: LinkProps;
  requestOffsetClose?: () => void;
  requestOffsetRemove: () => void;
  requestOffsetSet: (offset: DateOffsetInput) => void;
  requestRemindersAdd: (reminder: Reminder) => void;
  requestRemindersRemove: (position?: number) => void;
  requestRemindersSet: (position: number, reminder: Reminder) => void;
  requestSave: () => void;
};

/**
 * Offset popup for the compose block
 *
 * @param props                        Props passed to the component
 * @param props.className              styled-components generated class name, needed for styling
 * @param props.dataOffsetCurrent      Currently selected offset
 * @param props.dataOffsetSaved        Currently saved offset
 * @param props.dataRemindersCurrent   Current reminders
 * @param props.dataRemindersSaved     Saved reminders
 * @param props.inputCustom            Component to use for overview in mobile view
 * @param props.isActive               Whether the component should be visible
 * @param props.linkToggleProps        Props for the toggle link
 * @param props.requestOffsetClose     Request to close offset popup
 * @param props.requestOffsetRemove    Request that the current offset is removed (set to null)
 * @param props.requestOffsetSet       Request that selected offset is changed
 * @param props.requestRemindersAdd    Request that the new reminder is added
 * @param props.requestRemindersRemove Request that the current reminder is removed (or all of the current reminders are removed)
 * @param props.requestRemindersSet    Request that the reminder is changed
 * @param props.requestSave            Request that the current offset and current reminders are saved
 * @returns                            The component itself
 */
const MetaOffset: FC<Props> = ({
  className,
  dataOffsetCurrent,
  dataOffsetSaved,
  dataRemindersCurrent,
  dataRemindersSaved,
  inputCustom,
  isActive,
  linkToggleProps = null,
  requestOffsetClose,
  requestOffsetRemove,
  requestOffsetSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSet,
  requestSave,
}) => {
  /**
   * We need to check if currently selected offset is equal to draft/saved offset
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled = getIsSaveDisabled(
    dataOffsetCurrent,
    dataOffsetSaved,
    dataRemindersCurrent,
    dataRemindersSaved,
  );

  const idForm = useId();
  const idHeading = useId();

  // Popup top buttons' labels
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textHeading = translate('MESSAGE__HEADING__DATE_DUE');
  const textPlaceholder = translate('DUE_DATE__PLACEHOLDER');

  const offsetTitle = getOffsetTitle(dataOffsetSaved);

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    className,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose: requestOffsetClose,
    textHeading,
  };

  const children = (
    <TemplateOffsetForm
      ariaDescribedBy={idHeading}
      ariaLabelledBy={idHeading}
      dataOffsetCurrent={dataOffsetCurrent}
      dataRemindersCurrent={dataRemindersCurrent}
      idForm={idForm}
      requestOffsetRemove={requestOffsetRemove}
      requestOffsetSet={requestOffsetSet}
      requestRemindersAdd={requestRemindersAdd}
      requestRemindersRemove={requestRemindersRemove}
      requestRemindersSet={requestRemindersSet}
      requestSave={requestSave}
    />
  );

  if (linkToggleProps === null) {
    return isActive ? (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    ) : null;
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={offsetTitle ?? ''}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaOffset;
