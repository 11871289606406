import React, { FC } from 'react';

import {
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../../../../generated/graphql';

import translate from '../../../../../i18n/translate';
import MessageAssignees from '../../../../Common/MessageAssignees';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  groups: UserGroupBasicFragment[];
  users: UserBasicFragment[];
};

/**
 * Section in the message compose that displays the assignees
 *
 * @param props        Props passed to the component
 * @param props.groups User groups list
 * @param props.users  Users/members list
 * @returns            The component itself
 */
const ComposeMetaAssignees: FC<Props> = ({ groups, users }) => {
  return (
    <ComposeMetaSection heading={translate('MESSAGE__HEADING__ASSIGNEES')}>
      <MessageAssignees groups={groups} users={users} />
    </ComposeMetaSection>
  );
};

export default ComposeMetaAssignees;
