import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  ILLUSTRATION__REQUEST,
  ILLUSTRATION__REQUEST_ANSWERED,
  ILLUSTRATION__REQUEST_UNANSWERED,
} from '../../../constants/illustrations';
import { CardType } from '../../../models/dashboard';

import Illustration from '../../Common/Illustration';

import * as Styled from './styled';

export type Props = {
  className?: string;
  children: ReactNode;
  type: CardType;
  url?: string;
};

const ILLUSTRATION_HEIGHT = 91;
const ILLUSTRATION_WIDTH = 111;

const illustrationByType: { [key in CardType]: string } = {
  ['REQUEST']: ILLUSTRATION__REQUEST,
  ['REQUEST_ANSWERED']: ILLUSTRATION__REQUEST_ANSWERED,
  ['REQUEST_UNANSWERED']: ILLUSTRATION__REQUEST_UNANSWERED,
};

/**
 * Dashboard card with KPIs
 *
 * @param props           Props passed to the component
 * @param props.children  Children passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.type      Card type ('REQUEST', 'REQUEST_ANSWERED' or 'REQUEST_UNANSWERED')
 * @param props.url       Url to link to
 * @returns               The component itself
 */
const DashboardCard: FC<Props> = ({ className, children, type, url }) => {
  return (
    <Styled.Card
      as={url ? Link : 'article'}
      className={className}
      to={url ?? undefined}
    >
      {/* Width and height from designs */}
      <Illustration
        height={ILLUSTRATION_HEIGHT}
        name={illustrationByType[type]}
        width={ILLUSTRATION_WIDTH}
      />
      {children}
    </Styled.Card>
  );
};
export default DashboardCard;
