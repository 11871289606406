/**
 * @file Hook that tells us which compose mode is open
 */
import { SEARCH_PARAM__COMPOSE_MODE } from '../../../constants/routing/searchParams';
import { ComposeModeParam } from '../../../models/pageParams';

import useSearchParams from './useSearchParams';

/**
 * Hook that tells us which compose mode is open
 * based on the url
 *
 * @returns Whether/which composeMode is open
 */
const useComposeMode = (): ComposeModeParam | null => {
  const query = useSearchParams();

  const composeMode = query.get(SEARCH_PARAM__COMPOSE_MODE);

  if (composeMode === null) {
    return null;
  }

  return composeMode as ComposeModeParam;
};

export default useComposeMode;
