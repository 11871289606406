import { Chip } from '@mui/material';
import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__DUE_DATE } from '../../../constants/routing/hashLinks';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';
import { dateFormatUi } from '../../../utils/date/format';

import * as Styled from './styled';
import { useDueDateColor } from './useDueDateColor';

type Props = { task: Pick<MessageFeed, 'dueDate' | 'status'>; url: string };

/**
 * Task due date chip component when dueDate and status is available
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the due date of
 * @param props.url  URL of the message details within the feed
 * @returns          The component itself or null
 */
const TaskDueDate: FC<Props> = ({ task, url }) => {
  const { dueDate = null, status = null } = task;
  const color = useDueDateColor(status, dueDate);

  if (dueDate === null || status === null) {
    return null;
  }

  const label = dateFormatUi(new Date(dueDate));

  return (
    <Styled.WrapperLink to={getUrlWithHash(url, HASH_MESSAGE__INFO__DUE_DATE)}>
      <Chip color={color} component="div" label={label} size="medium" />
    </Styled.WrapperLink>
  );
};

export default TaskDueDate;
