import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';

import SettingsNavigationDesktop from './SettingsNavigationDesktop';
import SettingsNavigationMobile from './SettingsNavigationMobile';

/**
 * List of settings items inside in desktop drawer when opened
 *
 * @returns Settings navigation component
 */
const SettingsNavigation: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <SettingsNavigationMobile />;
  }

  return <SettingsNavigationDesktop />;
};

export default SettingsNavigation;
