/**
 * @file contains custom react hook for controlling the feed scroll
 */
import { MutableRefObject, useLayoutEffect, useRef } from 'react';

import { SCROLL__DEFAULT } from '../../../../../constants/preferences';
import { Topic } from '../../../../../generated/graphql';

import useFeedScrollState from '../../../../../hooks/store/useFeedScrollState';
import debounce from '../../../../../utils/debounce';

/**
 * React hook that handles scrolling of the feed
 *
 * @param topicId ID of the currently viewed topic
 * @returns       void
 */
const useScrollFeed = (
  topicId: Topic['id'] | null,
): MutableRefObject<HTMLElement | null> => {
  const scrollRef = useRef<HTMLElement>(null);

  const {
    requestScrollRememberFeed,
    requestScrollTopicsLatestPostRemove,
    scrollValue,
    topicHasLatestPost,
  } = useFeedScrollState(topicId);

  useLayoutEffect(() => {
    /**
     * Remember Scroll effect
     */
    const feedDOMElement = scrollRef.current;

    if (feedDOMElement === null) {
      return;
    }

    /**
     * Handles user scroll event. Debounced to increase performance since
     * scroll events are often called.
     */
    const handleScroll = debounce(() => {
      requestScrollRememberFeed(feedDOMElement.scrollTop);
    }, 160);

    feedDOMElement.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => feedDOMElement.removeEventListener('scroll', handleScroll);
  }, [topicId, requestScrollRememberFeed]);

  useLayoutEffect(() => {
    /**
     * Layout effect that restores the scroll position according to scroll feed state
     */
    const feedDOMElement = scrollRef.current;
    if (feedDOMElement === null) {
      return;
    }

    feedDOMElement.scrollTop = scrollValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);

  useLayoutEffect(() => {
    /**
     * Layout effect for resetting the scroll to default
     * Separate from the restore scroll effect in order,
     * to avoid calling RAF more then we need
     */
    const feedDOMElement = scrollRef.current;
    if (feedDOMElement === null) {
      return;
    }

    // Reset scroll to default in case scroll value changes to 0
    if (scrollValue === SCROLL__DEFAULT) {
      feedDOMElement.scrollTop = scrollValue;

      if (topicHasLatestPost) {
        requestScrollTopicsLatestPostRemove();
      }
    }
  }, [scrollValue, topicHasLatestPost, requestScrollTopicsLatestPostRemove]);

  return scrollRef;
};

export default useScrollFeed;
