/**
 * @file Get the avatar URL for a member (or null if no data)
 */

import { URL_AVATARS, URL_IMAGES } from '../config';

/**
 * Check whether the url passed is absolute or relative
 *
 * @param url The URL to check
 * @returns   Whether it's true
 */
const getIsUrlAbsolute = (url: string): boolean => {
  const pattern = new RegExp('^(?:[a-z]+:)?//', 'i');
  return pattern.test(url);
};

/**
 * Get the avatar URL for a member (or null if no data)
 *
 * @param imageUrl The image url of the member
 * @returns        src for the avatar image
 */
export const memberGetImageUrl = (
  imageUrl: string | null = '',
): string | null => {
  if (imageUrl === undefined || imageUrl === null || imageUrl.length === 0) {
    return null;
  }
  return getIsUrlAbsolute(imageUrl) ? imageUrl : URL_AVATARS + imageUrl;
};

/**
 * Get the avatar URL for a hotel (or null if no data)
 *
 * @param imageUrl The image url of the hotel
 * @returns        src for the hotel image
 */
export const hotelGetImageUrl = (
  imageUrl: string | null | undefined = '',
): string | undefined => {
  if (imageUrl === undefined || imageUrl === null || imageUrl.length === 0) {
    return undefined;
  }
  return getIsUrlAbsolute(imageUrl) ? imageUrl : URL_IMAGES + imageUrl;
};

export default memberGetImageUrl;
