import React, { FC } from 'react';

import { Message } from '../../../generated/graphql';
import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  isSeen: Message['isSeen'];
  numberOfComments: Message['numberOfComments'];
};

/**
 * Used as header for reply tray page sheet
 *
 * @param props                  Props passed to the component
 * @param props.isSeen           Whether the message is read
 * @param props.numberOfComments Number of comments for message
 * @returns                      The component itself
 */
const CommentsHeader: FC<Props> = ({ isSeen, numberOfComments }) => {
  /**
   * Gets text needed to be rendered depending on the
   * number of comments
   *
   * @returns The text to render
   */
  const getTextCommentNumber = () => {
    if (numberOfComments === 0) {
      return translate('MESSAGE__REPLIES_PLACEHOLDER');
    }

    if (numberOfComments === 1) {
      return `1 ${translate('MESSAGE__REPLY')}`;
    }

    return `${numberOfComments} ${translate('MESSAGE__REPLIES')}`;
  };

  return (
    <Styled.Wrapper>
      {getTextCommentNumber()}
      {isSeen === false && (
        <Styled.BadgeIndicator ariaLabel={translate('ARIA__UNREAD_COMMENTS')} />
      )}
    </Styled.Wrapper>
  );
};

export default CommentsHeader;
