/**
 * @file  Hook for attachment file upload
 */

import { Dispatch } from 'react';
import { useMutation } from 'urql';

import { FilesAddToMessageDocument, Message } from '../../generated/graphql';
import { DraftActionAttachment } from '../../models/draftAttachment';

import { reportApiErrors } from '../../utils/error';

import {
  createAttachmentsFromFiles,
  prepareAttachmentsForUpload,
  uploadAttachments,
} from './fileUploadHelpers';

/**
 * Hook for attachment file upload
 *
 * @param dispatch Redux dispatch callback
 * @param parentId The parent of current draft attachments local state
 * @returns        Function that uploads attachments
 */
const useFileUpload = (
  dispatch: Dispatch<DraftActionAttachment>,
  parentId: string,
): {
  uploadFiles: (
    fileSignedUploadPostFields: string[],
    files: File[],
    messageId?: Message['id'],
  ) => void;
} => {
  const [, filesAddToMessageMutation] = useMutation(FilesAddToMessageDocument);

  /**
   * Function for attachment file upload
   *
   * @param fileSignedUploadPostFields Data for POST requests received from S3
   * @param files                      Files to be uploaded
   * @param messageId                  Id of the current message
   */
  const uploadFiles = (
    fileSignedUploadPostFields: string[],
    files: File[],
    messageId?: Message['id'],
  ) => {
    const attachments = createAttachmentsFromFiles(
      files,
      fileSignedUploadPostFields,
    );

    const filesUpload: Promise<void | Response | undefined>[] =
      prepareAttachmentsForUpload(attachments, dispatch, parentId);

    uploadAttachments(
      attachments,
      dispatch,
      filesUpload,
      filesAddToMessageMutation,
      messageId,
      parentId,
    ).catch(reportApiErrors);
  };

  return { uploadFiles };
};

export default useFileUpload;
