import React, { FC } from 'react';

import { LabelBasicFragment } from '../../../../../generated/graphql';
import translate from '../../../../../i18n/translate';
import MessageLabels from '../../../../Common/MessageLabels';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  labels: LabelBasicFragment[];
};

/**
 * Section in the message that displays labels
 *
 * @param props        Props passed to the component
 * @param props.labels An array of labels
 * @returns            The component itself
 */
const ComposeMetaLabels: FC<Props> = ({ labels }) => {
  return (
    <ComposeMetaSection heading={translate('MESSAGE__HEADING__LABELS')}>
      <MessageLabels labels={labels} />
    </ComposeMetaSection>
  );
};

export default ComposeMetaLabels;
