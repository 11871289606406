/**
 * @file Helpers for tasks within the kanban
 */

import { PAGINATION__MAX_ITEMS_PER_PAGE } from '../../constants/forms';
import {
  Message,
  MessageStatus,
  TasksDocument,
  Topic,
} from '../../generated/graphql';
import { MessageFeed } from '../../models/message';

/**
 * Helps us to identify if the message is a task. A message is a task if it
 * has a due date set and status.
 *
 * @param message The message we want to verify
 * @returns       Whether the message is a task
 */
export const getIsTask = <T extends Pick<Message, 'dueDate' | 'status'>>(
  message: T,
): message is T & { dueDate: string; status: MessageStatus } => {
  const { status = null, dueDate = null } = message;
  return status !== null && dueDate !== null;
};

/**
 * Get the variables for tasks query
 *
 * @param topicId Topic the tasks belong to
 * @param status  Task status
 * @param members Member ids we need to filter tasks by
 * @returns       Tasks query options object
 */
export const getTasksQueryOptions = (
  topicId: Topic['id'] | null,
  status: MessageStatus,
  members: string[] | null | undefined,
) => ({
  pause: topicId === null,
  query: TasksDocument,
  variables: {
    filter: {
      members: members ?? [],
      status,
      topicId: topicId ?? '',
    },
    pagination: { before: '', last: PAGINATION__MAX_ITEMS_PER_PAGE },
  },
});

/**
 * Inserts a task into the tasks array while maintaining order based on due dates.
 *
 * @param task  The task to insert
 * @param tasks The array of tasks
 * @returns     A new array of tasks with the task inserted
 */
export const insertTaskByDueDate = (
  task: MessageFeed,
  tasks: MessageFeed[],
) => {
  const isTask = getIsTask(task);

  if (isTask === false) {
    return tasks;
  }

  // ?? Condition will never be invoked, as getIsTask will return false
  // when dueDate is null or undefined, but TypeScript complains otherwise
  const dueTimestamp = new Date(task.dueDate ?? 0).getTime();

  const insertIndex = tasks.findIndex(
    taskItem => dueTimestamp < new Date(taskItem.dueDate ?? 0).getTime(),
  );

  if (insertIndex === -1) {
    return [...tasks, task];
  }

  const finalTasks = [...tasks];
  finalTasks.splice(insertIndex, 0, task);

  return finalTasks;
};
