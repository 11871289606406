/**
 * @file Based on client height, determines whether the list should be opened upwards or downwards
 */

import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

type Args = {
  comboBoxRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  itemCount: number;
  placement: 'up' | 'down' | 'auto';
};

type UseListPlacementReturn = {
  labelRef: RefObject<HTMLLabelElement>;
  labelHeight: number;
  listRef: RefObject<HTMLUListElement>;
  isUpwards: boolean;
};

/**
 * Based on client height, determines whether the list should be opened upwards or downwards
 *
 * @param args             Arguments passed to the hook
 * @param args.comboBoxRef Reference to comboBox element in <Select/>
 * @param args.isOpen      Whether the menu is open or not. Used to force a recalculation after menu is opened.
 * @param args.itemCount   Number of items in dropdown
 * @param args.placement   Placement of the dropdown, up, down or auto
 * @returns                A tuple containing a list ref and a placement boolean
 */
const useListPlacement = ({
  comboBoxRef,
  isOpen,
  itemCount,
  placement,
}: Args): UseListPlacementReturn => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  // Use ref in order to keep the value over various re-renders
  const upwardsRef = useRef<boolean>(placement === 'up');

  const [labelHeight, setLabelHeight] = useState(0);

  useEffect(() => {
    const labelBoundingClientRect = labelRef.current?.getBoundingClientRect();

    if (labelBoundingClientRect !== undefined) {
      const { height: initialLabelHeight } = labelBoundingClientRect;
      setLabelHeight(initialLabelHeight);
    }
  }, []);

  useLayoutEffect(() => {
    if (placement === 'auto') {
      const viewHeight = window.innerHeight;

      const listBoundingClientRect = listRef.current?.getBoundingClientRect();
      const comboBoxBoundingClientRect =
        comboBoxRef.current?.getBoundingClientRect();

      if (
        listBoundingClientRect === undefined ||
        comboBoxBoundingClientRect === undefined
      ) {
        return;
      }
      const { top: comboBoxTop } = comboBoxBoundingClientRect;
      const { height: menuHeight } = listBoundingClientRect;

      const viewSpaceBelow = viewHeight - comboBoxTop;

      upwardsRef.current = viewSpaceBelow < menuHeight;
    }
  }, [comboBoxRef, isOpen, itemCount, placement]);

  return { isUpwards: upwardsRef.current, labelHeight, labelRef, listRef };
};

export default useListPlacement;
