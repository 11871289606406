/**
 * @file All permissions checks related to user groups
 */

import { PermissionsGroup } from '../../models/permissions';

import {
  GROUP__ADMIN,
  GROUP__CREATE,
  GROUP__DELETE,
  GROUP__UPDATE,
} from './permissionsAll';
import { getPermissionsCurrent } from './storage';

/**
 *
 * HELPER
 *
 */

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsGroup): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the current user has admin permissions for groups
 *
 * @returns Whether it's true
 */
const getIsAdmin = (): boolean => {
  return getPermissionsCurrent().includes(GROUP__ADMIN);
};

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user has permissions
 * to create a group
 *
 * @returns Whether the user has permissions
 */
export const getCanCreateGroup = (): boolean => {
  return getIsAdmin() || hasPermission(GROUP__CREATE);
};

/**
 *
 * READ
 *
 */

// Backend won't return unavailable groups

/**
 *
 * UPDATE
 *
 */

/**
 * Check whether the user has permissions
 * to update a group
 *
 * @returns Whether the user has permissions
 */
export const getCanUpdateGroup = (): boolean => {
  return getIsAdmin() || hasPermission(GROUP__UPDATE);
};

/**
 *
 * DELETE
 *
 */

/**
 * Check whether the user has permissions
 * to delete a group
 *
 * @returns Whether the user has permissions
 */
export const getCanDeleteGroup = (): boolean => {
  return getIsAdmin() || hasPermission(GROUP__DELETE);
};
