/**
 * @file contains a custom react hook for handling state of the scroll in the feed
 */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Topic } from '../../generated/graphql';
import AppState from '../../models/state';

import {
  scrollRememberFeed,
  scrollResetFeed,
  scrollTopicsLatestPostAdd,
  scrollTopicsLatestPostRemove,
} from '../../store/actions/scroll';

type UseFeedScrollStateReturn = {
  isTopicScrolled: boolean;
  requestScrollRememberFeed: (scrollPosition: number) => void;
  requestScrollResetFeed: () => void;
  requestScrollTopicsLatestPostAdd: () => void;
  requestScrollTopicsLatestPostRemove: () => void;
  scrollValue: number;
  topicHasLatestPost: boolean;
};

/**
 * Quick access to scroll state and actions for a topic,
 * in the store because it's used by different components/hooks
 *
 * @param topicId ID of the topic
 * @returns       Scroll state and actions to change it
 */
const useFeedScrollState = (
  topicId: Topic['id'] | null,
): UseFeedScrollStateReturn => {
  const dispatch = useDispatch();

  /**
   * Using 2x useSelector is better then one big that will select entire state,
   * makes it rerender fewer times. Selecting scroll value for the topic and
   * if topic has latest post displayed.
   */
  const scrollValue = useSelector<AppState, number>(state =>
    topicId !== null ? state.scroll.feed[topicId] : 0,
  );

  const topicHasLatestPost = useSelector<AppState, boolean>(state => {
    return topicId !== null
      ? state.scroll.topicsLatestPost.has(topicId)
      : false;
  });

  /**
   * Dispatches action to remember scroll position.
   */
  const requestScrollRememberFeed = useCallback(
    (scrollPosition: number) => {
      if (topicId === null) {
        return;
      }
      dispatch(scrollRememberFeed(scrollPosition, topicId));
    },
    [dispatch, topicId],
  );

  /**
   * Dispatches action to reset scroll position.
   */
  const requestScrollResetFeed = useCallback(() => {
    if (topicId === null) {
      return;
    }
    dispatch(scrollResetFeed(topicId));
  }, [dispatch, topicId]);

  /**
   * Dispatches action to show the latest post button for a topic.
   */
  const requestScrollTopicsLatestPostAdd = useCallback(() => {
    if (topicId === null) {
      return;
    }
    dispatch(scrollTopicsLatestPostAdd(topicId));
  }, [dispatch, topicId]);

  /**
   * Dispatches action to hide the latest post button for a topic.
   */
  const requestScrollTopicsLatestPostRemove = useCallback(() => {
    if (topicId === null) {
      return;
    }
    dispatch(scrollTopicsLatestPostRemove(topicId));
  }, [dispatch, topicId]);

  return {
    // Since flex column is reversed, scrolling is negative
    isTopicScrolled: scrollValue < 0,
    requestScrollRememberFeed,
    requestScrollResetFeed,
    requestScrollTopicsLatestPostAdd,
    requestScrollTopicsLatestPostRemove,
    scrollValue,
    topicHasLatestPost,
  };
};

export default useFeedScrollState;
