import { FC } from 'react';
import { useSubscription } from 'urql';

import { CommentInfoDocument, Message } from '../../generated/graphql';

type Props = {
  messageId: Message['id'];
};

/**
 * Component for subscribing to comment changes
 *
 * @param props           Props passed to the component
 * @param props.messageId ID of the message to use to subscribe to comments
 * @returns               Component that holds subscription to comments
 */
const CommentInfoSubscription: FC<Props> = ({ messageId }) => {
  useSubscription({
    query: CommentInfoDocument,
    variables: {
      messageId,
    },
  });
  return null;
};

export default CommentInfoSubscription;
