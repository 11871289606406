import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import ButtonControl, { NAMES } from '../ButtonControl';

const iconName = NAMES.COMPOSE__CONTROL_LIST_BULLETS;

type Props = {
  isActive: boolean;
  onClick: () => void;
};

/**
 * The button for adding a bulleted list
 *
 * @param props          The props passed to the component
 * @param props.isActive Whether the control is active
 * @param props.onClick  Button click callback
 * @returns              The component itself
 */
const ButtonStyleListBullets: FC<Props> = props => {
  const label = translate('COMPOSE__LIST__BULLETS');
  return <ButtonControl iconName={iconName} label={label} {...props} />;
};

export default ButtonStyleListBullets;
