import { styled } from 'styled-components';

import { CssVariable } from '../../../../styles/cssTypes';

import RichText from '../../RichText';

type TextProps = {
  'data-is-deactivated': boolean;
  'data-should-clamp': boolean;
};

/**
 * Get which color to use for message text
 *
 * @param props Props passed to the component
 * @returns     The color to use
 */
const getColor = (props: TextProps): CssVariable | 'inherit' => {
  if (props['data-is-deactivated']) {
    return 'var(--color-text-light)';
  }

  return 'inherit';
};

/**
 * Get whether to clamp the message or not,
 * depending on the props passed
 *
 * @param props Props passed to the component
 * @returns     Line clamp value
 */
const getLineClamp = (props: TextProps): string => {
  if (props['data-should-clamp']) {
    return 'var(--preference-message-line-clamp)';
  }

  return 'initial';
};

// Message text itself
export const Text = styled(RichText)<TextProps>`
  -webkit-box-orient: vertical;
  color: ${getColor};
  display: -webkit-box;
  -webkit-line-clamp: ${getLineClamp};
  /* stylelint-disable-next-line property-no-unknown */
  line-clamp: ${getLineClamp};
  margin-bottom: var(--spacing-small);
  overflow: hidden;
  word-break: break-word;
`;
