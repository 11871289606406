import React, { FC } from 'react';

import { UserBasicFragment } from '../../../generated/graphql';
import userGetName from '../../../utils/user/userGetName';
import Checkbox from '../../Common/Checkbox';

import * as Styled from './styled';

export type Props = {
  className?: string;
  isAssigned?: boolean;
  member: UserBasicFragment;
  onChange?: (state: boolean) => void;
};

/**
 * A single row in the member list within assignees box, for the compose portion
 *
 * @param props            Props passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.isAssigned Whether the user is already assigned
 * @param props.member     Member data
 * @param props.onChange   When the checkbox is toggled
 * @returns                The component itself
 */
const ComposeMetaAssigneeMember: FC<Props> = ({
  className,
  isAssigned = false,
  member,
  onChange = null,
}) => {
  const displayName = userGetName(member);
  const isViewMode = onChange === null;

  return (
    <Styled.Label className={className}>
      <Styled.Avatar user={member} />
      <Styled.Name>{displayName}</Styled.Name>
      {!isViewMode && (
        <Checkbox
          colorChecked="var(--color-primary-light)"
          colorUnchecked="var(--color-grey-darkest)"
          isChecked={isAssigned}
          label={displayName}
          onChange={onChange}
        />
      )}
    </Styled.Label>
  );
};

export default ComposeMetaAssigneeMember;
