/**
 * @file contains key-value translations that are used
 * when the user is not yet authenticated
 *
 * Language: English
 */

export default {
  ERROR__404__ACTION: 'To the home page',
  ERROR__404__DESCRIPTION:
    'Unfortunately, this page does not exist. Please try again with a valid URL.',
  ERROR__AUTH_HOTEL_NOT_FOUND__DESCRIPTION:
    'Unfortunately, we could not find the object. Please contact your administrator or our support team.',
  ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION:
    'Unfortunately, we could not find a user for this identifier. Please try again with valid access data. If the error occurs again, please contact the support.',
  ERROR__DEACTIVATED_HOTEL__DESCRIPTION:
    'Your user has been deactivated for the object. Please contact your administrator or our support team.',
  ERROR__HOTEL__SWITCH_DESCRIPTION:
    'Would you like to switch to another object instead?',
  ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX: 'Switch to “',
  ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX: '”',
  ERROR__MISSING_HOTEL__DESCRIPTION:
    'Unfortunately, you do not have access to the object. Please contact your administrator or our support team.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION:
    'To gain access, please accept the general terms and conditions.',
  ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE: "Oops, something's missing!",
  GENERAL__BACK_TO_LOGIN: 'Back to login',
  GENERAL__OOPS: 'Oops, something went wrong.',
};
