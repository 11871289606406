import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

import SkeletonLine from '../SkeletonLine';

// Wrapper around the entire component
export const Wrapper = styled.div`
  padding: var(--spacing-small);
`;

// Replies list
export const Ul = styled.ul`
  width: 100%;
`;

// Wrapper around a single reply
export const Li = styled.li`
  display: flex;
  margin-top: var(--spacing-medium);
`;

export const Contents = styled.article`
  flex-grow: 1;
  margin-left: var(--spacing-medium);
`;

// Title line
export const SkeletonTitle = styled(SkeletonLine)`
  height: var(--spacing-medium);
`;

// Used for accessibility, annotating what the skeleton is for
export const LoadingDescription = styled.p`
  ${visuallyHidden};
`;
