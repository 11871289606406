import React, { FC, ReactNode } from 'react';

import useIsMobile from '../../../../hooks/useIsMobile';
import translate from '../../../../i18n/translate';
import Icon, { NAMES } from '../../../Common/Icon';

import * as Styled from './styled';

type Props = {
  backUrl: string;
  className?: string;
  contentLeft?: ReactNode;
};

const ICON_SIZE = 14;

/**
 * Right Sidebar Header component
 *
 * @param props             props passed to the component
 * @param props.backUrl     Route the back button should redirect to
 * @param props.className   styled-components generated class name
 * @param props.contentLeft Content that should be shown on the left side of the header
 * @returns                 The component itself
 */
const RightSidebarHeader: FC<Props> = ({
  backUrl,
  className,
  contentLeft = null,
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return (
    <Styled.Wrapper className={className}>
      {contentLeft}
      <Styled.CloseSidebarDesktopLink
        aria-label={translate('GENERAL__CLOSE')}
        to={backUrl}
      >
        <Icon
          height={ICON_SIZE}
          name={NAMES.GENERAL__CLOSE}
          width={ICON_SIZE}
        />
      </Styled.CloseSidebarDesktopLink>
    </Styled.Wrapper>
  );
};

export default RightSidebarHeader;
