/**
 * @file Hook for getting the current user's locale settings
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import { Locale, LocaleDocument, LocaleQuery } from '../generated/graphql';
import { setTranslations, Translations } from '../i18n/translations';
import { setLanguageCode } from '../utils/user/language';
import { setLocaleCode } from '../utils/user/locale';

/**
 * Map translations
 *
 * @param rawTranslations Array of translation objects, coming from the backend.
 * @returns               A map of translations
 */
export const mapTranslations = (
  rawTranslations: LocaleQuery['localization']['translations'],
) => {
  return rawTranslations.reduce<Translations>((acc, { label, value }) => {
    acc[value] = label;
    return acc;
  }, {});
};

/**
 * Hook for initializing user language code,
 * fetches the current user info and stores that
 *
 * @param shouldPause Whether to pause the query execution
 * @returns           The user's set locale (null while fetching or error)
 */
const useLocale = (shouldPause = false): Locale | null => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [{ data }] = useQuery({
    pause: shouldPause,
    query: LocaleDocument,
    variables: {},
  });

  const language = data?.me.language ?? null;
  const locale = data?.me.localization ?? null;
  const translations = data?.localization.translations ?? null;

  useEffect(() => {
    if (language === null || locale === null || translations === null) {
      return;
    }

    setLanguageCode(language);
    setLocaleCode(locale);
    setTranslations(mapTranslations(translations));
    setIsInitialized(true);
  }, [language, locale, translations]);

  // not using language because it can happen that
  // the effect wasn't yet executed
  return isInitialized ? language : null;
};

export default useLocale;
