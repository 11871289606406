import { Avatar, Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { KANBAN__MAX_AVATARS_TO_SHOW } from '../../../constants/preferences';
import { HASH_MESSAGE__INFO__ASSIGNEE } from '../../../constants/routing/hashLinks';
import translate from '../../../i18n/translate';
import { MessageFeed } from '../../../models/message';

import { getUrlWithHash } from '../../../routes/helpers/hash';

import userGetName from '../../../utils/user/userGetName';
import MuiAvatar from '../../MUI/Avatar';

import { getSurplusTooltipText } from '../helpers';

import * as Styled from './styled';

type Props = {
  task: Pick<MessageFeed, 'assignedMembers'>;
  url: string;
};

/**
 * Task Members Component
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the members of
 * @param props.url  Message details URL
 * @returns          The component itself
 */
const TaskMembers: FC<Props> = ({ task, url }) => {
  const { assignedMembers } = task;
  if (assignedMembers.length === 0) {
    return null;
  }

  /**
   * Get the avatar content for number of members that are not shown
   *
   * @param surplus Count of members that are not shown
   * @returns       Content for surplus avatar
   */
  const renderSurplus = (surplus: number) => {
    if (surplus < 1) {
      return null;
    }

    const memberNames = assignedMembers.map(member => userGetName(member));
    // We use -surplus here because the surplus avatar is also counted.
    // If we have 4 members, we show them all. If we have 5, we show 3 members
    // and the surplus avatar
    const textTooltip = getSurplusTooltipText(memberNames, -surplus);

    return (
      <Tooltip title={textTooltip}>
        <Avatar>{`+${surplus}`}</Avatar>
      </Tooltip>
    );
  };

  return (
    <Styled.MuiAvatarGroup
      aria-label={translate('GENERAL__MEMBERS')}
      component={Link}
      max={KANBAN__MAX_AVATARS_TO_SHOW}
      renderSurplus={renderSurplus}
      spacing="small"
      to={getUrlWithHash(url, HASH_MESSAGE__INFO__ASSIGNEE)}
      total={assignedMembers.length}
    >
      {assignedMembers.map(user => (
        <MuiAvatar key={user.id} user={user} />
      ))}
    </Styled.MuiAvatarGroup>
  );
};

export default TaskMembers;
