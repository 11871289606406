/**
 * @file All available editor configurations
 */

import { EditorConfiguration, EditorUsage } from './types';

const configurationFull: EditorConfiguration = {
  buttons: new Set([
    'buttonAttach',
    'buttonSend',
    'listBullets',
    'listNumbers',
    'textBold',
    'textItalic',
    'textStrikethrough',
  ]),
  multiline: true,
};

const configurationTemplate: EditorConfiguration = {
  buttons: new Set([
    'buttonSend',
    'listBullets',
    'listNumbers',
    'textBold',
    'textItalic',
    'textStrikethrough',
  ]),
  multiline: true,
};

const configurationTemplateMobile: EditorConfiguration = {
  buttons: new Set([
    'listBullets',
    'listNumbers',
    'textBold',
    'textItalic',
    'textStrikethrough',
  ]),
  multiline: true,
};

const configurationEmpty: EditorConfiguration = {
  buttons: new Set(['buttonSend']),
  multiline: true,
};

const configurationMessageEdit: EditorConfiguration = {
  buttons: new Set([
    'listBullets',
    'listNumbers',
    'textBold',
    'textItalic',
    'textStrikethrough',
  ]),
  multiline: true,
};

const configurationTopic: EditorConfiguration = {
  buttons: new Set(),
  multiline: false,
};

export const configurations: Record<EditorUsage, EditorConfiguration> = {
  comment: configurationFull,
  commentExternal: configurationEmpty,
  message: configurationFull,
  messageEdit: configurationMessageEdit,
  template: configurationTemplate,
  templateMobile: configurationTemplateMobile,
  topic: configurationTopic,
};
