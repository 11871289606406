import React, { FC, ReactNode } from 'react';

import Title from '../../../Common/Title';

import SkeletonHeaderMobile from '../../../Loading/HeaderMobile';

import * as Styled from './styled';

export type Props = {
  isLoading?: boolean;
  title: ReactNode;
};

/**
 * Mobile header title component for feed and details view
 *
 * @param props           props passed to the component
 * @param props.isLoading Whether the title is loading
 * @param props.title     title that will be shown in the header
 * @returns               The component itself
 */
const HeaderMobileTitle: FC<Props> = ({ isLoading = false, title }) => {
  if (isLoading) {
    return (
      <Title>
        <SkeletonHeaderMobile />
      </Title>
    );
  }

  if (typeof title === 'string') {
    return <Title>{title}</Title>;
  }

  return <Styled.TitleComponentWrapper>{title}</Styled.TitleComponentWrapper>;
};

export default HeaderMobileTitle;
