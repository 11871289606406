/**
 * @file Optimistically update the cache with new group title
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  GroupUpdateMutation,
  GroupUpdateMutationVariables,
} from '../../../../../generated/graphql';
import groupTitleUpdate from '../../updates/utils/updaters/groups/groupTitleUpdate';

/**
 * Optimistically update a group
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const groupUpdate: OptimisticMutationResolver<
  GroupUpdateMutationVariables,
  GroupUpdateMutation['userGroupUpdate'] | null
> = (args, cache) => groupTitleUpdate(cache, args);

export default groupUpdate;
