// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hardcoded values used across the project */
:root {
  /*
   * The segmented buttons is a group of buttons that we use within the header
   * currently, to navigate between different views
   */
  --header-segmented-buttons-min-width: 15rem;

  /*
   * On larger screens we want to limit how wide the task card footer is, but still
   * be fluid for smaller screens.
   */
  --task-card-footer-max-width: 26rem;
  /*
   * In order to align the title and icon in the desktop header, we need to apply
   * some spacing to the element that wraps the title and description.
   */
  --header-desktop-hgroup-padding-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_hardcoded.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE;;;IAGE;EACF,2CAA2C;;EAE3C;;;IAGE;EACF,mCAAmC;EACnC;;;IAGE;EACF,wCAAwC;AAC1C","sourcesContent":["/* Hardcoded values used across the project */\n:root {\n  /*\n   * The segmented buttons is a group of buttons that we use within the header\n   * currently, to navigate between different views\n   */\n  --header-segmented-buttons-min-width: 15rem;\n\n  /*\n   * On larger screens we want to limit how wide the task card footer is, but still\n   * be fluid for smaller screens.\n   */\n  --task-card-footer-max-width: 26rem;\n  /*\n   * In order to align the title and icon in the desktop header, we need to apply\n   * some spacing to the element that wraps the title and description.\n   */\n  --header-desktop-hgroup-padding-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
