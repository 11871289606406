import React, { FC, FormEventHandler, useId, useState } from 'react';

import {
  CHECKLIST__MAX_TEXT_LENGTH,
  CHECKLIST__MIN_TEXT_LENGTH,
  CHECKLIST__TEXT_REQUIRED,
  CHECKLIST__TEXT_UNIQUE,
} from '../../../constants/forms';

import translate from '../../../i18n/translate';
import { Text } from '../../../models/checklist';

import * as Styled from './styled';

export type Props = {
  className?: string;
  itemsExisting: Text[];
  requestCreate: (text: Text) => void;
};

// name attribute for title input field
const nameText = 'text';

/**
 * Checklist item add form
 *
 * @param props               Props passed to the component
 * @param props.className     styled-components generated class name, needed for styling
 * @param props.itemsExisting Already
 * @param props.requestCreate Request that a new checklist item is added
 * @returns                   The component itself
 */
const ChecklistFormCreate: FC<Props> = ({
  className,
  itemsExisting,
  requestCreate,
}) => {
  const [value, setValue] = useState('');

  const idHeading = useId();
  const idSubheading = useId();

  const placeholder = translate('CHECKLIST__ADD__PLACEHOLDER');
  const textSubmit = translate('CHECKLIST__ADD__SUBMIT');
  const textHeading = translate('CHECKLIST__ADD__HEADING');
  const textSubheading = translate('CHECKLIST__ADD__SUBHEADING');

  /**
   * Something within the form changed, so we
   *
   * @param event The event that took place
   */
  const handleChange: FormEventHandler<HTMLFormElement> = event => {
    const form = event.currentTarget;

    if (CHECKLIST__TEXT_UNIQUE) {
      const inputText = form.elements.namedItem(nameText) as HTMLInputElement;

      const isTextDuplicate = itemsExisting.includes(inputText.value);
      const errorMessage = isTextDuplicate
        ? translate('CHECKLIST__ERROR__DUPLICATE_TEXT')
        : '';
      inputText.setCustomValidity(errorMessage);
    }
  };

  /**
   * The user has submitted the form
   *
   * @param event The event that took place
   */
  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    event.currentTarget.reset();
    setValue('');
    requestCreate(value);
  };

  return (
    <Styled.Form
      aria-describedby={idSubheading}
      aria-labelledby={idHeading}
      className={className}
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <Styled.Heading id={idHeading}>{textHeading}</Styled.Heading>
      <Styled.Text id={idSubheading}>{textSubheading}</Styled.Text>
      <Styled.Input
        isRequired={CHECKLIST__TEXT_REQUIRED}
        maxLength={CHECKLIST__MAX_TEXT_LENGTH}
        minLength={CHECKLIST__MIN_TEXT_LENGTH}
        name={nameText}
        onChange={setValue}
        placeholder={placeholder}
        value={value}
      />
      <Styled.Submit type="submit" value={textSubmit} />
    </Styled.Form>
  );
};

export default ChecklistFormCreate;
