import React, { FC, FormEventHandler, useState } from 'react';

import {
  COMMENT__MAX_TEXT_LENGTH,
  COMMENT__TEXT_REQUIRED,
} from '../../../constants/forms';
import { Comment, Thread } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import Device from '../../../models/device';

import RecipientToggle, { RECIPIENTS } from './RecipientToggle';
import { getShouldDisableToggle } from './helpers';

import * as Styled from './styled';

const breakpointMin: Device = 'tabletS';
const nameText = 'text';
const nameRecipient = 'recipient';

export type Props = {
  requestDraftUpdate: (text: Comment['text']) => void;
  requestSubmit: (isForExternal: Comment['isForExternal']) => void;
  text: Comment['text'];
  thread: Thread | null;
};

/**
 * Compose form for comments
 *
 * @param props                    Props passed to the component
 * @param props.requestDraftUpdate Request that draft text is updated
 * @param props.requestSubmit      Request that a new comment is created
 * @param props.text               Text in reply compose form
 * @param props.thread             Metadata about the comment thread (for external messaging only)
 * @returns                        The component itself
 */
const CommentCompose: FC<Props> = ({
  requestDraftUpdate,
  requestSubmit,
  text,
  thread,
}) => {
  const textLength = text.trim().length;
  const [recipientMode, setRecipientMode] = useState(RECIPIENTS.TEAM);
  const [isEditorEmpty, setIsEditorEmpty] = useState(textLength === 0);

  /**
   * The form was submitted, so we request that the comment is created
   *
   * @param event The event that took place
   */
  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    const { elements } = event.currentTarget;
    const elemRecipient = elements.namedItem(nameRecipient);

    // For comments on internal messages, we don't show recipient toggle
    const isForExternal =
      elemRecipient !== null &&
      'value' in elemRecipient &&
      elemRecipient.value === RECIPIENTS.GUEST;

    if (event.currentTarget.checkValidity()) {
      requestSubmit(isForExternal);
    }
  };

  const placeholderTeamLabel = translate('MESSAGE__REPLIES_TEAM__PLACEHOLDER');
  const placeholderGuestLabel = translate(
    'MESSAGE__REPLIES_GUEST__PLACEHOLDER',
  );
  const recipientLabel =
    recipientMode === RECIPIENTS.TEAM
      ? placeholderTeamLabel
      : placeholderGuestLabel;

  const shouldDisableToggle = getShouldDisableToggle(thread);

  return (
    <Styled.Form aria-label={recipientLabel} onSubmit={handleSubmit}>
      <RecipientToggle
        isDisabled={shouldDisableToggle}
        name={nameRecipient}
        onChange={setRecipientMode}
        value={recipientMode}
      />
      <Styled.Editor
        breakpointMin={breakpointMin}
        isRequired={COMMENT__TEXT_REQUIRED}
        key={recipientMode}
        maxLength={COMMENT__MAX_TEXT_LENGTH}
        name={nameText}
        onChangeText={requestDraftUpdate}
        placeholder={recipientLabel}
        setIsEditorEmpty={setIsEditorEmpty}
        shouldDisableSubmit={isEditorEmpty || textLength === 0}
        usage={
          recipientMode === RECIPIENTS.TEAM ? 'comment' : 'commentExternal'
        }
        value={text}
      />
    </Styled.Form>
  );
};

export default CommentCompose;
