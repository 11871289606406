import { styled } from 'styled-components';

import CommonMessageSingle from '../../Common/MessageSingle';

type WrapperProps = {
  'data-should-hide-border-bottom': boolean;
};

type MessageSingleProps = {
  'data-is-seen': boolean;
};

export const Wrapper = styled.li<WrapperProps>`
  /* Fix rendering problem on iOS (posts disappear on feed) */
  transform: translateZ(0);

  & article {
    border-bottom: ${props =>
      props['data-should-hide-border-bottom']
        ? 'none'
        : '1px solid var(--color-grey-pale)'};
  }
`;

// Wrapper for a single message in the list
export const MessageSingle = styled(CommonMessageSingle)<MessageSingleProps>`
  position: relative;

  &::before {
    background-color: var(--color-message-new);
    content: '';
    display: ${props => (props['data-is-seen'] === false ? 'block' : 'none')};
    height: calc(100% - 2 * var(--spacing-medium));
    left: 0;
    position: absolute;
    top: var(--spacing-medium);
    width: var(--spacing-tiny);
  }
`;
