import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Hotel } from '../../../../generated/graphql';

import useCanUserAccessReservations from '../../../../hooks/useCanAccessReservations';
import { useIsChatEnabled } from '../../../../integrations/roqChat/useIsChatEnabled';
import { getCanViewDashboard } from '../../../../utils/permissions/dashboard';
import RailListItem from '../RailListItem';
import RailListItemHotel from '../RailListItem/RailListItemHotel';
import RailListItemUser from '../RailListItem/RailListItemUser';
import {
  getRailListItemHotel,
  getRailListItemSettings,
  getRailListItemsMain,
} from '../helpers';
import { NavItem, NavTab } from '../types';

import * as Styled from './styled';

export type Props = {
  currentHotel: Hotel | null;
  hasMoreHotels: boolean;
  nrUnreadTopics: number;
  selectedItem: NavTab;
};

/**
 * The Rail component for the desktop navigation
 *
 * @param props                Props passed to component
 * @param props.currentHotel   Current hotel
 * @param props.hasMoreHotels  Whether the user has more than one hotel
 * @param props.nrUnreadTopics The number of topic notifications
 * @param props.selectedItem   Currently selected item
 * @returns                    The component itself
 */
const Rail: FC<Props> = ({
  currentHotel,
  nrUnreadTopics,
  hasMoreHotels,
  selectedItem,
}) => {
  const canAccessReservations = useCanUserAccessReservations();
  const canViewDashboardPage = getCanViewDashboard();
  const hotelId = currentHotel?.id;
  const isChatEnabled = useIsChatEnabled();
  const location = useLocation();

  const hotelNavigationItem = useMemo(
    () => getRailListItemHotel(currentHotel?.name ?? '', location),
    [currentHotel?.name, location],
  );
  const mainNavigationItems = useMemo(
    () =>
      hotelId
        ? getRailListItemsMain(
            canAccessReservations,
            canViewDashboardPage,
            hotelId,
            isChatEnabled,
            location,
            nrUnreadTopics,
          )
        : [],
    [
      canAccessReservations,
      canViewDashboardPage,
      hotelId,
      isChatEnabled,
      location,
      nrUnreadTopics,
    ],
  );

  const settingsNavigationItem = useMemo(
    () => getRailListItemSettings(location),
    [location],
  );

  /**
   * Rendering items in navigation
   *
   * @param items Items to map into NavigationItem component
   * @returns     Mapped items as components
   */
  const renderItems = (items: NavItem[]) =>
    items.map(item => (
      <RailListItem
        isSelected={selectedItem === item.id}
        item={item}
        key={item.id}
      />
    ));

  return (
    <Styled.RailWrapper>
      <Styled.Rail as="nav">
        <Styled.NavigationList>
          <RailListItemHotel
            hasMoreHotels={hasMoreHotels}
            imageUrl={currentHotel?.imageUrl}
            isSelected={selectedItem === 'hotels'}
            item={hotelNavigationItem}
          />
          {renderItems(mainNavigationItems)}
        </Styled.NavigationList>
        <Styled.NavigationList>
          {renderItems([settingsNavigationItem])}
          <RailListItemUser />
        </Styled.NavigationList>
      </Styled.Rail>
    </Styled.RailWrapper>
  );
};

export default Rail;
