import React, { FC, FormEventHandler } from 'react';

import { TemplateBasicFragment } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../hooks/useIsTablet';
import translate from '../../../i18n/translate';
import { getSettingsTemplatesUrl } from '../../../routes/urls/settings';
import { getCanCreateTemplate } from '../../../utils/permissions/template';
import ListSearch from '../../Compose/Common/ComposeListSearch';

type Props = {
  ariaDescribedBy: string;
  ariaLabelledBy: string;
  dataTemplateAll: TemplateBasicFragment[];
  dataTemplateCurrent: TemplateBasicFragment['id'] | null;
  dataSearchValue: string;
  idForm: string;
  isLoading: boolean;
  onSearch: (term: string) => void;
  requestTemplateSave: () => void;
  requestTemplateChange: (templateId: TemplateBasicFragment['id']) => void;
};

/**
 * Form to select topic for template
 *
 * @param props                       Props passed to the component
 * @param props.ariaDescribedBy       Accessible form description
 * @param props.ariaLabelledBy        Accessible form title
 * @param props.dataTemplateAll       All templates
 * @param props.dataTemplateCurrent   Currently selected template
 * @param props.dataSearchValue       <input type="search" /> value attribute
 * @param props.idForm                The ID to use for the assignment <form /> element
 * @param props.isLoading             Whether the data is still being loaded
 * @param props.onSearch              Callback to invoke when search term is changed
 * @param props.requestTemplateSave   Request that the current template is saved
 * @param props.requestTemplateChange Request that the current template is changed
 * @returns                           The component itself
 */
const MessageTemplateForm: FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  dataTemplateAll,
  dataTemplateCurrent,
  dataSearchValue,
  idForm,
  isLoading,
  onSearch,
  requestTemplateSave,
  requestTemplateChange,
}) => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();

  const canCreateTemplate = getCanCreateTemplate();

  /**
   * The user has submitted the form,
   * so we request that the selected template is saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestTemplateSave();
  };

  /**
   * Get translated text for no results state based on user permission for creating template
   *
   * @returns Translated text
   */
  const getNoResultText = () => {
    if (canCreateTemplate) {
      return translate('TEMPLATE__GENERAL__NO_RESULT_PERMITTED');
    }

    return translate('TEMPLATE__GENERAL__NO_RESULT_UNPERMITTED');
  };

  return (
    <form
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      id={idForm}
      onSubmit={onSubmit}
    >
      <ListSearch
        actionLink={
          canCreateTemplate
            ? {
                href: getSettingsTemplatesUrl(hotelId, isTablet),
                text: translate('TEMPLATE__GENERAL__SETTINGS_LINK'),
              }
            : null
        }
        dataAll={dataTemplateAll}
        dataCurrent={dataTemplateCurrent}
        dataSearchValue={dataSearchValue}
        dataType="template"
        inputSearchLabel={translate('TEMPLATE__GENERAL__SEARCH_LABEL')}
        inputSearchPlaceholder={translate('TEMPLATE__GENERAL__SEARCH_LABEL')}
        isLoading={isLoading}
        noResultsText={getNoResultText()}
        onSearch={onSearch}
        requestChange={requestTemplateChange}
      />
    </form>
  );
};

export default MessageTemplateForm;
