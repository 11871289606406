import React, { AriaAttributes, FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  ariaCurrent?: AriaAttributes['aria-current'];
  bind: {
    onFocus: () => void;
    onBlur: () => void;
  };
  className?: string;
  children: ReactNode;
  isExternal?: boolean;
  label: string;
  onClick?: () => void;
  url: string;
};

/**
 * Link for external and internal linking used for LinkIcon
 *
 * @param props             Props passed to the component
 * @param props.ariaCurrent aria-current attribute for the link
 * @param props.bind        onFocus nad onBlur functions used for useFocus hook
 * @param props.className   Styled-components generated class name, needed for styling
 * @param props.children    Children passed to the component
 * @param props.isExternal  Whether this is an external link
 * @param props.label       Aria label for the button (since there's no text, just the icon)
 * @param props.onClick     Click callback, not needed for submit buttons
 * @param props.url         Url to link to
 * @returns                 The component itself
 */
const Link: FC<Props> = ({
  ariaCurrent,
  bind,
  children,
  className,
  isExternal = false,
  label,
  onClick,
  url,
}) => {
  const commonProps = {
    'aria-current': ariaCurrent,
    'aria-label': label,
    className,
    ...bind,
  };

  if (isExternal) {
    return (
      <Styled.Link
        as="a"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        to={url}
        {...commonProps}
      >
        {children}
      </Styled.Link>
    );
  }

  return (
    <Styled.Link onClick={onClick} to={url} {...commonProps}>
      {children}
    </Styled.Link>
  );
};

export default Link;
