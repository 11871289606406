import React, { FC } from 'react';

import { UserBasicFragment } from '../../../generated/graphql';
import intlPluralFormatter from '../../../utils/intlPluralFormat';
import userGetName from '../../../utils/user/userGetName';
import ProgressReadBy from '../../Common/ProgressReadBy';

import * as Styled from './styled';

type Props = {
  percentage: number;
  readBy: UserBasicFragment[];
};

/**
 * Show who has read the message already
 *
 * @param props            Props passed to the component
 * @param props.percentage Percentage of people that have read this
 * @param props.readBy     The users who have read the message
 * @returns                The component itself
 */
const MessageReadBy: FC<Props> = ({ percentage, readBy }) => {
  return (
    <>
      <Styled.Text>
        {intlPluralFormatter(readBy.map(user => userGetName(user)))}
      </Styled.Text>

      <ProgressReadBy percentage={percentage} />
    </>
  );
};

export default MessageReadBy;
