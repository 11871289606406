/**
 * @file Custom hook for checking if we are on mobile view or not
 */
import breakpoints from '../constants/breakpoints';

import useMatchMedia from './useMatchMedia';

/**
 * Custom hook for checking if we are on mobile view or not
 * Desktop separator determined by us.
 *
 * @returns Whether we are on mobile screens or not
 */
const useIsMobile = (): boolean => {
  /**
   * In order to avoid empty screen on 768px caused by
   * 1px gap between max-width and min-width -1 is added
   */
  return useMatchMedia(`(max-width: ${breakpoints.tabletS - 1}px)`);
};

export default useIsMobile;
