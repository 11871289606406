/**
 * @file State management for pagination info
 */

import { useCallback, useReducer } from 'react';

import { PREFERENCE__MESSAGES__LIMIT } from '../../../constants/pagination';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';

export type PaginationState = {
  after: string | null;
  before: string | null;
  first: number | null;
  last: number | null;
};

type Return = {
  paginationState: PaginationState;
  paginationActionUp: (after: string) => void;
  paginationActionDown: (before: string) => void;
  paginationActionReset: () => void;
  paginationActionResetFirstPage: () => void;
};

type PaginationActionUp = {
  type: 'UP';
  payload: {
    after: string;
  };
};

type PaginationActionDown = {
  type: 'DOWN';
  payload: {
    before: string;
  };
};

type PaginationActionReset = {
  type: 'RESET';
  payload: {
    messageIdFocus: string | null;
  };
};

type PaginationAction =
  | PaginationActionUp
  | PaginationActionDown
  | PaginationActionReset;

/**
 * Default pagination state when we first arrive to
 * messages page
 */
export const defaultPaginationState: PaginationState = {
  after: '',
  before: null,
  first: PREFERENCE__MESSAGES__LIMIT,
  last: null,
};

/**
 * Initializes the default state for messages pagination
 * depending on if we have a focused message or not
 *
 * @param messageIdFocus The message to focus (if using a deep link)
 * @returns              New pagination state
 */
const initState = (messageIdFocus: string | null): PaginationState => {
  if (messageIdFocus) {
    return {
      after: messageIdFocus,
      before: messageIdFocus,
      first: PREFERENCE__MESSAGES__LIMIT,
      last: PREFERENCE__MESSAGES__LIMIT,
    };
  }

  return defaultPaginationState;
};

/**
 * Pagination state reducer
 *
 * @param state  Old pagination state
 * @param action Pagination action
 * @returns      New pagination state
 */
const reducer = (
  state: PaginationState,
  action: PaginationAction,
): PaginationState => {
  switch (action.type) {
    case 'UP':
      return {
        after: action.payload.after,
        before: null,
        first: PREFERENCE__MESSAGES__LIMIT,
        last: null,
      };
    case 'DOWN':
      return {
        after: null,
        before: action.payload.before,
        first: null,
        last: PREFERENCE__MESSAGES__LIMIT,
      };
    case 'RESET':
      return initState(action.payload.messageIdFocus);
    default:
      return state;
  }
};

/**
 * Pagination cursors handle hook
 *
 * @returns Set of pagination state and state change actions
 */
const useMessagePaginationCursors = (): Return => {
  const messageIdFocus = useParamMessageId();

  const [paginationState, dispatch] = useReducer(
    reducer,
    messageIdFocus,
    initState,
  );

  /**
   * Starts the pagination process in the UP direction
   */
  const paginationActionUp = useCallback((after: string): void => {
    return dispatch({
      payload: { after },
      type: 'UP',
    });
  }, []);

  /**
   * Starts the pagination process in the DOWN direction
   */
  const paginationActionDown = useCallback((before: string): void => {
    return dispatch({
      payload: { before },
      type: 'DOWN',
    });
  }, []);

  /**
   * Resets pagination state to sensible default
   */
  const paginationActionReset = useCallback((): void => {
    return dispatch({
      payload: { messageIdFocus },
      type: 'RESET',
    });
  }, [messageIdFocus]);

  /**
   * Resets pagination state to first page
   */
  const paginationActionResetFirstPage = useCallback((): void => {
    return dispatch({
      payload: { ...defaultPaginationState, messageIdFocus: null },
      type: 'RESET',
    });
  }, []);

  return {
    paginationActionDown,
    paginationActionReset,
    paginationActionResetFirstPage,
    paginationActionUp,
    paginationState,
  };
};

export default useMessagePaginationCursors;
