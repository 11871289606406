import React, { FC } from 'react';

import BaseHeaderDesktop from '../../../../components/Header/BaseHeaderDesktop';
import MuiIcon from '../../../../components/MUI/MuiIcon';
import useLayoutConfig from '../../../../components/layouts/SettingsLayout/hooks/useLayoutConfig';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamRecurringFrequency from '../../../../hooks/router/params/useParamRecurringFrequency';
import useParamTemplateId from '../../../../hooks/router/params/useParamTemplateId';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import useIsTablet from '../../../../hooks/useIsTablet';
import { getSettingsSegmentedButtons } from '../helpers';

/**
 * Desktop header component for the settings page
 *
 * @returns The component itself
 */
const SettingsHeaderDesktop: FC = () => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const recurringFrequency = useParamRecurringFrequency();
  const templateId = useParamTemplateId();

  const { buttons, icon, subtitle, title } = useLayoutConfig();

  const segmentedButtons = getSettingsSegmentedButtons({
    currentPath,
    hotelId,
    isTablet,
    recurringFrequency,
    templateId,
  });

  return (
    <BaseHeaderDesktop
      actionButtonRight={null}
      buttons={buttons}
      description={subtitle}
      leadingVisual={icon !== null ? <MuiIcon icon={icon} /> : null}
      segmentedButtons={segmentedButtons}
      subtitle={null}
      title={title}
    />
  );
};

export default SettingsHeaderDesktop;
