/**
 * @file Get the title of the current topic
 */

import { useQuery } from 'urql';

import { TopicBasicDocument } from '../../../generated/graphql';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';

/**
 * Get the title of the current topic
 *
 * @returns The title of the current topic, once fetched
 */
const useTopicTitle = () => {
  const topicId = useParamTopicId();

  const [{ data }] = useQuery({
    pause: topicId === null,
    query: TopicBasicDocument,
    variables: { id: topicId },
  });

  return data?.topic.title ?? null;
};

export default useTopicTitle;
