import React, { FC } from 'react';

import { UserGroupBasicFragment } from '../../../generated/graphql';
import GroupSingle from '../GroupSingle';

import * as Styled from './styled';

export type Props = {
  className?: string;
  groups: UserGroupBasicFragment[];
};

/**
 * The list of groups, commonly displayed format
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.groups    The groups to show
 * @returns               The component itself
 */
const GroupList: FC<Props> = ({ className, groups }) => {
  return (
    <Styled.Ul className={className}>
      {groups.map(group => {
        return (
          <Styled.Li key={`group-${group.id}`}>
            <GroupSingle group={group} />
          </Styled.Li>
        );
      })}
    </Styled.Ul>
  );
};

export default GroupList;
