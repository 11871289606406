/**
 *
 * @file contains modes that the labels meta block can be in
 * (used for compose and in sidebar)
 */

export const MODE_CREATE = 'MODE_CREATE';
export const MODE_EDIT = 'MODE_EDIT';
export const MODE_VIEW = 'MODE_VIEW';

export type ModeLabels =
  | typeof MODE_CREATE
  | typeof MODE_EDIT
  | typeof MODE_VIEW;
