import { Button, styled } from '@mui/material';

export const MuiButton = styled(Button)({
  '&:hover,:active': {
    boxShadow: 'none',
  },
  border: 'var(--mui-button-outlined-border)',
  boxShadow: 'none',
  flex: '1 1 0',
  paddingLeft: 'var(--spacing-big)',
  paddingRight: 'var(--spacing-big)',
  textTransform: 'capitalize',
});
