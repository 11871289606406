import { styled } from 'styled-components';

import ButtonTranslate from '../../components/ButtonTranslate';

export const ButtonUntranslate = styled(ButtonTranslate)`
  background-color: var(--color-hover-dark);

  @media (hover: hover) {
    &:enabled:hover {
      background-color: var(--color-primary-light);
    }
  }
`;
