import { styled } from 'styled-components';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: var(--color-primary-light);
  display: flex;
  font-size: var(--font-baseline);
  vertical-align: top;
`;
