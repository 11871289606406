/**
 * @file Comment proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorComment } from './navigators';

const mapperComment = {
  [NotificationEvent.CommentCreated]: navigatorComment,
  [NotificationEvent.CommentCreatedForAssignees]: navigatorComment,
  [NotificationEvent.CommentCreatedForCreator]: navigatorComment,
  [NotificationEvent.CommentExternalCreated]: navigatorComment,
  [NotificationEvent.CommentExternalCreatedForAssignees]: navigatorComment,
};

export default mapperComment;
