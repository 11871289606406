import React, { FC, useId } from 'react';

import { REMINDER__MAX_TOTAL_ITEMS } from '../../constants/forms';
import { PREFERENCE_REMINDER_DEFAULT } from '../../constants/preferences';
import { Reminder } from '../../generated/graphql';
import translate from '../../i18n/translate';

import Button from '../Common/Button';

import ReminderItems from './ReminderItems';

import * as Styled from './styled';

type Props = {
  itemsCurrent: Reminder[];
  isDisabled?: boolean;
  requestRemindersAdd: (reminder: Reminder) => void;
  requestRemindersRemove: (position?: number) => void;
  requestRemindersSet: (position: number, reminder: Reminder) => void;
};

/**
 * Reminders section
 *
 * @param props                        Props passed to the component
 * @param props.itemsCurrent           The reminder items to render
 * @param props.isDisabled             Whether the disabled info is shown
 * @param props.requestRemindersAdd    Request that a new item is added
 * @param props.requestRemindersRemove Request that an existing item is removed
 * @param props.requestRemindersSet    Request that the reminder is changed
 * @returns                            The component itself
 */
const Reminders: FC<Props> = ({
  itemsCurrent,
  isDisabled = false,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSet,
}) => {
  const itemCount = itemsCurrent?.length;
  const canCreateMore = itemCount < REMINDER__MAX_TOTAL_ITEMS;
  const idHeading = useId();

  /**
   * Create default reminder
   */
  const handleReminderCreate = () => {
    requestRemindersAdd(PREFERENCE_REMINDER_DEFAULT);
  };

  return (
    <Styled.Fieldset aria-labelledby={idHeading}>
      <Styled.Legend id={idHeading}>
        {translate('MODAL__REMINDERS_SET__PLACEHOLDER')}
      </Styled.Legend>
      <ReminderItems
        items={itemsCurrent}
        requestChange={requestRemindersSet}
        requestDelete={requestRemindersRemove}
      />
      {canCreateMore && (
        <Button onClick={handleReminderCreate}>
          {translate('MODAL__REMINDERS_ADD__PLACEHOLDER')}
        </Button>
      )}
      <Styled.Info>
        {isDisabled
          ? translate('MODAL__REMINDERS__SELECT__DUE_DATE')
          : translate('MODAL__REMINDERS__INFO')}
      </Styled.Info>
    </Styled.Fieldset>
  );
};

export default Reminders;
