/**
 * @file contains functions related to token storage and auth
 */

import { signOut } from '@gastfreund/roq-ui-react';

import {
  AUTH__CODE,
  SSO_BASE_URL,
  SSO_CLIENT_ID,
  SSO_REDIRECT_URI,
} from '../config';
import { removeAllRoqTokens } from '../integrations/roqChat/tokenStorage';

import { removeAuthCookies } from './storage/auth';
import { postWebViewCookiesFlushEvent } from './webview/events';
import { getIsReactNativeIos } from './webview/helpers';

/**
 * Get the authorization code
 *
 * @returns Auth code if it exists
 */
export const getAuthCode = (): string | null => {
  return new URLSearchParams(window.location.search).get(AUTH__CODE);
};

/**
 * Redirect the user to IAM if not logged in
 */
export const goToIAM = (): void => {
  // Encode url so it doesn't get mixed up with normal url params (it's using & and ?).
  const returnUrl = `&state=${encodeURIComponent(window.location.href)}`;

  // It can't be done via iamUrl.searchParams.set
  // Because SSO_REDIRECT_URI and createReturnUrl() need to NOT be urlencoded,
  // while .set() automatically encodes
  const iamUrl = new URL(
    `/oauth/authorize?client_id=${SSO_CLIENT_ID}&redirect_uri=${SSO_REDIRECT_URI}&response_type=code&scope${returnUrl}`,
    SSO_BASE_URL,
  );

  /**
   * Apple requires native apps to implement tracking prevention.
   * However, there's no way for us to properly handle this at the moment,
   * so the solution was to just hide the cookie popup on iOS native
   *
   * @see https://developer.apple.com/documentation/apptrackingtransparency
   * @todo Do it properly (show the cookie popup and respect user choice)
   */
  if (getIsReactNativeIos()) {
    iamUrl.searchParams.set('cmpnoscreen', '1');
  }

  window.location.href = iamUrl.href;
};

const logoutUrl = new URL('logout', SSO_BASE_URL);
logoutUrl.searchParams.set('client_id', SSO_CLIENT_ID);

/**
 * Log out user totally
 */
export const logoutInIAM = (): void => {
  signOut()
    .then(() => removeAllRoqTokens())
    .catch(() => reportError('Error signing out of ROQ chat'))
    .finally(() => {
      removeAuthCookies();
      postWebViewCookiesFlushEvent();

      window.location.href = logoutUrl.href;
    });
};
