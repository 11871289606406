/**
 * @file Functions to extract concierge labels
 */

import * as FIELD_TYPES from '../../constants/conciergeFieldTypes';
import translate from '../../i18n/translate';
import {
  ContactFormDataFieldRaw,
  DataConciergeFieldRaw,
} from '../../models/message';

import { getValueLocalized } from './record';

import getFieldType from './type';

/**
 * Field type -> fallback translation key mapping
 */
const fallbacks = new Map<FIELD_TYPES.ConciergeFieldType, string>([
  [FIELD_TYPES.DATE, 'FALLBACK__CONCIERGE__DATE'],
  [FIELD_TYPES.DATETIME, 'FALLBACK__CONCIERGE__DATETIME'],
  [FIELD_TYPES.DESCRIPTION, 'FALLBACK__CONCIERGE__DESCRIPTION'],
  [FIELD_TYPES.DIVIDER, 'FALLBACK__CONCIERGE__DIVIDER'],
  [FIELD_TYPES.DROPDOWN, 'FALLBACK__CONCIERGE__DROPDOWN'],
  [FIELD_TYPES.EMAIL, 'FALLBACK__CONCIERGE__EMAIL'],
  [FIELD_TYPES.FEEDBACK_EMOJI, 'FALLBACK__CONCIERGE__FEEDBACK_EMOJI'],
  [FIELD_TYPES.FEEDBACK_STARS, 'FALLBACK__CONCIERGE__FEEDBACK_STARS'],
  [FIELD_TYPES.RADIOBOX_GROUP, 'FALLBACK__CONCIERGE__RADIOBOX_GROUP'],
  [FIELD_TYPES.SUBMIT, 'FALLBACK__CONCIERGE__SUBMIT'],
  [FIELD_TYPES.SUBTITLE, 'FALLBACK__CONCIERGE__SUBTITLE'],
  [FIELD_TYPES.TEXT, 'FALLBACK__CONCIERGE__TEXT'],
  [FIELD_TYPES.TEXT_AREA, 'FALLBACK__CONCIERGE__TEXT_AREA'],
  [FIELD_TYPES.TIME, 'FALLBACK__CONCIERGE__TIME'],
  [FIELD_TYPES.TITLE, 'FALLBACK__CONCIERGE__TITLE'],
]);

/**
 * Check if there is a fallback label for the specific field type,
 * And if not, just use a generic field label
 *
 * @param type Concierge field type
 * @returns    The label to use
 */
const getLabelFallback = (type: FIELD_TYPES.ConciergeFieldType): string => {
  return translate(fallbacks.get(type) ?? 'FALLBACK__CONCIERGE__FIELD');
};

/**
 * Get the label to show in <th> for the concierge table
 *
 * @param field The field to get the label for
 * @returns     The label to use
 */
export const getLabel = (field: ContactFormDataFieldRaw): string => {
  if (field.labelMain) {
    return field.labelMain;
  }

  if (field.labelSubmitted) {
    return field.labelSubmitted;
  }

  const type = parseInt(field.type, 10) as FIELD_TYPES.ConciergeFieldType;
  return getLabelFallback(type);
};

/**
 * Get the label to show in <dd> for the concierge table
 * for data coming in via Messaging API
 *
 * @param data               Raw field data
 * @param data.label         Raw label
 * @param data.typeId        Field type
 * @param submissionLanguage The language the form was submitted in
 * @returns                  The label to use
 */
export const getLabelMessagingApi = (
  { label, typeId }: DataConciergeFieldRaw,
  submissionLanguage?: string,
): string => {
  const value = getValueLocalized(label, submissionLanguage);

  if (value === null || value.length === 0) {
    return getLabelFallback(getFieldType(typeId));
  }

  return value;
};
