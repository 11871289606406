import { Avatar, AvatarProps, styled } from '@mui/material';

import { colorNeutral, colorPrimary } from '../../../styles/mui/colors';
import { colorNeutralBaseStyle } from '../../../styles/mui/components/shared';

type Props = {
  'data-is-inactive': boolean;
  'data-is-pending': boolean;
};

/**
 * Get styles for inactive state
 *
 * @param props Props passed to Avatar component
 * @returns     Styles or empty object
 */
const getInactiveStyles = (props: Props) => {
  if (props['data-is-inactive'] === false) {
    return {};
  }

  return {
    backgroundColor: colorNeutral[400],
    color: colorPrimary[500],
    filter: 'blur(0.7px)',
  };
};

/**
 * Get styles for pending activation state
 *
 * @param props Props passed to Avatar component
 * @returns     Styles or empty object
 */
const getPendingActivationStyles = (props: Props) => {
  if (props['data-is-pending'] === false) {
    return {};
  }

  return {
    backgroundColor: colorNeutralBaseStyle.backgroundColor,
    color: colorNeutralBaseStyle.color,
    filter: 'none',
  };
};

export const MuiAvatar = styled(Avatar)<AvatarProps & Props>(props => ({
  ...getInactiveStyles(props),
  ...getPendingActivationStyles(props),
}));
