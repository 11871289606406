/**
 * @file Helper functions related to user language preference
 */

import { I18N__FALLBACK_LANGUAGE } from '../../constants/forms';
import { Locale } from '../../generated/graphql';
import capitalize from '../string/capitalize';

/**
 * Gets default interface language
 *
 * @returns Language string
 */
export const getDefaultLanguageCode = (): Locale => {
  // Google lighthouse is accessing this code while the window is not defined.
  if (typeof window === 'undefined') {
    return I18N__FALLBACK_LANGUAGE as Locale;
  }

  const browserLanguage = window.navigator.language;

  const { language } = new Intl.Locale(browserLanguage);
  if (capitalize(language) in Locale) {
    return language as Locale;
  }

  return I18N__FALLBACK_LANGUAGE as Locale;
};

let currentCode = getDefaultLanguageCode();

/**
 * Set the currently used interface language
 *
 * @param code The code to set
 */
export const setLanguageCode = (code: Locale): void => {
  currentCode = code;
};

/**
 * Get the currently used interface language
 * (falls back to using language set in preferences.ts, I18N__FALLBACK_LANGUAGE key)
 *
 * @returns The language code (eg. 'de')
 */
export const getLanguageCode = (): Locale => {
  return currentCode;
};
