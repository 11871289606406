/**
 * @file contains functions used to create paths for use in Route related to the search page
 */
import { ROUTE__SEARCH } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the search page
 *
 * @returns The generated path
 */
export const createSearchPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SEARCH}`;
};

/**
 * Generate a path for the search text page
 *
 * @returns The generated path
 */
export const createSearchTextPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__SEARCH}/:searchTerm/:page`;
};
