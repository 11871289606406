import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import Icon, { NAMES } from '../Icon';

import ToastContent from './ToastContent';
import * as Styled from './styled';
import { Props } from './types';

const ICON_SIZE = 10;

/**
 * Component for alerting concise messages
 *
 * @param props                Props passed to the component
 * @param props.additionalText Text for collapsed section of toast message
 * @param props.isOpen         We use this prop to force toast showing on the screen
 * @param props.requestDelete  Function called on message close
 * @param props.shouldAnimate  Tells us which message wrapper should we use (with or without animation)
 * @param props.text           Message text
 * @param props.title          Message title
 * @param props.type           Message type
 * @returns                    The component itself
 */
const ToastMessage: FC<Props> = ({
  additionalText,
  isOpen,
  requestDelete = null,
  shouldAnimate,
  text,
  title,
  type,
}) => {
  /**
   * If toast message is being closed
   * this callback will remove message data from redux
   *
   * @param isToastOpen Is toast message open or its being closed
   */
  const onOpenChange = (isToastOpen: boolean) => {
    if (isToastOpen === false && requestDelete !== null) {
      requestDelete();
    }
  };

  const Component = shouldAnimate
    ? Styled.MessageWrapper
    : Styled.MessageWrapperNoAnimation;

  return (
    <Component
      data-message-type={type}
      onOpenChange={onOpenChange}
      open={isOpen}
    >
      <ToastContent
        additionalText={additionalText}
        text={text}
        title={title}
        type={type}
      />
      <Styled.MessageClose aria-label={translate('GENERAL__CLOSE')}>
        <Icon
          aria-hidden="true"
          height={ICON_SIZE}
          name={NAMES.TOAST__CLOSE}
          width={ICON_SIZE}
        />
      </Styled.MessageClose>
    </Component>
  );
};

export default ToastMessage;
