import { styled } from 'styled-components';

import { CssVariable } from '../../../styles/cssTypes';

import devices from '../../../styles/devices';

import RichText from '../../Common/RichText';

import TimeRelative from '../../Common/TimeRelative';

import {
  getBackgroundColor,
  TextProps,
  UsernameProps,
  WrapperProps,
} from './helpers';

/**
 * Get which color to use for the comment
 *
 * @param props Props passed to components
 * @returns     The variable to use
 */
const getColor = (props: TextProps | UsernameProps): CssVariable => {
  if (props['data-inactive']) {
    return 'var(--color-text-light)';
  }

  return 'var(--color-text-regular)';
};

// Wrapper around the entire comment
export const Wrapper = styled.article<WrapperProps>`
  background-color: ${getBackgroundColor};
  display: flex;
  padding: var(--spacing-small) var(--spacing-medium);

  @media ${devices.tabletS} {
    padding: var(--spacing-small);
    padding-left: 0;
  }
`;

// Wrapper around everything except avatar
export const Content = styled.div`
  margin-left: var(--spacing-small);
`;

// Comment metadata
export const Header = styled.header`
  display: flex;
  margin-bottom: var(--spacing-tiny);
`;

// Comment author
export const Username = styled.h4<UsernameProps>`
  color: ${getColor};
  font-weight: var(--font-weight-comment-author);
  margin-right: var(--spacing-tiny);
`;

// Comment post time
export const Date = styled(TimeRelative)`
  color: var(--color-text-light);
`;

// Comment contents
export const Text = styled(RichText)<TextProps>`
  color: ${getColor};
  overflow-wrap: anywhere;
`;
