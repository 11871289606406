import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamMessageEditMode from '../../../hooks/router/params/useParamMessageEditMode';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useIsMobile from '../../../hooks/useIsMobile';

import useIsTablet from '../../../hooks/useIsTablet';
import translate from '../../../i18n/translate';
import { getTopicUrl } from '../../../routes/urls/topic';

import ButtonTranslateContainer from '../../buttonTranslate';

import { mapping } from './helpers';

/**
 * Header component for message details page
 *
 * @returns The component itself
 */
const MessageDetailsHeader: FC = () => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const messageEditMode = useParamMessageEditMode();
  const messageId = useParamMessageId();
  const location = useLocation();

  if (isMobile === false) {
    return null;
  }

  const topicUrl = getTopicUrl(hotelId, location, topicId);

  const contentRightDefault =
    messageId !== null ? (
      // @todo Add the new styling for the button
      <ButtonTranslateContainer container="details" messageId={messageId} />
    ) : null;

  const defaultProps = {
    backUrl: topicUrl,
    contentRight: contentRightDefault,
    title: translate('GENERAL__DETAILS'),
  };

  const props =
    mapping.get(messageEditMode)?.(
      hotelId,
      isTablet,
      location,
      messageId,
      topicId,
    ) ?? defaultProps;

  return <BaseHeaderMobile {...props} />;
};

export default MessageDetailsHeader;
