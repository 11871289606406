import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import SplashScreen from '../../../components/SplashScreen';
import { MeDocument } from '../../../generated/graphql';
import { getDefaultHotelId } from '../../../utils/hotel';
import {
  createErrorAuthUserNotFoundPath,
  createErrorDeactivatedHotelPath,
} from '../../paths/error';

/**
 * Entry point route of the app /
 *
 * @returns Splash screen while loading, error page on error, redirection to default hotel otherwise
 */
const PageIndex: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });

  if (error) {
    return <Navigate to={createErrorAuthUserNotFoundPath()} />;
  }

  if (fetching) {
    return <SplashScreen />;
  }

  const defaultHotelId = getDefaultHotelId(data?.me ?? null);

  /**
   * Every user should have a default hotel ID, if they don't we consider this an
   * error. At the moment, the `MISSING_HOTEL` error screen is used, from the frontend.
   */
  if (defaultHotelId === null) {
    return <Navigate to={createErrorDeactivatedHotelPath()} />;
  }

  return <Navigate to={defaultHotelId} />;
};

export default PageIndex;
