import React, { FC, useId } from 'react';
import { useMutation } from 'urql';

import { MESSAGE_STATUS_TRANSLATION_KEY } from '../../../constants/message';
import {
  Message,
  MessageStatus as MessageStatusEnum,
  MessageStatusUpdateDocument,
} from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { reportApiErrors } from '../../../utils/error';
import Select from '../../Common/Select';

export type Props = {
  canUpdateStatus: boolean;
  className?: HTMLSelectElement['className'];
  messageId: Message['id'];
  status: MessageStatusEnum;
};

/**
 * Message status component
 *
 * @param props                 Props passed to the component
 * @param props.canUpdateStatus Whether the user can update status
 * @param props.className       styled-components generated class name, needed for styling Select
 * @param props.messageId       ID of the message
 * @param props.status          Status of the message
 * @returns                     The component itself
 */
const MessageStatus: FC<Props> = ({
  canUpdateStatus,
  className,
  messageId,
  status,
}) => {
  const selectId = useId();

  const [, messageStatusUpdateMutation] = useMutation(
    MessageStatusUpdateDocument,
  );

  /**
   * Reacts to dropdown selection change
   *
   * @param messageStatus Selected value in the dropdown
   */
  const onChange = (messageStatus: MessageStatusEnum) => {
    messageStatusUpdateMutation({
      id: messageId,
      status: messageStatus,
    })
      .then(result => reportApiErrors(result.error))
      .catch(reportApiErrors);
  };

  /**
   * Gets select label text
   *
   * @param value Value of the status
   * @returns     Translated label text
   */
  const getLabelText = (value: MessageStatusEnum): string => {
    return translate(MESSAGE_STATUS_TRANSLATION_KEY[value]);
  };

  /**
   * Create select items based on MessageStatus Enum from the backend
   */
  const items = Object.values(MessageStatusEnum).map(value => ({
    label: getLabelText(value),
    labelText: getLabelText(value),
    value,
  }));

  return (
    <Select
      className={className}
      defaultItem={
        status
          ? {
              label: getLabelText(status),
              labelText: getLabelText(status),
              value: status,
            }
          : undefined
      }
      disabled={canUpdateStatus === false}
      items={items}
      name="select-post-status"
      onChange={onChange}
      placeholder={translate('ARIA__MESSAGE_INFO__STATUS')}
      selectId={selectId}
    />
  );
};

export default MessageStatus;
