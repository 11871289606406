/**
 * @file contains redux actions related to debug state
 */

import {
  DEBUG__HAS_MESSAGES,
  DEBUG__NETWORK__COMMENTS__SET,
  DEBUG__NETWORK__COMPOSE__SET,
  DEBUG__NETWORK__MESSAGE_DETAILS_SET,
  DEBUG__NETWORK__MESSAGES_SET,
  DEBUG__NETWORK__QUICK_FILTERS__SET,
  DEBUG__NETWORK__TOPIC_BASIC__SET,
  DEBUG__NETWORK__TOPIC_DETAILS__SET,
  DEBUG__NETWORK__TOPICS__SET,
  DEBUG__PANEL__CLOSE,
  DEBUG__PANEL__OPEN,
  DEBUG__PANEL__TOGGLE,
} from '../../constants/actionTypes';
import { User } from '../../generated/graphql';
import {
  DebugActionHasMessages,
  DebugActionNetworkCommentsSet,
  DebugActionNetworkComposeSet,
  DebugActionNetworkMessageDetailsSet,
  DebugActionNetworkMessagesSet,
  DebugActionNetworkQuickFiltersSet,
  DebugActionNetworkTopicBasicSet,
  DebugActionNetworkTopicDetailsSet,
  DebugActionNetworkTopicsSet,
  DebugActionPanelClose,
  DebugActionPanelOpen,
  DebugActionPanelToggle,
} from '../../models/debug';
import { MessageFetchingDirection } from '../../models/message';

/**
 * Generate the action for overriding hasMessages flag from me query
 * (null for disabling the override)
 *
 * @param payload The value to set
 * @returns       The generated action
 */
export const setDebugHasMessages = (
  payload: User['hasMessages'] | null,
): DebugActionHasMessages => ({
  payload,
  type: DEBUG__HAS_MESSAGES,
});

/**
 * Generate the action for setting comments fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkComments = (
  payload: boolean,
): DebugActionNetworkCommentsSet => ({
  payload,
  type: DEBUG__NETWORK__COMMENTS__SET,
});

/**
 * Generate the action for setting compose data fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkCompose = (
  payload: boolean,
): DebugActionNetworkComposeSet => ({
  payload,
  type: DEBUG__NETWORK__COMPOSE__SET,
});

/**
 * Generate the action for setting network message details
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkMessageDetails = (
  payload: boolean,
): DebugActionNetworkMessageDetailsSet => ({
  payload,
  type: DEBUG__NETWORK__MESSAGE_DETAILS_SET,
});

/**
 * Generate the action for setting messages fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkMessages = (
  payload: MessageFetchingDirection,
): DebugActionNetworkMessagesSet => ({
  payload,
  type: DEBUG__NETWORK__MESSAGES_SET,
});

/**
 * Generate the action for setting quick filters fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkQuickFilters = (
  payload: boolean,
): DebugActionNetworkQuickFiltersSet => ({
  payload,
  type: DEBUG__NETWORK__QUICK_FILTERS__SET,
});

/**
 * Generate the action for setting topic basics fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkTopicBasics = (
  payload: boolean,
): DebugActionNetworkTopicBasicSet => ({
  payload,
  type: DEBUG__NETWORK__TOPIC_BASIC__SET,
});

/**
 * Generate the action for setting topic details fetching state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkTopicDetails = (
  payload: boolean,
): DebugActionNetworkTopicDetailsSet => ({
  payload,
  type: DEBUG__NETWORK__TOPIC_DETAILS__SET,
});

/**
 * Generate the action for setting topic fetching debug state
 *
 * @param payload The fetching state
 * @returns       The generated action
 */
export const setDebugNetworkTopics = (
  payload: boolean,
): DebugActionNetworkTopicsSet => ({
  payload,
  type: DEBUG__NETWORK__TOPICS__SET,
});

/**
 * Generate the action for setting debug panel state
 *
 * @returns The generated action
 */
export const closeDebugPanel = (): DebugActionPanelClose => ({
  type: DEBUG__PANEL__CLOSE,
});

/**
 * Generate the action for setting debug panel state
 *
 * @returns The generated action
 */
export const openDebugPanel = (): DebugActionPanelOpen => ({
  type: DEBUG__PANEL__OPEN,
});

/**
 * Generate the action for setting debug panel state
 *
 * @returns The generated action
 */
export const toggleDebugPanelState = (): DebugActionPanelToggle => ({
  type: DEBUG__PANEL__TOGGLE,
});
