/**
 * @file contains functions used to create paths for use in Route related to the dev panel
 */

import { ROUTE__DEV_PANEL } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for the more page
 *
 * @returns The generated path
 */
export const createDevPanelPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__DEV_PANEL}`;
};
