import React, { FC } from 'react';

import {
  getGroupsVisible,
  getMembersVisible,
} from '../../containers/compose/helpers';
import { ComposeNodeType } from '../../containers/compose/props';

import {
  User,
  UserBasicFragment,
  UserGroup,
  UserGroupBasicFragment,
} from '../../generated/graphql';
import useIsMobile from '../../hooks/useIsMobile';

import translate from '../../i18n/translate';
import InputSearch from '../Common/InputSearch';

import AssigneesGroups from './AssigneesGroups';
import AssigneesMembers from './AssigneesMembers';
import AssignmentNoResult from './AssignmentNoResult';
import {
  getIsEmpty,
  getIsSearching,
  getShouldShowNoResults,
  getShouldShowSearchBox,
  getShouldShowSeparator,
} from './helpers';

import * as Styled from './styled';

export type Props = {
  dataGroupsAll: UserGroupBasicFragment[];
  dataGroupsAssigned: Set<UserGroup['id']>;
  dataMembersAll: UserBasicFragment[];
  dataMembersAssigned: Set<User['id']>;
  dataSearchValue: string;
  isLoading: boolean;
  nodeType?: ComposeNodeType;
  onAssignUpdateGroup: (groupId: UserGroup['id'], state: boolean) => void;
  onAssignUpdateMember: (memberId: User['id'], state: boolean) => void;
  onSearch: (term: string) => void;
};

/**
 * Assignment panel,
 * Consists of a filter box,
 * Members list (with a checkbox for each of them)
 * and group list (where each item is a toggle itself)
 *
 * @param props                      Props passed to the component
 * @param props.dataGroupsAll        All available groups
 * @param props.dataGroupsAssigned   Groups checked
 * @param props.dataMembersAll       All available members
 * @param props.dataMembersAssigned  Members checked
 * @param props.dataSearchValue      <input type="search" /> value attribute
 * @param props.isLoading            Whether the data is being fetched
 * @param props.nodeType             Whether the compose form is being used for messages or templates
 * @param props.onAssignUpdateGroup  Callback to invoke when a group is (un)checked
 * @param props.onAssignUpdateMember Callback to invoke when a member is (un)checked
 * @param props.onSearch             Callback to invoke when search term is changed
 * @returns                          The component itself
 */
const Assignment: FC<Props> = ({
  dataGroupsAll,
  dataGroupsAssigned,
  dataMembersAll,
  dataMembersAssigned,
  dataSearchValue,
  isLoading,
  nodeType = 'MESSAGE',
  onAssignUpdateGroup,
  onAssignUpdateMember,
  onSearch,
}) => {
  const isMobile = useIsMobile();

  const dataGroupsVisible = getGroupsVisible(dataGroupsAll, {
    assigneeGroupsCurrent: dataGroupsAssigned,
    assigneeSearch: dataSearchValue,
  });
  const dataMembersVisible = getMembersVisible(dataMembersAll, {
    assigneeMembersCurrent: dataMembersAssigned,
    assigneeSearch: dataSearchValue,
  });

  const isEmpty = getIsEmpty(dataGroupsVisible, dataMembersVisible);
  const isSearching = getIsSearching(dataSearchValue);
  const shouldShowSeparator = getShouldShowSeparator(
    dataGroupsVisible,
    dataMembersVisible,
  );
  const shouldShowSearchbox = getShouldShowSearchBox(isEmpty, isSearching);
  const shouldShowNoResults = getShouldShowNoResults(
    isEmpty,
    isLoading,
    isSearching,
  );

  if (shouldShowNoResults) {
    return <AssignmentNoResult nodeType={nodeType} />;
  }

  return (
    <>
      {shouldShowSearchbox && (
        <Styled.Content>
          <InputSearch
            label={translate('ASSIGNMENT__SEARCH_LABEL')}
            onChange={onSearch}
            placeholder={translate('ASSIGNMENT__SEARCH_PLACEHOLDER')}
            /**
             * @todo Find out why auto-focus is breaking layout on mobile (iOS) and reenable auto-focus on mobile when that is fixed
             * @see https://gastfreund.atlassian.net/browse/HOT-1736
             */
            shouldFocus={isMobile === false}
            value={dataSearchValue}
          />
        </Styled.Content>
      )}
      <Styled.Content>
        <AssigneesMembers
          dataAssigned={dataMembersAssigned}
          dataVisible={dataMembersVisible}
          isLoading={isLoading}
          isSearching={isSearching}
          onChange={onAssignUpdateMember}
        />
      </Styled.Content>
      {shouldShowSeparator === true && <Styled.Hr />}
      <Styled.Content>
        <AssigneesGroups
          dataAssigned={dataGroupsAssigned}
          dataVisible={dataGroupsVisible}
          isLoading={isLoading}
          isSearching={isSearching}
          onChange={onAssignUpdateGroup}
        />
      </Styled.Content>
    </>
  );
};

export default Assignment;
