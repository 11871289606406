/**
 * @file Fetching template data
 */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import {
  ChecklistItem,
  Template,
  TemplateDocument,
} from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../hooks/useIsTablet';
import { getSettingsTemplatesUrl } from '../../../routes/urls/settings';
import { reportError } from '../../../services/reporting';
import {
  clearDraftRequest,
  populateDraftRequest,
} from '../../../store/actions/draftsMain';
import { ComposeNodeType } from '../props';

// Error message that occurs when a template in the URL was deleted
const errorDeletedTemplate = '[GraphQL] Template not found';

/**
 * Fetch data needed for the compose form and initialize a draft in Redux store
 *
 * @param nodeId   Template id (if editing a template, null otherwise)
 * @param nodeType Whether the compose form is for a message or a template
 * @returns        Array of existing checklist item IDs or null
 */
const useComposeData = (
  nodeId: Template['id'] | null,
  nodeType: ComposeNodeType,
): ChecklistItem['id'][] | null => {
  const dispatch = useDispatch();
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const navigate = useNavigate();

  const [existingItemIds, setExistingItemIds] = useState<
    ChecklistItem['id'][] | null
  >(null);
  const isEditingTemplate = nodeType === 'TEMPLATE' && nodeId !== null;
  const urlTemplates = getSettingsTemplatesUrl(hotelId, isTablet);

  const [{ data, error, fetching }] = useQuery({
    pause: isEditingTemplate === false,
    query: TemplateDocument,
    variables: { templateId: nodeId ?? '' },
  });

  // We need fetching === false because error value stays during fetching
  const shouldClear =
    fetching === false && error?.message === errorDeletedTemplate;

  // The user is creating a new message/template, so we init an empty draft
  useEffect(() => {
    if (nodeId === null) {
      dispatch(clearDraftRequest(nodeType));
    }
  }, [dispatch, nodeId, nodeType]);

  // We retrieved data needed, so we populate the draft with what's received
  useEffect(() => {
    if (data?.template.id === nodeId) {
      dispatch(populateDraftRequest(nodeType, data.template));

      setExistingItemIds(data.template.checklist.map(item => item.id));
    }
  }, [data?.template, dispatch, nodeId, nodeType]);

  // We got "template not found" message
  // meaning that the template being edited was deleted
  // So we clear the draft and redirect to template settings page
  useEffect(() => {
    if (shouldClear) {
      dispatch(clearDraftRequest(nodeType));
      navigate(urlTemplates);
    }
  }, [dispatch, navigate, nodeType, shouldClear, urlTemplates]);

  if (error && error.message !== errorDeletedTemplate) {
    reportError(error);
  }

  return existingItemIds;
};

export default useComposeData;
