import React from 'react';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';

import translate from '../../../i18n/translate';
import MobileFullViewNavigation from '../../MUI/MobileFullViewNavigation';

import { generateLinks, generateLinksExternal } from './links';

/**
 * Settings navigation for mobile
 *
 * @returns The component itself
 */
const SettingsNavigationMobile = () => {
  const hotelId = useParamHotelId();
  // Links are the same for mobile and tablet devices
  const linksInternal = generateLinks(hotelId, true, true);
  const linksExternal = generateLinksExternal();

  return (
    <MobileFullViewNavigation
      links={linksInternal
        .concat(...linksExternal)
        .map(item => ({ ...item, label: translate(item.label) }))}
    />
  );
};

export default SettingsNavigationMobile;
