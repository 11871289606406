import React, { FC } from 'react';

import translate from '../../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  formId: string;
  isSaveDisabled: boolean;
  labelConfirm: string;
  requestCancel?: () => void;
};

/**
 * Footer for compose
 *
 * @param props                Props passed to the component
 * @param props.formId         id attribute for the form related to submit button
 * @param props.isSaveDisabled Should save button be disabled
 * @param props.labelConfirm   The text to show for confirm label
 * @param props.requestCancel  Callback for cancel button
 * @returns                    The component itself
 */
const ComposeFooter: FC<Props> = ({
  formId,
  isSaveDisabled,
  labelConfirm,
  requestCancel,
}) => {
  const labelCancel = translate('GENERAL__CANCEL');

  return (
    <Styled.Footer>
      {requestCancel && (
        <Styled.ButtonCancel
          formId={formId}
          isDisabled={isSaveDisabled}
          onClick={requestCancel}
          type="button"
        >
          {labelCancel}
        </Styled.ButtonCancel>
      )}
      <Styled.ButtonSave
        formId={formId}
        isDisabled={isSaveDisabled}
        type="submit"
      >
        {labelConfirm}
      </Styled.ButtonSave>
    </Styled.Footer>
  );
};

export default ComposeFooter;
