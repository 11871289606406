import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  className?: string;
  numberOfLines: number;
};

/**
 * Column that lists skeleton lines that represent loading state
 *
 * @param props               Props passed to the component
 * @param props.className     styled-components generated class name, needed for styling
 * @param props.numberOfLines Number of lines in the column
 * @returns                   The component itself
 */
const SkeletonList: FC<Props> = ({ className, numberOfLines }) => {
  return (
    <Styled.Wrapper className={className}>
      {Array.from({ length: numberOfLines }).map((column, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Styled.SkeletonLine key={index} />;
      })}
    </Styled.Wrapper>
  );
};

export default SkeletonList;
