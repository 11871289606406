/**
 * @file All vertical breakpoint values we use
 */

import Device from '../models/device';

/**
 * All vertical breakpoint values we use
 * Sorted by screen size, not alphabetically
 *
 * NOTE: The mobile values are with 128px removed for browser UI
 *
 * @see https://yesviz.com/devices.php
 */
/* eslint-disable sort-keys */
const breakpointsVertical: Record<Required<Device>, number> = {
  mobileS: 539, // iPhone SE 2nd gen (667 - 128)
  mobileM: 724, // iPhone 15 (852 - 128)
  mobileL: 804, // iPhone 15 Plus (932 - 128)

  tabletS: 1024, // iPad
  tabletM: 1180, // iPad Air
  tabletL: 1366, // iPad Pro

  laptopS: 768, // cheaper 13"
  laptopM: 900, // more expensive 13"
  laptopL: 1080, // 15-16"

  desktopS: 1080, // full hd
  desktopM: 1440, // 2K
  desktopL: 2160, // 4K
};

export default breakpointsVertical;
