import { styled } from 'styled-components';

import { visuallyHidden } from '../../../../../styles/common';

// Toggle that controls whether to show styling buttons
// (on phone)
export const Toggle = styled.input`
  ${visuallyHidden}
`;

// Label that toggles Toggle
// (phones only; for larger screens all buttons are always visible)
export const Label = styled.label`
  font-size: var(--font-baseline);
  margin-right: var(--spacing-small);

  /**
   * In order to prevent jumping content of controls section padding is added.
   * As a consequence of that, content is always the same height.
   */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  padding: 6px 0;

  ${Toggle}:focus + & {
    border: var(--border-outline);
  }
`;

// Wrapper for all buttons
export const Ul = styled.ul`
  align-items: center;
  background-color: var(--color-background-tools);
  display: inline-flex;
  margin-right: var(--spacing-small);

  ${Toggle}:not(:checked) ~ & {
    display: none;
  }
`;

// A single button's wrapper
export const Li = styled.li`
  &:not(:last-child) {
    margin-right: var(--spacing-small);
  }
`;
