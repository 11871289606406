import * as Toast from '@radix-ui/react-toast';
import React, { FC, useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';

import ToastMessage from '../../components/Common/ToastMessage';
import useIsMobile from '../../hooks/useIsMobile';
import AppState from '../../models/state';
import { ToastMessageModel } from '../../models/toastMessage';

import mapDispatchToProps from './mapDispatchToProps';
import { Props, StateProps } from './props';

import * as Styled from './styled';

/**
 * User needs to swipe for 50px in order
 * to close toast message
 */
const swipeThreshold = 50;

/**
 * Long message duration in order to keep it displayed
 */
const longDuration = Number.MAX_SAFE_INTEGER;

/**
 * Container for toast messages.
 *
 * @param props                           Props passed to the component
 * @param props.requestRemoveToastMessage Action creator that gets triggered on message close
 * @param props.toastMessages             List of toast messages from redux
 * @param props.toastMessageDuration      Duration for which toast message will be shown
 * @returns                               The component itself
 */
const ToastMessages: FC<Props> = ({
  requestRemoveToastMessage,
  toastMessages,
  toastMessageDuration,
}) => {
  const [isFirst, setIsFirst] = useState(true);

  const toastFirst = toastMessages[0] ?? null;
  const toastSecond = toastMessages[1] ?? null;
  const isMessageArrayEmpty = toastMessages.length === 0;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMessageArrayEmpty) {
      /**
       * If last message of the current array of messages is displayed,
       * isFirst is again set to true because we want to animate (slideIn)
       * the first message from next array of messages
       */
      setIsFirst(true);
    }
  }, [isMessageArrayEmpty]);

  if (!isMobile) {
    /**
     * Numbers for duration and swipeThreshold properties comes from
     * researching on this subject
     * (how long should toast message be shown for the user and what swipe threshold should be)
     */
    return (
      <Toast.Provider
        duration={toastMessageDuration}
        swipeThreshold={swipeThreshold}
      >
        {toastMessages.map(
          ({ additionalText, id, title, text, type }: ToastMessageModel) => (
            <ToastMessage
              additionalText={additionalText}
              key={id}
              requestDelete={() => requestRemoveToastMessage(id)}
              shouldAnimate={true}
              text={text}
              title={title}
              type={type}
            />
          ),
        )}
        <Styled.MessageViewport />
      </Toast.Provider>
    );
  }

  return (
    /**
     * Order of first and second toast is important because of position inside html
     * on which proper functioning depends.
     * Also, requestDelete is omitted because we don't want to let
     * ToastMessage to affect the redux state (toast is shown only to provide
     * illusion of stacked messages - but there are only two of them)
     */
    <>
      {toastSecond !== null && (
        <Toast.Provider duration={longDuration} swipeThreshold={swipeThreshold}>
          <ToastMessage
            additionalText={toastSecond.additionalText}
            isOpen={true}
            key={toastSecond.id}
            shouldAnimate={false}
            text={toastSecond.text}
            title={toastSecond.title}
            type={toastSecond.type}
          />
          <Styled.MessageViewport />
        </Toast.Provider>
      )}
      {toastFirst !== null && (
        <Toast.Provider
          duration={toastMessageDuration}
          swipeThreshold={swipeThreshold}
        >
          <ToastMessage
            additionalText={toastFirst.additionalText}
            key={toastFirst.id}
            requestDelete={() => {
              requestRemoveToastMessage(toastFirst.id);
              setIsFirst(false);
              /**
               * If toast is manually deleted element that gets focus after
               * that is <Styled.MessageViewport /> which has tabIndex=-1
               * and that breaks the timer of next toast message which will stay on the screen until
               * user manually removes it. So focus is returned on <body/> to prevent that behavior.
               * (this won't brake the 'native' toast behavior that timer pauses after
               * window in which app is open isn't focused)
               */
              if (
                document.activeElement instanceof HTMLElement &&
                document.activeElement?.tabIndex < 0
              ) {
                document.activeElement.blur();
                document.body.focus();
              }
            }}
            shouldAnimate={isFirst}
            text={toastFirst.text}
            title={toastFirst.title}
            type={toastFirst.type}
          />
          <Styled.MessageViewport />
        </Toast.Provider>
      )}
    </>
  );
};

/**
 * Redux map state to props function
 *
 * @param state Entire redux app state
 * @returns     Props for a react component derived from redux state
 */
const mapStateToProps: MapStateToProps<
  StateProps,
  Record<string, never>,
  AppState
> = state => ({
  toastMessageDuration: state.toastMessageDuration,
  toastMessages: state.toastMessages,
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);
