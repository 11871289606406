import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'urql';

import QuickFilters from '../../components/QuickFilters';
import {
  SearchQueriesDocument,
  SearchQueryType,
} from '../../generated/graphql';
import { reportError } from '../../services/reporting';

import Error from './Error';
import mapStateToProps from './mapStateToProps';
import { Props } from './props';

/**
 * Quick Filters component that renders links for ES
 *
 * @param props                     Props passed to the container
 * @param props.networkQuickFilters Whether debugging for quick filter state is enabled
 * @returns                         Topic filters links component
 */
const QuickFiltersContainer: FC<Props> = ({ networkQuickFilters }) => {
  const [{ data, fetching, error }, executeQuery] = useQuery({
    query: SearchQueriesDocument,
    variables: { type: SearchQueryType.QuickFilter },
  });

  /**
   * Handles click/tap on reload button inside Error component
   * Tries to fetch smart views
   */
  const handleReload = () => {
    executeQuery({ requestPolicy: 'cache-and-network' });
  };

  if (error) {
    reportError(error);
    return <Error onReload={handleReload} />;
  }

  const isLoading = fetching || networkQuickFilters;
  const quickFilters = data?.searchQueries ?? [];

  return <QuickFilters isLoading={isLoading} quickFilters={quickFilters} />;
};

export default connect(mapStateToProps)(QuickFiltersContainer);
