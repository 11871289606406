import React, { FC } from 'react';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamSmartView from '../../../hooks/router/params/useParamSmartView';
import useIsTablet from '../../../hooks/useIsTablet';
import { getSmartViewsSmartViewUrl } from '../../../routes/urls/smartViews';
import { SmartViewsLink } from '../../../utils/smartViews';
import DrawerEntry from '../../MUI/Drawer/DrawerContent/DrawerEntry';

import * as Styled from '../styled';

export type Props = {
  smartViewsLinks: SmartViewsLink[];
};

/**
 * List of items for the SmartViews navigation content
 *
 * @param props                 Props passed to the component
 * @param props.smartViewsLinks Array of smart view links
 * @returns                     The component itself
 */
const SmartViewsNavigation: FC<Props> = ({ smartViewsLinks }) => {
  const currentSmartView = useParamSmartView();
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();

  return (
    <Styled.ScrollableList>
      {smartViewsLinks.map(({ id, label }) => {
        return (
          <DrawerEntry
            href={getSmartViewsSmartViewUrl(hotelId, id, isTablet)}
            isSelected={currentSmartView === id}
            key={id}
            label={label}
          />
        );
      })}
    </Styled.ScrollableList>
  );
};

export default SmartViewsNavigation;
