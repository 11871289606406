import { styled } from 'styled-components';

import Button from '../Button';
import CommonButtonIconWithText from '../ButtonIconWithText';

export const ButtonDelete = styled(Button)`
  border-radius: var(--border-radius-small);
  color: var(--color-button-danger);
  padding: var(--spacing-small) var(--spacing-medium);
`;

export const ButtonIconWithText = styled(CommonButtonIconWithText)`
  justify-content: space-between;
  width: 100%;
`;
