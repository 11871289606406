import React, { FC } from 'react';

import { UserGroupBasicFragment } from '../../../generated/graphql';

import * as Styled from './styled';

export type Props = {
  className?: string;
  group: UserGroupBasicFragment;
};

/**
 * A single user group
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.group     The group to show
 * @returns               The component itself
 */
const GroupSingle: FC<Props> = ({ className, group }) => {
  return <Styled.Title className={className}>{group.title}</Styled.Title>;
};

export default GroupSingle;
