/**
 * @file Get the operating system the app is running on
 */

type Category = 'Android' | 'iPad' | 'iPhone' | 'Linux' | 'macOS' | 'Windows';

type Info = Record<Category, boolean>;

const ua = navigator.userAgent;

/**
 * Some common device targets
 */
const deviceInfo: Info = {
  Android: /Android/.test(ua),
  Linux: /Linux/.test(ua),
  Windows: /Win/.test(ua),
  iPad: /iPad/.test(ua),
  iPhone: /iPhone/.test(ua),
  macOS: /Macintosh/.test(ua),
};

/**
 * Check if app is running in WebView on Android
 */
export const isWebViewAndroid = /\bAndroid\b.*?\bwv\b.*?$/.test(ua);

/**
 * Check if app is running in WebView on iOS
 */
export const isWebViewIos =
  /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(ua);

export default deviceInfo;
