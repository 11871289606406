import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import { NAMES } from '../Icon';

import * as Styled from './styled';

type Props = {
  className?: string;
  onClick: () => void;
};

/**
 * Button wrapper that returns reused Button with + (plus) as its content
 *
 * @param props           The props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.onClick   Button click callback, can be omitted when type is submit
 * @returns               The component itself
 */
const ButtonAdd: FC<Props> = ({ className, onClick }) => {
  return (
    <Styled.ButtonAdd
      className={className}
      color="var(--color-background)"
      iconName={NAMES.GENERAL__PLUS}
      label={translate('GENERAL__ADD')}
      onClick={onClick}
    />
  );
};

export default ButtonAdd;
