/**
 * @file Hook for detecting dark mode
 */

import useMatchMedia from './useMatchMedia';

/**
 * Hook for detecting dark mode
 *
 * @returns Whether the user has enabled dark mode
 */
const usePrefersDarkMode = (): boolean => {
  return useMatchMedia('(prefers-color-scheme: dark)');
};

export default usePrefersDarkMode;
