import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import SkeletonMessages from '../Messages';

type Props = {
  nrComments?: number;
};

/**
 * Skeleton view for comments,
 * visible in the feed
 *
 * @param props            Props passed to the component
 * @param props.nrComments How many comments should we show the skeleton for
 * @returns                The component itself
 */
const SkeletonComments: FC<Props> = ({ nrComments = 3 }) => {
  return (
    <SkeletonMessages
      avatarSize={28}
      color="--color-background"
      description={translate('LOADING__COMMENTS')}
      nrMessages={nrComments}
      shouldShowSkeleton={false}
    />
  );
};

export default SkeletonComments;
