import React, { FC } from 'react';

import ComposeMetaAttachments from '../../../components/Compose/Common/ComposeMeta/ComposeMetaAttachments';
import MetaAttachment from '../../../components/MetaAttachment';
import useAttachmentsUpload from '../../../hooks/attachments/useAttachmentsUpload';
import useIsMobile from '../../../hooks/useIsMobile';
import { ComposeModeParam } from '../../../models/pageParams';
import useComposeLinking from '../hooks/useComposeLinking';
import { ComposeNodeType } from '../props';

type Props = {
  nodeType: ComposeNodeType;
};

/**
 * Attachments container for the compose form
 *
 * @param props          Props passed to the container
 * @param props.nodeType Whether the compose form is being used for messages or templates
 * @returns              The container itself
 */
const ComposeMetaAttachmentsContainer: FC<Props> = ({ nodeType }) => {
  const { attachmentsFormatted, handleFileRemove, inputProps } =
    useAttachmentsUpload(nodeType);

  const isMobile = useIsMobile();
  const mode: ComposeModeParam = 'attach';
  const { isActive, linkToggleProps } = useComposeLinking(mode);

  // On large screens, it's shown as an editor control, and files are within textarea
  if (isMobile === false) {
    return null;
  }

  return (
    <MetaAttachment
      dataAttachments={attachmentsFormatted}
      dataInputFileProps={inputProps}
      inputCustom={
        <ComposeMetaAttachments attachments={attachmentsFormatted} />
      }
      isActive={isActive}
      linkToggleProps={linkToggleProps}
      requestAttachmentRemove={handleFileRemove}
    />
  );
};

export default ComposeMetaAttachmentsContainer;
