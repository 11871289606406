import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import { getComposeTitle } from '../../../components/Compose/helpers';
import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useComposeMode from '../../../hooks/router/search/useComposeMode';
import useIsMobile from '../../../hooks/useIsMobile';
import { getCloseComposeUrl } from '../../../routes/urls/searchParams/compose';
import { ComposeNodeId, ComposeNodeType } from '../../compose/props';

import { mapping } from './helpers';

type Props = {
  nodeId: ComposeNodeId;
  nodeType: ComposeNodeType;
};

/**
 * Header for the compose form
 *
 * @param props          Props passed to the component
 * @param props.nodeId   Message ID
 * @param props.nodeType Node type
 * @returns              The component itself
 */
const ComposeHeader: FC<Props> = ({ nodeId, nodeType }) => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const composeMode = useComposeMode();

  if (isMobile === false) {
    return null;
  }

  const composeTitle = getComposeTitle(nodeId, nodeType);

  const defaultProps = {
    backUrl: getCloseComposeUrl(location),
    contentRight: null,
    title: composeTitle,
  };

  const props =
    mapping.get(composeMode)?.(location, nodeId, nodeType) ?? defaultProps;

  return <BaseHeaderMobile {...props} />;
};

export default ComposeHeader;
