import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__ASSIGNEE } from '../../../constants/routing/hashLinks';

import { MessageCommon } from '../../../models/message';

import Icon, { NAMES } from '../Icon';

import MessageInfo from './MessageInfo';
import { getGroupsPlural, getGroupsText } from './helpers';

type Props = {
  assignedGroups: MessageCommon['assignedGroups'];
  className?: string;
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

const iconHeight = 17;
const iconWidth = 28;

/**
 * Shows groups assigned to message
 *
 * @param props                Props passed to the component
 * @param props.assignedGroups Groups assigned to message
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.messageId      Message id
 * @param props.shouldBeLink   Whether info should be rendered as a link or not
 * @returns                    The component itself
 */
const MessageInfoGroups: FC<Props> = ({
  assignedGroups,
  className,
  messageId,
  shouldBeLink,
}) => {
  return (
    <MessageInfo
      className={className}
      elementId={HASH_MESSAGE__INFO__ASSIGNEE}
      labelKey="ARIA__MESSAGE_INFO__GROUPS"
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      <Icon
        color="var(--color-blue-dark)"
        height={iconHeight}
        name={NAMES.MESSAGE__INFO__GROUPS}
        width={iconWidth}
      />
      <Tippy content={getGroupsPlural(assignedGroups)} touch={false}>
        <p>{getGroupsText(assignedGroups.length)}</p>
      </Tippy>
    </MessageInfo>
  );
};

export default MessageInfoGroups;
