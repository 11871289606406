/**
 * @file Set of helper functions
 */

import {
  MESSAGE__TEXT_REQUIRED,
  MESSAGE__TITLE_REQUIRED,
} from '../../constants/forms';
import {
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../generated/graphql';
import { DraftDataFormatted } from '../../models/draft';
import { DraftAttachmentDataFormatted } from '../../models/draftAttachment';
import searchGroups from '../../utils/members/filterGroups';
import { searchMembers } from '../../utils/members/filterMembers';

import { ComposeNodeType } from './props';

type DraftGroups = {
  assigneeSearch: string;
  assigneeGroupsCurrent: Set<string>;
};

type DraftMembers = {
  assigneeSearch: string;
  assigneeMembersCurrent: Set<string>;
};

/**
 * Get whether there's an attachment that's being uploaded
 *
 * @param draftAttachments Draft attachments data
 * @returns                Whether an attachment is being uploaded
 */
const getIsUploading = (
  draftAttachments?: DraftAttachmentDataFormatted,
): boolean => {
  if (draftAttachments === undefined) {
    return true;
  }

  return Array.from(draftAttachments.attachments.values()).some(
    attachment => attachment.isUploading === true,
  );
};

/**
 * Get which groups should be visible
 *
 * @param groupsAll All groups that can be assigned to the message
 * @param draft     Draft data
 * @returns         All the groups to show
 */
export const getGroupsVisible = (
  groupsAll: UserGroupBasicFragment[],
  draft: DraftGroups,
): UserGroupBasicFragment[] => {
  if (draft.assigneeSearch === '' && draft.assigneeGroupsCurrent.size === 0) {
    return groupsAll;
  }

  return searchGroups(
    draft.assigneeSearch,
    groupsAll,
    draft.assigneeGroupsCurrent,
  );
};

/**
 * Get which members should be visible
 *
 * @param membersAll All members that can be assigned to the message
 * @param draft      Draft data
 * @returns          All the members to show
 */
export const getMembersVisible = (
  membersAll: UserBasicFragment[],
  draft: DraftMembers,
): UserBasicFragment[] => {
  if (draft.assigneeSearch === '' && draft.assigneeMembersCurrent.size === 0) {
    return membersAll;
  }

  return searchMembers(
    draft.assigneeSearch,
    membersAll,
    draft.assigneeMembersCurrent,
  );
};

/**
 * Get whether we should disable message submit button for message compose
 *
 * @param draft       Draft textual data
 * @param isUploading Whether there's a file being uploaded
 * @returns           Whether to disable the button
 */
const getShouldDisableSubmitMessage = (
  draft: DraftDataFormatted,
  isUploading: boolean,
): boolean => {
  const { text, title } = draft;

  return (
    isUploading ||
    (MESSAGE__TEXT_REQUIRED && text.trim().length === 0) ||
    (MESSAGE__TITLE_REQUIRED && title.trim().length === 0)
  );
};

/**
 * Get whether we should disable message submit button for template compose
 *
 * @param draft       Draft textual data
 * @param isUploading Whether there's a file being uploaded
 * @returns           Whether to disable the button
 */
const getShouldDisableSubmitTemplate = (
  draft: DraftDataFormatted,
  isUploading: boolean,
): boolean => {
  return (
    getShouldDisableSubmitMessage(draft, isUploading) ||
    draft.templateTopicSaved === null
  );
};

/**
 * Get whether we should disable message submit button
 *
 * @param nodeType            Whether it's message ot template
 * @param draft               Draft textual data
 * @param draftAttachments    Draft attachments data
 * @param shouldDisableSubmit Passed through component props
 * @returns                   Whether to disable the button
 */
export const getShouldDisableSubmit = (
  nodeType: ComposeNodeType,
  draft?: DraftDataFormatted,
  draftAttachments?: DraftAttachmentDataFormatted,
  shouldDisableSubmit?: boolean,
): boolean => {
  if (draft === undefined || shouldDisableSubmit) {
    return true;
  }
  const isUploading = getIsUploading(draftAttachments);

  if (nodeType === 'MESSAGE') {
    return getShouldDisableSubmitMessage(draft, isUploading);
  }

  return getShouldDisableSubmitTemplate(draft, isUploading);
};
