/**
 * @file URL helpers related to kanban board
 */
import { Location } from 'react-router-dom';

import { ROUTE__KANBAN } from '../../constants/routing/routes';
import { Hotel, Topic } from '../../generated/graphql';

import { getNoMatchUrl } from './404';
import { getHomeUrl } from './home';

/**
 * Gets url that opens kanban page for a topic
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from the history or null if we don't want search params to be kept
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicKanbanUrl = (
  hotelId: Hotel['id'] | null,
  location: Location | null,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicKanbanUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicKanbanUrl was null');
    return getHomeUrl(hotelId);
  }

  const search = location?.search ?? '';

  return `/${hotelId}/${ROUTE__KANBAN}/${topicId}${search}`;
};
