/**
 * @file Hotelboard <=> Firebase communication
 * At the moment, we're using Firebase for push notifications
 */

import { getToken } from '@firebase/messaging';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import { FIREBASE_OPTIONS, FIREBASE_VAPID_KEY } from '../../config';
import { checkServiceWorkerSupport } from '../../serviceWorker';
import { reportApiErrors } from '../error';
import { getTokenStored, setTokenStored } from '../storage/notifications';

/**
 * Initialize Hotelboard to use Firebase
 *
 * @returns The Firebase app
 */
const initFirebase = (): FirebaseApp => {
  try {
    return getApp();
  } catch {
    return initializeApp(FIREBASE_OPTIONS);
  }
};

/**
 * Get Firebase token for PWA
 *
 * @returns The token or null in case of an error
 */
export const getFirebaseToken = async (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    // There are multiple async calls within,
    // But we need all this to be treated like one block
    // (calling getToken multiple times will yield errors)
    navigator.locks
      .request('firebase-token', async () => {
        const storedToken = getTokenStored();
        if (storedToken !== null) {
          return resolve(storedToken);
        }

        if (checkServiceWorkerSupport() === false) {
          return reject('Service worker unavailable');
        }

        const registration = await navigator.serviceWorker.ready;
        initFirebase();
        const messaging = getMessaging();

        const token = await getToken(messaging, {
          serviceWorkerRegistration: registration,
          vapidKey: FIREBASE_VAPID_KEY,
        });

        setTokenStored(token);
        resolve(token);
      })
      .catch(reportApiErrors);
  });
};
