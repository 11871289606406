import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

/**
 * Button used for text translation
 *
 * @returns The component itself
 */
const SpinnerDots: FC = () => {
  return (
    <Styled.Wrapper
      aria-busy="true"
      aria-live="polite"
      aria-valuetext={translate('ARIA__LABEL__TRANSLATING')}
      role="progressbar"
    >
      <Styled.Dot1 />
      <Styled.Dot2 />
      <Styled.Dot3 />
    </Styled.Wrapper>
  );
};

export default SpinnerDots;
