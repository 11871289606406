/**
 * @file Hook for handling comment list cursor change
 */

import { useEffect, useState } from 'react';

import { PREFERENCE__COMMENTS__LIMIT } from '../../../constants/pagination';
import { Comment } from '../../../models/comment';

/**
 * Hook for handling comment list cursor change
 *
 * @param comments     The array of comments
 * @param isNewComment Whether new comment was created by the user
 * @returns            Current comment cursor
 */
const useCommentsCurrentCursor = (
  comments: Comment[],
  isNewComment: boolean,
) => {
  const [currentCursor, setCurrentCursor] = useState<string>(
    comments[comments.length - 1]?.id ?? '',
  );

  useEffect(() => {
    if (isNewComment) {
      setCurrentCursor(comments[comments.length - 1]?.id ?? '');
      return;
    }

    const newCursor = comments[PREFERENCE__COMMENTS__LIMIT - 1]?.id;
    if (currentCursor !== newCursor) {
      setCurrentCursor(newCursor ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  return currentCursor;
};

export default useCommentsCurrentCursor;
