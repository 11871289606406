/**
 * @file Make a deep copy of drafts state data
 */

import { DraftDataFormatted, DraftsState } from '../models/draft';

/**
 * Make a deep copy of drafts state data
 *
 * @param stateOriginal The state we want to clone
 * @returns             The cloned state
 */
const stateDraftsClone = (stateOriginal: DraftsState): DraftsState => {
  const stateCloned = new Map<string, DraftDataFormatted>();
  stateOriginal.forEach((draft, parentId) => {
    stateCloned.set(parentId, {
      ...draft,
      assigneeGroupsCurrent: new Set(draft.assigneeGroupsCurrent),
      assigneeGroupsSaved: new Set(draft.assigneeGroupsSaved),
      assigneeMembersCurrent: new Set(draft.assigneeMembersCurrent),
      assigneeMembersSaved: new Set(draft.assigneeMembersSaved),
      checklistCurrent: [...draft.checklistCurrent],
      checklistSaved: [...draft.checklistSaved],
      labelsCurrent: new Set(draft.labelsCurrent),
      labelsSaved: new Set(draft.labelsSaved),
      remindersCurrent: [...draft.remindersCurrent],
      remindersSaved: [...draft.remindersSaved],
    });
  });

  return stateCloned;
};

export default stateDraftsClone;
