/**
 * @file Generate search params for useMessageRankedSearchQuery
 */

import { useRef } from 'react';

import { SEARCH__MAX_SIZE } from '../../../constants/forms';

import { PREFERENCE__SEARCH_RESULTS__LIMIT } from '../../../constants/pagination';
import { SearchParams } from '../../../generated/graphql';
import { reportError } from '../../../services/reporting';
import useParamSearchPage from '../../router/params/useParamSearchPage';
import useParamSearchTerm from '../../router/params/useParamSearchTerm';

/**
 * Generate search params for useMessageRankedSearchQuery
 *
 * @returns The params to use
 */
const useSearchFormParams = (): SearchParams | null => {
  const hasFetchedRef = useRef(false);
  const page = useParamSearchPage();
  const queryString = useParamSearchTerm();

  if (page === null) {
    reportError('Page number missing from search page');
    return null;
  }

  if (queryString === null) {
    reportError('Query string missing from search page');
    return null;
  }

  /**
   * If we're fetching the data for the first time,
   * and we're not on the 1st page,
   * We need all previous pages fetched
   */

  if (hasFetchedRef.current) {
    return {
      from: (page - 1) * PREFERENCE__SEARCH_RESULTS__LIMIT,
      queryString,
      size: PREFERENCE__SEARCH_RESULTS__LIMIT,
    };
  }

  hasFetchedRef.current = true;

  // We can't fetch more than SEARCH__MAX_SIZE results,
  // No matter which page we're on
  const size = Math.min(
    page * PREFERENCE__SEARCH_RESULTS__LIMIT,
    SEARCH__MAX_SIZE,
  );

  return {
    from: 0,
    queryString,
    size,
  };
};

export default useSearchFormParams;
