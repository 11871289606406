import React, { ChangeEventHandler, FC, useId } from 'react';

import * as Styled from './styled';

type Items = {
  label: string;
  value: string;
}[];

export type Props = {
  className?: HTMLSelectElement['className'];
  defaultValue?: string;
  disabled?: HTMLSelectElement['disabled'];
  isLabelVisible: boolean;
  items: Items;
  label?: string;
  name: HTMLSelectElement['name'];
  onChange?: (value: string) => void;
  required?: HTMLSelectElement['required'];
};

/**
 * Accessible <select /> component with all basic features
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.defaultValue   Default value
 * @param props.disabled       Whether the Select is disabled
 * @param props.isLabelVisible Whether the label is hidden
 * @param props.items          An array of items to be used as options
 * @param props.label          Label for the select
 * @param props.name           Name of the select dropdown
 * @param props.onChange       Callback to be invoked when a selection is made
 * @param props.required       If the select is required in terms of a form
 * @returns                    A Select dropdown component
 */
const SelectMobile: FC<Props> = ({
  className,
  defaultValue,
  disabled = false,
  isLabelVisible,
  items,
  label,
  name,
  onChange,
  required,
}) => {
  /**
   * Change handler
   *
   * @param event Event object
   */
  const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
    onChange?.(event.target.value);
  };

  const elementId = useId();

  return (
    <Styled.Wrapper className={className}>
      {label !== undefined && (
        <Styled.Label data-is-visible={isLabelVisible} htmlFor={elementId}>
          {label}
        </Styled.Label>
      )}
      <Styled.InnerWrapper>
        <Styled.Select
          defaultValue={defaultValue}
          disabled={disabled}
          id={elementId}
          /**
           * Select is uncontrolled, that's why it doesn't update after defaultValue changes (due to api call). Key will * make it controlled but not lose behavior we need. We may want to change this when `isLoading` gets added
           */
          key={defaultValue}
          name={name}
          onChange={handleChange}
          required={required}
        >
          {items.map(item => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Styled.Select>
        <Styled.Chevron direction="down" size={12} />
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

export default SelectMobile;
