/**
 * @file A group was created, so we update the cache with it
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  GroupCreateMutation,
  GroupCreateMutationVariables,
  UserGroupsDocument,
} from '../../../../../generated/graphql';
import queryCreation from '../utils/updaters/queryCreation';

/**
 * Handle subscription to everything related to groups
 *
 * @param result                 Result of a graphql operation
 * @param result.userGroupCreate The created UserGroup
 * @param _args                  Data sent to the server along the mutation
 * @param cache                  URQL graphql cache
 */
const groupCreateMutation: UpdateResolver<
  GroupCreateMutation,
  GroupCreateMutationVariables
> = ({ userGroupCreate }, _args, cache) => {
  queryCreation<GroupCreateMutation['userGroupCreate']>({
    cache,
    queries: [{ name: 'userGroups', query: UserGroupsDocument }],
    result: userGroupCreate,
  });
};

export default groupCreateMutation;
