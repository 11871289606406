import React, { FC } from 'react';

import ChevronIcon from '../../Common/Chevron';

import getChevronDirection from './getChevronDirection';
import * as Styled from './styled';

const chevronSize = 8;

type Props = {
  isOpen: boolean;
};

/**
 * The input icon (chevron)
 *
 * @param props        Props passed to the component
 * @param props.isOpen Whether the input is currently open
 * @returns            The component itself
 */
const ComposeMetaInputIcon: FC<Props> = ({ isOpen }) => {
  const chevronDirectionHorizontal = getChevronDirection(isOpen, false);
  const chevronDirectionVertical = getChevronDirection(isOpen, true);

  return (
    <>
      <Styled.ChevronWrapperVertical>
        <ChevronIcon
          color="var(--color-placeholder)"
          direction={chevronDirectionVertical}
          size={chevronSize}
        />
      </Styled.ChevronWrapperVertical>
      <Styled.ChevronWrapperHorizontal>
        <ChevronIcon
          color="var(--color-placeholder)"
          direction={chevronDirectionHorizontal}
          size={chevronSize}
        />
      </Styled.ChevronWrapperHorizontal>
    </>
  );
};

export default ComposeMetaInputIcon;
