/**
 * @file Entry point for the permissions system
 */

import { Hotel, MeQuery } from '../../generated/graphql';

import { setHotelCurrentId, setUserCurrent } from './storage';

/**
 * Initialize the permissions system
 *
 * @param user    The current user's info
 * @param hotelId The ID of the hotel the board is for
 */
export const initPermissionsSystem = (
  user: MeQuery['me'],
  hotelId: Hotel['id'],
): void => {
  setUserCurrent(user);
  setHotelCurrentId(hotelId);
};
