import React, { FC } from 'react';

import { User, UserBasicFragment } from '../../../generated/graphql';
import AssigneesMembersLoading from '../../Loading/AssignmentMembers';

import AssigneesMemberSingle from '../AssigneesMemberSingle';

import AssigneesMembersNoResult from '../AssigneesMembersNoResult';

import * as Styled from './styled';

export type Props = {
  dataAssigned: Set<User['id']>;
  dataVisible: UserBasicFragment[];
  isLoading: boolean;
  isSearching: boolean;
  onChange?: (memberId: User['id'], state: boolean) => void;
};

/**
 * Members list, so the user can pick assignees
 *
 * @param props              Props passed to the component
 * @param props.dataAssigned IDs of members already checked
 * @param props.dataVisible  Visible board members
 * @param props.isLoading    Whether the data is being loaded
 * @param props.isSearching  Whether something was typed into search
 * @param props.onChange     When checkbox next to the user name is toggled
 * @returns                  The component itself
 */
const AssigneesMembers: FC<Props> = ({
  dataAssigned,
  dataVisible,
  isLoading,
  isSearching,
  onChange = null,
}) => {
  if (isLoading === true) {
    return <AssigneesMembersLoading />;
  }

  if (dataVisible.length === 0) {
    return isSearching === true ? (
      <AssigneesMembersNoResult reason="searching" />
    ) : null;
  }

  return (
    <Styled.Ul>
      {dataVisible.map(member => {
        return (
          <Styled.Li key={`member-${member.id}`}>
            <AssigneesMemberSingle
              isAssigned={dataAssigned.has(member.id)}
              member={member}
              onChange={
                onChange ? state => onChange(member.id, state) : undefined
              }
            />
          </Styled.Li>
        );
      })}
    </Styled.Ul>
  );
};

export default AssigneesMembers;
