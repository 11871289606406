import React, { FC } from 'react';

import ButtonTranslate from '../../components/ButtonTranslate';
import { Message, TargetLanguage } from '../../generated/graphql';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';

import useMessageTranslationToggle from '../../hooks/store/useMessageTranslationToggle';
import useCurrentPath from '../../hooks/useCurrentPath';
import { createFeedMessageDetailsEditPath } from '../../routes/paths/feed';
import { TranslationContainer } from '../../services/matomo/types';

export type Props = {
  className?: string;
  container: TranslationContainer;
  messageId: Message['id'];
  targetLanguage: TargetLanguage;
};

/**
 * Translate button container
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name
 * @param props.container      Container where the button is displayed
 * @param props.messageId      ID of the message
 * @param props.targetLanguage Language label displayed in the button
 * @returns                    The component itself
 */
const ButtonTranslateMessage: FC<Props> = ({
  className,
  container,
  messageId,
  targetLanguage,
}) => {
  const { isLoading, requestTranslationToggle } = useMessageTranslationToggle(
    messageId,
    container,
  );
  const currentPath = useCurrentPath();
  const urlMessageId = useParamMessageId();

  /**
   * Disable translate if we are editing the message
   */
  const isDisabled =
    currentPath === createFeedMessageDetailsEditPath() &&
    urlMessageId === messageId;

  return (
    <ButtonTranslate
      className={className}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={requestTranslationToggle}
      targetLanguage={targetLanguage}
    />
  );
};

export default ButtonTranslateMessage;
