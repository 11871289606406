import { styled } from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  grid-area: footer;

  &:empty {
    display: none;
  }
`;
