import { css, styled } from 'styled-components';

const commonStyle = css`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-medium);
`;

export const DataWrapper = styled.div`
  ${commonStyle}
  width: 55%;
`;

export const DataWrapperColumn = styled.div`
  flex-direction: column;
  ${commonStyle}
  width: 100%;
`;

export const KpiName = styled.span`
  height: var(--font-lg);
  margin: var(--spacing-tiny);
  width: 60%;
`;

export const Number = styled.span`
  height: var(--spacing-large);
  width: var(--spacing-large);
`;
