import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type ConditionalProps =
  | {
      isDeleted?: boolean;
      isInactive?: boolean;
      onClick?: never;
    }
  | {
      isDeleted?: never;
      isInactive?: never;
      onClick: () => void;
    };

export type Props = {
  children: ReactNode;
  className?: string;
  fontSize?: number;
  label?: string;
  size?: number;
} & ConditionalProps;

/**
 * Wrapper for Avatar. Either div or button based on onClick prop.
 *
 * @param props            Props passed to the component
 * @param props.children   Children passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.fontSize   The font-size of the avatar initials
 * @param props.isDeleted  Whether the user is deleted
 * @param props.isInactive Whether the user is disabled
 * @param props.label      Aria label for the button
 * @param props.onClick    Button click
 * @param props.size       Size of the the avatar image
 * @returns                The component itself
 */
const AvatarWrapper: FC<Props> = ({
  children,
  className,
  fontSize = 0.65,
  isDeleted,
  isInactive,
  label,
  onClick,
  size = 33,
}) => {
  return (
    <Styled.Wrapper
      aria-label={label ?? undefined}
      as={onClick ? 'button' : 'div'}
      className={className}
      data-deleted={isDeleted}
      data-font-size={fontSize}
      data-inactive={isInactive}
      data-size={size}
      onClick={onClick ?? undefined}
    >
      {children}
    </Styled.Wrapper>
  );
};

export default AvatarWrapper;
