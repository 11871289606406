/**
 * @file Hook for determining what to show on Kanban page
 */

import useCurrentPath from '../../../../hooks/useCurrentPath';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
  createKanbanMessageDetailsEditPath,
  createKanbanMessageDetailsPath,
} from '../../../paths/kanban';

type UseKanbanParamsReturn = {
  shouldShowMessageDetails: boolean;
};

const showMessageDetailsFor = [
  createKanbanMessageDetailsPath(),
  createKanbanMessageDetailsEditPath(),
];

/**
 * Hook for determining what to show on Kanban page
 *
 * @returns Info about the kanban page
 */
const useKanbanRootParams = (): UseKanbanParamsReturn => {
  const currentPath = useCurrentPath();
  const isMobile = useIsMobile();

  /**
   * Decide if messageDetails should be rendered.
   * Based on current path and whether app is rendered on mobile
   */
  const shouldShowMessageDetails =
    currentPath !== null &&
    isMobile &&
    showMessageDetailsFor.includes(currentPath);

  return {
    shouldShowMessageDetails,
  };
};

export default useKanbanRootParams;
