/**
 * @file User group proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorUserGroup } from './navigators';

const mapperUserGroup = {
  [NotificationEvent.UserGroupCreated]: navigatorUserGroup,
  [NotificationEvent.UserGroupDeleted]: navigatorUserGroup,
  [NotificationEvent.UserGroupMemberAdded]: navigatorUserGroup,
  [NotificationEvent.UserGroupMemberRemoved]: navigatorUserGroup,
  [NotificationEvent.UserGroupTitleUpdated]: navigatorUserGroup,
};

export default mapperUserGroup;
