import Tippy from '@tippyjs/react';
import React, { FC, MouseEvent, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
  className?: HTMLButtonElement['className'];
  formId?: string;
  isDisabled?: HTMLButtonElement['disabled'];
  label?: HTMLButtonElement['ariaLabel'];
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  shouldFocus?: boolean;
  type?: 'button' | 'submit';
};

/**
 * A generic button, has no styles and just renders children passed as props,
 * with onClick callback
 *
 * @param props             The props passed to the component
 * @param props.children    Children to render inside the button
 * @param props.className   styled-components generated class name, needed for styling
 * @param props.formId      The formId optional argument
 * @param props.isDisabled  Whether to make the button isDisabled
 * @param props.label       Label used for Tippy tooltip
 * @param props.onClick     Button click callback, can be omitted when type is submit
 * @param props.shouldFocus Should the button be auto focused
 * @param props.type        <button> type attribute
 * @returns                 The component itself
 */
const Button: FC<Props> = ({
  children,
  className,
  isDisabled = false,
  formId,
  label = null,
  onClick,
  shouldFocus = false,
  type = 'button',
  ...rest
}) => {
  if (label === null) {
    return (
      <Styled.Button
        {...rest}
        autoFocus={shouldFocus}
        className={className}
        disabled={isDisabled}
        form={formId}
        onClick={onClick}
        type={type}
      >
        {children}
      </Styled.Button>
    );
  }

  return (
    <Tippy content={label} touch={false}>
      <Styled.Button
        {...rest}
        autoFocus={shouldFocus}
        className={className}
        disabled={isDisabled}
        form={formId}
        onClick={onClick}
        type={type}
      >
        {children}
      </Styled.Button>
    </Tippy>
  );
};

export default Button;
