import Tippy from '@tippyjs/react';
import React, { AriaAttributes, FC } from 'react';

import useFocus from '../../../hooks/useFocus';

import Icon, { NAMES } from '../Icon';

import Link from './Link';

type Props = {
  ariaCurrent?: AriaAttributes['aria-current'];
  className?: string;
  color?: string;
  colorFocus?: string;
  colorHover?: string;
  iconHeight?: number;
  iconWidth?: number;
  iconName: string;
  isExternal?: boolean;
  label: string;
  onClick?: () => void;
  url: string;
};

const defaultColor = 'currentColor';

/**
 * Link which doesn't have any other content apart from the icon within it
 *
 * @param props             Props passed to the component
 * @param props.ariaCurrent aria-current attribute for the link
 * @param props.className   Styled-components generated class name, needed for styling
 * @param props.color       Color of the icon
 * @param props.colorFocus  Color of the icon when the button is focused
 * @param props.colorHover  Color of the icon when the button is hovered
 * @param props.iconHeight  The height of the included icon (button itself will be the same size)
 * @param props.iconName    Which icon to show within the button
 * @param props.iconWidth   The width of the included icon (button itself will be the same size)
 * @param props.isExternal  Whether this is an external link
 * @param props.label       Aria label for the button (since there's no text, just the icon)
 * @param props.onClick     Click callback, not needed for submit buttons
 * @param props.url         Url to link to
 * @returns                 The component itself
 */
const LinkIcon: FC<Props> = ({
  ariaCurrent,
  className,
  color = defaultColor,
  colorFocus = color,
  colorHover = color,
  iconHeight,
  iconName,
  iconWidth,
  isExternal = false,
  label,
  onClick,
  url,
}) => {
  const [isFocused, bind] = useFocus();

  return (
    <Tippy content={label} touch={false}>
      <span>
        <Link
          ariaCurrent={ariaCurrent}
          bind={bind}
          className={className}
          isExternal={isExternal}
          label={label}
          onClick={onClick}
          url={url}
        >
          <Icon
            color={isFocused ? colorFocus : color}
            colorHover={colorHover}
            height={iconHeight}
            name={iconName}
            width={iconWidth}
          />
        </Link>
      </span>
    </Tippy>
  );
};

export { NAMES };
export default LinkIcon;
