/**
 * @file contains reducer function for toast messages
 */
import { TOAST__ADD, TOAST__REMOVE } from '../../constants/actionTypes';
import {
  ToastMessageAction,
  ToastMessageActionAdd,
  ToastMessageActionRemove,
  ToastMessageState,
} from '../../models/toastMessage';
import initialState from '../initialState';

type Reducer<State, Action> = (state: State, action: Action) => State;

/**
 * The user has requested to add the message
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onToastMessageAdd = (
  state: ToastMessageState,
  action: ToastMessageActionAdd,
): ToastMessageState => [...state, action.payload];

/**
 * The user has requested to delete the message
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onToastMessageDelete = (
  state: ToastMessageState,
  action: ToastMessageActionRemove,
): ToastMessageState => state.filter(({ id }) => id !== action.payload);

/**
 * action:reducer mapping for toastMessage
 */
const mapping = new Map<string, Reducer<ToastMessageState, ToastMessageAction>>(
  [
    [TOAST__ADD, onToastMessageAdd],
    [TOAST__REMOVE, onToastMessageDelete],
  ],
);

/**
 * Reducer for all toastMessage actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const toastMessages = (
  state = initialState.toastMessages,
  action: ToastMessageAction,
): ToastMessageState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default toastMessages;
