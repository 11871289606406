import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

import CommonInput from '../Input';

// The form itself
export const Form = styled.form`
  display: flex;
  margin-bottom: var(--spacing-small);
`;

// Form ariaLabelledBy
export const Heading = styled.h5`
  ${visuallyHidden};
`;

// Form ariaDescribedBy
export const Text = styled.p`
  ${visuallyHidden};
`;

// Checklist title input
export const Input = styled(CommonInput)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  flex-grow: 1;

  /* Safari has 2px margin by default */
  margin: 0;

  /* otherwise, the form would overflow on small screens */
  min-width: 0;
`;

// Submit button (input with type="submit")
export const Submit = styled.input`
  /* It is now supported everywhere, and Autoprefixer will add -webkit- for older Safari */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  background-color: var(--color-background-tools);
  border: var(--border-dark);
  border-left: 0;

  /* iOS adds border-radius automatically if not specified */
  border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;

  /* iOS has #fff as the default, while Chrome has #000 */
  color: var(--color-text-regular);

  /* Safari has 2px margin by default */
  margin: 0;
  padding: var(--spacing-small) var(--spacing-large);
`;
