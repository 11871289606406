import { styled } from 'styled-components';

// The subheading text
export const Subheading = styled.h3`
  color: var(--color-text-regular);
  font-size: var(--font-baseline);

  /* To prevent this text from making the modal too wide */
  max-width: 30rem;
`;
