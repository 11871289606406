import { styled } from '@mui/material';

import breakpoints from '../../../constants/breakpoints';
import { CssVariable } from '../../../styles/cssTypes';

type ContentProps = {
  'data-has-floating-button': boolean;
};

/**
 * Create spacing for smaller devices
 * in order for floating to not overflow table contents
 *
 * @param props Props passed to the component
 * @returns     The variable to use
 */
const getPaddingBottom = (props: ContentProps): CssVariable => {
  if (props['data-has-floating-button'] === false) {
    return 0;
  }

  return 'var(--spacing-huge)';
};

export const Content = styled('div')<ContentProps>`
  /* Don't stretch the table to fit content, just align it to the top */
  align-items: start;
  display: grid;
  flex-grow: 1;
  gap: var(--spacing-medium);

  /* Header + Table */
  grid-template-rows: auto 1fr;

  @media (max-width: ${breakpoints.tabletS - 1}px) {
    display: flex;
    padding: var(--spacing-medium) var(--spacing-medium) ${getPaddingBottom}
      var(--spacing-medium);
  }
`;
