import React, { ComponentProps, FC } from 'react';

import { reportApiErrors } from '../../../../utils/error';
import requestPermission from '../../../../utils/notifications/requestPermission';
import ModalAllowNotifications from '../AllowNotifications';
import ModalPushSettingsPage from '../PushSettingsPage';
import ModalPushSubscribe from '../PushSubscribe';

import { NotificationModal } from './helpers';
import useModalToShow from './hooks/useModalToShow';

type Props =
  | ComponentProps<typeof ModalAllowNotifications>
  | ComponentProps<typeof ModalPushSettingsPage>
  | ComponentProps<typeof ModalPushSubscribe>;

const mapping: Record<NotificationModal, FC<Props>> = {
  green: ModalAllowNotifications,
  purple: ModalPushSubscribe,
  red: ModalPushSettingsPage,
};

/**
 * Main component for push notification modals,
 * Does not render anything by itself, but rather checks conditions,
 * and then either returns one of three available push modals or nothing
 *
 * @returns The modal to show (or null for none)
 */
const NotificationsMain: FC = () => {
  const [toShow, requestClose] = useModalToShow();

  /**
   * The user has clicked to subscribe to Firebase push notifications
   */
  const onClickSubscribe = () => {
    requestPermission().catch(reportApiErrors);
    requestClose();
  };

  if (toShow === null) {
    return null;
  }

  const Component = mapping[toShow];

  return (
    <Component
      isOpen={toShow !== null}
      onCancel={requestClose}
      onConfirm={onClickSubscribe}
    />
  );
};

export default NotificationsMain;
