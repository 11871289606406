import React, { FC } from 'react';
import { LinkProps } from 'react-router-dom';

import { LABEL__COLOR_DEFAULT } from '../../../constants/forms';
import { Label } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import MetaLabelEdit from '../MetaLabelEdit';

export type Props = {
  className?: string;
  isActive: boolean;
  labelsExisting: Label['text'][];
  linkToggleProps?: LinkProps;
  requestModeView: () => void;
  requestCreate: ((title: Label['text'], color: Label['color']) => void) | null;
};

/**
 * Label create form if the user has permissions for creation,
 * null otherwise
 *
 * Extracted into a separate file like this
 * to reduce complexity in index.tsx
 *
 * @param props                 Props passed to the component
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.isActive        Whether the component should be visible
 * @param props.labelsExisting  Labels already present
 * @param props.linkToggleProps Props for the toggle link
 * @param props.requestCreate   Callback for to be invoked on pressing "Save"
 * @param props.requestModeView Request to switch to view mode, showing a list of labels
 * @returns                     The component itself (or null)
 */
const MetaLabelCreate: FC<Props> = ({
  className,
  isActive,
  labelsExisting,
  linkToggleProps = null,
  requestCreate,
  requestModeView,
}) => {
  // The user has no permission to create a label
  if (requestCreate === null) {
    return null;
  }

  const heading = translate('COMPOSE__LABELS__CREATE');

  return (
    <MetaLabelEdit
      className={className}
      dataColor={LABEL__COLOR_DEFAULT}
      isActive={isActive}
      labelClose={translate('GENERAL__CLOSE')}
      labelsExisting={labelsExisting}
      linkToggleProps={linkToggleProps}
      requestModeView={requestModeView}
      requestSave={(title, color) => {
        requestCreate(title, color);
        requestModeView();
      }}
      textHeading={heading}
    />
  );
};

export default MetaLabelCreate;
