/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { ROUTE__404 } from '../../constants/routing/routes';

/**
 * Url that matches nothing in the app
 *
 * @returns Url string
 */
export const getNoMatchUrl = (): string => {
  return '/' + ROUTE__404;
};
