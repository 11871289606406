/**
 * @file Hook for clearing app badge (count) whenever the app gets into view
 */

import { notificationCounterSet } from 'hotelboard-indexeddb';
import { useEffect } from 'react';

import { reportApiErrors } from '../utils/error';

import useIsAppInView from './useIsAppInView';

/**
 * Hook for clearing app badge (count) whenever the app gets into view
 */
const useBadgeClear = () => {
  const isAppInView = useIsAppInView();

  useEffect(() => {
    if (isAppInView) {
      notificationCounterSet(0).catch(reportApiErrors);

      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge().catch(reportApiErrors);
      }
    }
  }, [isAppInView]);
};

export default useBadgeClear;
