import React, { FC, MouseEventHandler, ReactNode } from 'react';

import Illustration from '../../Common/Illustration';

import * as Styled from './styled';

export type Props = {
  actionButton: {
    text: string;
    onClick: MouseEventHandler;
  } | null;
  children?: ReactNode | ReactNode[];
  description: string;
  illustrationName: string;
  isFullScreen?: boolean;
  title: string;
};

/**
 * Generic error page component
 *
 * @param props                  Props passed to the component
 * @param props.actionButton     Action button for the error screen
 * @param props.children         React children
 * @param props.description      Error description
 * @param props.illustrationName Name of the illustration to use
 * @param props.isFullScreen     Whether error report is shown on full screen or inside of a container
 * @param props.title            Error title
 * @returns                      The component itself
 */
const ErrorWrapper: FC<Props> = ({
  actionButton,
  children,
  description,
  illustrationName,
  isFullScreen = true,
  title,
}) => {
  return (
    <Styled.Wrapper data-full-screen={isFullScreen}>
      <Styled.Content>
        <Illustration height={363} name={illustrationName} width={400} />

        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
        {actionButton !== null && (
          <Styled.ActionButton onClick={actionButton.onClick} type="button">
            {actionButton.text}
          </Styled.ActionButton>
        )}
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default ErrorWrapper;
