import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import { AttachmentDraft } from '../../../../../models/attachment';
import MessageAttachment from '../../../../Common/MessageAttachments';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  attachments: AttachmentDraft[];
};

/**
 * Section in the message compose that displays the attachments
 *
 * @param props             Props passed to the component
 * @param props.attachments Attachments to show
 * @returns                 The component itself
 */
const ComposeMetaAttachments: FC<Props> = ({ attachments }) => {
  return (
    <ComposeMetaSection heading={translate('MESSAGE__HEADING__ATTACHMENTS')}>
      <MessageAttachment attachments={attachments} />
    </ComposeMetaSection>
  );
};

export default ComposeMetaAttachments;
