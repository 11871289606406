import { styled } from 'styled-components';

type Props = {
  'data-color-hover-fill': string;
  'data-color-hover-stroke': string;
  'data-color-normal-fill': string;
  'data-color-normal-stroke': string;
  'data-dimensions-height': number;
  'data-dimensions-width': number;
};

/**
 * Get the color to use for icon fill when hovering
 *
 * @param props Props passed to the icon
 * @returns     The color to use
 */
const getColorFillHover = (props: Props) => {
  return props['data-color-normal-fill'];
};

/**
 * Get the color to use for icon fill
 *
 * @param props Props passed to the icon
 * @returns     The color to use
 */
const getColorFillRegular = (props: Props) => {
  return props['data-color-normal-fill'];
};

/**
 * Get the color to use for icon fill when hovering
 *
 * @param props Props passed to the icon
 * @returns     The color to use
 */
const getColorStrokeHover = (props: Props) => {
  return props['data-color-hover-stroke'];
};

/**
 * Get the color to use for icon stroke
 *
 * @param props Props passed to the icon
 * @returns     The color to use
 */
const getColorStrokeRegular = (props: Props) => {
  return props['data-color-normal-stroke'];
};

export const Svg = styled.svg<Props>`
  fill: ${getColorFillRegular};
  height: ${props => props['data-dimensions-height']}px;
  stroke: ${getColorStrokeRegular};
  transition: var(--preference-transition-duration-normal);
  vertical-align: top;
  width: ${props => props['data-dimensions-width']}px;

  &:hover {
    fill: ${getColorFillHover};
    stroke: ${getColorStrokeHover};
  }
`;
