import React, { FC } from 'react';

import { FormattedData } from '../../../models/dashboard';

import ConciergesDesktop from '../ConciergesDesktop';

import RequestsDesktop from '../RequestsDesktop';
import TasksDesktop from '../TasksDesktop';

type Props = {
  data: FormattedData;
};

/**
 * Dashboard desktop
 *
 * @param props      Props passed to the component
 * @param props.data KPIs data from which UI is mapped
 * @returns          The component itself
 */
const ContentDesktop: FC<Props> = ({ data }) => {
  const { CONCIERGE, REQUEST, TASK } = data;

  return (
    <>
      <TasksDesktop tasks={TASK} />
      <RequestsDesktop requests={REQUEST} />
      <ConciergesDesktop concierges={CONCIERGE} />
    </>
  );
};

export default ContentDesktop;
