/**
 * @file Hook for checking out if the width of the window
 */
import { useEffect, useState } from 'react';

/**
 * Hook for checking out if the width of the window
 * matches the media query we pass into it
 *
 * @param mediaQuery Media query we want to match against
 * @returns          Whether the media query matches the current screen size
 */
const useMatchMedia = (mediaQuery: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    /**
     * Media that matches changed, so we update the state to reflect that
     *
     * @param event The event that took place
     */
    const updateMatches = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    const mediaQueryList = window.matchMedia(mediaQuery);

    setMatches(mediaQueryList.matches);

    /**
     * MediaQueryList.addListener is basically an alias of Event.addEventListener but we use this one
     * to make it backwards compatible with older browsers like Safari
     *
     * @todo - test this more to determine if this can be replaced
     */
    mediaQueryList.addListener(updateMatches);

    return () => mediaQueryList.removeListener(updateMatches);
  }, [mediaQuery]);

  return matches;
};

export default useMatchMedia;
