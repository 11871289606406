/**
 * @file Contains helper function for creating <Avatar/> alt text that is properly formatted
 */

import { User } from '../generated/graphql';

import userGetName from './user/userGetName';

/**
 * Get a user's full name representation (or null if there's no data)
 *
 * @param user User to get the name for
 * @returns    The formatted name
 */
const getAvatarAltText = (
  user:
    | Omit<Partial<User>, 'hotelPermissions' | 'userGroups'>
    | Pick<User, 'firstName' | 'lastName' | 'status'>
    | null,
) => {
  return `${userGetName(user)} avatar`;
};

export default getAvatarAltText;
