/**
 * @file contains custom useEditor hook for tiptap
 */
import { EditorOptions } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { DependencyList, useEffect, useState } from 'react';

import useForceUpdate from '../../../hooks/useForceUpdate';

/**
 * Custom tiptap useEditor hook that will not cause flicker of the component
 * on each re-mount, and thus will not cause UI to shift.
 *
 * This function is recommended by tiptap to avoid flicker.
 *
 * @param options Tiptap Editor options object
 * @param deps    Dependencies used to re-render the editor
 * @returns       Tiptap Editor
 */
const useEditorCustom = (
  options: Partial<EditorOptions> = {},
  deps: DependencyList = [],
) => {
  const [editor, setEditor] = useState<Editor>(() => new Editor(options));
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let instance: Editor;

    if (editor.isDestroyed) {
      instance = new Editor(options);
      setEditor(instance);
    } else {
      instance = editor;
    }

    instance.on('transaction', () => {
      /**
       * Tiptap wraps this with 2x requestAnimationFrame
       * I have removed this and I see no difference, but if we have issues
       * maybe we shall return requestAnimationFrame again
       */
      forceUpdate();
    });

    return () => {
      instance.destroy();
    };
    /* This is supplied by tiptap and it works so I won't pass any extra deps. If it works it works. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return editor;
};

export default useEditorCustom;
