import React, { FC } from 'react';

import HeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

/**
 * Header for the ROQ chat conversation page
 *
 * @returns The component itself
 */
const ChatConversationPageHeader: FC = () => {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return null;
  }

  return <HeaderMobile backUrl={true} title={translate('GENERAL__CHAT')} />;
};

export default ChatConversationPageHeader;
