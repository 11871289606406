import React, { ComponentProps, FC, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'urql';

import MetaAssignment from '../../../components/MetaAssignment';
import {
  AssigneesFullDocument,
  MessageUpdateDocument,
} from '../../../generated/graphql';
import useRedirectOnError from '../../../hooks/useRedirectOnError';

import { reportApiErrors } from '../../../utils/error';
import {
  getGroupsAll,
  getGroupsSaved,
  getMembersAll,
  getMembersSaved,
} from '../../../utils/members/assignment';
import { PropsInner } from '../props';

type PropsAssignment = ComponentProps<typeof MetaAssignment>;

/**
 * Assignment mode for message full
 *
 * @param props                       Props passed to the component
 * @param props.messageId             The message that the assignment is for
 * @param props.topicId               The ID of the topic the message is in
 * @param props.networkMessageDetails Whether the debug option for fetching this is on
 * @param props.requestModeOverview   Request that messageFull switches to overview mode
 * @returns                           The component itself
 */
const MessageAssignment: FC<PropsInner> = ({
  messageId,
  networkMessageDetails,
  topicId,
  requestModeOverview,
}) => {
  const handleErrorRedirect = useRedirectOnError();
  const [searchTerm, setSearch] = useState('');
  const [{ data, error, fetching }] = useQuery({
    query: AssigneesFullDocument,
    variables: {
      messageId,
      topicId,
    },
  });

  const [, requestMessageUpdate] = useMutation(MessageUpdateDocument);

  const isLoading = fetching === true || networkMessageDetails === true;

  const groupsAll = getGroupsAll(data);
  const groupsSaved = getGroupsSaved(data);
  const [groupsAssigned, setGroupsAssigned] = useState(groupsSaved);
  const membersAll = getMembersAll(data);
  const membersSaved = getMembersSaved(data);
  const [membersAssigned, setMembersAssigned] = useState(membersSaved);

  /**
   * When data updates, we need to update the state
   * That can happen when the users switches the message/topic they're editing
   */
  useEffect(() => {
    setGroupsAssigned(getGroupsSaved(data));
    setMembersAssigned(getMembersSaved(data));
  }, [data]);

  /**
   * The user has toggled a group
   *
   * @param groupId The ID of the group the user has toggled
   * @param state   Whether the group is selected or not
   */
  const onAssignUpdateGroup: PropsAssignment['onAssignUpdateGroup'] = (
    groupId,
    state,
  ) => {
    const groups = new Set(groupsAssigned);
    if (state === true) {
      groups.add(groupId);
    } else {
      groups.delete(groupId);
    }

    setGroupsAssigned(groups);
  };

  /**
   * The user has toggled a member
   *
   * @param memberId The ID of the member the user has toggled
   * @param state    Whether the member is selected or not
   */
  const onAssignUpdateMember: PropsAssignment['onAssignUpdateMember'] = (
    memberId,
    state,
  ) => {
    const members = new Set(membersAssigned);
    if (state === true) {
      members.add(memberId);
    } else {
      members.delete(memberId);
    }

    setMembersAssigned(members);
  };

  /**
   * Request that assignment is updated
   */
  const requestAssignSave = () => {
    requestMessageUpdate({
      data: {
        assignedGroups: Array.from(groupsAssigned),
        assignedMembers: Array.from(membersAssigned),
      },
      id: messageId,
    })
      .then(requestModeOverview)
      .catch(reportApiErrors);
  };

  if (error) {
    handleErrorRedirect(error);
    return null;
  }

  return (
    <MetaAssignment
      dataGroupsAll={groupsAll}
      dataGroupsAssigned={groupsAssigned}
      dataGroupsSaved={groupsSaved}
      dataMembersAll={membersAll}
      dataMembersAssigned={membersAssigned}
      dataMembersSaved={membersSaved}
      dataSearchValue={searchTerm}
      isActive={true}
      isLoading={isLoading}
      onAssignUpdateGroup={onAssignUpdateGroup}
      onAssignUpdateMember={onAssignUpdateMember}
      onSearch={setSearch}
      requestAssignClose={requestModeOverview}
      requestAssignSave={requestAssignSave}
    />
  );
};

export default MessageAssignment;
