import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import FloatingBottomNav from '../../../../components/FloatingBottomNav';
import BaseHeaderMobile from '../../../../components/Header/BaseHeaderMobile';
import HotelSelectMobile from '../../../../components/HotelSelectMobile';
import AppLayout from '../../../../components/layouts/AppLayout';
import { MeDocument } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../../hooks/useIsMobile';
import translate from '../../../../i18n/translate';
import { getHomeUrl } from '../../../urls/home';

/**
 * Hotel select page
 *
 * @returns The page itself
 */
const HotelSelect: FC = () => {
  const isMobile = useIsMobile();
  const hotelId = useParamHotelId();

  const [{ data }] = useQuery({ query: MeDocument, variables: {} });

  if (data === null || data === undefined) {
    // Data should be in cache by this point, this is just for type correctness
    return null;
  }

  const { hotelPermissions, selectedHotel } = data.me;

  if (isMobile) {
    return (
      <AppLayout
        footer={<FloatingBottomNav />}
        header={
          <BaseHeaderMobile
            backUrl={true}
            title={translate('GENERAL__CHANGE_OBJECT')}
          />
        }
      >
        <HotelSelectMobile
          hotelPermissions={hotelPermissions}
          selectedHotel={selectedHotel}
        />
      </AppLayout>
    );
  }

  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default HotelSelect;
