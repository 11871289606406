export const GENERAL__UUID_LENGTH = 36;
export const GENERAL__COLOR_REGEX =
  '^#(([a-fA-F0-9]{3}){1,2}|([a-fA-F0-9]{4}){1,2})$';
export const GENERAL__SORT_LOCALE = 'en';
export const GENERAL__SORT_OPTION = { caseFirst: 'upper' };

export const MONGO__ID_LENGTH = 24;

export const I18N__FALLBACK_LANGUAGE = 'en';

export const EXTERNAL_COMMUNICATION__FORM__DUE_DATE_OFFSET__HOURS = 1;

export const REMINDER__MAX_TOTAL_ITEMS = 4;
export const REMINDER__MIN_AMOUNT = -9999;
export const REMINDER__MAX_AMOUNT = 9999;
export const REMINDER__PRESET_OPTIONS = [
  { amount: -1, unit: 'DAYS', default: false },
  { amount: -1, unit: 'HOURS', default: false },
  { amount: 0, unit: 'MINUTES', default: false },
  { amount: 10, unit: 'MINUTES', default: false },
  { amount: 30, unit: 'MINUTES', default: true },
  { amount: 1, unit: 'HOURS', default: false },
  { amount: 2, unit: 'HOURS', default: false },
  { amount: 1, unit: 'DAYS', default: false },
];

export const CHECKLIST__MAX_TOTAL_ITEMS = 100;
export const CHECKLIST__MIN_TEXT_LENGTH = 3;
export const CHECKLIST__MAX_TEXT_LENGTH = 500;
export const CHECKLIST__TEXT_REQUIRED = true;
export const CHECKLIST__TEXT_UNIQUE = true;

export const COMMENT__MAX_TEXT_LENGTH = 50000;
export const COMMENT__TEXT_REQUIRED = true;

export const FILE__MIN_FILES_UPLOAD = 1;
export const FILE__MAX_FILES_UPLOAD = 10;
export const FILE__MAX_FILE_SIZE = 15728640;
export const FILE__EXTENSIONS = {
  pdf: 'pdf',
  ai: 'image',
  ico: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  ps: 'image',
  psd: 'image',
  key: 'other',
  odp: 'other',
  pps: 'other',
  ppt: 'other',
  pptx: 'other',
  ods: 'other',
  xls: 'other',
  xlsx: 'other',
  doc: 'other',
  docx: 'other',
  odt: 'other',
  rtf: 'other',
  tex: 'other',
  txt: 'other',
  wpd: 'other',
  csv: 'other',
};

export const LABEL__MIN_TEXT_LENGTH = 3;
export const LABEL__MAX_TEXT_LENGTH = 63;
export const LABEL__COLOR_DEFAULT = '#0e0e0e';
export const LABEL__COLOR_REQUIRED = true;
export const LABEL__TEXT_REQUIRED = true;
export const LABEL__TEXT_UNIQUE = true;

export const MESSAGE__MAX_TITLE_LENGTH = 255;
export const MESSAGE__MAX_TEXT_LENGTH = 50000;
export const MESSAGE__TITLE_REQUIRED = true;
export const MESSAGE__TEXT_REQUIRED = true;

export const PAGINATION__MAX_ITEMS_PER_PAGE = 50;

export const SEARCH__MAX_QUERY_STRING_LENGTH = 255;
export const SEARCH__MAX_OFFSET = 1000;
export const SEARCH__MAX_SIZE = 100;

export const TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MIN = 1;
export const TEMPLATE__DUE_DATE_OFFSET_AMOUNT_MAX = 9999;
export const TEMPLATE__RECURRING_DAILY_INTERVAL_AMOUNT_MAX = 999;
export const TEMPLATE__RECURRING_WEEKLY_INTERVAL_AMOUNT_MAX = 99;

export const TOPIC__MAX_TITLE_LENGTH = 100;
export const TOPIC__MAX_DESCRIPTION_LENGTH = 500;
export const TOPIC__TITLE_REQUIRED = true;
export const TOPIC__TITLE_UNIQUE = true;
export const TOPIC__DESCRIPTION_REQUIRED = false;
export const TOPIC__IS_PRIVATE_DEFAULT = false;

export const USER__DEFAULT_TIMEZONE = 316;
export const USER__DEFAULT_LANGUAGE = 'de';

export const USER_GROUP__MAX_TITLE_LENGTH = 100;
export const USER_GROUP__TITLE_REQUIRED = true;
export const USER_GROUP__TITLE_UNIQUE = true;
