/**
 * @file Label proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorLabel } from './navigators';

const mapperLabel = {
  [NotificationEvent.LabelCreated]: navigatorLabel,
  [NotificationEvent.LabelDeleted]: navigatorLabel,
  [NotificationEvent.LabelUpdated]: navigatorLabel,
};

export default mapperLabel;
