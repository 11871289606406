import React, { FC } from 'react';

import { Reminder } from '../../../generated/graphql';
import SelectReminder from '../SelectReminder';
import { getReminderFromValue } from '../helpers';
import * as Styled from '../styled';

type Props = {
  items: Reminder[];
  requestChange: (position: number, reminder: Reminder) => void;
  requestDelete: (position: number) => void;
};

/**
 * List of reminder items
 *
 * @param props               Props passed to the component
 * @param props.items         Reminder items
 * @param props.requestChange Request that one item is updated
 * @param props.requestDelete Request that one item is deleted
 * @returns                   The component itself
 */
const ReminderItems: FC<Props> = ({ items, requestChange, requestDelete }) => {
  if (items?.length === 0) {
    return null;
  }

  return (
    <ul>
      {items?.map((item, index) => (
        // Use index as key so we don't have to add another prop to the Reminders object
        // and since we use array for reminders list we need element index.
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Li key={index}>
          <SelectReminder
            reminder={{ ...item, position: index }}
            requestChange={value =>
              requestChange(index, getReminderFromValue(value))
            }
            requestDelete={() => requestDelete(index)}
          />
        </Styled.Li>
      ))}
    </ul>
  );
};

export default ReminderItems;
