/**
 * @file Helper functions for useMessageRead hook
 */

import { ShouldRemoveParams, UseMessageReadParams } from './types';

/**
 * Get whether the message should be added to mark-as-read queue
 *
 * @param args ARguments passed to the hook
 * @returns    Whether the message should be added to mark-as-read queue
 */
export const getShouldAdd = (args: UseMessageReadParams) => {
  const { hasComments, isExpanded, isInView, isUnread } = args;

  const shouldReadOnView = hasComments === false && isInView;
  const shouldReadOnExpand = hasComments === true && isExpanded;

  return isUnread && (shouldReadOnView || shouldReadOnExpand);
};

/**
 * Get whether to remove the message from mark-as-read queue
 *
 * @param args ARguments passed to the hook
 * @returns    Whether to remove the message from mark-as-read queue
 */
export const getShouldRemove = (args: ShouldRemoveParams) => {
  const { isInView, isUnread } = args;
  return isUnread === true && isInView === false;
};
