/**
 * @file Track page view function
 */

import { useEffect } from 'react';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import useWebViewDeviceInfo from '../../hooks/webview/useWebViewDeviceInfo';
import { getUserCurrent } from '../../utils/permissions/storage';

import {
  AdditionalConfig,
  CustomDimensions,
  getCustomDimensions,
} from './customDimensions';

import { trackPageView } from '.';

/**
 * Generate function to handle visibility change
 *
 * @param customDimensions Matomo custom dimensions
 * @returns                Change handler
 */
const generateChangeHandler = (customDimensions: CustomDimensions) => {
  return () => {
    if (document.visibilityState !== 'visible') {
      return;
    }

    trackPageView(customDimensions);
  };
};

/**
 * Track page view again when the app gets into view:
 * visibilitychange - happens on browser tab change
 * focus - happens when an element, in this case window,
 * receives focus. Happens on alt+tab, changing focus
 * from dev tools back to the app, etc.
 *
 * @param customDimensions Matomo custom dimensions
 * @returns                Destructor
 */
const trackPageViewAgainOnFocus = (customDimensions: CustomDimensions) => {
  const handleChange = generateChangeHandler(customDimensions);

  window.addEventListener('focus', handleChange);
  window.addEventListener('visibilitychange', handleChange);

  return () => {
    window.removeEventListener('focus', handleChange);
    window.removeEventListener('visibilitychange', handleChange);
  };
};

/**
 * Track page view hook
 */
export const useTrackPageView = () => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const messageId = useParamMessageId();
  const { deviceInfo } = useWebViewDeviceInfo();

  const appVersionNative = deviceInfo?.appVersion;
  const href = window.location.href;
  const user = getUserCurrent();

  useEffect(() => {
    const common: AdditionalConfig = {
      appVersionNative,
      appVersionWeb: APP_VERSION,
      messageId,
      topicId,
    };

    const manualDimensions = getCustomDimensions(href, user, hotelId, {
      ...common,
      actionType: 'manual',
    });

    const automaticDimensions = getCustomDimensions(href, user, hotelId, {
      ...common,
      actionType: 'automatic',
    });

    trackPageView(manualDimensions);

    return trackPageViewAgainOnFocus(automaticDimensions);

    // Changes to other deps will also change the href or re-render
    // the whole component, so we don't need to add them here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersionNative, href]);
};
