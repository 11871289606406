/**
 * @file Handle subscription to everything related to comments
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  CommentInfoSubscription,
  CommentInfoSubscriptionVariables,
  CommentsDocument,
  CommentsQueryVariables,
  Message,
  Node,
} from '../../../../../generated/graphql';
import paginatedQueryCreation from '../utils/updaters/paginatedQueryCreation';
import paginatedQueryDeletion from '../utils/updaters/paginatedQueryDeletion';
import {
  addNodeToPaginatedQuery,
  filterQueries,
  removeNodeFromPaginatedQuery,
} from '../utils/updaters/paginatedQueryHelpers';

const queryName = 'comments';

/**
 * Handle subscription to everything related to comments
 *
 * @param result                     Result of a graphql operation
 * @param result.commentInfo         Data passed along
 * @param result.commentInfo.action  The action that triggered the event
 * @param result.commentInfo.comment The updated comment
 * @param args                       Arguments of a graphql operation
 * @param args.messageId             The ID of the message the comment is for
 * @param cache                      URQL graphql cache
 */
const commentInfoSubscription: UpdateResolver<
  CommentInfoSubscription,
  CommentInfoSubscriptionVariables
> = ({ commentInfo: { action, comment } }, { messageId }, cache) => {
  if (action === ActionType.Created) {
    paginatedQueryCreation({
      cache,
      filterQueries: filterQueries<Message['id']>({
        filterById: messageId,
        parentIdName: 'messageId',
        queryName,
      }),
      updateQuery: (variables: CommentsQueryVariables) => {
        addNodeToPaginatedQuery({
          cache,
          node: comment as Node,
          query: CommentsDocument,
          queryName,
          variables,
        });
      },
    });
    return;
  }

  if (action === ActionType.Deleted) {
    paginatedQueryDeletion({
      cache,
      queryName,
      updateQuery: (variables: CommentsQueryVariables) => {
        removeNodeFromPaginatedQuery({
          cache,
          nodeId: comment.id,
          query: CommentsDocument,
          queryName,
          variables,
        });
      },
    });
    return;
  }

  /**
   * @todo - handle DELETE and/or UPDATE cases here
   */
};

export default commentInfoSubscription;
