import React from 'react';

import ErrorWrapper from '../../../components/Errors/ErrorWrapper';
import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import useLogout from '../../../hooks/useLogout';
import translate from '../../../i18n/translate';

/**
 * Page that shows error when there are missing terms and conditions
 * As of now, it can't be shown ever since this is handled on the IAM, but
 * if IAM changes something, we have this safeguard
 *
 * @returns The component itself
 */
const MissingTermsAndConditionsPage = () => {
  const logout = useLogout();

  return (
    <ErrorWrapper
      actionButton={{
        onClick: logout,
        text: translate('GENERAL__BACK_TO_LOGIN'),
      }}
      description={translate(
        'ERROR__MISSING_TERMS_AND_CONDITIONS__DESCRIPTION',
      )}
      illustrationName={ILLUSTRATION__SEARCH}
      title={translate('ERROR__MISSING_TERMS_AND_CONDITIONS__TITLE')}
    />
  );
};

export default MissingTermsAndConditionsPage;
