import { FC, useCallback } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSubscription } from 'urql';

import {
  Message,
  MessageInfoDocument,
  Topic,
} from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import { getTopicUrl } from '../../../routes/urls/topic';
import { unsubscribeFromCommentsSet } from '../../../store/actions/subscriptions';

import useActionDeleted from '../useActionDeleted';

import { getMessageInfo } from './helpers';
import useLatestPost from './useLatestPost';

type DispatchProps = {
  requestUnsubscribeFromComments: (messageId: Message['id']) => void;
};

type OwnProps = {
  messageId: Message['id'] | null;
  topicId: Topic['id'];
};

type Props = DispatchProps & OwnProps;

/**
 * Subscription component for messageInfo
 *
 * @param props                                Props passed to the component
 * @param props.messageId                      Currently active message
 * @param props.topicId                        ID of the topic to use to subscribe to messages
 * @param props.requestUnsubscribeFromComments Redux action that unsubscribes user from comments
 * @returns                                    Component that holds subscription to messages
 */
const MessageInfoSubscription: FC<Props> = ({
  messageId,
  topicId,
  requestUnsubscribeFromComments,
}) => {
  const [{ data }] = useSubscription({
    query: MessageInfoDocument,
    variables: {
      topicId,
    },
  });

  const hotelId = useParamHotelId();
  const location = useLocation();

  const { creator, isActionCreated, isActionDeleted, messageInfoId } =
    getMessageInfo(data);

  const unsubscribeAction = useCallback(() => {
    if (messageInfoId !== null) {
      requestUnsubscribeFromComments(messageInfoId);
    }
  }, [messageInfoId, requestUnsubscribeFromComments]);

  useActionDeleted({
    redirectUrl:
      isActionDeleted && messageId === messageInfoId
        ? getTopicUrl(hotelId, location, topicId)
        : null,
    unsubscribeAction: isActionDeleted ? unsubscribeAction : null,
  });

  useLatestPost({
    creator,
    isActionCreated,
    messageId: messageInfoId,
    topicId,
  });

  return null;
};

/**
 * Map Redux state to subscriptions props
 *
 * @param dispatch Redux dispatch function
 * @returns        Props to pass to the component
 */
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
): DispatchProps => ({
  requestUnsubscribeFromComments: (messageId: Message['id']) => {
    return dispatch(unsubscribeFromCommentsSet(messageId));
  },
});

export default connect(null, mapDispatchToProps)(MessageInfoSubscription);
