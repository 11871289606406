/**
 * @file Hook to keep track of the messages to be marked as read
 */

import { useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { Message } from '../../../generated/graphql';
import AppState from '../../../models/state';
import useDebounce from '../../useDebounce';

import reducer from './reducer';
import { MessagesReadQueueState } from './types';

/**
 * Initially empty, but will hold all the messages that are to be
 */
const initialState: MessagesReadQueueState = new Set();

/**
 * We only add to the state after a specified amount of time,
 * giving the user the chance to read the message instead of just skimming past,
 * in which case, we clear the timer and never add the message to the state
 */
const timers = new Map<Message['id'], number>();

/**
 * Hook to keep track of the messages to be marked as read
 *
 * @returns The state and the reducers
 */
const useMessagesReadQueueReducer = () => {
  const timerDebounce = useSelector<AppState, number>(
    ({ markAsRead }) => markAsRead.debounce,
  );
  const timerDelay = useSelector<AppState, number>(
    ({ markAsRead }) => markAsRead.delay,
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Add messageId to the queue,
   * but only after specified amount of time
   */
  const readQueueAdd = useCallback(
    (messageId: Message['id']) => {
      // We already have a timer for this message,
      if (timers.has(messageId)) {
        return;
      }

      const timerId = window.setTimeout(() => {
        dispatch({
          payload: { messageId },
          type: 'ADD_TO_QUEUE',
        });
      }, timerDelay);

      timers.set(messageId, timerId);
    },
    [timerDelay],
  );

  /**
   * The user has navigated away from the feed,
   * So we clear all the timers currently active
   */
  const readQueueClear = useCallback(() => {
    timers.forEach(timer => clearTimeout(timer));
    timers.clear();
  }, []);

  /**
   * Remove a message from the mark-as-read queue
   */
  const readQueueRemove = useCallback((messageId: Message['id']) => {
    // Only stop the timers, to prevent the item from being added to the queue
    // Items already present should remain there until readQueueReset called
    clearTimeout(timers.get(messageId));
    timers.delete(messageId);
  }, []);

  /**
   * Only what's already in the queue should be marked as read,
   * No need to clear the timers
   */
  const readQueueReset = useCallback(() => {
    dispatch({ type: 'RESET_QUEUE' });
  }, []);

  return {
    readQueueAdd,
    readQueueClear,
    readQueueRemove,
    readQueueReset,
    state: useDebounce(state, timerDebounce),
  };
};

export default useMessagesReadQueueReducer;
