import React, { FC } from 'react';

import { LabelBasicFragment } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import LabelList from '../LabelList';
import MessageNoResult from '../MessageNoResult';

export type Props = {
  labels: LabelBasicFragment[];
};

/**
 * Labels attached to the message
 *
 * @param props        Props passed to the component
 * @param props.labels The labels to use
 * @returns            The component itself
 */
const MessageLabels: FC<Props> = ({ labels }) => {
  if (labels.length === 0) {
    return <MessageNoResult message={translate('LABELS__NONE')} />;
  }

  return <LabelList labels={labels} />;
};

export default MessageLabels;
