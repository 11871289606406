import React, { FC, useState } from 'react';

import MetaDateDue from '../../../components/MetaDateDue';
import { Reminder } from '../../../generated/graphql';
import { getDateToUse } from '../../../utils/date/getDate';

type Props = {
  dateDueSaved: Date | null;
  remindersSaved: Reminder[];
  requestModeOverview: () => void;
  requestSave: (dateDue: Date | null, reminders: Reminder[]) => void;
};

/**
 * 2nd part of the message full date due container
 * Split like this because of urql + useState
 * we have branching in urql for fetching, error and done states
 * and useState doesn't allow for that
 *
 * @param props                     Props passed to the component
 * @param props.dateDueSaved        The saved due date
 * @param props.remindersSaved      Saved reminders
 * @param props.requestModeOverview Request that messageFull switches to overview mode
 * @param props.requestSave         Request to save current assignment data
 * @returns                         The component itself
 */
const Inner: FC<Props> = ({
  dateDueSaved,
  remindersSaved,
  requestModeOverview,
  requestSave,
}) => {
  const [dateDueCurrent, setDateDue] = useState(dateDueSaved);
  const [remindersCurrent, setRemindersCurrent] = useState(remindersSaved);

  const dateMin = new Date();

  /**
   * Request that the due date is saved
   * dateDueSaved === null means that the user has selected no date,
   * So we use an hour from now by default
   */
  const requestDataSave = () => {
    requestSave(
      dateDueSaved === null ? getDateToUse(dateDueCurrent) : dateDueCurrent,
      remindersCurrent,
    );
    requestModeOverview();
  };

  /**
   * Request that the due date is removed from the message
   */
  const requestDateDueRemove = () => {
    setDateDue(null);
  };

  /**
   * Request that one new reminder is added to remindersCurrent
   *
   * @param reminder Reminder to add
   */
  const requestRemindersAdd = (reminder: Reminder) => {
    setRemindersCurrent(oldReminders => [...oldReminders, reminder]);
  };

  /**
   * Request that one reminder is changed
   *
   * @param position Array index of the reminder
   * @param reminder New reminder value
   */
  const requestRemindersSet = (position: number, reminder: Reminder) => {
    const remindersCopy = [...remindersCurrent];
    remindersCopy[position] = reminder;
    setRemindersCurrent(_oldReminders => [...remindersCopy]);
  };

  /**
   * Request that one reminder is removed / all reminders are removed
   *
   * @param position Array index of the reminder (if undefined remove all of the items)
   */
  const requestRemindersRemove = (position?: number) => {
    if (position === undefined) {
      setRemindersCurrent([]);
      return;
    }

    setRemindersCurrent(reminders =>
      reminders.filter((_reminder, index) => index !== position),
    );
  };

  return (
    <MetaDateDue
      dataDateDueCurrent={dateDueCurrent}
      dataDateDueMin={dateMin}
      dataDateDueSaved={dateDueSaved}
      dataRemindersCurrent={remindersCurrent}
      dataRemindersSaved={remindersSaved}
      isActive={true}
      onClose={requestModeOverview}
      requestDateDueRemove={requestDateDueRemove}
      requestDateDueSet={setDateDue}
      requestRemindersAdd={requestRemindersAdd}
      requestRemindersRemove={requestRemindersRemove}
      requestRemindersSet={requestRemindersSet}
      requestSave={requestDataSave}
    />
  );
};

export default Inner;
