import React, { FC } from 'react';

import { MessageCommon } from '../../../../models/message';
import { dateTimeFormatUi } from '../../../../utils/date/format';
import Archived from '../../../Archived';

import MessageInfoCreatorName from '../MessageInfoCreatorName';

import * as Styled from './styled';

type Props = Pick<
  MessageCommon,
  'createdAt' | 'creator' | 'creatorType' | 'isArchived'
>;

/**
 * Shows info about message creator and when its created
 *
 * @param props             Props passed to the component
 * @param props.createdAt   Time of message creation
 * @param props.creator     Author of the message
 * @param props.creatorType Message creator type
 * @param props.isArchived  Flag that indicates whether the message is archived
 * @returns                 The component itself
 */
const MessageInfoCreator: FC<Props> = ({
  createdAt = null,
  creator = null,
  creatorType,
  isArchived = false,
}) => {
  const date = new Date(createdAt ?? '');
  return (
    <Styled.Wrapper>
      <MessageInfoCreatorName creator={creator} creatorType={creatorType} />
      {/* In order to place <Archived/> in the same line (on mobile) */}
      {/* as <Time/> this wrapper was needed */}
      <div>
        <Styled.Time dateTime={createdAt ?? ''}>
          {dateTimeFormatUi(date)}
        </Styled.Time>
        <Archived isArchived={isArchived} />
      </div>
    </Styled.Wrapper>
  );
};

export default MessageInfoCreator;
