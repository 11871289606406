/**
 * @file React hook that handles height animation of collapsible elements
 */

import { useCallback, useEffect, useState } from 'react';

type CollapsibleStyle = {
  overflow: string;
  height: string;
};

type Props = {
  isActive: boolean;
};

type Return = [CollapsibleStyle, () => void];

/**
 * React hook that handles height animation of collapsible elements
 *
 * @param props          Props passed to the hook
 * @param props.isActive Active state of the component that decides if the collapsible is shown
 * @returns              CollapsibleStyle object
 */
const useCollapsibleStyles = ({ isActive }: Props): Return => {
  const [wrapperHeight, setWrapperHeight] = useState(isActive ? '100%' : '0');

  const callbackSetHeight = useCallback(() => {
    if (isActive === false) {
      setWrapperHeight('0');
    } else {
      setWrapperHeight('auto');
    }
  }, [isActive]);

  useEffect(() => {
    callbackSetHeight();
  }, [isActive, callbackSetHeight]);

  const wrapperStyles = {
    height: wrapperHeight,
    overflow: 'hidden',
  };

  return [wrapperStyles, callbackSetHeight];
};

export default useCollapsibleStyles;
