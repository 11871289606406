import React from 'react';
import { RouteProps } from 'react-router-dom';

import PageAuthHotelNotFound from '../pages/Error/AuthHotelNotFound';
import PageAuthUserNotFound from '../pages/Error/AuthUserNotFound';
import PageDeactivatedHotel from '../pages/Error/DeactivatedHotel';
import PageMissingHotel from '../pages/Error/MissingHotel';
import PageMissingTermsAndConditions from '../pages/Error/MissingTermsAndConditions';
import {
  createErrorAuthHotelNotFoundPath,
  createErrorAuthUserNotFoundPath,
  createErrorDeactivatedHotelPath,
  createErrorMissingHotelPath,
  createErrorMissingTermsPath,
} from '../paths/error';

const mappingRoutesError: RouteProps[] = [
  {
    element: <PageAuthUserNotFound />,
    path: createErrorAuthUserNotFoundPath(),
  },
  {
    element: <PageAuthHotelNotFound />,
    path: createErrorAuthHotelNotFoundPath(),
  },
  {
    element: <PageMissingHotel />,
    path: createErrorMissingHotelPath(),
  },
  {
    element: <PageDeactivatedHotel />,
    path: createErrorDeactivatedHotelPath(),
  },
  {
    element: <PageMissingTermsAndConditions />,
    path: createErrorMissingTermsPath(),
  },
];

export default mappingRoutesError;
