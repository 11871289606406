import React, { FC } from 'react';

import { ILLUSTRATION__CONCIERGE } from '../../../constants/illustrations';
import { KpiSingle } from '../../../models/dashboard';

import Illustration from '../../Common/Illustration';

import * as Styled from './styled';

export type Props = {
  concierges: KpiSingle[];
};

const ILLUSTRATION_HEIGHT = 200;
const ILLUSTRATION_WIDTH = 215;

/**
 * Desktop version of UI for kpi type CONCIERGES
 *
 * @param props            Props passed to the component
 * @param props.concierges Concierges to be rendered
 * @returns                The component itself
 */
const ConciergesDesktop: FC<Props> = ({ concierges }) => {
  if (concierges.length === 0) {
    return null;
  }

  return (
    <Styled.ConciergesWrapper>
      <Styled.ConciergeImageWrapper>
        <Illustration
          height={ILLUSTRATION_HEIGHT}
          name={ILLUSTRATION__CONCIERGE}
          width={ILLUSTRATION_WIDTH}
        />
      </Styled.ConciergeImageWrapper>
      <Styled.ConciergeDataWrapper>
        {concierges.map(({ amount, label, url }) => {
          return (
            <li key={label}>
              <Styled.ConciergeKpiSingle
                amount={amount}
                label={label}
                url={url}
              />
            </li>
          );
        })}
      </Styled.ConciergeDataWrapper>
    </Styled.ConciergesWrapper>
  );
};

export default ConciergesDesktop;
