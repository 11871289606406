import React, { FC, FormEventHandler } from 'react';

import { Reminder } from '../../../generated/graphql';
import PickerDateTime from '../../Common/PickerDateTime';
import Reminders from '../../Reminders';

import * as Styled from './styled';

type Props = {
  ariaDescribedBy: string;
  ariaLabelledBy: string;
  dataDateDueCurrent: Date | null;
  dataDateDueMin?: Date | null;
  dataRemindersCurrent: Reminder[];
  idForm: string;
  requestDateDueSet: (date: Date) => void;
  requestRemindersAdd: (reminder: Reminder) => void;
  requestRemindersRemove: (position?: number) => void;
  requestRemindersSet: (position: number, reminder: Reminder) => void;
  requestSave: () => void;
};

/**
 * Form to create/edit/remove due date and reminders
 *
 * @param props                        Props passed to the component
 * @param props.ariaDescribedBy        Accessible form description
 * @param props.ariaLabelledBy         Accessible form title
 * @param props.dataDateDueCurrent     Current due date
 * @param props.dataDateDueMin         Saved due date
 * @param props.dataRemindersCurrent   Current reminders
 * @param props.idForm                 The ID to use for the due date <form /> element
 * @param props.requestDateDueSet      Callback to be invoked when a due date is set
 * @param props.requestRemindersAdd    Request that the new reminder is added
 * @param props.requestRemindersRemove Request that the current reminder is removed (or all of the current reminders are removed)
 * @param props.requestRemindersSet    Request that the reminder is changed
 * @param props.requestSave            Request that the current due date and current reminders are saved
 * @returns                            The component itself
 */
const MessageDueDateForm: FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  dataDateDueCurrent,
  dataDateDueMin,
  dataRemindersCurrent,
  idForm,
  requestDateDueSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSet,
  requestSave,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the selected due date and reminders are saved
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    requestSave();
  };

  return (
    <Styled.Form
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      id={idForm}
      onSubmit={onSubmit}
    >
      <PickerDateTime
        dateCurrent={dataDateDueCurrent}
        dateMin={dataDateDueMin}
        requestSet={requestDateDueSet}
      />
      <Reminders
        isDisabled={dataDateDueCurrent === null}
        itemsCurrent={dataRemindersCurrent}
        requestRemindersAdd={requestRemindersAdd}
        requestRemindersRemove={requestRemindersRemove}
        requestRemindersSet={requestRemindersSet}
      />
    </Styled.Form>
  );
};

export default MessageDueDateForm;
