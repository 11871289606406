/**
 * @file ES pagination for urql
 */

import { stringifyVariables } from '@urql/core';
import { DataField, Resolver, Variables } from '@urql/exchange-graphcache';

import {
  MessageSearchQuery,
  MessageSearchQueryVariables,
  Scalars,
} from '../../../../generated/graphql';

/**
 * Function to compare arguments of queries in cache and arguments passed to the
 * most recent search query. If the arguments are the same, the goal is to group them
 * into one return result for the user so he can paginate over results, but if the
 * arguments are different, that means we have a new query.
 *
 * @param fieldArgs      Arguments passed to the most recent query
 * @param connectionArgs Arguments in cache
 * @returns              Whether the query arguments are the same
 */
const compareArgs = (
  fieldArgs: Variables,
  connectionArgs: Variables | null,
): boolean => {
  if (
    connectionArgs === null ||
    typeof fieldArgs.query !== 'string' ||
    typeof connectionArgs.query !== 'string'
  ) {
    return false;
  }

  /** @todo Figure out the type coming in and annotate accordingly */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { query: fieldQuery } = JSON.parse(fieldArgs.query);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { query: connectionQuery } = JSON.parse(connectionArgs.query);

  return JSON.stringify(fieldQuery) === JSON.stringify(connectionQuery);
};

/**
 * Ensures that the cache key exists and
 * gives proper Typescript support
 *
 * @param value Resolved cache value
 * @returns     Resolved key or null if not resolved
 */
const ensureKey = (value: DataField): string | null =>
  typeof value === 'string' ? value : null;

/**
 * Handles Elastic Search pagination by adding up the results that have the
 * same searchQuery into one array in cache.
 *
 * @see https://formidable.com/open-source/urql/docs/graphcache/local-resolvers/#simple-pagination
 * @param _parent   The object on which the field will be added to
 * @param fieldArgs The arguments that the field is being called with
 * @param cache     urql cache instance
 * @param info      Running information about the traversal of the query document
 * @returns         URQL cache resolver
 */
export const esPagination: Resolver<
  MessageSearchQuery,
  MessageSearchQueryVariables
> = (_parent, fieldArgs, cache, info) => {
  const { parentKey: entityKey, fieldName } = info;

  /**
   * All cache fields from all queries made in the app
   */
  const allFields = cache.inspectFields(entityKey);

  /**
   * All cache fields that have the same name as the
   * query that is in question (aka messageSearch)
   */
  const fieldInfos = allFields.filter(filedInfo => {
    return filedInfo.fieldName === fieldName;
  });
  const size = fieldInfos.length;

  if (size === 0) {
    return null;
  }

  /**
   * Used to check if entity is already in cache
   */
  const fieldKey = `${fieldName}(${stringifyVariables(fieldArgs)})`;
  const isItInTheCache = cache.resolve(entityKey, fieldKey);

  /**
   * Let urql know if to fetch more data from the server, if it is true, urql
   * will think we did not give it all the data and will fetch it from the server
   */
  info.partial = !isItInTheCache;

  /**
   * Array to store results that will be sent as the result after pagination
   */
  const result: Scalars['ID']['output'][] = [];
  let __typename;
  let searchInfo;

  fieldInfos.forEach(fi => {
    const key = cache.resolve(entityKey, fi.fieldKey);
    const link = key === undefined ? null : ensureKey(key);

    /**
     * If no arguments, cache value or if arguments from cache,
     * and arguments from the request are not the same (aka user has made a new search)
     * -> skip that fieldInfo when iterating over them
     */
    if (
      link === null ||
      fi.arguments === null ||
      compareArgs(fieldArgs, fi.arguments) === false
    ) {
      return;
    }

    /**
     * Resolve __typename, nodes and searchInfo needed
     * to mimic the result from the query
     */
    __typename = cache.resolve(link, '__typename') as string;
    const nodes = (cache.resolve(link, 'nodes') ?? []) as string[];
    searchInfo = cache.resolve(link, 'searchInfo');

    /**
     * Push new results at the end, later we can
     * edit this if needed to be reversed
     */
    result.push(...nodes);
  });

  // Nothing to put in the cache
  if (result.length === 0) {
    return;
  }

  return {
    __typename,
    /**
     * nodes has to be returned as a Set in order to filter out duplicate search results
     * cause 2 different searches can yield same result message
     */
    nodes: Array.from(new Set(result)),
    searchInfo,
  };
};
