import React, { FC } from 'react';

import Progress from '../Progress';

type Props = {
  percentage: number;
};

/**
 * Progress bar for readBy segment
 *
 * @param props            Props passed to the component
 * @param props.percentage Percentage of people that have read a message
 * @returns                The component itself
 */
const ProgressReadBy: FC<Props> = ({ percentage }) => (
  <Progress color="var(--color-icons-primary)" max={100} value={percentage} />
);

export default ProgressReadBy;
