/**
 * @file contains helper function for cloning dates
 */

/**
 * Clone passed date
 *
 * @param date Date to clone
 * @returns    Cloned date
 */
export const cloneDate = (date: Date | null): Date | null => {
  if ('structuredClone' in globalThis) {
    return structuredClone(date);
  }

  if (date === null) {
    return null;
  }

  return new Date(date);
};
