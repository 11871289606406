/**
 * @file contains reducer function for deepl translations
 */
import {
  TRANSLATIONS__TRANSLATE_MESSAGE,
  TRANSLATIONS__UNTRANSLATE_MESSAGE,
} from '../../constants/actionTypes';
import {
  TranslateMessage,
  TranslationsAction,
  TranslationsState,
  UntranslateMessage,
} from '../../models/translations';
import initialState from '../initialState';

/**
 * Reducer for subscribe state
 */
type Reducer = (
  state: TranslationsState,
  action: TranslationsAction,
) => TranslationsState;

/**
 * Request to translate a message
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onTranslateMessage = (
  state: TranslationsState,
  action: TranslateMessage,
): TranslationsState => {
  const { messageId, translation } = action.payload;

  // Do nothing if it already exists (failsafe)
  if (state.messages.has(messageId)) {
    return state;
  }

  const messages = new Map(state.messages);

  messages.set(messageId, translation);

  return {
    ...state,
    messages,
  };
};
/**
 * Request to untranslate a message
 *
 * @param state  The current subscribe state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onUntranslateMessage = (
  state: TranslationsState,
  action: UntranslateMessage,
): TranslationsState => {
  const { messageId } = action.payload;

  const messages = new Map(state.messages);

  const isDeleted = messages.delete(messageId);

  if (isDeleted) {
    return {
      ...state,
      messages,
    };
  }

  return state;
};

/**
 * action:reducer mapping for subscribe
 */
const mapping = new Map<string, Reducer>([
  [TRANSLATIONS__TRANSLATE_MESSAGE, onTranslateMessage],
  [TRANSLATIONS__UNTRANSLATE_MESSAGE, onUntranslateMessage],
]);

/**
 * Reducer for all subscriptions actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const translations: Reducer = (state = initialState.translations, action) => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default translations;
