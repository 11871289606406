import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import { reportError } from '../../../../../services/reporting';
import { DataType } from '../helpers';

import * as Styled from './styled';

export type Reason = 'empty' | 'error' | 'fetching' | 'searching';

export type Props = {
  dataType: DataType;
  reason: Reason;
};

/**
 * Mapping between no result reason and the translations key
 */
const mapping = new Map<`${DataType}-${Reason}`, string>([
  ['template-empty', 'TEMPLATE__GENERAL__NO_RESULT_EMPTY'],
  ['template-error', 'TEMPLATE__GENERAL__NO_RESULT_ERROR'],
  ['template-fetching', 'GENERAL__PLEASE_WAIT'],
  ['template-searching', 'TEMPLATE__GENERAL__NO_RESULT_EMPTY'],
  ['topic-empty', 'TEMPLATE__TOPICS__NO_RESULT_EMPTY'],
  ['topic-error', 'TEMPLATE__TOPICS__NO_RESULT_ERROR'],
  ['topic-fetching', 'GENERAL__PLEASE_WAIT'],
  ['topic-searching', 'TEMPLATE__TOPICS__NO_RESULT_EMPTY'],
]);

/**
 * Get the message to show, explaining why there's nothing to show
 *
 * @param dataType Type of data rendered inside of list
 * @param reason   The reason we want the message for
 * @returns        The (translated) message
 */
const getMessage = (dataType: DataType, reason: Reason): string => {
  const message = mapping.get(`${dataType}-${reason}`);
  if (message === undefined) {
    reportError(`Message ${dataType}-${reason} not found`);
  }

  return message ? translate(message) : '';
};

/**
 * No result for list
 *
 * @param props          Props passed to the component
 * @param props.dataType Type of data used inside of list
 * @param props.reason   Why is there nothing to show
 * @returns              The component itself
 */
const ListNoResult: FC<Props> = ({ dataType, reason }) => {
  return <Styled.Title>{getMessage(dataType, reason)}</Styled.Title>;
};

export default ListNoResult;
