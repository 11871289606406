import React, { FC } from 'react';

import MessageSection from '..';
import { HASH_MESSAGE__INFO__CHECKLIST } from '../../../../constants/routing/hashLinks';

import { ChecklistItemBasicFragment } from '../../../../generated/graphql';
import translate from '../../../../i18n/translate';
import { MessageTranslation } from '../../../../models/translations';

import { canReadItemsForExistingMessage } from '../../../../utils/permissions/checklist/checklistRead';
import ChecklistItems from '../../../Common/ChecklistItems';

import MessageNoResult from '../../../Common/MessageNoResult';
import {
  TypeChecklistItemRequestCreate,
  TypeChecklistItemRequestDelete,
  TypeChecklistItemRequestReorder,
  TypeChecklistItemRequestSetState,
  TypeChecklistItemRequestSetText,
} from '../../props/checklist';

import { transformChecklist } from './helpers';

import * as Styled from './styled';

type Props = {
  className?: string;
  items: ChecklistItemBasicFragment[];
  requestCreate?: TypeChecklistItemRequestCreate;
  requestDelete: TypeChecklistItemRequestDelete;
  requestReorder: TypeChecklistItemRequestReorder;
  requestSetState: TypeChecklistItemRequestSetState;
  requestSetText: TypeChecklistItemRequestSetText;
  translation: MessageTranslation['checklist'] | undefined;
};

/**
 * Checklist section for full message view
 *
 * @param props                 Props passed to the component
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.items           Checklist items to show
 * @param props.requestCreate   Callback to request that a new item is added
 * @param props.requestDelete   Callback to request that an item is removed
 * @param props.requestReorder  Callback to request that an item's position is updated
 * @param props.requestSetState Callback to request that an item's state (whether it's checked) is updated
 * @param props.requestSetText  Callback to request that an item's text is updated
 * @param props.translation     Translation for the checklist items
 * @returns                     The component itself
 */
const MessageSectionChecklist: FC<Props> = ({
  className,
  items,
  requestCreate = null,
  requestDelete,
  requestReorder,
  requestSetState,
  requestSetText,
  translation,
}) => {
  if (canReadItemsForExistingMessage() === false) {
    return null;
  }

  const itemsTranslated = transformChecklist(items, translation);
  const itemsExisting = items.map(item => item.text);

  return (
    <MessageSection
      className={className}
      heading={<Styled.Heading items={items} />}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__CHECKLIST}
    >
      <ChecklistItems
        items={itemsTranslated}
        mode="EXISTING"
        noResult={<MessageNoResult message={translate('CHECKLIST__EMPTY')} />}
        requestDelete={requestDelete}
        requestReorder={requestReorder}
        requestSetState={requestSetState}
        requestSetText={requestSetText}
      />
      {requestCreate !== null && (
        <Styled.FormCreate
          itemsExisting={itemsExisting}
          requestCreate={requestCreate}
        />
      )}
    </MessageSection>
  );
};

export default MessageSectionChecklist;
