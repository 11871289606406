/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { ROUTE__DEV_PANEL } from '../../constants/routing/routes';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

import { getNoMatchUrl } from './404';

/**
 * Get the url for the /dev-panel route
 *
 * @param hasPermission Has permission to view dev panel
 * @param hotelId       ID of the hotel
 * @returns             A string in a url format
 */
export const getDevPanelUrl = (
  hasPermission: boolean,
  hotelId: Hotel['id'] | null,
): string | null => {
  if (hotelId === null) {
    reportError('HotelId passed to getDevPanelUrl was null');
    return getNoMatchUrl();
  }

  return hasPermission ? `/${hotelId}/${ROUTE__DEV_PANEL}` : null;
};
