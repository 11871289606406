/**
 * @file Helper functions for composeMetaText container
 */

import { EditorUsage } from '../../../components/Common/Editor/types';
import { ComposeNodeType } from '../props';

/**
 * Map compose node type to editor usage for large screens,
 * so that correct controls (and styles) are shown
 */
const usageMappingDesktop: Record<ComposeNodeType, EditorUsage> = {
  MESSAGE: 'message',
  TEMPLATE: 'template',
};

/**
 * Map compose node type to editor usage for small screens,
 * so that correct controls (and styles) are shown
 */
const usageMappingMobile: Record<ComposeNodeType, EditorUsage> = {
  MESSAGE: 'messageEdit',
  TEMPLATE: 'templateMobile',
};

/**
 * Get value for the Editor usage prop
 *
 * @param isMobile Whether the user is on a small screen
 * @param nodeType Whether the compose form is being used for messages or templates
 * @returns        The Editor usage to use
 */
export const getUsage = (isMobile: boolean, nodeType: ComposeNodeType) => {
  const mapper = isMobile ? usageMappingMobile : usageMappingDesktop;
  return mapper[nodeType];
};
