import { styled } from 'styled-components';

import devices from '../../../../styles/devices';
import ButtonCancelCommon from '../../../Common/ButtonCancel';
import ButtonSaveCommon from '../../../Common/ButtonSave';

export const Footer = styled.footer`
  background-color: var(--color-background-selected);
  display: flex;
  gap: var(--spacing-small);
  padding: var(--spacing-small) var(--spacing-medium);

  @media ${devices.tabletS} {
    background-color: inherit;
    padding: var(--spacing-small) 0 0;
  }
`;

export const ButtonCancel = styled(ButtonCancelCommon)`
  padding: var(--spacing-small) 0;
  width: 100%;
`;

export const ButtonSave = styled(ButtonSaveCommon)`
  padding: var(--spacing-small) 0;
  width: 100%;
`;
