/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Location } from 'react-router-dom';

import { SEARCH_PARAM__SIDEBAR } from '../../../constants/routing/searchParams';
import {
  modifyUrlParams,
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';
import { getSearchParamSidebar } from '../../helpers/searchParams';

/**
 * Gets url for opening the sidebar
 *
 * @deprecated Use getSidebarToggleUrl instead
 *
 * @param      location Location object from the history
 * @returns             A string in a url format
 */
export const getOpenSidebarUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, 'sidebar');
};

/**
 * Gets url for closing the sidebar
 *
 * @deprecated Use getSidebarToggleUrl instead
 *
 * @param      location Location object from the history
 * @returns             A string in a url format
 */
export const getCloseSidebarUrl = (location: Location): string => {
  return replaceOrAddParamsToUrl(location, { sidebar: 'closed' });
};

/**
 * Gets url for opening or closing the sidebar based on current location
 * and selected item in navigation
 *
 * @param location Location object from the history
 * @param tab      Tab item
 * @returns        A string in a url format
 */
export const getSidebarToggleUrl = (
  location: Location,
  tab: string,
): string => {
  const activeSearchParam = getSearchParamSidebar(location);
  const isOpened = activeSearchParam !== null;
  const isSelected = tab === activeSearchParam;

  const shouldRemove = isSelected === true && isOpened === true;

  const newUrl = modifyUrlParams(location, {
    params: shouldRemove ? {} : { [SEARCH_PARAM__SIDEBAR]: tab },
    paramsToRemove: shouldRemove ? [SEARCH_PARAM__SIDEBAR] : [],
  });

  return newUrl;
};
