/**
 * @file Optimistically update the cache, update user data
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MutationUserUpdateArgs,
  UserUpdateMutation,
} from '../../../../../generated/graphql';
import { getSettingsUserById } from '../../updates/utils/getters/settings';

/**
 * Optimistically update a user
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Optimistic updates or null
 */
const userUpdate: OptimisticMutationResolver<
  MutationUserUpdateArgs,
  UserUpdateMutation['userUpdate'] | null
> = (args, cache) => {
  const user = getSettingsUserById(cache, args.id);

  if (user === null) {
    return null;
  }
  return {
    ...user,
    __typename: 'User',
    targetLanguage: args.data.targetLanguage ?? user.targetLanguage,
  };
};

export default userUpdate;
