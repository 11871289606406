import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import { ItemDraft } from '../../../../Common/ChecklistItems/props';
import MessageNoResult from '../../../../Common/MessageNoResult';

import ComposeMetaSection from '../ComposeMetaSection';

import ChecklistItemsPreview from './ChecklistItemsPreview';

import * as Styled from './styled';

type Props = {
  items: ItemDraft[];
};

/**
 * Section in the message compose that displays the checklist
 *
 * @param props       Props passed to the component
 * @param props.items Checklist items to show
 * @returns           The component itself
 */
const ComposeMetaChecklist: FC<Props> = ({ items }) => {
  return (
    <ComposeMetaSection heading={<Styled.ProgressChecklist items={items} />}>
      <ChecklistItemsPreview
        items={items}
        noResult={<MessageNoResult message={translate('CHECKLIST__EMPTY')} />}
      />
    </ComposeMetaSection>
  );
};

export default ComposeMetaChecklist;
