/**
 * @file Mutation for logging users in with an auth code
 */
export const loginMutation = `
    mutation Login($code: String!) {
      login(code: $code) {
        isFirstLogin
        user {
          id
          firstName
          lastName
          email
          language
          hotelPermissions {
            hotel {
              id
              uuid
              name
              imageUrl
            }
            permissions
          }
          imageUrl
        }
        token
      }
    }`;
