import { styled } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const RadioList = styled.ul`
  display: flex;
  flex-direction: column;

  /* In order to prevent styling of nested <Select><li/><Select/> we need to target first child <li/> */
  & > li {
    /* Remove padding from last child in order to make it same hight as others, requested by Laura in designs */
    &:not(:last-child) {
      padding: var(--spacing-tiny) 0;
    }

    @media (hover: hover) {
      /* Don't add hover styles to the last child because last child is custom offset picker */
      &:not(:last-child):hover {
        background-color: var(--color-background-selected);
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: var(--font-baseline);
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-tiny);
`;
