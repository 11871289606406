import React, { FC, ReactNode } from 'react';

import useIsMobile from '../../hooks/useIsMobile';

import RightSidebarDesktop from './RightSidebarDesktop';
import RightSidebarMobile from './RightSidebarMobile';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * Right sidebar component
 *
 * @param props          Props passed to the component
 * @param props.children Children of the component
 * @returns              The component itself
 */
const RightSidebar: FC<Props> = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <RightSidebarMobile>{children}</RightSidebarMobile>;
  }

  return <RightSidebarDesktop>{children}</RightSidebarDesktop>;
};

export default RightSidebar;
