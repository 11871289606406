import { styled } from 'styled-components';

import CommonInput from '../Input';

// The input itself
export const Input = styled(CommonInput)`
  display: block;

  /**
   * made 2px narrower because of the focus outline
   * (it would get cut off otherwise)
   */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin: 0 1px var(--spacing-medium);
  width: calc(100% - 2px);
`;
