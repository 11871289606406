/**
 * @file Toggle message translate/untranslate hook
 */
import { useEffect } from 'react';
import { useQuery } from 'urql';

import {
  Message,
  MessageFullDocument,
  MessageTranslationDocument,
} from '../../generated/graphql';
import translate from '../../i18n/translate';
import { ToastMessageArgs } from '../../models/toastMessage';
import { trackTranslationEvent } from '../../services/matomo/trackTranslationEvent';
import { TranslationContainer } from '../../services/matomo/types';
import useParamHotelId from '../router/params/useParamHotelId';
import useParamTopicId from '../router/params/useParamTopicId';

import useToast from './useToast';
import useTranslatedMessages from './useTranslatedMessages';
import useTranslationsActions from './useTranslationsActions';

type UseMessageTranslationReturn = {
  isLoading: boolean;
  requestTranslationToggle: () => void;
};

/**
 * Get toast message arguments for a bad request
 *
 * @returns Toast message arguments
 */
const getToastMessageArgs = (): ToastMessageArgs => ({
  text: translate('TOAST__ERROR__TRANSLATIONS_NOT_FETCHED__DESCRIPTION'),
  title: translate('TOAST__ERROR__TRANSLATIONS_NOT_FETCHED__TITLE'),
  type: 'error',
});

/**
 * Toggle message translate/untranslate hook
 *
 * @param messageId Id of the message
 * @param container Container where the button is displayed
 * @returns         Translations state from redux
 */
const useMessageTranslationToggle = (
  messageId: Message['id'],
  container: TranslationContainer,
): UseMessageTranslationReturn => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const addToastMessage = useToast();

  const { requestTranslateMessageAction, requestUntranslateMessageAction } =
    useTranslationsActions();

  const translatedMessages = useTranslatedMessages();

  const options = {
    pause: true,
    requestPolicy: 'network-only',
    variables: { messageId },
  } as const;

  const [{ data, error, fetching }, executeQuery] = useQuery({
    ...options,
    query: MessageTranslationDocument,
  });

  const [
    {
      data: messageFullData,
      error: messageFullError,
      fetching: messageFullFetching,
    },
    executeMessageFullQuery,
  ] = useQuery({ ...options, query: MessageFullDocument });

  /**
   * This effect will react to changes in data or error regarding the translations
   */
  useEffect(() => {
    if (!data && !error) {
      return;
    }

    if (error) {
      addToastMessage(getToastMessageArgs());
      return;
    }

    if (data?.message?.translation !== undefined) {
      requestTranslateMessageAction(messageId, {
        checklist: data.message.checklist.map(checklistItem => ({
          id: checklistItem.id,
          text: checklistItem.translation.text,
        })),
        text: data.message?.translation.text,
        title: data.message?.translation.title,
      });
    }
  }, [addToastMessage, data, error, messageId, requestTranslateMessageAction]);

  useEffect(() => {
    if (!data?.message) {
      return;
    }

    trackTranslationEvent(hotelId, {
      container,
      message: data.message,
      operation: 'translated',
      topicId,
    });
    // We don't want to track an event when hotelId or topicId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, data]);

  /**
   * This effect will react to changes in data or error regarding
   * fetch of the original message (un-translating of the message)
   */
  useEffect(() => {
    if (!messageFullData && !messageFullError) {
      return;
    }

    if (messageFullError) {
      addToastMessage(getToastMessageArgs());
      return;
    }

    if (messageFullData && messageFullData.message !== undefined) {
      requestUntranslateMessageAction(messageId);
    }
  }, [
    addToastMessage,
    messageFullData,
    messageFullError,
    messageId,
    requestUntranslateMessageAction,
  ]);

  useEffect(() => {
    if (!messageFullData?.message) {
      return;
    }

    trackTranslationEvent(hotelId, {
      container,
      message: messageFullData.message,
      operation: 'untranslated',
      topicId,
    });
    // We don't want to track an event when hotelId or topicId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, messageFullData]);

  if (translatedMessages.has(messageId)) {
    return {
      isLoading: messageFullFetching,
      requestTranslationToggle: () => executeMessageFullQuery(),
    };
  }
  return {
    isLoading: fetching,
    requestTranslationToggle: () => executeQuery(),
  };
};

export default useMessageTranslationToggle;
