/**
 * @file All permissions checks related to templates
 */
import { Template, Topic } from '../../generated/graphql';
import { PermissionsTemplate } from '../../models/permissions';
import { reportError } from '../../services/reporting';

import {
  TEMPLATE__ADMIN,
  TEMPLATE__CREATE,
  TEMPLATE__DELETE,
  TEMPLATE__UPDATE,
  TEMPLATE__VIEW,
} from './permissionsAll';
import { getIsBasic, getPermissionsCurrent, getUserCurrent } from './storage';

/**
 *
 * HELPER
 *
 */

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsTemplate): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the current user has admin permissions for templates
 *
 * @returns Whether it's true
 */
const getIsAdmin = (): boolean => {
  return getPermissionsCurrent().includes(TEMPLATE__ADMIN);
};

/**
 * Check whether the current user is author of a specific template
 *
 * @param templateCreator The template creator to check permissions for
 * @returns               Whether it's true
 */
const getIsAuthor = (templateCreator: Template['creator']): boolean => {
  const user = getUserCurrent();
  return user !== null && user.id === templateCreator.id;
};

/**
 * Check whether the current user is a member of a specified topic
 *
 * @param topic The topic to check
 * @returns     Whether it's true
 */
export const getIsMember = (topic: Topic): boolean => {
  const user = getUserCurrent();

  if (user === null) {
    reportError('getIsMember: Reading user current before initializing it.');
    return false;
  }

  if (topic.members.findIndex(member => member.id === user.id) !== -1) {
    return true;
  }

  if (topic.isPrivate === false) {
    return true;
  }

  return (
    topic.groups.filter(group =>
      group.members.some(member => member.id === user.id),
    ).length !== 0
  );
};

/**
 *
 * CREATE
 *
 */

/**
 * Check whether the user has permissions
 * to create a template
 *
 * @returns Whether the user has permissions
 */
export const getCanCreateTemplate = (): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  return getIsAdmin() || hasPermission(TEMPLATE__CREATE);
};

/**
 *
 * READ
 *
 */

/**
 * Check whether the current user can view templates
 *
 * @returns Whether the user has permissions
 */
export const getCanViewTemplatesTable = (): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  return getIsAdmin() || hasPermission(TEMPLATE__VIEW);
};

/**
 *
 * UPDATE
 *
 */

/**
 * Check whether a user is allowed to update template data
 * (title, description...)
 *
 * @param template The template creator to check for
 * @returns        Whether the user has permissions
 */
export const getCanUpdateTemplate = (template: Template): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(TEMPLATE__UPDATE) === false) {
    return false;
  }

  return getIsAuthor(template.creator) && getIsMember(template.topic);
};

/**
 *
 * DELETE
 *
 */

/**
 * Check whether the user is allowed to delete a specific template
 *
 * @param template The template creator to check for
 * @returns        Whether the user has permissions
 */
export const getCanDeleteTemplate = (template: Template): boolean => {
  if (getIsBasic() === true) {
    return false;
  }

  if (getIsAdmin()) {
    return true;
  }

  if (hasPermission(TEMPLATE__DELETE) === false) {
    return false;
  }

  return getIsAuthor(template.creator) && getIsMember(template.topic);
};

/**
 * Check whether the user is allowed to delete recurring rule
 *
 * @param rule The rule to check for
 * @returns    Whether the user has permissions
 */
export const getCanDeleteTemplateRecurringRule = (
  rule: Partial<Template['recurringRule']>,
): boolean => {
  return rule !== undefined && rule !== null;
};
