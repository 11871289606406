/**
 * @file Helper functions for AppLayout
 */
import { ReactNode } from 'react';

import { RightAsideProps } from './styledProps';

/**
 * Get max-width for the right sidebar
 * (only should be applied when it's open)
 *
 * @param props Props passed to the component
 * @returns     Valid value for max width property
 */
export const getRightSidebarMaxWidthDesktop = (
  props: RightAsideProps,
): string => {
  if (props['data-should-show-desktop']) {
    return 'var(--preference-right-sidebar-open-max-width-desktop)';
  }

  return 'none';
};

/**
 * Get min-width for the right sidebar
 * (only should be applied when it's open)
 *
 * @param props Props passed to the component
 * @returns     Valid value for min width property
 */
export const getRightSidebarMinWidthDesktop = (
  props: RightAsideProps,
): string => {
  if (props['data-should-show-desktop']) {
    return 'var(--preference-right-sidebar-open-min-width-desktop)';
  }

  return '0';
};

/**
 * Returns <ToastMessage/> | null for desktop
 *
 * @param element  JSX element (toast message component)
 * @param isMobile Tells us are we on mobile or not
 * @returns        JSX element (toast message component) or null
 */
export const getToastMessageFooter = (
  element: ReactNode,
  isMobile: boolean,
) => {
  return isMobile === false ? element : null;
};

/**
 * Returns <ToastMessage/> | null for mobile
 *
 * @param element  JSX element (toast message component)
 * @param isMobile Tells us are we on mobile or not
 * @returns        JSX element (toast message component) or null
 */
export const getToastMessageHeader = (
  element: ReactNode,
  isMobile: boolean,
) => {
  return isMobile ? element : null;
};
