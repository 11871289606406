import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import BaseHeaderDesktop from '../../../../components/Header/BaseHeaderDesktop';
import TopicVisibilityIcon from '../../../../components/Header/TopicVisibilityIcon';
import MuiIcon from '../../../../components/MUI/MuiIcon';
import { TopicWithMembersDocument } from '../../../../generated/graphql';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useIsTablet from '../../../../hooks/useIsTablet';
import translate from '../../../../i18n/translate';
import { getTopicDetailsUrl } from '../../../../routes/urls/topic';
import { reportError } from '../../../../services/reporting';
import { getCanUpdateTopic } from '../../../../utils/permissions/topic';

import { getFeedKanbanSegmentedButtons } from '../helpers';
import FeedKanbanQuickFilters from '../quickFilters';

import mapStateToProps from './mapStateToProps';
import { Props } from './props';

/**
 * The container for the topic header on the home page
 * Fetches the currently selected topic
 *
 * @returns The component itself
 */
const FeedKanbanHeaderDesktop: FC<Props> = () => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();
  const topicId = useParamTopicId();

  const [{ data, error }] = useQuery({
    pause: topicId === null,
    query: TopicWithMembersDocument,
    variables: { id: topicId ?? '' },
  });

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  const topic = data?.topic;
  const topicDetailsUrl = getTopicDetailsUrl(
    hotelId,
    isTablet,
    location,
    topicId,
  );

  const { description = null, title = null, isPrivate = true } = topic ?? {};

  const actionButtonRight =
    topic !== undefined && getCanUpdateTopic(topic)
      ? {
          icon: <MuiIcon icon="edit" />,
          label: translate('TOPIC__EDIT'),
          url: topicDetailsUrl,
        }
      : null;

  return (
    <BaseHeaderDesktop
      actionButtonRight={actionButtonRight}
      buttons={[]}
      description={description}
      leadingVisual={<TopicVisibilityIcon isPrivate={isPrivate} />}
      segmentedButtons={getFeedKanbanSegmentedButtons(
        hotelId,
        location,
        topicId,
      )}
      subtitle={null}
      title={title}
    >
      <FeedKanbanQuickFilters />
    </BaseHeaderDesktop>
  );
};

export default connect(mapStateToProps)(FeedKanbanHeaderDesktop);
