/**
 * @file Hook for message pagination
 */

import { useCallback, useEffect } from 'react';

import useMessageAccessible from '../../../hooks/message/useMessageAccessible';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';

import useMessagesQuery from '../useMessagesQuery';

import { getIsInitialLoad } from './helpers';
import useMessagesPaginationCursors from './useMessagesPaginationCursors';

/**
 * Message pagination hook
 *
 * @returns Set of pagination state, state change actions and messages query response
 */
const useMessagesPagination = () => {
  const topicId = useParamTopicId();
  const paramMessageId = useParamMessageId();
  const [messageAccessible, isMessageAccessibleFetching] =
    useMessageAccessible(paramMessageId);

  const {
    paginationActionDown,
    paginationActionReset,
    paginationActionResetFirstPage,
    paginationActionUp,
    paginationState,
  } = useMessagesPaginationCursors();

  /**
   * Reset the pagination params when user changes topic
   */
  useEffect(() => {
    if (topicId === null) {
      return;
    }

    paginationActionReset();
  }, [paginationActionReset, topicId]);

  const response = useMessagesQuery(paginationState);

  /**
   * Reset pagination to first page if there is an attempt to use a deleted or
   * archived message as a cursor.
   */
  useEffect(() => {
    if (
      paramMessageId !== null &&
      isMessageAccessibleFetching === false &&
      messageAccessible?.isArchived !== false
    ) {
      paginationActionResetFirstPage();
    }
  }, [
    isMessageAccessibleFetching,
    messageAccessible,
    paginationActionResetFirstPage,
    paramMessageId,
  ]);

  const {
    nodes,
    pageInfo: { hasNextPage, hasPreviousPage },
  } = response;

  const firstMessage = nodes.at(0)?.id;
  const lastMessage = nodes.at(-1)?.id;

  const requestLoadMoreAfter = useCallback(() => {
    if (lastMessage === undefined) {
      return;
    }

    paginationActionUp(lastMessage);
  }, [lastMessage, paginationActionUp]);

  const requestLoadMoreBefore = useCallback(() => {
    if (firstMessage === undefined) {
      return;
    }

    paginationActionDown(firstMessage);
  }, [firstMessage, paginationActionDown]);

  return {
    isInitialLoad: getIsInitialLoad(
      response.nodes,
      response.isFetching,
      isMessageAccessibleFetching,
    ),
    paginationState,
    requestLoadMoreAfter:
      hasNextPage && lastMessage !== undefined ? requestLoadMoreAfter : null,
    requestLoadMoreBefore:
      hasPreviousPage && firstMessage !== undefined
        ? requestLoadMoreBefore
        : null,
    response,
  };
};

export default useMessagesPagination;
