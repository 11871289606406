/**
 * @file Hook for determining whether push notifications are supported
 */

import { useEffect, useState } from 'react';

import { checkWebPushSupport } from '../../serviceWorker';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import useWebViewDeviceInfo from '../webview/useWebViewDeviceInfo';

const isReactNativeWebView = getIsReactNativeWebView();

/**
 * Hook for determining whether push notifications are supported
 *
 * @returns Whether it's true (null while processing)
 */
const useIsPushSupported = (): boolean | null => {
  const { deviceInfo } = useWebViewDeviceInfo();
  const [isPushSupported, setIsPushSupported] = useState<boolean | null>(null);

  const hasPushSupportNative = deviceInfo?.hasPushSupport ?? null;
  useEffect(() => {
    if (isReactNativeWebView) {
      setIsPushSupported(hasPushSupportNative);
    } else {
      setIsPushSupported(checkWebPushSupport());
    }
  }, [hasPushSupportNative]);

  return isPushSupported;
};

export default useIsPushSupported;
