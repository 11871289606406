/**
 * @file contains helper functions for MessageSectionAttachments component
 */
import { File as CustomFile } from '../../../generated/graphql';
import {
  Attachment,
  AttachmentDraft,
  AttachmentDraftMapEntry,
  ExtensionType,
} from '../../../models/attachment';

/**
 * Combine already attached files with draft attachments
 *
 * @param files               Files attached to the current message
 * @param attachmentsDraftMap Attachments draft
 * @returns                   Array with attachments of the current message + draft attachments
 */
export const getCombinedAttachments = (
  files: CustomFile[],
  attachmentsDraftMap: Map<string, AttachmentDraftMapEntry>,
): (Attachment | AttachmentDraft)[] => {
  const attachments: Attachment[] = files.map(file => ({
    extension: file.extension as ExtensionType,
    file,
    id: file.id,
    key: file.key,
    name: file.name,
  }));

  const attachmentsDraft = Array.from(
    attachmentsDraftMap,
    ([key, { file, extension, isDraft, isUploading, name }]) => ({
      extension,
      file,
      isDraft,
      isUploading,
      key,
      name,
    }),
  );

  return [...attachments, ...attachmentsDraft];
};
