/**
 * @file contains all keyboard shortcuts used in the app
 */

export type Shortcut = {
  altKey: boolean;
  ctrlKey: boolean;
  /** @see http://keycode.info/ */
  code: string;
  // A unique identifier for the shortcut,
  // preventing double registration
  key: string;
  // ⌘ on macOS ⊞ on Windows
  metaKey: boolean;
  shiftKey: boolean;
};

/**
 * Keyboard shortcut to show/hide dev panel
 * (will only be used on local and dev environments)
 */
export const DEV_PANEL__TOGGLE: Shortcut = {
  altKey: true,
  code: 'KeyD',
  ctrlKey: false,
  key: 'dev-panel-toggle',
  metaKey: false,
  shiftKey: true,
};
