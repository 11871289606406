import { styled } from 'styled-components';

import ChecklistFormCreate from '../../../Common/ChecklistFormCreate';
import ProgressChecklist from '../../../Common/ProgressChecklist';

export const Heading = styled(ProgressChecklist)`
  margin-bottom: 0;
`;

export const FormCreate = styled(ChecklistFormCreate)`
  margin-top: var(--spacing-small);
`;
