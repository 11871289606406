import { Stack, styled } from '@mui/material';

import { colorNeutral } from '../../../../styles/mui/colors';

export const StyledNavigation = styled('nav')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: theme.spacing(3.75),
  paddingInline: 'var(--spacing-medium)',
  width: '100%',
}));

export const Wrapper = styled(Stack)(() => ({
  backgroundColor: colorNeutral[100],
  minWidth:
    'calc(var(--preference-left-sidebar-open-width-desktop) - var(--preference-left-sidebar-closed-width-desktop))',
}));

export const TopContentWrapper = styled(Stack)({
  gap: 'var(--spacing-small)',
  padding:
    'var(--spacing-medium) var(--spacing-medium) 0 var(--spacing-medium)',
});
