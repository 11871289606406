// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Commented variables are not currently used but are supported by our current type face */
:root {
  /* Font weight basic variables */
  /* --font-thin: 100; */
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  /* --font-extra-bold: 800; */
  --font-black: 900;

  /* Variables per usage */
  --font-weight-avatar-initials: var(--font-bold);
  --font-weight-button-icon: var(--font-black);
  --font-weight-comment-author: var(--font-bold);
  --font-weight-form-label: var(--font-bold);
  --font-weight-label-bold: var(--font-semibold);
  --font-weight-label-regular: var(--font-light);
  --font-weight-heading: var(--font-bold);
  --font-weight-strong: var(--font-bold);
  --font-weight-text: var(--font-normal);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_font-weight.css"],"names":[],"mappings":"AAAA,0FAA0F;AAC1F;EACE,gCAAgC;EAChC,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,4BAA4B;EAC5B,iBAAiB;;EAEjB,wBAAwB;EACxB,+CAA+C;EAC/C,4CAA4C;EAC5C,8CAA8C;EAC9C,0CAA0C;EAC1C,8CAA8C;EAC9C,8CAA8C;EAC9C,uCAAuC;EACvC,sCAAsC;EACtC,sCAAsC;AACxC","sourcesContent":["/* Commented variables are not currently used but are supported by our current type face */\n:root {\n  /* Font weight basic variables */\n  /* --font-thin: 100; */\n  --font-light: 300;\n  --font-normal: 400;\n  --font-medium: 500;\n  --font-semibold: 600;\n  --font-bold: 700;\n  /* --font-extra-bold: 800; */\n  --font-black: 900;\n\n  /* Variables per usage */\n  --font-weight-avatar-initials: var(--font-bold);\n  --font-weight-button-icon: var(--font-black);\n  --font-weight-comment-author: var(--font-bold);\n  --font-weight-form-label: var(--font-bold);\n  --font-weight-label-bold: var(--font-semibold);\n  --font-weight-label-regular: var(--font-light);\n  --font-weight-heading: var(--font-bold);\n  --font-weight-strong: var(--font-bold);\n  --font-weight-text: var(--font-normal);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
