import { styled } from 'styled-components';

import { visuallyHidden } from '../../../../styles/common';

// Wrapper for the options
export const Ul = styled.ul`
  display: flex;
`;

// Radio button itself
export const Radio = styled.input`
  ${visuallyHidden}
`;

// Text for the label
export const LabelText = styled.div`
  background-color: var(--color-background-selected);
  border: var(--border-dark);
  color: var(--color-button-secondary);

  /** Show bottom border */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-bottom: -1px;
  padding: var(--spacing-tiny) var(--spacing-medium);
  transition: border-color var(--preference-transition-duration-short);

  /**
   * We need to ensure that the first and last items have rounded corners
   */
  ${Ul} li:first-child & {
    border-top-left-radius: var(--border-radius-small);
  }

  ${Ul} li:last-child & {
    border-top-right-radius: var(--border-radius-small);
  }

  /* Otherwise, we'd have outline cut off */
  ${Ul} li:not(:last-child) ${Radio}:not(:checked) + & {
    border-right: none;
  }

  ${Ul} li:not(:first-child) ${Radio}:not(:checked) + & {
    border-left: none;
  }

  ${Radio}:checked + & {
    background-color: var(--color-background-list);
    border-color: var(--color-outline);
    color: var(--color-link);
    font-weight: var(--font-bold);
  }

  /**
   * Only apply when the textarea is focused (it'll have a blue border itself)
   * CSS has is supported in modern browsers,
   * but we can not update caniuse-lite, as it has breaking changes
   * @todo Check after it's updated
   */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  :has(:focus-visible) ${Radio}:checked + & {
    border-bottom-color: var(--color-background-selected);
  }

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  :has(:focus-visible) ${Radio}:not(:checked) + & {
    border-bottom-color: var(--color-outline);
  }

  ${Radio}:focus-visible + & {
    background-color: var(--color-border-dark);
    border-color: var(--color-text-light);
    color: var(--color-text-light);
    font-weight: var(--font-bold);
  }

  ${Radio}:not(:checked):not(:disabled) + & {
    cursor: pointer;
  }

  ${Radio}:disabled + & {
    background-color: var(--color-button-disabled);
    color: var(--color-text-light);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }
`;
