import React, { FC } from 'react';

import { Hotel } from '../../../generated/graphql';
import useActiveSidebarTab from '../../../hooks/router/search/useActiveSidebarTab';
import useIsLeftSidebarOpen from '../../../hooks/router/search/useIsLeftSidebarOpen';

import DrawerContent from './DrawerContent';
import Rail from './Rail';
import { getIsCollapsibleItem } from './helpers';
import * as Styled from './styled';

type Props = {
  currentHotel: Hotel | null;
  hasMoreHotels: boolean;
  nrUnreadTopics: number;
};

/**
 * Main navigation drawer for desktop with collapsible content
 *
 * @param props                Props passed to component
 * @param props.currentHotel   Current hotel
 * @param props.hasMoreHotels  Whether user has more then one hotel
 * @param props.nrUnreadTopics The number of topic notifications
 * @returns                    The drawer component for desktop navigation
 */
const Drawer: FC<Props> = ({ currentHotel, hasMoreHotels, nrUnreadTopics }) => {
  const isOpen = useIsLeftSidebarOpen();
  const activeTab = useActiveSidebarTab();

  const isCollapsibleItemSelected = getIsCollapsibleItem(activeTab);

  const isExpanded = isOpen === true && isCollapsibleItemSelected === true;

  return (
    <Styled.Drawer as="aside" open={isExpanded} variant="permanent">
      <Rail
        currentHotel={currentHotel}
        hasMoreHotels={hasMoreHotels}
        nrUnreadTopics={nrUnreadTopics}
        selectedItem={activeTab}
      />
      {isCollapsibleItemSelected && (
        <DrawerContent id={activeTab} isOpen={isExpanded} />
      )}
    </Styled.Drawer>
  );
};

export default Drawer;
