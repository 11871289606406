import { styled } from 'styled-components';

import { visuallyHidden } from '../../../../../styles/common';

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled.p`
  padding: var(--spacing-small);
  text-align: left;
  width: 100%;
`;

export const Input = styled.input`
  ${visuallyHidden}

  &:focus-visible + ${LabelText} {
    background-color: var(--color-background-list);
  }

  &:checked + ${LabelText} {
    background-color: var(--color-primary-light);
    color: var(--color-text-alt);
  }

  @media (hover: hover) {
    &:not(:checked) + ${LabelText}:hover {
      background-color: var(--color-hover-light);
    }
  }
`;
