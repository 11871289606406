import React from 'react';

import ErrorWrapper from '../../../components/Errors/ErrorWrapper';
import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import useLogout from '../../../hooks/useLogout';
import translate from '../../../i18n/translate';

/**
 * Page that shows error when there is a AUTH_USER_NOT_FOUND error
 *
 * @returns The component itself
 */
const AuthUserNotFoundPage = () => {
  const logout = useLogout();
  return (
    <ErrorWrapper
      actionButton={{
        onClick: logout,
        text: translate('GENERAL__BACK_TO_LOGIN'),
      }}
      description={translate('ERROR__AUTH_USER_NOT_FOUND__DESCRIPTION')}
      illustrationName={ILLUSTRATION__SEARCH}
      title={translate('GENERAL__OOPS')}
    />
  );
};

export default AuthUserNotFoundPage;
