/**
 * @file Helper functions for ElasticSearch
 */

/**
 * Extract error, if there is one
 *
 * @param isValidQuery     Whether the query itself is valid
 * @param isLoading        Whether the data is being loaded
 * @param messagesError    Whether we received an error from the backend when fetching messages
 * @param searchQueryError Whether we received an error from the backend when fetching search results
 * @returns                The error to show
 */
export const extractError = <T>(
  isValidQuery: boolean,
  isLoading: boolean,
  messagesError: T,
  searchQueryError?: T,
): T | string | null => {
  if (isLoading) {
    return null;
  }

  if (isValidQuery === false) {
    return 'Invalid search query';
  }

  if (messagesError) {
    return messagesError;
  }

  return searchQueryError ?? null;
};
