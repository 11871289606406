import React, { FC } from 'react';
import { connect } from 'react-redux';

import SkeletonFeedWrapper from '../../components/Loading/FeedWrapper';
import Messages from '../../components/Messages';
import MessagesEmpty from '../../components/MessagesEmpty';
import translate from '../../i18n/translate';

import { getShouldShowSkeletons } from './helpers';
import useMessageInfoSubscription from './hooks/useMessageInfoSubscription';
import useMessagesPagination from './hooks/useMessagesPagination';
import useTopicTitle from './hooks/useTopicTitle';
import { Props } from './props';
import { mapStateToProps } from './redux';

/**
 * The container for the message list
 *
 * @param props                 Props passed to the component
 * @param props.networkMessages List of mocked messages
 * @returns                     The component itself
 */
const MessagesContainer: FC<Props> = ({ networkMessages }) => {
  useMessageInfoSubscription();
  const topicTitle = useTopicTitle();

  const {
    isInitialLoad,
    paginationState,
    requestLoadMoreAfter,
    requestLoadMoreBefore,
    response: { hasError, isFetching, nodes },
  } = useMessagesPagination();

  const [shouldShowAbove, shouldShowBelow] = getShouldShowSkeletons(
    isFetching,
    networkMessages,
    paginationState,
  );

  if (hasError === true) {
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  if (nodes.length === 0 && isInitialLoad === false) {
    return <MessagesEmpty title={topicTitle} />;
  }

  return (
    <SkeletonFeedWrapper
      isInitialLoad={isInitialLoad}
      shouldShowAbove={shouldShowAbove}
      shouldShowBelow={shouldShowBelow}
    >
      <Messages
        messages={nodes}
        requestLoadMoreAfter={requestLoadMoreAfter}
        requestLoadMoreBefore={requestLoadMoreBefore}
      />
    </SkeletonFeedWrapper>
  );
};

export default connect(mapStateToProps)(MessagesContainer);
