import React, { FC } from 'react';

import { MessageCommon } from '../../../models/message';

import MessageInfoAssignees from './MessageInfoAssignees';
import MessageInfoChecklist from './MessageInfoChecklist';
import MessageInfoDueDate from './MessageInfoDueDate';
import MessageInfoGroups from './MessageInfoGroups';
import MessageInfoStatus from './MessageInfoStatus';

export type Props = {
  assignedGroups: MessageCommon['assignedGroups'];
  assignedMembers: MessageCommon['assignedMembers'];
  checklist: MessageCommon['checklist'];
  className?: string;
  dueDate: MessageCommon['dueDate'];
  id: MessageCommon['id'];
  shouldRenderLinks: boolean;
  status: MessageCommon['status'];
};

/**
 * Show a single message in the feed
 *
 * @param props                   Props passed to the component
 * @param props.assignedGroups    Groups assigned to the message
 * @param props.assignedMembers   Members assigned to the message
 * @param props.checklist         Message checklist items
 * @param props.className         styled-components generated class name, needed for styling
 * @param props.dueDate           Message due date
 * @param props.id                Message id
 * @param props.shouldRenderLinks Whether to render links as <a />
 * @param props.status            Message status
 * @returns                       The component itself
 */
const MessageInfoWrapper: FC<Props> = ({
  assignedGroups,
  assignedMembers,
  className,
  checklist,
  dueDate,
  id,
  shouldRenderLinks,
  status,
}) => {
  return (
    <nav className={className}>
      {dueDate !== null && (
        <>
          <MessageInfoDueDate
            dueDate={dueDate}
            messageId={id}
            shouldBeLink={shouldRenderLinks}
            status={status}
          />
          <MessageInfoStatus
            messageId={id}
            messageStatus={status}
            shouldBeLink={shouldRenderLinks}
          />
        </>
      )}
      {checklist.length > 0 && (
        <MessageInfoChecklist
          checklist={checklist}
          messageId={id}
          shouldBeLink={shouldRenderLinks}
        />
      )}
      {assignedMembers.length > 0 && (
        <MessageInfoAssignees
          assignedMembers={assignedMembers}
          messageId={id}
          shouldBeLink={shouldRenderLinks}
        />
      )}
      {assignedGroups.length > 0 && (
        <MessageInfoGroups
          assignedGroups={assignedGroups}
          messageId={id}
          shouldBeLink={shouldRenderLinks}
        />
      )}
    </nav>
  );
};

export default MessageInfoWrapper;
