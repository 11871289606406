/**
 * @file Helpers for the Proxy page
 */

import { reportError } from '../../../services/reporting';

import { Collection, ParamsAll, ParamSingle } from './types';

/**
 * Pattern by which we extract
 */
const collectionPattern = new RegExp(/(.+?)__/);

/**
 * Params present in all proxy links
 */
const persistent: ParamSingle[] = ['hotelId'];

/**
 * Mapping, which collection type has which fields sent via proxy links
 */
const validations: Record<Collection, ParamSingle[]> = {
  CHAT: [...persistent, 'conversationId'],
  CHECKLIST_ITEM: [...persistent, 'messageId', 'topicId'],
  COMMENT: [...persistent, 'commentId', 'messageId', 'topicId'],
  LABEL: [...persistent, 'labelId'],
  MESSAGE: [...persistent, 'messageId', 'topicId'],
  TOPIC: [...persistent, 'topicId'],
  USER: [...persistent, 'userId'],
  USER_GROUP: [...persistent, 'userGroupId'],
};

/**
 * Based on the event name, extract collection type for it
 * That way, we know which fields ought to be present
 *
 * @param event The event name
 * @returns     The collection name
 */
const extractCollection = (event: string): string | null => {
  const matches = event.match(collectionPattern);

  if (matches === null || matches.length < 1) {
    return null;
  }

  return matches[1];
};

/**
 * Extract full proxy data from the URL
 *
 * @param params Url params for the proxy link
 * @returns      All params possibly contained within the proxy link
 */
export const getProxyData = (params: URLSearchParams): ParamsAll => ({
  commentId: params.get('commentId'),
  conversationId: params.get('conversationId'),
  event: params.get('event'),
  hotelId: params.get('hotelId'),
  labelId: params.get('labelId'),
  messageId: params.get('messageId'),
  topicId: params.get('topicId'),
  userGroupId: params.get('userGroupId'),
  userId: params.get('userId'),
});

/**
 * Validate whether proxy data includes everything needed
 *
 * @param params The search params
 * @returns      Whether all the data is present
 */
export const validateProxyData = (params: URLSearchParams): boolean => {
  const event = params.get('event');

  if (event === null) {
    reportError('Proxy data: event is missing');
    return false;
  }

  const collection = extractCollection(event);

  if (collection === null) {
    reportError('Proxy data: Unrecognized collection for event: ' + event);
    return false;
  }

  if (collection in validations === false) {
    reportError('Proxy data: No validators for collection: ' + collection);
    return false;
  }

  const data = validations[collection as Collection];
  const isValid = data.every(key => params.get(key) !== null);

  if (isValid === false) {
    reportError(
      `Proxy data: Invalid params for ${event}: ${params.toString()}`,
    );
  }

  return isValid;
};
