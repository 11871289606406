import React, { FC } from 'react';
import { useQuery } from 'urql';

import ContentDesktop from '../../components/Dashboard/ContentDesktop';
import ContentMobile from '../../components/Dashboard/ContentMobile';
import DashboardError from '../../components/Errors/DashboardError';
import DashboardSkeleton from '../../components/Loading/DashboardSkeleton';
import { DashboardKpiDocument } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../hooks/useIsMobile';
import useIsTablet from '../../hooks/useIsTablet';
import translate from '../../i18n/translate';

import { getFormattedData } from './helpers';

import * as Styled from './styled';

/**
 * Dashboard component with KPIs
 *
 * @returns The component itself
 */
const DashboardContent: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: DashboardKpiDocument,
    requestPolicy: 'cache-and-network',
    variables: {},
  });

  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const dataMissingText = translate(
    'PLACEHOLDER__DATA_UNAVAILABLE',
    translate('DASHBOARD__TITLE'),
  );

  if (fetching) {
    return (
      <Styled.ContentWrapper>
        <DashboardSkeleton isMobile={isMobile} />
      </Styled.ContentWrapper>
    );
  }

  if (error) {
    return (
      <Styled.ContentWrapper>
        <DashboardError />
      </Styled.ContentWrapper>
    );
  }

  if (!data) {
    return <div>{dataMissingText}</div>;
  }
  const { dashboardKpi } = data;

  const formattedData = getFormattedData({
    dashboardKpi,
    hotelId,
    isTablet,
  });

  const content = isMobile ? (
    <ContentMobile data={formattedData} />
  ) : (
    <ContentDesktop data={formattedData} />
  );

  return <Styled.ContentWrapper>{content}</Styled.ContentWrapper>;
};

export default DashboardContent;
