import React, { FC } from 'react';

import { MessageStatus as MessageStatusEnum } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import DateDue from '../DateDue';
import MessageNoResult from '../MessageNoResult';

export type Props = {
  date?: string | null;
  status?: MessageStatusEnum | null;
};

/**
 * Date and time when the message is due
 *
 * @param props        Props passed to the component
 * @param props.date   The due date to show
 * @param props.status Current status value
 * @returns            The component itself
 */
const MessageDateDue: FC<Props> = ({ date = null, status }) => {
  if (date === null || date === '') {
    return <MessageNoResult message={translate('DUE_DATE__NONE')} />;
  }

  return <DateDue date={date} status={status} />;
};

export default MessageDateDue;
