/**
 * @file Contains id's of modals, which are used as values for modalId search param.
 */

export const MODAL__GROUP_CREATE = 'group-create';
export const MODAL__GROUP_DELETE = 'group-delete';
export const MODAL__GROUP_EDIT = 'group-edit';
export const MODAL__GROUP_VIEW = 'group-view';
export const MODAL__LABEL_CREATE = 'label-create';
export const MODAL__LABEL_DELETE = 'label-delete';
export const MODAL__LABEL_EDIT = 'label-edit';
export const MODAL__POST_ARCHIVE = 'post-archive';
export const MODAL__POST_ATTACHMENT_DELETE = 'post-attachment-delete';
export const MODAL__POST_DELETE = 'post-delete';
export const MODAL__POST_UNARCHIVE = 'post-unarchive';
export const MODAL__TOPIC_CREATE = 'topic-create';
export const MODAL__TOPIC_DELETE = 'topic-delete';
export const MODAL__TOPIC_EDIT = 'topic-edit';
export const MODAL__TEMPLATE_DELETE = 'template-delete';
export const MODAL__TEMPLATE_RECURRING_RULE_DELETE =
  'template-recurring-rule-delete';
export const MODAL__USER_EDIT = 'user-edit';
