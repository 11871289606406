import React, {
  FC,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  MESSAGE__TEXT_REQUIRED,
  MESSAGE__TITLE_REQUIRED,
} from '../../../constants/forms';

import translate from '../../../i18n/translate';
import { reportWarning } from '../../../services/reporting';
import ButtonCancel from '../../Common/ButtonCancel';
import ButtonSave from '../../Common/ButtonSave';
import { EditorUsage } from '../../Common/Editor/types';

import * as Styled from './styled';

export type Props = {
  apiError?: string | null;
  descriptionUsage: EditorUsage;
  formId?: string;
  isDisabled: boolean;
  maxLength?: HTMLInputElement['maxLength'];
  mode: 'description' | 'title';
  requestCancel?: () => void;
  requestSave: (text: string) => void;
  onSaveDisabledChange?: (isDisabled: HTMLButtonElement['disabled']) => void;
  shouldFocus?: boolean;
  shouldShowActionButtons: boolean;
  text: string;
};
const inputValue = 'inputElement';

/**
 * Update Message title or description form component
 *
 * @param props                         Props passed to the component
 * @param props.apiError                ApiError if request fails (eg. duplicate topic title)
 * @param props.isDisabled              Whether that user has the permission to edit the message
 * @param props.descriptionUsage        What the textarea is used for
 * @param props.formId                  The formId, optional argument
 * @param props.maxLength               Maximum number of characters for the form field
 * @param props.mode                    The mode in which the component is used (description | title)
 * @param props.onSaveDisabledChange    Callback that informs whether the save button is disabled or not
 * @param props.requestCancel           Callback that cancels the form (hides action buttons)
 * @param props.requestSave             Callback that updates the message's title
 * @param props.shouldFocus             Should the input be auto focused
 * @param props.shouldShowActionButtons Whether we should show the action buttons (Cancel & Save)
 * @param props.text                    Message current text value
 * @returns                             The component itself
 */
const CommonTextForm: FC<Props> = ({
  apiError = null,
  descriptionUsage,
  formId,
  isDisabled,
  maxLength,
  mode,
  requestCancel,
  requestSave,
  onSaveDisabledChange,
  shouldFocus,
  shouldShowActionButtons = false,
  text,
}) => {
  const [inputText, setInputText] = useState(text);
  const formRef = useRef<HTMLFormElement>(null);
  const inputField = formRef.current?.elements.namedItem(
    inputValue,
  ) as HTMLInputElement;

  const shouldDisableSave = inputText === text || inputText.trim().length === 0;

  useEffect(() => {
    onSaveDisabledChange?.(shouldDisableSave);
  }, [onSaveDisabledChange, shouldDisableSave]);

  /**
   * If an apiError occurs show the user the errorMessage
   */
  useEffect(() => {
    if (apiError !== null && inputField !== undefined) {
      inputField.setCustomValidity(apiError);
      inputField.reportValidity();
    }
  }, [apiError, inputField]);

  useEffect(() => {
    if (shouldShowActionButtons === false && requestCancel !== undefined) {
      reportWarning(
        'CommonTextForm: invalid prop requestCancel/shouldShowActionButtons',
      );
    }
  }, [shouldShowActionButtons, requestCancel]);

  /**
   * Set text value and check if save button should be disabled
   * Also reset customValidity (red border input field) when an
   * apiError was triggered previously
   *
   * @param value New value of text input
   */
  const handleTextChange = (value: string): void => {
    if (apiError && inputField !== undefined) {
      inputField.setCustomValidity('');
    }
    setInputText(value);
  };

  /**
   * The form was submitted (perhaps by pressing enter within an input)
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (inputText !== text) {
      requestSave(inputText);
    }
  };

  const propsCommon = {
    isDisabled,
    isLabelVisible: false,
    onChange: handleTextChange,
    value: inputText,
  };

  /**
   * Renders content conditionally depending on mode
   *
   * @returns Description or Title input
   */
  const renderContent = () => {
    if (mode === 'description') {
      return (
        <Styled.Description
          {...propsCommon}
          isRequired={MESSAGE__TEXT_REQUIRED}
          label={translate('GENERAL__DESCRIPTION')}
          maxLength={maxLength}
          shouldFocus={shouldFocus}
          usage={descriptionUsage}
        />
      );
    }

    return (
      <Styled.Field
        {...propsCommon}
        isRequired={MESSAGE__TITLE_REQUIRED}
        label={translate('GENERAL__TITLE')}
        maxLength={maxLength}
        name={inputValue}
        shouldFocus={shouldFocus}
      />
    );
  };

  return (
    <form
      aria-label={translate('ARIA__FORM__MESSAGE_UPDATE')}
      id={formId}
      onSubmit={onSubmit}
      ref={formRef}
    >
      {renderContent()}
      {shouldShowActionButtons === true && (
        <Styled.ButtonWrapper>
          {requestCancel !== undefined && (
            <ButtonCancel onClick={requestCancel} type="button" />
          )}
          <ButtonSave isDisabled={shouldDisableSave} type="submit" />
        </Styled.ButtonWrapper>
      )}
    </form>
  );
};

export default CommonTextForm;
