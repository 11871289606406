/**
 * @file Helpers for the compose header component
 */

import { Location } from 'react-router-dom';

import { ModeLabels } from '../../constants/metaModesLabels';
import translate from '../../i18n/translate';
import { LabelUsage } from '../../models/message';
import { getSearchParamLabelMode } from '../../routes/helpers/searchParams';

const titleMapping: Record<ModeLabels, string> = {
  MODE_CREATE: 'MODAL__LABEL_CREATE_HEADING',
  MODE_EDIT: 'LABELS__EDIT__HEADING',
  MODE_VIEW: 'COMPOSE__PLACEHOLDER__LABEL',
};

/**
 * Get the heading for the labels popup
 *
 * @param location History location object
 * @param where    Where the label mode is used
 * @returns        The heading by current label mode
 */
export const getLabelsHeading = (location: Location, where: LabelUsage) => {
  const labelMode = getSearchParamLabelMode(location, where) ?? 'MODE_VIEW';

  return translate(titleMapping[labelMode]);
};
