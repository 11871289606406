/**
 * @file Hook that gets currently active hotel id from url
 */

import { useParams } from 'react-router-dom';

import { Hotel } from '../../../generated/graphql';
import { RootParams } from '../../../models/pageParams';
import validateMongoId from '../../../utils/string/validateMongoId';

/**
 * Hook that gets currently active hotel id from url
 *
 * @returns Current active hotelId
 */
const useParamHotelId = (): Hotel['id'] | null => {
  const { hotelId } = useParams<RootParams>();

  if (hotelId === undefined) {
    return null;
  }

  const isValid = validateMongoId(hotelId);
  if (isValid === false) {
    return null;
  }

  return hotelId;
};

export default useParamHotelId;
