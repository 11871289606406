import { SvgIcon } from '@mui/material';
import React, { FC } from 'react';

import { IconPropsExtended, MuiSymbolIconName } from '../../types';

import { muiIconNameMapping } from './mapping';

type Props = IconPropsExtended & { iconName: MuiSymbolIconName };

/**
 * This component allows us to use custom icons, but wrapped with SvgIcon it
 * will behave the same way the @mui/icons-material icons do. Some icons are not
 * looking good using the library, and in this case we can use custom icons (preferably mui-symbols).
 *
 * @see https://fonts.google.com/icons?icon.set=Material+Symbols&icon.style=Rounded
 *
 * @param props Props passed to the component
 * @returns     The component itself
 */
const MaterialSymbolIconBase: FC<Props> = props => {
  const { iconName, ...otherProps } = props;

  return (
    <SvgIcon {...otherProps} inheritViewBox={true}>
      <use xlinkHref={`#${muiIconNameMapping[iconName]}`} />
    </SvgIcon>
  );
};

export default MaterialSymbolIconBase;
