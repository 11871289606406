/**
 * @file Get whether to mark the current message as read
 */

import { MessageFullQuery } from '../../../generated/graphql';

/**
 * Get whether to mark the current message as read
 * We do so when it's unread and has no comments
 * (in case where there are comments, we mark as read on them becoming visible)
 *
 * @param data The fetched data
 * @returns    Whether to mark the message as read
 */
const getShouldMarkAsRead = (data: MessageFullQuery | undefined) => {
  const message = data?.message ?? null;

  // Data not yet ready
  if (message === null) {
    return false;
  }

  // Message already read, no need to mark it again
  if (message.isSeen === true) {
    return false;
  }

  // In the feed, we only mark messages as read when they have no comments
  // For those that do, we mark them as read when the comments are visible
  return message.numberOfComments === 0;
};

export default getShouldMarkAsRead;
