import { styled } from 'styled-components';

import devices from '../../../../styles/devices';

export const Ul = styled.ul`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: row;

  &:empty {
    display: none;
  }

  @media ${devices.tabletS} {
    gap: var(--spacing-small);
    grid-auto-flow: column;
    margin-bottom: var(--spacing-small);
  }
`;
