import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import { AttachmentAction, AttachmentMode } from '../../../models/attachment';

import AttachmentRibbon from './AttachmentRibbon';

export type Props =
  | {
      actionDownload: AttachmentAction;
      actionPreview: AttachmentAction;
      canDownloadFile: boolean;
      mode: 'feed';
    }
  | {
      actionDownload?: AttachmentAction;
      actionPreview?: AttachmentAction;
      canDownloadFile: boolean;
      mode: Exclude<AttachmentMode, 'feed'>;
    };

/**
 * Fragment of AttachmentSingle rendered in details mode
 *
 * @param props                 Props passed to the component
 * @param props.actionDownload  Attachment action download props
 * @param props.actionPreview   Attachment action preview props
 * @param props.canDownloadFile Whether user has permission to download attachment
 * @param props.mode            In which mode to render the component
 * @returns                     The component itself
 */
const FeedFragment: FC<Props> = ({
  actionDownload,
  actionPreview,
  canDownloadFile,
  mode,
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  if (mode === 'feed') {
    return (
      <AttachmentRibbon
        actionDownload={actionDownload}
        actionPreview={actionPreview}
        canDownloadFile={canDownloadFile}
      />
    );
  }

  return null;
};

export default FeedFragment;
