import React, { FC } from 'react';

import translate from '../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  isArchived: boolean;
};

/**
 * Shows archived info if a message is archived
 *
 * @param props            Props passed to the component
 * @param props.isArchived Flag that indicates whether the message is archived
 * @returns                The component itself
 */
const Archived: FC<Props> = ({ isArchived }) => {
  return (
    <>
      {isArchived && (
        <Styled.Archived>
          {`(${translate('MESSAGE__ARCHIVED__SUFFIX')})`}
        </Styled.Archived>
      )}
    </>
  );
};

export default Archived;
