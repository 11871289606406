/**
 * @file contains redux actions related to draftsText state
 */

import { DRAFTS__TEXT__SET } from '../../constants/actionTypes';

import { DraftActionTextSet } from '../../models/draftText';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to update the draft text itself
 *
 * @param parentId The ID of the message this is a reply to
 * @param text     The new text
 * @returns        The action itself
 */
export const updateDraftTextRequest = (
  parentId: string,
  text: string,
): DraftActionTextSet => ({
  parentId,
  text,
  type: DRAFTS__TEXT__SET,
});
