/**
 * @file contains the draftChecklists reducer function
 */

import {
  DRAFTS__CHECKLIST__ADD,
  DRAFTS__CHECKLIST__REMOVE,
  DRAFTS__CHECKLIST__REORDER,
  DRAFTS__CHECKLIST__SAVE,
  DRAFTS__CHECKLIST__SET_STATE,
  DRAFTS__CHECKLIST__SET_TEXT,
} from '../../constants/actionTypes';

import { ChecklistDataDraft } from '../../models/checklist';
import { DraftsState } from '../../models/draft';

import {
  DraftActionChecklist,
  DraftActionChecklistCreate,
  DraftActionChecklistDelete,
  DraftActionChecklistReorder,
  DraftActionChecklistSave,
  DraftActionChecklistSetState,
  DraftActionChecklistSetText,
} from '../../models/draftChecklist';

import cloneDraftsState from '../../utils/stateDraftsClone';
import initialState from '../initialState';

type Reducer = (
  state: DraftsState,
  action: DraftActionChecklist,
) => DraftsState;

/**
 *
 * CREATE callbacks
 *
 */

/**
 * A request was made to add a checklist item
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistCreateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistCreate,
): DraftsState => {
  const { parentId, text } = action;
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    const position =
      draftNext.checklistCurrent.length === 0
        ? 1
        : Math.max(...draftNext.checklistCurrent.map(item => item.position)) +
          1;

    draftNext.checklistCurrent.push({
      checked: false,
      position,
      text,
    });
  }

  return stateNext;
};

/**
 *
 * UPDATE request
 *
 */

/**
 * A request was made to reorder checklist items
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistReorderRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistReorder,
): DraftsState => {
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.checklistCurrent = action.items.map(item => ({
      ...item,
    }));
  }

  return stateNext;
};

/**
 * A request was made to transfer checklistCurrent to checklistSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistSaveRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistSave,
): DraftsState => {
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.checklistSaved = draftNext.checklistCurrent.map(item => ({
      ...item,
    }));
  }

  return stateNext;
};

/**
 * A request was made to (un)check a specific checklist item
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistSetStateRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistSetState,
): DraftsState => {
  const { parentId, position, state } = action;
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.checklistCurrent = draftNext.checklistCurrent.map(item => ({
      ...item,
      checked: item.position === position ? state : item.checked,
    }));
  }

  return stateNext;
};

/**
 * A request was made to change a checklist item's text
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistSetTextRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistSetText,
): DraftsState => {
  const { parentId, position, text } = action;
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.checklistCurrent = draftNext.checklistCurrent.map(item => ({
      ...item,
      text: item.position === position ? text : item.text,
    }));
  }

  return stateNext;
};

/**
 *
 * DELETE callbacks
 *
 */

/**
 * A request was made to remove a checklist item
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onChecklistDeleteRequest = (
  stateCurrent: DraftsState,
  action: DraftActionChecklistDelete,
): DraftsState => {
  const { parentId, position } = action;
  const stateNext = cloneDraftsState(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.checklistCurrent = draftNext.checklistCurrent
      .filter(item => item.position !== position)
      .sort((a, b) => a.position - b.position)
      .map(
        (item, index): ChecklistDataDraft => ({
          ...item,
          position: index + 1,
        }),
      );
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft checklists
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__CHECKLIST__ADD, onChecklistCreateRequest],
  [DRAFTS__CHECKLIST__REMOVE, onChecklistDeleteRequest],
  [DRAFTS__CHECKLIST__REORDER, onChecklistReorderRequest],
  [DRAFTS__CHECKLIST__SAVE, onChecklistSaveRequest],
  [DRAFTS__CHECKLIST__SET_STATE, onChecklistSetStateRequest],
  [DRAFTS__CHECKLIST__SET_TEXT, onChecklistSetTextRequest],
]);

/**
 * Drafts checklists reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftChecklists = (
  state = initialState.drafts,
  action: DraftActionChecklist,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftChecklists;
