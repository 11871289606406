/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Frequency } from 'rrule';

import {
  ROUTE__SETTINGS,
  ROUTE__SETTINGS__NOTIFICATIONS,
} from '../../constants/routing/routes';
import { SEARCH_PARAM__SIDEBAR } from '../../constants/routing/searchParams';
import { SIDEBAR__PARAM_SETTINGS } from '../../constants/sidebar';
import { Hotel, Template } from '../../generated/graphql';
import { SettingNotificationsParams } from '../../models/notifications';
import { reportError } from '../../services/reporting';

import { getNoMatchUrl } from './404';

const sidebarParam = `?${SEARCH_PARAM__SIDEBAR}=${SIDEBAR__PARAM_SETTINGS}`;

/**
 * Get the url for the /settings route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getSettingsUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsUrl is null');
    return getNoMatchUrl();
  }

  return `/${hotelId}/${ROUTE__SETTINGS}`;
};

/**
 * Generate a url for the settings groups page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsGroupsUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsGroupsUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS}/groups${params}`;
};

/**
 * Generate a url for the settings labels page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsLabelsUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsLabelsUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS}/labels${params}`;
};

/**
 * Generate a url for the settings notifications page
 *
 * @param hotelId ID of the hotel
 * @returns       The generated url
 */
export const getSettingsNotificationsUrl = (
  hotelId: Hotel['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsNotificationsUrl is null');
    return getNoMatchUrl();
  }

  return `/${hotelId}/${ROUTE__SETTINGS__NOTIFICATIONS}`;
};

/**
 * Generate a url for the settings email notifications page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsNotificationsEmailUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsNotificationsEmailUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS__NOTIFICATIONS}/email${params}`;
};

/**
 * Generate a url for the settings email notifications page with url param
 *
 * @param hotelId           The ID of the current hotel
 * @param notificationParam Url parameter
 * @returns                 The generated url
 */
export const getSettingsNotificationsEmailSingleUrl = (
  hotelId: Hotel['id'] | null,
  notificationParam: SettingNotificationsParams['notificationParam'],
): string => {
  if (hotelId === null) {
    reportError(
      'HotelId passed to getSettingsNotificationsEmailSingleUrl is null',
    );
    return getNoMatchUrl();
  }

  return `/${hotelId}/${ROUTE__SETTINGS__NOTIFICATIONS}/email/${notificationParam}`;
};

/**
 * Generate a url for the settings push notifications page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsNotificationsPushUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsNotificationsPushUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS__NOTIFICATIONS}/push${params}`;
};

/**
 * Generate a url for the settings push notifications page with url param
 *
 * @param hotelId           ID of the hotel
 * @param notificationParam Url parameter
 * @returns                 The generated url
 */
export const getSettingsNotificationsPushSingleUrl = (
  hotelId: Hotel['id'] | null,
  notificationParam: SettingNotificationsParams['notificationParam'],
): string => {
  if (hotelId === null) {
    reportError(
      'HotelId passed to getSettingsNotificationsPushSingleUrl is null',
    );
    return getNoMatchUrl();
  }

  return `/${hotelId}/${ROUTE__SETTINGS__NOTIFICATIONS}/push/${notificationParam}`;
};

/**
 * Generate a url for the settings people page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsPeopleUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsPeopleUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS}/people${params}`;
};

/**
 * Generate a url for the settings topics page
 *
 * @param hotelId    ID of the hotel
 * @param isTablet   Whether the device is a tablet
 * @param templateId The ID of the template that's being edited
 * @returns          The generated url
 */
export const getSettingsTemplatesUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
  templateId: Template['id'] | null = null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsTemplatesUrl is null');
    return getNoMatchUrl();
  }

  const base = `/${hotelId}/${ROUTE__SETTINGS}/templates`;
  const subRoute = templateId === null ? '' : `/${templateId}`;
  const params = isTablet ? '' : sidebarParam;

  return `${base}${subRoute}${params}`;
};

/**
 * Generate a url for the settings template recurring page
 *
 * @param frequency  Rrule frequency
 * @param hotelId    ID of the hotel
 * @param isTablet   Whether the device is a tablet
 * @param templateId The ID of the template that's being edited
 * @returns          The generated url
 */
export const getSettingsTemplatesRecurringUrl = (
  frequency: Frequency,
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
  templateId: Template['id'] | null = null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsTemplatesRecurringUrl is null');
    return getNoMatchUrl();
  }

  const base = `/${hotelId}/${ROUTE__SETTINGS}/templates`;
  const params = isTablet ? '' : sidebarParam;

  return templateId === null
    ? base
    : `${base}/${templateId}/recurring/${Frequency[frequency].toLowerCase()}${params}`;
};

/**
 * Generate a url for the settings topics page
 *
 * @param hotelId  ID of the hotel
 * @param isTablet Whether the device is a tablet
 * @returns        The generated url
 */
export const getSettingsTopicsUrl = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getSettingsTopicsUrl is null');
    return getNoMatchUrl();
  }

  const params = isTablet ? '' : sidebarParam;

  return `/${hotelId}/${ROUTE__SETTINGS}/topics${params}`;
};
