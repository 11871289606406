/**
 * @file contains functions used to create paths for use in Route related to the home page
 */
import {
  ROUTE__DYNAMIC_PREFIX,
  ROUTE__HOME,
} from '../../constants/routing/routes';

/**
 * Generate a path for the home page
 *
 * @returns The generated path
 */
export const createHomePath = (): string => {
  return `${ROUTE__DYNAMIC_PREFIX}/${ROUTE__HOME}`;
};
