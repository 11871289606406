/**
 * @file Updates a paginated query in cache by adding a node to one
 */

import { Cache, FieldArgs, FieldInfo } from '@urql/exchange-graphcache';
import { TypedDocumentNode } from 'urql';

import {
  Node,
  PaginationResult,
  PaginationResultExtended,
} from '../../../../../../generated/graphql';
import { QueryName } from '../../../types';

type ParentIdName = 'messageId' | 'topicId';

type AddNodeToQueryParams = {
  cache: Cache;
  variables: FieldArgs;
  queryName: QueryName;
  query: TypedDocumentNode;
  node: Node;
};

type FilterQueriesParams<IdType> = {
  filterById: IdType;
  queryName: QueryName;
  parentIdName: ParentIdName;
};

type RemoveNodeFromQueryParams = {
  cache: Cache;
  nodeId: string;
  query: TypedDocumentNode;
  queryName: QueryName;
  variables: FieldArgs;
};

/**
 * Updates a paginated query in cache by adding a node to one
 *
 * @param args           Args passed to the updater
 * @param args.cache     urql cache object
 * @param args.variables Variables used in the query
 * @param args.queryName Name of the query in cache
 * @param args.query     Document node of the query to update
 * @param args.node      Node that is being added to query
 */
export const addNodeToPaginatedQuery = ({
  cache,
  variables,
  queryName,
  query,
  node,
}: AddNodeToQueryParams): void => {
  cache.updateQuery({ query, variables }, data => {
    if (
      /** Optional chaining doesn't work here, we need to handle both data and data.labels === null */
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      data === null ||
      data[queryName] === null ||
      data[queryName] === undefined
    ) {
      return null;
    }

    /**
     * Updates the data in the cache
     */
    (data[queryName] as PaginationResult).nodes.unshift(node);

    return data;
  });
};

/**
 * Filters message queries by parent ID
 *
 * @param args              Args passed to the updater
 * @param args.filterById   ID to filter queries by
 * @param args.queryName    Name of the query in the cache
 * @param args.parentIdName ID of the parent that is used to find and filter queries
 * @returns                 A function that filters queries given the correct parameters
 */
export const filterQueries = <IdType>({
  filterById,
  queryName,
  parentIdName,
}: FilterQueriesParams<IdType>) => {
  return (field: FieldInfo): boolean => {
    if (field.arguments === null) {
      return false;
    }

    return (
      field.arguments[parentIdName] === filterById &&
      field.fieldName === queryName
    );
  };
};

/**
 * Updates a paginated query in cache by adding a node to one
 *
 * @param args           Args passed to the updater
 * @param args.cache     urql cache object
 * @param args.variables Variables used in the query
 * @param args.queryName Name of the query in cache
 * @param args.query     Document node of the query to update
 * @param args.nodeId    Node that is being added to query
 */
export const removeNodeFromPaginatedQuery = ({
  cache,
  nodeId,
  query,
  queryName,
  variables,
}: RemoveNodeFromQueryParams): void => {
  cache.updateQuery(
    {
      query,
      variables,
    },
    data => {
      if (
        /** Optional chaining doesn't work here, we need to handle both data and data.labels === null */
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        data === null ||
        data[queryName] === null ||
        data[queryName] === undefined
      ) {
        return null;
      }

      /**
       * Updates the data in the cache
       * Data in urql cache is being via mutating it and that is ok, since we are
       * working with the copy of the cache, and not the real cache
       * More info: https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#updating-lists-or-links
       *
       * Note on typecasting: It is required at this moment because types are dynamic,
       * if we ever find a way to avoid this being typecast, we should implement it
       */
      const result = data[queryName] as
        | PaginationResult
        | PaginationResultExtended;

      const nodesOriginal = result.nodes;
      const nodesUpdated = nodesOriginal.filter(node => node.id !== nodeId);
      const isNodeRemoved = nodesOriginal.length !== nodesUpdated.length;

      if (isNodeRemoved === false) {
        return data;
      }

      result.nodes = nodesUpdated;

      if ('nodesInfo' in result) {
        /**
         * In queries with nodesInfo, we need to decrement the counts for total nodes and
         * returned nodes
         */
        result.nodesInfo.returned--;
        result.nodesInfo.total--;
      }

      return data;
    },
  );
};
