/**
 * @file Optimistically read a message
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MessagesReadMutation,
  MutationMessagesReadArgs,
} from '../../../../../generated/graphql';
import { messagesSetSeen } from '../../updates/utils/updaters/messages/messagesSetSeen';

/**
 * Optimistically read a message
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     The updated cache entry
 */
const messagesRead: OptimisticMutationResolver<
  MutationMessagesReadArgs,
  MessagesReadMutation['messagesRead']
> = (args, cache) => {
  messagesSetSeen(args, cache);

  return {
    __typename: 'ActionResult',
    status: true,
  };
};

export default messagesRead;
