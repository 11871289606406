/**
 * @file Generate UUIDv4 string
 */

import { KEY__DEVICE_ID } from '../../constants/localStorage';
import generateUuid from '../user/uuidGenerator';

/**
 * Generate UUIDv4 string,
 * using crypto where supported and common APIs where not (Safari)
 *
 * @returns Valid UUIDv4 string
 */
const getDeviceId = (): string => {
  const deviceIdStored = localStorage.getItem(KEY__DEVICE_ID);
  if (deviceIdStored === null) {
    const uuid = generateUuid();
    localStorage.setItem(KEY__DEVICE_ID, uuid);

    return uuid;
  }

  return deviceIdStored;
};

export default getDeviceId;
