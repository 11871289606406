/**
 * @file The mark as read reducer function
 */

import {
  MARK_AS_READ__DEBOUNCE,
  MARK_AS_READ__DELAY,
} from '../../constants/actionTypes';
import {
  MarkAsReadAction,
  MarkAsReadDebounceSetAction,
  MarkAsReadDelaySetAction,
  MarkAsReadState,
} from '../../models/markAsRead';
import initialState from '../initialState';

type Reducer<State, Action> = (state: State, action: Action) => State;

/**
 * The user has requested that the markAsReadDebounce key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onMarkAsReadDebounceSet = (
  state: MarkAsReadState,
  action: MarkAsReadDebounceSetAction,
): MarkAsReadState => ({
  ...state,
  debounce: action.payload,
});

/**
 * The user has requested that the markAsReadDelay key is set,
 * so we reflect that
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onMarkAsReadDelaySet = (
  state: MarkAsReadState,
  action: MarkAsReadDelaySetAction,
): MarkAsReadState => ({
  ...state,
  delay: action.payload,
});

/**
 * action:reducer mapping for mark as read
 */
const mapping = new Map<string, Reducer<MarkAsReadState, MarkAsReadAction>>([
  [MARK_AS_READ__DEBOUNCE, onMarkAsReadDebounceSet],
  [MARK_AS_READ__DELAY, onMarkAsReadDelaySet],
]);

/**
 * Reducer for all  actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const markAsRead = (
  state = initialState.markAsRead,
  action: MarkAsReadAction,
): MarkAsReadState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default markAsRead;
