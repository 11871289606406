/**
 * @file Helper functions for topicHeaderMobile container
 */

import { Location } from 'react-router-dom';

import { Hotel, TopicWithMembersFragment } from '../../../../generated/graphql';
import {
  createFeedTopicDetailsEditPath,
  createFeedTopicDetailsPath,
} from '../../../../routes/paths/feed';
import {
  createKanbanTopicDetailsEditPath,
  createKanbanTopicDetailsPath,
} from '../../../../routes/paths/kanban';
import { getTopicDetailsUrl } from '../../../../routes/urls/topic';
import { getCanUpdateTopic } from '../../../../utils/permissions/topic';

/**
 * Get the value for infoUrl prop which is responsible for rendering the edit
 * topic icon. We want to show the edit icon only if the use has permissions
 *
 * @param topic    The topic we need the details URL for
 * @param hotelId  ID of the hotel
 * @param location History location object
 * @returns        String in URL format if topic accessible otherwise undefined
 */
export const getInfoUrl = (
  topic: TopicWithMembersFragment | undefined,
  hotelId: Hotel['id'] | null,
  location: Location,
) => {
  const isTablet = true;

  // Pass true for isTablet to get link without sidebar search param
  return topic !== undefined && getCanUpdateTopic(topic)
    ? getTopicDetailsUrl(hotelId, isTablet, location, topic.id)
    : undefined;
};

const shouldShowTopicHeaderFor = [
  createFeedTopicDetailsPath(),
  createFeedTopicDetailsEditPath(),
  createKanbanTopicDetailsPath(),
  createKanbanTopicDetailsEditPath(),
];

/**
 * Get whether we should show the topic header
 *
 * @param currentPath The current path
 * @returns           Whether to show the topic header
 */
export const getShouldShowTopicHeader = (currentPath: string | null) => {
  return currentPath !== null && shouldShowTopicHeaderFor.includes(currentPath);
};
