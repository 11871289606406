/**
 * @file Check whether two dates fall on the same day
 */

import getDifferenceInDays from './getDifferenceInDays';

/**
 * Check whether two dates fall on the same day
 *
 * @param date1 The date to compare for
 * @param date2 The date to compare against
 * @returns     Whether it's true
 */
const getIsSameDay = (date1: Date, date2: Date): boolean => {
  return getDifferenceInDays(date1, date2) === 0;
};

export default getIsSameDay;
