/**
 * @file Helper functions for toast messages
 */

import { ToastMessageModel } from '../../../models/toastMessage';
import { CssVariable } from '../../../styles/cssTypes';

import { DataProps } from './types';

const mappingColorBackground: Record<ToastMessageModel['type'], CssVariable> = {
  error: 'var(--color-toast-background)',
  info: 'var(--color-hover)',
  notification: 'var(--color-background-avatar-inactive)',
};

const mappingColorPrimary: Record<ToastMessageModel['type'], CssVariable> = {
  error: 'var(--color-icons-danger)',
  info: 'var(--color-primary-light)',
  notification: 'var(--color-border-alternative)',
};

/**
 * Get which variable to use for wrapper background color
 *
 * @param props Props passed to the wrapper
 * @returns     The variable to use
 */
export const getToastColorBackground = (props: DataProps): CssVariable => {
  return mappingColorBackground[props['data-message-type']];
};

/**
 * Get which variable to use for wrapper border color
 *
 * @param props Props passed to the wrapper
 * @returns     The variable to use
 */
export const getToastColorPrimary = (props: DataProps): CssVariable => {
  return mappingColorPrimary[props['data-message-type']];
};
