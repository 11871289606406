/**
 * @file Gets the key down handler for the select item
 */

import { Dispatch, KeyboardEvent, SetStateAction } from 'react';

import { Item } from './types';
import { FocusHandlers } from './useFocusHandlers';

type KeyboardHandlerItemParams = {
  close: () => void;
  currentFocusIndex: number;
  focusHandlers: FocusHandlers;
  itemCount: number;
  selectItem: Dispatch<SetStateAction<Item>>;
  setTypeAheadValue: Dispatch<SetStateAction<string>>;
};

/**
 * Gets the key down handler for the select item
 *
 * @param args                   Args passed to the hook
 * @param args.close             Closes select dropdown
 * @param args.currentFocusIndex Index of the item that is currently focused
 * @param args.focusHandlers     Methods for handling of the focus position
 * @param args.itemCount         Number of items in the list
 * @param args.selectItem        Selects the new item to be current one
 * @param args.setTypeAheadValue Sets value for type-ahead focus change
 * @returns                      The handler
 */
const getKeyboardHandlerItem = ({
  close,
  currentFocusIndex,
  focusHandlers,
  itemCount,
  selectItem,
  setTypeAheadValue,
}: KeyboardHandlerItemParams): ((event: KeyboardEvent, item: Item) => void) => {
  /**
   * Focus the next item on arrowUp or first item if
   * last one is focused
   */
  const arrowDown = () => {
    if (currentFocusIndex === -1 || currentFocusIndex === itemCount - 1) {
      focusHandlers.toFirstItem();
      return;
    }
    focusHandlers.toItem(currentFocusIndex + 1);
  };

  /**
   * Focus the previous item on arrowUp or last item if
   * first one is focused
   */
  const arrowUp = () => {
    if (currentFocusIndex === -1 || currentFocusIndex === 0) {
      focusHandlers.toLastItem();
      return;
    }

    focusHandlers.toItem(currentFocusIndex - 1);
  };

  /**
   * Focus the last item on end
   */
  const end = () => {
    focusHandlers.toLastItem();
  };

  /**
   * Focus the first item on home
   */
  const home = () => {
    focusHandlers.toFirstItem();
  };

  /**
   * Focus the button and close the dropdown
   */
  const escape = () => {
    focusHandlers.toButton();
    close();
  };

  /**
   * Handle all printable keys on keyboard and avoid all
   * keys like Enter, Meta, Space, Backspace...
   *
   * @param key The key pressed
   */
  const printableKeys = (key: KeyboardEvent['key']) => {
    if (
      /**
       * This regex should check if key is printable key and if length is larger then 1
       * it means it's some other key like Backspace
       */
      /[a-zA-Z0-9./<>?;:"'`!@#$%^&*()\\[\]{}_+=|\\-~,]/.test(key) &&
      key.length === 1
    ) {
      setTypeAheadValue(value => value + key);
    }
  };

  /**
   * Line has too many *case* statements so it's deemed too complex,
   * I still haven't found a way to simplify it
   *
   * @param event The event that took place
   * @param item  The dropdown item
   */
  return (event: KeyboardEvent, item: Item) => {
    event.preventDefault();

    /**
     * Default keyboard input callback that gets returned if no other match is found
     *
     * @returns printableKeys function
     */
    const callbackDefault = () => printableKeys(event.key);

    /**
     * Callback for selecting items inside dropdown
     *
     * @returns selectItem function
     */
    const callbackSelect = () => selectItem(item);

    const mapping = new Map([
      ['ArrowDown', arrowDown],
      ['ArrowUp', arrowUp],
      ['End', end],
      ['Home', home],
      ['Escape', escape],
      ['Space', callbackSelect],
      ['Enter', callbackSelect],
    ]);

    const callback = mapping.get(event.code) ?? callbackDefault;

    callback();
  };
};

export default getKeyboardHandlerItem;
