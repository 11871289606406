/**
 * @file contains the draftAssignees reducer function
 */
import {
  DRAFTS__TEMPLATE__TOPIC__SAVE,
  DRAFTS__TEMPLATE__TOPIC__SEARCH__SET,
  DRAFTS__TEMPLATE__TOPIC__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionTemplateTopic,
  DraftActionTemplateTopicSave,
  DraftActionTemplateTopicSearchSet,
  DraftActionTemplateTopicSet,
} from '../../models/draftTemplateTopic';

import stateDraftsClone from '../../utils/stateDraftsClone';

import initialState from '../initialState';

type Reducer = (
  state: DraftsState,
  action: DraftActionTemplateTopic,
) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to set topics search term, so we store it
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSearchTemplateTopicsRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateTopicSearchSet,
) => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, value } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateTopicSearch = value;
  }

  return stateNext;
};

/**
 * A request was made to store currently checked assignees
 * so we transfer data from assigneeGroupsCurrent and assigneeMembersCurrent
 * to assigneeGroupsSaved and assigneeMembersSaved, respectively
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveTemplateTopicsRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateTopicSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateTopicSaved = draftCurrent.templateTopicCurrent;
  }

  return stateNext;
};

/**
 * The user has changed assignment status for a group,
 * so we update the state to reflect that
 *
 * @param stateCurrent The current state of the drafts
 * @param action       The action that took place
 * @returns            The updated state
 */
const onUpdateTemplateTopicsRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTemplateTopicSet,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const { parentId, topicId } = action;
  const draftCurrent = stateNext.get(parentId);

  if (draftCurrent) {
    draftCurrent.templateTopicCurrent = topicId;
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft assignees
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__TEMPLATE__TOPIC__SET, onUpdateTemplateTopicsRequest],
  [DRAFTS__TEMPLATE__TOPIC__SAVE, onSaveTemplateTopicsRequest],
  [DRAFTS__TEMPLATE__TOPIC__SEARCH__SET, onSearchTemplateTopicsRequest],
]);

/**
 * Drafts assignees reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftTemplateTopic = (
  state = initialState.drafts,
  action: DraftActionTemplateTopic,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftTemplateTopic;
