/**
 * @file Track deepl translation event
 */

import {
  Hotel,
  Locale,
  MeQuery,
  Message,
  TargetLanguage,
  Topic,
} from '../../generated/graphql';
import { getUserCurrent } from '../../utils/permissions/storage';

import { getCustomDimensions } from './customDimensions';
import { getMessageOrigin } from './helpers';
import { TranslationContainer } from './types';

import { track, TRACK_TYPES } from '.';

type AdditionalConfig = {
  container: TranslationContainer;
  message: Pick<Message, 'creatorType' | 'id' | 'title' | 'type'>;
  operation: 'translated' | 'untranslated';
  topicId: Topic['id'] | null;
};

/**
 * Get user language or target language based on the operation
 *
 * @param user      User who is tracked
 * @param operation Translate or untranslate
 * @returns         Language that is being tracked
 */
const getLanguage = (
  user: MeQuery['me'] | null,
  operation: AdditionalConfig['operation'],
): Locale | TargetLanguage | null => {
  if (user === null) {
    return null;
  }

  if (operation === 'translated') {
    return user.targetLanguage ?? null;
  }

  return user.language;
};

/**
 * Track deepl translation event
 *
 * @param hotelId          The ID of the hotel the board is for
 * @param config           Additional config
 * @param config.container Container that wraps the button
 * @param config.message   Message
 * @param config.operation Translate or untranslate
 * @param config.topicId   Topic ID
 */
export const trackTranslationEvent = (
  hotelId: Hotel['id'] | null,
  { container, message, operation, topicId }: AdditionalConfig,
): void => {
  const user = getUserCurrent();

  const customDimensions = getCustomDimensions(
    window.location.href,
    user,
    hotelId,
    {
      entityType: 'target language',
      entityValue: getLanguage(user, operation),
      messageId: message.id,
      messageOrigin: getMessageOrigin(message),
      messageTitle: message.title,
      topicId,
    },
  );

  track(
    [
      TRACK_TYPES.TRACK_EVENT,
      'Translation',
      'translate',
      `Post is ${operation} from ${container}`,
    ],
    customDimensions,
  );
};
