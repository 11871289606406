/**
 * @file contains functions used to create paths for use in Route related to the feed page
 */
import { ROUTE__CHAT } from '../../constants/routing/routes';

import { createDashboardPath } from './dashboard';

/**
 * Generate a path for a chat conversation
 *
 * @returns The generated path
 */
export const createChatPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__CHAT}`;
};

/**
 * Generate a path for a chat conversation
 *
 * @returns The generated path
 */
export const createChatConversationPath = (): string => {
  return `${createDashboardPath()}/${ROUTE__CHAT}/:conversationId`;
};
