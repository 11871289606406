/**
 * @file Get start of the day today Date object
 */

/**
 * Get start of the day today Date object
 *
 * @returns The said date object
 */
const getStartOfToday = (): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export default getStartOfToday;
