import React, { FC } from 'react';
import { connect } from 'react-redux';

import ComposeMetaOffset from '../../../components/Compose/Common/ComposeMeta/ComposeMetaOffset';
import MetaOffset from '../../../components/MetaOffset';
import { ComposeModeParam } from '../../../models/pageParams';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Manage template due date offset
 *
 * @param props                        Props passed to the container
 * @param props.dataOffsetCurrent      Currently selected offset
 * @param props.dataOffsetSaved        Saved offset
 * @param props.dataRemindersCurrent   Currently created reminders
 * @param props.dataRemindersSaved     Saved reminders
 * @param props.requestOffsetRemove    Request that the offset is cleared
 * @param props.requestOffsetSave      Request current -> saved store
 * @param props.requestOffsetSet       Request to update current offset
 * @param props.requestRemindersAdd    Request that one new reminder is added
 * @param props.requestRemindersRemove Request that one reminder is removed
 * @param props.requestRemindersSave   Request current -> save data transfer
 * @param props.requestRemindersSet    Request that adds one reminder
 * @returns                            The container itself
 */
const ComposeMetaOffsetContainer: FC<Props> = ({
  dataOffsetCurrent,
  dataOffsetSaved,
  dataRemindersCurrent,
  dataRemindersSaved,
  requestOffsetRemove,
  requestOffsetSave,
  requestOffsetSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSave,
  requestRemindersSet,
}) => {
  const mode: ComposeModeParam = 'offset';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  /**
   * Save currently selected offset and reminders and close the popup
   */
  const handleRequestSave = () => {
    requestOffsetSave();
    requestRemindersSave();
    goBack();
  };

  return (
    <MetaOffset
      dataOffsetCurrent={dataOffsetCurrent}
      dataOffsetSaved={dataOffsetSaved}
      dataRemindersCurrent={dataRemindersCurrent}
      dataRemindersSaved={dataRemindersSaved}
      inputCustom={<ComposeMetaOffset offset={dataOffsetSaved} />}
      isActive={isActive}
      linkToggleProps={linkToggleProps}
      requestOffsetRemove={requestOffsetRemove}
      requestOffsetSet={requestOffsetSet}
      requestRemindersAdd={requestRemindersAdd}
      requestRemindersRemove={requestRemindersRemove}
      requestRemindersSet={requestRemindersSet}
      requestSave={handleRequestSave}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaOffsetContainer);
