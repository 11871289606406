/**
 * @file contains redux actions related to scroll state
 */

import {
  SCROLL__REMEMBER__FEED,
  SCROLL__REMEMBER__SIDEBAR,
  SCROLL__RESET__FEED,
  SCROLL__TOPICS__LATEST_POST__ADD,
  SCROLL__TOPICS__LATEST_POST__REMOVE,
} from '../../constants/actionTypes';
import { Topic } from '../../generated/graphql';
import {
  ScrollRememberFeed,
  ScrollRememberSidebar,
  ScrollResetFeed,
  ScrollTopicsLatestPostAdd,
  ScrollTopicsLatestPostRemove,
} from '../../models/scroll';

/**
 * Action to remember the topic scroll position
 *
 * @param scrollPosition Current scroll position
 * @param topicId        Id of the topic for which we are remembering the scroll position
 * @returns              Appropriately formatted redux action
 */
export const scrollRememberFeed = (
  scrollPosition: number,
  topicId: Topic['id'],
): ScrollRememberFeed => {
  return {
    payload: {
      scrollPosition,
      topicId,
    },
    type: SCROLL__REMEMBER__FEED,
  };
};

/**
 * Action to remember the sidebar scroll position
 *
 * @param scrollPosition Current scroll position
 * @returns              Appropriately formatted redux action
 */
export const scrollRememberSidebar = (
  scrollPosition: number,
): ScrollRememberSidebar => ({
  payload: { scrollPosition },
  type: SCROLL__REMEMBER__SIDEBAR,
});

/**
 * Action to remember the topic scroll position
 *
 * @param topicId Id of the topic for which we are remembering the scroll position
 * @returns       Appropriately formatted redux action
 */
export const scrollResetFeed = (topicId: string): ScrollResetFeed => {
  return {
    payload: {
      topicId,
    },
    type: SCROLL__RESET__FEED,
  };
};

/**
 * Add a topic to the list of topics that should show the latest post button
 *
 * @param topicId Id of the topic which received the new message
 * @returns       Appropriately formatted redux action
 */
export const scrollTopicsLatestPostAdd = (
  topicId: string,
): ScrollTopicsLatestPostAdd => {
  return {
    payload: {
      topicId,
    },
    type: SCROLL__TOPICS__LATEST_POST__ADD,
  };
};

/**
 * Remove a topic from the list of topics that should show the latest post button
 *
 * @param topicId Id of the topic which received the new message
 * @returns       Appropriately formatted redux action
 */
export const scrollTopicsLatestPostRemove = (
  topicId: string,
): ScrollTopicsLatestPostRemove => {
  return {
    payload: {
      topicId,
    },
    type: SCROLL__TOPICS__LATEST_POST__REMOVE,
  };
};
