/**
 * @file Hook that that focuses first item on open if none is selected and focuses
 */

import { RefObject, useEffect } from 'react';

import { FocusHandlers } from './useFocusHandlers';

type UseAutoFocusItemParams = {
  focusFirstItem: FocusHandlers['toFirstItem'] | null;
  focusItem: FocusHandlers['toItem'];
  isOpen: boolean;
  itemRefs: RefObject<HTMLLIElement>[];
};

/**
 * Hook that that focuses first item on open if none is selected and focuses
 * selected item if there is any
 *
 * @param args                Args passed to the hook
 * @param args.focusFirstItem Focuses the first item in the list
 * @param args.focusItem      Focuses the item in the list based on the index passed in
 * @param args.isOpen         Whether the select is open
 * @param args.itemRefs       Refs for all the list option items
 */
const useFocusItemOnOpen = ({
  focusFirstItem,
  focusItem,
  isOpen,
  itemRefs,
}: UseAutoFocusItemParams): void => {
  useEffect(() => {
    if (isOpen === false) {
      return;
    }

    if (focusFirstItem !== null) {
      focusFirstItem();
      return;
    }

    const selectedItemIndex = itemRefs.findIndex(ref => {
      return ref.current?.getAttribute('aria-selected') === 'true';
    });

    focusItem(selectedItemIndex);
    /**
     * We only want to run this effect when isOpen changes
     * other dependencies don't interest us
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
};

export default useFocusItemOnOpen;
