import React, { FC, ReactNode, RefObject } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import useIsMobile from '../../../hooks/useIsMobile';

import MetaInputField from '../MetaInputField';
import MetaInputIcon from '../MetaInputIcon';

import * as Styled from './styled';

export type Props = {
  inputCustom?: ReactNode;
  linkProps: LinkProps;
  linkRef?: RefObject<HTMLAnchorElement>;
  placeholder: string;
  shouldDisablePopup?: boolean;
  shouldShowPopup: boolean;
  value: string;
};

/**
 * The persistent portion of a meta field
 * (looks like input, but it's not)
 *
 * @param props                    The props passed to the component
 * @param props.inputCustom        The component to use for input instead of the plain one
 * @param props.linkProps          Props to pass to the <Link /> element
 * @param props.linkRef            Wrapper link ref
 * @param props.placeholder        Placeholder text for the input
 * @param props.shouldDisablePopup Whether to disable popup
 * @param props.shouldShowPopup    Whether to show full view
 * @param props.value              Meta field value
 * @returns                        The component itself
 */
const MetaInput: FC<Props> = ({
  inputCustom,
  linkProps,
  linkRef,
  placeholder,
  shouldDisablePopup = false,
  shouldShowPopup,
  value,
}) => {
  const isMobile = useIsMobile();

  const inputDefault = (
    <>
      <MetaInputField placeholder={placeholder} value={value} />
      {shouldDisablePopup === false && (
        <MetaInputIcon isOpen={shouldShowPopup} />
      )}
    </>
  );

  const wrapperProps =
    shouldDisablePopup === true ? {} : { as: Link, ...linkProps, ref: linkRef };

  return (
    <Styled.Wrapper data-active={shouldShowPopup} {...wrapperProps}>
      {isMobile && inputCustom ? inputCustom : inputDefault}
    </Styled.Wrapper>
  );
};

export default MetaInput;
