import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  inputId: string;
  isVisible?: boolean;
  label: string;
};

/**
 * <label> wrapper (with its text) for form fields
 *
 * @param props           Props passed to the component
 * @param props.children  Label content (apart from its text)
 * @param props.className styled-components generated class name, needed for styling
 * @param props.inputId   The ID of the input that the labe is for
 * @param props.isVisible Whether label is visible
 * @param props.label     Label text
 * @returns               The component itself
 */
const FormLabel: FC<Props> = ({
  className,
  children,
  inputId,
  isVisible = true,
  label,
}) => {
  return (
    <Styled.Label className={className}>
      <Styled.LabelText data-is-visible={isVisible} htmlFor={inputId}>
        {label}
      </Styled.LabelText>
      {children}
    </Styled.Label>
  );
};

export default FormLabel;
