import React, { FC } from 'react';

import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useCurrentPath from '../../../hooks/useCurrentPath';
import useIsMobile from '../../../hooks/useIsMobile';

import translate from '../../../i18n/translate';
import { createSearchPath } from '../../../routes/paths/search';

import SearchHeaderDesktop from './desktop';

/**
 * The container for the search header
 *
 * @returns The component itself
 */
const HeaderSearch: FC = () => {
  const currentPath = useCurrentPath();
  const isMobile = useIsMobile();

  if (currentPath === createSearchPath() && isMobile === false) {
    return null;
  }

  if (isMobile) {
    return (
      <BaseHeaderMobile
        backUrl={true}
        isLoading={false}
        title={translate('GENERAL__SEARCH')}
      />
    );
  }

  return <SearchHeaderDesktop />;
};

export default HeaderSearch;
