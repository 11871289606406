/**
 * @file Hook that gets currently active templateId from url
 */
import { useParams } from 'react-router-dom';

import { Template } from '../../../generated/graphql';
import { SettingsTemplatesParams } from '../../../models/pageParams';

/**
 * Hook that gets currently active templateId from url
 *
 * @returns Currently active templateId
 */
const useParamTemplateId = (): Template['id'] | null => {
  const { templateId } = useParams<SettingsTemplatesParams>();

  if (!templateId) {
    return null;
  }

  return templateId;
};

export default useParamTemplateId;
