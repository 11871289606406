import { styled } from 'styled-components';

import { CssVariable } from '../../styles/cssTypes';

type HotelInfoProps = {
  'data-is-current': boolean;
};

/**
 * Get which color to use for item background
 *
 * @param props Props passed to li element
 * @returns     Which color to use
 */
const getBackgroundColor = (props: HotelInfoProps): CssVariable => {
  if (props['data-is-current']) {
    return 'var(--color-hover)';
  }

  return 'var(--color-background)';
};

export const Ul = styled.ul`
  padding: var(--spacing-small) var(--spacing-medium) var(--spacing-large);
`;

export const Li = styled.li<HotelInfoProps>`
  background-color: ${getBackgroundColor};
  padding: var(--spacing-small);
`;
