/**
 * @file Get parsed array of rrule byweekday
 */

import { Weekday } from 'rrule';

import { getWeekday } from './getWeekday';

/**
 * Get parsed array of rrule byweekday
 *
 * @param byweekday rrule byweekday option
 * @returns         Number array of rrule byweekday
 */
export const getParsedWeekdays = (
  byweekday: Weekday | Weekday[] | null | undefined,
): Set<number> => {
  if (
    byweekday === null ||
    byweekday === undefined ||
    (Array.isArray(byweekday) && byweekday.length === 0)
  ) {
    return new Set([getWeekday()]);
  }

  if (Array.isArray(byweekday)) {
    return new Set(byweekday.map((weekday: Weekday) => weekday.weekday));
  }

  return new Set([byweekday.weekday]);
};

/**
 * Get parsed array of rrule byweekday (single)
 *
 * Rrule uses the same prop (byweekday) for weekly and monthly frequencies
 * so we only need to parse one weekday for monthly frequency
 *
 * @param byweekday rrule byweekday option
 * @returns         rrule byweekday (single) number
 */
export const getParsedWeekdaySingle = (
  byweekday: Weekday | Weekday[] | null | undefined,
): number => {
  if (
    byweekday === null ||
    byweekday === undefined ||
    (Array.isArray(byweekday) && byweekday.length === 0)
  ) {
    return 0;
  }

  if (Array.isArray(byweekday)) {
    return byweekday[0].weekday;
  }

  return byweekday.weekday;
};
