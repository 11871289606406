/**
 * @file Generates refs for the wrapper, button and items
 */

import { createRef, RefObject, useMemo, useRef } from 'react';

type UseSelectRefsReturn = {
  buttonRef: RefObject<HTMLDivElement>;
  itemRefs: RefObject<HTMLLIElement>[];
  wrapperRef: RefObject<HTMLDivElement>;
};

/**
 * Generates refs for the wrapper, button and items
 *
 * @param itemCount Number of items in the list
 * @returns         Refs needed for the select dropdown
 */
const useSelectRefs = (itemCount: number): UseSelectRefsReturn => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const itemRefs = useMemo<RefObject<HTMLLIElement>[]>(
    () => Array.from({ length: itemCount }, () => createRef<HTMLLIElement>()),
    [itemCount],
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  return {
    buttonRef,
    itemRefs,
    wrapperRef,
  };
};

export default useSelectRefs;
