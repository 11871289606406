/**
 * @file Date format helper functions
 */

import {
  PREFERENCE_DATE_FORMAT,
  PREFERENCE_MESSAGE_DUE_DATE_FORMAT,
  PREFERENCE_TIME_FORMAT,
} from '../../constants/preferences';
import { getLocaleCode } from '../user/locale';

/**
 * Pad the number, to occupy the correct number of chars
 * (so pad(5, 3, 'k') returns 'kk5')
 *
 * @param number    The number to pad
 * @param nrDigits  The number of digits we want
 * @param padString The string to fill each of the free spots with
 * @returns         Padded string representation of the passed number
 */
const pad = (number: number, nrDigits = 2, padString = '0'): string => {
  return number.toString().padStart(nrDigits, padString);
};

/**
 * Get time in format that's suitable for use in
 * <input type="time" /> value
 *
 * @param date The date to get the representation for
 * @returns    String representation for the time, formatted correctly
 */
export const timeFormatInput = (date: Date): string => {
  return pad(date.getHours()) + ':' + pad(date.getMinutes());
};

/**
 * Get string representation of the current time,
 * in locale format
 *
 * @param date The date to get the representation for
 * @returns    hh:mm time
 */
export const timeFormatUi = (date: Date): string => {
  return date.toLocaleString(getLocaleCode(), PREFERENCE_TIME_FORMAT);
};

/**
 * Get date in format that's suitable for use in
 * <input type="date" /> value
 *
 * @param date The date to get the representation for
 * @returns    String representation for the date, formatted correctly
 */
export const dateFormatInput = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate())
  );
};

/**
 * Get date and time in format that's suitable for use in
 * <input type="datetime-local" /> value
 *
 * @param date The date to get the representation for
 * @returns    String representation for the date, formatted correctly
 */
export const dateTimeFormatInput = (date: Date): string => {
  return dateFormatInput(date) + 'T' + timeFormatInput(date);
};

/**
 * Get string representation of the passed date
 *
 * @param date The date to get the representation for
 * @returns    Locale formatted date
 */
export const dateFormatUi = (date: Date): string => {
  return date.toLocaleString(getLocaleCode(), PREFERENCE_DATE_FORMAT);
};

/**
 * Get string representation of the passed date string
 *
 * @param date The date to get the representation for
 * @returns    Locale formatted date
 */
export const dateFormatUiFromString = (date: string): string => {
  // Does nothing for now, since incoming date format is not standardized
  // In the future, it will be and we'll reformat the date properly
  return date;
};

/**
 * Get date and time as it should be displayed in the UI
 *
 * @param date The date to get the text for
 * @returns    String representation of the datetime
 */
export const dateTimeFormatUi = (date: Date): string => {
  return timeFormatUi(date) + ', ' + dateFormatUi(date);
};

/**
 * Get date and time as it should be displayed in the UI
 *
 * @param date The date to get the text for
 * @returns    String representation of the datetime
 */
export const dateTimeFormatUiFromString = (date: string): string => {
  // Does nothing for now, since incoming date format is not standardized
  // In the future, it will be and we'll reformat the date properly
  return date;
};

/**
 * Converts date into string.
 *
 * @param date Date that we want to convert into string
 * @returns    String
 */
export const dateToString = (date?: string | null): string => {
  return date?.toString() ?? '';
};

/**
 * Converts date string into string representation of the current time,
 * in locale format
 *
 * @param dateRaw Date as string type
 * @returns       String representation of the time (HH:MM AM/PM) or empty string if date is null/undefined
 */
export const timeFormatUiFromString = (
  dateRaw: string | null = null,
): string => {
  if (dateRaw === null) {
    return timeFormatUi(new Date());
  }

  const isValidDate = Number.isNaN(new Date(dateRaw).getTime()) === false;

  if (isValidDate) {
    return timeFormatUi(new Date(dateRaw));
  }

  const matches = dateRaw.match(/([0-9]{1,2})/g);

  if (matches === null) {
    return timeFormatUi(new Date());
  }

  // We can't use
  // const [hours, minutes] = dateRaw.trim().split(':').map(Number);
  // because we won't always get '12:34', sometimes we will have
  // 'de => en : 12:34'
  const [hours = 0, minutes = 0, seconds = 0] = matches.map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  if (dateRaw.match(/pm/i) !== null) {
    date.setHours(date.getHours() + 12);
  }
  return timeFormatUi(date);
};

/**
 * Converts date string into string representation of the date,
 * in locale format
 *
 * @param date Date as string type
 * @returns    String representation of the date (MONTH DD) or empty string if date is null/undefined
 */
export const dueDateFormatUi = (date?: string | null): string => {
  return date !== undefined && date !== null
    ? new Date(date).toLocaleString(
        getLocaleCode(),
        PREFERENCE_MESSAGE_DUE_DATE_FORMAT,
      )
    : '';
};
