/**
 * @file Determine which notifications modal to show, if any,
 * based on the linked diagrams
 * This one is for "Page load" action
 */

import { useEffect, useState } from 'react';

import useIsSubscribed from '../../../../../hooks/notifications/useIsSubscribed';
import useIsSupported from '../../../../../hooks/notifications/useIsSupported';
import useNotificationPermissionStatus from '../../../../../hooks/notifications/useNotificationPermissionStatus';
import { getIsFirstLogin } from '../../../../../utils/storage/firstLogin';
import {
  getHasUnsubscribed,
  getModalGreenDismissed,
  getModalRedDismissed,
} from '../../../../../utils/storage/notifications';

import { NotificationModal } from '../helpers';

import useHasMessages from './useHasMessages';
import usePushNotificationsSettingsPage from './useIsPushNotificationsSettingsPage';

/**
 * Determine which notifications modal to show, if any,
 * based on the linked diagrams
 * This one is for "Page load" action
 *
 * @see     https://app.diagrams.net/#G1belOmzoJ_04rEnWU8WMkeGsToqeQyvty
 * @returns Which modal to show (if any)
 */
const useShouldShowOnPageLoad = () => {
  const isPushSettingsPage = usePushNotificationsSettingsPage();
  const [toShow, setToShow] = useState<NotificationModal | null>(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  const hasMessages = useHasMessages();
  const isFirstLogin = getIsFirstLogin();
  const isSupported = useIsSupported();
  const isSubscribed = useIsSubscribed();
  const permission = useNotificationPermissionStatus();

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isSupported === false) {
      return setToShow(null);
    }

    if (permission === null || permission === 'granted') {
      return setToShow(null);
    }

    if (isSubscribed === true) {
      return setToShow(null);
    }

    if (getHasUnsubscribed() === true) {
      return setToShow(null);
    }

    if (isPushSettingsPage) {
      if (getModalRedDismissed() === false) {
        return setToShow('red');
      }

      return setToShow(null);
    }

    if (getModalGreenDismissed() === true) {
      return setToShow(null);
    }

    if (hasMessages === true) {
      return setToShow(null);
    }

    if (isFirstLogin === false) {
      return setToShow(null);
    }

    setToShow('green');
  }, [
    hasMessages,
    isFirstLogin,
    isPushSettingsPage,
    isSubscribed,
    isSupported,
    pageLoaded,
    permission,
  ]);

  useEffect(() => {
    /**
     * The page was loaded, so we update the state,
     * because that's a condition for modals
     *
     * @returns void
     */
    const handlePageLoad = () => setPageLoaded(true);

    window.addEventListener('DOMContentLoaded', handlePageLoad, false);

    return () => {
      window.removeEventListener('DOMContentLoaded', handlePageLoad);
    };
  }, []);

  return toShow;
};

export default useShouldShowOnPageLoad;
