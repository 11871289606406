import React, { FC } from 'react';

import BaseHeaderDesktop from '../../../components/Header/BaseHeaderDesktop';
import MuiIcon from '../../../components/MUI/MuiIcon';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import HeaderDashboardMobile from './mobile';

/**
 * Header component for the dashboard page
 *
 * @returns The component itself
 */
const HeaderDashboard: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <HeaderDashboardMobile />;
  }

  return (
    <BaseHeaderDesktop
      actionButtonRight={null}
      buttons={[]}
      description={translate('DASHBOARD__DESCRIPTION')}
      leadingVisual={<MuiIcon icon="speed" />}
      subtitle={null}
      title={translate('DASHBOARD__OVERVIEW')}
    />
  );
};

export default HeaderDashboard;
