import React, { FC } from 'react';

import { Hotel } from '../../../../generated/graphql';

import translate from '../../../../i18n/translate';
import { getHomeUrl } from '../../../../routes/urls/home';

import * as Styled from './styled';

export type HotelsData = {
  hotels: {
    id: Hotel['id'];
    name: Hotel['name'];
  }[];
  isLoading: boolean;
};

type Props = {
  hotels: HotelsData['hotels'];
  isLoading: boolean;
};

/**
 * List of hotels that user can access
 *
 * @param props           Props passed to the component
 * @param props.hotels    List of hotels for a user
 * @param props.isLoading Hotels list is currently fetching or not
 * @returns               The component itself
 */
const HotelsList: FC<Props> = ({ hotels, isLoading }) => {
  if (isLoading) {
    return <Styled.SkeletonColumnList numberOfLines={2} />;
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>
        {translate('ERROR__HOTEL__SWITCH_DESCRIPTION')}
      </Styled.Title>
      <ul>
        {hotels.map(hotel => {
          return (
            <li key={hotel.id}>
              {translate('ERROR__HOTEL__SWITCH_LINK_TEXT_PREFIX')}
              {/* Using regular <a> tag here instead of react-router-dom Link because
              we want a full page reload on switching hotels */}
              <Styled.Link href={getHomeUrl(hotel.id)}>
                {hotel.name}
              </Styled.Link>
              {translate('ERROR__HOTEL__SWITCH_LINK_TEXT_SUFIX')}
            </li>
          );
        })}
      </ul>
    </Styled.Wrapper>
  );
};

export default HotelsList;
