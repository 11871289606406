import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import FloatingBottomNav from '../../../components/FloatingBottomNav';
import AppLayout from '../../../components/layouts/AppLayout';
import HeaderSmartViews from '../../../containers/header/smartViews';
import SearchResultsContainer from '../../../containers/searchResults';
import useElasticSearchQuery from '../../../hooks/elasticSearch/useEsSmartViewQuery/useElasticSearchQuery';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamSmartView from '../../../hooks/router/params/useParamSmartView';
import useCurrentPath from '../../../hooks/useCurrentPath';

import useIsMobile from '../../../hooks/useIsMobile';
import { createSmartViewsSmartViewPath } from '../../paths/smartViews';

import { getHomeUrl } from '../../urls/home';

/**
 * SmartViews page
 *
 * @returns The page itself
 */
const PageSmartViews: FC = () => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const smartViewId = useParamSmartView();
  const esQuery = useElasticSearchQuery({ id: smartViewId ?? '' });
  const isMobile = useIsMobile();

  /**
   * Check if on individual smart view (/smart-views/:smartView)
   * (/smart-views path does not exist atm in the app)
   */
  if (currentPath === createSmartViewsSmartViewPath()) {
    return (
      <AppLayout
        footer={isMobile ? <FloatingBottomNav /> : null}
        header={<HeaderSmartViews />}
      >
        <SearchResultsContainer esQuery={esQuery} />
      </AppLayout>
    );
  }

  /**
   * Maybe unneeded check but just in case
   */
  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default PageSmartViews;
