/**
 * @file ES pagination for urql
 */

import { stringifyVariables } from '@urql/core';
import {
  Cache,
  Data,
  DataField,
  NullArray,
  ResolveInfo,
  Resolver,
  Variables,
} from '@urql/exchange-graphcache';

/**
 * Function to compare arguments of queries in cache and arguments passed to the
 * most recent search query. If the arguments are the same, the goal is to group them
 * into one return result for the user so he can paginate over results, but if the
 * arguments are different, that means we have a new query.
 *
 * @param fieldArgs      Arguments passed to the most recent query
 * @param connectionArgs Arguments in cache
 * @returns              Whether the query arguments are the same
 */
const compareArgs = (
  fieldArgs: Variables,
  connectionArgs: Variables | null,
): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const fieldArgsQuery = fieldArgs?.params?.queryString;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const connectionArgsQuery = connectionArgs?.params?.queryString;

  return (
    typeof fieldArgsQuery === 'string' &&
    typeof connectionArgsQuery === 'string' &&
    fieldArgsQuery === connectionArgsQuery
  );
};

/**
 * Ensures that the cache key exists and
 * gives proper Typescript support
 *
 * @param value Resolved cache value
 * @returns     Resolved key or null if not resolved
 */
const ensureKey = (value: DataField): string | null =>
  typeof value === 'string' ? value : null;

/**
 * Handles Elastic Search pagination by adding up the results that have the
 * same searchQuery into one array in cache.
 *
 * @returns URQL cache resolver
 */
export const esPaginationRanked = (): Resolver => {
  return (
    _parent: Data,
    fieldArgs: Variables,
    cache: Cache,
    info: ResolveInfo,
  ) => {
    const { parentKey: entityKey, fieldName } = info;

    /**
     * All cache fields from all queries made in the app
     */
    const allFields = cache.inspectFields(entityKey);

    /**
     * All cache fields that have the same name as the
     * query that is in question (aka messageSearch)
     */
    const fieldInfos = allFields.filter(filedInfo => {
      return filedInfo.fieldName === fieldName;
    });
    const size = fieldInfos.length;

    if (size === 0) {
      return;
    }

    /**
     * Used to check if entity is already in cache
     */
    const fieldKey = `${fieldName}(${stringifyVariables(fieldArgs)})`;
    const isItInTheCache = cache.resolve(entityKey, fieldKey);

    /**
     * Let urql know if to fetch more data from the server, if it is true, urql
     * will think we did not give it all the data and will fetch it from the server
     */
    info.partial = !isItInTheCache;

    /**
     * Array to store results that will be sent as the result after pagination
     */
    let result: NullArray<string> = [];
    let __typename;
    let searchInfo;

    fieldInfos.forEach(fi => {
      const data = cache.resolve(entityKey, fi.fieldKey);

      if (data === undefined) {
        return;
      }

      const link = ensureKey(data);

      /**
       * If no arguments, cache value or if arguments from cache,
       * and arguments from the request are not the same (aka user has made a new search)
       * -> skip that fieldInfo when iterating over them
       */
      if (
        link === null ||
        fi.arguments === null ||
        compareArgs(fieldArgs, fi.arguments) === false
      ) {
        return;
      }

      /**
       * Create a temporary array to store fresh results
       * from the server
       */
      const tempResult: NullArray<string> = [];

      /**
       * Resolve __typename, nodes and searchInfo needed
       * to mimic the result from the query
       */
      __typename = cache.resolve(link, '__typename') as string;
      const nodes = (cache.resolve(link, 'nodes') ?? []) as NullArray<string>;
      searchInfo = cache.resolve(link, 'searchInfo');

      tempResult.push(...nodes);

      /**
       * Push new results at the end, later we can
       * edit this if needed to be reversed
       */
      result = [...result, ...tempResult];

      return;
    });

    return {
      __typename,
      /**
       * nodes has to be returned as a Set in order to filter out duplicate search
       * results cause 2 different searches can yield same result message
       */
      nodes: Array.from(new Set(result)),
      searchInfo,
    };
  };
};
