import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import MessageDateDue from '../../../../Common/MessageDateDue';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  date?: string;
};

/**
 * Section in the message compose that displays due date
 *
 * @param props      Props passed to the component
 * @param props.date Date value
 * @returns          The component itself
 */
const ComposeMetaDateDue: FC<Props> = ({ date }) => {
  return (
    <ComposeMetaSection heading={translate('MESSAGE__HEADING__DATE_DUE')}>
      <MessageDateDue date={date} />
    </ComposeMetaSection>
  );
};

export default ComposeMetaDateDue;
