import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  ariaLabel: string;
  className?: string;
};

/**
 * Red circle badge that indicates something
 *
 * @param props           Props passed to the component
 * @param props.ariaLabel Aria label for the badge indicator
 * @param props.className styled-components className
 * @returns               BadgeIndicator component
 */
const BadgeIndicator: FC<Props> = ({ ariaLabel, className }) => {
  return (
    <Styled.Circle
      aria-label={ariaLabel}
      aria-live="polite"
      className={className}
      role="status"
    />
  );
};

export default BadgeIndicator;
