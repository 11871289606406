import React, { FC } from 'react';

import FloatingBottomNav from '../../../../../components/FloatingBottomNav';
import ComposeContainer from '../../../../../containers/compose';
import OpenComposeButton from '../../../../../containers/openComposeButton';
import useParamTopicId from '../../../../../hooks/router/params/useParamTopicId';

import useComposeModeShown from '../../../../../hooks/router/search/useComposeModeShown';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { getCanCreateMessage } from '../../../../../utils/permissions/message';

import { getShouldShowButtonCreate } from './helpers';

/**
 * Component that shows feed header
 *
 * @returns Topic subheader component
 */
const FeedFooter: FC = () => {
  const isMobile = useIsMobile();
  const topicId = useParamTopicId();
  const shouldShowButtonCreate = getShouldShowButtonCreate(
    getCanCreateMessage(),
    topicId,
  );
  const shouldShowCompose = useComposeModeShown();

  return (
    <>
      {shouldShowCompose && <ComposeContainer nodeType="MESSAGE" />}
      {isMobile && (
        <FloatingBottomNav
          floatingButton={shouldShowButtonCreate && <OpenComposeButton />}
        />
      )}
    </>
  );
};

export default FeedFooter;
