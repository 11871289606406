import { styled } from 'styled-components';

import Icon from '../Icon';

export type ChevronDirection = 'up' | 'down' | 'left' | 'right';

type ChevronIconProps = {
  'data-direction': ChevronDirection;
};

/**
 * Get by how much degrees to rotate the icon depending on where it should point
 *
 * @param direction The direction of the chevron
 * @returns         The rotation degree
 */
const getRotation = (direction: ChevronDirection): number => {
  const mapping = new Map<ChevronDirection, number>([
    ['up', -90],
    ['right', 0],
    ['down', 90],
    ['left', 180],
  ]);

  return mapping.get(direction)!;
};

export const ChevronIcon = styled(Icon)<ChevronIconProps>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  transform: rotate(${props => getRotation(props['data-direction'])}deg);
  transition: transform var(--preference-transition-duration-normal);
`;
