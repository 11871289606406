import { styled } from 'styled-components';

export const Section = styled.section`
  flex-grow: 1;
`;

// Actions Section
export const ActionsWrapper = styled.section`
  align-items: center;
  border-top: var(--border-pale);
  padding: var(--spacing-medium);

  /**
   * A fix for the scrolling issue on right sidebar for checklist overflow
   * pushing the page up
   * Resolved by creating another layer with position relative
   */
  position: relative;
`;

// ReadBy Section
export const Percentage = styled.span`
  margin-left: var(--spacing-tiny);
`;
