/**
 * @file Helpers related to Task card component
 */

import {
  KANBAN__CHIP__MAX_TEXT_LENGTH,
  KANBAN__CHIPS_SHOWN,
} from '../../../constants/preferences';
import { MessageFeed } from '../../../models/message';
import { colorPrimary } from '../../../styles/mui/colors';
import truncateText from '../../../utils/string/truncateText';
import { getSurplusTooltipText } from '../helpers';

type LabelToShow = {
  color: string;
  key: string;
  text: string;
  tooltip: string | null;
};

/**
 * Retrieves the visible labels with truncated text for display.
 *
 * @param labels Array of label objects
 * @returns      Array of objects containing color, key, and truncated text.
 */
export const getLabelsToShow = (
  labels: MessageFeed['labels'],
): LabelToShow[] => {
  const nrHidden = labels.length - KANBAN__CHIPS_SHOWN;

  const toShow: LabelToShow[] = labels
    .slice(0, KANBAN__CHIPS_SHOWN)
    .map(label => ({
      color: label.color,
      key: label.id,
      text: truncateText(KANBAN__CHIP__MAX_TEXT_LENGTH, label.text),
      tooltip: null,
    }));

  if (nrHidden > 0) {
    const tooltip = getSurplusTooltipText(
      labels.map(label => label.text),
      KANBAN__CHIPS_SHOWN,
    );

    toShow.push({
      color: colorPrimary[200],
      key: 'additional',
      text: '+' + nrHidden,
      tooltip,
    });
  }

  return toShow;
};
