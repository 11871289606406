/**
 * @file Check whether the user can access the requested message
 */

import { useQuery } from 'urql';

import {
  Maybe,
  Message,
  MessageFullDocument,
  MessageFullFragment,
} from '../../generated/graphql';

type MessageID = Maybe<Message['id']>;

/**
 * Check whether the user can access the requested message
 *
 * @param messageId The message to check
 * @returns         The accessible message and whether the data was fetched
 */
const useMessageAccessible = (
  messageId: MessageID,
): [MessageFullFragment | null, boolean] => {
  /**
   * Using this query, as it fetches the whole message
   * Otherwise, if a container tries to fetch that while this has not resolved yet,
   * That request will be cancelled and stuck in fetching state
   *
   * @see https://gastfreund.atlassian.net/browse/HOT-2256
   * @todo Revisit after urql is updated
   */
  const [{ data, fetching }] = useQuery({
    pause: messageId === null,
    query: MessageFullDocument,
    variables: { messageId: messageId ?? '' },
  });

  return [data?.message ?? null, fetching];
};

export default useMessageAccessible;
