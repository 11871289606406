/**
 * @file Topic mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorTopicMain, navigatorTopicSettings } from './navigators';

const mapperTopic = {
  // Topic is created, changed
  [NotificationEvent.TopicCreated]: navigatorTopicMain,
  [NotificationEvent.TopicDescriptionUpdated]: navigatorTopicMain,
  [NotificationEvent.TopicTitleUpdated]: navigatorTopicMain,

  // User or group is added to or removed from a topic
  [NotificationEvent.TopicGroupAdded]: navigatorTopicMain,
  [NotificationEvent.TopicGroupRemoved]: navigatorTopicMain,
  [NotificationEvent.TopicMemberAdded]: navigatorTopicMain,
  [NotificationEvent.TopicMemberRemoved]: navigatorTopicMain,

  [NotificationEvent.TopicDeleted]: navigatorTopicSettings,
  [NotificationEvent.TopicPrivacyUpdatedToPrivate]: navigatorTopicSettings,
  [NotificationEvent.TopicPrivacyUpdatedToPublic]: navigatorTopicSettings,
};

export default mapperTopic;
