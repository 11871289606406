import { styled } from 'styled-components';

// Wrapper around the entire component
export const Ul = styled.ul`
  align-items: center;
  background-color: var(--color-background-tools);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  padding: var(--spacing-small);
`;

// A single list item (for image/document attachment)
export const LiAttachment = styled.li`
  &:not(:last-child) {
    margin-right: var(--spacing-small);
  }
`;

// Wrapper for bold/italic/strikethrough/bullets/numbers buttons
export const LiStyles = styled.li`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

// Li around the submit or cancel button
export const ItemButton = styled.li`
  margin-left: var(--spacing-small);
`;
