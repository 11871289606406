/**
 * @file Contains helper functions for Reminders
 */

import { PREFERENCE_REMINDERS_LIST } from '../../constants/preferences';
import { Reminder, TimeUnit } from '../../generated/graphql';
import { Item } from '../../hooks/useSelect';
import translate from '../../i18n/translate';
import { ReminderString } from '../../models/reminder';
import { getLocaleCode } from '../../utils/user/locale';

/**
 * Generate translation for reminder item
 *
 * @param amount Reminder amount
 * @param unit   Reminder unit
 * @returns      Translation based on amount and unit
 */
export const getTranslation = (amount: number, unit: TimeUnit): string => {
  if (amount === 0) {
    return translate('MODAL__REMINDERS__AT_TIME_OF_DUE_DATE');
  }

  const locale = getLocaleCode();
  const rtf = new Intl.RelativeTimeFormat(locale).formatToParts(
    Math.abs(amount),
    unit.toLowerCase() as unknown as Intl.RelativeTimeFormatUnit,
  );

  return `${rtf[1].value}${rtf[2].value} ${translate(
    amount > 0
      ? 'MODAL__REMINDERS__BEFORE_SUFFIX'
      : 'MODAL__REMINDERS__AFTER_SUFFIX',
  )}`;
};

/**
 * Get reminder items used for Select options
 *
 * @returns Reminder items
 */
export const getItems = (): Item<ReminderString>[] => {
  return PREFERENCE_REMINDERS_LIST.map(reminder => ({
    label: getTranslation(reminder.amount, reminder.unit),
    value: `${reminder.amount}_${reminder.unit}`,
  }));
};

/**
 * Get reminder from Select option value string
 *
 * @param value Select option value
 * @returns     Reminder item
 */
export const getReminderFromValue = (value: ReminderString): Reminder => {
  const reminderValues = value.split('_');
  return {
    amount: parseInt(reminderValues[0], 10),
    unit: reminderValues[1].toUpperCase() as TimeUnit,
  };
};

/**
 * Get the label for the reminder select
 *
 * @param defaultItem Select reminder default item
 * @returns           Label
 */
export const getReminderSelectLabel = (
  defaultItem: Item<ReminderString> | undefined,
): string => {
  if (defaultItem !== undefined) {
    return defaultItem.label as string;
  }

  const items = getItems();

  return items[0].label as string;
};
