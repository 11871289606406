import React, { FC } from 'react';

import { MESSAGE_STATUS_TRANSLATION_KEY } from '../../../../constants/message';
import { HASH_MESSAGE__INFO__STATUS } from '../../../../constants/routing/hashLinks';

import { MessageStatus } from '../../../../generated/graphql';

import translate from '../../../../i18n/translate';
import { MessageCommon } from '../../../../models/message';

import MessageInfo from '../MessageInfo';

import * as Styled from './styled';

type Props = {
  messageStatus?: MessageStatus | null;
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

/**
 * Shows current status of message
 *
 * @param props               Props passed to the component
 * @param props.messageId     Message id
 * @param props.messageStatus Status in which message is (in progress, open)
 * @param props.shouldBeLink  Whether to render as a link or not
 * @returns                   The component itself
 */
const MessageInfoStatus: FC<Props> = ({
  messageId,
  messageStatus = null,
  shouldBeLink,
}) => {
  if (messageStatus === null) return null;

  return (
    <MessageInfo
      elementId={HASH_MESSAGE__INFO__STATUS}
      labelKey="ARIA__MESSAGE_INFO__STATUS"
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      {/* Values are hardcoded because we are using this component instead of an icon */}
      <Styled.MessageProgressBar
        color="var(--color-blue-dark)"
        max={3}
        value={2}
      />
      {translate(MESSAGE_STATUS_TRANSLATION_KEY[messageStatus])}
    </MessageInfo>
  );
};

export default MessageInfoStatus;
