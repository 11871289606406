/**
 * @file contains keys for session storage key->value store
 */

export const KEY__PUSH_DISMISSED_GREEN = 'push-dismissed-green';
export const KEY__PUSH_DISMISSED_PURPLE = 'push-dismissed-purple';
export const KEY__PUSH_DISMISSED_RED = 'push-dismissed-red';
export const KEY__PUSH_PAUSED = 'push-paused';
export const KEY__PUSH_TOKEN = 'push-firebase-token';
export const KEY__PUSH_UNSUBSCRIBED = 'push-unsubscribed';
