/**
 * @file contains the draftOffset reducer function
 */

import {
  DRAFTS__OFFSET__SAVE,
  DRAFTS__OFFSET__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionOffset,
  DraftActionOffsetSave,
  DraftActionOffsetSet,
} from '../../models/draftOffset';

import stateDraftsClone from '../../utils/stateDraftsClone';
import initialState from '../initialState';

type Reducer = (state: DraftsState, action: DraftActionOffset) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to save offset,
 * so we store the data from offsetCurrent to offsetSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveOffsetRequest = (
  stateCurrent: DraftsState,
  action: DraftActionOffsetSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.offsetSaved = draftNext.offsetCurrent;
  }

  return stateNext;
};

/**
 * A request was made to update current offset
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetOffsetRequest = (
  stateCurrent: DraftsState,
  action: DraftActionOffsetSet,
): DraftsState => {
  const { offset, parentId } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.offsetCurrent = offset;
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft checklists
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__OFFSET__SAVE, onSaveOffsetRequest],
  [DRAFTS__OFFSET__SET, onSetOffsetRequest],
]);

/**
 * Drafts offset reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsOffset = (
  state = initialState.drafts,
  action: DraftActionOffset,
): DraftsState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsOffset;
