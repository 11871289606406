/**
 * @file contains helper functions for the MetaOffset component
 */

import { DateOffsetInput, Reminder } from '../../generated/graphql';
import { sortReminders } from '../../utils/reminders/sortReminders';

/**
 * We need to check if currently selected offset is equal to draft/saved offset
 * or reminders are set and due date is not
 * so we can disable save button in those cases.
 *
 * @param dataOffsetCurrent Current due date
 * @param dataOffsetSaved   Saved due date
 * @param remindersCurrent  Current reminders
 * @param remindersSaved    Saved reminders
 * @returns                 Whether the save button should be disabled
 */
export const getIsSaveDisabled = (
  dataOffsetCurrent: DateOffsetInput | null,
  dataOffsetSaved: DateOffsetInput | null,
  remindersCurrent: Reminder[],
  remindersSaved: Reminder[],
): boolean => {
  if (dataOffsetCurrent === null) {
    return remindersCurrent?.length > 0;
  }

  if (remindersCurrent.length !== remindersSaved.length) {
    return false;
  }

  // Remove duplicates and sort reminders for comparison
  const remindersCurrentSorted = [...new Set(remindersCurrent)].sort(
    sortReminders,
  );
  const remindersSavedSorted = [...new Set(remindersSaved)].sort(sortReminders);

  // JSON.stringify() is used here so we can check for deep equality.
  return (
    JSON.stringify(dataOffsetCurrent) === JSON.stringify(dataOffsetSaved) &&
    JSON.stringify(remindersCurrentSorted) ===
      JSON.stringify(remindersSavedSorted)
  );
};
