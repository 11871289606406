import { styled } from 'styled-components';

import Input from '../../Common/Input';

// Label text field
export const Title = styled(Input)`
  display: block;
  margin-bottom: var(--spacing-medium);
  width: 100%;
`;
