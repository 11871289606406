import { Stack, StackProps, styled } from '@mui/material';
import { LinkProps } from 'react-router-dom';

export const Wrapper = styled(Stack)<StackProps & LinkProps>(({ theme }) => ({
  '&:focus-visible, &:hover': {
    // No specific reason for 75% and 4, it was just a matter of trying it out
    filter: 'opacity(75%) saturate(4)',
  },
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  // Fix for Safari, where the hover effect
  // has color overshoot and jump back and emojis move a few px
  transform: 'translateZ(0)',
  transition: 'filter var(--preference-transition-duration-normal)',
}));
