/**
 * @file contains the draftReminders reducer function
 */

import {
  DRAFTS__REMINDERS__ADD,
  DRAFTS__REMINDERS__REMOVE,
  DRAFTS__REMINDERS__SAVE,
  DRAFTS__REMINDERS__SET,
} from '../../constants/actionTypes';

import { DraftsState } from '../../models/draft';
import {
  DraftActionReminders,
  DraftActionRemindersAdd,
  DraftActionRemindersRemove,
  DraftActionRemindersSave,
  DraftActionRemindersSet,
} from '../../models/draftReminders';

import stateDraftsClone from '../../utils/stateDraftsClone';
import initialState from '../initialState';

type Reducer = (
  state: DraftsState,
  action: DraftActionReminders,
) => DraftsState;

/**
 *
 * CREATE callbacks
 *
 */

/**
 * A request was made to add a new reminder to a draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onAddRemindersRequest = (
  stateCurrent: DraftsState,
  action: DraftActionRemindersAdd,
): DraftsState => {
  const { parentId, reminder } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.remindersCurrent.push(reminder);
  }

  return stateNext;
};

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * A request was made to save reminders,
 * so we store the data from remindersCurrent to remindersSaved
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSaveRemindersRequest = (
  stateCurrent: DraftsState,
  action: DraftActionRemindersSave,
): DraftsState => {
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(action.parentId);

  if (draftNext) {
    draftNext.remindersSaved = draftNext.remindersCurrent;
  }

  return stateNext;
};

/**
 * A request was made to update current reminder
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onSetRemindersRequest = (
  stateCurrent: DraftsState,
  action: DraftActionRemindersSet,
): DraftsState => {
  const { parentId, position, reminder } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.remindersCurrent[position] = reminder;
  }

  return stateNext;
};

/**
 *
 * DELETE callbacks
 *
 */

/**
 * A request was made to remove a reminder item
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onRemoveRemindersRequest = (
  stateCurrent: DraftsState,
  action: DraftActionRemindersRemove,
): DraftsState => {
  const { parentId, position } = action;
  const stateNext = stateDraftsClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext === undefined) {
    return stateNext;
  }

  if (position === undefined) {
    draftNext.remindersCurrent = [];
  } else if (position >= 0) {
    draftNext.remindersCurrent.splice(position, 1);
  }

  return stateNext;
};

/**
 * action:reducer mapping for draft reminders
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__REMINDERS__ADD, onAddRemindersRequest],
  [DRAFTS__REMINDERS__REMOVE, onRemoveRemindersRequest],
  [DRAFTS__REMINDERS__SAVE, onSaveRemindersRequest],
  [DRAFTS__REMINDERS__SET, onSetRemindersRequest],
]);

/**
 * Drafts reminders reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftsReminders = (
  state = initialState.drafts,
  action: DraftActionReminders,
): DraftsState => {
  const reducer = mapping.get(action.type);

  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsReminders;
