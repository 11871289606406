import { styled } from 'styled-components';

/**
 * In order for badge to be square and conform to font-size changes,
 * we are setting the size in rems.
 */
const badgeSize = '1rem';
const fontSize = '0.65rem';

export const Badge = styled.span`
  align-items: center;
  background-color: var(--color-icons-danger);
  border-radius: var(--border-radius-large);
  color: var(--color-text-alt);
  display: flex;
  flex-shrink: 0;
  font-size: ${fontSize};
  font-weight: 600;
  height: ${badgeSize};
  justify-content: center;
  margin-left: var(--spacing-tiny);
  width: ${badgeSize};
`;
