import React, { FC } from 'react';

import translate from '../../../i18n/translate';
import { dateToString } from '../../../utils/date/format';
import {
  getCanArchiveMessage,
  getCanDeleteMessage,
  getCanUpdateMessageAssignment,
  getCanUpdateMessageBasics,
  getCanUpdateMessageMeta,
  getCanUpdateMessageStatus,
} from '../../../utils/permissions/message';
import {
  getCanDeleteAttachment,
  getCanDownloadAttachment,
  getCanUploadAttachmentExistingMessage,
} from '../../../utils/permissions/messageAttachments';
import { NAMES } from '../../Common/LinkIconWithText';

import MessageArchive from '../../Common/MessageArchive';
import MessageDelete from '../MessageDelete';
import MessageSectionAssignees from '../MessageSectionAssignees';
import MessageSectionAttachments from '../MessageSectionAttachments';
import MessageSectionAuthor from '../MessageSectionAuthor';
import MessageSectionDateDue from '../MessageSectionDateDue';
import MessageSectionLabels from '../MessageSectionLabels';
import MessageSectionReadBy from '../MessageSectionReadBy';
import {
  requestCreateHelper,
  requestDeleteHelper,
  requestReorderHelper,
  requestSetStateHelper,
  requestSetTextHelper,
} from '../helpers/checklist';

import useMessageSectionUrl from '../hooks/useMessageSectionUrl';
import { MessageDetailsProps as Props } from '../props';

import * as Styled from './styled';

const ICON_SIZE = 20;

/**
 * A single message's details, "Interaction view"
 *
 * @param props                          Props passed to the component
 * @param props.message                  The message to show
 * @param props.requestChecklistCreate   Request that a checklist item is added
 * @param props.requestChecklistDelete   Request that a checklist item is removed
 * @param props.requestChecklistReorder  Checkbox toggle callback
 * @param props.requestChecklistSetState Callback to be invoked when an item is moved up or down
 * @param props.requestChecklistSetText  Request that a checklist items's text is updated
 * @param props.translation              Translations for the message
 * @returns                              The component itself
 */
const MessageDetails: FC<Props> = ({
  message,
  requestChecklistCreate,
  requestChecklistDelete,
  requestChecklistReorder,
  requestChecklistSetState,
  requestChecklistSetText,
  translation,
}) => {
  const canDelete = getCanDeleteMessage(message);
  const canUpdate = getCanUpdateMessageBasics(translation !== null, message);
  const canUpdateMeta = getCanUpdateMessageMeta(message);
  const canUpdateStatus = getCanUpdateMessageStatus(message);

  const canDeleteFile = getCanDeleteAttachment(message);
  const canDownloadFile = getCanDownloadAttachment(message);
  const canUploadFile = getCanUploadAttachmentExistingMessage(message);

  const editMessageTextLink = useMessageSectionUrl({
    mode: 'title',
  });
  const editLabel = translate('GENERAL__EDIT');

  return (
    <>
      <Styled.MessageSingle
        isExpanded={true}
        message={message}
        mode="details"
        shouldHideBorderBottom={true}
        shouldRenderLinks={true}
        shouldShowComments={false}
        translation={translation}
      />
      {canUpdate && (
        <Styled.LinkIconWithText
          iconHeight={ICON_SIZE}
          iconName={NAMES.COMPOSE__LABEL_EDIT}
          iconWidth={ICON_SIZE}
          label={editLabel}
          text={editLabel}
          url={editMessageTextLink}
        />
      )}
      <MessageSectionAttachments
        canDeleteFile={canDeleteFile}
        canDownloadFile={canDownloadFile}
        canUploadFile={canUploadFile}
        files={message.files}
        messageId={message.id}
      />
      {message.dueDate !== undefined && message.dueDate !== null && (
        <Styled.MessageSectionStatus
          canUpdateStatus={canUpdateStatus}
          messageId={message.id}
          status={message.status}
        />
      )}
      <Styled.MessageSectionChecklist
        items={message.checklist}
        requestCreate={requestCreateHelper(message, requestChecklistCreate)}
        requestDelete={requestDeleteHelper(message, requestChecklistDelete)}
        requestReorder={requestReorderHelper(message, requestChecklistReorder)}
        requestSetState={requestSetStateHelper(
          message,
          requestChecklistSetState,
        )}
        requestSetText={requestSetTextHelper(message, requestChecklistSetText)}
        translation={translation?.checklist}
      />
      <MessageSectionAssignees
        canAssignUsers={getCanUpdateMessageAssignment(message)}
        groups={message.assignedGroups}
        users={message.assignedMembers}
      />
      <MessageSectionDateDue
        canUpdateDate={canUpdateMeta}
        date={dateToString(message.dueDate)}
        status={message.status}
      />
      <MessageSectionLabels
        canUpdateLabels={canUpdateMeta}
        labels={message.labels}
      />
      <MessageSectionReadBy message={message} />
      <MessageSectionAuthor
        author={message.creator}
        createdAtRaw={dateToString(message.createdAt)}
        creatorType={message.creatorType}
      />
      <MessageArchive
        messageId={message.id}
        shouldShowContent={getCanArchiveMessage()}
      />
      <MessageDelete messageId={message.id} shouldShowContent={canDelete} />
    </>
  );
};

export default MessageDetails;
