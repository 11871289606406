/**
 * @file Helper functions for Custom offset
 */

import { DateOffsetInput, TimeUnit } from '../../../generated/graphql';
import { Item } from '../../../hooks/useSelect';

/**
 * Get default item for offset select.
 * If custom offset is set use his unit otherwise use first item in array
 *
 * @param items      Array of items
 * @param offsetUnit Unit of currently selected custom offset
 * @returns          Unit of selected offset or first item
 */
export const getDefaultItem = (
  items: Item<TimeUnit>[],
  offsetUnit?: DateOffsetInput['unit'],
) => {
  return items.find(item => item.value === offsetUnit) ?? items[0];
};
