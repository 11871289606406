import React, { ComponentProps, FC } from 'react';

import { generateRandomInt } from '../../../utils/number';

import SkeletonList from '../SkeletonList';

import * as Styled from './styled';

const sizeMin = 60;
const sizeMax = 180;

type Props = ComponentProps<typeof SkeletonList>;

/**
 * Skeleton list, with lines inline
 *
 * @param props               Props passed to the component
 * @param props.className     styled-components generated class name, needed for styling
 * @param props.numberOfLines The number of lines to render
 * @returns                   The component itself
 */
const SkeletonListColumns: FC<Props> = ({ className, numberOfLines }) => {
  // Generate random widths for skeleton lines, between sizeMin and sizeMax
  const widths = Array.from({ length: numberOfLines }, () => {
    return generateRandomInt(sizeMin, sizeMax);
  });

  return (
    <Styled.List className={className}>
      {widths.map((width, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Styled.Line data-width={width} key={index} />
      ))}
    </Styled.List>
  );
};

export default SkeletonListColumns;
