import React, { ChangeEventHandler, FC, ReactNode } from 'react';

import { NAMES } from '../Icon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  children: ReactNode;
  colorChecked?: string;
  colorDisabled?: string;
  colorUnchecked?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange?: (isChecked: boolean, value: number | string) => void;
  name: string;
  size?: number;
  value: number | string;
};

/**
 * Get which icon should be used for the radio
 *
 * @param isChecked Whether we have a checked radio
 * @returns         The name of the icon to use
 */
const getIconName = (isChecked: boolean): string => {
  if (isChecked === true) {
    return NAMES.GENERAL__RADIO_CHECKED;
  }

  return NAMES.GENERAL__RADIO_UNCHECKED;
};

/**
 * Get the color of the radio icon
 *
 * @param props                The props passed to the component
 * @param props.isChecked      Whether the radio button is checked
 * @param props.isDisabled     Whether the radio button is disabled
 * @param props.colorChecked   Which color to use for checked state
 * @param props.colorDisabled  Which color to use for disabled state
 * @param props.colorUnchecked Which color to use for unchecked state
 * @returns                    The color to use
 */
const getIconColor = ({
  isChecked,
  isDisabled,
  colorChecked,
  colorDisabled,
  colorUnchecked,
}: Props): string | undefined => {
  if (isChecked) {
    return colorChecked;
  }

  if (isDisabled === true && colorDisabled !== undefined) {
    return colorDisabled;
  }

  return colorUnchecked;
};

/**
 * Generic radio component, wraps native input type=radio,
 * replacing its look with svg icons
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.children       Children passed to the component
 * @param props.colorUnchecked The color of the radio (will default to default icon color)
 * @param props.colorChecked   The color of the radio when it is checked (will default to props.colorUnchecked)
 * @param props.colorDisabled  The color of the radio when it is disabled (will default to props.colorUnchecked)
 * @param props.isChecked      Whether to check the radio
 * @param props.isDisabled     Whether the radio is disabled
 * @param props.name           Value for radio's name property
 * @param props.onChange       Callback to be invoked on checked/unchecked
 * @param props.size           Radio size, in px
 * @param props.value          Radio's value
 * @returns                    The component itself
 */
const Radio: FC<Props> = props => {
  const {
    className,
    children,
    isChecked,
    isDisabled = false,
    name,
    onChange,
    size = 19,
    value,
  } = props;

  /**
   * The user has changed the selected radio item
   *
   * @param event The event that took place
   */
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange?.(event.currentTarget.checked, event.currentTarget.value);
  };

  const iconName = getIconName(isChecked);

  const colorIcon = getIconColor(props);

  return (
    <Styled.Label className={className}>
      <Styled.Input
        checked={isChecked}
        disabled={isDisabled}
        name={name}
        onChange={handleChange}
        type="radio"
        value={value}
      />
      <Styled.Icon
        color={colorIcon}
        height={size}
        name={iconName}
        width={size}
      />
      <Styled.LabelText>{children}</Styled.LabelText>
    </Styled.Label>
  );
};
export default Radio;
