import { styled } from 'styled-components';

import { CssVariable } from '../../../styles/cssTypes';

type UlProps = {
  'data-should-wrap': boolean;
};

type LiProps = {
  'data-should-wrap': boolean;
};

/**
 * Get flex-wrap value, based on passed props
 * (sometimes the label list should break into multiple lines, sometimes not)
 *
 * @param props Props passed to the list
 * @returns     A valid flex-wrap property value
 */
const getFlexWrap = (props: UlProps): 'wrap' | 'wrap-reverse' | 'nowrap' => {
  if (props['data-should-wrap']) {
    return 'wrap';
  }

  return 'nowrap';
};

/**
 * Get the amount of bottom margin to have on the item
 *
 * @param props Props passed to the item
 * @returns     The margin to use
 */
const getMarginBottom = (props: LiProps): CssVariable | '0' => {
  if (props['data-should-wrap']) {
    return 'var(--spacing-tiny)';
  }

  return '0';
};

// The list itself
export const Ul = styled.ul<UlProps>`
  align-items: center;
  display: flex;
  flex-wrap: ${getFlexWrap};
  overflow: hidden;
`;

// Wrapper for each label
export const Li = styled.li<LiProps>`
  font-size: var(--font-sm);
  margin-bottom: ${getMarginBottom};
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-right: 8px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-top: 3px;
  max-width: 100%;
`;
