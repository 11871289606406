/**
 * @file Getter functions for users in the urql cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  UserBasicFragment,
  UserBasicFragmentDoc,
} from '../../../../../../generated/graphql';
import { UserID, UserIDs } from '../../../../../../models/user';

/**
 * Get the ID of the current user
 *
 * @param cache urql GraphQL cache
 * @returns     The ID of the current user
 */
export const getCurrentUserId = (cache: Cache): UserID | null => {
  const result = cache.readQuery<MeQuery, MeQueryVariables>({
    query: MeDocument,
    variables: {},
  });

  return result?.me.id ?? null;
};

/**
 * Use GraphQL cache to retrieve user data
 *
 * @param cache urql GraphQL cache
 * @param id    The ID of the user we want
 * @returns     The user, if found, or null if not
 */
export const getUserById = (
  cache: Cache,
  id: UserID,
): UserBasicFragment | null => {
  const user = cache.readFragment<UserBasicFragment, UserBasicFragment>(
    UserBasicFragmentDoc,
    {
      __typename: 'User',
      id,
    },
  );

  return user === null ? null : { ...user, __typename: 'User' };
};

/**
 * User GraphQL cache to retrieve users data
 *
 * @param cache urql GraphQL cache
 * @param ids   The IDs of the users we want
 * @returns     The users
 */
export const getUsersByIds = (
  cache: Cache,
  ids: UserIDs,
): UserBasicFragment[] => {
  const users: UserBasicFragment[] = [];

  ids.forEach(id => {
    const user = getUserById(cache, id);
    if (user !== null) {
      users.push(user);
    }
  });

  return users;
};
