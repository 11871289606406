import React, { FC, FormEventHandler } from 'react';

import ComposeMetaText from '../../../../containers/compose/composeMetaText';
import ComposeMetaTitle from '../../../../containers/compose/composeMetaTitle';
import { ComposeNodeType } from '../../../../containers/compose/props';
import Device from '../../../../models/device';
import ComposeMeta from '../ComposeMeta';

import * as Styled from './styled';

export type Props = {
  breakpointMax: Device | null;
  formId: string;
  nodeType: ComposeNodeType;
  requestCancel?: () => void;
  requestSubmit: () => void;
  shouldDisableSubmit?: boolean;
  shouldShowTitle?: boolean;
};

/**
 * Main fields for compose (title and description)
 *
 * @param props                     Props passed to the component
 * @param props.breakpointMax       Until which point should title be shown
 * @param props.formId              The formId, optional argument
 * @param props.nodeType            Whether the compose form is being used for messages or templates
 * @param props.requestCancel       Request that editing is canceled
 * @param props.requestSubmit       The callback on compose submit
 * @param props.shouldDisableSubmit Whether the submit button should be disabled
 * @param props.shouldShowTitle     Whether to show the title input
 * @returns                         The component itself
 */
const ComposeMain: FC<Props> = ({
  breakpointMax,
  formId,
  nodeType,
  requestCancel,
  requestSubmit,
  shouldShowTitle = false,
  shouldDisableSubmit = false,
}) => {
  /**
   * The user has submitted the form,
   * so we request that the message/template is created/updated
   *
   * @param event The event that took place
   */
  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    if (event.currentTarget.checkValidity()) {
      requestSubmit();
    }
  };

  return (
    <>
      <ComposeMeta formId={formId} nodeType={nodeType} position="ABOVE" />

      <Styled.Form id={formId} onSubmit={onSubmit}>
        {shouldShowTitle && (
          <ComposeMetaTitle formId={formId} nodeType={nodeType} />
        )}
        <ComposeMetaText
          breakpointMin={breakpointMax}
          nodeType={nodeType}
          requestCancel={requestCancel}
          shouldDisableSubmit={shouldDisableSubmit}
        />
      </Styled.Form>
    </>
  );
};

export default ComposeMain;
