/**
 * @file Get device info and firebase token from native app
 */

import { useEffect, useState } from 'react';

import { postWebViewDeviceInfoEvent } from '../../utils/webview/events';

import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import {
  DeviceInfoResponsePayload,
  FirebaseTokenResponsePayload,
  WebViewEvent,
  WebViewEventNames,
} from '../../utils/webview/payloadTypes';

import { reactNativeMessageHandler } from './reactNativeMessageHandler';

/**
 * Get device info and firebase token from native app
 *
 * @returns Object containing device info and firebase token
 */
const useWebViewDeviceInfo = (): {
  deviceInfo?: DeviceInfoResponsePayload;
  firebaseToken: FirebaseTokenResponsePayload['fcmToken'];
} => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfoResponsePayload>();
  const [firebaseToken, setFirebaseToken] =
    useState<FirebaseTokenResponsePayload>();

  const shouldInit = deviceInfo === undefined;
  useEffect(() => {
    if (shouldInit) {
      postWebViewDeviceInfoEvent();
    }
  }, [shouldInit]);

  useEffect(() => {
    if (getIsReactNativeWebView() === false) {
      return;
    }

    const webViewEvents: WebViewEvent[] = [
      {
        callback: (response: DeviceInfoResponsePayload) => {
          setDeviceInfo(response);
        },
        eventName: WebViewEventNames.DeviceInfoResponse,
      },
      {
        callback: (response: FirebaseTokenResponsePayload) => {
          setFirebaseToken(response);
        },
        eventName: WebViewEventNames.FirebaseTokenResponse,
      },
    ];

    /**
     * A message has come in from the native side
     *
     * @param event The event that took place
     */
    const messageEventHandler = (event: MessageEvent<string>) => {
      reactNativeMessageHandler(event, webViewEvents);
    };

    document.addEventListener('message', messageEventHandler, false);
    window.addEventListener('message', messageEventHandler, false);

    return () => {
      document.removeEventListener('message', messageEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
  }, []);

  return { deviceInfo, firebaseToken: firebaseToken?.fcmToken };
};

export default useWebViewDeviceInfo;
