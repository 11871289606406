/**
 * @file Pagination preferences (used on the feed and search)
 */

// The number of messages to fetch per page
export const PREFERENCE__MESSAGES__LIMIT = 10;

// The number of comments to fetch per page
export const PREFERENCE__COMMENTS__LIMIT = 5;

// The number of search results to fetch per page
export const PREFERENCE__SEARCH_RESULTS__LIMIT = 10;

// When to load more search results (2 -> 2nd last result visible)
export const PREFERENCE__SEARCH_RESULTS__TRIGGER = 2;
