import { styled } from 'styled-components';

export const Ul = styled.ul`
  padding-top: var(--spacing-small);
`;

export const Li = styled.li`
  height: var(--spacing-medium);
  margin: 0 var(--spacing-small) var(--spacing-medium);
`;
