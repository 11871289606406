/**
 * @file handle subscription for UserGroups
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  UserGroupInfoSubscription,
  UserGroupInfoSubscriptionVariables,
  UserGroupsDocument,
} from '../../../../../generated/graphql';

import queryCreation from '../utils/updaters/queryCreation';

/**
 * Handle subscription to everything related to user groups
 *
 * @param result Result of a graphql operation
 * @param _args  Arguments of a graphql operation (unused)
 * @param cache  URQL graphql cache
 * @returns      void
 */
const userGroupInfo: UpdateResolver<
  UserGroupInfoSubscription,
  UserGroupInfoSubscriptionVariables
> = (result, _args, cache) => {
  const { action, userGroup } = result.userGroupInfo;

  if (action === ActionType.Created) {
    return queryCreation({
      cache,
      queries: [{ name: 'userGroups', query: UserGroupsDocument }],
      result: userGroup,
    });
  }

  if (action === ActionType.Deleted) {
    return cache.invalidate({
      __typename: 'UserGroup',
      id: userGroup.id,
    });
  }

  // ActionType.Updated is automatically handled
};

export default userGroupInfo;
