import { styled } from 'styled-components';

import CommonButtonIcon from '../../../ButtonIcon';

export const ButtonIcon = styled(CommonButtonIcon)`
  border-radius: var(--border-radius-small);

  /**
   * Safari is adding 2px margin around button,
   * with margin explicitly set to 0 it behaves like it should.
   */
  margin: 0;
  padding: var(--spacing-tiny);
  transition: background-color var(--preference-transition-duration-normal);

  &:active,
  &[aria-pressed='true'] {
    background-color: var(--color-icons-active);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-icons-active);
    }
  }
`;
