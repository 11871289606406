// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/Montserrat-Thin.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Montserrat-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/Montserrat-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/fonts/Montserrat-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../assets/fonts/Montserrat-Semibold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../assets/fonts/Montserrat-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../assets/fonts/Montserrat-Extrabold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../assets/fonts/Montserrat-Black.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff2');
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_fonts.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAoE;AACtE;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAuE;AACzE;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAsE;AACxE;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAwE;AAC1E;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAoE;AACtE;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAyE;AAC3E;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE","sourcesContent":["@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 100;\n  src: url('../../assets/fonts/Montserrat-Thin.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 300;\n  src: url('../../assets/fonts/Montserrat-Light.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 400;\n  src: url('../../assets/fonts/Montserrat-Regular.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 500;\n  src: url('../../assets/fonts/Montserrat-Medium.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 600;\n  src: url('../../assets/fonts/Montserrat-Semibold.woff2') format('woff2');\n}\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 700;\n  src: url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 800;\n  src: url('../../assets/fonts/Montserrat-Extrabold.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  font-weight: 900;\n  src: url('../../assets/fonts/Montserrat-Black.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
