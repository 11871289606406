import React, { FC } from 'react';
import { LinkProps } from 'react-router-dom';

import { Label, LabelBasicFragment } from '../../generated/graphql';

import intlPluralFormatter from '../../utils/intlPluralFormat';

import MetaLabelEdit from './MetaLabelEdit';

type Props = {
  className?: string;
  dataAll: LabelBasicFragment[];
  dataCurrent: Label['id'] | null;
  dataSaved?: Set<Label['id']>;
  isActive: boolean;
  labelsExisting: Label['text'][];
  linkToggleProps?: LinkProps;
  requestModeView: () => void;
  requestUpdate: (
    id: Label['id'],
    title: Label['text'],
    color: Label['color'],
  ) => void;
};

/**
 * A helper component, to avoid data extraction and checks
 * in index.tsx
 *
 * @param props                 Props passed to the component
 * @param props.className       styled-components generated class name, needed for styling
 * @param props.dataAll         All the labels on the board
 * @param props.dataCurrent     Currently selected labels
 * @param props.dataSaved       The labels already saved
 * @param props.isActive        Whether edit mode is active
 * @param props.labelsExisting  Labels already present
 * @param props.linkToggleProps Props for the toggle link
 * @param props.requestModeView Request to switch to label list
 * @param props.requestUpdate   Request to update a label
 * @returns                     The component itself
 */
const ModeEdit: FC<Props> = ({
  className,
  dataAll,
  dataCurrent,
  dataSaved,
  isActive,
  labelsExisting,
  linkToggleProps = null,
  requestModeView,
  requestUpdate,
}) => {
  if (dataCurrent === null) {
    return null;
  }

  const label = dataAll.find(singleLabel => singleLabel.id === dataCurrent);

  // Shouldn't happen because dataAll has all labels,
  // but needs to be added for correctness' sake
  if (dataSaved === undefined || label === undefined) {
    return null;
  }

  const labelsSaved = dataAll.filter(singleLabel =>
    dataSaved.has(singleLabel.id),
  );
  const titlesSaved = labelsSaved.map(singleLabel => singleLabel.text);

  return (
    <MetaLabelEdit
      className={className}
      dataColor={label.color}
      dataText={intlPluralFormatter(titlesSaved)}
      dataTextSelected={label.text}
      isActive={isActive}
      labelsExisting={labelsExisting}
      linkToggleProps={linkToggleProps}
      requestModeView={requestModeView}
      requestSave={(text, color) => {
        requestUpdate(dataCurrent, text, color);
        requestModeView();
      }}
    />
  );
};

export default ModeEdit;
