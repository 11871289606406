import React, { FC, MouseEventHandler } from 'react';

import { useQuery } from 'urql';

import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import { MeDocument } from '../../../generated/graphql';
import ErrorWrapper from '../ErrorWrapper';

import HotelsList from './HotelsList';

export type Props = {
  actionButton: {
    text: string;
    onClick: MouseEventHandler;
  };
  description: string;
  title: string;
};

/**
 * Hotel error wrapper
 *
 * @param props              Props passed to the component
 * @param props.actionButton Action button for the error screen
 * @param props.description  Error description
 * @param props.title        Error title
 * @returns                  The component itself
 */
const HotelErrorWrapper: FC<Props> = props => {
  const [{ data, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });

  const hotels = data?.me.hotelPermissions.map(({ hotel: { id, name } }) => ({
    id,
    name,
  }));

  return (
    <ErrorWrapper {...props} illustrationName={ILLUSTRATION__SEARCH}>
      {hotels !== undefined && hotels.length > 0 && (
        <HotelsList hotels={hotels} isLoading={fetching} />
      )}
    </ErrorWrapper>
  );
};

export default HotelErrorWrapper;
