/**
 * @file Parsing functions for a single concierge field
 */

import { ConciergeFieldType } from '../../constants/conciergeFieldTypes';
import typesActive from '../../constants/conciergeFieldTypesActive';
import {
  ContactFormDataFieldFormatted,
  ContactFormDataFieldRaw,
} from '../../models/message';

import { getLabel } from './label';
import { getValue } from './value';

/**
 * Format a single field within a contact form data
 *
 * @param fieldRaw The field to format
 * @returns        Formatted field data
 */
const formatContactFormFieldSingle = (
  fieldRaw: ContactFormDataFieldRaw,
): ContactFormDataFieldFormatted => ({
  isOptional: fieldRaw.isOptional === '1',
  label: getLabel(fieldRaw),
  type: parseInt(fieldRaw.type, 10) as ConciergeFieldType,
  value: getValue(fieldRaw),
});

/**
 * Format a list of fields within a contact form data
 * (and filter it so that we only keep fields
 * that have a type from conciergeFieldTypesActive)
 *
 * @param fields The fields to format (and filter)
 * @returns      Formatted (and filtered) fields data
 */
export const formatContactFormFields = (
  fields: ContactFormDataFieldRaw[],
): ContactFormDataFieldFormatted[] => {
  return fields
    .map(formatContactFormFieldSingle)
    .filter(field => typesActive.includes(field.type));
};
