/**
 * @file contains helper functions for Attachment hooks
 */
import { Dispatch } from 'redux';

import {
  FILE__EXTENSIONS,
  FILE__MAX_FILE_SIZE,
  FILE__MAX_FILES_UPLOAD,
} from '../../constants/forms';
import translate from '../../i18n/translate';
import { addToastMessage } from '../../store/actions/toastMessage';
import intlPluralFormatter from '../../utils/intlPluralFormat';

export type AttachmentErrorsMapKeys = 'count' | 'format' | 'size';

/**
 * Get file extension from file name
 *
 * @todo Handle file names without extensions
 * @param filename Name of file
 * @returns        File extension or null
 */
export const getFileExtension = (filename: string): string | undefined => {
  const splitFilename = filename.split('.');

  if (splitFilename.length <= 1) {
    return;
  }

  return splitFilename.pop()?.toLowerCase();
};

/**
 * Get name from full file name (with extension)
 *
 * @todo Handle file names without extensions
 * @param filename Name of file
 * @returns        File name
 */
export const getFileName = (filename: string): string => {
  const splitFilename = filename.split('.');

  splitFilename.pop();

  return splitFilename.join('.');
};

/**
 * Append _file to filenames reserved for Windows and replace reserved chars with underscore
 * (This function mutates original File)
 *
 * @param file File that we are renaming
 */
export const formatFilename = (file: File) => {
  // eslint-disable-next-line no-control-regex
  const filenameReservedRegex = /[<>:"/\\|?*+\u0000-\u001F]/g;
  const windowsReservedNameRegex =
    /^((?:COM[0-9]|CON|LPT[0-9]|NUL|PRN|AUX|com[0-9]|con|lpt[0-9]|nul|prn|aux)(\.|$)|\s|[.]{2,})[^\\/:*"?<>|]{1,254}$/i;
  let name = file.name;

  if (windowsReservedNameRegex.test(file.name)) {
    name = getFileName(name) + '_file.' + getFileExtension(name);
  }

  if (filenameReservedRegex) {
    name = name.replace(filenameReservedRegex, '_');
  }

  const splitName = getFileName(name);
  const extension = getFileExtension(name);

  /**
   * Since File is read-only we need to mutate existing object
   */
  Object.defineProperty(file, 'name', {
    value: splitName + '.' + extension,
  });
};

/**
 * Check whether all conditions for file upload are met and if not return file name for each file
 *
 * @param fileList         List of files selected for upload
 * @param draftFilesLength Length of current draft files
 * @returns                Record with file names arrays for each error type
 */
export const getAttachmentErrors = (
  fileList: File[],
  draftFilesLength: number,
): Record<string, string[]> => {
  const errors: Record<AttachmentErrorsMapKeys, string[]> = {
    count: [],
    format: [],
    size: [],
  };

  const extensionsSupported = Object.keys(FILE__EXTENSIONS);

  const filesOverMaxCount = fileList
    .slice(FILE__MAX_FILES_UPLOAD - draftFilesLength)
    .map(file => file.name);

  fileList.forEach(file => {
    const extension = getFileExtension(file.name) ?? '';
    if (extensionsSupported.includes(extension) === false) {
      errors.format.push(file.name);
    }

    if (file.size > FILE__MAX_FILE_SIZE) {
      errors.size.push(file.name);
    }
  });

  if (filesOverMaxCount.length > 0) {
    errors.count.push(...filesOverMaxCount);
  }

  return errors;
};

/**
 * Get Record that contains translations for different attachment error types
 * (count, format and size errors)
 *
 * @returns Record containing single and plural translations for attachment errors
 */
export const getFileToastErrors = (): Record<
  AttachmentErrorsMapKeys,
  { textSingle: string; textPlural: string }
> => ({
  count: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__COUNT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__COUNT__ERROR'),
  },
  format: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__FORMAT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__FORMAT__ERROR'),
  },
  size: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__SIZE_LIMIT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__SIZE_LIMIT__ERROR'),
  },
});

/**
 * If there are any errors dispatch action that creates toast message
 *
 * @param dispatch Redux dispatch callback
 * @param errors   Record of errors for attachment upload
 */
export const showFileToastError = (
  dispatch: Dispatch,
  errors: Record<string, string[]>,
) => {
  const toastErrorContent = getFileToastErrors();

  Object.entries(errors).forEach(
    ([key, errorFileNames]: [AttachmentErrorsMapKeys, string[]]) => {
      if (errorFileNames.length > 0) {
        const additionalTextPrefix =
          errorFileNames.length > 1
            ? translate('ATTACHMENT__TOAST__PLURAL__INFO')
            : translate('ATTACHMENT__TOAST__SINGLE__INFO');
        dispatch(
          addToastMessage({
            additionalText: `${additionalTextPrefix} ${intlPluralFormatter(
              errorFileNames,
            )}`,
            text:
              errorFileNames.length > 1
                ? toastErrorContent[key].textPlural
                : toastErrorContent[key].textSingle,
            title: translate('ATTACHMENT__TOAST__TITLE'),
            type: 'error',
          }),
        );
      }
    },
  );
};
