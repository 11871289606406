import { Draggable } from '@hello-pangea/dnd';

import React from 'react';
import { useLocation } from 'react-router-dom';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../hooks/useIsTablet';
import { MessageFeed } from '../../models/message';
import { getMessageDetailsUrl } from '../../routes/urls/message';

import { getCanUpdateMessageStatus } from '../../utils/permissions/message';

import TaskDueDate from './DueDate';
import TaskFooter from './Footer';
import TaskGroups from './Groups';
import TaskLabels from './Labels';
import TaskMembers from './Members';
import TaskTextContent from './TextContent';

import * as Styled from './styled';

type Props = { index: number; task: MessageFeed };
/**
 * Task card component used in the kanban board
 *
 * @param props       Props passed to the component
 * @param props.index Position of the task within the list
 * @param props.task  The task the card is for
 * @returns           The component itself
 */
const Task = ({ index, task }: Props) => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();
  const { id, topic } = task;

  const messageDetailsUrl = getMessageDetailsUrl(
    hotelId,
    isTablet,
    location,
    id,
    topic.id,
  );

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={getCanUpdateMessageStatus(task) === false}
    >
      {(provided, snapshot) => (
        <Styled.MuiPaper
          data-is-dragging={snapshot.isDragging}
          ref={provided.innerRef}
          // The library will add keyboard usage automatically, we just need the tabIndex.
          tabIndex={0}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // We need to add the role here, because the library attaches role="button",
          // which is not good semantics because we have links within a button
          role="article"
        >
          <Styled.MuiStack>
            <TaskDueDate task={task} url={messageDetailsUrl} />
            <TaskLabels task={task} url={messageDetailsUrl} />
            <TaskTextContent task={task} url={messageDetailsUrl} />
            <TaskMembers task={task} url={messageDetailsUrl} />
            <TaskGroups task={task} url={messageDetailsUrl} />
            <TaskFooter task={task} url={messageDetailsUrl} />
          </Styled.MuiStack>
        </Styled.MuiPaper>
      )}
    </Draggable>
  );
};

export default Task;
