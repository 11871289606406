import { styled } from 'styled-components';

import CommonSkeletonLine from '../../Loading/SkeletonLine';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tiny);
`;

export const SkeletonLine = styled(CommonSkeletonLine)`
  height: var(--spacing-medium);
`;
