import React, { FC } from 'react';

import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useIsMobile from '../../../hooks/useIsMobile';
import HotelAvatarContainer from '../hotelAvatar';

/**
 * Header component for the home page
 *
 * @returns The component itself
 */
const HeaderHome: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile === false) {
    return null;
  }

  return <BaseHeaderMobile title={<HotelAvatarContainer />} />;
};

export default HeaderHome;
