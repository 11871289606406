/**
 * @file Get whether the user created their first message within this session
 */

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AppState from '../../../../../models/state';

import useHasMessages from './useHasMessages';

/**
 * Get whether the user created their first message within this session
 *
 * @returns Whether it's true
 */
const useFirstMessageCreated = (): boolean => {
  const hasMessages = useHasMessages();
  const [hadMessages, setHadMessages] = useState<boolean | null>(hasMessages);

  useEffect(() => {
    if (hadMessages === null) {
      setHadMessages(hasMessages);
    }
  }, [hadMessages, hasMessages]);

  const override = useSelector<AppState, AppState['debug']['hasMessages']>(
    state => state.debug.hasMessages,
  );

  if (override !== null) {
    return override;
  }

  return hadMessages === false && hasMessages === true;
};

export default useFirstMessageCreated;
