import React, { FC, Suspense } from 'react';

import { Navigate } from 'react-router-dom';

import SplashScreen from '../../../components/SplashScreen';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useTopicAccessible from '../../../hooks/topics/useTopicAccessible';
import { getTopicKanbanUrl } from '../../urls/kanban';
import PageMessageDetailsContainer from '../MessageDetails';

import Kanban from './Kanban';
import useKanbanRootParams from './hooks/useKanbanRootParams';

/**
 * Kanban page
 *
 * @returns The component itself
 */
const PageKanban: FC = () => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();

  const { shouldShowMessageDetails } = useKanbanRootParams();
  const [topicAccessibleId] = useTopicAccessible(topicId);

  /**
   * If the user accesses the page with no topic id or if the topic is not
   * available anymore, we redirect the user to the "first" topic
   */
  if (topicId === null || topicId !== topicAccessibleId) {
    return (
      <Navigate to={getTopicKanbanUrl(hotelId, null, topicAccessibleId)} />
    );
  }

  return (
    <Suspense fallback={<SplashScreen />}>
      {shouldShowMessageDetails ? <PageMessageDetailsContainer /> : <Kanban />}
    </Suspense>
  );
};

export default PageKanban;
