/**
 * @file Handle subscription to everything related to labels
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  LabelInfoSubscription,
  LabelInfoSubscriptionVariables,
  LabelsDocument,
} from '../../../../../generated/graphql';

import queryCreation from '../utils/updaters/queryCreation';

/**
 * Handle subscription to everything related to labels
 *
 * @param result Result of a graphql operation
 * @param _args  Arguments of a graphql operation (unused)
 * @param cache  URQL graphql cache
 */
const labelInfo: UpdateResolver<
  LabelInfoSubscription,
  LabelInfoSubscriptionVariables
> = (result, _args, cache) => {
  const {
    labelInfo: { action, label },
  } = result;

  if (action === ActionType.Created) {
    queryCreation({
      cache,
      queries: [{ name: 'labels', query: LabelsDocument }],
      result: label,
    });
  }

  if (action === ActionType.Deleted) {
    cache.invalidate({
      __typename: 'Label',
      id: label.id,
    });
  }
};

export default labelInfo;
