/**
 * @file Helper functions for generating links for mobile navigation
 */

import translate from '../../../i18n/translate';
import { NavigationLinkProps } from '../../MUI/MobileFullViewNavigation/NavigationLink';

type Params = {
  devPanelUrl: string | null;
  selectHotelUrl: string;
  settingsUrl: string;
};

/**
 * Generates a list of navigation lists with data
 *
 * @param urls                The urls to generate the links with
 * @param urls.devPanelUrl    The link to the dev panel (won't render it if null)
 * @param urls.selectHotelUrl Internal url for the hotel select
 * @param urls.settingsUrl    Internal url for the settings
 * @returns                   The links to show
 */
export const generateLinks = ({
  devPanelUrl,
  selectHotelUrl,
  settingsUrl,
}: Params): NavigationLinkProps[] => {
  const links: NavigationLinkProps[] = [
    {
      icon: 'settings',
      isDisabled: false,
      isExternal: false,
      label: translate('GENERAL__SETTINGS'),
      url: settingsUrl,
    },
    {
      icon: 'changeObject',
      isDisabled: selectHotelUrl === '',
      isExternal: false,
      label: translate('GENERAL__CHANGE_OBJECT'),
      url: selectHotelUrl,
    },
  ];

  if (devPanelUrl !== null) {
    links.push({
      icon: 'devPanel',
      isDisabled: false,
      isExternal: false,
      label: translate('DEBUG__DEV_PANEL__LINK'),
      url: devPanelUrl,
    });
  }

  return links;
};
