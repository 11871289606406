import { styled } from 'styled-components';

type AvatarProps = {
  'data-color'?: string;
  'data-size': number;
};

/**
 * Get which color to use for the avatar background
 *
 * @param props Props passed to Wrapper
 * @returns     The color to use
 */
const getBackgroundColor = (props: AvatarProps): string => {
  return props['data-color'] ?? '--color-background-skeleton';
};

// The avatar itself
export const Avatar = styled.div<AvatarProps>`
  background-color: var(${getBackgroundColor});
  border-radius: var(--border-radius-small);
  height: ${props => props['data-size']}px;
  width: ${props => props['data-size']}px;
`;
