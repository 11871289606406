/**
 * @file Calculate and export the device's class
 */

import deviceInfo from './deviceInfo';

type DeviceClass = 'desktop' | 'tablet' | 'phone';

/**
 * Calculate and export the device's class
 * We can't precisely know, but it is helpful info for device list
 * for push notification settings
 */
const deviceClass: DeviceClass = (() => {
  const { Linux, macOS, iPad, Windows } = deviceInfo;

  if (Linux || macOS || Windows) {
    return 'desktop';
  }

  if (iPad) {
    return 'tablet';
  }

  return 'phone';
})();

export default deviceClass;
