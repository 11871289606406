import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  handleClick: () => void;
  isDisabled?: HTMLButtonElement['disabled'];
  label: string;
};

/**
 * Button to cancel the action
 *
 * @param props             Props passed to the component
 * @param props.handleClick Handle button click
 * @param props.isDisabled  Whether to make the button disabled
 * @param props.label       Label passed to the ButtonControl (also used as button text)
 * @returns                 The component itself
 */
const ButtonCancel: FC<Props> = ({
  handleClick,
  isDisabled = false,
  label,
}) => {
  return (
    <Styled.Button isDisabled={isDisabled} onClick={handleClick} type="button">
      {label}
    </Styled.Button>
  );
};

export default ButtonCancel;
