import React, { ComponentProps, FC, useId } from 'react';

import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';
import Device from '../../models/device';
import { getLabelConfirm } from '../../utils/forms/compose';

import ComposeFooter from './Common/ComposeFooter';
import ComposeMain from './Common/ComposeMain';
import ComposeMeta from './Common/ComposeMeta';
import ComposeToggle from './Common/ComposeToggle';
import { getShouldShowToggle } from './Common/helpers';
import { getComposeTitle } from './helpers';

import Props from './props';
import * as Styled from './styled';

// The breakpoint at which we switch between mobile and desktop views
const breakpoint: Device = 'tabletS';

/**
 * Main compose block,
 * this component wraps all the functionality there
 *
 * @param props                     The props passed to the component
 * @param props.nodeId              The ID of the node (template) being edited (null for creation)
 * @param props.nodeType            Whether the compose form is being used for messages or templates
 * @param props.requestCancel       Request that editing is canceled
 * @param props.requestStateSet     Request that the compose form is opened/closed
 * @param props.requestSubmit       Request that the message/template is created/updated
 * @param props.shouldDisableSubmit Whether to disable the submit button
 * @param props.shouldShow          Whether to even show the compose form
 * @returns                         The component itself
 */
const Compose: FC<Props> = ({
  nodeId,
  nodeType,
  requestCancel,
  requestStateSet,
  requestSubmit,
  shouldDisableSubmit,
  shouldShow,
}) => {
  const composeTitle = getComposeTitle(nodeId, nodeType);
  /**
   * Form ID is needed here because we have 2 buttons that submit this form.
   * One is external in the compose header so we need to link to the form with it.
   */
  const formId = useId();
  const isEditMode = nodeId !== null;
  const isMobile = useIsMobile();

  // We render submit button at different HTML points depending on screen size,
  // This covers props used in both places
  const mainProps: ComponentProps<typeof ComposeMain> = {
    breakpointMax: null,
    formId,
    nodeType,
    requestCancel,
    requestSubmit,
    shouldDisableSubmit,
  };

  const toggle = (
    <ComposeToggle
      onChange={requestStateSet}
      state={shouldShow}
      text={composeTitle}
    />
  );

  if (getShouldShowToggle(isMobile, shouldShow)) {
    return <Styled.InnerWrapper>{toggle}</Styled.InnerWrapper>;
  }

  return (
    <Styled.Wrapper aria-label={translate('ARIA__LABEL__COMPOSE')}>
      <Styled.InnerWrapper>
        {isMobile === false && toggle}
        <Styled.Content data-should-show={shouldShow}>
          {isMobile && (
            <ComposeMain
              {...mainProps}
              breakpointMax={breakpoint}
              shouldShowTitle={true}
            />
          )}

          <ComposeMeta formId={formId} nodeType={nodeType} position="BELOW" />

          {isMobile === false && <ComposeMain {...mainProps} />}
        </Styled.Content>

        {isMobile && (
          <ComposeFooter
            formId={formId}
            isSaveDisabled={shouldDisableSubmit}
            labelConfirm={getLabelConfirm(nodeType, isEditMode)}
            requestCancel={requestCancel}
          />
        )}
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

export default Compose;
