/**
 * @file Get parsed array of rrule bymonthday
 */

/**
 * Get parsed array of rrule bymonthday
 *
 * @param bymonthday rrule bymonthday option
 * @returns          Number array of rrule bymonthday
 */
const getParsedMonthdays = (
  bymonthday: number | number[] | null | undefined,
): Set<number> => {
  if (
    bymonthday === null ||
    bymonthday === undefined ||
    (Array.isArray(bymonthday) && bymonthday.length === 0)
  ) {
    return new Set();
  }

  if (Array.isArray(bymonthday)) {
    return new Set(bymonthday);
  }

  return new Set([bymonthday]);
};

export default getParsedMonthdays;
