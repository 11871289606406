import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import SplashScreen from '../../../components/SplashScreen';
import { TopicsDocument } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useToast from '../../../hooks/store/useToast';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { reportError } from '../../../services/reporting';
import getTopicAccessible from '../../../utils/topics/getTopicAccessible';
import { getTopicFromHomeRedirectUrl } from '../../urls/topic';
import ErrorTopicsNotFetched from '../Home/ErrorTopicsNotFetched';

import Feed from './Feed';
import useFeedRootParams from './Feed/hooks/useFeedRootParams';

const MessageDetails = lazy(() => import('../MessageDetails'));

/**
 * Feed page
 *
 * @returns The component itself
 */
const PageFeed: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: TopicsDocument,
    variables: {},
  });
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const pushToast = useToast();
  const topicIdUrl = useParamTopicId();
  const { shouldShowMessageDetails } = useFeedRootParams();

  const topicIdAccessible = getTopicAccessible(data, topicIdUrl);

  useEffect(() => {
    // The ID of the topic that is in the URL is not present in topics query,
    // So we inform the user
    if (topicIdAccessible !== topicIdUrl) {
      pushToast({
        text: translate('ERROR__MISSING_TOPIC__DESCRIPTION'),
        title: translate('GENERAL__OOPS'),
        type: 'error',
      });
    }
  }, [pushToast, topicIdAccessible, topicIdUrl]);

  /**
   * If fetching is true we return null because we have double rendering of skeleton for entire feed page,
   * which causes unwanted effects.
   * That happens because one skeleton comes from our <Feed/> component and the other one we get
   * from all components that Feed consists of (left sidebar, message feed, right sidebar etc.).
   * With this solution we eliminate skeleton from <Feed/> and leave the other ones that
   * when combined look exactly like when they are all combined and rendered in one big skeleton
   * that comes from <Feed/>.
   */
  if (fetching) {
    return null;
  }

  if (error) {
    reportError(error);

    return <ErrorTopicsNotFetched />;
  }

  // The ID of the topic that is in the URL is not present in topics query,
  // So we redirect the user
  if (topicIdAccessible !== topicIdUrl) {
    reportError(`Topic ${topicIdUrl} not found`);
    return (
      <Navigate
        to={getTopicFromHomeRedirectUrl(
          hotelId,
          topicIdAccessible,
          // On Mobile, we want to redirect to the topic, not compose form view
          isMobile === false,
        )}
      />
    );
  }

  return (
    <Suspense fallback={<SplashScreen />}>
      {shouldShowMessageDetails ? (
        <MessageDetails />
      ) : (
        <Feed areTopicsEmpty={data?.topics.length === 0} />
      )}
    </Suspense>
  );
};

export default PageFeed;
