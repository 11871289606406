import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'urql';

import ComposeMetaTopics from '../../../components/Compose/Common/ComposeMeta/ComposeMetaTopics';
import MetaTopic from '../../../components/MetaTopic';
import { TopicBasicFragment, TopicsDocument } from '../../../generated/graphql';
import { ComposeModeParam } from '../../../models/pageParams';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Container for topics selection for templates
 *
 * @param props                            Props passed to the container
 * @param props.dataTopicCurrent           Currently selected topic
 * @param props.dataTopicSaved             Saved topic
 * @param props.dataTopicSearchValue       Value of search input
 * @param props.debugLoading               Whether to permanently enable loading state
 * @param props.isEditMode                 Whether Editor is in template edit mode
 * @param props.requestTemplateTopicSearch Request update to topic search input
 * @param props.requestTopicChange         Request that the topic is updated
 * @param props.requestTopicSave           Request current -> saved transfer
 * @returns                                The container itself
 */
const ComposeMetaTopicContainer: FC<Props> = ({
  dataTopicCurrent,
  dataTopicSaved,
  dataTopicSearchValue,
  debugLoading,
  isEditMode,
  requestTemplateTopicSearch,
  requestTopicChange,
  requestTopicSave,
}) => {
  const [{ data, error, fetching }] = useQuery({
    query: TopicsDocument,
    variables: {},
  });

  const dataTopicAll = (data?.topics ?? []) as TopicBasicFragment[];

  const mode: ComposeModeParam = 'topic';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  const isLoading = fetching || debugLoading;

  return (
    <MetaTopic
      dataSearchValue={dataTopicSearchValue}
      dataTopicAll={dataTopicAll}
      dataTopicCurrent={dataTopicCurrent}
      dataTopicSaved={dataTopicSaved}
      error={error}
      inputCustom={
        <ComposeMetaTopics
          isEditMode={isEditMode}
          topic={dataTopicAll.find(topic => topic.id === dataTopicCurrent)}
        />
      }
      isActive={isActive}
      isEditMode={isEditMode}
      isLoading={isLoading}
      linkToggleProps={linkToggleProps}
      onSearch={requestTemplateTopicSearch}
      requestTopicChange={requestTopicChange}
      requestTopicSave={() => {
        if (dataTopicCurrent) {
          requestTopicSave(dataTopicCurrent);
          goBack();
        }
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaTopicContainer);
