/**
 * @file Getter functions for users in the urql cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  UserGroup,
  UserGroupsWithMembersDocument,
  UserGroupsWithMembersQuery,
  UserGroupsWithMembersQueryVariables,
} from '../../../../../../generated/graphql';

type GroupWithMembers = UserGroupsWithMembersQuery['userGroups'][0];

/**
 * Get a user group with members
 *
 * @param cache urql GraphQL cache
 * @param id    The ID of the group we want
 * @returns     The group, if found, or null if not
 */
export const getGroupWithMembers = (
  cache: Cache,
  id: UserGroup['id'],
): GroupWithMembers | null => {
  const data = cache.readQuery<
    UserGroupsWithMembersQuery,
    UserGroupsWithMembersQueryVariables
  >({
    query: UserGroupsWithMembersDocument,
    variables: {},
  });

  if (data === null) {
    return null;
  }

  return data.userGroups.find(group => group.id === id) ?? null;
};

/**
 * Get multiple user groups with members
 *
 * @param cache urql GraphQL cache
 * @param ids   The IDs of the groups we want
 * @returns     The group, if found, or null if not
 */
export const getGroupsWithMembers = (
  cache: Cache,
  ids: UserGroup['id'][] | null = [],
): GroupWithMembers[] => {
  if (ids === null) {
    return [];
  }

  const groups: GroupWithMembers[] = [];
  ids.forEach(id => {
    const group = getGroupWithMembers(cache, id);
    if (group !== null) {
      groups.push(group);
    }
  });

  return groups;
};

/**
 * Get multiple user groups (sorted alphabetically) with members
 *
 * @param cache urql GraphQL cache
 * @param ids   The IDs of the groups we want
 * @returns     The group, if found, or null if not
 */
export const getGroupsSortedWithMembers: typeof getGroupsWithMembers = (
  cache,
  ids,
) => {
  return getGroupsWithMembers(cache, ids).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
};
