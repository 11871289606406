import React, { FC } from 'react';
import { connect } from 'react-redux';

import ComposeMetaChecklist from '../../../components/Compose/Common/ComposeMeta/ComposeMetaChecklist';
import MetaChecklist from '../../../components/MetaChecklist';
import { ComposeModeParam } from '../../../models/pageParams';

import useComposeLinking from '../hooks/useComposeLinking';

import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Managing checklist in the compose form
 *
 * @param props                           Props passed to the container
 * @param props.dataChecklistItemsCurrent Currently checked items
 * @param props.dataChecklistItemsSaved   Saved items
 * @param props.requestChecklistCreate    Add a new checklist item
 * @param props.requestChecklistDelete    Remove a checklist item
 * @param props.requestChecklistReorder   Reorder an item
 * @param props.requestChecklistSave      Request current -> saved data move
 * @param props.requestChecklistSetState  (un)check an item
 * @param props.requestChecklistSetText   Update an item's text
 * @returns                               The container itself
 */
const ComposeMetaChecklistContainer: FC<Props> = ({
  dataChecklistItemsCurrent,
  dataChecklistItemsSaved,
  requestChecklistCreate,
  requestChecklistDelete,
  requestChecklistReorder,
  requestChecklistSave,
  requestChecklistSetState,
  requestChecklistSetText,
}) => {
  const mode: ComposeModeParam = 'checklist';
  const { goBack, isActive, linkToggleProps } = useComposeLinking(mode);

  return (
    <MetaChecklist
      inputCustom={<ComposeMetaChecklist items={dataChecklistItemsSaved} />}
      isActive={isActive}
      itemsCurrent={dataChecklistItemsCurrent}
      itemsSaved={dataChecklistItemsSaved}
      linkToggleProps={linkToggleProps}
      mode="DRAFT"
      requestCreate={requestChecklistCreate}
      requestDelete={requestChecklistDelete}
      requestReorder={requestChecklistReorder}
      requestSave={() => {
        requestChecklistSave();
        goBack();
      }}
      requestSetState={requestChecklistSetState}
      requestSetText={requestChecklistSetText}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaChecklistContainer);
