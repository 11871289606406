/**
 * @file Mappings between mui symbol icons and svg file names within the assets/icons
 * folder (prefixed with mui_symbol__).
 */

import { MuiSymbolIconName } from '../../types';

export const muiIconNameMapping: Record<MuiSymbolIconName, string> = {
  home: 'mui_symbol__home',
  settings: 'mui_symbol__settings',
};
