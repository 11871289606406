/**
 * @file Redux mappers for composeMetaLabels container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  addDraftLabelRequest,
  removeDraftLabelRequest,
  saveDraftLabelRequest,
} from '../../../store/actions/draftsLabels';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux app state to component props
 *
 * @param appState          Current app state
 * @param appState.debug    Debug state of the app
 * @param appState.drafts   Drafts in state
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ debug, drafts }, { nodeType }) => ({
  dataSaved: drafts.get(nodeType)?.labelsSaved ?? new Set(),
  dataSelected: drafts.get(nodeType)?.labelsCurrent ?? new Set(),
  debugLoading: debug.networkCompose,
});

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  requestLabelAdd: labelId => dispatch(addDraftLabelRequest(nodeType, labelId)),
  requestLabelRemove: labelId => {
    dispatch(removeDraftLabelRequest(nodeType, labelId));
  },
  requestLabelsSave: () => dispatch(saveDraftLabelRequest(nodeType)),
});
