import React, { FC } from 'react';

import SearchForm from '../../../../components/Forms/SearchForm';

import * as Styled from './styled';

/**
 * Header component for search layout on desktop devices
 *
 * @returns The component itself
 */
const SearchHeaderDesktop: FC = () => {
  return (
    <Styled.Header>
      <SearchForm />
    </Styled.Header>
  );
};

export default SearchHeaderDesktop;
