import { styled } from 'styled-components';

import CommonSkeletonLine from '../../../../components/Loading/SkeletonLine';
import CommonSkeletonColumnList from '../../../../components/Loading/SkeletonList';

export const SkeletonColumnList = styled(CommonSkeletonColumnList)`
  margin-top: var(--spacing-large);
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tiny);
  margin-top: var(--spacing-large);
`;

export const Title = styled.h3`
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-tiny);
`;

export const SkeletonLine = styled(CommonSkeletonLine)`
  height: var(--spacing-medium);
`;

export const Link = styled.a`
  color: var(--color-link);

  &:hover {
    text-decoration: underline;
  }
`;
