import { Button, ButtonProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import devices from '../../../styles/devices';

export const Footer = styled('footer')({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyItems: 'start',
  maxWidth: 'var(--task-card-footer-max-width)',
  // Between those breakpoints we need two rows for the links/button, because the
  // task cards get narrow
  [`@media ${devices.tabletS}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media ${devices.laptopS}`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyItems: 'space-between',
  },
});

export const MuiButton = styled(Button)<ButtonProps & LinkProps>(
  ({ theme }) => ({
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
  }),
);
