// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_bell-icon.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_sidebar-conversation-list.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_sidebar-conversation-new.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_sidebar-header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_sidebar.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_skeletons.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-content.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-delete-modal.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-empty-state.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-file-preview.css";
import ___CSS_LOADER_AT_RULE_IMPORT_11___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-file-upload-progress.css";
import ___CSS_LOADER_AT_RULE_IMPORT_12___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_13___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_14___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./_window-message-single.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_14___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
* Typeahead menu is causing issues on iOS so we need to hide it.
* Since styles are added via JS we need to use !important to override them.
* @see https://github.com/facebook/lexical/issues/4366
* @todo If this bug is fixed this can be removed.
*/
#typeahead-menu {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/index.css"],"names":[],"mappings":"AAgBA;;;;;CAKC;AACD;EACE,wBAAwB;AAC1B","sourcesContent":["@import '_bell-icon.css';\n@import '_sidebar-conversation-list.css';\n@import '_sidebar-conversation-new.css';\n@import '_sidebar-header.css';\n@import '_sidebar.css';\n@import '_skeletons.css';\n@import '_variables.css';\n@import '_window-content.css';\n@import '_window-delete-modal.css';\n@import '_window-empty-state.css';\n@import '_window-file-preview.css';\n@import '_window-file-upload-progress.css';\n@import '_window-footer.css';\n@import '_window-header.css';\n@import '_window-message-single.css';\n\n/* \n* Typeahead menu is causing issues on iOS so we need to hide it.\n* Since styles are added via JS we need to use !important to override them.\n* @see https://github.com/facebook/lexical/issues/4366\n* @todo If this bug is fixed this can be removed.\n*/\n#typeahead-menu {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
