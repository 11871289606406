/**
 * @file contains all main app routes constants
 */

/**
 * Page not found route
 */
export const ROUTE__404 = '404';

/**
 * Hotelboard chat
 */
export const ROUTE__CHAT = 'chat';

/**
 * Dynamic path prefix that will go in front of all paths
 */
export const ROUTE__DYNAMIC_PREFIX = ':hotelId';

/**
 * Hotelboard home
 */
export const ROUTE__HOME = 'home';

/**
 * Entry point for the application
 */
export const ROUTE__INDEX = '/';

/**
 * Route that dev panel is on
 */
export const ROUTE__DEV_PANEL = 'dev-panel';

/**
 * Hotelboard feed
 */
export const ROUTE__FEED = 'feed';

/**
 * Hotelboard kanban
 */
export const ROUTE__KANBAN = 'kanban';

/**
 * Hotelboard reservations
 */
export const ROUTE__RESERVATIONS = 'reservations';

/**
 * Hotelboard search
 */
export const ROUTE__SEARCH = 'search';

/**
 * Hotelboard smart views
 */
export const ROUTE__SMART_VIEWS = 'smart-views';

/**
 * More screen;
 */
export const ROUTE__MORE = 'more';

/**
 * Select hotel mobile More screen
 */
export const ROUTE__MORE__SELECT_HOTEL = 'change-object';

/**
 * Message replies
 */
export const ROUTE__REPLIES = 'replies';

/**
 * Settings screen;
 */
export const ROUTE__SETTINGS = 'settings';

/**
 * Settings template recurring screen;
 */
export const ROUTE__SETTINGS__RECURRING = 'recurring';

/**
 * Settings notifications screens
 */
export const ROUTE__SETTINGS__NOTIFICATIONS = `${ROUTE__SETTINGS}/notifications`;
export const ROUTE__SETTINGS__EMAIL = `${ROUTE__SETTINGS__NOTIFICATIONS}/email`;
export const ROUTE__SETTINGS__PUSH = `${ROUTE__SETTINGS__NOTIFICATIONS}/push`;

/**
 * Error routes
 */
export const ROUTE__ERROR__MISSING_HOTEL = '/error/missing-hotel';
export const ROUTE__ERROR__AUTH_HOTEL_NOT_FOUND = '/error/hotel-not-found';
export const ROUTE__ERROR__AUTH_USER_NOT_FOUND = '/error/user-not-found';
export const ROUTE__ERROR__MISSING_TERMS_AND_CONDITIONS =
  '/error/missing-terms';
export const ROUTE__ERROR__DEACTIVATED_HOTEL = '/error/deactivated-hotel';
