/**
 * @file Hook for marking a post as read
 */

import { useEffect } from 'react';

import useParamTopicId from '../../router/params/useParamTopicId';
import useIsAppInView from '../../useIsAppInView';

import useMutationMessagesRead from '../useMutationMessagesRead';

import { getShouldAdd, getShouldRemove } from './helpers';
import { UseMessageReadParams } from './types';
import useMessagesReadQueueReducer from './useMessagesReadQueueReducer';

/**
 * Hook for marking a post as read
 * Gives back a callback that is invoked for each message separately
 * Based on its params, it'll be added to the queue or removed from it,
 * And after the specified amount of time, if the messageID is still in view,
 * It'll call the appropriate mutation, to mark it as read
 *
 * @returns Function that accepts message info and adds or removes from mark-as-read queue
 */
const useMessagesRead = () => {
  const messagesReadMutation = useMutationMessagesRead();
  const {
    readQueueAdd,
    readQueueClear,
    readQueueRemove,
    readQueueReset,
    state,
  } = useMessagesReadQueueReducer();
  const isAppInView = useIsAppInView();
  const topicId = useParamTopicId();

  useEffect(() => {
    if (isAppInView) {
      readQueueClear();
    }
  }, [isAppInView, readQueueClear, topicId]);

  useEffect(() => {
    if (state.size === 0) {
      return;
    }

    readQueueReset();
    messagesReadMutation(Array.from(state));

    /**
     * Only react to changes in the debounced state array
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.size]);

  return (args: UseMessageReadParams) => {
    const { messageId } = args;

    if (getShouldAdd(args)) {
      readQueueAdd(messageId);
    } else if (getShouldRemove({ ...args, isAppInView })) {
      readQueueRemove(messageId);
    }
  };
};

export default useMessagesRead;
