/**
 * @file Update message status in cache
 */

import { Cache, Data } from '@urql/exchange-graphcache';

import {
  MessageFeedFragment,
  MessageFeedFragmentDoc,
  MessageStatusUpdateMutationVariables,
} from '../../../../../../../generated/graphql';

import { addOrUpdateTask } from './tasks';

/**
 * Update message status in cache
 *
 * @param args  The arguments passed along with the mutation
 * @param cache Current GraphQL cache
 */
export const messageStatusUpdate = (
  args: MessageStatusUpdateMutationVariables,
  cache: Cache,
): void => {
  const task = cache.readFragment<MessageFeedFragment, Data>(
    MessageFeedFragmentDoc,
    {
      __typename: 'Message',
      id: args.id,
    },
  );

  if (task === null) {
    return;
  }

  addOrUpdateTask({ ...task, status: args.status }, cache);
};
