import React, { FC } from 'react';

import { ChatBubble } from '../../../../integrations/roqChat/ChatBubble';
import MuiIcon from '../../MuiIcon';
import NotificationsBadge from '../../NotificationsBadge';

import { NavItem } from '../types';

export type Props = {
  item: NavItem;
};

/**
 * Icon for list item used in the rail for desktop navigation
 *
 * @param props      Props passed to the component
 * @param props.item Item object for rail navigation
 * @returns          The component itself
 */
const RailIcon: FC<Props> = ({ item }) => {
  const { icon, id, notificationsCount } = item;

  if (id === 'chat') {
    // For chat, ROQ chat bell component will be shown
    return (
      <ChatBubble>
        <MuiIcon fontSize="small" icon={icon} />
      </ChatBubble>
    );
  }

  return (
    <NotificationsBadge badgeContent={notificationsCount}>
      <MuiIcon fontSize="small" icon={icon} />
    </NotificationsBadge>
  );
};

export default RailIcon;
