/**
 * @file Hook for native compatibility check
 */
import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import {
  checkVersion,
  comparisonFunctions,
} from '../../utils/webview/nativeCompatibility';

import useWebViewDeviceInfo from './useWebViewDeviceInfo';

/**
 * Check if the condition for the passed operator and target version is true
 *
 * @param targetVersion Target version (semver)
 * @param operator      Operator to use
 * @returns             Whether the native app version is compatible
 */
const useNativeCompatibility = (
  targetVersion: string,
  operator: keyof typeof comparisonFunctions = 'gt',
) => {
  const { deviceInfo } = useWebViewDeviceInfo();
  const isWebView = getIsReactNativeWebView();

  if (isWebView === false) {
    return true;
  }

  if (deviceInfo?.appVersion) {
    return checkVersion(deviceInfo?.appVersion, targetVersion, operator);
  }

  return false;
};

export default useNativeCompatibility;
