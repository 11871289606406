/**
 * @file Contains helpers for arrays
 */

import { CombinedError } from 'urql';

import { Scalars } from '../generated/graphql';
import { reportError } from '../services/reporting';

/**
 * Get data for a single data item from an array
 *
 * @param id       The ID of the data item we want
 * @param data     The array of data
 * @param fetching Whether data is fetching
 * @param error    Query Error if any
 * @returns        The desired data item if found
 */
export const getDataById = <T extends { id: Scalars['ID']['output'] | null }>(
  id: Scalars['ID']['output'] | null,
  data: T[] | undefined,
  fetching: boolean | undefined = undefined,
  error: CombinedError | undefined = undefined,
): T | null => {
  if (id === null || fetching === true) {
    return null;
  }

  if (error !== undefined) {
    reportError(error);
    return null;
  }

  const item = data?.find(el => el.id === id);

  if (item === undefined) {
    reportError(`getDataById: Data ${id} not found.`);
    return null;
  }

  return item;
};
