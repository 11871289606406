import React, { FC } from 'react';

import MetaText from '../../../components/MetaText';

import { Message } from '../../../generated/graphql';

type Props = {
  description?: Message['text'];
  requestModeOverview: () => void;
  requestSave: (description: Message['text'], title: Message['title']) => void;
  title?: Message['title'];
};

/**
 * 2nd part of the messageFull title container
 * Split like this because of urql + useState
 * we have branching in urql for fetching, error and done states
 * and useState doesn't allow for that
 *
 * @param props                     Props passed to the component
 * @param props.description         Current message description
 * @param props.requestModeOverview Request that messageFull switches to overview mode
 * @param props.requestSave         Request to save current title
 * @param props.title               Current message title
 * @returns                         The component itself
 */
const Inner: FC<Props> = ({
  description = '',
  requestModeOverview,
  requestSave,
  title = '',
}) => {
  /**
   * Request that the message description and title is updated
   *
   * @param messageDescription The description to use
   * @param messageTitle       The title to use
   */
  const requestTitleSave = (
    messageDescription: Message['text'],
    messageTitle: Message['title'],
  ) => {
    requestSave(messageDescription, messageTitle);
    requestModeOverview();
  };

  return (
    <MetaText
      description={description}
      onClose={requestModeOverview}
      requestSave={requestTitleSave}
      title={title}
    />
  );
};

export default Inner;
