import React, { FC } from 'react';

import { UserGroup, UserGroupBasicFragment } from '../../../generated/graphql';

import translate from '../../../i18n/translate';
import AssigneesGroupsLoading from '../../Loading/AssignmentGroups';

import AssigneesGroupSingle from '../AssigneesGroupSingle';
import AssigneesGroupsNoResult from '../AssigneesGroupsNoResult';

import * as Styled from './styled';

export type Props = {
  dataAssigned: Set<UserGroup['id']>;
  dataVisible: UserGroupBasicFragment[];
  isLoading: boolean;
  isSearching: boolean;
  onChange: (groupId: UserGroup['id'], state: boolean) => void;
};

/**
 * List all groups in the board and mark those already assigned to as such
 *
 * @param props              Props passed to the component
 * @param props.dataAssigned The groups the message is currently assigned to
 * @param props.dataVisible  Visible the groups in the board
 * @param props.isLoading    Whether the data is being fetched
 * @param props.isSearching  Whether something was typed into search
 * @param props.onChange     When the user toggles a group off/on
 * @returns                  The component itself
 */
const AssigneesGroups: FC<Props> = ({
  dataAssigned,
  dataVisible,
  isLoading,
  isSearching,
  onChange,
}) => {
  const label = translate('ASSIGNMENT__GROUPS__HEADING');

  if (isLoading === true) {
    return (
      <>
        <Styled.Heading>{label}</Styled.Heading>
        <AssigneesGroupsLoading />
      </>
    );
  }

  if (dataVisible.length === 0) {
    return isSearching === true ? (
      <AssigneesGroupsNoResult reason="searching" />
    ) : null;
  }

  return (
    <>
      <Styled.Heading>{label}</Styled.Heading>
      <Styled.Ul>
        {dataVisible.map(group => (
          <Styled.Li key={`group-${group.id}`}>
            <AssigneesGroupSingle
              group={group}
              isAssigned={dataAssigned.has(group.id)}
              onChange={state => onChange(group.id, state)}
            />
          </Styled.Li>
        ))}
      </Styled.Ul>
    </>
  );
};

export default AssigneesGroups;
