// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-panel-header {
  font-size: var(--font-baseline);
  font-weight: var(--font-semibold);
  padding-top: var(--spacing-medium);
}

.roq-chat-conversation-list-sidebar__header__top__action,
.roq-chat-create-conversation-sidebar__header__top__action {
  background-color: var(--color-hover-dark);
  border-radius: var(--border-radius-circle);
  box-shadow: var(--box-shadow-1);
  color: var(--color-icons-alt);
  display: flex;
  font-weight: var(--font-weight-label-bold);
  padding: var(--spacing-small) var(--spacing-medium);
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  transition: all var(--preference-transition-duration-normal);
}

.roq-chat-conversation-list-sidebar__header__top__action:hover,
.roq-chat-create-conversation-sidebar__header__top__action:hover {
  background-color: var(--color-button-darker);
  box-shadow: var(--box-shadow-popup);
}

.roq-chat-conversation-list-sidebar__header__top__action:active,
.roq-chat-create-conversation-sidebar__header__top__action:active {
  box-shadow: var(--box-shadow-strong);
}

.roq-chat-panel-header button:focus-visible {
  background-color: var(--color-button-darker);
}

.roq-chat-panel-header button svg {
  width: var(--spacing-medium);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_sidebar-header.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,iCAAiC;EACjC,kCAAkC;AACpC;;AAEA;;EAEE,yCAAyC;EACzC,0CAA0C;EAC1C,+BAA+B;EAC/B,6BAA6B;EAC7B,aAAa;EACb,0CAA0C;EAC1C,mDAAmD;EACnD,2EAA2E;EAC3E,4DAA4D;AAC9D;;AAEA;;EAEE,4CAA4C;EAC5C,mCAAmC;AACrC;;AAEA;;EAEE,oCAAoC;AACtC;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".roq-chat-panel-header {\n  font-size: var(--font-baseline);\n  font-weight: var(--font-semibold);\n  padding-top: var(--spacing-medium);\n}\n\n.roq-chat-conversation-list-sidebar__header__top__action,\n.roq-chat-create-conversation-sidebar__header__top__action {\n  background-color: var(--color-hover-dark);\n  border-radius: var(--border-radius-circle);\n  box-shadow: var(--box-shadow-1);\n  color: var(--color-icons-alt);\n  display: flex;\n  font-weight: var(--font-weight-label-bold);\n  padding: var(--spacing-small) var(--spacing-medium);\n  /* stylelint-disable-next-line declaration-property-value-disallowed-list */\n  transition: all var(--preference-transition-duration-normal);\n}\n\n.roq-chat-conversation-list-sidebar__header__top__action:hover,\n.roq-chat-create-conversation-sidebar__header__top__action:hover {\n  background-color: var(--color-button-darker);\n  box-shadow: var(--box-shadow-popup);\n}\n\n.roq-chat-conversation-list-sidebar__header__top__action:active,\n.roq-chat-create-conversation-sidebar__header__top__action:active {\n  box-shadow: var(--box-shadow-strong);\n}\n\n.roq-chat-panel-header button:focus-visible {\n  background-color: var(--color-button-darker);\n}\n\n.roq-chat-panel-header button svg {\n  width: var(--spacing-medium);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
