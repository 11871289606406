import React, { FC, Fragment } from 'react';

import { Props } from '../props';

/**
 * Description field
 *
 * @param props             Props
 * @param props.field       The field to render
 * @param props.field.label Concierge field label
 * @returns                 The component itself
 */
const FieldDescription: FC<Props> = ({ field: { label } }) => {
  return (
    <>
      {/* Support for multiline text separated by \n */}
      {label.split('\n').map((line, index) => (
        // No way to calculate key otherwise here
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export default FieldDescription;
