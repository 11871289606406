import React, { FC } from 'react';

import { Props } from '../props';

import * as Styled from './styled';

/**
 * Subtitle field
 *
 * @param props             Props
 * @param props.field       The field to render
 * @param props.field.label Concierge field label
 * @returns                 The component itself
 */
const FieldSubtitle: FC<Props> = ({ field: { label } }) => {
  return <Styled.St>{label}</Styled.St>;
};

export default FieldSubtitle;
