/**
 * @file A topic was created, so we update the cache accordingly
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicCreateMutation,
  TopicCreateMutationVariables,
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables,
  TopicsDocument,
  TopicSettingsDocument,
} from '../../../../../../../generated/graphql';
import queryCreation from '../queryCreation';

import {
  getShouldAddToList,
  getShouldAddToSettings,
} from './topicsAssign/helpers';

type Mutations = TopicCreateMutation | TopicInfoSubscription;
type Variables = TopicCreateMutationVariables | TopicInfoSubscriptionVariables;
type ResultType =
  | TopicCreateMutation['topicCreate']
  | TopicInfoSubscription['topicInfo'];

/**
 * Handle topic creation in cache
 *
 * @param result Result of a graphql operation
 * @param _args  Arguments of a graphql operation
 * @param cache  URQL graphql cache
 */
const topicCreate: UpdateResolver<Mutations, Variables> = (
  result,
  _args,
  cache,
) => {
  const topic =
    'topicInfo' in result ? result.topicInfo.topic : result.topicCreate;

  if (topic === null || topic === undefined) {
    return;
  }

  const queries = [];

  if (getShouldAddToList(cache, topic)) {
    queries.push({ name: 'topics', query: TopicsDocument });
  }

  if (getShouldAddToSettings(cache, topic)) {
    queries.push({ name: 'topicsAll', query: TopicSettingsDocument });
  }

  queryCreation<ResultType>({
    cache,
    queries,
    result: topic,
  });
};

export default topicCreate;
