import useIsComposeOpen from '../../../../hooks/router/search/useIsComposeOpen';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
  getIsSettingsNotificationsCategoryPage,
  getIsSettingsRootPage,
  getIsTemplatesPage,
} from '../../../../routes/helpers/pages';

/**
 * Get whether to apply styling for the page content
 * (it's only false on template compose open on mobile, in that case, we just render <Compose />)
 *
 * @returns Whether it's true
 */
const useContentConfig = (): boolean => {
  const isComposeOpen = useIsComposeOpen();
  const isMobile = useIsMobile();

  return (
    isMobile &&
    [
      getIsSettingsNotificationsCategoryPage(),
      getIsSettingsRootPage(),
      getIsTemplatesPage() && isComposeOpen,
    ].some(Boolean)
  );
};

export default useContentConfig;
