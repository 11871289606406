/**
 * @file contains hook that returns current hotel based on hotelId from url and users data that i stored in our cookies
 */

import { Hotel, Maybe } from '../generated/graphql';
import { getHotelPermissionsById } from '../utils/hotel';

import useParamHotelId from './router/params/useParamHotelId';
import useCurrentUser from './useCurrentUser';

/**
 * Custom hook for getting current hotel
 * (based on hotelId from url and users data that is stored in our cookies)
 *
 * @returns Current hotel, null or undefined while data is not written in cookies
 */
const useCurrentHotel = () => {
  const hotelId = useParamHotelId();
  const user = useCurrentUser();

  const hotelPermission = getHotelPermissionsById(
    hotelId,
    user?.hotelPermissions ?? [],
  );

  const hotel: Maybe<Hotel> = hotelPermission?.hotel ?? null;

  return hotel;
};

export default useCurrentHotel;
