import React, { FC } from 'react';

import { DateOffsetInput } from '../../../../../generated/graphql';

import translate from '../../../../../i18n/translate';
import { getOffsetTitle } from '../../../../../utils/offset';
import MessageNoResult from '../../../../Common/MessageNoResult';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  offset: DateOffsetInput | null;
};

/**
 * Section in the template compose that displays the offset
 *
 * @param props        Props passed to the component
 * @param props.offset Currently selected offset
 * @returns            The component itself
 */
const ComposeMetaOffset: FC<Props> = ({ offset }) => {
  return (
    <ComposeMetaSection heading={translate('MESSAGE__HEADING__DATE_DUE')}>
      {getOffsetTitle(offset) ?? (
        <MessageNoResult
          message={translate('TEMPLATE__OFFSET__MESSAGE__NONE')}
        />
      )}
    </ComposeMetaSection>
  );
};

export default ComposeMetaOffset;
