import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  className?: string;
  placeholder: string;
  value: string;
};

/**
 * Input field for meta items (title, assignees, labels, date, checklist)
 *
 * @param props             Props passed to the component
 * @param props.className   styled-components generated class name, needed for styling
 * @param props.placeholder Placeholder text to use when nothing was selected
 * @param props.value       Text value to be shown within the (default) input
 * @returns                 The component itself
 */
const MetaInputField: FC<Props> = ({ className, placeholder, value }) => {
  const showPlaceholder = value.trim().length === 0;

  if (showPlaceholder) {
    return (
      <Styled.Placeholder className={className}>
        {placeholder}
      </Styled.Placeholder>
    );
  }

  return (
    <Styled.WrapperPlain className={className}>
      <Styled.Input>{value}</Styled.Input>
    </Styled.WrapperPlain>
  );
};

export default MetaInputField;
