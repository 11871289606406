/**
 * @file contains the scroll reducer function
 */

import {
  SCROLL__REMEMBER__FEED,
  SCROLL__REMEMBER__SIDEBAR,
  SCROLL__RESET__FEED,
  SCROLL__TOPICS__LATEST_POST__ADD,
  SCROLL__TOPICS__LATEST_POST__REMOVE,
} from '../../constants/actionTypes';
import { SCROLL__DEFAULT } from '../../constants/preferences';

import {
  ScrollAction,
  ScrollRememberFeed,
  ScrollRememberSidebar,
  ScrollResetFeed,
  ScrollState,
  ScrollTopicsLatestPostAdd,
  ScrollTopicsLatestPostRemove,
} from '../../models/scroll';

import initialState from '../initialState';

/**
 * Reducer for scroll position
 */
type Reducer = (state: ScrollState, action: ScrollAction) => ScrollState;

/**
 * Request was made to add a topic to the list of topics that show latest post button
 *
 * @param state  The current scroll state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onScrollTopicsLatestPostAdd = (
  state: ScrollState,
  action: ScrollTopicsLatestPostAdd,
): ScrollState => {
  return {
    ...state,
    topicsLatestPost: new Set([
      ...state.topicsLatestPost,
      action.payload.topicId,
    ]),
  };
};

/**
 * Request was made to remove a topic from the list of topics that show latest post button
 *
 * @param state  The current scroll state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onScrollTopicsLatestPostRemove = (
  state: ScrollState,
  action: ScrollTopicsLatestPostRemove,
): ScrollState => {
  // Goal of this is to avoid mutating the state directly
  const clonedSet = new Set(state.topicsLatestPost);
  clonedSet.delete(action.payload.topicId);

  return {
    ...state,
    topicsLatestPost: clonedSet,
  };
};

/**
 * Request was made to remember the scroll position for the topic
 *
 * @param state  The current scroll state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onScrollRememberFeed = (
  state: ScrollState,
  action: ScrollRememberFeed,
): ScrollState => ({
  ...state,
  feed: {
    ...state.feed,
    [action.payload.topicId]: action.payload.scrollPosition,
  },
});

/**
 * Request was made to remember the scroll position for the sidebar
 *
 * @param state  The current scroll state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onScrollRememberSidebar = (
  state: ScrollState,
  action: ScrollRememberSidebar,
): ScrollState => ({
  ...state,
  sidebar: action.payload.scrollPosition,
});

/**
 * Request was made to remember the scroll position for the topic
 *
 * @param state  The current scroll state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const onScrollResetFeed = (
  state: ScrollState,
  action: ScrollResetFeed,
): ScrollState => ({
  ...state,
  feed: {
    ...state.feed,
    [action.payload.topicId]: SCROLL__DEFAULT,
  },
});

/**
 * action:reducer mapping for scroll
 */
const mapping = new Map<string, Reducer>([
  [SCROLL__REMEMBER__FEED, onScrollRememberFeed],
  [SCROLL__REMEMBER__SIDEBAR, onScrollRememberSidebar],
  [SCROLL__RESET__FEED, onScrollResetFeed],
  [SCROLL__TOPICS__LATEST_POST__ADD, onScrollTopicsLatestPostAdd],
  [SCROLL__TOPICS__LATEST_POST__REMOVE, onScrollTopicsLatestPostRemove],
]);

/**
 * Reducer for all scroll actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const scroll = (
  state = initialState.scroll,
  action: ScrollAction,
): ScrollState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default scroll;
