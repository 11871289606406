import { styled } from 'styled-components';

import Button from '../Button';

export const ButtonSave = styled(Button)`
  background-color: var(--color-button-primary);
  border-radius: var(--border-radius-small);
  color: var(--color-text-alt);
  display: block;
  font-size: var(--font-baseline);
  padding: var(--spacing-tiny) var(--spacing-medium);

  &:disabled {
    background-color: var(--color-button-disabled);
    border-color: var(--color-button-disabled);
    color: var(--color-text-alt);

    /* unsupported on iOS, but doesn't matter since that's touch input anyway */
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }
`;
