import React, { FC, ReactNode } from 'react';

import ComposeContainer from '../../../../containers/compose';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getIsTemplatesPage } from '../../../../routes/helpers/pages';
import { getCanCreateTemplate } from '../../../../utils/permissions/template';
import FloatingBottomNav from '../../../FloatingBottomNav';

type Props = {
  floatingButton?: ReactNode;
};

/**
 * Footer region for the settings pages
 *
 * @param props                Props passed to the component
 * @param props.floatingButton Floating button to show about the navigation
 * @returns                    The component itself
 */
const SettingsLayoutFooter: FC<Props> = ({ floatingButton }) => {
  const isMobile = useIsMobile();
  const shouldShowCompose =
    getIsTemplatesPage() && getCanCreateTemplate() && isMobile === false;

  return (
    <>
      {shouldShowCompose && <ComposeContainer nodeType="TEMPLATE" />}
      {isMobile && <FloatingBottomNav floatingButton={floatingButton} />}
    </>
  );
};

export default SettingsLayoutFooter;
