import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import Comments from '../../components/Comments';
import translate from '../../i18n/translate';
import { reportError } from '../../services/reporting';
import { reportApiErrors } from '../../utils/error';

import mapDispatchToProps from './mapDispatchToProps';
import mapStateToProps from './mapStateToProps';
import { Props } from './props';
import useComments from './useComments';

/**
 * Comments related to a message
 *
 * @param props                            Props passed to the component
 * @param props.isArchived                 Flag that indicate if message is archived
 * @param props.messageId                  The message to show the comments for
 * @param props.networkComments            Whether to override GraphQL fetching state (debug option)
 * @param props.numberOfComments           Number of comments
 * @param props.requestSubscribeToComments Requests a graphql subscription to the comments for a message
 * @param props.scrollIntoViewCallback     Callback that calls scrollIntoView on the selected message
 * @param props.thread                     Metadata about the comment thread (for external messaging only)
 * @returns                                The component itself
 */
const CommentsContainer: FC<Props> = ({
  isArchived,
  messageId,
  networkComments,
  numberOfComments,
  requestSubscribeToComments,
  scrollIntoViewCallback,
  thread = null,
}) => {
  const [text, setDraftText] = useState('');
  const [isNewComment, setIsNewComment] = useState(false);

  const {
    comments,
    createComment,
    error,
    hasMoreComments,
    isLoadingNewComments,
    loadMoreComments,
  } = useComments(messageId, numberOfComments, isArchived === true);

  useEffect(() => {
    // Subscribes to comments when the comments have been opened for the first time
    requestSubscribeToComments(messageId);
  }, [messageId, requestSubscribeToComments]);

  const isLoading = isLoadingNewComments === true || networkComments === true;

  /**
   * Request that a message is submitted
   *
   * @param isForExternal Whether the message is intended for team members or the guest that posted the message
   */
  const requestSubmit = (isForExternal: boolean) => {
    createComment(isForExternal, text).catch(reportApiErrors);
    setDraftText('');
    setIsNewComment(true);
  };

  useLayoutEffect(() => {
    // Comments have been expanded and fetching has been completed
    // and we scroll that message into view

    if (scrollIntoViewCallback && isLoading === false) {
      scrollIntoViewCallback('auto', 'start');
    }
  }, [isLoading, scrollIntoViewCallback]);

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  /**
   * Load more comments
   */
  const loadMore = () => {
    setIsNewComment(false);
    loadMoreComments();
  };
  return (
    <Comments
      comments={comments}
      draftText={text}
      isArchived={isArchived}
      isLoading={isLoading}
      isNewComment={isNewComment}
      requestDraftUpdate={setDraftText}
      requestLoadMore={hasMoreComments ? loadMore : null}
      requestSubmit={requestSubmit}
      thread={thread}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
