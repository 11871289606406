import React, { FC, useEffect, useRef } from 'react';

import {
  MESSAGE__MAX_TITLE_LENGTH,
  MESSAGE__TITLE_REQUIRED,
} from '../../../../../constants/forms';
import { ComposeTitle } from '../../../../../containers/compose/props';
import useIsComposeOpen from '../../../../../hooks/router/search/useIsComposeOpen';

import translate from '../../../../../i18n/translate';

import * as Styled from './styled';

type Props = {
  className?: string;
  formId?: string;
  onChange: (value: ComposeTitle) => void;
  value: ComposeTitle;
};

/**
 * Post title input
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.formId    The formId, optional argument
 * @param props.onChange  Callback to be invoked every time input changes
 * @param props.value     Current input value
 * @returns               The component itself
 */
const ComposeMetaTitle: FC<Props> = ({
  className,
  formId,
  onChange,
  value,
}) => {
  const shouldShow = useIsComposeOpen();
  const ref = useRef<HTMLInputElement>(null);

  // Automatically focus compose title when shouldShow becomes true
  useEffect(() => {
    if (shouldShow) {
      ref.current?.focus();
    }
  }, [shouldShow]);

  return (
    <Styled.Input
      ariaLabel={translate('GENERAL__TITLE')}
      className={className}
      enterKeyHint="send"
      formId={formId}
      isRequired={MESSAGE__TITLE_REQUIRED}
      maxLength={MESSAGE__MAX_TITLE_LENGTH}
      onChange={onChange}
      placeholder={translate('COMPOSE__PLACEHOLDER__TITLE')}
      ref={ref}
      type="text"
      value={value}
    />
  );
};

export default ComposeMetaTitle;
