import React, { FC } from 'react';

import MuiIcon from '../../MUI/MuiIcon';

type Props = {
  isPrivate: boolean;
};

/**
 * Topic visibility icon (private/public)
 *
 * @param props           Props passed to the component
 * @param props.isPrivate Whether the topic is private
 * @returns               The component itself
 */
const TopicVisibilityIcon: FC<Props> = ({ isPrivate }) => {
  return <MuiIcon icon={isPrivate ? 'lock' : 'lockOpen'} />;
};

export default TopicVisibilityIcon;
