/**
 * @file Helper functions for MetaTemplate
 */

import {
  Maybe,
  Template,
  TemplateBasicFragment,
} from '../../generated/graphql';
import translate from '../../i18n/translate';

type TemplateID = Maybe<Template['id']>;

/**
 * Find the name of a specific template
 *
 * @param templatesAll All available templates
 * @param templateId   The ID we want the title for
 * @returns            The title to show
 */
export const getTemplateTitle = (
  templatesAll: TemplateBasicFragment[],
  templateId: TemplateID,
): string => {
  return templatesAll.find(template => template.id === templateId)?.title ?? '';
};

/**
 * Get the title to use for warning text
 *
 * @param dataTemplateCurrent Currently selected template
 * @param dataTemplateSaved   Currently saved template
 * @returns                   The text to use or null if none
 */
export const getWarningTitle = (
  dataTemplateCurrent: TemplateID,
  dataTemplateSaved: TemplateID,
): Maybe<string> => {
  const shouldWarn =
    dataTemplateCurrent !== null && dataTemplateCurrent !== dataTemplateSaved;

  return shouldWarn ? translate('TEMPLATE__GENERAL__SAVE_WARNING') : null;
};
