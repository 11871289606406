/**
 * @file React hook that handles element focus
 */

import { useMemo, useState } from 'react';

/**
 * React hook that handles element focus
 *
 * @returns A tuple of isFocused - tells us if the element is focused and bind - to bind it to an input
 */
const useFocus = (): [
  boolean,
  {
    onBlur: () => void;
    onFocus: () => void;
  },
] => {
  const [isFocused, setFocused] = useState(false);

  const bind = useMemo(
    () => ({
      onBlur: () => setFocused(false),
      onFocus: () => setFocused(true),
    }),
    [],
  );

  return [isFocused, bind];
};

export default useFocus;
