/**
 * @file Get how many days separate the two passed dates
 */

const msecsInDay = 1000 * 60 * 60 * 24;

/**
 * Get how many days separate the two passed dates
 * (if date1 is 21h yesterday and date2 is 7h today,
 * should return 1, even though the difference is less than 24 hours)
 *
 * @param date1 Date to compare from
 * @param date2 Date to compare against
 * @returns     The difference in days
 */
const getDifferenceInDays = (date1: Date, date2: Date): number => {
  // copy date objects to not mutate the original variables
  const date1Copy = new Date(date1.getTime());
  const date2Copy = new Date(date2.getTime());

  // we're only interested in days,
  // so we set both date1 and date2 to 00:00:00.0000 time
  // that way, for 2020-10-11 23:59:59 and 2020-10-12 00:00:00
  // we will get 1 as result
  date1Copy.setHours(0, 0, 0, 0);
  date2Copy.setHours(0, 0, 0, 0);

  const msecDiff = date2Copy.getTime() - date1Copy.getTime();

  return Math.floor(msecDiff / msecsInDay);
};

export default getDifferenceInDays;
