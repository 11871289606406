/**
 * @file Calculate new permissions state
 */

import { Reducer } from 'react';

import { Action, State } from './types';

/**
 * Calculate new permissions state
 *
 * @param state  Current permissions state
 * @param action The action that took place
 * @returns      New state
 */
const reducer: Reducer<State, Action> = (state, action) => ({
  ...state,
  [action.name]: action.status,
});

export default reducer;
