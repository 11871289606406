import { styled } from 'styled-components';

type LabelTextProps = {
  'data-is-active': boolean;
};

type InputProps = LabelTextProps;

/**
 * Get which color should the border be,
 * for date/time input, based on its props
 *
 * @param props Props passed to the input component
 * @returns     Color value (as a CSS variable)
 */
const getBorderColor = (props: InputProps): string => {
  if (props['data-is-active'] === true) {
    return 'var(--color-primary-light)';
  }

  return 'var(--color-border-inactive)';
};

// Plain inputs in one row, wrapped by their respective labels
export const Header = styled.header`
  display: flex;
  margin-bottom: var(--spacing-small);
`;

// Wraps around the input, so we don't need to use for=""
export const Label = styled.label`
  cursor: auto;
  width: calc(50% - var(--spacing-small) / 2);

  &:first-child {
    margin-right: var(--spacing-small);
  }
`;

// The actual label text
export const LabelText = styled.p<LabelTextProps>`
  font-weight: ${props => (props['data-is-active'] ? 'bold' : 'normal')};
  margin-bottom: var(--spacing-tiny);
`;

// Date and time inputs
export const Input = styled.input<InputProps>`
  border: 1px solid ${getBorderColor};
  display: block;
  font-size: var(--font-baseline);
  padding: var(--spacing-tiny);
  width: 100%;
`;

// Wrapper around Common Date and Time Picker components
export const Contents = styled.section`
  align-items: center;
  display: flex;
  font-weight: var(--font-normal);
  justify-content: center;
  min-height: 200px;
  width: 100%;
`;
