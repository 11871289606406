import React, { FC, ReactNode } from 'react';

import Checkbox from '../../../../Common/Checkbox';
import ChecklistEmpty from '../../../../Common/ChecklistItems/ChecklistEmpty';

import { ItemDraft } from '../../../../Common/ChecklistItems/props';

import * as Styled from './styled';

type Props = {
  items: ItemDraft[];
  noResult?: ReactNode;
};

/**
 * Checklist for compose form mobile
 *
 * @param props          Props passed to the component
 * @param props.items    Checklist items to show
 * @param props.noResult ReactNode to use when the passed array is empty
 * @returns              The component itself
 */
const ChecklistItemsPreview: FC<Props> = ({ items, noResult }) => {
  if (items.length === 0) {
    return noResult ? <>{noResult}</> : <ChecklistEmpty />;
  }

  return (
    <ul>
      {items.map(item => (
        <Styled.ItemsPreviewWrapper key={`checklist-item-${item.position}`}>
          <Styled.ItemsPreviewLabel>
            <Checkbox
              colorChecked={'var(--color-primary-light)'}
              isChecked={item.checked}
              onChange={null}
            />
            <Styled.ItemsPreviewText>{item.text}</Styled.ItemsPreviewText>
          </Styled.ItemsPreviewLabel>
        </Styled.ItemsPreviewWrapper>
      ))}
    </ul>
  );
};

export default ChecklistItemsPreview;
