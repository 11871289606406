/**
 * @file Hook for managing ES search query and its results
 */

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import {
  MessageRankedSearchDocument,
  SearchParams,
} from '../../../generated/graphql';
import useParamSearchTerm from '../../router/params/useParamSearchTerm';
import { extractError } from '../helpers';
import { UseESQueryReturn } from '../types';

import { getHasMore, getIsEmpty, getIsValidQuery } from './helpers';
import useSearchFormParams from './useSearchFormParams';
import useSearchPageSync from './useSearchTextUrlCurrent';
import useSearchTextUrlNext from './useSearchTextUrlNext';

// Never actually used,
// since we pause making a request when params are missing from the URL
// But TypeScript complains otherwise
const paramsDefault: SearchParams = { from: 0, queryString: '', size: 0 };

/**
 * Hook for managing ES search query and its results
 *
 * @returns Data needed from making an ES query.
 */
const useEsTextQuery = (): UseESQueryReturn => {
  const navigate = useNavigate();
  const searchTerm = useParamSearchTerm();
  const paramsUrl = useSearchFormParams();
  const urlNext = useSearchTextUrlNext();

  const isValidQuery = getIsValidQuery(paramsUrl, searchTerm);

  const [{ data, error, fetching }] = useQuery({
    pause: isValidQuery === false,
    query: MessageRankedSearchDocument,
    requestPolicy: 'cache-and-network',
    variables: { params: paramsUrl ?? paramsDefault },
  });

  const hasMore = getHasMore(data);
  const isEmpty = getIsEmpty(data, fetching);

  useSearchPageSync(data);

  return {
    error: extractError(isValidQuery, fetching, error),
    isEmpty,
    isLoading: fetching,
    loadMore: hasMore ? () => navigate(urlNext) : null,
    messages: data?.messageRankedSearch.nodes ?? [],
    searchTerm,
    total: data?.messageRankedSearch.searchInfo.total ?? 0,
  };
};

export default useEsTextQuery;
