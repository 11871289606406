import React, { FC } from 'react';

import { LabelBasicFragment } from '../../../generated/graphql';

import LabelSingle from '../LabelSingle';

import * as Styled from './styled';

export type Props = {
  className?: string;
  labels: LabelBasicFragment[];
  shouldWrap?: boolean;
};

/**
 * The list of labels, commonly displayed format
 *
 * @param props            Props passed to the component
 * @param props.className  styled-components generated class name
 * @param props.labels     The labels to show
 * @param props.shouldWrap Whether the label list should wrap into 2nd, 3rd... line
 * @returns                The component itself
 */
const LabelList: FC<Props> = ({ className, labels, shouldWrap = true }) => {
  return (
    <Styled.Ul className={className} data-should-wrap={shouldWrap}>
      {labels.map(label => {
        return (
          <Styled.Li data-should-wrap={shouldWrap} key={`label-${label.id}`}>
            <LabelSingle label={label} />
          </Styled.Li>
        );
      })}
    </Styled.Ul>
  );
};

export default LabelList;
