/**
 * @file contains message helper functions
 */

import {
  Message,
  MessageCreatorType,
  MessageType,
  UserStatus,
} from '../../generated/graphql';
import translate from '../../i18n/translate';
import { MessageCreator, MessageCreatorTypeField } from '../../models/message';
import { UserNameFields } from '../../models/user';
import userGetName from '../user/userGetName';

/**
 * Check if the message is external.
 *
 * @param creatorType Creator type of the message to check
 * @returns           True if the message is external
 */
export const getIsExternal = (creatorType: MessageCreatorType): boolean => {
  return creatorType === MessageCreatorType.Guest;
};

/**
 * Check if the message is a concierge form
 *
 * @param type Type of the message to check
 * @returns    Is message a concierge form
 */
export const getIsConcierge = (type: MessageType): boolean => {
  return type === MessageType.ConciergeForm;
};

/**
 * Check if the message is an (external) request
 *
 * @param message             Message to check
 * @param message.creatorType Creator type of the message to check
 * @param message.type        Type of the message to check
 * @returns                   Is message a request
 */
export const getIsRequest = ({
  creatorType,
  type,
}: Pick<Message, 'creatorType' | 'type'>): boolean => {
  return getIsExternal(creatorType) && !getIsConcierge(type);
};

/**
 * Check if the message is created by a recurring rule
 *
 * @param creatorType Creator type of the message to check
 * @returns           Is message created by a recurring rule
 */
export const getIsRecurring = (creatorType: MessageCreatorType): boolean => {
  return creatorType === MessageCreatorType.HotelboardRecurring;
};

/**
 * Check if the message creator is active. That means either that
 * the creator exists and his status is active or that the message
 * is external or created by a recurring rule.
 *
 * @param message The message to check
 * @returns       Is the message creator active
 */
export const getIsCreatorActive = (
  message: MessageCreator & MessageCreatorTypeField,
): boolean => {
  return (
    message.creator?.status === UserStatus.Active ||
    getIsExternal(message.creatorType) ||
    getIsRecurring(message.creatorType)
  );
};

/**
 * Get creator name based on creator type and creator
 *
 * @param creatorType The creator type
 * @param creator     The creator
 * @returns           The user name
 */
export const getCreatorName = (
  creatorType: MessageCreatorType,
  creator?: UserNameFields,
): string => {
  if (getIsRecurring(creatorType)) {
    return translate('RRULE__POST');
  }

  return userGetName(creator ?? null);
};
