import { styled } from 'styled-components';

import CommonIcon from '../../Icon';

// For concierge icons
export const ConciergeIcon = styled(CommonIcon)`
  background-color: var(--color-background);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-tiny);
`;
