/**
 * @file After successful message delete mutation, update the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MessageDeleteMutation,
  MessageDeleteMutationVariables,
  MessagesDocument,
} from '../../../../../generated/graphql';
import { removeTask } from '../utils/updaters/messages/tasks';
import paginatedQueryDeletion from '../utils/updaters/paginatedQueryDeletion';
import { removeNodeFromPaginatedQuery } from '../utils/updaters/paginatedQueryHelpers';

/**
 * After successful delete request this function removes message
 * from graphql cache.
 *
 * @param _result      The result of the mutation (unused)
 * @param variables    The variables passed along with the mutation
 * @param variables.id The ID of the deleted message
 * @param cache        Current GraphQL cache
 */
const messageDeleteMutation: UpdateResolver<
  MessageDeleteMutation,
  MessageDeleteMutationVariables
> = (_result, { id: messageId }, cache) => {
  paginatedQueryDeletion({
    cache,
    queryName: 'messages',
    updateQuery: variables => {
      removeNodeFromPaginatedQuery({
        cache,
        nodeId: messageId,
        query: MessagesDocument,
        queryName: 'messages',
        variables,
      });
    },
  });

  removeTask(messageId, cache);
};

export default messageDeleteMutation;
