import React, { FC } from 'react';

import { IconPropsExtended } from '../types';

import MaterialSymbolIconBase from './MaterialSymbolIconBase';

/**
 * Material symbol settings icon
 *
 * @param props Props passed to the component
 * @returns     Settings icon
 */
const MaterialSymbolSettingsIcon: FC<IconPropsExtended> = props => (
  <MaterialSymbolIconBase {...props} iconName="settings" />
);

export default MaterialSymbolSettingsIcon;
