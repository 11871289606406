/**
 * @file Helper functions for getting the UI visible colors of labels
 */

import colorHexToRgba from '../utils/colorHexToRgba';
import colorRgbaToHsla from '../utils/colorRgbaToHsla';

/**
 * Calculate background color for the label
 * (make it a bit lighter, so that the text is readable)
 *
 * @param hex           The original label's hex color code
 * @param alphaModifier Modify alpha channel of the original color
 * @returns             The color to use, in rgba() format
 */
export const getLabelBgColor = (hex: string, alphaModifier = 0.2): string => {
  const color = colorHexToRgba(hex);

  if (color === null) {
    return hex;
  }

  const { red, green, blue, alpha } = color;
  return `rgba(${red}, ${green}, ${blue}, ${alpha * alphaModifier})`;
};

/**
 * Calculate border color for the label
 * (make it a bit darker, so that the border/checkbox is visible)
 *
 * @param hex The original label's hex color code
 * @returns   The color to use, in rgba() format
 */
export const getLabelBorderColor = (hex: string): string => {
  const color = colorHexToRgba(hex);

  if (color === null) {
    return hex;
  }

  const colorHsla = colorRgbaToHsla(color);

  if (colorHsla.lightness > 80) {
    const { hue, saturation, lightness, alpha } = colorHsla;
    return `hsla(${hue}, ${saturation}%, ${lightness - 50}%, ${alpha})`;
  }

  return hex;
};
