import { css, styled } from 'styled-components';

import devices from '../../styles/devices';

import { getQueryByDevice } from '../../styles/getQuery';
import MetaContainerInline from '../MetaContainerInline';

const mediaQuery = getQueryByDevice(null, 'tabletS');

export const Wrapper = styled.div`
  @media ${devices.tabletS} {
    position: relative;
  }
`;

type PopoverProps = {
  'data-align': 'center' | 'right';
  'data-auto-height': boolean;
  'data-upwards': boolean;
};

/**
 * Get additional styles to apply to the wrapper based on its direction
 *
 * @param props Props passed to the button
 * @returns     The CSS to apply (if any)
 */
const getWrapperDirectionStyles = (props: PopoverProps) => {
  if (props['data-upwards'] === true) {
    return css<PopoverProps>`
      bottom: 170%;
    `;
  }

  return css`
    border-top: var(--border-pale);
    bottom: auto;
    top: 145%;
  `;
};

export const Popover = styled(MetaContainerInline)<PopoverProps>`
  --width: min(200%, 80vw, 330px);
  --left: calc(50% - var(--width) / 2);

  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-popup);
  left: ${props => (props['data-align'] === 'center' ? 'var(--left)' : 'auto')};
  max-height: ${props => (props['data-auto-height'] ? '50vh' : 'inherit')};
  ${getWrapperDirectionStyles}
  position: absolute;
  right: ${props => (props['data-align'] === 'right' ? '0' : 'auto')};
  width: var(--width);
  z-index: var(--z-compose-popup);

  @media ${mediaQuery} {
    max-height: none;
  }

  &::before,
  &::after {
    content: '';
    left: ${props => (props['data-align'] === 'center' ? '50%' : 'auto')};
    position: absolute;
    right: ${props => (props['data-align'] === 'right' ? '25%' : 'auto')};
    top: ${props => (props['data-upwards'] ? '100%' : '0')};
    transform: translateX(-50%);
  }

  &::after {
    background-color: var(--color-white);
    border: 6px solid black;
    border-color: transparent transparent var(--color-white) var(--color-white);
    box-shadow: ${props =>
      props['data-upwards']
        ? 'var(--box-shadow-popup-triangle-upward)'
        : 'var(--box-shadow-popup-triangle-downward)'};
    height: 0;
    transform: ${props =>
      props['data-upwards'] ? 'rotate(-45deg)' : 'rotate(135deg)'};
    transform-origin: 0 0;
    width: 0;
  }

  @media ${mediaQuery} {
    height: 100%;
    inset: 0;
    max-width: none;
    position: absolute;
    width: 100%;

    &::before,
    &::after {
      display: none;
    }
  }
`;
