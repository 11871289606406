/**
 * @file contains a debounce function
 */

/**
 * A higher order function that allows us to “group” multiple sequential
 * calls in a single one.
 *
 * @param callback Function to debounce
 * @param delay    Debounce delay
 * @returns        A debounced function
 */
const debounce = (
  callback: () => unknown,
  delay = 300,
): ((...args: unknown[]) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
};

export default debounce;
