/**
 * @file Helper functions for toast message content
 */

import { ToastMessageModel } from '../../../../models/toastMessage';

/**
 * Get which direction should the direction be pointed at
 *
 * @param hasAdditionalText Whether the toast message has additional text
 * @param isVisible         Whether the additional text is visible
 * @returns                 Chevron direction or null if it shouldn't be shown
 */
export const getChevronDirection = (
  hasAdditionalText: boolean,
  isVisible: boolean,
) => {
  if (hasAdditionalText === false) {
    return null;
  }

  return isVisible ? 'down' : 'right';
};

/**
 * Get whether the toast message has anything for additional text
 *
 * @param additionalText Additional text of the toast message
 * @returns              Whether it has
 */
export const getHasAdditionalText = (
  additionalText: ToastMessageModel['additionalText'],
): boolean => {
  return (additionalText?.trim().length ?? 0) !== 0;
};
