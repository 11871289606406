import { styled } from 'styled-components';

import CommonIcon from '../../Common/Icon';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

// "at" text between the avatar and the message creation time
export const TextAt = styled.span`
  margin: 0 var(--spacing-tiny);
`;
export const UserName = styled.p`
  margin-left: var(--spacing-tiny);
`;

export const ConciergeIcon = styled(CommonIcon)`
  background-color: var(--color-background);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-tiny);
`;
