import React, { ChangeEventHandler, FC } from 'react';

import translate from '../../../../i18n/translate';
import { reportError } from '../../../../services/reporting';

import * as Styled from './styled';

export const RECIPIENTS: Record<'GUEST' | 'TEAM', string> = {
  GUEST: 'GUEST',
  TEAM: 'TEAM',
};

export type Props = {
  className?: string;
  isDisabled: boolean;
  name: string;
  onChange: (value: string) => void;
  value: string;
};

/**
 * Toggle whether the comment is for team members or the guest
 *
 * @param props            Props passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.isDisabled Whether to disable the toggle
 * @param props.name       HTML name attribute for radio buttons
 * @param props.onChange   Request that radioGroup selection is changed
 * @param props.value      Currently selected radio button
 * @returns                The component itself
 */
const RecipientToggle: FC<Props> = ({
  className,
  isDisabled,
  name,
  onChange,
  value,
}) => {
  /**
   * The user switched the radio option for recipients
   *
   * @param event The event that took place
   */
  const handleOptionChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { value: optionValue } = event.target;

    if (optionValue === RECIPIENTS.TEAM) {
      onChange(RECIPIENTS.TEAM);
    } else if (optionValue === RECIPIENTS.GUEST) {
      onChange(RECIPIENTS.GUEST);
    } else {
      reportError('RecipientToggle: Unknown recipient.');
    }
  };

  return (
    <Styled.Ul className={className} role="radiogroup">
      <li>
        <label>
          <Styled.Radio
            checked={value === RECIPIENTS.TEAM}
            name={name}
            onChange={handleOptionChange}
            required={true}
            type="radio"
            value={RECIPIENTS.TEAM}
          />
          <Styled.LabelText>
            {translate('COMMENTS__RECIPIENT_TEAM')}
          </Styled.LabelText>
        </label>
      </li>

      <li>
        <label>
          <Styled.Radio
            checked={value === RECIPIENTS.GUEST}
            disabled={isDisabled}
            name={name}
            onChange={handleOptionChange}
            required={true}
            type="radio"
            value={RECIPIENTS.GUEST}
          />
          <Styled.LabelText>
            {translate('COMMENTS__RECIPIENT_GUEST')}
          </Styled.LabelText>
        </label>
      </li>
    </Styled.Ul>
  );
};

export default RecipientToggle;
