import React, { FC } from 'react';

import BaseHeaderMobile from '../../../../components/Header/BaseHeaderMobile';
import useLayoutConfig from '../../../../components/layouts/SettingsLayout/hooks/useLayoutConfig';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamRecurringFrequency from '../../../../hooks/router/params/useParamRecurringFrequency';
import useParamTemplateId from '../../../../hooks/router/params/useParamTemplateId';
import useIsComposeOpen from '../../../../hooks/router/search/useIsComposeOpen';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import ComposeHeader from '../../compose';

import { getSettingsSegmentedButtons } from '../helpers';

import { getContentRight, getSettingsBackUrl } from './helpers';

/**
 * Mobile header component for the settings page
 *
 * @returns The component itself
 */
const SettingsHeaderMobile: FC = () => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const isComposeOpen = useIsComposeOpen();
  // Use link without sidebar params for mobile by passing true for isTablet
  const isTablet = true;
  const recurringFrequency = useParamRecurringFrequency();
  const templateId = useParamTemplateId();

  const { buttons, title } = useLayoutConfig();

  if (isComposeOpen) {
    return <ComposeHeader nodeId={templateId} nodeType="TEMPLATE" />;
  }

  const backUrl = getSettingsBackUrl(currentPath, hotelId, isTablet);
  const contentRight = getContentRight(currentPath, buttons[0]);
  const segmentedButtons = getSettingsSegmentedButtons({
    currentPath,
    hotelId,
    isTablet,
    recurringFrequency,
    templateId,
  });

  return (
    <BaseHeaderMobile
      backUrl={backUrl}
      contentRight={contentRight}
      segmentedButtons={segmentedButtons}
      title={title}
    />
  );
};

export default SettingsHeaderMobile;
