import { styled } from 'styled-components';

import ProgressChecklistCommon from '../../../../Common/ProgressChecklist';

export const ProgressChecklist = styled(ProgressChecklistCommon)`
  margin-right: var(--spacing-medium);
`;

// Wraps around the entire component
export const ItemsPreviewWrapper = styled.li`
  align-items: center;
  display: flex;
  transition: background-color var(--preference-transition-duration-normal);

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-background-selected);
    }
  }
`;

// Wraps around checkbox and item title
export const ItemsPreviewLabel = styled.label`
  align-items: center;
  display: flex;
  flex-grow: 1;

  /**
   * Use min-width because flex child with flex-grow: 1; prop
   * will go outside of parents container.
   * That way, if we have long text, we don't have horizontal scrollbar on sidebar
   */
  min-width: 0;
  padding: var(--spacing-small);
  padding-right: 0;
`;

// Item title
export const ItemsPreviewText = styled.p`
  flex-grow: 1;
  margin: 0 var(--spacing-medium);
  overflow: hidden;
  text-overflow: ellipsis;
`;
