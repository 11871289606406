/**
 * @file contains formatter function for plural elements in different languages
 */

import { reportError } from '../services/reporting';

import { getLocaleCode, LOCALE__DEFAULT } from './user/locale';

/**
 * Translations for ordinal number suffixes
 */
const suffixes: Record<string, Map<string, string>> = {
  'de-DE': new Map([
    ['one', '.'],
    ['two', '.'],
    ['few', '.'],
    ['other', '.'],
  ]),
  'en-GB': new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]),
  'fr-FR': new Map([
    ['one', 'er'],
    ['two', 'ème'],
    ['few', 'ème'],
    ['other', 'ème'],
  ]),
  'it-IT': new Map([
    ['one', '°'],
    ['two', '°'],
    ['few', '°'],
    ['other', '°'],
  ]),
  'nl-NL': new Map([
    ['one', 'e'],
    ['two', 'e'],
    ['few', 'e'],
    ['other', 'e'],
  ]),
};

/**
 * Format ordinal number for current language
 *
 * @param num Number to format
 * @returns   Formatted number
 */
const formatOrdinalNumber = (num: string) => {
  const locale = getLocaleCode();

  const pluralRules = new Intl.PluralRules(locale, { type: 'ordinal' });

  if (locale in suffixes === false) {
    reportError('No mapping for locale: ' + locale);
  }

  const ruleToPick = pluralRules.select(parseInt(num, 10));
  const rules = suffixes[locale] ?? suffixes[LOCALE__DEFAULT];

  const suffix = rules.get(ruleToPick);
  return `${num}${suffix}`;
};

export default formatOrdinalNumber;
