/**
 * @file contains the draftsText reducer function
 */

import { DRAFTS__TEXT__SET } from '../../constants/actionTypes';

import { DraftDataFormatted, DraftsState } from '../../models/draft';
import { DraftActionText, DraftActionTextSet } from '../../models/draftText';

import initialState from '../initialState';

/**
 * Reducers for drafts
 */
type Reducer = (state: DraftsState, action: DraftActionText) => DraftsState;

/**
 *
 * UPDATE callbacks
 *
 */

/**
 * The user has updated the draft, so a request was made to store those changes
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state to reflect this
 */
const onUpdateDraftTextRequest = (
  stateCurrent: DraftsState,
  action: DraftActionTextSet,
): DraftsState => {
  const stateNew = new Map(stateCurrent);

  const key = action.parentId;
  const draftOriginal = stateNew.get(key);

  if (draftOriginal) {
    const draftCopy: DraftDataFormatted = {
      ...draftOriginal,
      text: action.text,
    };

    stateNew.set(key, draftCopy);
  }

  return stateNew;
};

/**
 * action:reducer mapping for drafts
 */
const mapping = new Map<string, Reducer>([
  [DRAFTS__TEXT__SET, onUpdateDraftTextRequest],
]);

/**
 * Reducer for all drafts actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const drafts = (
  state = initialState.drafts,
  action: DraftActionText,
): DraftsState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default drafts;
