import React, { FC } from 'react';

import { MessageCommon } from '../../../../models/message';
import SkeletonLabelList from '../../../Loading/LabelList';

import * as Styled from './styled';

type Props = {
  labels: MessageCommon['labels'];
};

/**
 * Labels shown in a single message in the feed
 *
 * @param props        Props passed to the component
 * @param props.labels Array of labels
 * @returns            The component itself
 */
const MessageLabels: FC<Props> = ({ labels }) => {
  /**
   * In case labels are fetching again after being deleted, in no other cases will raw labels be null
   *
   * @todo remove this when backend implements non-nullable labels in a Message
   */
  if (labels === null) {
    return (
      <Styled.SkeletonWrapper>
        <SkeletonLabelList nrLabels={1} />
      </Styled.SkeletonWrapper>
    );
  }

  return <Styled.LabelList labels={labels ?? []} />;
};

export default MessageLabels;
