import React, { ChangeEventHandler, FC } from 'react';

import translate from '../../../i18n/translate';

import { Mode } from '../../../models/recurringMain';
import { getDateMinValue, getTimeMinValue } from '../../../utils/date/getDate';

import * as Styled from './styled';

export type Props = {
  dateMin: Date | null;
  mode: Mode;
  requestChangeDate: (value: string) => void;
  requestChangeTime: (value: string) => void;
  setMode: (mode: Mode) => void;
  textDate: string;
  textTime: string;
  valueDate: string;
};

/**
 * Whether to use native date and time pickers
 *
 * @todo  Discuss and check what we should use
 */
const shouldUseNativeControls = false;

/**
 * DateTime Picker header component
 * used in compose form due date picker
 *
 * @param props                   Props passed to the component
 * @param props.dateMin           Earliest selectable date
 * @param props.mode              Current mode
 * @param props.requestChangeDate Change selected date
 * @param props.requestChangeTime Change selected time
 * @param props.setMode           Change current mode
 * @param props.textDate          Locale formatted date
 * @param props.textTime          Locale formatted time
 * @param props.valueDate         String representation of the date
 * @returns                       The component itself
 */
const PickerDateTimeHeader: FC<Props> = ({
  dateMin,
  mode,
  requestChangeDate,
  requestChangeTime,
  setMode,
  textDate,
  textTime,
  valueDate,
}) => {
  const labelDate = translate('DUE_DATE__LABEL_DATE');
  const labelTime = translate('DUE_DATE__LABEL_TIME');

  const isModeDate = mode === 'DATE';
  const isModeTime = mode === 'TIME';

  const valueMinDate = getDateMinValue(dateMin);
  const valueMinTime = getTimeMinValue(dateMin);

  /**
   * The user has changed the selected date
   * (time is unchanged)
   *
   * @param event The event that took place
   */
  const onChangeDate: ChangeEventHandler<HTMLInputElement> = event => {
    requestChangeDate(event.target.value);
  };

  /**
   * The user has changed the selected time
   * (date is unchanged)
   *
   * @param event The event that took place
   */
  const onChangeTime: ChangeEventHandler<HTMLInputElement> = event => {
    requestChangeTime(event.target.value);
  };

  return (
    <Styled.Header>
      <Styled.Label>
        <Styled.LabelText data-is-active={isModeDate}>
          {labelDate}
        </Styled.LabelText>

        <Styled.Input
          data-is-active={isModeDate}
          min={valueMinDate}
          onChange={onChangeDate}
          onFocus={() => setMode('DATE')}
          type={shouldUseNativeControls ? 'date' : 'text'}
          value={shouldUseNativeControls ? valueDate : textDate}
        />
      </Styled.Label>

      <Styled.Label>
        <Styled.LabelText data-is-active={isModeTime}>
          {labelTime}
        </Styled.LabelText>

        <Styled.Input
          data-is-active={isModeTime}
          min={valueMinTime}
          onChange={onChangeTime}
          onFocus={() => setMode('TIME')}
          type={shouldUseNativeControls ? 'time' : 'text'}
          value={textTime}
        />
      </Styled.Label>
    </Styled.Header>
  );
};

export default PickerDateTimeHeader;
