/**
 * @file specifies all the possibilities for error reporting levels
 *
 * In config.ts, we have defined which error level to report and which to log to console
 */

export const LEVEL_DEBUG = 0;
export const LEVEL_INFO = 1;
export const LEVEL_WARNING = 2;
export const LEVEL_ERROR = 3;
export const LEVEL_CRITICAL = 4;
export const LEVEL_NONE = 5;
