import { HashLink } from 'react-router-hash-link';
import { styled } from 'styled-components';

export const CustomHashLink = styled(HashLink)`
  align-items: center;
  display: inline-flex;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
`;
