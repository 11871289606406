/**
 * @file Generate URL for the next search page
 */

import { useLocation } from 'react-router-dom';

import { getSearchTextUrl, getSearchUrl } from '../../../routes/urls/search';
import useParamHotelId from '../../router/params/useParamHotelId';
import useParamSearchPage from '../../router/params/useParamSearchPage';
import useParamSearchTerm from '../../router/params/useParamSearchTerm';

/**
 * Generate URL for the next search page
 *
 * @returns The URL to use for the next page
 */
const useSearchTextUrlNext = (): string => {
  const hotelId = useParamHotelId();
  const location = useLocation();
  const page = useParamSearchPage();
  const searchTerm = useParamSearchTerm();

  if (page === null || searchTerm === null) {
    return getSearchUrl(hotelId);
  }

  return getSearchTextUrl(hotelId, searchTerm, page + 1, location.search);
};

export default useSearchTextUrlNext;
