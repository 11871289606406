/**
 * @file Hook to determine whether to show the right sidebar
 */

import useParamMessageEditMode from '../../../../hooks/router/params/useParamMessageEditMode';
import useIsMobile from '../../../../hooks/useIsMobile';

/**
 * Determine whether to show the right sidebar
 *
 * @returns boolean
 */
const useShouldShowRightSidebar = () => {
  const isMobile = useIsMobile();
  const messageEditMode = useParamMessageEditMode();

  // On desktops, we have message full view in the right sidebar
  if (isMobile === false) {
    return true;
  }

  // On mobile, we render the right sidebar full screen when editing
  return messageEditMode !== null;
};

export default useShouldShowRightSidebar;
