/**
 * @file Hook for handling attachment action (download/preview)
 */

import { useEffect, useState } from 'react';

import { File as CustomFile, Message } from '../../generated/graphql';
import {
  AttachmentAction,
  AttachmentActionType,
} from '../../models/attachment';

import {
  getIsPwaIos,
  getIsReactNativeIos,
  getIsReactNativeWebView,
} from '../../utils/webview/helpers';
import useWebViewDownloadInfo from '../webview/useWebViewDownloadInfo';

import { handlePreview } from './attachmentActionHelpers';
import useAttachmentGetDownloadUrl from './useAttachmentGetDownloadUrl';
import useTrackAttachmentAction from './useTrackAttachmentAction';

/**
 * Hook for handling attachment action (download/preview)
 *
 * @param filename    File name
 * @param key         Key of the attachment to download/preview
 * @param linkElement Anchor element used for attachment action
 * @param messageId   Id of the current message
 * @param actionType  Type of action (download/preview)
 * @returns           Object with necessary props for handling attachment action
 */
const useAttachmentAction = (
  filename: string,
  key: CustomFile['key'],
  linkElement: HTMLAnchorElement | null,
  messageId: Message['id'],
  actionType: AttachmentActionType = 'download',
): Omit<AttachmentAction, 'linkRef'> => {
  // When tmpKey is null don't trigger file preview/download.
  // Using tmpKey since data.fileSignedDownloadUrlForMessage url is dynamic
  // and refocus exchange is triggering the same query on each refocus.
  // Without this variable we could only trigger download/preview once per file.
  const [tmpKey, setTmpKey] = useState<string | null>(key);

  const [isInProgress, setIsInProgress] = useState(false);
  const [link, setLink] = useState<string>();
  const [signedUrl, setSignedUrl] = useState<string>();

  const downloadInfo = useWebViewDownloadInfo();
  const trackAttachmentAction = useTrackAttachmentAction(
    messageId,
    filename,
    actionType,
  );

  const isReactNativeWebView = getIsReactNativeWebView();
  const isReactNativeIos = getIsReactNativeIos();
  const isPwaIos = getIsPwaIos();

  const {
    queryData: { data, error, fetching },
    reExecuteQuery,
  } = useAttachmentGetDownloadUrl(actionType, key, messageId);

  // Set link to signed url sent from the backend
  useEffect(() => {
    if (
      tmpKey &&
      fetching === false &&
      data !== undefined &&
      error === undefined
    ) {
      setSignedUrl(data.fileSignedDownloadUrlForMessage);
      setTmpKey(null);
    }
  }, [tmpKey, data, fetching, error]);

  /**
   * Handle attachment download/preview
   * PWA - Click on current link ref
   * Native (>= v1.8) - Post attachment action event (if action type is preview)
   */
  useEffect(() => {
    if (signedUrl === undefined || linkElement === null) {
      return;
    }

    if (actionType === 'preview') {
      const previewUrl = handlePreview(filename, signedUrl);

      if (previewUrl) {
        setLink(previewUrl);
      }
      return;
    }

    // If action type is download let the browser/web view handle everything
    linkElement.download = filename;
    setLink(signedUrl);

    // Reset state
    setIsInProgress(false);
    setTmpKey(null);

    return () => {
      // Delete locally saved blob on iOS Safari
      if (isReactNativeWebView === false && link && isPwaIos) {
        URL.revokeObjectURL(link);
      }
    };
    // Only trigger this event on signedUrl or linkElement change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedUrl, linkElement]);

  /**
   * Click on the current link ref after link is set
   */
  useEffect(() => {
    if (link === null || linkElement === null) {
      return;
    }

    linkElement.click();

    // Only run this effect on link change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  /**
   * Enable button/link on iOS after download is finished
   * (downloadInfo.url is not undefined)
   */
  useEffect(() => {
    if (signedUrl !== undefined && signedUrl === downloadInfo?.url) {
      setIsInProgress(false);
    }
  }, [downloadInfo, signedUrl]);

  /**
   * Re-execute FileSignedDownloadUrl query on button click
   * (disable button/link on iOS)
   */
  const requestButtonClick = () => {
    reExecuteQuery();
    setTmpKey(key);
    trackAttachmentAction();

    if (isReactNativeIos) {
      setIsInProgress(true);
    }
  };

  return {
    isInProgress,
    link,
    requestButtonClick,
  };
};

export default useAttachmentAction;
