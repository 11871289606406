/**
 * @file Hook that returs esQuery for currently active quick filter
 */
import { SEARCH_PARAM__FILTER__QUICK } from '../../constants/routing/searchParams';

import useParamTopicId from '../router/params/useParamTopicId';

import useSearchParams from '../router/search/useSearchParams';

import { UseESQueryReturn } from './types';
import useElasticSearchQuery from './useEsSmartViewQuery/useElasticSearchQuery';

/**
 * Get esQuery for currenlty active quick filter based
 * on search params
 *
 * @returns esQuery for currently active quick filter
 */
const useQuickFilters = (): UseESQueryReturn | null => {
  const query = useSearchParams();
  const topicId = useParamTopicId();

  const quickFilterId = query.get(SEARCH_PARAM__FILTER__QUICK);

  const esQuery = useElasticSearchQuery({
    context: { topicId },
    id: quickFilterId ?? '',
  });

  if (quickFilterId === null) {
    return null;
  }

  return esQuery;
};

export default useQuickFilters;
