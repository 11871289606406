/**
 * @file Checklist proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage } from './navigators';

const mapperChecklist = {
  // Checklist item created
  [NotificationEvent.ChecklistItemCreated]: navigatorMessage,
  [NotificationEvent.ChecklistItemCreatedForAssignees]: navigatorMessage,
  [NotificationEvent.ChecklistItemCreatedForCreator]: navigatorMessage,

  // Checklist item updated
  [NotificationEvent.ChecklistItemChecked]: navigatorMessage,
  [NotificationEvent.ChecklistItemCheckedForAssignees]: navigatorMessage,
  [NotificationEvent.ChecklistItemCheckedForCreator]: navigatorMessage,
  [NotificationEvent.ChecklistItemUnchecked]: navigatorMessage,
  [NotificationEvent.ChecklistItemUncheckedForAssignees]: navigatorMessage,
  [NotificationEvent.ChecklistItemUncheckedForCreator]: navigatorMessage,

  // Checklist item deleted
  [NotificationEvent.ChecklistItemDeleted]: navigatorMessage,
  [NotificationEvent.ChecklistItemDeletedForAssignees]: navigatorMessage,
  [NotificationEvent.ChecklistItemDeletedForCreator]: navigatorMessage,
};

export default mapperChecklist;
