/**
 * @file contains optimistic update functions for message
 */
import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MessageFullDocument,
  MessageFullQuery,
  MessageFullQueryVariables,
  MessageUpdateMutation,
  MutationMessageUpdateArgs,
} from '../../../../../generated/graphql';

/**
 * Update message cache props
 *
 * @param original Cache data
 * @param updates  New data
 * @returns        Updated cache data
 */
const getUpdates = (
  original: MessageFullQuery['message'] | null,
  updates: MutationMessageUpdateArgs['data'],
): MessageUpdateMutation['messageUpdate'] | null => {
  if (original === null || original === undefined) {
    return null;
  }

  return {
    ...original,
    dueDate: updates.dueDate ?? original.dueDate,
    text: updates.text ?? original.text,
    title: updates.title ?? original.title,
  };
};

/**
 * Optimistically update a message
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Null or optimistic updates
 */
const messageUpdate: OptimisticMutationResolver<
  MutationMessageUpdateArgs,
  MessageUpdateMutation['messageUpdate'] | null
> = (args, cache) => {
  const data = cache.readQuery<MessageFullQuery, MessageFullQueryVariables>({
    query: MessageFullDocument,
    variables: {
      messageId: args.id,
    },
  });

  return getUpdates(data?.message, args.data);
};

export default messageUpdate;
