/**
 * @file contains helper functions for adding/removing members filters search param
 */
import { Location } from 'react-router-dom';

import { SEARCH_PARAM__FILTER__MEMBERS } from '../../../constants/routing/searchParams';
import { User } from '../../../generated/graphql';
import {
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';

/**
 * Gets url that adds `?filterMembers={userId}` search param
 *
 * @param location Location object from the history
 * @param userId   Name of the section
 * @returns        A string in a url format
 */
export const getAddMembersFilterUrl = (
  location: Location,
  userId?: User['id'],
): string => {
  return replaceOrAddParamsToUrl(location, { filterMembers: userId });
};

/**
 * Gets url that removes `?filterMembers={userId}` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getRemoveMembersFilterUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, SEARCH_PARAM__FILTER__MEMBERS);
};

/**
 * Get url to add/remove members filter
 *
 * @param location Location object from the history
 * @param isActive Whether the filter is active
 * @param userId   Id of the user
 * @returns        Url to add/remove members filter
 */
export const getFilterMembersUrl = (
  location: Location,
  isActive: boolean,
  userId?: User['id'],
): string => {
  return isActive
    ? getRemoveMembersFilterUrl(location)
    : getAddMembersFilterUrl(location, userId);
};
