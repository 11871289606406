/**
 * @file The user has successfully unsubscribed, so we update the cache
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  PushInfoDocument,
  PushInfoQuery,
  PushInfoQueryVariables,
  PushUnsubscribeMutation,
  PushUnsubscribeMutationVariables,
} from '../../../../../generated/graphql';

/**
 * The user has successfully unsubscribed, so we update the cache
 *
 * @param _result The response from the server
 * @param args    The arguments passed to the nutation
 * @param cache   urql cache instance
 */
const pushNotificationUnsubscribe: UpdateResolver<
  PushUnsubscribeMutation,
  PushUnsubscribeMutationVariables
> = (_result, args, cache) => {
  cache.updateQuery<PushInfoQuery, PushInfoQueryVariables>(
    {
      query: PushInfoDocument,

      variables: {},
    },
    data => {
      if (data === null) {
        return null;
      }

      const pushSubscriptions = data.pushSubscriptions.filter(
        ({ token }) => token !== args.token,
      );

      return {
        ...data,

        pushSubscriptions,
      };
    },
  );
};

export default pushNotificationUnsubscribe;
