import { styled } from 'styled-components';

export const List = styled.ul`
  align-items: center;
  background-color: var(--color-background);
  border: var(--border-dark);
  border-radius: var(--border-radius-large);
  color: var(--color-icons-inactive);
  display: flex;
  justify-content: center;
  padding: var(--spacing-tiny);

  /**
  * Add margin only if there is one or more items
  */
  & > *:not(:first-child) {
    margin-left: var(--spacing-small);
  }
`;
