import React, { FC } from 'react';

import { ID, State, Text } from '../../../models/checklist';

import ChecklistItemSingle from './ChecklistItemSingle';
import { PropsSingleExisting } from './props';

/**
 * A single list item in the checklist (for existing messages)
 * Extracted like this so that it can be used by both
 * ChecklistDraggable and ChecklistStatic
 *
 * @param props                 Props passed to the component
 * @param props.item            The item to render
 * @param props.item.checked    Whether the item is checked
 * @param props.item.id         The ID of the item
 * @param props.item.text       Item text
 * @param props.requestDelete   Callback to request that an item is removed
 * @param props.requestSetState Callback to request that an item is (un)checked
 * @param props.requestSetText  Callback to request that an item's text is updated
 * @returns                     The component itself
 */
const ListItemExisting: FC<PropsSingleExisting> = ({
  item: { checked, id: itemId, text: itemText },
  requestDelete,
  requestSetState,
  requestSetText,
}) => {
  /**
   * Generate a callback for removing an item
   *
   * @param id The ID of the item
   * @returns  The callback itself
   */
  const helperDelete = (id: ID): (() => void) | null => {
    if (requestDelete === null) {
      return null;
    }

    return () => requestDelete(id);
  };

  /**
   * Generate a callback for setting an item's state
   *
   * @param id The id to generate the callback for
   * @returns  The callback itself
   */
  const helperSetState = (id: ID): ((state: State) => void) | null => {
    if (requestSetState === null) {
      return null;
    }

    return state => requestSetState(id, state);
  };

  /**
   * Generate a callback for setting an item's text
   *
   * @param id The id to generate the callback for
   * @returns  The callback itself
   */
  const helperSetText = (id: ID): ((text: Text) => void) | null => {
    if (requestSetText === null) {
      return null;
    }

    return text => requestSetText(id, text);
  };

  return (
    <ChecklistItemSingle
      isChecked={checked}
      requestDelete={helperDelete(itemId)}
      requestSetState={helperSetState(itemId)}
      requestSetText={helperSetText(itemId)}
      text={itemText}
    />
  );
};

export default ListItemExisting;
