/**
 * @file Contains a hook that uses links to handle modal visibility
 */

import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ModalId, ModalParams } from '../../models/modal';
import { getSearchParamModalId } from '../../routes/helpers/searchParams';

import { getCloseModalUrl, getOpenModalUrl } from './helpers';

/**
 * A hook that can open and close modals by using deep links
 *
 * @returns Callbacks to open and close a modal, and the id of currently open modal
 */
export const useModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const openModalId = getSearchParamModalId(location);

  const openModal = useCallback(
    <T extends ModalId>(modalId: T, paramConfig?: ModalParams[T]) => {
      const url = getOpenModalUrl(location, modalId, paramConfig);
      navigate(url);
    },
    [location, navigate],
  );

  const closeModal = useCallback(() => {
    if (openModalId === null) {
      return;
    }
    navigate(getCloseModalUrl(location), { replace: true });
  }, [location, navigate, openModalId]);

  return { closeModal, openModal, openModalId };
};
