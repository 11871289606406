/**
 * @file Contains helpers for MetaLabels components and hooks
 */

import {
  MODE_CREATE,
  MODE_EDIT,
  MODE_VIEW,
  ModeLabels,
} from '../../constants/metaModesLabels';
import { LabelBasicFragment } from '../../generated/graphql';
import {
  getCanCreateLabel,
  getCanUpdateLabel,
} from '../../utils/permissions/label';

/**
 * Get whether the current labels mode is accessible
 *
 * @param mode         Current label mode
 * @param fetching     Whether labels are fetching
 * @param labelEditing Label we want to edit
 * @returns            Whether the user can access the current mode
 */
export const getIsLabelModeAccessible = (
  mode: ModeLabels,
  fetching: boolean,
  labelEditing: LabelBasicFragment | null,
) => {
  const accessibleMap: Record<ModeLabels, boolean> = {
    [MODE_CREATE]: getCanCreateLabel() === true,
    [MODE_EDIT]:
      fetching === false &&
      labelEditing !== null &&
      getCanUpdateLabel() === true,
    [MODE_VIEW]: true, // no restrictions
  };

  return accessibleMap[mode];
};
