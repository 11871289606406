import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__DESCRIPTION } from '../../../../constants/routing/hashLinks';

import * as Styled from './styled';

type Props = {
  children: string;
  isDeactivated: boolean;
  shouldClamp: boolean;
  shouldRenderLinks: boolean;
};

/**
 * A single message's text
 *
 * @param props                   Props passed to the component
 * @param props.children          The text to show
 * @param props.isDeactivated     Whether the author is deactivated
 * @param props.shouldRenderLinks Whether to render links as <a />
 * @returns                       The component itself
 */
const MessageText: FC<Props> = ({
  children,
  isDeactivated,
  shouldRenderLinks,
}) => {
  /**
   * Not using shouldClamp from props because Safari has a bug
   * It's manifested with jumbled text for messages with <ul>/<ol> elements
   *
   * @todo Revisit this in the future
   * @see https://gastfreund.atlassian.net/browse/HOT-2218
   */
  const shouldClamp = false;

  return (
    <div id={HASH_MESSAGE__INFO__DESCRIPTION}>
      <Styled.Text
        data-is-deactivated={isDeactivated}
        data-should-clamp={shouldClamp}
        shouldRenderLinks={shouldRenderLinks}
      >
        {children}
      </Styled.Text>
    </div>
  );
};

export default MessageText;
