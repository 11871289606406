/**
 * @file Get whether push notification reception is supported
 */

import useIsFirebaseSupported from './useIsFirebaseSupported';
import useIsPushSupported from './useIsPushSupported';

/**
 * Get whether push notification reception is supported
 * (includes Firebase support check and permission check)
 *
 * @returns Whether it is
 */
const useIsSupported = (): boolean => {
  const hasFirebaseSupport = useIsFirebaseSupported();
  const hasPushSupport = useIsPushSupported();

  return hasPushSupport === true && hasFirebaseSupport === true;
};

export default useIsSupported;
