import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__ASSIGNEE } from '../../constants/routing/hashLinks';

import {
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../generated/graphql';

import translate from '../../i18n/translate';
import MessageAssignees from '../Common/MessageAssignees';

import MessageSection from './MessageSection';
import useMessageSectionUrl from './hooks/useMessageSectionUrl';

type Props = {
  canAssignUsers: boolean;
  groups: UserGroupBasicFragment[];
  users: UserBasicFragment[];
};

/**
 * Section in the message that displays the assignees
 *
 * @param props                Props passed to the component
 * @param props.canAssignUsers Whether the user can assign users
 * @param props.groups         User groups list
 * @param props.users          Users/members list
 * @returns                    The component itself
 */
const MessageSectionAssignees: FC<Props> = ({
  canAssignUsers,
  groups,
  users,
}) => {
  const linkTo = useMessageSectionUrl({
    mode: 'assign',
  });

  return (
    <MessageSection
      heading={translate('MESSAGE__HEADING__ASSIGNEES')}
      /**
       * Using "raw" ID constants (without useComponentId()) in order to match them
       * with ones in <HashLink> in <MessageSingle/> component. If we used useComponentId(),
       * every time we would create unique ID value and could not scroll proper element into view.
       */
      id={HASH_MESSAGE__INFO__ASSIGNEE}
      linkTo={canAssignUsers ? linkTo : undefined}
    >
      <MessageAssignees groups={groups} users={users} />
    </MessageSection>
  );
};

export default MessageSectionAssignees;
