import { styled } from 'styled-components';

import CommonAvatar from '../../Common/Avatar';

// Wrapper around everything;
// It's a label so that clicking anywhere toggles the checkbox
export const Label = styled.label`
  align-items: center;
  display: flex;
  width: 100%;
`;

// User avatar
export const Avatar = styled(CommonAvatar)`
  flex-shrink: 0;
`;

// User name
export const Name = styled.p`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 var(--spacing-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
