/**
 * @file Helpers for TaskCard component
 */

import intlPluralFormatter from '../../utils/intlPluralFormat';

/**
 * Get the tooltip for elements which show the surplus or items that are not visible.
 *
 * @param items      Array of strings we want to slice and format
 * @param sliceCount Slice argument
 * @returns          A pluralized string
 */
export const getSurplusTooltipText = (items: string[], sliceCount: number) => {
  const tooltip = intlPluralFormatter(items.slice(sliceCount));

  return tooltip;
};
