import React, { FC } from 'react';

import * as Styled from './styled';

export type Props = {
  className?: string;
  color?: string;
  max: number;
  value: number;
};

/**
 * Common progressbar component, utilizing HTML progress element
 * (min value is always 0)
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.color     The color to use for the bar
 * @param props.max       Maximum progress value
 * @param props.value     Current progress value
 * @returns               The component itself
 */
const Progress: FC<Props> = ({
  className,
  color = 'var(--color-primary-light)',
  max,
  value,
}) => {
  return (
    <Styled.ProgressBar
      className={className}
      data-color={color}
      max={max}
      value={value}
    />
  );
};

export default Progress;
