/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import {
  createSearchParams,
  Location,
  URLSearchParamsInit,
} from 'react-router-dom';

import {
  SEARCH_PARAM__COMPOSE,
  SEARCH_PARAM__COMPOSE_MODE,
} from '../../../constants/routing/searchParams';
import { Hotel, Template } from '../../../generated/graphql';

import { ComposeModeParam } from '../../../models/pageParams';
import { reportError } from '../../../services/reporting';

import {
  parseUrlString,
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';
import { getSettingsTemplatesUrl } from '../settings';

/**
 * Gets url that adds `?compose=open` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getOpenComposeUrl = (location: Location): string => {
  return replaceOrAddParamsToUrl(location, { compose: 'open' });
};

/**
 * Generate URL for template edit
 *
 * @param hotelId    The ID of the currently open hotel
 * @param isTablet   Whether the use is on a tablet
 * @param templateId The ID of the template to generate the url for
 * @returns          The generated URL
 */
export const getOpenComposeUrlTemplate = (
  hotelId: Hotel['id'] | null,
  isTablet: boolean,
  templateId: Template['id'],
) => {
  if (hotelId === null) {
    reportError('Hotel ID not found: ' + window.location.href);
    return '/';
  }

  const url = getSettingsTemplatesUrl(hotelId, isTablet, templateId);
  const [pathname, search] = parseUrlString(url);

  const paramsConfig: URLSearchParamsInit = { [SEARCH_PARAM__COMPOSE]: 'open' };

  const params = createSearchParams(paramsConfig);
  const paramsFinal = `${search ? `${search}&` : '?'}${params.toString()}`;

  return `${pathname}${paramsFinal}`;
};

/**
 * Gets url that removes `?compose=open` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getCloseComposeUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, 'compose');
};

/**
 * Gets url that adds `?composeMode={section}` search param
 *
 * @param location    Location object from the history
 * @param sectionName Name of the section
 * @returns           A string in a url format
 */
export const getOpenComposeSectionUrl = (
  location: Location,
  sectionName: ComposeModeParam,
): string => {
  return replaceOrAddParamsToUrl(location, { composeMode: sectionName });
};

/**
 * Gets url that removes `?composeMode={section}` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getCloseComposeSectionUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, SEARCH_PARAM__COMPOSE_MODE);
};
