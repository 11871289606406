/**
 * @file Helper functions for the message details header
 */

import React, { ReactNode } from 'react';
import { Location } from 'react-router-dom';

import translate from '../../../i18n/translate';
import { MessageEditModeParam } from '../../../models/pageParams';
import { getSearchParamLabelMode } from '../../../routes/helpers/searchParams';
import { getLabelModeUrl } from '../../../routes/urls/label';
import { getMessageDetailsUrl } from '../../../routes/urls/message';
import { getLabelsHeading } from '../helpers';
import ContentRightLabel from '../mobileContentRight/label';

/**
 * Get the back URL for the labels mode
 *
 * @param messageDetailsUrl URL of the message details
 * @param location          History location object
 * @returns                 Back URL for the labels mode
 */
export const getLabelsModeBackUrl = (
  messageDetailsUrl: string,
  location: Location,
) => {
  const labelMode = getSearchParamLabelMode(location, 'messageFull');

  if (labelMode === null || labelMode === 'MODE_VIEW') {
    return messageDetailsUrl;
  }

  return getLabelModeUrl(location, 'messageFull', 'MODE_VIEW');
};

/**
 * Get the composed title of different translations for pages that need it
 *
 * @returns Title text
 */
const getTitle = () => {
  return `${translate('GENERAL__TITLE')} & ${translate(
    'GENERAL__DESCRIPTION',
  )}`;
};

type Callback = (
  hotelId: string | null,
  isTablet: boolean,
  location: Location,
  messageId: string | null,
  topicId: string | null,
) => {
  title: string;
  backUrl: string;
  contentRight: ReactNode;
};

export const mapping = new Map<MessageEditModeParam | null, Callback>([
  [
    'assign',
    (hotelId, isTablet, location, messageId, topicId) => ({
      backUrl: getMessageDetailsUrl(
        hotelId,
        isTablet,
        location,
        messageId,
        topicId,
      ),
      contentRight: null,
      title: translate('ASSIGNMENT__EDIT__PLACEHOLDER'),
    }),
  ],
  [
    'date',
    (hotelId, isTablet, location, messageId, topicId) => ({
      backUrl: getMessageDetailsUrl(
        hotelId,
        isTablet,
        location,
        messageId,
        topicId,
      ),
      contentRight: null,
      title: translate('DUE_DATE__PLACEHOLDER'),
    }),
  ],
  [
    'description',
    (hotelId, isTablet, location, messageId, topicId) => ({
      backUrl: getMessageDetailsUrl(
        hotelId,
        isTablet,
        location,
        messageId,
        topicId,
      ),
      contentRight: null,
      title: getTitle(),
    }),
  ],
  [
    'label',
    (hotelId, isTablet, location, messageId, topicId) => {
      const messageDetailsUrl = getMessageDetailsUrl(
        hotelId,
        isTablet,
        location,
        messageId,
        topicId,
      );

      return {
        backUrl: getLabelsModeBackUrl(messageDetailsUrl, location),
        contentRight: <ContentRightLabel where="messageFull" />,
        title: getLabelsHeading(location, 'messageFull'),
      };
    },
  ],
  [
    'title',
    (hotelId, isTablet, location, messageId, topicId) => ({
      backUrl: getMessageDetailsUrl(
        hotelId,
        isTablet,
        location,
        messageId,
        topicId,
      ),
      contentRight: null,
      title: getTitle(),
    }),
  ],
]);
