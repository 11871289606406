import React, { FC } from 'react';

import Chevron from '../../Chevron';
import Spinner from '../../Spinner';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  isOpen: boolean;
};

/**
 * Chevron icon for the select component
 * (or spinner while loading)
 * (or nothing if disabled)
 *
 * @param props            Props passed to the component
 * @param props.isDisabled Whether the dropdown is disabled
 * @param props.isLoading  Whether the data for dropdown list is still loading
 * @param props.isOpen     Whether the dropdown is open
 * @returns                The component itself
 */
const SelectChevron: FC<Props> = ({ isDisabled, isLoading, isOpen }) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (isDisabled) {
    return null;
  }

  return <Chevron direction={isOpen ? 'up' : 'down'} size={10} />;
};

export default SelectChevron;
