import React, { FC } from 'react';

import { InputFileProps } from '../../../models/attachment';

import * as Styled from './styled';

export type Props = {
  inputFileProps: InputFileProps;
};

/**
 * The input for attaching a file
 *
 * @param props                Props passed to the component
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @returns                    The component itself
 */
const InputFileAttachment: FC<Props> = ({ inputFileProps }) => {
  return <Styled.FileUploadInput type="file" {...inputFileProps} />;
};

export default InputFileAttachment;
