import { ListItem, ListItemIcon } from '@mui/material';
import React, { FC } from 'react';

import { NavItem } from '../types';

import RailIcon from './RailIcon';
import * as Styled from './styled';

export type Props = {
  isSelected: boolean;
  item: NavItem;
};

/**
 * List item used in the rail for desktop navigation
 *
 * @param props            Props passed to the component
 * @param props.isSelected Whether rail list item is selected
 * @param props.item       Item object for rail navigation
 * @returns                The component itself
 */
const RailListItem: FC<Props> = ({ isSelected, item }) => {
  const { label, id, to } = item;

  return (
    <ListItem disablePadding={true} key={id}>
      <Styled.StyledLink
        aria-current={isSelected === true ? 'page' : false}
        replace={true}
        to={to}
      >
        <ListItemIcon>
          <RailIcon item={item} />
        </ListItemIcon>
        <Styled.RailListItemLabel
          // Prevents wrapping child in typography
          disableTypography={true}
          primary={label}
          title={label}
        />
      </Styled.StyledLink>
    </ListItem>
  );
};

export default RailListItem;
