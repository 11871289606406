import { AvatarGroup, AvatarGroupProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import { colorPrimary } from '../../../styles/mui/colors';
import {
  colorNeutralFocusStyle,
  colorNeutralHoverStyle,
  colorPrimaryFocusStyle,
  colorPrimaryHoverStyle,
} from '../../../styles/mui/components/shared';

export const MuiAvatarGroup = styled(AvatarGroup)<AvatarGroupProps & LinkProps>(
  {
    '&:focus-visible .MuiAvatar-root': {
      '&[data-is-pending="true"]': {
        backgroundColor: colorNeutralFocusStyle.backgroundColor,
      },
      backgroundColor: colorPrimaryFocusStyle.backgroundColor,
      borderColor: colorPrimary[100],
    },
    '&:hover .MuiAvatar-root': {
      '&[data-is-pending="true"]': {
        backgroundColor: colorNeutralHoverStyle.backgroundColor,
      },
      backgroundColor: colorPrimaryHoverStyle.backgroundColor,
      borderColor: colorPrimary[50],
    },
    marginRight: 'auto',
    transform: 'translateZ(0)', // Added to fix Safari issue with disappearing avatars
  },
);
