import React from 'react';

import HotelErrorWrapper from '../../../components/Errors/HotelErrorWrapper';
import useLogout from '../../../hooks/useLogout';

import translate from '../../../i18n/translate';

/**
 * Screen that shows an error when topics are not fetched on initial launch
 *
 * @returns The component itself
 */
const TopicsNotFetchedError = () => {
  const logout = useLogout();
  return (
    <HotelErrorWrapper
      actionButton={{
        onClick: logout,
        text: translate('GENERAL__BACK_TO_LOGIN'),
      }}
      description={translate('ERROR__TOPICS__NOT_FETCHED__DESCRIPTION')}
      title={translate('GENERAL__OOPS')}
    />
  );
};

export default TopicsNotFetchedError;
