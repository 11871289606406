/**
 * @file contains hook for requesting the current user data
 */

import { useQuery } from 'urql';

import { MeDocument, UserMeFragment } from '../generated/graphql';

/**
 * Custom hook for getting current user data.
 *
 * @returns Current user data or null
 */
const useCurrentUser = (): UserMeFragment | null => {
  const [{ data }] = useQuery({
    query: MeDocument,
    variables: {},
  });

  return data?.me ?? null;
};

export default useCurrentUser;
