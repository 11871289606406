/**
 * @file Helpers for header mobile
 */
import { NavigateFunction } from 'react-router-dom';

/**
 * Go to previous route from history or backUrl
 *
 * @param backUrl  Url to go back to
 * @param navigate Navigate function from react-router-dom
 */
export const goBack = (backUrl: string | true, navigate: NavigateFunction) => {
  if (backUrl === true) {
    navigate(-1);
  } else {
    navigate(backUrl);
  }
};
