import { styled } from 'styled-components';

import KpiSingle from './KpiSingle';

/**
 * It's used in 2 (or more) components.
 * Extracted here in order not to repeat code.
 */
export const KpiSingleCard = styled(KpiSingle)`
  padding-bottom: 0;
  text-align: left;
`;
