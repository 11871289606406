import { styled } from 'styled-components';

import SkeletonLine from '../SkeletonLine';

// Message title skeleton
export const Title = styled(SkeletonLine)`
  height: var(--spacing-medium);
  width: 40%;
`;

// Message description skeleton
export const Description = styled(SkeletonLine)`
  height: var(--spacing-medium);
  width: 60%;
`;

// Wrapper around a message section
export const SectionWrapper = styled.section`
  align-items: center;
  border-top: var(--border-pale);
  padding: var(--spacing-medium);
`;

// Section heading
export const SectionHeading = styled.h2`
  align-items: center;
  display: flex;
  font-size: var(--font-baseline);
  font-weight: var(--font-weight-heading);
  gap: var(--spacing-small);
  margin: var(--spacing-tiny) 0;
`;

// List of assignees
export const Assignees = styled.ul`
  display: flex;
  margin: var(--spacing-tiny) 0;
`;

// A single assignee avatar wrapper
export const AssigneesItem = styled.li`
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-right: 1px;
`;

// Skeleton for due date
export const DateDue = styled(SkeletonLine)`
  height: var(--spacing-medium);
`;

// Wrapper for the creator (avatar + date)
export const CreatorWrapper = styled.div`
  align-items: center;
  display: flex;
`;

// Message creation date
export const CreationDate = styled(SkeletonLine)`
  height: var(--spacing-medium);
  margin-left: var(--spacing-tiny);
`;
