/**
 * @file All available illustrations
 */

export const ILLUSTRATION__ATTACHMENT = 'attachment.png';
export const ILLUSTRATION__CONCIERGE = 'concierge.png';
export const ILLUSTRATION__INFO = 'info.png';
export const ILLUSTRATION__REQUEST = 'request.png';
export const ILLUSTRATION__REQUEST_ANSWERED = 'request_answered.png';
export const ILLUSTRATION__REQUEST_UNANSWERED = 'request_unanswered.png';
export const ILLUSTRATION__SEARCH = 'search.png';
export const ILLUSTRATION__TASK = 'task.png';
export const ILLUSTRATION__TOPIC = 'topic.png';
