/**
 * @file contains redux actions related to draftLabels state
 */

import {
  DRAFTS__LABEL__ADD,
  DRAFTS__LABEL__REMOVE,
  DRAFTS__LABEL__SAVE,
} from '../../constants/actionTypes';

import {
  DraftActionLabelAdd,
  DraftActionLabelRemove,
  DraftActionLabelSave,
} from '../../models/draftLabel';

/**
 *
 * CREATE actions
 *
 */

/**
 * Action to add a label to a particular draft
 *
 * @param parentId The ID of the message this is a reply to
 * @param labelId  The ID of the label to add to a draft
 * @returns        The action itself
 */
export const addDraftLabelRequest = (
  parentId: string,
  labelId: string,
): DraftActionLabelAdd => ({
  labelId,
  parentId,
  type: DRAFTS__LABEL__ADD,
});

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftLabelRequest = (
  parentId: string,
): DraftActionLabelSave => ({
  parentId,
  type: DRAFTS__LABEL__SAVE,
});

/**
 *
 * DELETE actions
 *
 */

/**
 * Action to remove a label from a particular draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param labelId  The ID of the label to remove from a draft
 * @returns        The action itself
 */
export const removeDraftLabelRequest = (
  parentId: string,
  labelId: string,
): DraftActionLabelRemove => ({
  labelId,
  parentId,
  type: DRAFTS__LABEL__REMOVE,
});
