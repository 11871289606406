import React, { FC } from 'react';

import { getIsMuiSymbolIconName } from './helpers';
import { mappingMaterialSymbolIcon, muiIconMappings } from './mapping';
import { IconPropsExtended, MuiIconName, MuiSymbolIconName } from './types';

type Props = {
  icon: MuiIconName | MuiSymbolIconName;
} & IconPropsExtended;

/**
 * Standardized MUI Icon component
 *
 * @param props      Props passed to the component
 * @param props.icon The icon to display
 * @returns          The component itself
 */
const MuiIcon: FC<Props> = ({ icon, ...props }) => {
  const isSymbolIcon = getIsMuiSymbolIconName(icon);

  const IconComponent = isSymbolIcon
    ? mappingMaterialSymbolIcon[icon]
    : muiIconMappings[icon];

  return <IconComponent {...props} />;
};

export default MuiIcon;
