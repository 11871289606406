import { styled } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-shadow);
  border-radius: calc(var(--border-radius-small) - 1px);
  display: none;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding-right: var(--spacing-tiny);
  position: absolute;
  width: 100%;

  @media (hover: none) {
    background-color: transparent;
    display: flex;
    position: relative;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    width: fit-content;
  }
`;
