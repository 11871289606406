/**
 * @file contains helpers for Editor ButtonControl
 */
/**
 * Get which fill to use for button svg
 *
 * @param isDisabled Whether the button is disabled
 * @returns          The variable to use for button color
 */
export const getIconColor = (isDisabled: boolean): string => {
  if (isDisabled) {
    return '--color-icons-disabled';
  }

  return '--color-primary-lighter';
};
