/**
 * @file Handle logout procedure
 *
 * It's not as simple as navigating to a logout route,
 * We need to clear IAM storage, cookie storage and Hotelboard API access token
 */

import { useMutation } from 'urql';

import { LogoutDocument, PushUnsubscribeDocument } from '../generated/graphql';
import { reportError } from '../services/reporting';
import { logoutInIAM } from '../utils/auth';
import {
  getTokenStored,
  setSubscriptionPaused,
} from '../utils/storage/notifications';

/**
 * Unsubscribe the user from push notifications,
 * but only if there's a Firebase token stored in cookies
 *
 * Even so, it could happen that this cookie is not used
 * for this user's push subscriptions
 *
 * It could be used for other users on the same device though,
 * meaning we need to handle mutation failures which come out of this
 *
 * Additionally, we don't clear the Firebase token from cookie storage
 * if it's unused, because it might be used (in a few seconds),
 * when/once the user grants the permission for us to send push
 *
 * @returns ThePromise
 */
const usePushUnsubscribe = () => {
  const [, pushUnsubscribeMutation] = useMutation(PushUnsubscribeDocument);

  return () => {
    // We're only concerned with stored Firebase token,
    // as if we don't have it in cookies, the user is not subscribed
    const firebaseToken = getTokenStored();

    if (firebaseToken === null) {
      return Promise.resolve(null);
    }

    // This promise never rejects
    // Instead, it catches mutations errors and reports from within
    // That way, we encapsulate the functionality,
    // And don't rely on nested .catch within useLogout
    return new Promise(resolve => {
      pushUnsubscribeMutation({ token: firebaseToken })
        .then(token => resolve(token))
        // It can happen that the token stored in the cookies
        // is not used for any active push subscriptions
        // So we need to handle this situations as well
        .catch((error: string) => {
          reportError(error);
          resolve(null);
        });
    });
  };
};

/**
 * Make all the needed API requests to log out from Hotelboard
 *
 * @todo This should ideally use a beacon for a request,
 * but beacon cannot accept any headers, just type, so this is impossible atm
 * todo is left just in case in the future we find a better way to make a req to logout
 * and avoid a request wait time for the end user
 * @returns The callback to log the user out
 */
const useLogout = (): (() => void) => {
  const pushUnsubscribe = usePushUnsubscribe();
  const [, logoutMutation] = useMutation(LogoutDocument);

  return () => {
    setSubscriptionPaused(true);

    pushUnsubscribe()
      .then(() => logoutMutation({}))
      .catch((error: string) => reportError(error))
      .finally(() => logoutInIAM());
  };
};

export default useLogout;
