import React, { FC } from 'react';

import NAMES, { fillStrokeMapping, Segments } from '../../../constants/icons';

import * as Styled from './styled';

type Props = {
  className?: string;
  color?: string;
  colorHover?: string;
  height?: number;
  name: string;
  width?: number;
};

const defaultSize = 30;
const defaultColor = 'currentColor';

/**
 * Get icon color
 * Some of them color fill and others stroke (some even both)
 *
 * @param color       The color to use if it's intended for this
 * @param which       Whether we're checking fill or stroke
 * @param preferences Color preferences
 * @returns           Which color to use
 */
const getColor = (
  color: string,
  which: Segments,
  preferences?: Set<Segments>,
): string => {
  return preferences?.has(which) ? color : '';
};

/**
 * General Icon component that loads SVG icons from assets.
 *
 * @param props            props passed to the component
 * @param props.className  styled-components generated class name, needed for styling
 * @param props.color      color of the icon
 * @param props.colorHover The color to show when hovering over the icon
 * @param props.height     icon height, in px
 * @param props.name       which icon to show within the button
 * @param props.width      icon width, in px
 * @returns                the component itself
 */
const Icon: FC<Props> = ({
  className,
  color = defaultColor,
  colorHover = color,
  height = defaultSize,
  name,
  width = defaultSize,
}) => {
  const preferences = fillStrokeMapping.get(name);

  return (
    <Styled.Svg
      className={className}
      data-color-hover-fill={getColor(colorHover, 'fill', preferences)}
      data-color-hover-stroke={getColor(colorHover, 'stroke', preferences)}
      data-color-normal-fill={getColor(color, 'fill', preferences)}
      data-color-normal-stroke={getColor(color, 'stroke', preferences)}
      data-dimensions-height={height}
      data-dimensions-width={width}
      data-testid={name}
      role="img"
    >
      <use xlinkHref={`#${name}`} />
    </Styled.Svg>
  );
};

export { NAMES }; // A convenience export, so Icon and NAMES can both be imported from a single file
export default Icon;
