/**
 * @file Helper functions for ButtonSingle component
 */

import { CssVariable } from '../../../styles/cssTypes';

import { ButtonProps, Style, StyleKeys } from './types';

/**
 * The styles that vary based on button usage
 */
const styles: Record<StyleKeys, Style> = {
  // Positive confirmation
  'confirm-ghost': {
    colorBackground: 'transparent',
    colorBorder: 'transparent',
    colorHover: 'var(--color-primary-lighter)',
    colorText: 'var(--color-primary-lighter)',
  },
  'confirm-outline': {
    colorBackground: 'transparent',
    colorBorder: 'var(--color-primary-light)',
    colorHover: 'var(--color-primary-lighter)',
    colorText: 'var(--color-primary-light)',
  },
  'confirm-solid': {
    colorBackground: 'var(--color-primary-light)',
    colorBorder: 'var(--color-primary-light)',
    colorHover: 'var(--color-primary-lighter)',
    colorText: 'var(--color-text-alt)',
  },

  // Default button look
  'default-ghost': {
    colorBackground: 'transparent',
    colorBorder: 'transparent',
    colorHover: 'var(--color-primary-light)',
    colorText: 'var(--color-primary-light)',
  },
  'default-outline': {
    colorBackground: 'transparent',
    colorBorder: 'var(--color-text-regular)',
    colorHover: 'var(--color-primary-light)',
    colorText: 'var(--color-text-regular)',
  },
  'default-solid': {
    colorBackground: 'var(--color-background)',
    colorBorder: 'var(--color-text-light)',
    colorHover: 'var(--color-primary-light)',
    colorText: 'var(--color-text-regular)',
  },

  // Destructive actions, like delete confirmation
  // Not sorting alphabetically because it is sorted by what the buttons look like
  // (no background -> outline -> full background)
  // eslint-disable-next-line sort-keys
  'danger-ghost': {
    colorBackground: 'transparent',
    colorBorder: 'transparent',
    colorHover: 'var(--color-button-danger)',
    colorText: 'var(--color-button-danger)',
  },
  'danger-outline': {
    colorBackground: 'transparent',
    colorBorder: 'var(--color-button-danger)',
    colorHover: 'var(--color-button-danger)',
    colorText: 'var(--color-button-danger)',
  },
  'danger-solid': {
    colorBackground: 'var(--color-button-danger)',
    colorBorder: 'var(--color-button-danger)',
    colorHover: 'var(--color-button-danger)',
    colorText: 'var(--color-text-alt)',
  },
};

/**
 * Get the button background color
 *
 * @param props Props passed to the component
 * @returns     The variable to use
 */
export const getColorBackground = (props: ButtonProps): CssVariable => {
  return styles[props['data-usage']].colorBackground;
};

/**
 * Determining which color the border will be,
 * depending on current background color
 *
 * @param props Props passed to the component
 * @returns     The variable to use
 */
export const getColorBorder = (props: ButtonProps): CssVariable => {
  return styles[props['data-usage']].colorBorder;
};

/**
 * Determining which color to use when the button is hovered
 *
 * @param props Props passed to the component
 * @returns     The variable to use
 */
export const getColorHover = (props: ButtonProps): CssVariable => {
  return styles[props['data-usage']].colorHover;
};

/**
 * Get text color for the button
 *
 * @param props Props passed to the component
 * @returns     The variable to use
 */
export const getColor = (props: ButtonProps): CssVariable => {
  return styles[props['data-usage']].colorText;
};
