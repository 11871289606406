/**
 * @file Hook for handling switching between different label popup modes
 */

import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MODE_CREATE,
  MODE_EDIT,
  MODE_VIEW,
  ModeLabels,
} from '../../constants/metaModesLabels';
import { Label } from '../../generated/graphql';
import { useLabelAccessible } from '../../hooks/label/useLabelAccessible';
import { LabelUsage } from '../../models/message';
import { getSearchParam } from '../../routes/helpers/searchParams';
import { getLabelModeUrl, labelParamConfig } from '../../routes/urls/label';
import {
  getCanCreateLabel,
  getCanUpdateLabel,
} from '../../utils/permissions/label';

import { getIsLabelModeAccessible } from './helpers';

type Return = [
  Label['id'] | null,
  ModeLabels,
  {
    setModeCreate: (() => void) | null;
    setModeEdit: ((labelId: Label['id']) => void) | null;
    setModeView: () => void;
  },
];

/**
 * Custom hook for handling switching between
 * different label popup modes in the compose and messageFull component
 *
 * @param where Where the hook is used
 * @returns     Info needed to use the hook
 */
const useLabelsMode = (where: LabelUsage): Return => {
  const location = useLocation();
  const navigate = useNavigate();

  const { labelId: labelIdParamKey, labelMode: labelModeParamKey } =
    labelParamConfig[where];

  const labelId = getSearchParam(labelIdParamKey, location);
  const mode = getSearchParam(labelModeParamKey, location) ?? MODE_VIEW;

  const [labelEditing, fetching] = useLabelAccessible(labelId);

  /**
   * Changes view mode
   *
   * @param nextMode    The mode we want to switch to
   * @param editLabelId Id of the label we want to edit
   */
  const handleModeChange = useCallback(
    (nextMode: ModeLabels, editLabelId?: Label['id']) => {
      navigate(getLabelModeUrl(location, where, nextMode, editLabelId), {
        replace: true,
      });
    },
    [location, navigate, where],
  );

  useEffect(() => {
    /**
     * If the user does not have enough permissions or the label we want to edit
     * was deleted, then we switch to MODE_VIEW
     */
    if (getIsLabelModeAccessible(mode, fetching, labelEditing) === false) {
      handleModeChange(MODE_VIEW);
    }
  }, [fetching, handleModeChange, labelEditing, mode]);

  return [
    labelId,
    mode,
    {
      setModeCreate: getCanCreateLabel()
        ? () => handleModeChange(MODE_CREATE)
        : null,
      setModeEdit: getCanUpdateLabel()
        ? (editLabelId: Label['id']) => {
            handleModeChange(MODE_EDIT, editLabelId);
          }
        : null,
      setModeView: () => {
        handleModeChange(MODE_VIEW);
      },
    },
  ];
};

export default useLabelsMode;
