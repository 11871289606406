/**
 * @file Optimistically update message status
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MessageStatusUpdateMutation,
  MutationMessageStatusUpdateArgs,
} from '../../../../../generated/graphql';
import { messageStatusUpdate as messageStatusUpdater } from '../../updates/utils/updaters/messages/messageStatusUpdate';
/**
 * Optimistically update a message
 *
 * @param args  Arguments of a graphQL operation
 * @param cache GraphQL cache
 * @returns     The updated message
 */
const messageStatusUpdate: OptimisticMutationResolver<
  MutationMessageStatusUpdateArgs,
  MessageStatusUpdateMutation['messageStatusUpdate'] | null
> = (args, cache) => {
  messageStatusUpdater(args, cache);
  return {
    __typename: 'Message',
    id: args.id,
    status: args.status,
  };
};

export default messageStatusUpdate;
