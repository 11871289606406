/**
 * @file Cache addition for paginated queries
 */

import { Cache, FieldArgs, FieldInfo } from '@urql/exchange-graphcache';

type Params = {
  cache: Cache;
  filterQueries: (field: FieldInfo) => boolean;
  updateQuery: (variables: FieldArgs) => void;
};

/**
 * Cache addition for paginated queries
 *
 * @param args               Args passed to the updater
 * @param args.cache         Graphql cache object
 * @param args.filterQueries Filter queries by parent query ID eg. filter messages by topicId
 * @param args.updateQuery   Query updater function
 */
const paginatedQueryCreation = ({
  cache,
  filterQueries,
  updateQuery,
}: Params): void => {
  /**
   * Inspect all queries and filter for the ones that match
   */
  const fields = cache.inspectFields('Query').filter(filterQueries);

  /**
   * Handle the case where fields length is 0
   * maybe write to cache but it is not needed in our app atm
   */
  if (fields.length === 0) {
    return;
  }

  /**
   * Just select the last query and add to it
   */
  const field = fields[0];

  updateQuery(field.arguments);
};

export default paginatedQueryCreation;
