import React, { FC } from 'react';

import {
  MessageStatus,
  MessageStatus as MessageStatusEnum,
} from '../../../generated/graphql';
import translate from '../../../i18n/translate';

import * as Styled from './styled';

export type Props = {
  className?: string;
  date?: string | null;
  status?: MessageStatusEnum | null;
};

/**
 * Show due date for the message, if it has one
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @param props.date      The message due date
 * @param props.status    Current status value
 * @returns               The component itself
 */
const MessageDateDue: FC<Props> = ({ className, date = null, status }) => {
  if (date === null) {
    return null;
  }

  const dateObj = new Date(date);
  const isDateValid = Number.isNaN(dateObj.getTime()) === false;
  const isLate = isDateValid && dateObj.getTime() < Date.now();
  const isResolved = status === MessageStatus.Resolved;

  return (
    <Styled.DateDue
      className={className}
      data-is-late={isLate}
      data-is-resolved={isResolved}
      date={date}
      label={translate('MESSAGE__HEADING__DATE_DUE')}
    />
  );
};

export default MessageDateDue;
