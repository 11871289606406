import React, { FC } from 'react';

import { Props as ButtonProps } from '../../ButtonSingle';

import * as Styled from './styled';

export type Props = {
  buttons: ButtonProps[];
};

/**
 * All the buttons within a modal's footer
 *
 * @param props         Props passed to the component
 * @param props.buttons The buttons to show
 * @returns             The component itself
 */
const ModalButtons: FC<Props> = ({ buttons }) => {
  // TODO: Revisit.
  // Force a re-render of the entire component when a button key changes,
  // in order to fix a Safari bug where a change to the key or label
  // of a button does not render the component properly.
  const ulKey = buttons.map(({ key }) => key).join('');

  return (
    <Styled.Ul key={ulKey}>
      {buttons.map(props => (
        <li key={props.key}>
          <Styled.ButtonSingle {...props} />
        </li>
      ))}
    </Styled.Ul>
  );
};

export default ModalButtons;
