import { styled } from 'styled-components';

/**
 * Used value that suits the height of the compose toggle when closed.
 * Used rems in case user changes font size.
 */

type UlProps = {
  'data-should-pad-bottom': boolean;
};

/**
 * Gets bottom padding for the list in case it needs it. (eg. Compose closed, on desktop)
 *
 * @param props List props
 * @returns     Bottom padding for the list
 */
const getPaddingBottom = (props: UlProps): string => {
  /* Calculate toggle height and add some spacing */
  const paddingBottom =
    'calc(var(--preference-compose-toggle-closed-height) + var(--spacing-small))';

  if (props['data-should-pad-bottom']) {
    return paddingBottom;
  }

  return '0';
};

// Component wrapper
export const Ul = styled.ul<UlProps>`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: ${getPaddingBottom};
`;
