/**
 * @file contains the toast message duration reducer function
 */

import { TOAST__SET__DURATION } from '../../constants/actionTypes';
import { ToastMessageActionDuration } from '../../models/toastMessage';
import initialState from '../initialState';

type Reducer<State, Action> = (state: State, action: Action) => State;

/**
 * The user has requested to edit the duration of a message
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const onToastMessageDuration = (
  state: number,
  action: ToastMessageActionDuration,
): number => {
  return action.payload ? action.payload : state;
};

/**
 * action:reducer mapping for toastMessage duration
 */
const mapping = new Map<string, Reducer<number, ToastMessageActionDuration>>([
  [TOAST__SET__DURATION, onToastMessageDuration],
]);

/**
 * Reducer for toastMessage duration actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The new app state
 */
const toastMessageDuration = (
  state = initialState.toastMessageDuration,
  action: ToastMessageActionDuration,
): number => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default toastMessageDuration;
