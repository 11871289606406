import React, { FC } from 'react';

import { TemplateBasicFragment } from '../../../../../generated/graphql';

import translate from '../../../../../i18n/translate';
import MessageNoResult from '../../../../Common/MessageNoResult';
import ComposeMetaSection from '../ComposeMetaSection';

type Props = {
  template?: TemplateBasicFragment;
};

/**
 * Section in the compose that displays the templates
 *
 * @param props          Props passed to the component
 * @param props.template Currently selected template
 * @returns              The component itself
 */
const ComposeMetaTemplates: FC<Props> = ({ template }) => {
  return (
    <ComposeMetaSection heading={translate('GENERAL__TEMPLATE')}>
      {template?.title ?? (
        <MessageNoResult
          message={translate('TEMPLATE__GENERAL__MESSAGE__NONE')}
        />
      )}
    </ComposeMetaSection>
  );
};

export default ComposeMetaTemplates;
