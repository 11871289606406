/**
 * @file A topic was updated, so we update the cache accordingly
 */
import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import {
  TopicBasicDocument,
  TopicBasicFragment,
  TopicBasicQuery,
  TopicBasicQueryVariables,
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables,
  TopicsDocument,
  TopicSettingsDocument,
  TopicSettingsQuery,
  TopicSettingsQueryVariables,
  TopicsQuery,
  TopicsQueryVariables,
  TopicUpdateMutation,
  TopicUpdateMutationVariables,
} from '../../../../../../../generated/graphql';

import { topicAddToList, topicAddToSettings } from './topicAdd';
import { topicRemoveFromList, topicRemoveFromSettings } from './topicRemove';

import {
  getShouldAddToList,
  getShouldAddToSettings,
  getShouldRemoveFromList,
  getShouldRemoveFromSettings,
} from './topicsAssign/helpers';

type Callback = (
  cache: Cache,
  topic: NonNullable<TopicInfoSubscription['topicInfo']['topic']>,
) => void;

type Mutations =
  | { __typename?: 'Mutation' }
  | TopicUpdateMutation
  | TopicInfoSubscription;
type Queries = TopicsQuery | TopicSettingsQuery;
type Variables = TopicUpdateMutationVariables | TopicInfoSubscriptionVariables;

/**
 * Merge original with updates and return
 *
 * @param original               The old data
 * @param topic                  Updated topic data
 * @param topic.data             Updated topic data
 * @param topic.data.title       Updated topic title
 * @param topic.data.description Updated topic description
 * @returns                      Merged topic data
 */
const patchTopicSingle = (
  original: TopicBasicFragment,
  { data: { title, description } }: Variables,
): TopicBasicFragment => ({
  ...original,
  description: description ?? original.description,
  title: title ?? original.title,
});

/**
 * Patch the topic list with the updates for the single topic
 *
 * @param args Updated topic data
 * @param data Query data
 * @returns    Updated data, to insert into the cache
 */
const patchTopicsList = <Data extends Queries>(
  args: Variables,
  data: Data | null,
): Data | null => {
  if (data === null) {
    return null;
  }

  const topics = 'topics' in data ? data.topics : data.topicsAll;
  const index = topics.findIndex(topic => topic.id === args.id);

  if (index === -1) {
    return null;
  }

  const topic = topics[index];

  topics[index] = patchTopicSingle(topic as TopicBasicFragment, args);
  if (topic.title !== args.data.title) {
    topics.sort((topicA, topicB) => topicA.title.localeCompare(topicB.title));
  }

  return data;
};

/**
 * Update TopicList query, if there's a need to do so
 *
 * @param cache urql GraphQL cache
 * @param topic The topic
 */
const topicUpdateList: Callback = (cache, topic) => {
  if (getShouldAddToList(cache, topic)) {
    topicAddToList(cache, topic);
  } else if (getShouldRemoveFromList(cache, topic)) {
    topicRemoveFromList(cache, topic.id);
  }
};

/**
 * Update TopicSettings query, if there's a need to do so
 *
 * @param cache urql GraphQL cache
 * @param topic The topic
 */
const topicUpdateSettings: Callback = (cache, topic) => {
  if (getShouldAddToSettings(cache, topic)) {
    topicAddToSettings(cache, topic);
  } else if (getShouldRemoveFromSettings(cache, topic)) {
    topicRemoveFromSettings(cache, topic?.id ?? null);
  }
};

/**
 * A topic was updated, so we update it in the cache,
 * and also re-sort topics if the title changed
 *
 * @param result Mutation result (what was returned from the server)
 * @param args   The updates that occurred
 * @param cache  urql cache object
 */
const topicUpdateAll: UpdateResolver<Mutations, Variables> = (
  result,
  args,
  cache,
) => {
  /**
   * Only handle subscriptions
   * Mutations no longer have assignment changes, so that's handled separately
   */
  if ('topicInfo' in result && result.topicInfo.topic) {
    const topic = result.topicInfo.topic;
    topicUpdateList(cache, topic);
    topicUpdateSettings(cache, topic);
  }

  cache.updateQuery<TopicBasicQuery, TopicBasicQueryVariables>(
    { query: TopicBasicDocument, variables: { id: args.id } },
    data => {
      if (data === null) {
        return null;
      }

      data.topic = patchTopicSingle(data.topic, args);
      return data;
    },
  );

  cache.updateQuery<TopicsQuery, TopicsQueryVariables>(
    { query: TopicsDocument },
    data => patchTopicsList(args, data),
  );

  cache.updateQuery<TopicSettingsQuery, TopicSettingsQueryVariables>(
    { query: TopicSettingsDocument },
    data => patchTopicsList(args, data),
  );
};

export default topicUpdateAll;
