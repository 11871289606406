import { styled } from 'styled-components';

// Title for the groups section
export const Heading = styled.h3`
  color: var(--color-text-light);
  font-size: var(--font-sm);
  font-weight: 600;
  margin-bottom: var(--spacing-small);
`;

// Wrapper around all groups
export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

// Wrapper around each group
export const Li = styled.li`
  margin-right: var(--spacing-small);

  /** 
   * In order to force reDraw on Safari and display items properly 
   * @see http://mir.aculo.us/2009/09/25/force-redraw-dom-technique-for-webkit-based-browsers/
   */
  transform: translateZ(0);
`;
