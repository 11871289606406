import React, { FC } from 'react';

import Icon, { NAMES } from '../Common/Icon';

import * as Styled from './styled';

const splashScreenSize = 250;

/**
 * Splash screen for the app
 *
 * @returns The component itself
 */
const SplashScreen: FC = () => {
  return (
    <Styled.Wrapper>
      <Icon
        color="var(--color-button-secondary)"
        height={splashScreenSize}
        name={NAMES.GENERAL__LOGO__SPLASH}
        width={splashScreenSize}
      />
    </Styled.Wrapper>
  );
};

export default SplashScreen;
