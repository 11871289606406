import { styled } from 'styled-components';

type CustomOffsetProps = {
  'data-is-checked': boolean;
};

export const Wrapper = styled.div<CustomOffsetProps>`
  align-items: center;
  display: grid;
  font-weight: ${props =>
    props['data-is-checked']
      ? 'var(--font-weight-label-bold)'
      : 'var(--font-weight-label-regular)'};
  gap: var(--spacing-small);

  /* 3 grind columns: + (plus sign) <input type="text" <Select/> */
  grid-template-columns: auto 1fr 2fr;
  justify-content: space-between;
  width: 100%;

  &::before {
    content: '+';
  }

  & > svg {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;
