import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  className?: string;
};

/**
 * Dashed line separating message groups (by day)
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name, needed for styling
 * @returns               The component itself
 */
const MessageSeparator: FC<Props> = ({ className }) => (
  <div className={className}>
    <Styled.Separator />
  </div>
);

export default MessageSeparator;
