import { styled } from 'styled-components';

import LinkIcon from '../../LinkIcon';
import CommonRibbon from '../../Ribbon';

export const Ribbon = styled(CommonRibbon)`
  display: none;
  left: 50%;
  position: absolute;
  top: var(--spacing-small);
  transform: translateX(-50%);

  @media (hover: none) {
    display: flex;
  }
`;

// Use &:hover { color } so we can manipulate hover color of 'i' icon
export const EditIcon = styled(LinkIcon)`
  &:hover {
    color: var(--color-hover-dark);
  }
`;
