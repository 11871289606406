/**
 * @file contains helper functions for Compose List Search component
 */
import {
  TemplateBasicFragment,
  TopicBasicFragment,
} from '../../../../generated/graphql';
import filter from '../../../../utils/filter';

export type DataType = 'template' | 'topic';

/**
 * Get whether the list is empty (nothing to show)
 *
 * @param data The data that are able to be assigned
 * @returns    Whether it's true
 */
export const getIsEmpty = (
  data: (TemplateBasicFragment | TopicBasicFragment)[],
): boolean => {
  return data.length === 0;
};

/**
 * Get whether the user is searching groups and members
 * (whitespace doesn't count)
 *
 * @param searchTerm What is typed in the search form
 * @returns          Whether it's true
 */
export const getIsSearching = (searchTerm: string): boolean => {
  return searchTerm.trim().length !== 0;
};

/**
 * Get whether to show no results text
 *
 * @param isEmpty     Whether the assignment modal is empty (no groups or members to show)
 * @param isLoading   Whether the data is being fetched
 * @param isSearching Whether the user is searching groups and members
 * @returns           Whether to show no results text
 */
export const getShouldShowNoResults = (
  isEmpty: boolean,
  isLoading: boolean,
  isSearching: boolean,
): boolean => {
  return isEmpty === true && isLoading === false && isSearching === false;
};

/**
 * Get whether to show the search box
 *
 * @param isEmpty     Whether the assignment modal is empty (no groups or members to show)
 * @param isSearching Whether the user is searching groups and members
 * @returns           Whether to show the search box
 */
export const getShouldShowSearchBox = (
  isEmpty: boolean,
  isSearching: boolean,
): boolean => {
  return isEmpty === false || isSearching === true;
};

/**
 * Filter passed data by title
 *
 * @param data   All data
 * @param search What the user typed in search box
 * @returns      Filtered array of data
 */
export const searchDataByTitle = <T>(data: T[], search: string): T[] => {
  // If nothing is searched, then just return everything
  if (search.trim().length === 0) {
    return data;
  }

  return filter(data, search, { keys: ['title'] });
};
