/**
 * @file Optimistically update the cache, filtering out removed topic
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MutationTopicDeleteArgs,
  TopicDeleteMutation,
  TopicsDocument,
  TopicSettingsDocument,
} from '../../../../../generated/graphql';
import queryDeletion from '../../updates/utils/updaters/queryDeletion';

/**
 * Optimistically delete a topic
 *
 * @param args  Arguments of a graphQL operation
 * @param cache urql graphQL cache
 * @returns     Updated cache
 */
const topicDelete: OptimisticMutationResolver<
  MutationTopicDeleteArgs,
  TopicDeleteMutation['topicDelete'] | null
> = (args, cache) => {
  queryDeletion({
    cache,
    entityId: args.id,
    query: TopicsDocument,
    queryName: 'topics',
  });

  queryDeletion({
    cache,
    entityId: args.id,
    query: TopicSettingsDocument,
    queryName: 'topicsAll',
  });

  cache.invalidate({
    __typename: 'Topic',
    id: args.id,
  });

  return {
    __typename: 'ActionResult',
    status: true,
  };
};

export default topicDelete;
