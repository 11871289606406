/**
 * @file Hook for getting message details
 */

import { useQuery } from 'urql';

import { MessageFullDocument } from '../../../../generated/graphql';
import useParamMessageId from '../../../../hooks/router/params/useParamMessageId';
import useTranslation from '../../../../hooks/store/useTranslation';

import useMessageDetailsParams from './useMessageDetailsParams';

/**
 * Fetch message details
 *
 * @returns All the info required for showing message details
 */
const useMessageDetails = () => {
  const messageId = useParamMessageId() ?? '';
  const [{ data, error, fetching }] = useQuery({
    pause: messageId === '',
    query: MessageFullDocument,
    variables: {
      messageId,
    },
  });

  const translation = useTranslation(messageId);

  const {
    shouldShowHeader,
    shouldShowReplyTray,
    shouldShowRightSidebarMobile,
  } = useMessageDetailsParams(data?.message?.isArchived ?? false);

  return {
    data,
    error,
    fetching,
    messageId,
    shouldShowHeader,
    shouldShowReplyTray,
    shouldShowRightSidebarMobile,
    translation,
  };
};

export default useMessageDetails;
