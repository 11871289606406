/**
 * @file contains helper functions related to the MessageSectionChecklist
 */

import { MessageFullFragment } from '../../../../generated/graphql';
import { MessageTranslation } from '../../../../models/translations';

/**
 * Transforms checklist items if they need translating
 *
 * @param checklist           Checklist items from backend
 * @param translatedChecklist Translated checklist items
 * @returns                   Checklist items
 */
export const transformChecklist = (
  checklist: MessageFullFragment['checklist'],
  translatedChecklist: MessageTranslation['checklist'] | undefined,
): MessageFullFragment['checklist'] => {
  if (translatedChecklist === undefined) {
    return checklist;
  }

  return checklist.map(checklistItem => {
    const itemTranslation = translatedChecklist.find(
      item => checklistItem.id === item.id,
    );

    return {
      ...checklistItem,
      text: itemTranslation?.text ?? checklistItem.text,
    };
  });
};
