import { styled } from 'styled-components';

import ButtonCommon from '../../../Button';

export const Button = styled(ButtonCommon)`
  border-radius: var(--border-radius-small);
  color: var(--color-text-dark);
  padding: var(--spacing-tiny) var(--spacing-large);
  transition:
    background-color var(--preference-transition-duration-normal),
    color var(--preference-transition-duration-normal);

  &:hover {
    background-color: var(--color-button-danger-hover);
    color: var(--color-text-alt);
  }
`;
