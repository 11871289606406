/**
 * @file Use ROQ token hook
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'urql';

import { RoqTokenCreateDocument } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import { reportApiErrors } from '../../utils/error';

import { getRoqToken, removeRoqToken, setRoqToken } from './tokenStorage';

/**
 * Use ROQ token hook
 *
 * @returns ROQ auth token or undefined
 */
export const useRoqToken = (): [
  string | undefined,
  Dispatch<SetStateAction<boolean>>,
] => {
  const hotelId = useParamHotelId();
  const [token, setToken] = useState(() => getRoqToken(hotelId));
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [, roqTokenCreateMutation] = useMutation(RoqTokenCreateDocument);

  useEffect(() => {
    // If the token is expired, unset it so it can be reset.
    if (hotelId !== null && isTokenExpired) {
      removeRoqToken(hotelId);
      setToken(undefined);
    }
  }, [hotelId, isTokenExpired]);

  useEffect(() => {
    // Check if we should (not) fetch a new token.
    if (hotelId === null || token !== undefined) {
      return;
    }

    roqTokenCreateMutation({})
      .then(result => {
        const newToken = result.data?.roqTokenCreate.token;
        if (newToken === undefined) {
          return;
        }

        setRoqToken(hotelId, newToken);
        setToken(newToken);
        setIsTokenExpired(false);
      })
      .catch(reportApiErrors);
  }, [hotelId, token, roqTokenCreateMutation]);

  return [token, setIsTokenExpired];
};
