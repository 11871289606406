/**
 * @file contains redux actions related to draftDateDue state
 */

import {
  DRAFTS__DATE_DUE__SAVE,
  DRAFTS__DATE_DUE__SET,
} from '../../constants/actionTypes';

import {
  DraftActionDueDateSave,
  DraftActionDueDateSet,
} from '../../models/draftDueDate';

/**
 *
 * UPDATE actions
 *
 */

/**
 * Action to transfer data from current to saved keys in draft state
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @returns        The action itself
 */
export const saveDraftDueDateRequest = (
  parentId: string,
): DraftActionDueDateSave => ({
  parentId,
  type: DRAFTS__DATE_DUE__SAVE,
});

/**
 * Action to set the due date for a draft
 *
 * @param parentId The ID of the message the target draft is a reply to
 * @param date     New due date (null for no due date)
 * @returns        The action itself
 */
export const setDraftDueDateRequest = (
  parentId: string,
  date: Date | null,
): DraftActionDueDateSet => ({
  date,
  parentId,
  type: DRAFTS__DATE_DUE__SET,
});
