import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

import { CssVariable } from '../../styles/cssTypes';

type LinkProps = {
  'data-is-selected': boolean;
};

/**
 * Get which variable to use for background color
 *
 * @param props Props passed to the link
 * @returns     The variable to use
 */
const getLinkBackgroundColor = (props: LinkProps): CssVariable => {
  return props['data-is-selected']
    ? 'var(--color-background-selected)'
    : 'transparent';
};

/**
 * Get which variable to use for text color
 *
 * @param props Props passed to the link
 * @returns     The variable to use
 */
const getLinkTextColor = (props: LinkProps): CssVariable => {
  return props['data-is-selected']
    ? 'var(--color-text-selected)'
    : 'var(--color-text-regular)';
};

export const Link = styled(RouterLink)<LinkProps>`
  background-color: ${getLinkBackgroundColor};
  border-radius: var(--border-radius-small);
  color: ${getLinkTextColor};
  display: block;
  font-weight: ${(props: LinkProps) =>
    props['data-is-selected'] ? 'var(--font-normal)' : 'var(--font-light)'};
  padding: var(--spacing-small);
  transition: background-color var(--preference-transition-duration-normal);

  &:hover {
    background-color: var(--color-hover-light);
  }
`;
