import React, { FC, RefObject } from 'react';

import translate from '../../i18n/translate';

import { AttachmentActionType } from '../../models/attachment';
import ButtonIcon, { NAMES } from '../Common/ButtonIcon';

import * as Styled from './styled';

export type Props = {
  actionType?: AttachmentActionType;
  color?: string;
  colorHover?: string;
  iconSize?: number;
  link?: string;
  linkRef: RefObject<HTMLAnchorElement>;
  isInProgress?: boolean;
  requestButtonClick: () => void;
};

/**
 * Button used for file download/preview
 *
 * @param props                    Props passed to the component
 * @param props.actionType         Button action type (download/preview)
 * @param props.color              Icon color
 * @param props.colorHover         Icon hover color
 * @param props.iconSize           Height and width of the icon
 * @param props.isInProgress       Whether the download is in progress (WebView only)
 * @param props.link               Presigned url
 * @param props.linkRef            Ref to hidden link
 * @param props.requestButtonClick Request download button click
 * @returns                        The component itself
 */
const ButtonAttachmentAction: FC<Props> = ({
  actionType = 'download',
  color = 'var(--color-icons-inactive)',
  colorHover = 'var(--color-hover-dark)',
  link,
  iconSize,
  linkRef,
  isInProgress = false,
  requestButtonClick,
}) => {
  const linkTarget = actionType === 'download' ? undefined : '_blank';
  const iconName: Record<AttachmentActionType, string> = {
    download: NAMES.GENERAL__DOWNLOAD,
    preview: NAMES.ATTACHMENT__PREVIEW,
  };

  const label: Record<AttachmentActionType, string> = {
    download: translate('ARIA__LABEL__DOWNLOAD'),
    preview: translate('FILE__PREVIEW'),
  };

  return (
    // Use div as a wrapper because download button is used inside of <Ribbon/>
    <div>
      <ButtonIcon
        color={color}
        colorHover={colorHover}
        iconHeight={iconSize}
        iconName={iconName[actionType]}
        iconWidth={iconSize}
        isDisabled={isInProgress}
        isLoading={isInProgress}
        label={label[actionType]}
        onClick={requestButtonClick}
      />
      <Styled.HiddenLink
        aria-hidden="true"
        download={actionType === 'download'}
        href={link}
        ref={linkRef}
        target={linkTarget}
        title={label[actionType]}
      />
    </div>
  );
};

export default ButtonAttachmentAction;
