/**
 * @file Helpers to determine which members to pass
 * to messageAssign/messageUnassign/topicAssign/topicUnassign
 */

import {
  AssigneesFullQuery,
  UserBasicFragment,
  UserGroupBasicFragment,
} from '../../generated/graphql';
import { GroupID } from '../../models/group';
import { UserID } from '../../models/user';

/**
 * Since many of the functions do pretty much the same thing, extract their type
 */
type Extractor<T> = (data?: AssigneesFullQuery) => T;

/**
 * Get groups that can be assigned to a message
 *
 * @param data Data retrieved from the server
 * @returns    All groups that can be assigned to the message
 */
export const getGroupsAll: Extractor<UserGroupBasicFragment[]> = data => {
  return data?.topicAssignableGroups ?? [];
};

/**
 * Get groups that already assigned to the message
 *
 * @param data Data retrieved from the server
 * @returns    All groups that are already assigned
 */
export const getGroupsSaved: Extractor<Set<GroupID>> = data => {
  const groups = data?.message?.assignedGroups ?? [];
  return new Set(groups.map(group => group.id));
};

/**
 * Get members that can be assigned to a message
 *
 * @param data Data retrieved from the server
 * @returns    All members that can be assigned to the message
 */
export const getMembersAll: Extractor<UserBasicFragment[]> = data => {
  return data?.topicAssignableMembers ?? [];
};

/**
 * Get members that already assigned to the message
 *
 * @param data Data retrieved from the server
 * @returns    All members that are already assigned
 */
export const getMembersSaved: Extractor<Set<UserID>> = data => {
  const members = data?.message?.assignedMembers ?? [];
  return new Set(members.map(member => member.id));
};
