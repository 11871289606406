/**
 * @file Wisepops service for showing welcome popups
 */

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { WISEPOPS__SITE_ID, WISEPOPS__URL } from '../../config';

/**
 * Init WisePops service by injecting their script tag
 */
export const initWisePops = () => {
  // Do nothing if already initialized
  if (window.WisePopsObject) {
    return;
  }

  window.WisePopsObject = 'wisepops';

  (window.wisepops =
    window.wisepops ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window.wisepops.q = window.wisepops.q || []).push(arguments);
    }),
    (window.wisepops.l = Date.now());

  const o = document.createElement('script');
  const p = document.getElementsByTagName('script')[0];

  o.defer = true;
  o.src = `${WISEPOPS__URL}&h=${WISEPOPS__SITE_ID}`;
  p.parentNode?.insertBefore(o, p);
};
