/**
 * @file Gets the key down handler for the select button
 */

import { KeyboardEvent } from 'react';

type KeyboardHandlerButtonParams = {
  open: () => void;
};

const keyCodes = new Set(['ArrowDown', 'ArrowUp', 'Space']);

/**
 * Gets the key down handler for the select button
 *
 * @param args      Args passed to the hook
 * @param args.open Opens the select dropdown
 * @returns         Keyboard onKeyDown handler for the button
 */
const getKeyboardHandlerButton = ({
  open,
}: KeyboardHandlerButtonParams): ((event: KeyboardEvent) => void) => {
  return (event: KeyboardEvent) => {
    if (keyCodes.has(event.code) === true) {
      event.preventDefault();
      open();
    }
  };
};

export default getKeyboardHandlerButton;
