import React, { FC } from 'react';

import Search from '../../../components/Search';
import SearchLayout from '../../../components/layouts/SearchLayout';

/**
 * Search page
 *
 * @returns AppLayout for /search page
 */
const PageSearch: FC = () => {
  return (
    // Header is not needed in main search page
    <SearchLayout>
      <Search />
    </SearchLayout>
  );
};

export default PageSearch;
