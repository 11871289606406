import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__CHECKLIST } from '../../../constants/routing/hashLinks';

import { ChecklistItem } from '../../../generated/graphql';
import { MessageCommon } from '../../../models/message';
import Icon, { NAMES } from '../Icon';

import MessageInfo from './MessageInfo';

type Item = Pick<ChecklistItem, 'checked'>;

type Props = {
  checklist: Item[];
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

const ICON_SIZE = 17;

/**
 * Generate x/y text for checklist part of the message subtitle
 *
 * @param checklist The data to generate the text for
 * @returns         The generated text
 */
const getText = (checklist: Item[]): string => {
  const checked = checklist.filter(item => item.checked).length;
  const total = checklist.length;

  return `${checked}/${total}`;
};

/**
 * Checklist: x/y label for the message subtitle
 * (or null if there are no checklist items added to it)
 *
 * @param props              Props passed to the component
 * @param props.checklist    The checklist items attached to the current message
 * @param props.messageId    Message id
 * @param props.shouldBeLink Whether the info should be rendered as a link or not
 * @returns                  The component itself
 */
const MessageInfoChecklist: FC<Props> = ({
  checklist,
  messageId,
  shouldBeLink,
}) => {
  return (
    <MessageInfo
      elementId={HASH_MESSAGE__INFO__CHECKLIST}
      labelKey="ARIA__MESSAGE_INFO__CHECKLIST"
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      <Icon
        color="var(--color-background)"
        height={ICON_SIZE}
        name={NAMES.MESSAGE__INFO__CHECKBOX}
        width={ICON_SIZE}
      />
      <b>{getText(checklist)}</b>
    </MessageInfo>
  );
};

export default MessageInfoChecklist;
