/**
 * @file contains helper functions for Feed component
 */

/**
 * Whether useClickOutside hook should be executed
 *
 * @param isTablet       Is app running in tablet view
 * @param isTopicMissing Is current topic missing
 * @returns              Whether useClickOutside should execute
 */
export const shouldStopClickOutsideExec = (
  isTablet: boolean,
  isTopicMissing: boolean,
): boolean => {
  return !isTablet || isTopicMissing;
};
