import { styled } from 'styled-components';

// The additional text itself
export const AdditionalText = styled.p`
  margin: var(--spacing-small) 0;

  /* Max height of element is 100svh - padding of wrapping element (2 * var(---spacing-small)) */
  max-height: calc(var(--height-full) - 2 * var(--spacing-small));
  word-break: break-all;
`;
