import React, { FC, useId } from 'react';

import { generateRandomInt } from '../../../utils/number';

import SkeletonAvatar from '../SkeletonAvatar';
import SkeletonParagraph from '../SkeletonParagraph';

import * as Styled from './styled';

type Props = {
  avatarSize: number;
  color?: string;
  description: string;
  nrMessages?: number;
  shouldShowSkeleton: boolean;
};

const sizeMin = 10;
const sizeMax = 40;

/**
 * Skeleton view for messages,
 * visible in the feed
 *
 * @param props                    Props passed to the component
 * @param props.avatarSize         The size of the avatar skeleton
 * @param props.color              Skeleton color (it's different for comments)
 * @param props.description        Aria description for the loading state
 * @param props.nrMessages         How many messages should we show the skeleton for
 * @param props.shouldShowSkeleton Whether to show a skeleton paragraph for message body
 * @returns                        The component itself
 */
const SkeletonMessages: FC<Props> = ({
  avatarSize,
  color,
  description,
  nrMessages = 3,
  shouldShowSkeleton,
}) => {
  const descriptionId = useId();

  // Generate random widths for skeleton lines, between sizeMin and sizeMax
  const widths = Array.from({ length: nrMessages }, () => {
    return generateRandomInt(sizeMin, sizeMax);
  });

  return (
    <Styled.Wrapper aria-describedby={descriptionId} role="progressbar">
      <Styled.LoadingDescription id={descriptionId}>
        {description}
      </Styled.LoadingDescription>
      <Styled.Ul>
        {widths.map((width, index) => (
          // No way to generate a key for this
          // eslint-disable-next-line react/no-array-index-key
          <Styled.Li key={index}>
            <SkeletonAvatar color={color} size={avatarSize} />
            <Styled.Contents>
              <Styled.SkeletonTitle color={color} width={width} />
              {shouldShowSkeleton && <SkeletonParagraph nrLines={4} />}
            </Styled.Contents>
          </Styled.Li>
        ))}
      </Styled.Ul>
    </Styled.Wrapper>
  );
};

export default SkeletonMessages;
