/**
 * @file Get whether the user account is considered inactive
 */

import { UserStatus } from '../../generated/graphql';

// Mapping between status and whether we consider the user inactive
const mapping: Record<UserStatus, boolean> = {
  [UserStatus.ActivationPending]: true,
  [UserStatus.Active]: false,
  [UserStatus.Deactivated]: true,
  [UserStatus.Deleted]: true,
  [UserStatus.NotAssigned]: true,
};

/**
 * Get whether the user account is considered inactive
 *
 * @param status The user's status
 * @returns      Whether it counts as disabled
 */
const userGetInactive = (status?: UserStatus): boolean => {
  return status === undefined ? true : mapping[status];
};

export default userGetInactive;
