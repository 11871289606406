/**
 * @file Parse message JSON string
 */

import { JSONContent } from '@tiptap/react';

/**
 * Parse the text coming from the backend,
 * so that it can be used by the tiptap library
 *
 * @param text The text to parse
 * @returns    Text to use
 */
export const parseText = (text: string): string | JSONContent => {
  /**
   * There are 3 possibilities for text value:
   *
   * 1. It's a Hotelboard message, with some formatting => typeof === 'string'
   * 2. It's a Hotelboard message, but just a number typed within => typeof 'number'
   * 3. It's an external message => not a JSON, just plain text
   */
  try {
    const content = JSON.parse(text) as number | string;

    return typeof content === 'number' ? `<p>${text}</p>` : content;
  } catch {
    return text.length === 0 ? '' : `<p>${text}</p>`;
  }
};
