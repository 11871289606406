/**
 * @file All permissions checks related to users
 */

import { PermissionsUser } from '../../models/permissions';

import { USER__ADMIN, USER__UPDATE } from './permissionsAll';
import { getPermissionsCurrent } from './storage';

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
const hasPermission = (which: PermissionsUser): boolean => {
  return getPermissionsCurrent().includes(which);
};

/**
 * Check whether the current user has admin permissions for users
 *
 * @returns Whether it's true
 */
const getIsAdmin = (): boolean => {
  return hasPermission(USER__ADMIN);
};

/**
 * Check whether the user has permissions to update user
 *
 * @returns Whether the user has permissions
 */
export const getCanUpdateUser = (): boolean => {
  return getIsAdmin() || hasPermission(USER__UPDATE);
};

/**
 * Check whether the user has permissions to view users table
 *
 * @returns Whether the user has permissions
 */
export const getCanViewUsersTable = (): boolean => {
  return getIsAdmin();
};

/**
 * Check whether the user has permissions to change target language
 *
 * @returns Whether the user has permissions
 */
export const getCanChangeTargetLanguage = (): boolean => {
  return getIsAdmin();
};
