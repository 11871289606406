import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import ModalsContainer from '../../containers/modals';
import usePushSubscribe from '../../hooks/notifications/usePushSubscribe';
import RoqChatProvider from '../../integrations/roqChat/RoqChatProvider';

/**
 * Main application entry point for authenticated users
 * and pages that have the hotel ID in the URL.
 *
 * @returns AppAuthenticatedHotel component
 */
const AppAuthenticatedHotel: FC = () => {
  usePushSubscribe();

  return (
    <RoqChatProvider>
      <Outlet />
      <ModalsContainer />
    </RoqChatProvider>
  );
};

export default AppAuthenticatedHotel;
