/**
 * @file contains helper functions for useAttachmentAction hook
 */

import { reportApiErrors } from '../../utils/error';
import { postWebViewAttachmentActionEvent } from '../../utils/webview/events';
import {
  getIsPwaIos,
  getIsReactNativeWebView,
} from '../../utils/webview/helpers';

/**
 * Handle attachment preview
 *
 * @param filename Name of file
 * @param url      Signed url
 * @returns        url
 */
export const handlePreview = (filename: string, url: string): string | void => {
  const isReactNativeWebView = getIsReactNativeWebView();
  const isPwaIos = getIsPwaIos();

  // Native (>= v1.8) - Post attachment action event and handle preview via native method.
  if (isReactNativeWebView) {
    postWebViewAttachmentActionEvent('preview', url);
    return;
  }

  if (isPwaIos === false) {
    // Let the browser handle opening previews in new tab.
    return url;
  }

  // Fetch file locally so we could circumvent iOS Safari cross domain error.
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const file = new File([blob], filename, { type: blob.type });
      return URL.createObjectURL(file);
    })
    .catch(reportApiErrors);
};
