/**
 * @file Whether to show the compose form in the layout main grid area
 */
import useParamTopicId from '../../../../../hooks/router/params/useParamTopicId';
import useIsComposeOpen from '../../../../../hooks/router/search/useIsComposeOpen';

import useIsMobile from '../../../../../hooks/useIsMobile';
import { getCanCreateMessage } from '../../../../../utils/permissions/message';

/**
 * Whether to show the compose form in the layout main grid area
 *
 * @returns Whether to show the compose form
 */
const useShouldShowComposeMain = (): boolean => {
  const isComposeInUrl = useIsComposeOpen();
  const isMobile = useIsMobile();
  const topicId = useParamTopicId();

  return (
    getCanCreateMessage() && isComposeInUrl && isMobile && topicId !== null
  );
};

export default useShouldShowComposeMain;
