/**
 * @file Track site search hook
 */

import { useEffect } from 'react';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import { getUserCurrent } from '../../utils/permissions/storage';

import { getCustomDimensions } from './customDimensions';

import { trackSiteSearch } from '.';

type Params = {
  count: number;
  searchTerm: string | null;
  shouldSkipTracking: boolean;
};

/**
 * Track site search hook
 *
 * @param params                    Tracking params
 * @param params.count              The number of search results returned
 * @param params.searchTerm         The term that the user is searching
 * @param params.shouldSkipTracking Should the tracking be skipped (loading results or error)
 */
export const useTrackSiteSearch = ({
  count,
  searchTerm,
  shouldSkipTracking,
}: Params) => {
  const hotelId = useParamHotelId();

  useEffect(() => {
    if (shouldSkipTracking === true) {
      return;
    }

    if (searchTerm === null || searchTerm.trim().length === 0) {
      return;
    }

    const href = window.location.href;
    const user = getUserCurrent();

    const customDimensions = getCustomDimensions(href, user, hotelId);

    trackSiteSearch(searchTerm, count, customDimensions);
  }, [shouldSkipTracking, searchTerm, count, hotelId]);
};
