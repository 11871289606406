import React, { FC } from 'react';

import { NAMES } from '../Icon';

import * as Styled from './styled';

type Props = {
  size: number;
};

/**
 * Avatar icon for recurring posts
 *
 * @param props      Component props
 * @param props.size Icon size
 * @returns          The component itself
 */
const AvatarRecurring: FC<Props> = ({ size }) => {
  return (
    <Styled.RecurringIcon
      color="var(--color-background)"
      height={size}
      name={NAMES.CONCIERGE__TIME_PICKER_OUTLINE}
      width={size}
    />
  );
};

export default AvatarRecurring;
