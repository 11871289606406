/**
 * @file Hook to create a 404: Page not found toast
 */

import { useCallback } from 'react';

import translate from '../../i18n/translate';

import useToast from './useToast';

/**
 * Hook to create a 404: Page not found toast
 *
 * @returns Callback to create a toast
 */
const useToastPageNotFound = () => {
  const pushToast = useToast();

  const callback = useCallback(() => {
    pushToast({
      text: translate('ERROR__404__DESCRIPTION'),
      title: translate('GENERAL__OOPS'),
      type: 'error',
    });
  }, [pushToast]);

  return callback;
};

export default useToastPageNotFound;
